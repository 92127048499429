import { useState, useRef, useEffect } from "react";

// For wrapping state that is frequently updated, but used for an api,
// e.g. a text input hooked up to a search query.
export function useDebouncedTrigger<T>(trigger: T): T {
  const [debouncedTrigger, setDebouncedTrigger] = useState(trigger);

  const _timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    _timeout.current = setTimeout(() => {
      setDebouncedTrigger(trigger);
    }, 300);

    return () => {
      if (_timeout.current) {
        clearTimeout(_timeout.current);
      }
    };
  }, [trigger]);
  return debouncedTrigger;
}
