import {
  assertIsGrower,
  GrowerCutFlowerListing,
  GrowerListing,
  NarrowFromCategory,
  realProductCategories,
  searchObject,
  WithId,
} from "@rooted/shared";
import { Button, Input, Table, Tabs, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { CardViewToggle } from "../../../components/Misc/CardViewToggle";
import { NoSearchResults } from "../../../components/Misc/NoSearchResults";
import { useRouterBreadcrumb } from "../../../components/Misc/useRouterBreadcrumb";
import { PageHeader } from "../../../components/PageHeader";
import { ProductGrid } from "../../../components/ProductGrid";
import {
  colorColumn,
  genericNameColumn,
  growerCutFlowerPriceColumn,
  growerDriedFlowerTypeColumn,
  growerOptionsColumn,
  growerPriceColumn,
  growerStockColumn,
  makeGrowerDatesColumn,
  photoColumn,
  stemLengthColumn,
} from "../../../components/ProductTable";
import { FlowerVarietyColumn } from "../../../components/TableColumns/FlowerVarietyColumn";
import { useDebouncedTrigger } from "../../../hooks/util/useDebouncedTrigger";
import { useRooted } from "../../../RootedContext";
import { db, useCollectionDataChecked } from "../../../services/firebase";
import { getBreakpoint } from "../../../utils/detectWindowSize";
import { getCategorySlugBreadcrumbReplacement } from "../../../utils/getCategorySlugBreadcrumbReplacement";
import { CategorySettingsWidget } from "../CategorySettings";
import { ListingCard } from "./ListingCard";

type MakeColumns<L extends GrowerListing> = (listings: WithId<L>[]) => ColumnsType<WithId<L>>;

const genericColumns = [
  photoColumn,
  genericNameColumn,
  growerPriceColumn,
  growerStockColumn,
  growerOptionsColumn,
];

const makeColumnsMap: {
  [C in GrowerListing["category"]]: MakeColumns<NarrowFromCategory<GrowerListing, C>>;
} = {
  "cut-flower": (listings) => [
    photoColumn,
    FlowerVarietyColumn,
    colorColumn,
    stemLengthColumn,
    makeGrowerDatesColumn(listings),
    growerCutFlowerPriceColumn,
    growerStockColumn,
    growerOptionsColumn,
  ],
  bouquet: (listings) => [
    photoColumn,
    genericNameColumn,
    colorColumn,
    makeGrowerDatesColumn(listings),
    growerStockColumn,
    growerPriceColumn,
    growerOptionsColumn,
  ],
  bucket: (listings) => [
    photoColumn,
    genericNameColumn,
    colorColumn,
    makeGrowerDatesColumn(listings),
    growerStockColumn,
    growerPriceColumn,
    growerOptionsColumn,
  ],
  "dried-flower": (listings) => [
    photoColumn,
    genericNameColumn,
    growerDriedFlowerTypeColumn,
    colorColumn,
    makeGrowerDatesColumn(listings),
    growerStockColumn,
    growerPriceColumn,
    growerOptionsColumn,
  ],
  "potted-plant": (listings) => [
    photoColumn,
    genericNameColumn,
    makeGrowerDatesColumn(listings),
    growerStockColumn,
    growerPriceColumn,
    growerOptionsColumn,
  ],
  csa: (listings) => genericColumns,
  wreath: (listings) => genericColumns,
};

export function GrowerInventoryOverview<
  C extends GrowerListing["category"] = GrowerListing["category"]
>({ category }: { category: C }) {
  const { activeRole } = useRooted();
  assertIsGrower(activeRole);

  const [viewCards, setViewCards] = useState(!getBreakpoint().md);
  const breadcrumb = useRouterBreadcrumb({
    ...getCategorySlugBreadcrumbReplacement(category),
  });

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebouncedTrigger(search);

  const [activeListings = [], activeListingsLoading] = useCollectionDataChecked<
    WithId<NarrowFromCategory<GrowerListing, C>>
  >(
    db
      .collection("listings")
      .where("seller.profileId", "==", activeRole.profileId)
      .where("category", "==", category)
      .where("availability.active", "==", true),
    { idField: "_id" }
  );

  const [archivedListings = [], archivedListingsLoading] = useCollectionDataChecked<
    WithId<NarrowFromCategory<GrowerListing, C>>
  >(
    db
      .collection("listings")
      .where("seller.profileId", "==", activeRole.profileId)
      .where("category", "==", category)
      .where("availability.active", "==", false),
    { idField: "_id" }
  );

  const filteredActiveListings = useMemo(
    () => activeListings.filter((listing) => searchObject(debouncedSearch, listing)),
    [activeListings, debouncedSearch]
  );
  const filteredArchivedListings = useMemo(
    () => archivedListings.filter((listing) => searchObject(debouncedSearch, listing)),
    [archivedListings, debouncedSearch]
  );

  return (
    <>
      <PageHeader
        title={realProductCategories[category].pluralName}
        breadcrumb={breadcrumb}
        extra={
          <>
            {category === "cut-flower" && (
              <Link to="/connections/notify-buyers">
                <Button type="default">Notify Buyers</Button>
              </Link>
            )}
            <Link to="add-inventory">
              <Button className="btn btn-secondary">Add New Listings</Button>
            </Link>
          </>
        }
      />

      <CategorySettingsWidget category={category} />

      <Input
        placeholder={`Search by name`}
        style={{ width: 300, marginBottom: 12 }}
        allowClear
        onChange={(e) => setSearch(e.target.value.trim())}
      />

      <Tabs
        type="card"
        tabBarExtraContent={<CardViewToggle value={viewCards} onChange={setViewCards} />}
        className="no-margin"
      >
        <Tabs.TabPane
          key="active"
          tab={
            <>
              Visible
              {filteredActiveListings && filteredActiveListings.length > 0 && (
                <Typography.Text type="secondary">
                  &nbsp;&nbsp;({filteredActiveListings.length})
                </Typography.Text>
              )}
            </>
          }
        >
          <InventoryListingList
            loading={activeListingsLoading}
            isEmpty={filteredActiveListings.length === 0 && activeListings.length > 0}
            emptyComponent={
              <NoSearchResults
                search={debouncedSearch}
                onClear={() => setSearch("")}
                clearing={search === ""}
              />
            }
            category={category}
            listings={filteredActiveListings}
            mode={viewCards ? "cards" : "table"}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="archived"
          tab={
            <>
              Hidden / Archived
              {filteredArchivedListings && filteredArchivedListings.length > 0 && (
                <Typography.Text type="secondary">
                  &nbsp;&nbsp;({filteredArchivedListings.length})
                </Typography.Text>
              )}
            </>
          }
        >
          <InventoryListingList
            loading={archivedListingsLoading}
            isEmpty={filteredArchivedListings.length === 0 && archivedListings.length > 0}
            emptyComponent={
              <NoSearchResults
                search={debouncedSearch}
                onClear={() => setSearch("")}
                clearing={search === ""}
              />
            }
            category={category}
            listings={filteredArchivedListings}
            mode={viewCards ? "cards" : "table"}
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}

function InventoryListingList<C extends GrowerListing["category"] = GrowerListing["category"]>({
  listings,
  mode,
  category,
  isEmpty,
  emptyComponent,
  loading,
}: {
  listings: WithId<NarrowFromCategory<GrowerListing, C>>[];
  mode: "cards" | "table";
  category: C;
  isEmpty: boolean;
  emptyComponent: React.ReactNode;
  loading?: boolean;
}) {
  const { activeRole } = useRooted();
  assertIsGrower(activeRole);

  const columns = useMemo(() => makeColumnsMap[category](listings as any), [category, listings]);

  // We need to filter by category again here -- there is an unfortunate race condition where the category
  // can change but the query does not re-run until the next render, in which case the table
  // will display listings from a different category with the wrong columns
  const filteredListings = useMemo(
    () => listings.filter((listing) => listing.category === category),
    [category, listings]
  );

  if (isEmpty) return <>{emptyComponent}</>;

  if (mode === "cards") {
    return (
      <ProductGrid>
        {filteredListings.map((d) => (
          <ListingCard key={d._id} listing={d as WithId<GrowerCutFlowerListing>} />
        ))}
      </ProductGrid>
    );
  }

  // We protect these invariants in `makeColumnsMap`
  return (
    <Table
      loading={loading}
      columns={columns as any}
      dataSource={filteredListings as any}
      size="small"
      className="table-x-scroll"
      rowKey="_id"
    />
  );
}
