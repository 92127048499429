import { DeleteOutlined } from "@ant-design/icons";
import { EnterpriseProfile, isEnterprise, Profile, Role, RootedUser, WithId } from "@rooted/shared";
import {
  Button,
  Card,
  Col,
  Divider,
  List,
  Modal,
  notification,
  Radio,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import Search from "antd/lib/input/Search";
import firebase from "firebase/app";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { EnterpriseAvatar } from "../../../components/Images/ThumbnailAvatar";
import { CopyCode } from "../../../components/Misc/CopyCode";
import { PageHeader } from "../../../components/PageHeader";
import { useQuery } from "../../../hooks/util/useQuery";
import { useSyncQueryParams } from "../../../hooks/util/useSyncQueryParams";
import { db } from "../../../services/firebase";
import { makePrefixQuery } from "../../../services/higher-order-searches";
import toIdDoc from "../../../utils/toIdDoc";
import { NarrowLayout, PageContent } from "../../layouts";

// APIS:
const getUserRoles = async (userId: string): Promise<WithId<Role>[]> =>
  (await db.collection("roles").where("userId", "==", userId).get()).docs.map((doc) =>
    toIdDoc<Role>(doc)
  );

const getPublicProfiles = async (profileIds: string[]): Promise<WithId<Profile>[]> => {
  const snapshots = await Promise.all(
    profileIds.map((id) => db.collection("profiles").doc(id).get())
  );
  const profiles = (
    await Promise.all(
      snapshots.map((snapshot) => {
        const { id: _id } = snapshot;
        const data = snapshot.data();
        if (!data) return null;
        return Object.assign(data as Profile, { _id });
      })
    )
  ).filter((profile) => !!profile) as WithId<Profile>[];
  return profiles;
};

type SortField = "email" | "id";
function isSortField(s?: string | null): s is SortField {
  return s === "email" || s === "id";
}

export const UsersOverview: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<WithId<RootedUser>[]>([]);

  const query = useQuery();
  const [search, setSearch] = useState(() => query.get("s") || "");
  const [sortField, setSortField] = useState<SortField>(() => {
    const param = query.get("field");
    if (isSortField(param)) {
      return param;
    } else return "email";
  });

  useSyncQueryParams([
    ["field", sortField],
    ["s", search],
  ]);

  const lastVisible = useRef<any>(); // TODO: type this a query document snapshot

  const loadMoreUsers = useCallback(async () => {
    setLoading(true);
    try {
      const searchLowercase = search.toLocaleLowerCase();
      let query: firebase.firestore.Query<firebase.firestore.DocumentData> = db
        .collection("users")
        .limit(10)
        .orderBy(sortField === "id" ? firebase.firestore.FieldPath.documentId() : sortField);

      if (sortField === "id") {
        // Cannot `startAfter` an id-ordered query with an empty string
        if (lastVisible.current || search) {
          query = query.startAfter(lastVisible.current || search);
        }
      } else {
        query = makePrefixQuery(query, sortField, searchLowercase);
        query = query.startAfter(lastVisible.current || null);
      }

      const snapshots = await query.get();

      const newUsers: WithId<RootedUser>[] = snapshots.docs.map((doc) => {
        const user = doc.data() as RootedUser;
        const _id = doc.id;
        return Object.assign(user, { _id });
      });
      // last visible controls this being a new result set
      setUsers(lastVisible.current ? (users) => users.concat(newUsers) : newUsers);
      const lastUser = snapshots.docs[snapshots.docs.length - 1];
      lastVisible.current = sortField === "id" ? lastUser.id || "" : lastUser;
    } catch (error) {
      notification.error({
        message: "Oops! Something went wrong fetching those users: " + error.message,
      });
    }
    setLoading(false);
  }, [search, sortField]);

  useEffect(() => {
    lastVisible.current = null;
    loadMoreUsers();
  }, [loadMoreUsers]);

  return (
    <NarrowLayout>
      <PageContent>
        <PageHeader title="Users" />

        <Space direction="vertical" style={{ width: "100%" }}>
          <Radio.Group
            style={{ marginBottom: 16 }}
            value={sortField}
            onChange={(e) => setSortField(e.target.value)}
            buttonStyle="solid"
            optionType="button"
            options={[
              { label: "Email", value: "email" },
              { label: "Id", value: "id" },
            ]}
          />
          <Search
            placeholder={`Search by ${sortField}`}
            style={{ width: 300 }}
            allowClear
            onSearch={setSearch}
            enterButton
            defaultValue={search}
          />
        </Space>

        <List
          loadMore={
            <Button
              loading={loading}
              onClick={loadMoreUsers}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              Load More
            </Button>
          }
          dataSource={users}
          loading={loading}
          rowKey={(user) => user._id}
          renderItem={(user) => <UserRow user={user} />}
        />
      </PageContent>
    </NarrowLayout>
  );
};

const UserRow: React.FC<{ user: WithId<RootedUser> }> = ({ user }) => {
  const { firstName, lastName } = user;

  const [{ publicProfiles, roles, isAdmin, loading }, setData] = useState<{
    publicProfiles: WithId<Profile>[];
    roles: WithId<Role>[];
    isAdmin: boolean;
    loading: boolean;
  }>({
    publicProfiles: [],
    roles: [],
    isAdmin: false,
    loading: true,
  });

  const refreshUserData = useCallback(async (userId: string) => {
    setData((data) => ({ ...data, loading: true }));
    try {
      const roles = await getUserRoles(userId);
      const publicProfiles = (
        await getPublicProfiles(
          roles.map((role) => (role.type === "admin" ? "admin" : role.profileId))
        )
      ).sort((p1, p2) => p1.type.localeCompare(p2.type));
      const isAdmin = roles.some((role) => role.type === "admin");
      setData({
        roles,
        publicProfiles,
        isAdmin,
        loading: false,
      });
    } catch (error) {
      console.error("Failed to get user data for %s", userId);
    }
  }, []);

  useEffect(() => {
    refreshUserData(user._id);
  }, [user._id, refreshUserData]);

  const fullName = `${firstName} ${lastName}`;

  // Only works on non-retail roles for now, due to complications with how
  // retail roles are weird with admin users
  const removeRole = useCallback(
    (profile: WithId<EnterpriseProfile>) => {
      Modal.confirm({
        title: `Remove Role: ${user.firstName} ${user.lastName} <-> ${profile.bio.displayName}`,
        onOk: async () => {
          const role = roles.find(
            (r) => r.type !== "retail-buyer" && r.type !== "admin" && r.profileId === profile._id
          );
          if (!role) {
            notification.error({ message: `Role not found for profile: ${profile._id}` });
            return;
          }
          await db.collection("roles").doc(role._id).delete();
          await refreshUserData(user._id);
        },
      });
    },
    [roles, user._id, refreshUserData, user.firstName, user.lastName]
  );

  return (
    <List.Item>
      <Card style={{ width: "100%" }}>
        <Col style={{ width: "100%" }}>
          <Row style={{ display: "flex", width: "100%", flex: 1 }}>
            <div style={{ flex: 1 }}>
              <List.Item.Meta
                title={
                  <div>
                    {fullName}
                    {isAdmin && (
                      <Tag style={{ marginBottom: 4, marginLeft: 4 }} color="gold">
                        admin
                      </Tag>
                    )}
                  </div>
                }
                description={user.email}
              />
            </div>
            <CopyCode>{user._id}</CopyCode>
          </Row>
          <Divider style={{ width: "100%" }} />
          <Col>
            {loading && <Spin />}
            {publicProfiles.map((profile) => (
              <Row key={profile._id}>
                <Space>
                  <EnterpriseAvatar profile={profile} size={"small"} />

                  <Typography.Text>
                    {profile.type !== "retail-buyer" ? (
                      <Link to={`/${profile.type}s/${profile.slug}`}>
                        {profile.bio.displayName}
                      </Link>
                    ) : (
                      profile.bio.displayName
                    )}
                  </Typography.Text>
                  <CopyCode>{profile._id}</CopyCode>
                  {isEnterprise(profile) && (
                    <Button
                      style={{ minWidth: 32 }} // force aspect ratio
                      size="small"
                      onClick={() => removeRole(profile)}
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  )}
                </Space>
              </Row>
            ))}
          </Col>
        </Col>
      </Card>
    </List.Item>
  );
};
