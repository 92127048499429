import { ApplicationForm, WithId } from "@rooted/shared";
import { Card, Steps, Tag, Tooltip } from "antd";
import React from "react";

export const ApplicationCard: React.FC<{ application: WithId<ApplicationForm> }> = ({
  application,
}) => {
  if (application.type === "coop") {
    const currentStepIndex =
      application.bio === undefined
        ? 0
        : application.details === undefined
        ? 1
        : application.stripe === undefined
        ? 2
        : 3;

    return (
      <Card title={application.bio?.displayName || "Untitled Coop Application"}>
        <Steps progressDot current={currentStepIndex} direction="vertical">
          <Steps.Step title="Profile" description="Your public rootedfarmers.com profile" />
          <Steps.Step title="Business Details" description="More about your business" />
          <Steps.Step
            title="Stripe Onboarding"
            description="Create an account to receive payments"
          />
          <Steps.Step
            title="Rooted Review"
            description="Rooted staff will review your application"
          />
        </Steps>
      </Card>
    );
  }

  return <></>;
};

export const ApplicationStatusTag: React.FC<{ application: WithId<ApplicationForm> }> = ({
  application,
}) => {
  if (application.status === "in-progress") {
    if (application.bio && application.details)
      return (
        <Tooltip title="This application is awaiting approval from Rooted staff.">
          <Tag>Awaiting Rooted approval</Tag>
        </Tooltip>
      );
    else
      return (
        <Tooltip title="This application needs additional information from you.">
          <Tag>In progress</Tag>
        </Tooltip>
      );
  }
  if (application.status === "approved") {
    if (
      application.type !== "wholesale-buyer" &&
      application.creditCardId &&
      application.ownerId &&
      application.stripe.status !== "needs-user-input"
    )
      return (
        <Tooltip title="This application is awaiting approval from Stripe, our payment processor.">
          <Tag>Awaiting Stripe approval</Tag>
        </Tooltip>
      );
    else
      return (
        <Tooltip title="This application has been approved. Enter you payment info to start selling.">
          <Tag>Needs payment info</Tag>
        </Tooltip>
      );
  }
  if (application.status === "profile-created")
    return (
      <Tooltip title="This application is complete. You're all set!">
        <Tag>Complete</Tag>
      </Tooltip>
    );
  else
    return (
      <Tooltip title="This application has been waitlisted.">
        <Tag>Waitlisted</Tag>
      </Tooltip>
    );
};
