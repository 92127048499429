import path from "path";

export function storageUrlTo100x100Url(storageUrl: string): string {
  const url = new URL(storageUrl);
  const fileExt = path.extname(url.pathname);
  const fileName = path.basename(url.pathname, fileExt);
  url.pathname = path.join(path.dirname(url.pathname), `${fileName}_100x100${fileExt}`);

  return url.href;
}
