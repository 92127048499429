import { FullWholesaleBuyerRole } from "@rooted/shared";
import { Typography, Form } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { PageHeader } from "../../../components/PageHeader";

export const WholesaleBuyerStartPane: React.FC<{
  role: FullWholesaleBuyerRole;
}> = ({ role }) => {
  return (
    <>
      <PageHeader title={"Where do I start?"} />
      <Typography.Paragraph>
        <Form>
          <ul>
            <li>
              <Form.Item>
                <b>Returning member? Your profile and images are safe! </b>
                <br />
                If you are a returning member of Rooted, you should find your photo and profile
                information intact.
              </Form.Item>
            </li>
            <li>
              <Form.Item>
                <b>Returning member? All of your connections are safe, too :)</b>
                <br />
                View all of your farmers and connections <Link to="/connections">here</Link> – no
                need to re-link with any of them!
              </Form.Item>
            </li>
            <li>
              <Form.Item>
                <b>New member?</b>
                <br />
                Excellent! We can't wait to help you source those gorgeous blooms! First,{" "}
                <Link to="/connections/find-sellers">connect</Link> with local growers and
                collectives, and then <Link to="/shop">visit the shop</Link> to purchase their
                products.
              </Form.Item>
            </li>
            <li>
              <Form.Item>
                <b>When can I start buying?</b>
                <br />
                Depends on your climate zone! Our farmers' growing schedules differ, but typically
                by mid-March we start to see fresh flowers becoming available.
              </Form.Item>
            </li>
            <li>
              <Form.Item>
                <b>I still have a question that I don't see answered here… </b>
                <br />
                Drop us a line! Send us an email at{" "}
                <Typography.Link href="mailto:help@rootedfarmers.com">
                  help@rootedfarmers.com
                </Typography.Link>{" "}
                and we'll be back in touch ASAP (usually within a couple of hours!).
              </Form.Item>
            </li>
          </ul>
        </Form>
      </Typography.Paragraph>
    </>
  );
};
