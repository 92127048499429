import React from "react";
import { isLocalhost, isProd, isPrerelease, version } from "../../utils/env";
import { GlobalBanner } from "./GlobalBanner";

export const AppVersionBanner: React.FC = () => {
  if (isLocalhost || !isProd)
    return (
      <GlobalBanner
        type={isProd ? "warning" : "info"}
        message={
          <>
            &nbsp;Rooted v{version}
            {isPrerelease && isLocalhost ? "-local" : "-beta"} ({process.env.REACT_APP_GIT_SHA})
            running with <b>{process.env.REACT_APP_BACKEND}</b> backend
          </>
        }
      />
    );
  else return null;
};
