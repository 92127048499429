import {
  CoopApplicationForm,
  GrowerApplicationForm,
  WithId,
  TokenizedCreditCard,
} from "@rooted/shared";

import { Card, Form, Input, Select, Tag } from "antd";
import { LabeledValue, SelectProps } from "antd/lib/select";
import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { formatCurrency } from "../../../../components/FormControls/CurrencyInput";
import { FirestoreDocForm } from "../../../../components/FormControls/FirestoreDocForm";
import { db } from "../../../../services/firebase";
import { useSubscriptionPlans } from "../../../../services/sellers/subscriptions";

export const CoopSubscriptionPanel: React.FC<{
  application: WithId<CoopApplicationForm | GrowerApplicationForm>;
}> = ({ application }) => {
  const [creditCard] = useDocumentData<TokenizedCreditCard>(
    db.collection("creditCards").doc(application.creditCardId || "none")
  );

  return (
    <>
      {!application.creditCardId && <Card>No credit card added</Card>}
      {creditCard && (
        <Card>
          Credit card: {creditCard.card.brand} ending in {creditCard.card.last4}
        </Card>
      )}
      <br />

      <FirestoreDocForm
        data={application}
        dbRef={db.collection("applications").doc(application._id)}
      >
        <Form.Item
          requiredMark={undefined}
          label="Subscription plan"
          name={["subscription", "key"]}
        >
          <AdminSubscriptionPicker type={application.type} />
        </Form.Item>
        <Form.Item requiredMark={undefined} label="Offer code" name={["subscription", "offerCode"]}>
          <Input />
        </Form.Item>
      </FirestoreDocForm>
    </>
  );
};

export const AdminSubscriptionPicker: React.FC<
  SelectProps<LabeledValue> & { type: "grower" | "coop" }
> = ({ onChange, value, type, ...props }) => {
  const [data, dataLoading, dataError] = useSubscriptionPlans(type);

  return (
    <Select
      {...props}
      showSearch
      style={{}}
      showArrow={false}
      filterOption={false}
      onChange={(v, o) => {
        onChange && onChange(v, o);
      }}
      notFoundContent={null}
      placeholder="Select a plan..."
      virtual={false}
      value={value}
    >
      {data?.map((x) => (
        <Select.Option key={x._id} value={x._id}>
          {x.name} <Tag>{x.group}</Tag> <br />
          {formatCurrency(x.price)} - tier {x.permissionTier}
        </Select.Option>
      ))}
    </Select>
  );
};
