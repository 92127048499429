import { Image } from "antd";
import React from "react";
import * as Sentry from "@sentry/react";
import { storageUrlTo100x100Url } from "./getResizedUrl";

type ThumbnailPreviewImageProps = {
  src: string;
  alt?: string;
  width?: string | number;
  height?: string | number;
  fallback?: string;
  preview?: boolean;
};

const ThumbnailPreviewImage__UNSAFE: React.FC<ThumbnailPreviewImageProps> = ({
  src,
  fallback = "/images/inventory-default.png",
  preview,
  ...rest
}) => {
  const thumbnailUrl = storageUrlTo100x100Url(src);
  return <Image src={thumbnailUrl} fallback={fallback} preview={preview ?? { src }} {...rest} />;
};

export const ThumbnailPreviewImage: React.FC<ThumbnailPreviewImageProps> = (props) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => {
      scope.setExtra("src", props.src);
    }}
    fallback={<Image {...props} src={props.fallback} preview={false} />}
  >
    {/* eslint-disable-next-line react/jsx-pascal-case */}
    <ThumbnailPreviewImage__UNSAFE {...props} />
  </Sentry.ErrorBoundary>
);
