/**
 * Converts an array of words to a grammatically correct concatenation.
 * https://www.youtube.com/watch?v=P_i1xk07o4g
 */
export const wordListToSentence = (words: string[]) => {
  if (words.length === 0) return "";
  if (words.length === 1) return words[0];
  const wordsCopy = [...words];
  const last = wordsCopy.pop();
  return wordsCopy.join(", ") + " and " + last;
};
