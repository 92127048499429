import { CSASeason, GrowerCSAListing, legalCSASeasons, WithId } from "@rooted/shared";
import { Button, InputNumber, Popover, Select, Space } from "antd";
import { SelectProps } from "antd/lib/select";
import React, { useCallback, useState } from "react";
import { db } from "../../../../services/firebase";
import { EditOutlined } from "@ant-design/icons";
import { makeSelectOptions } from "../../../../utils/make-select-options";

const legalCSASeasonsOptions = makeSelectOptions(legalCSASeasons);
export const CSASeasonInput: React.FC<SelectProps<string>> = (props) => {
  return (
    <Select
      style={{ width: 200 }}
      {...props}
      placeholder="Season..."
      options={legalCSASeasonsOptions}
    />
  );
};

export const CSAWeeksWidget: React.FC<{
  listing: WithId<GrowerCSAListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [weeks, setWeeks] = useState(listing.product.numberOfWeeks);

  const submit = useCallback(async () => {
    setSubmitting(true);

    await db
      .collection("listings")
      .doc(listing._id)
      .update({
        "product.numberOfWeeks": weeks,
        "pricing.pricePerStem": Math.round(listing.pricing.price / weeks),
      });

    setSubmitting(false);
    setOpen(false);
  }, [weeks, listing.pricing.price, listing._id]);

  return (
    <>
      {listing.product.numberOfWeeks} weeks
      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <InputNumber
                  min={3}
                  max={12}
                  value={weeks}
                  onChange={(e) => typeof e === "number" && setWeeks(e)}
                />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change duration"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

export const CSASeasonWidget: React.FC<{
  listing: WithId<GrowerCSAListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [season, setSeason] = useState<CSASeason>(listing.product.season);

  const submit = useCallback(async () => {
    setSubmitting(true);

    await db.collection("listings").doc(listing._id).update({
      "product.season": season,
    });

    setSubmitting(false);
    setOpen(false);
  }, [listing._id, season]);

  return (
    <>
      {listing.product.season}
      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <CSASeasonInput
                  value={season}
                  onChange={(newSeason) => setSeason(newSeason as CSASeason)}
                />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change season"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};
