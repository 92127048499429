import React from "react";
import { OrdersTable } from "./OrderTable/OrderTable";
import { PageHeader } from "../../components/PageHeader";
import { Typography } from "antd";
import { Link } from "react-router-dom";

export const AnonymousPointOfSaleOrders: React.FC = () => (
  <>
    <PageHeader title={"Guest Checkout Orders"} breadcrumb />
    <Typography.Text>
      Guest Checkout Orders can be used to create Point of Sale purchases for customers that don't
      have Rooted accounts. You'll need to have your customer's credit card on hand to process
      these. After a Guest Checkout Order is processed, you'll see it in{" "}
      <Link to="/orders/fulfilled">Fulfilled Orders</Link> with the rest of your orders.
    </Typography.Text>

    {/* Antd <Space> is collapsing margin / padding, so block elements are needed for this spacing */}
    <br />
    <br />

    <OrdersTable status="guest-checkout" />
  </>
);
