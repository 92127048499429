import { allProductCategories, CoopRole, Listing, Relisting, WithId } from "@rooted/shared";
import { Card, Col, Divider, Image, Row, Tooltip, Typography } from "antd";
import { SelectProps } from "antd/lib/select";
import React, { useMemo, useState } from "react";
import { db, useCollectionDataChecked } from "../../services/firebase";
import { getFlowerPrototypeSearchFields } from "../../services/flower-prototypes";
import { useBreakpoint } from "../../utils/detectWindowSize";
import { sortByLocaleCompare } from "../../utils/sortByLocaleCompare";
import { ProductThumbnail } from "../Images/ProductThumbnail";
import { ThumbnailPreviewImage } from "../Images/ThumbnailPreviewImage";
import { ColorDisplay } from "../Misc/ColorDisplay";
import OverflowParagraph from "../OverflowParagraph";
import { FlowerVariety } from "../TableColumns/FlowerVarietyColumn";
import { PricingDisplay } from "../Widgets/BuyerListings/PriceWidget";
import { OnChangeFunc, SearchableSelect, SelectOption } from "./SearchableSelect";

const ListingTooltipPreview: React.FC<{
  listing: WithId<Listing>;
  textStyle?: React.CSSProperties;
}> = ({ listing, textStyle }) => {
  return (
    <Col>
      <Image width={200} src={listing.product.photoStorageUrl} />
      <br />
      {listing.category === "cut-flower" ? (
        <FlowerVariety
          textStyle={textStyle}
          flowerPrototype={listing.product._prototypeCache}
          color={listing.product.dominantColor}
        />
      ) : (
        <Typography.Text style={textStyle}>{listing.product.name}</Typography.Text>
      )}
      <PricingDisplay pricing={listing.pricing} unit={listing.product.unit} />
    </Col>
  );
};

const ListingModalPreview: React.FC<{
  listing: WithId<Listing>;
  textStyle?: React.CSSProperties;
}> = ({ listing, textStyle }) => {
  return (
    <>
      <Row wrap={false}>
        <ProductThumbnail product={listing.product} />
        <Col style={{ paddingLeft: 8 }}>
          {listing.category === "cut-flower" ? (
            <FlowerVariety
              textStyle={textStyle}
              flowerPrototype={listing.product._prototypeCache}
              color={listing.product.dominantColor}
            />
          ) : (
            <Typography.Text style={textStyle}>{listing.product.name}</Typography.Text>
          )}
          <PricingDisplay pricing={listing.pricing} unit={listing.product.unit} />
        </Col>
      </Row>
      {listing.product.description && (
        <>
          <Divider style={{ marginTop: 4, marginBottom: 4 }} />
          <OverflowParagraph expandable>{listing.product.description}</OverflowParagraph>
        </>
      )}
    </>
  );
};

const ListingOptionWithTooltip: React.FC<{ listing: WithId<Listing> }> = ({ listing }) => {
  // Don't show tooltip on mobile / ipad screens
  const { xl } = useBreakpoint();
  const { product } = listing;

  const categoryName = allProductCategories[product.category]?.name;
  const nameWithCategory = `${product.name}${categoryName ? ` (${categoryName})` : ""}`;

  if (xl) {
    return (
      <Tooltip
        mouseEnterDelay={0.5}
        title={<ListingTooltipPreview listing={listing} textStyle={{ color: "white" }} />}
      >
        <Row style={{ paddingRight: 8, alignItems: "center" }}>
          <ThumbnailPreviewImage
            src={product.photoStorageUrl}
            width={24}
            height={24}
            preview={false}
          />
          <Col style={{ flex: 1, paddingLeft: 8 }}>
            <Typography.Text>{nameWithCategory}</Typography.Text>
          </Col>
          {product.category === "cut-flower" && <ColorDisplay color={product.dominantColor} />}
        </Row>{" "}
      </Tooltip>
    );
  }
  // This isn't very DRY, but it's a pain with Tooltips to abstract what's inside of them,
  // and the select behavior means we can't control the `visible` prop on tooltip.
  // This is all temporary, as we don't want the tooltip at all eventually.
  return (
    <Row style={{ paddingRight: 8, alignItems: "center" }}>
      <ThumbnailPreviewImage src={product.photoStorageUrl} width={24} height={24} preview={false} />
      <Col style={{ flex: 1, paddingLeft: 8 }}>
        <Typography.Text>{nameWithCategory}</Typography.Text>
      </Col>

      {product.category === "cut-flower" && <ColorDisplay color={product.dominantColor} />}
    </Row>
  );
};

/**
 * Get an array of additional search fields for the given listing
 */
const getListingSearchFields = (listing: WithId<Listing | Relisting>) => {
  if (listing.category !== "cut-flower") return;
  return getFlowerPrototypeSearchFields(listing.product._prototypeCache);
};

const listingToOption = (listing: WithId<Listing | Relisting>): SelectOption => {
  return {
    label: <ListingOptionWithTooltip listing={listing} />,
    name: listing.product.name,
    value: listing._id,
    searchFields: getListingSearchFields(listing),
  };
};

export const GrowerListingSelect: React.FC<{ profileId: string; onChange?: OnChangeFunc }> = ({
  profileId,
  onChange,
}) => {
  const [listings = [], listingsLoading] = useCollectionDataChecked<WithId<Listing>>(
    db.collection("listings").where("seller.profileId", "==", profileId),
    { idField: "_id" }
  );

  const listingOptions = useMemo(
    () => listings.map(listingToOption).sort(sortByLocaleCompare("name")),
    [listings]
  );

  return (
    <SearchableSelect options={listingOptions} loading={listingsLoading} onChange={onChange} />
  );
};

export const CoopListingSelect: React.FC<{
  role: CoopRole;
  growerId: string;
  onChange?: SelectProps<string>["onChange"];
}> = ({ role, growerId, onChange }) => {
  const [relistings = [], relistingsLoading] = useCollectionDataChecked<WithId<Relisting>>(
    db
      .collection("relistings")
      .where("seller.profileId", "==", role.profileId)
      .where("backingListing.seller.profileId", "==", growerId),
    { idField: "_id" }
  );

  const relistingOptions = useMemo(
    () => relistings.map(listingToOption).sort(sortByLocaleCompare("name")),
    [relistings]
  );

  const [selectedId, setSelectedId] = useState<string>();
  const selectedRelisting = useMemo(() => relistings.find((r) => r._id === selectedId), [
    relistings,
    selectedId,
  ]);

  return (
    <>
      <SearchableSelect
        options={relistingOptions}
        loading={relistingsLoading}
        onChange={(id, option) => {
          setSelectedId(id);
          onChange?.(id, option);
        }}
      />
      {selectedRelisting && (
        <>
          <Typography.Text type="secondary" style={{ fontSize: 14 }}>
            Details:
          </Typography.Text>

          <Card style={{ marginTop: 0 }} bodyStyle={{ padding: 8 }}>
            <ListingModalPreview listing={selectedRelisting} />
          </Card>
        </>
      )}
    </>
  );
};
