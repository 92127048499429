import { WithId, makeProfileRoute, Profile, isRetail } from "@rooted/shared";
import { db, useDocumentDataChecked } from "../../services/firebase";
import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "antd";

export const EnterpriseProfileCallout: React.FC<{ id: string }> = ({ id }) => {
  const [profile] = useDocumentDataChecked<WithId<Profile>>(db.collection("profiles").doc(id), {
    idField: "_id",
  });

  if (!profile) return <>...</>;
  return (
    <>
      <Link to={makeProfileRoute(profile)}>{profile.bio.displayName}</Link>
    </>
  );
};

export const ProfileNameSimple: React.FC<{ id: string }> = ({ id }) => {
  const [profile] = useDocumentDataChecked<Profile>(db.collection("profiles").doc(id));

  if (!profile) return <>...</>;
  return (
    <>
      {profile.bio.displayName}
      {isRetail(profile) && <Typography.Text type="secondary">{" (retail)"}</Typography.Text>}
    </>
  );
};

export const ProfileCallout: React.FC<{ id: string; bold?: boolean }> = ({ id, bold = true }) => {
  const [profile] = useDocumentDataChecked<Profile>(db.collection("profiles").doc(id));

  if (!profile) return <>...</>;
  return (
    <>
      {bold ? <b>{profile.bio.displayName}</b> : profile.bio.displayName}

      {isRetail(profile) && (
        <>
          {" "}
          <Typography.Text type="secondary" style={{ float: "right", whiteSpace: "nowrap" }}>
            (retail buyer)
          </Typography.Text>
        </>
      )}
    </>
  );
};
