import { Layout, Result, Image, Typography } from "antd";
import React from "react";

const icon = (
  <Image
    style={{ marginTop: "1rem", display: "block", width: "400px", marginBottom: ".8rem" }}
    src="/logos/logo-horizontal.svg"
    preview={false}
    alt="Rooted Farmers logo"
  />
);

// Don't use `Link` component, because the react dom itself may fail. this component should
// have minimal dependencies.
const extra = (
  <Typography.Paragraph>
    Please try refreshing your page. If this problem persists, please contact support at{" "}
    <Typography.Link href="mailto:help@rootedfarmers.com">help@rootedfarmers.com</Typography.Link>
    <br />
    <Typography.Link style={{ marginTop: 12 }} href={process.env.REACT_APP_BASE_URL} target="_self">
      Back to home
    </Typography.Link>
  </Typography.Paragraph>
);

export const ErrorPage: React.FC = () => {
  return (
    <Layout>
      <Result
        icon={icon}
        style={{ marginTop: "30vh" }}
        title="Oops! Looks like something went wrong"
        extra={extra}
      />
    </Layout>
  );
};
