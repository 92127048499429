import { assertIsSeller, Connection, WithId } from "@rooted/shared";
import { Card, Col, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FulfillmentWidget } from "../../components/Widgets";
import { useRooted } from "../../RootedContext";
import { db, useDocumentDataChecked } from "../../services/firebase";
import { OrderingEnabledSwitch } from "./OrderingEnabledSwitch";

export const RetailConnectionCard: React.FC<{
  style?: React.CSSProperties;
}> = ({ style }) => {
  const { activeRole } = useRooted();
  assertIsSeller(activeRole);

  const [retailConnection] = useDocumentDataChecked<WithId<Connection>>(
    db.collection("connections").doc(`retail-${activeRole.profile._id}`),
    { idField: "_id" }
  );
  if (!retailConnection || retailConnection.status !== "approved") return null;

  return (
    <Card style={style} title="Retail Buyers" bodyStyle={{ paddingRight: 24, paddingLeft: 24 }}>
      {/* this mirrors the styling of a `ProfileConnectionBlurb` combined with the `FulfillmentWidget` applied to
          cards on `ApprovedConnectionList
      */}
      <Row style={{ width: "100%" }}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={10}
          xl={12}
          style={{ justifyContent: "flex-end", display: "flex", paddingBottom: 8, paddingRight: 8 }}
        >
          <Col flex={1}>
            <Typography.Text type="secondary" style={{ fontSize: 14, marginBottom: 8 }}>
              To enable retail buyers to shop your inventory, select delivery & pickup days as well
              as the zip codes you will deliver to.
              {/* Coops only have 1 category right now, so this message doesn't make sense for them. */}
              {activeRole.type !== "coop" && (
                <>
                  <br />
                  Also be sure to enable each <Link to="/inventory/">type of product</Link> you
                  would like to sell to retail buyers.
                </>
              )}
            </Typography.Text>
          </Col>
          {/* This size prevents jank when the 'disabled' tag enters */}
          <div style={{ width: 100 }}>
            <OrderingEnabledSwitch loading={false} connection={retailConnection} editable />
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={14} xl={12}>
          <FulfillmentWidget role={activeRole} editable={true} connection={retailConnection} />
        </Col>
      </Row>
    </Card>
  );
};
