import {
  Profile,
  Role,
  RootedUser,
  generateRole,
  generateAdminRole,
  generateAdminRoleId,
  generateRoleId,
  AdminRole,
  WithId,
} from "@rooted/shared";
import { db, useCollectionDataChecked } from "./firebase";

export const getRoleBetween = (user: WithId<RootedUser>, profile: WithId<Profile>) => {
  return db.collection("roles").doc(generateRoleId(user, profile));
};

export const hasRole = async (user: WithId<RootedUser>, profile: WithId<Profile>) => {
  const docRef = await db.collection("roles").doc(generateRoleId(user, profile)).get();
  return docRef.exists;
};

export const useAdminRoles = () =>
  useCollectionDataChecked<WithId<AdminRole>>(db.collection("roles").where("type", "==", "admin"), {
    idField: "_id",
  });

export const getAdminRole = (user: WithId<RootedUser>) =>
  db.collection("roles").doc(generateAdminRoleId(user));

export const createRole = async (user: WithId<RootedUser>, profile: WithId<Profile>) => {
  const role: Role = generateRole(user, profile);
  const roleId = generateRoleId(user, profile);
  await db.collection("roles").doc(roleId).set(role);
};

export const createAdminRole = (user: WithId<RootedUser>) => {
  const role: AdminRole = generateAdminRole(user);
  const roleId = generateAdminRoleId(user);
  db.collection("roles").doc(roleId).set(role);
};
