import React from "react";
import { OrdersTable } from "./OrderTable/OrderTable";
import { PageHeader } from "../../components/PageHeader";

export const FulfilledOrders: React.FC = () => (
  <>
    <PageHeader title={"Fulfilled Orders"} breadcrumb />
    <OrdersTable status="fulfilled" />
  </>
);
