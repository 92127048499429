import React from "react";
// This is the explicit file to allow this in!
// eslint-disable-next-line no-restricted-imports
import { PageHeader as AntdPageHeader } from "antd";
import { useRouterBreadcrumb } from "./Misc/useRouterBreadcrumb";
import { BreadcrumbProps } from "antd/lib/breadcrumb";

export const PageHeader: React.FC<{
  title: string;
  breadcrumb?: BreadcrumbProps | boolean;
  extra?: React.ReactNode;
}> = ({ title, breadcrumb, extra, children }) => {
  const defaultBreadcrumb = useRouterBreadcrumb();

  return (
    <AntdPageHeader
      title={title}
      breadcrumb={
        typeof breadcrumb === "boolean" ? (breadcrumb ? defaultBreadcrumb : undefined) : breadcrumb
      }
      extra={extra}
    >
      {children}
    </AntdPageHeader>
  );
};
