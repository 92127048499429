import {
  AddPaymentMethodRequest,
  FullUserRole,
  RemoveCreditCardRequest,
  RemoveCreditCardResponse,
  TokenizedCreditCard,
  WithId,
} from "@rooted/shared";
import { functions, db, useCollectionDataChecked } from "../firebase";

/** Call a cloud function to get a new client secret. */
export const getSetupIntentClientSecret = async (role: FullUserRole) => {
  const res = await functions.httpsCallable("getSetupIntentClientSecret")(role.profileId);
  return res.data as string;
};

/** After completing a setup intent, send the new payment method to the server. */
export const addPaymentMethod = async (role: FullUserRole, paymentMethod: string) => {
  const request: AddPaymentMethodRequest = {
    profileId: role.profileId,
    paymentMethod,
  };
  await functions.httpsCallable("addPaymentMethod")(request);
};

/** Listen to the list of role's current credit cards. */
export const useMyCards = (role: FullUserRole) =>
  useCollectionDataChecked<WithId<TokenizedCreditCard>>(
    db.collection("creditCards").where("ownerId", "==", role.profileId),
    { idField: "_id" }
  );

/** Remove a saved credit card. */
export const removeCreditCard = async (
  params: RemoveCreditCardRequest
): Promise<RemoveCreditCardResponse> => {
  const { data } = await functions.httpsCallable("removeCreditCard")(params);
  return data;
};
