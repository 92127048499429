/* eslint-disable import/namespace */
// ESLint is complaining about sentry namespace, but it is working correctly so not sure why

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { version } from "../utils/env";

export function initializeSentry() {
  Sentry.init({
    // TODO: consider moving to an env variable.
    // This is non critical -- this is not a secret, and is the same across all environments.
    dsn: "https://8673b780d1b34d85a1475ce90199a395@o585819.ingest.sentry.io/5738093",
    integrations: [new Integrations.BrowserTracing()],
    release: `rooted-app@${version}:${process.env.REACT_APP_GIT_SHA}`,
    environment: process.env.REACT_APP_BACKEND,
    ignoreErrors: ["ResizeObserver loop limit exceeded", "ResizeObserver"],
    enabled: process.env.REACT_APP_BACKEND !== "local" && process.env.NODE_ENV !== "development",

    // As we scale up, we should monitor this and probably limit to less
    tracesSampleRate: 1.0,
  });
}

export function setSentryUser({ email, id }: { email?: string; id: string }) {
  Sentry.setUser({
    email,
    id,
  });
}

export function logDebug({
  message,
  tags,
  extraData,
}: {
  message: string;
  tags?: { [key: string]: string };
  extraData?: { [key: string]: unknown };
}) {
  Sentry.withScope((scope) => {
    scope.setLevel(Sentry.Severity.Debug);
    if (tags) scope.setTags(tags);
    if (extraData) scope.setExtras(extraData);
    Sentry.captureMessage(message);
  });
}

export function logError({
  error,
  tags,
  extraData,
}: {
  error: any;
  tags?: { [key: string]: string };
  extraData?: { [key: string]: unknown };
}) {
  Sentry.withScope((scope) => {
    scope.setLevel(Sentry.Severity.Error);
    if (tags) scope.setTags(tags);
    if (extraData) scope.setExtras(extraData);
    Sentry.captureException(error, {});
  });
}

export const addBreadcrumb = Sentry.addBreadcrumb.bind(Sentry);
