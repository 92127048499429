import { FullCoopRole } from "@rooted/shared";
import { Badge, Menu } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useActiveOrders } from "../../services/sellers/orders";
import { useSellerConnectionRequests } from "../../services/connections";
import { useCoopMembershipRequests } from "../../services/coop-memberships";
import { useCount } from "../../services/higher-order-searches";
import { useCoopSettingsNotificationsCount } from "../../views/generic/Settings/settings-notifications";
import { useFulfillmentSettingsNotificationsCount } from "../../views/sellers/MyBuyers";
import { NavbarMode } from "./Navbar";

export const CoopNav: React.FC<{ role: FullCoopRole; navbarMode: NavbarMode; onClick?: any }> = ({
  role,
  navbarMode,
  onClick,
}) => {
  // Get the root level location (i.e. rootedfarmers.com/orders/msd763neo/... => "orders")
  const location = useLocation().pathname.split("/")[1];

  const connectionRequestCount = useCount(useSellerConnectionRequests(role));
  const membershipRequestCount = useCount(useCoopMembershipRequests(role));
  const orderCount = useCount(useActiveOrders(role));

  return (
    <Menu
      onClick={onClick}
      mode={navbarMode}
      className="role-specific-menu"
      selectedKeys={[location]}
    >
      <Menu.Item key="inventory">
        <Link to="/inventory">Inventory</Link>
      </Menu.Item>
      <Menu.Item key="orders">
        <Link to="/orders">Orders</Link> <Badge count={orderCount} />
      </Menu.Item>
      <Menu.Item key="connections">
        <Link to="/connections">
          My Buyers{" "}
          <Badge
            count={
              connectionRequestCount + useFulfillmentSettingsNotificationsCount(role.profileId)
            }
          />
        </Link>
      </Menu.Item>
      <Menu.Item key="members">
        <Link to="/members">
          My Growers <Badge count={membershipRequestCount} />
        </Link>
      </Menu.Item>
      <Menu.Item key="dashboard">
        <Link to="/dashboard/sales">Dashboard</Link>
      </Menu.Item>
      <Menu.Item key="my-account">
        <Link to="/my-account">
          My Account <Badge count={useCoopSettingsNotificationsCount(role.profileId)} />
        </Link>
      </Menu.Item>
    </Menu>
  );
};
