import { FullGrowerRole } from "@rooted/shared";
import { Badge, Menu } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSellerConnectionRequests } from "../../services/connections";
import { useCount } from "../../services/higher-order-searches";
import { useActiveOrders } from "../../services/sellers/orders";
import { isMissingGrowerExtraFields } from "../../views/generic/Settings/EnterpriseProfilePane";
import { useGrowerSettingsNotificationsCount } from "../../views/generic/Settings/settings-notifications";
import { useFulfillmentSettingsNotificationsCount } from "../../views/sellers/MyBuyers";
import { NavbarMode } from "./Navbar";

export interface GrowerNavProps {
  role: FullGrowerRole;
  navbarMode: NavbarMode;
  onClick?: any;
}

export const GrowerNav: React.FC<GrowerNavProps> = ({ role, navbarMode, onClick }) => {
  // Get the root level location (i.e. rootedfarmers.com/orders/msd763neo/... => "orders")
  const location = useLocation().pathname.split("/")[1];

  const connectionRequests = useSellerConnectionRequests(role);
  const incomingConnectionCount = useCount(connectionRequests);
  const fulfillmentNotificationCount = useFulfillmentSettingsNotificationsCount(role.profileId);
  const orderCount = useCount(useActiveOrders(role));
  const settingsNotificationCount = useGrowerSettingsNotificationsCount(role.profileId);

  const profileSettingsCount = isMissingGrowerExtraFields(role.profile) ? 1 : 0;

  return (
    <Menu
      onClick={onClick}
      mode={navbarMode}
      className="role-specific-menu"
      selectedKeys={[location]}
    >
      <Menu.Item key="inventory">
        <Link to="/inventory">Inventory</Link>
      </Menu.Item>
      <Menu.Item key="orders">
        <Link to="/orders">
          Orders <Badge count={orderCount} />
        </Link>
      </Menu.Item>

      <Menu.Item key="connections">
        <Link to="/connections">
          My Buyers <Badge count={incomingConnectionCount + fulfillmentNotificationCount} />
        </Link>
      </Menu.Item>
      <Menu.Item key="dashboard">
        <Link to="/dashboard/sales">Dashboard</Link>
      </Menu.Item>
      <Menu.Item key="my-account">
        <Link to="/my-account">
          My Account <Badge count={settingsNotificationCount + profileSettingsCount} />
        </Link>
      </Menu.Item>
      <Menu.Item key="resources">
        <Link to="/resources">Resources</Link>
      </Menu.Item>
    </Menu>
  );
};
