import {
  assertIsSeller,
  canViewChildOrder,
  FullSellerRole,
  stringifyFirestoreDefault,
} from "@rooted/shared";
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Modal,
  notification,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { ChildOrderItems } from "../../../components/ChildOrderItems";
import { formatCurrency } from "../../../components/FormControls/CurrencyInput";
import { useRouterBreadcrumb } from "../../../components/Misc/useRouterBreadcrumb";
import { PageHeader } from "../../../components/PageHeader";
import { CardSkeleton } from "../../../components/skeletons";
import { useCheckPaymentMethodExists } from "../../../hooks/orders/useCheckPaymentMethodExists";
import { useRooted } from "../../../RootedContext";
import { logError } from "../../../sentry";
import { useConnection } from "../../../services/connections";
import {
  cancelOrder,
  markOrderComplete,
  refreshAnonymousPosOrderPayment,
  useChildOrder,
} from "../../../services/sellers/orders";
import { RootedStripeProvider } from "../../../services/stripe";
import { NotFoundPage } from "../../../views/layouts";
import { OrderSummary } from "../../orders/OrderDetails/OrderSummary";
import { GuestCheckoutModal } from "./GuestCheckoutModal";

const coopTooltipCopy =
  "This order was placed through your coop. Please contact them to modify this order.";

const invalidPaymentMethodTooltipCopy =
  "This order has an issue with its credit card and cannot be completed at this time.";

const paymentErrorTooltipCopy =
  "This order has an issue that needs to be addressed before processing.";

const orderEmptyTooltipCopy = "This order is empty. Please add an item before processing.";

const ONE_DAY_MS = 24 * 60 * 60 * 1000;

export const OrderDetails: React.FC<{ role: FullSellerRole }> = ({ role }) => {
  const { orderId } = useParams();
  const { activeRole, isAdmin } = useRooted();
  assertIsSeller(activeRole);

  const [order, orderLoading] = useChildOrder(orderId);
  const buyerId = order?.buyerId ?? "-";

  // checks for OWN connection -- this is for order minimum message,
  // which will only matter if an original seller. If viewing a
  // relist, the order minimum will be irrelevant, but never show.
  const [connection] = useConnection(buyerId, role.profileId);

  const paymentMethodId = order?.paymentMethodId;
  const [isPaymentMethodValid, isPaymentMethodValidLoading] = useCheckPaymentMethodExists(
    paymentMethodId
  );

  const [approving, setApproving] = useState(false);
  const [canceling, setCanceling] = useState(false);
  const [refreshingAnonymousPos, setRefreshingAnonymousPos] = useState(true);
  const [posCheckoutVisible, setPosCheckoutVisible] = useState(false);

  // When the order loads and if it is a guest checkout, refresh the payment
  // On the server, this checks if 1) there is a payment intent for this order and 2) if that
  // payment intent is completed. If it is, refresh will mark the order as fulfilled
  useEffect(() => {
    if (!order || order.status !== "guest-checkout") return;
    setRefreshingAnonymousPos(true);

    refreshAnonymousPosOrderPayment({ orderId: order._id })
      .catch((error) => {
        logError({
          error,
          extraData: { context: "refresh anonymous pos order payment on load order details" },
        });
      })
      .finally(() => setRefreshingAnonymousPos(false));
  }, [order]);

  const title = order
    ? `Order #${order.number}${order.sellerId !== role.profileId ? " (coop)" : ""}`
    : "...";

  const breadcrumb = useRouterBreadcrumb({ [orderId]: title });
  if (breadcrumb.routes?.length === 2) {
    breadcrumb.routes = [
      breadcrumb.routes[0],
      { path: "", breadcrumbName: "Active" },
      breadcrumb.routes[1],
    ];
  }

  const approveOrder = useCallback(async () => {
    if (!order || order.status !== "active") throw new Error("Can only approve active order");
    setApproving(true);
    try {
      await markOrderComplete(order);
      notification.success({ message: "Order processed." });
    } catch (error) {
      const description = error.code === "failed-precondition" ? error.message : undefined;

      logError({
        error,
        tags: { page: "order-details" },
        extraData: { orderId },
      });

      notification.error({
        message: "Something went wrong processing this order.",
        description: description && <Typography.Text code>{description}</Typography.Text>,
        duration: 0,
      });
    }

    setApproving(false);
  }, [order, orderId]);

  const cancelOrderCallback = useCallback(async () => {
    if (!order || (order.status !== "active" && order.status !== "guest-checkout"))
      throw new Error("Can only cancel active / guest-checkout order");
    setCanceling(true);
    try {
      await cancelOrder({ orderId });
      notification.success({ message: "Order cancelled." });
    } catch (error) {
      logError({
        error,
        tags: { page: "order-details" },
        extraData: { orderId },
      });
      notification.error({ message: "Oops! Something went wrong canceling this order." });
    }
    setCanceling(false);
  }, [order, orderId]);

  if (orderLoading) return <CardSkeleton breadcrumb />;
  if (!order) return <NotFoundPage />;

  // Redirect to admin order view if the admin does not have the correct role
  if (!canViewChildOrder({ order, profileId: activeRole.profileId })) {
    return isAdmin ? <Navigate replace to={`/admin/orders/${orderId}`} /> : <NotFoundPage />;
  }

  const isOriginalSeller = order.sellerId === role.profileId;

  // if this is viewed as a grower not affiliated with this order,
  // then  pruneCoopOrder, in useChildOrder, will make this empty.
  const editable =
    isOriginalSeller && (order.status === "active" || order.status === "guest-checkout");

  const isOrderFulfillmentInFuture =
    order.fulfillment.type === "physical" &&
    order.fulfillment?.date &&
    Date.now() + ONE_DAY_MS < order.fulfillment.date;

  const orderMinimum = connection?.status === "approved" ? connection.orderMinimumCents ?? 0 : 0;
  const isBelowOrderMinimum = order._calculated.subtotal < orderMinimum;

  // Process payment warnings
  const warnings = [];
  if (isOrderFulfillmentInFuture) warnings.push("has a fulfillment date in the future");
  if (isBelowOrderMinimum) {
    warnings.push(`is below your order minimum of ${formatCurrency(orderMinimum)}`);
  }

  const orderIsEmpty = order.itemCounts.unique === 0 || order._calculated.subtotal === 0;

  // This lets us pick different copy for approve / cancel buttons if disabled.
  const [disableCancelCopyActive, disableApproveCopyActive] = ((): [
    string | undefined,
    string | undefined
  ] => {
    if (!isOriginalSeller) return [coopTooltipCopy, coopTooltipCopy];
    if (!isPaymentMethodValid)
      return [invalidPaymentMethodTooltipCopy, invalidPaymentMethodTooltipCopy];
    if (order.processingError && !order.processingError?.isResolved)
      return [paymentErrorTooltipCopy, paymentErrorTooltipCopy];
    if (orderIsEmpty) return [undefined, orderEmptyTooltipCopy];
    return [undefined, undefined];
  })();

  // Specify specific reasons for guest checkout
  const [disableCancelCopyGuestCheckout, disableApproveCopyGuestCheckout] = ((): [
    string | undefined,
    string | undefined
  ] => {
    if (!isOriginalSeller) return [coopTooltipCopy, coopTooltipCopy];
    if (orderIsEmpty) return [undefined, orderEmptyTooltipCopy];
    return [undefined, undefined];
  })();

  return (
    <>
      <Breadcrumb
        routes={breadcrumb.routes}
        itemRender={breadcrumb.itemRender}
        style={{ marginBottom: 16, paddingTop: 12 }}
      />
      {/* Only display this error when the corresponding fulfill buttons would show otherwise. */}
      {!isPaymentMethodValidLoading &&
        !isPaymentMethodValid &&
        editable &&
        order.status === "active" && (
          <Alert
            style={{ marginBottom: 16 }}
            showIcon
            type={"error"}
            message={
              // The default styling makes this line look gray whereas the description is black
              // We use typography to make them look the same.
              <Typography.Text>
                There's an issue with the credit card attached to this order.
              </Typography.Text>
            }
            description="We've been alerted of this issue & are working on addressing this!"
          />
        )}
      {isOriginalSeller &&
        order.status === "active" &&
        order.processingError &&
        !order.processingError.isResolved && (
          <Alert
            style={{ marginBottom: 16 }}
            showIcon
            type={"warning"}
            message={
              <Typography.Text>
                There was an issue billing this credit card
                {!order.processingError.cardError?.message && "."}
                {order.processingError.cardError?.message && (
                  <>
                    :<br />
                    <Typography.Text code>
                      {order.processingError.cardError.message}
                    </Typography.Text>
                  </>
                )}
              </Typography.Text>
            }
            description={
              <div style={{ marginTop: 8 }}>
                <Typography.Text>
                  Please contact{" "}
                  <Typography.Link
                    href={`mailto:help@rootedfarmers.com?subject=Help with Order #${order.number}`}
                  >
                    help@rootedfarmers.com
                  </Typography.Link>{" "}
                  before trying again.
                </Typography.Text>
              </div>
            }
          />
        )}
      {isOriginalSeller &&
        order.status === "active" &&
        order.processingError?.isResolved === true && (
          <Alert
            style={{ marginBottom: 16 }}
            showIcon
            type="success"
            message="The credit card issue has been resolved. You can now process this payment!"
          />
        )}
      <Card>
        <PageHeader
          title={title}
          extra={
            <Space direction="horizontal">
              {order.status === "active" &&
                (isPaymentMethodValidLoading ? (
                  <ApproveCancelOrderButtonsSkeleton />
                ) : (
                  <ApproveCancelOrderButtons
                    onCancel={cancelOrderCallback}
                    onApprove={approveOrder}
                    canceling={canceling}
                    approving={approving}
                    warningCopy={warnings}
                    // Disabled copy also triggers the disabled state
                    disableCancelCopy={disableCancelCopyActive}
                    disableApproveCopy={disableApproveCopyActive}
                  />
                ))}
              {order.status === "cancelled" && (
                <Typography.Text>
                  Cancelled on {stringifyFirestoreDefault(order.cancelledAt)}
                </Typography.Text>
              )}
              {order.status === "fulfilled" && (
                <Typography.Text>
                  Fulfilled on {stringifyFirestoreDefault(order.fulfilledAt)}
                </Typography.Text>
              )}
              {order.status === "guest-checkout" &&
                (refreshingAnonymousPos ? (
                  <ApproveCancelOrderButtonsSkeleton />
                ) : (
                  <ApproveCancelOrderButtons
                    onCancel={cancelOrderCallback}
                    onApprove={() => {
                      setPosCheckoutVisible(true);
                    }}
                    canceling={canceling}
                    approving={approving}
                    warningCopy={warnings}
                    disableCancelCopy={disableCancelCopyGuestCheckout}
                    disableApproveCopy={disableApproveCopyGuestCheckout}
                  />
                ))}
            </Space>
          }
        >
          <div style={{ display: "flex" }}>
            <OrderSummary order={order} sellerId={role.profileId} />
          </div>
        </PageHeader>

        <RootedStripeProvider stripeAccount={activeRole.privateProfile.stripeAccountId}>
          <GuestCheckoutModal
            orderId={order._id}
            visible={posCheckoutVisible}
            setVisible={() => setPosCheckoutVisible(false)}
          />
        </RootedStripeProvider>

        <ChildOrderItems order={order} role={role} editable={editable} />
      </Card>
    </>
  );
};

const ApproveCancelOrderButtonsSkeleton = () => (
  <>
    <Skeleton.Button active />
    &nbsp;&nbsp;
    <Skeleton.Button active />
  </>
);

/**
 * Note that `disabledCopy` controls this component being disabled, so that a message is always required.
 */
const ApproveCancelOrderButtons: React.FC<{
  onCancel: () => void;
  onApprove: () => void;
  canceling: boolean;
  approving: boolean;
  disableApproveCopy?: string;
  disableCancelCopy?: string;
  warningCopy?: string[];
}> = ({
  onCancel,
  onApprove,
  canceling,
  approving,
  disableApproveCopy,
  disableCancelCopy,
  warningCopy,
}) => {
  const onApproveWithWarnings = useCallback(() => {
    if (!warningCopy || !warningCopy.length) {
      onApprove();
      return;
    }

    // This will give us runway for ~1-3 concurrent warnings.
    // we're only planning 2 right now, so this is fine. Needs
    // revisiting if more warnings are added
    const warning = warningCopy.join(", and ");

    Modal.confirm({
      title: `Process Payment`,
      content: (
        <>
          {warning && (
            <>
              This order {warning}.
              <br />
            </>
          )}
          <div style={{ paddingTop: 16 }}>Are you sure you want to process the payment?</div>
        </>
      ),
      okText: "Process Payment",
      onOk: () => {
        // Don't return the promise.
        // Otherwise, the modal will stay open.
        onApprove();
      },
    });
  }, [onApprove, warningCopy]);

  return (
    <>
      <Tooltip title={disableCancelCopy}>
        <Button
          type="ghost"
          onClick={onCancel}
          loading={canceling}
          disabled={!!disableCancelCopy || approving}
          danger
          style={{ marginRight: 8 }}
        >
          Cancel
        </Button>
      </Tooltip>
      <Tooltip title={disableApproveCopy} placement={"left"}>
        <Button
          type="primary"
          onClick={onApproveWithWarnings}
          loading={approving}
          disabled={!!disableApproveCopy || canceling}
        >
          Process Payment
        </Button>
      </Tooltip>
    </>
  );
};
