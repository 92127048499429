import {
  ApplicationForm,
  CoopApplicationForm,
  GrowerApplicationForm,
  ProfileType,
  WithId,
  APPLICATION_STAGES,
  ApplicationFormStatus,
  ApplicationStage,
} from "@rooted/shared";
import { db, useCollectionDataChecked } from "../../../services/firebase";

const queryApplications = (type: ProfileType | "all", status: ApplicationFormStatus) => {
  const allTypes = db.collection("applications").where("status", "==", status);
  return type === "all" ? allTypes : allTypes.where("type", "==", type);
};

/**
 * Helper to convert our `ApplicationStage`, which defines a database field, 'status', as well as a filter function
 * into a nicely typed hook.
 */
const createUseApplicationsHook = <Stage extends ApplicationStage>(stage: Stage) => (
  type: ProfileType | "all"
) => {
  const res = useCollectionDataChecked<
    WithId<
      Stage extends { stage: 1 } ? ApplicationForm : GrowerApplicationForm | CoopApplicationForm
    >
  >(queryApplications(type, stage.status), {
    idField: "_id",
  });
  if (stage.filter) {
    // Unfortunately, the conditional type from on the `Stage extends {stage: 1}` doesn't apply to this.
    // This hack lets us avoid writing out the entire return type of `useCollectionDataChecked` with inference still.
    res[0] = res[0]?.filter((stage.filter as unknown) as any);
  }
  return res;
};

// Stage 1
export const useEmptyApplications = createUseApplicationsHook(APPLICATION_STAGES.empty);
export const useInProgressApplications = createUseApplicationsHook(APPLICATION_STAGES.inProgress);
export const useNeedsApprovalApplications = createUseApplicationsHook(
  APPLICATION_STAGES.needsApproval
);
export const useRejectedApplications = createUseApplicationsHook(APPLICATION_STAGES.rejected);

// Stage 2
export const useCreatedApplications = createUseApplicationsHook(APPLICATION_STAGES.created);
export const useApprovedIncompleteApplications = createUseApplicationsHook(
  APPLICATION_STAGES.approvedIncomplete
);
export const useApprovedCompleteApplications = createUseApplicationsHook(
  APPLICATION_STAGES.approvedComplete
);
