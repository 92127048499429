import { FullWholesaleBuyerRole } from "@rooted/shared";
import { Badge, Menu } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useBuyerConnectionRequests } from "../../services/connections";
import { NavbarMode } from "./Navbar";

export const WholesaleBuyerNav: React.FC<{
  role: FullWholesaleBuyerRole;
  navbarMode: NavbarMode;
  onClick?: any;
}> = ({ role, navbarMode, onClick }) => {
  // Get the root level location (i.e. rootedfarmers.com/orders/msd763neo/... => "orders")
  const location = useLocation().pathname.split("/")[1] || "shop";

  const [incoming] = useBuyerConnectionRequests(role);
  const incomingConnectionCount = incoming?.length || 0;

  return (
    <Menu
      onClick={onClick}
      mode={navbarMode}
      className="role-specific-menu"
      selectedKeys={[location]}
    >
      <Menu.Item key="shop">
        <Link to="/">Shop</Link>
      </Menu.Item>
      <Menu.Item key="orders">
        <Link to="/orders">Orders</Link>
      </Menu.Item>
      <Menu.Item key="connections">
        <Link to="/connections">
          My Growers <Badge count={incomingConnectionCount} />
        </Link>
      </Menu.Item>
      <Menu.Item key="my-account">
        <Link to="/my-account">My Account</Link>
      </Menu.Item>
    </Menu>
  );
};
