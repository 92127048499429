import { EditOutlined } from "@ant-design/icons";
import {
  formatPluralCount,
  getPottedPlantTagLabel,
  GrowerPottedPlantListing,
  legalPottedPlantTypes,
  PottedPlantTag,
  pottedPlantTagLabels,
  pottedPlantTagSchema,
  PottedPlantType,
  WithId,
} from "@rooted/shared";
import { Button, InputNumber, Popover, Select, Space, Tag } from "antd";
import { SelectProps } from "antd/lib/select";
import React, { useCallback, useState } from "react";
import { db } from "../../../../services/firebase";
import firebase from "firebase/app";
import { makeSelectOptions } from "../../../../utils/make-select-options";

const legalPottedPlantTypesOptions = makeSelectOptions(legalPottedPlantTypes);
export const PottedPlantTypeInput: React.FC<SelectProps<PottedPlantType>> = (props) => {
  return (
    <Select
      style={{ width: 200 }}
      {...props}
      placeholder="Type..."
      options={legalPottedPlantTypesOptions}
    />
  );
};

const pottedPlantTagsOptions = makeSelectOptions(
  pottedPlantTagSchema.options,
  (value) => pottedPlantTagLabels[pottedPlantTagSchema.parse(value)]
);

export const PottedPlantTagsSelect: React.FC<SelectProps<PottedPlantTag[]>> = (props) => {
  return (
    <Select
      style={{ width: 265 }}
      {...props}
      mode="multiple"
      maxTagCount={4}
      options={pottedPlantTagsOptions}
    />
  );
};

export const PottedPlantTypeWidget: React.FC<{
  listing: WithId<GrowerPottedPlantListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [type, setType] = useState<PottedPlantType>(listing.product.pottedPlantType);

  const submit = async () => {
    setSubmitting(true);

    await db
      .collection("listings")
      .doc(listing._id)
      .set(
        {
          product: {
            pottedPlantType: type,
          },
        },
        { merge: true }
      );

    setSubmitting(false);
    setOpen(false);
  };

  return (
    <>
      {listing.product.pottedPlantType}

      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <PottedPlantTypeInput value={type} onChange={setType} />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change type"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

export const PottedPlantTagsWidget: React.FC<{
  listing: WithId<GrowerPottedPlantListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [tags, setTags] = useState(listing.product.tags);

  const submit = async () => {
    setSubmitting(true);

    await db
      .collection("listings")
      .doc(listing._id)
      .update({
        "product.tags": tags || [],
      });

    setSubmitting(false);
    setOpen(false);
  };

  return (
    <>
      {listing.product.tags &&
        listing.product.tags.map((tag) => <Tag key={tag}>{getPottedPlantTagLabel(tag)}</Tag>)}

      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <PottedPlantTagsSelect value={tags} onChange={setTags} />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Set additional info"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

export const PotSizeWidget: React.FC<{
  listing: WithId<GrowerPottedPlantListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [potSize, setPotSize] = useState(listing.product.potSize);

  const submit = useCallback(async () => {
    setSubmitting(true);

    await db
      .collection("listings")
      .doc(listing._id)
      .update({
        "product.potSize": potSize || firebase.firestore.FieldValue.delete(),
      });

    setSubmitting(false);
    setOpen(false);
  }, [potSize, listing._id]);

  return (
    <>
      {listing.product.potSize && `${listing.product.potSize} in.`}
      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <InputNumber
                  min={1}
                  max={30}
                  value={potSize}
                  onChange={(e) => setPotSize((e as number | undefined) || undefined)}
                />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change pot size"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

export const TraySizeWidget: React.FC<{
  listing: WithId<GrowerPottedPlantListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [traySize, setTraySize] = useState(listing.product.traySize);

  const submit = useCallback(async () => {
    setSubmitting(true);

    await db
      .collection("listings")
      .doc(listing._id)
      .update({
        "product.traySize": traySize || firebase.firestore.FieldValue.delete(),
      });

    setSubmitting(false);
    setOpen(false);
  }, [listing._id, traySize]);

  return (
    <>
      {listing.product.traySize && formatPluralCount("cell", listing.product.traySize)}
      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <InputNumber
                  min={1}
                  max={500}
                  value={traySize}
                  onChange={(e) => setTraySize((e as number | undefined) || undefined)}
                />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change tray size"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};
