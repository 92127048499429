import { ArrowRightOutlined, MoreOutlined } from "@ant-design/icons";
import {
  ColorKey,
  CutFlowerListing,
  getDriedFlowerProductTypeLabel,
  GrowerCutFlowerListing,
  GrowerDriedFlowerListing,
  GrowerListing,
  legalStemLengths,
  Listing,
  WithId,
} from "@rooted/shared";
import { Button, Divider, Dropdown, Menu } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import React from "react";
import { Link } from "react-router-dom";
import { archiveListing, unArchiveListing } from "../../services/sellers/listings";
import {
  GrowerBulkListingDatesWidget,
  GrowerListingDatesWidget,
  GrowerPriceWidget,
  GrowerQuantityWidget,
} from "../../views/growers/Inventory/utils/widgets";
import { ProductThumbnail } from "../Images/ProductThumbnail";
import { ColorDisplay } from "../Misc/ColorDisplay";
import OverflowParagraph from "../OverflowParagraph";

export const stemLengthColumn = {
  title: "Stem Length",
  dataIndex: ["product", "stemLength"],
  key: "stemLength",
  width: 100,
  sorter: (
    { product: { stemLength: stemLengthA } }: CutFlowerListing,
    { product: { stemLength: stemLengthB } }: CutFlowerListing
  ) => legalStemLengths.indexOf(stemLengthA) - legalStemLengths.indexOf(stemLengthB),
  sortDirections: ["descend", "ascend"] as SortOrder[], // SortOrder from antd,
};

export const colorColumn = {
  title: "Color",
  key: "dominantColor",
  width: 120,
  render: (
    _: any,
    r: {
      product: {
        dominantColor: ColorKey;
      };
    }
  ) => <ColorDisplay color={r.product.dominantColor} />,
  sorter: (a: any, b: any): any =>
    b.product.dominantColor.localeCompare(a.product.dominantColor.toLowerCase()),
  sortDirections: ["descend", "ascend"] as any, // SortOrder from antd,
};

export const photoColumn = {
  title: "",
  dataIndex: ["photoStorageUrl"],
  key: "photo",
  fixed: "left" as const,
  width: 80,
  render: (_: any, r: Listing) => <ProductThumbnail product={r.product} />,
};

export const genericNameColumn = {
  title: "Name",

  key: "name",
  render: (_: any, record: WithId<Listing>) => record.product.name,
  sorter: (a: any, b: any): any => b.product.name.localeCompare(a.product.name.toLowerCase()),
  sortDirections: ["descend", "ascend"] as any, // SortOrder from antd,
};

export const genericDescriptionColumn = {
  title: "Description",
  // fixed: "right",
  dataIndex: ["product", "description"],
  key: "description",
  responsive: ["md"] as Breakpoint[],
  render: (_: any, record: WithId<Listing>) => (
    <OverflowParagraph expandable>{record.product.description}</OverflowParagraph>
  ),
};

// Also see: `FlowerVarietyDetailsColumn` for a better flower alternative
export const nameWithDescriptionColumn = {
  title: "Name",
  key: "name",
  width: 200,
  render: (_: any, { product }: WithId<Listing>) => (
    <>
      {product.name}
      <Divider style={{ marginTop: 0, marginBottom: 4 }} />
      <OverflowParagraph expandable>{product.description}</OverflowParagraph>
    </>
  ),
  sorter: (a: WithId<Listing>, b: WithId<Listing>): number =>
    b.product.name.localeCompare(a.product.name.toLowerCase()),
  sortDirections: ["descend", "ascend"] as SortOrder[],
};

// Grower-specific columns

export const growerOptionsColumn = {
  title: "More",
  dataIndex: "",
  key: "options",
  width: 60,
  className: "text-center",
  render: (value: any, record: WithId<Listing>) => (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Menu>
          <Menu.Item>
            <Link to={record._id}>
              See details&nbsp;&nbsp;
              <ArrowRightOutlined />
            </Link>
          </Menu.Item>
          {record.availability.active ? (
            <Menu.Item danger onClick={() => archiveListing(record)}>
              Hide
            </Menu.Item>
          ) : (
            <Menu.Item danger onClick={() => unArchiveListing(record)}>
              Unhide
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Button icon={<MoreOutlined />} />
    </Dropdown>
  ),
};

export const growerStockColumn = {
  title: "Stock",

  dataIndex: "quantity",
  key: "quantity",
  width: 120,
  className: "text-center",
  render: (_: any, record: WithId<Listing>) => (
    <GrowerQuantityWidget editable listing={record as WithId<GrowerListing>} />
  ),
  sorter: (a: any, b: any): any => b.availability.quantity - a.availability.quantity,
  sortDirections: ["descend", "ascend"] as any, // SortOrder from antd,
};

export const growerPriceColumn = {
  title: "Price",
  dataIndex: "price",
  key: "price",
  width: 80,
  className: "text-center",
  render: (_: any, record: WithId<Listing>) => (
    <GrowerPriceWidget editable listing={record as WithId<GrowerCutFlowerListing>} />
  ),
  sorter: (a: WithId<Listing>, b: WithId<Listing>): number => a.pricing.price - b.pricing.price,
  sortDirections: ["descend", "ascend"] as SortOrder[],
};

export const growerCutFlowerPriceColumn = {
  title: <>Price&nbsp;/ Stem</>,
  dataIndex: "price",
  key: "price",
  width: 80,
  className: "text-center",
  render: (_: any, listing: WithId<GrowerCutFlowerListing>) => (
    <GrowerPriceWidget editable listing={listing} />
  ),
  sorter: (a: WithId<GrowerCutFlowerListing>, b: WithId<GrowerCutFlowerListing>): any =>
    a.pricing.pricePerStem - b.pricing.pricePerStem,
  sortDirections: ["descend", "ascend"] as SortOrder[], // SortOrder from antd,
};

export const growerDriedFlowerTypeColumn = {
  title: "Type",
  key: "type",
  width: 80,
  className: "text-center",
  render: (_: any, listing: WithId<GrowerDriedFlowerListing>) =>
    getDriedFlowerProductTypeLabel(listing.product.type),
  sorter: (a: WithId<GrowerDriedFlowerListing>, b: WithId<GrowerDriedFlowerListing>) => {
    return a.product.type?.localeCompare(b.product.type);
  },
  sortDirections: ["descend", "ascend"] as SortOrder[], // SortOrder from antd,
};

export const makeGrowerDatesColumn = (
  allListings: WithId<GrowerListing>[] | undefined,
  title = "Availability"
) => {
  return {
    title: (
      <>
        {title}{" "}
        {allListings && (
          <GrowerBulkListingDatesWidget
            allListings={allListings as WithId<GrowerCutFlowerListing>[]}
          />
        )}
      </>
    ),
    dataIndex: "",
    key: "",
    width: 145,
    className: "text-center",
    render: (_: any, record: WithId<Listing>) => (
      <GrowerListingDatesWidget
        editable
        listing={record as WithId<GrowerCutFlowerListing>}
        allListings={allListings}
      />
    ),
  };
};
