import {
  assertRoleExists,
  calculateMarkedUpPricing,
  legalUnits,
  Listing,
  Pricing,
  UnitKey,
} from "@rooted/shared";
import { Typography } from "antd";
import React from "react";
import { useRooted } from "../../../RootedContext";
import { formatCurrency } from "../../FormControls/CurrencyInput";

/**
 * Read-only buyer price display.
 */
export const BuyerListingPriceWidget: React.FC<{
  listing: Listing;
  twoLine?: boolean;
  large?: boolean;
  alternateSecondLine?: boolean;
}> = ({ listing, twoLine, large, alternateSecondLine }) => {
  const { activeRole: role } = useRooted();
  assertRoleExists(role);

  const categorySettings =
    listing.category === "ad-hoc"
      ? {
          availableToRetail: true,
          availableToWholesale: true,
          availableToCoops: true,
          retailMarkup: 0,
        }
      : listing.seller._cache.categorySettings[listing.category];

  // This invariant isn't well maintained... we've had cases of
  // category settings not existing on the caches.
  if (!categorySettings) throw new Error("Category settings not found");

  const pricing = listing.pricing;

  const wholesalePricing = categorySettings.availableToWholesale ? (
    <PricingDisplay
      pricing={pricing}
      unit={listing.product.unit}
      twoLine={twoLine}
      large={large}
      alternateSecondLine={alternateSecondLine}
    />
  ) : (
    <Typography.Text type="secondary">Not available to wholesale buyers.</Typography.Text>
  );

  const retailPricing = categorySettings.availableToRetail ? (
    <PricingDisplay
      pricing={calculateMarkedUpPricing(listing.pricing, categorySettings.retailMarkup)}
      unit={listing.product.unit}
      twoLine={twoLine}
      large={large}
      alternateSecondLine={alternateSecondLine}
    />
  ) : (
    <Typography.Text type="secondary">Not available to retail buyers.</Typography.Text>
  );

  const dualPricing =
    categorySettings.retailMarkup > 0 ? (
      <>
        Wholesale: {wholesalePricing}
        <br />
        Retail: {retailPricing}
      </>
    ) : categorySettings.availableToWholesale ? (
      wholesalePricing
    ) : categorySettings.availableToRetail ? (
      retailPricing
    ) : (
      <Typography.Text type="secondary">
        Not available to retail or wholesale buyers.
      </Typography.Text>
    );

  // Listing creators see both prices
  if (role.profileId === listing.seller.profileId) {
    return dualPricing;
  }

  // Retail buyers see only the retail pricing
  else if (role.type === "retail-buyer") return retailPricing;
  // Coops and wholesale buyers see wholeale pricing
  else return wholesalePricing;
};

/**
 * Display the pricing for any product with the correct units.
 */
export const PricingDisplay: React.FC<{
  pricing: Pricing;
  unit: UnitKey;
  twoLine?: boolean;
  large?: boolean;
  alternateSecondLine?: boolean; // Whether to show the unit ratio instead of secondary price
}> = ({ pricing, unit, twoLine, large, alternateSecondLine }) => {
  const fullUnit = legalUnits.find((x) => unit === x.key)!;

  let topLine = "";
  let bottomLine = "";

  // Ugly -- early on we didn't include the stems per bunch in pricing.
  // We retrieve it this way but this is kind of gross
  // We need this value to ensure the marked up major unit price is an integer
  // multiple of the marked up minor unit price.
  const quantity = pricing.pricePerStem && Math.round(pricing.price / pricing.pricePerStem!);

  if (pricing.pricePerStem !== undefined) {
    if (pricing.prioritizeMajorUnit) {
      topLine = `${formatCurrency(pricing.price)} ${fullUnit.name && ` / ${fullUnit.name}`}`;
      bottomLine = alternateSecondLine
        ? `${formatCurrency(pricing.pricePerStem)} ${
            fullUnit.minorUnitName && ` / ${fullUnit.minorUnitName}`
          }`
        : quantity
        ? `${quantity} ${quantity === 1 ? fullUnit.minorUnitName : fullUnit.minotUnitPluralName}${
            fullUnit.name && ` / ${fullUnit.name}`
          }`
        : "";
    } else {
      topLine = `${formatCurrency(pricing.pricePerStem)} ${
        fullUnit.minorUnitName && ` / ${fullUnit.minorUnitName}`
      }`;
      bottomLine = alternateSecondLine
        ? `${formatCurrency(pricing.price)} ${fullUnit.name && ` / ${fullUnit.name}`}`
        : quantity
        ? `${quantity} ${quantity === 1 ? fullUnit.minorUnitName : fullUnit.minotUnitPluralName}${
            fullUnit.name && ` / ${fullUnit.name}`
          }`
        : "";
    }
  } else {
    topLine = `${formatCurrency(pricing.price)} ${fullUnit.name && ` / ${fullUnit.name}`}`;
  }

  if (large)
    return (
      <>
        <Typography.Title level={3} style={{ marginBottom: 4 }}>
          {topLine}
        </Typography.Title>
        {twoLine ? <br /> : <>&nbsp;&nbsp;</>}
        <Typography.Text type="secondary">{bottomLine}</Typography.Text>
      </>
    );
  else
    return (
      <>
        {topLine}
        {twoLine ? <br /> : <>&nbsp;&nbsp;</>}
        {bottomLine.length > 0 ? `(${bottomLine})` : null}
      </>
    );
};
