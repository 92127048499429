import { WarningOutlined } from "@ant-design/icons";
import { firestoreToMoment, legalProfileTypes, PublicGlobalSettings } from "@rooted/shared";
import { Card, Checkbox, DatePicker, Divider, Form, Input, Row } from "antd";
import { PickerProps } from "antd/lib/date-picker/generatePicker";
import firebase from "firebase/app";
import React from "react";
import { FirestoreDocForm } from "../../../components/FormControls/FirestoreDocForm";
import { PageHeader } from "../../../components/PageHeader";
import { db, useDocumentDataChecked } from "../../../services/firebase";
import { momentToFirestore } from "../../../utils/dates";
import { version } from "../../../utils/env";

export const OverviewPane: React.FC = () => {
  const [publicSettings] = useDocumentDataChecked<PublicGlobalSettings>(
    db.collection("global").doc("public")
  );

  return (
    <>
      <PageHeader title={"Overview"} breadcrumb />

      <Card title="Environment">
        <table>
          <tbody>
            <tr>
              <th>Public URL: </th>
              <td>{process.env.REACT_APP_BASE_URL}</td>
            </tr>
            <tr>
              <th>Version:</th>
              <td>{version}</td>
            </tr>
            <tr>
              <th>Git SHA:</th>
              <td>{process.env.REACT_APP_GIT_SHA}</td>
            </tr>
            <tr>
              <th>Database URL:</th>
              <td>{process.env.REACT_APP_DATABASE_URL}</td>
            </tr>
            <tr>
              {/* Longest label gets the extra padding */}
              <th style={{ paddingRight: 8 }}>Storage Bucket:</th>
              <td>{process.env.REACT_APP_STORAGE_BUCKET}</td>
            </tr>
          </tbody>
        </table>
      </Card>

      <br />

      {publicSettings && (
        <Card
          bodyStyle={{ paddingBottom: 0, marginBottom: 0 }}
          style={{ paddingBottom: 0 }}
          title={
            <>
              <WarningOutlined style={{ color: "red" }} /> Public Settings
            </>
          }
        >
          <FirestoreDocForm data={publicSettings} dbRef={db.collection("global").doc("public")}>
            <p style={{ color: "red" }}>Make sure you mean to do this!</p>
            <Form.Item valuePropName="checked" name="downForMaintenance">
              <Checkbox>Down for maintenance</Checkbox>
            </Form.Item>

            <Divider />

            {/* This is added ~July 12th. TODO: remove after clients have phased off of old versions using this. */}
            <Form.Item name="bannerMessage" label="Banner Message (Deprecated, old client support)">
              <Input />
            </Form.Item>

            <Form.Item name="bannerMessageMarkdown" label="Banner Message (Markdown)">
              <Input />
            </Form.Item>

            <Form.Item name="bannerVisibility" label="Banner Visibility">
              <Checkbox.Group>
                {Object.entries({
                  guest: { name: "Guest (not logged in / no profile)" },
                  ...legalProfileTypes,
                }).map(([profileType, { name }]) => (
                  <Row key={profileType}>
                    <Checkbox value={profileType} style={{ lineHeight: "32px" }}>
                      {name}
                    </Checkbox>
                  </Row>
                ))}
              </Checkbox.Group>
            </Form.Item>

            <Divider />
          </FirestoreDocForm>
        </Card>
      )}
    </>
  );
};

export const FirestoreDateTimePicker: React.FC<PickerProps<firebase.firestore.Timestamp>> = ({
  value,
  onChange,
  ...rest
}) => {
  return (
    <DatePicker
      use12Hours
      showTime
      value={value ? firestoreToMoment(value) : value}
      onChange={(v) =>
        onChange?.(v ? momentToFirestore(v) : v, v?.format("MMMM Do YYYY, h:mm a") || "")
      }
    />
  );
};

export const FirestoreDatePicker: React.FC<PickerProps<firebase.firestore.Timestamp>> = ({
  value,
  onChange,
  ...rest
}) => {
  return (
    <DatePicker
      use12Hours
      value={value ? firestoreToMoment(value) : value}
      onChange={(v) => onChange?.(v ? momentToFirestore(v) : v, v?.format("MMMM Do YYYY") || "")}
    />
  );
};
