import {
  ArrowRightOutlined,
  MailOutlined,
  PhoneOutlined,
  FacebookOutlined,
  InstagramOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import {
  EnterpriseProfile,
  EnterpriseProfileBio,
  GrowerProfile,
  makeProfileRoute,
  normalizeValue,
  Profile,
} from "@rooted/shared";
import {
  Alert,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Tag,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FirestoreDocForm } from "../../../components/FormControls/FirestoreDocForm";
import { MediaLibrarySelect } from "../../../components/FormControls/MediaLibrarySelect";
import { PageHeader } from "../../../components/PageHeader";
import { useRooted } from "../../../RootedContext";
import { db } from "../../../services/firebase";

import { CopyCode } from "../../../components/Misc/CopyCode";
import { Info as LuxonInfo } from "luxon";

export const EnterpriseProfilePane: React.FC = () => {
  const { activeRole: role } = useRooted();
  if (role?.type !== "grower" && role?.type !== "coop" && role?.type !== "wholesale-buyer")
    throw new Error(`Unsupported role type '${role?.type || "no-role"}'`);

  const [profileRef] = useState(db.collection("profiles").doc(role.profileId));
  const profileRoute = makeProfileRoute(role.profile);

  return (
    <>
      <PageHeader
        title={"Profile"}
        extra={
          <Link to={profileRoute}>
            Public Profile View
            <ArrowRightOutlined />
          </Link>
        }
      />
      {!role.profile.bio.phoneNumber ||
        (!role.profile.bio.email && (
          <Alert
            style={{ marginBottom: 16 }}
            type="warning"
            showIcon
            message={
              <Typography.Text>
                You're missing contact info which is needed for orders. Please ensure your{" "}
                <a href="#contact-info">phone number and email</a> are correct.
              </Typography.Text>
            }
          />
        ))}
      {isMissingGrowerExtraFields(role.profile) && (
        <Alert
          style={{ marginBottom: 16 }}
          type="warning"
          showIcon
          message={
            <Typography.Text>
              You're missing <a href="#growing-information">growing information</a> on your profile.
              Fill this in to let your buyers know more about your farm!
            </Typography.Text>
          }
        />
      )}
      <Card
        style={{ marginBottom: 12 }}
        title={
          <div style={{ whiteSpace: "normal" }}>
            <Typography.Text style={{ fontSize: 20, marginBottom: 0 }}>
              Public Profile Information
            </Typography.Text>
            <Typography.Paragraph type="secondary">
              This information will be be displayed on your{" "}
              <Link to={profileRoute}>public profile page</Link>.
            </Typography.Paragraph>
          </div>
        }
      >
        <FirestoreDocForm
          scrollToTop={false}
          rootFieldPath="bio"
          data={role.profile.bio}
          dbRef={profileRef}
          layout="vertical"
          requiredMark={true}
          replaceOnSubmit={(values: EnterpriseProfileBio): EnterpriseProfileBio => {
            // add normalized displayName for querying to bio model
            return {
              ...values,
              _displayNameLowerCase: normalizeValue(values.displayName)!,
            };
          }}
        >
          <Form.Item name="photoStorageUrl" label="Photo">
            <MediaLibrarySelect />
          </Form.Item>

          <Form.Item
            name="displayName"
            label="Display Name"
            rules={[{ required: true, message: "Display Name is required" }]}
          >
            <Input type="text"></Input>
          </Form.Item>

          <Form.Item
            name="description"
            rules={[{ required: true, message: "Description is required" }]}
            label="Description"
          >
            <Input.TextArea rows={5}></Input.TextArea>
          </Form.Item>

          <Form.Item
            name="websiteUrl"
            label={
              <>
                <LinkOutlined style={{ paddingRight: 4 }} />
                Website Link
              </>
            }
          >
            <Input placeholder="https://www.my-awesome-website.com" />
          </Form.Item>

          <Form.Item
            name="instagramUrl"
            label={
              <>
                <InstagramOutlined style={{ paddingRight: 4 }} />
                Instagram Link
              </>
            }
          >
            <Input placeholder="https://www.instagram.com/flowers-are-so-cool" />
          </Form.Item>

          <Form.Item
            name="facebookUrl"
            label={
              <>
                <FacebookOutlined style={{ paddingRight: 4 }} />
                Facebook Link
              </>
            }
          >
            <Input placeholder="https://www.facebook.com/flowers-are-very-neat" />
          </Form.Item>
          {role.type === "grower" && renderGrowerDetails(role.profile)}
        </FirestoreDocForm>
        <Divider />
        <Typography.Text>Shareable Profile Link:</Typography.Text>
        <br />
        <CopyCode>{`${process.env.REACT_APP_BASE_URL}${profileRoute}`}</CopyCode>
      </Card>

      <Card
        bodyStyle={{ paddingBottom: 0 }}
        title={
          <div style={{ whiteSpace: "normal" }}>
            <Typography.Text id={"contact-info"} style={{ fontSize: 20, marginBottom: 0 }}>
              Contact Information
            </Typography.Text>
            <Typography.Paragraph type="secondary">
              This information is used for placing orders.
            </Typography.Paragraph>
          </div>
        }
      >
        <FirestoreDocForm
          scrollToTop={false}
          rootFieldPath="bio"
          data={role.profile.bio}
          dbRef={profileRef}
          layout="vertical"
          requiredMark={true}
        >
          <Form.Item
            name="phoneNumber"
            label={
              <>
                <PhoneOutlined style={{ paddingRight: 4 }} />
                Phone Number
              </>
            }
            rules={[
              // Some decent sanity checks. TODO: make this robust.
              { min: 10, max: 18, required: true, message: "Please provide your phone number." },
            ]}
          >
            <Input type="tel" />
          </Form.Item>

          <Form.Item
            name="email"
            label={
              <>
                <MailOutlined style={{ paddingRight: 4 }} />
                Email
              </>
            }
            rules={[
              {
                required: true,
                type: "email",
                message: "Please provide a valid email",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
        </FirestoreDocForm>
      </Card>

      {role.type === "coop" && (
        <Card title="Coop Pricing Settings">
          <FirestoreDocForm
            data={role.profile}
            dbRef={db.collection("profiles").doc(role.profileId)}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item name={["fulfillmentSettings", "handlingFeePercent"]} label="Handling Fee">
              <InputNumber
                min={0}
                max={100}
                step={1}
                precision={0}
                formatter={(value) => `${value}%`}
                parser={(value) => value?.replace("%", "") || 0}
              />
            </Form.Item>
          </FirestoreDocForm>
        </Card>
      )}
    </>
  );
};

export const isMissingGrowerExtraFields = (profile: Profile) => {
  if (profile.type !== "grower") return false;
  const { bio } = profile;
  return (
    !bio.growerExtra ||
    undefined === bio.growerExtra.typicalDeliveryDays ||
    undefined === bio.growerExtra.typicalInventoryUpdateDays ||
    undefined === bio.growerExtra.typicalProductionMonths ||
    undefined === bio.growerExtra.typicallyOffersFarmPickUp
  );
};

const renderGrowerDetails = (profile: GrowerProfile) => (
  <>
    <Typography.Text type="secondary" style={{ fontSize: 18 }} id="growing-information">
      Growing Information
    </Typography.Text>
    <br />

    <Typography.Text type="secondary" style={{ fontSize: 14 }}>
      This info will help buyers know more about you product schedules.
    </Typography.Text>
    <br />

    <Form.Item
      name={["growerExtra", "typicalProductionMonths"]}
      label="What months do you produce flowers?"
    >
      <MonthDropdownSelect />
    </Form.Item>
    <Form.Item
      name={["growerExtra", "typicalInventoryUpdateDays"]}
      label="What days do you typically update your inventory?"
    >
      <WeekdayDropdownSelect />
    </Form.Item>
    <Form.Item
      name={["growerExtra", "typicalDeliveryDays"]}
      label="What days do you typically do deliveries?"
    >
      <WeekdayDropdownSelect />
    </Form.Item>
    <Form.Item
      name={["growerExtra", "typicallyOffersFarmPickUp"]}
      label="Do you typically offer farm pick up?"
    >
      <Radio.Group>
        <Radio value={true}>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
    </Form.Item>
  </>
);

// Helper for avoiding triggering antd thinking that the form needs updating.
function areArraysShallowlyEqual(a?: string[], b?: string[]): boolean {
  if (!a || !b) return false;
  return a.length === b.length && a.every((_, i) => b[i] !== undefined && b[i] === a[i]);
}

const CreateOrderMaintainingSelect = <T extends string = string>(
  options: T[]
): React.FC<{
  value?: T[] | undefined;
  onChange?: (newSelections: T[] | undefined) => void;
}> => {
  const memoizedOptions = options.map((key) => ({
    key,
    value: key,
  }));

  return function OrderMaintainingSelect({ value, onChange: onChangeExternal }) {
    const [selection, setSelection] = useState<T[]>(value ?? []);
    const onChange = useCallback((changedSelection: T[]) => {
      const newSelection = [...changedSelection];
      newSelection.sort((a, b) => options.indexOf(a) - options.indexOf(b));
      setSelection(newSelection);
    }, []);

    // Don't trigger the `onChangeExternal` on first render,
    // because this make the parent form thing there is a change needed.
    // That causes the form to allow the "Save" button to be active,
    // and also will prompt for unsaved changes on page change
    const firstRender = useRef(true);
    useEffect(() => {
      if (firstRender.current) {
        firstRender.current = false;
        return;
      }
      if (areArraysShallowlyEqual(selection, value)) return;
      onChangeExternal?.(selection);
    }, [onChangeExternal, selection, value]);

    return (
      <Select
        mode="multiple"
        allowClear
        value={selection}
        onChange={onChange}
        options={memoizedOptions}
      />
    );
  };
};

const WeekdayDropdownSelect = CreateOrderMaintainingSelect(LuxonInfo.weekdays());
const MonthDropdownSelect = CreateOrderMaintainingSelect(LuxonInfo.months());
