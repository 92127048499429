import {
  allItems,
  ApprovedConnection,
  Connection,
  CurrentCart,
  CustomFulfillmentOption,
  DayOfWeek,
  distance,
  FulfillmentOption,
  FullBuyerRole,
  FullRole,
  isSeller,
  Listing,
  ListingQueryContstraints,
  ListingWithFulfillment,
  Location,
  PhysicalFulfillmentOption,
  RealListing,
  RealRelistingOption,
  Relisting,
  RelistingGeneric,
  SellerProfile,
  WithId,
} from "@rooted/shared";
import firebase from "firebase/app";
import { DateTime } from "luxon";
import moment from "moment";
import { IOccurrencesArgs, Schedule } from "./rschedule";

export function makeWeekdaySchedule(x: DayOfWeek[]): Schedule {
  return new Schedule({
    rrules:
      x.length > 0
        ? [
            {
              // Needs to start on some arbitary date before any rooted activity
              // has occurred.
              // The real date range will be constrained later with `occurences(...)`
              start: DateTime.fromISO("2011-10-05T14:48:00.000Z"),
              frequency: "WEEKLY",
              byDayOfWeek: x,
            },
          ]
        : [],
  });
}

export function makeDefaultListingQueryConstraints(): ListingQueryContstraints {
  return {
    type: "all",
    dateRange: {
      start: firebase.firestore.Timestamp.now(),
      end: firebase.firestore.Timestamp.fromMillis(Date.now() + 999999999),
    },
    delivery: {
      enabled: true,
    },
    pickup: {
      enabled: true,
      maxDistance: 9999,
    },
    shipping: {
      enabled: true,
    },
    excludedProfileIds: [],
    showZeroQuantity: false,
  };
}
/** Generate all fulfillment options for a set of listings, optionally removing
 * those with no valid fulfillment options.
 */

export function materializeFullfillmentOptions(
  role: FullBuyerRole,
  listings: WithId<RealListing>[],
  connections: Connection[],
  constraints: ListingQueryContstraints,
  cart?: CurrentCart,
  options?: {
    removeUnpurchasable?: boolean;
    maxCountPerItem?: number;
  }
) {
  return listings
    .map((listing) => {
      const applicableConnection = connections.find((x) => x.sellerId === listing.seller.profileId);
      const res: ListingWithFulfillment = {
        listing,
        fulfillmentOptions: makeFulfillmentOptions(
          role,
          listing,
          applicableConnection?.status === "approved" ? applicableConnection : undefined,
          constraints,
          cart?.address,
          options?.maxCountPerItem || 3
        ),
      };
      return res;
    })
    .filter((listing) =>
      options?.removeUnpurchasable === false ? true : listing.fulfillmentOptions.length > 0
    )
    .filter((listing) =>
      constraints.showZeroQuantity
        ? true
        : listing.listing.availability.quantity > 0 ||
          (cart &&
            cart.status === "active" &&
            allItems(cart).some((x) => x.listing._id === listing.listing._id))
    );
}

export function makeFulfillmentOptions(
  role: FullRole,
  listing: WithId<RealListing>,
  connection: Connection | undefined,
  constraints: ListingQueryContstraints,
  location: Location | undefined,
  maxCount = 3
) {
  if (isSeller(role)) return [];

  const applicableConnection = connection?.status === "approved" ? connection : undefined;

  if (listing.availability.type === "physical")
    return makePhysicalFulfillmentOptions(
      role,
      listing,
      applicableConnection,
      constraints,
      location,
      maxCount
    );
  else if (listing.availability.type === "custom") {
    return makeCustomFulfillmentOptions(
      role,
      listing,
      applicableConnection,
      constraints,
      location,
      maxCount
    );
  } else return [];
}
/**
 * Make the list of fulfillment options for a single listing.
 */

export function makePhysicalFulfillmentOptions(
  role: FullBuyerRole,
  listing: (RealListing & { backingListing?: undefined }) | Relisting,
  userConnection: ApprovedConnection | undefined,
  constraints: ListingQueryContstraints,
  location: Location | undefined,
  maxCount = 3
) {
  if (!location) return [];
  const seller = listing.seller._cache;

  const isRetail = role.type === "retail-buyer";

  const connection = isRetail ? listing.seller._cache._retailConnectionCache! : userConnection;

  // If the connection doesn't have ordering enabled, there are no valid fulfillment options
  if (!connection || !connection.orderingEnabled) return [];

  const categorySettings = listing.seller._cache.categorySettings[listing.category];

  if (listing.availability.type !== "physical") throw new Error();

  // If the category isn't enabled for this type of seller, there are no valid fulfillment options
  if (isRetail && !categorySettings?.availableToRetail) return [];
  if (!isRetail && !categorySettings?.availableToWholesale) return [];

  // If the connection doesn't have ordering enabled, there are no valid fulfillment options
  if (!connection.orderingEnabled) return [];

  const zipCodeWithinRetailRange = seller.fulfillmentSettings.delivery.zipCodes.includes(
    location.streetAddress.zip
  );

  const deliveryAllowed =
    constraints.delivery.enabled &&
    connection.deliverySettings.enabled &&
    ((isRetail && zipCodeWithinRetailRange) || !isRetail);

  const pickupAllowed =
    constraints.pickup.enabled &&
    connection.pickupSettings.enabled &&
    listing.seller?._cache.bio?.location?.geopoint &&
    location?.geopoint &&
    distance(listing.seller._cache.bio.location, location) <= constraints.pickup.maxDistance;

  const { startDate, endDate } = listing.availability;
  const availabilityDatesSet = startDate && endDate;

  // Find the range of time allowed by
  // (1) the product's availability
  // (2) the query's time constraints
  const latestStartDate = Math.max(
    constraints.dateRange.start.toMillis(),
    startDate?.toMillis() || 0,
    Date.now()
  );

  const earliestEndDate = Math.min(
    constraints.dateRange.end.toMillis(),
    endDate?.toMillis() || Number.POSITIVE_INFINITY
  );

  const luxonDateRange: IOccurrencesArgs = {
    // Do not take any dates if availability dates are not set
    take: availabilityDatesSet ? maxCount : 0,
    start: DateTime.fromMillis(latestStartDate).startOf("day"),
    end: DateTime.fromMillis(earliestEndDate).endOf("day"),
  };

  let deliveryWeekdays: DayOfWeek[];
  let pickupWeekdays: DayOfWeek[];

  if (listing.backingListing) {
    deliveryWeekdays = connection.deliverySettings.weekdays.filter((x) =>
      listing.relistingMetaData.membershipDays.includes(x)
    );
    pickupWeekdays = connection.pickupSettings.weekdays.filter((x) =>
      listing.relistingMetaData.membershipDays.includes(x)
    );
  } else {
    deliveryWeekdays = connection.deliverySettings.weekdays;
    pickupWeekdays = connection.pickupSettings.weekdays;
  }

  const deliverySchedule = makeWeekdaySchedule(deliveryWeekdays);
  const pickupSchedule = makeWeekdaySchedule(pickupWeekdays);

  const deliveryOptions: PhysicalFulfillmentOption[] = deliveryAllowed
    ? deliverySchedule
        .occurrences(luxonDateRange)
        .toArray()
        .map((x) => {
          return {
            location: location,
            type: "physical",
            modality: "delivery",
            date: x.date.toMillis(),
            duration: x.duration,
            profileId: listing.seller.profileId,
            // Take handling fee directly from profile
            // (same for all orders for now)
            handlingFeePercent: listing.seller._cache.fulfillmentSettings.handlingFeePercent,
            price: connection.deliverySettings.price,
          };
        })
    : [];

  const pickupOptions: PhysicalFulfillmentOption[] = pickupAllowed
    ? pickupSchedule
        .occurrences(luxonDateRange)
        .toArray()
        .map((x) => {
          return {
            location: seller.bio.location,
            type: "physical",
            modality: "pickup",
            duration: x.duration,
            date: x.date.toMillis(),
            profileId: listing.seller.profileId,
            // Take handling fee directly from profile
            // (same for all orders for now)
            handlingFeePercent: listing.seller._cache.fulfillmentSettings.handlingFeePercent,
            price: 0, //Pickup is free
          };
        })
    : [];

  const shippingOptions: PhysicalFulfillmentOption[] = constraints.shipping.enabled
    ? [
        /* Example option for testing UI -- removed for now
{
  modality: "shipping",
  type: "physical",
  date: Date.now(),
  price: 1000,
  location: listing.seller._cache.bio.location,
  profileId: listing.seller.profileId,
},*/
      ]
    : []; //TODO

  // Now, try to pick a representative sample of the available options
  let results: PhysicalFulfillmentOption[] = [
    ...shippingOptions,
    ...pickupOptions,
    ...deliveryOptions,
  ];

  results = results.sort((a, b) => a.date - b.date).slice(0, maxCount);
  return results;
}
/**
 * Make the list of custom fulfillment options.
 */

export function makeCustomFulfillmentOptions(
  role: FullBuyerRole,
  listing: WithId<RealListing>,
  userConnection: ApprovedConnection | undefined,
  constraints: ListingQueryContstraints,
  location: Location | undefined,
  maxCount = 3
) {
  if (!location) return [];

  const isRetail = role.type === "retail-buyer";

  const connection = isRetail ? listing.seller._cache._retailConnectionCache! : userConnection;

  // If the connection doesn't have ordering enabled, there are no valid fulfillment options
  if (!connection || !connection.orderingEnabled) return [];

  const categorySettings = listing.seller._cache.categorySettings[listing.category];

  if (listing.availability.type !== "custom")
    throw new Error(`Invalid availability list type: + ${listing.availability.type}`);

  // If the category isn't enabled for this type of seller, there are no valid fulfillment options
  if (isRetail && !categorySettings?.availableToRetail) return [];
  if (!isRetail && !categorySettings?.availableToWholesale) return [];

  // Filter the pickup distance
  const pickupAllowed =
    constraints.pickup.enabled &&
    listing.seller._cache.bio?.location?.geopoint &&
    location?.geopoint &&
    distance(listing.seller._cache.bio.location, location) <= constraints.pickup.maxDistance;

  const results: CustomFulfillmentOption[] = pickupAllowed
    ? [
        {
          location: location,
          type: "custom",
          description: listing.availability.description,
          shortName: listing.availability.shortName,
          profileId: listing.seller.profileId,
          price: 0,
          id: listing._id,
        },
      ]
    : [];

  return results.slice(0, maxCount);
}
/**
 * TODO
 */

export function isValidPhysicalFulfillmentOptions(
  listing: Listing,
  seller: WithId<SellerProfile>,
  wholesaleConnection: SellerProfile,
  location: Location
) {
  return true;
  //TODO
}

export function stringifyFulfillment(x: FulfillmentOption) {
  if (x.type === "physical") {
    const modality = x.modality[0].toUpperCase() + x.modality.slice(1);
    const date = moment(x.date).format("M/D");
    return `${modality} on ${date}`;
  } else if (x.type === "digital") return "Digital good";
  else if (x.type === "custom") return x.shortName;
  else if (x.type === "event") return "Event";
  else if (x.type === "pos") return "Point of Sale";
  else if (x.type === "subscription") return "Subscription";
  else return "unknown"; // Should never happen but needed for type check
}
