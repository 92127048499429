import {
  getProductCategoryBySlug,
  isRelistingCategory,
  realProductCategories,
  RealProductCategoryKey,
} from "@rooted/shared";
import { Card, Menu } from "antd";
import React from "react";
import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import { PageHeader } from "../../../components/PageHeader";
import { ProductGrid } from "../../../components/ProductGrid";
import { CollapsingSider } from "../../../components/Sider/CollapsingSider";
import { NotFoundPage, PageContent, WideLayout } from "../../../views/layouts";
import { CoopInventoryOverview } from "./InventoryOverview";

const coopCategories: RealProductCategoryKey[] = [
  "cut-flower",
  "bucket",
  "bouquet",
  "dried-flower",
  "wreath",
  "potted-plant",
  "csa",
];

export const InventoryOverviewByCategory: React.FC = () => {
  const { slug } = useParams();
  const category = getProductCategoryBySlug(slug);

  if (!category || !isRelistingCategory(category.id)) {
    return <NotFoundPage />;
  }

  return <CoopInventoryOverview category={category.id} />;
};

export const CoopInventory: React.FC = () => {
  const category = useLocation().pathname.split("/")[2] || "overview";

  return (
    <>
      <WideLayout>
        <CollapsingSider
          smallDrawerLabel={{ selection: category, title: "Product Type" }}
          hideIfSmall={category === "overview"}
          selectedKeys={[category]}
        >
          {coopCategories.map((category) => (
            // URL uses plural, so this makes the highlight work
            <Menu.Item key={realProductCategories[category].slug}>
              <Link to={realProductCategories[category].slug}>
                {realProductCategories[category].pluralName}{" "}
              </Link>
            </Menu.Item>
          ))}
        </CollapsingSider>
        <PageContent>
          <Routes>
            <Route path=":slug" element={<InventoryOverviewByCategory />} />
            <Route path="" element={<InventoryOverview />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </PageContent>
      </WideLayout>
    </>
  );
};

export const InventoryOverview: React.FC = () => {
  return (
    <>
      <PageHeader title="Inventory"></PageHeader>
      <ProductGrid>
        {coopCategories.map((category) => (
          <Link
            key={category}
            to={realProductCategories[category].slug}
            style={{ display: "inline-block" }}
          >
            <Card
              className="overview-card"
              style={{
                backgroundImage: `url("/images/${category}.jpg")`,
              }}
            >
              <div className="overview-card-overlay">
                {realProductCategories[category].pluralName}
              </div>
            </Card>
          </Link>
        ))}
      </ProductGrid>
    </>
  );
};
