import React from "react";
import { Button, Card, Col, Row, Typography } from "antd";
import { formatCurrency } from "../FormControls/CurrencyInput";
import { useSubscriptionPlans } from "../../services/sellers/subscriptions";
import { LoadingPage } from "../../views/layouts";

export const SubscriptionPicker: React.FC<{
  type: "grower" | "coop";
  constraints?: {
    ascfg?: boolean;
    slowFlowers?: boolean;
  };
  onSelect?: (x: string) => void;
  selected?: string;
  loadingId?: string;
  columnSpan?: number;
  disabled?: boolean;
}> = ({ type, onSelect, selected, loadingId, constraints, columnSpan = 8, disabled = false }) => {
  const [subscriptionPlans] = useSubscriptionPlans(type);

  const filteredSubscriptions = subscriptionPlans?.filter((x) => x.group === "all");

  // Show a hidden plan if it's active
  if (filteredSubscriptions && !filteredSubscriptions.some((x) => x._id === selected)) {
    const selectedPlan = subscriptionPlans?.find((x) => x._id === selected);
    selectedPlan && filteredSubscriptions.push(selectedPlan);
  }

  if (!filteredSubscriptions) return <LoadingPage />;

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        {filteredSubscriptions.map((x) => (
          <Col key={x._id} xs={24} sm={12} md={columnSpan}>
            <Card
              title={
                <>
                  <Typography.Title level={5} style={{ whiteSpace: "normal" }}>
                    {x.name}
                  </Typography.Title>{" "}
                  {formatCurrency(x.price)} / year
                </>
              }
              style={{ marginBottom: 16, borderColor: selected === x._id ? "#067d7f" : undefined }}
              headStyle={{ textAlign: "center" }}
              bodyStyle={{ minHeight: "200px" }}
              actions={[
                <Button
                  key="set-selected"
                  onClick={() => onSelect?.(x._id)}
                  type={"primary"}
                  disabled={selected === x._id || disabled}
                >
                  {selected === x._id ? "Selected" : `Select`}
                </Button>,
              ]}
            >
              {x.description}
              <br />
              <br />
              <ul>
                {x.benefits.map((b, i) => (
                  <li key={`${i.toString()}: ${b}`}>{b}</li>
                ))}
              </ul>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};
