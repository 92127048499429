import { Form, Select, Input } from "antd";
import React, { useState } from "react";

export const HowDidYouHear: React.FC<any> = ({ disabled = false, adminView = false }) => {
  const [val, setVal] = useState<string>("");

  const onSelect = (val: string) => {
    setVal(val);
  };

  return (
    <>
      <Form.Item
        name={["howDidYouHear", "type"]}
        label="How did you hear about Rooted Farmers?"
        rules={[{ required: true, message: " " }]}
        requiredMark
      >
        <Select disabled={disabled} onSelect={onSelect} defaultValue="-- Choose One --">
          <Select.Option value="slow-flowers-podcast">Slow Flowers Podcast</Select.Option>
          <Select.Option value="ascfg-ad">ASCFG Ad</Select.Option>
          <Select.Option value="referral">Referral</Select.Option>
          <Select.Option value="other">Other</Select.Option>
        </Select>
      </Form.Item>

      {(val === "referral" || adminView) && (
        <Form.Item
          name={["howDidYouHear", "referral"]}
          label="Referral"
          requiredMark
          initialValue=""
          rules={adminView ? [] : [{ required: true, message: " " }]}
        >
          <Input disabled={disabled} />
        </Form.Item>
      )}

      {(val === "other" || adminView) && (
        <Form.Item
          name={["howDidYouHear", "other"]}
          label="Please specify"
          requiredMark
          initialValue=""
          rules={adminView ? [] : [{ required: true, message: " " }]}
        >
          <Input disabled={disabled} />
        </Form.Item>
      )}
    </>
  );
};
