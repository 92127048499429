import { makeName, Role, RootedUser, WithId } from "@rooted/shared";

import { Button, List, Spin, Modal, Tag } from "antd";
import { ListItemMetaProps } from "antd/lib/list";
import React from "react";
import { PageHeader } from "../../../components/PageHeader";
import { useRooted } from "../../../RootedContext";
import { db, useDocumentDataChecked } from "../../../services/firebase";
import { useAdminRoles } from "../../../services/roles";
import { AddAdmin } from "./AddAdmin";

export const AdminsPane: React.FC = () => {
  const { isAdmin } = useRooted();
  if (!isAdmin) throw new Error("Permission Denied");

  const [admins, adminsLoading] = useAdminRoles();
  const confirmRemoveAdmin = (role: WithId<Role>) => {
    Modal.confirm({
      icon: null,
      title: (
        <>
          Remove <UserName userId={role.userId} />
        </>
      ),
      content: (
        <>
          Are you sure you want to remove{" "}
          <b>
            <UserName userId={role.userId} />
          </b>{" "}
          as an admin?
        </>
      ),
      onOk: () => {
        db.collection("roles").doc(role._id).delete();
      },
    });
  };

  const showAddAdminModal = () => {
    Modal.success({
      icon: null,
      title: <>Add Admin</>,
      content: <>{<AddAdmin />}</>,
    });
  };

  return (
    <>
      <PageHeader
        title={"Admins"}
        breadcrumb
        extra={
          <Button type="primary" onClick={showAddAdminModal}>
            Add Admin
          </Button>
        }
      />
      <List
        bordered
        dataSource={admins}
        renderItem={(item) => (
          <List.Item key={item._id}>
            <UserListMeta userId={item.userId} tag={item.permissionLevel} />
            <Button type="ghost" onClick={() => confirmRemoveAdmin(item)}>
              Remove
            </Button>
          </List.Item>
        )}
      >
        {adminsLoading && <Spin />}
      </List>
      <br />
    </>
  );
};

export interface UserListMetaProps extends ListItemMetaProps {
  userId: string;
  tag?: string;
}

export const UserListMeta: React.FC<UserListMetaProps> = ({ userId, tag, ...props }) => {
  const [user] = useDocumentDataChecked<RootedUser>(db.collection("users").doc(userId));

  return (
    <List.Item.Meta
      title={
        <>
          {user && makeName(user)} {tag && <Tag>{tag}</Tag>}
        </>
      }
      description={user?.email}
    />
  );
};

export const UserName: React.FC<{ userId: string }> = ({ userId }) => {
  const [user] = useDocumentDataChecked<RootedUser>(db.collection("users").doc(userId));

  return <>{user ? makeName(user) : "..."}</>;
};

const AddRoleForm = () => {
  return "";
};
