import { QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";
import {
  ApprovedCoopMembership,
  assertIsSeller,
  asyncPool,
  CoopProfile,
  FullGrowerRole,
  RealProductCategoryKey,
} from "@rooted/shared";
import { Card, Col, Form, InputNumber, Row, Skeleton, Tooltip } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import React, { useEffect, useState } from "react";
import { FirestoreDocForm } from "../../components/FormControls/FirestoreDocForm";
import { useRooted } from "../../RootedContext";
import { logError } from "../../sentry";
import { db, querySnapshotToIdDocs, snapshotToIdDoc } from "../../services/firebase";

async function getGrowerCoopMemberships(growerProfileId: string) {
  const memberships = querySnapshotToIdDocs<ApprovedCoopMembership>(
    await db
      .collection("coopMemberships")
      .where("growerId", "==", growerProfileId)
      .where("status", "==", "approved")
      .get()
  );

  return await asyncPool(25, memberships, async (membership) => {
    const coopProfile = snapshotToIdDoc<CoopProfile>(
      await db.collection("profiles").doc(membership.coopId).get()
    );
    return { membership, coopProfile };
  });
}

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;
function isDefined<T>(item: T | undefined): item is T {
  return !!item;
}

const AvailableToCoopsCheckbox: React.FC<{
  checked?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
  title: string;
}> = ({ checked, onChange, title }) => {
  return (
    <>
      <Checkbox
        checked={checked}
        onChange={(e) => {
          onChange?.(e);
        }}
        style={{
          border: checked ? undefined : "1px solid red",
          paddingLeft: 4,
          paddingRight: 4,
          marginRight: !checked ? 4 : 0,
        }}
      />
      {title}{" "}
      <Tooltip
        color={!checked ? "red" : undefined}
        trigger={["hover", "click"]}
        title={
          checked
            ? "Your collective may apply different visibility and markup settings for your products"
            : "Heads up: products in this category will not be visible to the collective or any buyers through the collective"
        }
      >
        {checked && <QuestionCircleOutlined style={{ color: "grap" }} />}
        {!checked && <WarningOutlined style={{ color: "red" }} />}
      </Tooltip>
    </>
  );
};

const GrowerAvailableToCoopsItem: React.FC<{
  role: FullGrowerRole;
  category: RealProductCategoryKey;
}> = ({ role, category }) => {
  const [memberships = [], setMemberships] = useState<
    ThenArg<ReturnType<typeof getGrowerCoopMemberships>>
  >();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getGrowerCoopMemberships(role.profileId)
      .then((memberships) => setMemberships(memberships))
      .catch((error) => logError({ error, extraData: { profileId: role.profileId } }))
      .finally(() => setLoading(false));
  }, [role.profileId]);

  const coopNames = memberships
    .map(
      (membership) =>
        membership.coopProfile?.bio.abbreviation ?? membership.coopProfile?.bio.displayName
    )
    .filter(isDefined);

  return (
    <>
      {loading && <Skeleton.Input style={{ width: 100, marginRight: 16 }} />}
      <Form.Item
        name={["categorySettings", category, "availableToCoops"]}
        valuePropName="checked"
        hidden={loading || memberships.length === 0}
      >
        <AvailableToCoopsCheckbox
          title={coopNames.length > 0 ? coopNames.join(", ") : "Collective"}
        />
      </Form.Item>
    </>
  );
};

export const CategorySettingsWidget: React.FC<{
  category: RealProductCategoryKey;
}> = ({ category }) => {
  const { activeRole: role } = useRooted();
  assertIsSeller(role);

  const settings = role.profile.categorySettings[category];

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Card title="Who Can See These Products?" style={{ maxWidth: 800, marginBottom: 32 }}>
        <FirestoreDocForm
          dbRef={db.collection("profiles").doc(role.profileId)}
          data={role.profile}
          layout="inline"
          buttonText="Save"
        >
          <Row>
            <Col>
              <Form.Item
                // If the category is undefined, then this ensures we set a real value
                initialValue={settings?.availableToWholesale === undefined ? false : undefined}
                name={["categorySettings", category, "availableToWholesale"]}
                valuePropName="checked"
              >
                <Checkbox>Wholesale</Checkbox>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item
                // If the category is undefined, then this ensures we set a real value
                initialValue={settings?.availableToRetail === undefined ? false : undefined}
                name={["categorySettings", category, "availableToRetail"]}
                valuePropName="checked"
              >
                <Checkbox>Retail</Checkbox>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Retail Markup"
                // If the category is undefined, then this ensures we set a real percent for pricing.
                initialValue={settings?.retailMarkup === undefined ? 0 : undefined}
                required
                name={["categorySettings", category, "retailMarkup"]}
                className="ant-no-wrap"
              >
                <InputNumber
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value?.replace("%", "") || 0}
                />
              </Form.Item>
            </Col>

            {
              /* Only growers see the option to relist to coops */
              role.type === "grower" && (
                <GrowerAvailableToCoopsItem role={role} category={category} />
              )
            }
          </Row>
        </FirestoreDocForm>
      </Card>
    </div>
  );
};
