import { useParams } from "react-router-dom";
import React, { useCallback, useState } from "react";
import { Alert, Button, notification, Spin, Typography } from "antd";
import { functions, db, useDocumentDataOnceChecked } from "../../../services/firebase";
import {
  GenerateProfileReportRequest,
  GenerateProfileReportResponse,
  Profile,
  ProfileSlug,
  WithId,
} from "@rooted/shared";
import { PageContent, WideLayout } from "../../layouts";
import { PageHeader } from "../../../components/PageHeader";

async function generateProfileReport(
  params: GenerateProfileReportRequest
): Promise<GenerateProfileReportResponse> {
  const { data } = await functions.httpsCallable("generateProfileReport")(params);
  return data;
}

export const ProfileReport = () => {
  const { profileId } = useParams();
  const [generating, setGenerating] = useState(false);
  const [reportData, setReportData] = useState<GenerateProfileReportResponse | null>(null);

  const [idFromSlug, slugLoading] = useDocumentDataOnceChecked<ProfileSlug>(
    db.collection("slugs").doc(profileId)
  );

  const [profile, profileLoading] = useDocumentDataOnceChecked<WithId<Profile>>(
    slugLoading ? null : db.collection("profiles").doc(idFromSlug?.reference || profileId),
    { idField: "_id" }
  );

  const loading = slugLoading || profileLoading;

  const generateCallback = useCallback(async () => {
    if (generating || !profile) return;
    setGenerating(true);
    try {
      const data = await generateProfileReport({ profileId: profile._id });
      setReportData(data);
    } catch (error) {
      notification.error({
        message: "Failed to generate report",
        description: error.message,
      });
    } finally {
      setGenerating(false);
    }
  }, [generating, profile]);

  const title = `Profile Report: ${profile?.bio.displayName || "..."}`;

  return (
    <WideLayout>
      <PageContent>
        <PageHeader title={title} breadcrumb />
        <Alert
          style={{ marginBottom: 16 }}
          type="warning"
          message={
            "This script will generate an admin-only report on this profile! It may take a few seconds."
          }
        />

        <Button type="primary" disabled={!profile} onClick={generateCallback}>
          Generate Profile Report
        </Button>

        {(loading || generating) && <Spin />}
        {!loading && (
          <>
            {reportData && (
              <Typography.Paragraph>
                <pre>{JSON.stringify(reportData, null, 2)}</pre>
              </Typography.Paragraph>
            )}
          </>
        )}
      </PageContent>
    </WideLayout>
  );
};
