import {
  FullCoopRole,
  FullGrowerRole,
  makeName,
  Profile,
  Role,
  RootedUser,
  FullWholesaleBuyerRole,
  AdminRole,
  WithId,
} from "@rooted/shared";

import { Button, List, Spin, Modal, Tag } from "antd";
import { ListItemMetaProps } from "antd/lib/list";
import React from "react";
import { PageHeader } from "../../../components/PageHeader";
import { db, useCollectionDataChecked, useDocumentDataChecked } from "../../../services/firebase";
import { AddStaffMember } from "./AddStaffMember";
import { useAdminRoles } from "../../../services/roles";

export const StaffPane: React.FC<{
  role: WithId<FullGrowerRole | FullCoopRole | FullWholesaleBuyerRole>;
}> = ({ role }) => {
  const [admins] = useAdminRoles();
  const [roles, rolesLoading] = useCollectionDataChecked<WithId<Role>>(
    db.collection("roles").where("profileId", "==", role.profileId),
    { idField: "_id" }
  );
  const [profile] = useDocumentDataChecked<WithId<Profile>>(
    db.collection("profiles").doc(role.profileId),
    { idField: "_id" }
  );

  // filter roles to remove admins for all non-admin viewers
  let filteredRoles: WithId<Role>[] = [];
  if (roles && admins) {
    filteredRoles = roles.filter((r: WithId<Role>) => {
      return admins.findIndex((a: WithId<AdminRole>) => r.userId === a.userId) < 0;
    });
  }

  const confirmRemoveRole = (role: WithId<Role>) => {
    Modal.confirm({
      icon: null,
      title: (
        <>
          Remove <UserName userId={role.userId} />
        </>
      ),
      content: (
        <>
          Are you sure you want to remove{" "}
          <b>
            <UserName userId={role.userId} />
          </b>{" "}
          as an employee?
        </>
      ),
      onOk: () => {
        db.collection("roles").doc(role._id).delete();
      },
    });
  };

  const addRole = () => {
    Modal.success({
      icon: null,
      title: <>Add staff member</>,
      content: <>{profile && <AddStaffMember profile={profile} />}</>,
    });
  };

  return (
    <>
      <PageHeader
        title={"Staff"}
        breadcrumb
        extra={
          role.permissionLevel === "owner" && (
            <Button type="primary" onClick={addRole}>
              Add User
            </Button>
          )
        }
      />
      <List
        bordered
        dataSource={filteredRoles}
        renderItem={(item) => (
          <List.Item key={item._id}>
            <UserListMeta userId={item.userId} tag={item.permissionLevel} />
            {role.permissionLevel === "owner" && item._id !== role._id && (
              <Button type="ghost" onClick={() => confirmRemoveRole(item)}>
                Remove
              </Button>
            )}
          </List.Item>
        )}
      >
        {rolesLoading && <Spin />}
      </List>
      <br />
    </>
  );
};

export interface UserListMetaProps extends ListItemMetaProps {
  userId: string;
  tag?: string;
}

export const UserListMeta: React.FC<UserListMetaProps> = ({ userId, tag, ...props }) => {
  const [user] = useDocumentDataChecked<RootedUser>(db.collection("users").doc(userId));

  return (
    <List.Item.Meta
      title={
        <>
          {user && makeName(user)} {tag && <Tag>{tag}</Tag>}
        </>
      }
      description={user?.email}
    />
  );
};

export const UserName: React.FC<{ userId: string }> = ({ userId }) => {
  const [user] = useDocumentDataChecked<RootedUser>(db.collection("users").doc(userId));

  return <>{user ? makeName(user) : "..."}</>;
};
