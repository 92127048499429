import { ApplicationForm, WithId } from "@rooted/shared";
import React, { useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { SignedInComplete } from "../../services/account-state";
import { db, functions, useDocumentDataChecked } from "../../services/firebase";
import { LoadingPage, NotFoundPage } from "../layouts";
import { ApplicationDetails } from "./ApplicationDetails";
import { OnboardingHome } from "./OnboardingHome";

export const Onboarding: React.FC<{ account: SignedInComplete }> = ({ account }) => {
  return (
    <Routes>
      <Route path="" element={<OnboardingHome account={account} />} />
      <Route path="applications/:applicationId/*" element={<SingleApplicationView />} />
    </Routes>
  );
};

export const SingleApplicationView: React.FC = () => {
  const { applicationId } = useParams();

  const params = new URL(document.location.toString()).searchParams;
  const refreshStripe = params.get("refresh_stripe");
  const returnFromStripe = params.get("return_from_stripe");

  const [application, applicationLoading] = useDocumentDataChecked<WithId<ApplicationForm>>(
    db.collection("applications").doc(applicationId),
    { idField: "_id" }
  );

  // If this is a redirect back from stripe due to an old link, generate a new one.
  useEffect(() => {
    if (refreshStripe === "true") {
      functions
        .httpsCallable("refreshStripeSignupLink")(applicationId)
        .then((res) => window.location.replace(res.data));
    }
  }, [applicationId, refreshStripe]);

  // If the user is returning from stripe, update the status of the application
  useEffect(() => {
    if (returnFromStripe === "true") {
      functions
        .httpsCallable("checkSingleStripeOnboardingStatus")(applicationId)
        .then(() => (window.location.search = "")); //Remove the query parameter
    }
  }, [applicationId, returnFromStripe]);

  if (!applicationLoading && !application) return <NotFoundPage />;
  if (!application || applicationLoading || refreshStripe === "true" || returnFromStripe === "true")
    return <LoadingPage />;
  return <ApplicationDetails application={application} />;
};
