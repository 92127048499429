import {
  DeleteListingRequest,
  WithId,
  AddListingsRequest,
  AddListingsResponse,
} from "@rooted/shared";
import { functions, db } from "../firebase";

export const archiveListing = ({ _id }: WithId) =>
  db
    .collection("listings")
    .doc(_id)
    .set(
      {
        availability: {
          quantity: 0,
          active: false,
        },
      },
      { merge: true }
    );

export const unArchiveListing = ({ _id }: WithId) =>
  db
    .collection("listings")
    .doc(_id)
    .set(
      {
        availability: {
          active: true,
        },
      },
      { merge: true }
    );

export const deleteListing = (params: DeleteListingRequest) => {
  functions.httpsCallable("deleteListing")(params);
};

export const addListings = async (params: AddListingsRequest): Promise<AddListingsResponse> => {
  const { data } = await functions.httpsCallable("addListings")(params);
  return data;
};
