import { Layout, Row, Col, Space } from "antd";
import React from "react";
// import { Icon } from "antd"
import { InstagramOutlined, FacebookOutlined } from "@ant-design/icons";
import "./Footer.less";

export const Footer: React.FC = () => (
  <>
    <Layout.Footer className="rooted-footer">
      <Row align="middle" justify="space-between">
        <Col className="rooted-footer-logo" xs={{ span: 0 }} md={{ span: 6 }}>
          <Row justify="start">
            <Col>
              <img src="/logos/flowers-light.png" alt="Rooted Farmers logo" /> <br />
            </Col>
          </Row>
          {/* <div>Rooted</div>
          Out address or whatever other <br />
          legal stuff we probably have to <br />
          serve on every page. */}
        </Col>

        <Col xs={{ span: 20 }} md={{ span: 8 }} lg={{ span: 6 }}>
          <Row style={{ paddingBottom: "10px" }}>
            <Col>
              <Space size="middle">
                <a
                  href="https://www.instagram.com/rooted_farmers/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramOutlined />
                </a>
                <a
                  href="https://www.facebook.com/RootedFarmersUS/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookOutlined />
                </a>
              </Space>
            </Col>
          </Row>
          <a href="https://www.rootedfarmers.com/privacy-policy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          <br />
          <a href="https://www.rootedfarmers.com/terms" target="_blank" rel="noreferrer">
            Terms of Service
          </a>
          <br />
          <a href="https://www.rootedfarmers.com/contact-us" target="_blank" rel="noreferrer">
            Contact Us
          </a>
          <br />

          <span className="rights-reserved">
            © {new Date().getFullYear()} Rooted Farmers. All rights reserved.
          </span>
        </Col>
      </Row>

      {/* <Row justify="center">
        <Col>
          <div style={{ backgroundColor: "white", color: "black" }}>
            <img style={{ height: "100px" }} src="logos/makethiswork.png"></img>
            <br />
            Logo with with text needed!
          </div>
        </Col>
      </Row> */}

      {/* <Row justify="end">© {new Date().getFullYear()} Rooted Farmers. All rights reserved.</Row> */}
    </Layout.Footer>
  </>
);
