import { Menu } from "antd";
import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { CollapsingSider } from "../../../components/Sider/CollapsingSider";
import { NotFoundPage, PageContent, WideLayout } from "../../layouts";
import { ActionsPane } from "./ActionsPane";
import { AdminsPane } from "./AdminsPane";
import { OverviewPane } from "./OverviewPane";
import { RolesPane } from "./RolesPane";
import { SubscriptionPlansPane } from "./SubscriptionPlans";

export const SiteSettings: React.FC = () => {
  const category = useLocation().pathname.split("/")[3] || "overview";

  return (
    <>
      <WideLayout>
        <CollapsingSider selectedKeys={[category]}>
          <Menu.Item key="overview">
            <Link to="">Overview</Link>
          </Menu.Item>
          <Menu.Item key="roles">
            <Link to="roles">Roles</Link>
          </Menu.Item>
          <Menu.Item key="admins">
            <Link to="admins">Admins</Link>
          </Menu.Item>
          <Menu.Item key="actions">
            <Link to="actions">Actions</Link>
          </Menu.Item>
          <Menu.Item key="subscription-plans">
            <Link to="subscription-plans">Subscription Plans</Link>
          </Menu.Item>
        </CollapsingSider>
        <PageContent>
          <Routes>
            <Route path="admins/*" element={<AdminsPane />} />
            <Route path="roles/*" element={<RolesPane />} />
            <Route path="actions/*" element={<ActionsPane />} />
            <Route path="subscription-plans/*" element={<SubscriptionPlansPane />} />
            <Route path="" element={<OverviewPane />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </PageContent>
      </WideLayout>
    </>
  );
};
