import { SwapRightOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import React from "react";
import { useBreakpoint } from "../../utils/detectWindowSize";

export type DateRange = [moment.Moment | null, moment.Moment | null];
export const DateRangePicker: React.FC<{
  value?: DateRange;
  onChange?: (dates: DateRange) => void;
  disabledDate?: (current: moment.Moment) => boolean;
  format?: string;
  disabled?: boolean;
  width?: number;
}> = ({
  value = [null, null],
  onChange,
  disabledDate = () => false,
  format = "MM/DD/YY",
  disabled,
  width,
}) => {
  const breakpoint = useBreakpoint();

  return (
    <>
      {breakpoint.md ? (
        <DatePicker.RangePicker
          format={format}
          value={value}
          onChange={(e) => (e ? onChange?.(e) : onChange?.([null, null]))}
          disabledDate={disabledDate}
          disabled={disabled}
          style={{ width }}
        />
      ) : (
        <>
          <DatePicker
            format={format}
            value={value[0]}
            onChange={(e) => onChange?.([e, value[1]])}
            allowClear={false}
            suffixIcon={null}
            size="small"
            style={{ width: width && width / 2 }}
            disabled={disabled}
            disabledDate={(start) => {
              const [, end] = value;
              if (!start || !end) return false;
              return disabledDate(start) || start.valueOf() > end.valueOf();
            }}
            inputReadOnly // ensures mobile input does not spaz
          />
          <SwapRightOutlined />
          <DatePicker
            format={format}
            value={value[1]}
            onChange={(e) => onChange?.([value[0], e])}
            allowClear={false}
            suffixIcon={null}
            size="small"
            style={{ width: width && width / 2 }}
            disabled={disabled}
            disabledDate={(end) => {
              const [start] = value;
              if (!end || !start) return false;
              return disabledDate(end) || end.valueOf() <= start.valueOf();
            }}
            inputReadOnly // ensures mobile input does not spaz
          />
        </>
      )}
    </>
  );
};
