import { Layout, Result, Image, Typography } from "antd";
import React from "react";

const icon = (
  <Image
    style={{ marginTop: "1rem", display: "block", width: "400px", marginBottom: ".8rem" }}
    src="/logos/logo-horizontal.svg"
    preview={false}
    alt="Rooted Farmers logo"
  />
);

const extra = (
  <Typography.Paragraph>
    Please check back in an hour. In the meantime, find us on{" "}
    <Typography.Link href="https://www.instagram.com/rooted_farmers" target="_blank">
      {" "}
      Instagram
    </Typography.Link>{" "}
    or learn more at
    <Typography.Link href="https://www.rootedfarmers.com/" target="_blank">
      {" "}
      www.rootedfarmers.com
    </Typography.Link>
    .
  </Typography.Paragraph>
);

export const DownForMaintenancePage: React.FC = () => {
  return (
    <Layout>
      <Result
        icon={icon}
        style={{ marginTop: "30vh" }}
        title="Hi! We're currently upgrading our systems."
        extra={extra}
      />
    </Layout>
  );
};
