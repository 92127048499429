import { EditOutlined } from "@ant-design/icons";
import { ApprovedConnection, WithId } from "@rooted/shared";
import { Button, notification, Popover, Space, Typography } from "antd";
import React, { useState } from "react";
import { CurrencyInput, formatCurrency } from "../../components/FormControls/CurrencyInput";
import { updateConnection } from "../../services/connections";

export const OrderMinimumWidget: React.FC<{
  connection: WithId<ApprovedConnection>;
  editable?: boolean;
}> = ({ connection, editable }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [amount, setAmount] = useState(connection.orderMinimumCents ?? 0);

  const submit = async () => {
    setSubmitting(true);
    try {
      await updateConnection(connection._id, { orderMinimumCents: amount });
    } catch (error) {
      notification.error({ message: "Something went wrong while updating your order minimum." });
      console.error(error);
    }

    setSubmitting(false);
    setOpen(false);
  };

  return (
    <>
      {connection.orderMinimumCents ? (
        formatCurrency(connection.orderMinimumCents)
      ) : (
        <Typography.Text type="secondary">None</Typography.Text>
      )}
      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <CurrencyInput
                  value={amount}
                  onChange={(e) => {
                    const newMinimum = typeof e === "number" ? e : 0;
                    setAmount(newMinimum);
                  }}
                />
                <br />
                <Space direction="horizontal">
                  <Button type="primary" onClick={submit} loading={submitting}>
                    Update
                  </Button>
                </Space>
              </Space>
            </>
          }
          title="Change Order Minimum"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};
