import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthAction } from "./AuthAction";
import { CreateAccountForm } from "./CreateAccountForm";
import { LoginForm } from "./LoginForm";
import { ResetPasswordForm } from "./ResetPasswordForm";
export * from "./CompleteAccountForm";
export * from "./LoginForm";

export const Authentication: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="create-account" element={<CreateAccountForm />} />
        <Route path="login" element={<LoginForm />} />
        <Route path="reset-password" element={<ResetPasswordForm />} />
        <Route path="auth-action" element={<AuthAction />} />
        <Route path="*" element={<Navigate to="login" />} />
      </Routes>
    </>
  );
};
