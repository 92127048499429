import { WithId, FullBuyerRole, SavedLocation, stringifyLocation } from "@rooted/shared";

import { Button, Form, List, Modal, Space, Spin, Typography } from "antd";
import React from "react";
import { LocationInput } from "../../../components/FormControls/AddressInput";
import { PageHeader } from "../../../components/PageHeader";
import { removeAddress, saveAddress } from "../../../services/buyers/addresses";
import { db, useCollectionDataChecked } from "../../../services/firebase";

export const AddressesPane: React.FC<{
  role: WithId<FullBuyerRole>;
}> = ({ role }) => {
  const [addresses, addressesLoading] = useCollectionDataChecked<WithId<SavedLocation>>(
    db.collection("profiles").doc(role.profileId).collection("addresses"),
    { idField: "_id" }
  );

  return (
    <>
      <PageHeader
        title={"Addresses"}
        extra={
          <Button type="primary" onClick={() => showAddressModal(role)}>
            Add Address
          </Button>
        }
      />
      <List bordered>
        {addressesLoading && <Spin />}
        {addresses?.map((address) => (
          <List.Item
            key={address._id}
            extra={
              <Button type="text" onClick={() => removeAddress(role, address)}>
                Remove
              </Button>
            }
          >
            {stringifyLocation(address)}
          </List.Item>
        ))}
        {addresses?.length === 0 && (
          <List.Item>
            <Typography.Text type="secondary">You have no saved addresses.</Typography.Text>
          </List.Item>
        )}
      </List>
      <br />
    </>
  );
};

export const showAddressModal = (role: FullBuyerRole) =>
  Modal.info({
    icon: <></>,
    title: "Add Address",
    content: (
      <>
        <Form
          onFinish={async (x: any) => {
            await saveAddress(role, x["location"]);
            Modal.destroyAll();
          }}
          style={{ marginTop: 16 }}
        >
          <Form.Item name="location" rules={[{ required: true, message: " " }]}>
            <LocationInput />
          </Form.Item>
          <div style={{ marginLeft: "auto" }}>
            <Space>
              <Button onClick={Modal.destroyAll}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Ok
              </Button>
            </Space>
          </div>
        </Form>
      </>
    ),
    okButtonProps: { style: { display: "none" } },
  });
