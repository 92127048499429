import React, { useEffect, useCallback, useState } from "react";
import { Checkbox, Row, Col, Button } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

/**
 * Checkbox group that takes a list of string options to display,
 * and can supply select all / deselect all buttons.
 * Usable as a form input
 */
export const CheckboxGroup: React.FC<{
  selectAll?: boolean;
  deselectAll?: boolean;
  options: { label: string; value: string }[];
  value?: string[];
  onChange?: (selectedOptions: string[]) => void;
}> = ({ selectAll, deselectAll, options, value, onChange: onChangeProp }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>();

  useEffect(() => {
    if (selectedOptions) onChangeProp?.(selectedOptions);
  }, [onChangeProp, selectedOptions]);

  const onChange = useCallback((list: CheckboxValueType[]) => {
    setSelectedOptions(list as string[]);
  }, []);

  const onSelectAll = useCallback(() => {
    setSelectedOptions(options.map(({ value }) => value));
  }, [options]);

  const onDeselectAll = useCallback(() => {
    setSelectedOptions([]);
  }, []);

  return (
    <Checkbox.Group value={selectedOptions} onChange={onChange}>
      {(selectAll || deselectAll) && (
        <Row style={{ marginBottom: 20 }}>
          {selectAll && (
            <Button onClick={onSelectAll} type={"default"} style={{ marginRight: 12 }}>
              Select All
            </Button>
          )}
          {deselectAll && (
            <Button onClick={onDeselectAll} type={"ghost"}>
              Deselect All
            </Button>
          )}
        </Row>
      )}
      <Row>
        {options.map(({ label, value }) => (
          <Col span={24} key={label}>
            <Checkbox value={value}>{label}</Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
};
