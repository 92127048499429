import {
  ApprovedCoopMembership,
  CoopMembership,
  CoopProfile,
  FullCoopRole,
  FullGrowerRole,
  GrowerProfile,
  RequestedCoopMembership,
  WithId,
  RecursivePartial,
  CoopRole,
  EnterpriseProfile,
} from "@rooted/shared";
import firebase from "firebase/app";
import toIdDoc from "../utils/toIdDoc";
import { functions, db, useCollectionDataChecked, useDocumentDataChecked } from "./firebase";

/**
 * React hook to get the data for a coop membership between a given buyer and seller
 * @param coopId Id of the buyer's profile
 * @param growerId Id of the seller's profile
 */
export const useCoopMembership = (coop: WithId<CoopProfile>, grower: WithId<GrowerProfile>) =>
  useDocumentDataChecked<WithId<CoopMembership>>(
    db.collection("coopMemberships").doc(`${coop._id}-${grower._id}`),
    {
      idField: "_id",
    }
  );

/**
 * React hook to listen to connection requests for a given grower
 * @param role The role of the grower
 */
export const useCoopMembershipRequests = (role: FullCoopRole) => {
  return useCollectionDataChecked<WithId<RequestedCoopMembership>>(
    db
      .collection("coopMemberships")
      .where("status", "==", "grower-requested")
      .where("coopId", "==", role.profileId),
    { idField: "_id" }
  );
};

/**
 * React hook to listen to pending connections for a given seller
 * @param role The role of the grower
 */
export const usePendingCoopMemberships = (role: FullCoopRole) => {
  return useCollectionDataChecked<WithId<RequestedCoopMembership>>(
    db
      .collection("coopMemberships")
      .where("status", "==", "coop-requested")
      .where("coopId", "==", role.profileId),
    { idField: "_id" }
  );
};

/**
 * React hook to listen to approved connections for a given grower.
 * @param role The role of the grower
 */
export const useCoopMemberships = (role: CoopRole) => {
  return useCollectionDataChecked<WithId<ApprovedCoopMembership>>(
    db
      .collection("coopMemberships")
      .where("status", "==", "approved")
      .where("coopId", "==", role.profileId),
    { idField: "_id" }
  );
};

const getCoopMemberships = async (coopId: string): Promise<WithId<ApprovedCoopMembership>[]> => {
  const membershipsSnapshot = await db
    .collection("coopMemberships")
    .where("status", "==", "approved")
    .where("coopId", "==", coopId)
    .get();
  return membershipsSnapshot.docs.map((doc) => toIdDoc<ApprovedCoopMembership>(doc));
};

const getProfileFromMembership = async (
  membership: WithId<ApprovedCoopMembership>
): Promise<WithId<GrowerProfile> | undefined> => {
  const _id = membership.growerId;
  const profileSnapshot = await db.collection("profiles").doc(_id).get();
  if (!profileSnapshot.exists) return undefined;
  return Object.assign(profileSnapshot.data() as GrowerProfile, { _id });
};

export const getCoopMembers = async (coopId: string) => {
  const memberships = await getCoopMemberships(coopId);
  const members = (await Promise.all(memberships.map(getProfileFromMembership))).filter(
    (profile) => !!profile
  ) as WithId<GrowerProfile>[];

  return members.map(({ _id, bio: { displayName, photoStorageUrl } }) => ({
    _id,
    displayName,
    photoStorageUrl,
  }));
};

/**
 * React hook to listen to connection requests for a given grower
 * @param role The role of the grower
 */
export const useGrowerCoopMembershipRequests = (role: FullGrowerRole) => {
  return useCollectionDataChecked<WithId<RequestedCoopMembership>>(
    db
      .collection("coopMemberships")
      .where("status", "==", "coop-requested")
      .where("growerId", "==", role.profileId),
    { idField: "_id" }
  );
};

/**
 * React hook to listen to pending connections for a given seller
 * @param role The role of the grower
 */
export const useGrowerPendingCoopMemberships = (role: FullGrowerRole) => {
  return useCollectionDataChecked<WithId<RequestedCoopMembership>>(
    db
      .collection("coopMemberships")
      .where("status", "==", "grower-requested")
      .where("growerId", "==", role.profileId),
    { idField: "_id" }
  );
};

/**
 * React hook to listen to approved connections for a given grower.
 * @param role The role of the grower
 */
export const useGrowerCoopMemberships = (role: FullGrowerRole) => {
  return useCollectionDataChecked<WithId<ApprovedCoopMembership>>(
    db
      .collection("coopMemberships")
      .where("status", "==", "approved")
      .where("growerId", "==", role.profileId),
    { idField: "_id" }
  );
};

// MUTATIONS

/**
 * Approve a given pending coop membership. No-op if used on an already-approved coop membership.
 * @param id Id of the coop membership
 */
export const approveCoopMembership = (id: string) =>
  functions.httpsCallable("approveCoopMembership")(id);

/**
 * Remove a given existing or pending coop membership.
 * @param id Id of the coop membership
 */
export const removeCoopMembership = (id: string) =>
  db.collection("coopMemberships").doc(id).delete();

/**
 * Request a coop membership to a coop as a wholesale buyer.
 * @param role The role of the buyer requesting the coop membership
 * @param seller The profile of the seller with whom the buyer wants to connect
 */
export const requestMembershipInCoop = async (role: FullGrowerRole, coop: WithId<CoopProfile>) => {
  const c: RequestedCoopMembership = {
    status: "grower-requested",
    growerId: role.profileId,
    coopId: coop._id,
    requestedOn: firebase.firestore.Timestamp.now(),
  };

  db.collection("coopMemberships").doc(`${coop._id}-${role.profileId}`).set(c);
};

/**
 * Request a coop membership to a coop as a wholesale buyer.
 * @param role The role of the buyer requesting the coop membership
 * @param seller The profile of the seller with whom the buyer wants to connect
 */
export const inviteGrowerToCoop = async (role: FullCoopRole, grower: WithId<GrowerProfile>) => {
  const c: CoopMembership = {
    status: "coop-requested",
    growerId: grower._id,
    coopId: role.profileId,
    requestedOn: firebase.firestore.Timestamp.now(),
  };

  db.collection("coopMemberships").doc(`${role.profileId}-${grower._id}`).set(c);
};

/**
 * Update the delivery settings for a membership.
 */
export const setCoopMembershipTags = (
  membership: WithId<ApprovedCoopMembership>,
  value: string[] | undefined
) =>
  db
    .collection("coopMemberships")
    .doc(membership._id)
    .set(
      {
        tags: value || [],
      },
      { merge: true }
    );

/**
 * Update a membership.
 */
export const updateCoopMembership = (
  membership: WithId<ApprovedCoopMembership>,
  changes: RecursivePartial<ApprovedCoopMembership>
) =>
  db
    .collection("coopMemberships")
    .doc(membership._id)
    .set(
      {
        ...changes,
      },
      { merge: true }
    );
