import {
  FullBuyerRole,
  calculateCartTotal,
  calculateItemGroupQuantity,
  Cart,
  firestoreToMoment,
  momentToISO,
  SellerProfile,
  WithId,
  FulfillmentGroup,
  createUniqueFulfillmentGroupKey,
} from "@rooted/shared";
import {
  Alert,
  Button,
  Card,
  Form,
  Layout,
  List,
  notification,
  Statistic,
  Tag,
  Typography,
} from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { CreditCardSelect } from "../../../components/FormControls/CreditCardSelect";
import { formatCurrency } from "../../../components/FormControls/CurrencyInput";
import { PageHeader } from "../../../components/PageHeader";
import { logError } from "../../../sentry";
import {
  placeOrder,
  setPaymentMethodId,
  useCurrentCart,
} from "../../../services/buyers/active-cart";
import { functions, db, useDocumentDataChecked } from "../../../services/firebase";
import { breakpoint, useWindowSize } from "../../../utils/detectWindowSize";
import { LoadingPage, NarrowLayout, PageContent, WideLayout } from "../../../views/layouts";
import { CloudFunctionButton } from "../../admins/SiteSettings/ActionsPane";
import { CartFulfillmentGroup } from "./CartFulfillmentGroup";
import {
  FulfillmentGroupErrorsProvider,
  useFulfillmentGroupErrors,
} from "./FulfillmentGroupErrorsContext";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { BackToShopButton } from "../../../components/Navbar/CartButtons";

export const MyCart: React.FC<{
  role: WithId<FullBuyerRole>;
}> = ({ role }) => {
  const [cart, cartsLoading] = useCurrentCart();
  const [width] = useWindowSize();
  const isWide = width > breakpoint.md;

  if (cartsLoading) return <LoadingPage />;

  // Redirect the cart page to orders if the cart is nonexistent.
  if (!cart) return <Navigate to="/orders" />;

  if (cart.fulfillmentGroups.length === 0)
    return (
      <NarrowLayout>
        <BackToShopButton />
        <PageHeader title={"Cart"} />
        <Card style={{ textAlign: "center" }}>
          <Typography.Text>
            Looks like your cart is empty.
            <br />
            Try browsing some <Link to="/shop/cut-flowers">cut flowers.</Link>
          </Typography.Text>
        </Card>
      </NarrowLayout>
    );

  return (
    <FulfillmentGroupErrorsProvider>
      <WideLayout>
        <PageContent>
          {/** Dev only message: */}
          {process.env.REACT_APP_BACKEND === "local" &&
            cart.status !== "provisional" &&
            cart.firstItemAddedAt.toMillis() + 60 * 60 * 1000 < Date.now() && (
              <Form.Item>
                <Alert
                  type="warning"
                  message={
                    <>
                      <Tag>LOCAL MODE</Tag>&nbsp; Cart expiration checker can't run as we don't
                      emulate Google Pub/Sub. &nbsp;
                      <CloudFunctionButton
                        type="ghost"
                        onClick={() => functions.httpsCallable("testExpireCarts")({})}
                      >
                        Manually Expire
                      </CloudFunctionButton>
                    </>
                  }
                />
              </Form.Item>
            )}

          <BackToShopButton />
          <PageHeader
            title={"Cart"}
            extra={
              cart?.status === "active" && (
                <Statistic.Countdown
                  title="Time left"
                  value={momentToISO(
                    firestoreToMoment(cart.firstItemAddedAt).add(60 * 60 * 1000, "ms")
                  )}
                  format="mm:ss"
                />
              )
            }
          />
          {cart.fulfillmentGroups.map((group) => (
            <CartFulfillmentGroup
              key={createUniqueFulfillmentGroupKey(group)}
              group={group}
              role={role}
              cart={cart}
            />
          ))}
        </PageContent>

        {/* TODO: a better layout sider abstraction. Sider default has a constrained width on small screens */}
        {isWide ? (
          <Layout.Sider width={280} style={{ marginLeft: 12 }}>
            <SummaryCheckoutCard cart={cart} role={role} />
          </Layout.Sider>
        ) : (
          <SummaryCheckoutCard cart={cart} role={role} />
        )}
      </WideLayout>
    </FulfillmentGroupErrorsProvider>
  );
};

const SummaryCheckoutCard: React.FC<{
  cart: WithId<Cart>;
  role: WithId<FullBuyerRole>;
}> = ({ cart, role }) => {
  // Fulfillment groups each have their own listener to the connection,
  // and do the majority of displaying of their own fulfillment related errors,
  // e.g. order minimums.
  // However, we need to disable the top level button if those errors exist.
  const { state: fulfillmentGroupErrors } = useFulfillmentGroupErrors();

  // Upper bounded by number of fulfillment groups, fine to compute on render.
  // Filters groups without any errors
  const numGroups = cart.fulfillmentGroups.length;
  const numValidFulfillments = Object.values(fulfillmentGroupErrors).filter(
    ({ errors }) => errors.length === 0
  ).length;
  const allFulfillmentsValid = numValidFulfillments === numGroups;

  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const checkout = useCallback(async () => {
    setSubmitting(true);
    try {
      await placeOrder(cart);
      navigate(`/orders`);
    } catch (error) {
      notification.error({ message: "Oops! Something went wrong placing your order." });
      logError({
        error,
        extraData: {
          cart,
        },
        tags: {
          page: "cart",
        },
      });
    }
    setSubmitting(false);
  }, [cart, navigate]);

  const total = useMemo(() => formatCurrency(calculateCartTotal(cart)), [cart]);

  return (
    <Card>
      <OrderLineItems cart={cart} />
      <Form>
        <Form.Item label="Credit Card" name={"paymentMethodId"} rules={[{ required: true }]}>
          <CreditCardSelect
            role={role}
            value={cart.paymentMethodId}
            onChange={(cardId) => {
              setPaymentMethodId(cart, cardId);
            }}
          />
        </Form.Item>
        <Button
          loading={submitting}
          type="primary"
          disabled={!allFulfillmentsValid || cart.status !== "active" || !cart.paymentMethodId}
          onClick={checkout}
          style={{
            width: "100%",
          }}
        >
          {`Place order ${total}`}
        </Button>
      </Form>
      <Typography.Text
        type="secondary"
        style={{ fontStyle: "italic", marginTop: 12, fontSize: 12, display: "block" }}
      >
        Your payment method will be charged when your order is confirmed by the seller(s).
      </Typography.Text>
      {(!allFulfillmentsValid || !cart.paymentMethodId) && (
        <Typography.Text
          type="danger"
          style={{ paddingTop: 12, textAlign: "center", display: "block" }}
        >
          {!cart.paymentMethodId
            ? "Add payment method to place order"
            : "One or more of your orders has an issue that needs to be addressed"}
        </Typography.Text>
      )}
    </Card>
  );
};

const OrderLineItems: React.FC<{ cart: Cart | undefined }> = ({ cart }) => {
  return (
    <List size="small">
      {cart &&
        cart.fulfillmentGroups.map((x, index) => [
          // SubTotal
          <List.Item
            key={createUniqueFulfillmentGroupKey(x)}
            actions={[formatCurrency(x._calculated.subtotal)]}
            style={{ borderTop: index !== 0 ? "1px solid black" : "" }}
          >
            <b>
              <SellerName id={x.fulfillment.profileId} />
            </b>
            <br />({calculateItemGroupQuantity(x.items)} item
            {calculateItemGroupQuantity(x.items) > 1 && "s"})
          </List.Item>,

          // Tax
          x.applicableTaxes.map((tax, index) => (
            <List.Item
              key={`seller-${x.fulfillment.profileId}-tax-${tax.name}`}
              actions={[formatCurrency(x._calculated.taxBurden[index])]}
            >
              &nbsp;&nbsp;{tax.name}
            </List.Item>
          )),

          // Delivery
          x._calculated.handlingFee !== undefined && x._calculated.handlingFee > 0 && (
            <List.Item
              key={`seller-${x.fulfillment.profileId}-delivery`}
              actions={[formatCurrency(x._calculated.handlingFee)]}
            >
              &nbsp;&nbsp;Handling fee
            </List.Item>
          ),

          // Delivery
          x._calculated.fulfillmentPrice !== 0 && (
            <List.Item
              key={`seller-${x.fulfillment.profileId}-delivery`}
              actions={[formatCurrency(x._calculated.fulfillmentPrice)]}
            >
              &nbsp;&nbsp;Delivery
            </List.Item>
          ),

          // Total
          <List.Item
            key={`seller-${x.fulfillment.profileId}-subtotal`}
            actions={[formatCurrency(x._calculated.total)]}
          >
            &nbsp;&nbsp;Total
          </List.Item>,
        ])}
      <List.Item
        actions={[formatCurrency(calculateCartTotal(cart))]}
        style={{ borderTop: "1px solid black", borderBottom: "1px solid black", marginBottom: 10 }}
      >
        <b>Grand Total</b>
      </List.Item>
    </List>
  );
};

const SellerName: React.FC<{ id: string }> = ({ id }) => {
  const [seller] = useDocumentDataChecked<SellerProfile>(db.collection("profiles").doc(id));
  return <>{seller ? seller.bio.displayName : "..."}</>;
};
