import { useParams, Link } from "react-router-dom";
import React from "react";
import { Collapse, Typography, Breadcrumb, Alert, Row, Col, Card } from "antd";
import { db, useDocumentDataOnceChecked } from "../../../../services/firebase";
import { Profile, WithId, isEnterprise } from "@rooted/shared";
import { PageContent, WideLayout } from "../../../layouts";
import { PageHeader } from "../../../../components/PageHeader";
import { ArrowRightOutlined } from "@ant-design/icons";
import { ProfileLink } from "../../../../components/ProfileLink/ProfileLink";
import { OwnerDetails } from "./OwnerDetails";
import { AdminEnterpriseProfileDetails } from "./AdminEnterpriseProfileDetails";
import { CardSkeleton } from "../../../../components/skeletons";
import { useRouterBreadcrumb } from "../../../../components/Misc/useRouterBreadcrumb";

export const AdminProfileDetailsWithoutLayout = () => {
  const { profileId } = useParams();

  const [profile, profileLoading] = useDocumentDataOnceChecked<WithId<Profile>>(
    db.collection("profiles").doc(profileId),
    {
      idField: "_id",
    }
  );

  if (profileLoading) return <CardSkeleton />;

  if (!profile)
    return (
      <Card>
        <PageHeader title={"Profile Not Found"} />
        <Typography.Text>
          No Profile Found with Id: <Typography.Text code>{profileId}</Typography.Text>
        </Typography.Text>
      </Card>
    );

  return (
    <>
      <Alert
        style={{ marginBottom: 16 }}
        type="warning"
        message={
          <Row gutter={[8, 8]} justify="space-between">
            <Col>Currently viewing profile on Admin Dashboard.</Col>
            <Col>
              <ProfileLink to={profile}>
                View on Public Page <ArrowRightOutlined />
              </ProfileLink>
            </Col>
          </Row>
        }
      />

      <Card>
        <PageHeader title={profile.bio.displayName || profile._id} />

        <OwnerDetails ownerId={profile.ownerId} />
        {isEnterprise(profile) && <AdminEnterpriseProfileDetails profileId={profileId} />}

        <Link to="admin-report">Profile Report</Link>

        <CollapsibleJson data={profile} title={"Full Document"} />
      </Card>
    </>
  );
};

const CollapsibleJson: React.FC<{ data?: unknown; style?: React.CSSProperties; title: string }> = ({
  data,
  style,
  title,
}) => (
  <Collapse style={style}>
    <Collapse.Panel header={title} key="1">
      <pre
        style={{
          background: "rgba(150, 150, 150, 0.1)",
          border: "1px solid rgba(100, 100, 100, 0.2)",
        }}
      >
        {JSON.stringify(data, null, 2)}
      </pre>
    </Collapse.Panel>
  </Collapse>
);

export const AdminProfileDetails = () => {
  const breadcrumb = useRouterBreadcrumb();

  return (
    <WideLayout>
      <PageContent>
        <Breadcrumb {...breadcrumb} style={{ marginBottom: 16, paddingTop: 12 }} />
        <AdminProfileDetailsWithoutLayout />
      </PageContent>
    </WideLayout>
  );
};
