import {
  assertIsCoop,
  DayOfWeek,
  firestoreToMoment,
  momentToDayOfWeek,
  NarrowFromCategory,
  RealListing,
  realProductCategories,
  RealRelisting,
  searchObject,
  WithId,
} from "@rooted/shared";
import { Button, Card, DatePicker, Input, Tabs, Tooltip, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { PageHeader } from "../../../components/PageHeader";
import {
  colorColumn,
  genericNameColumn,
  photoColumn,
  stemLengthColumn,
} from "../../../components/ProductTable";
import { useRooted } from "../../../RootedContext";
import { useAllActiveRelistings } from "../../../services/coops/relistings";
import { CategorySettingsWidget } from "../../growers/CategorySettings";
import { CoopPriceWidget } from "./RelistingPriceWidget";
import { ActiveWidget } from "./RelistingActiveWidget";
import {
  FlowerVarietyColumn,
  FlowerVarietyDetailsColumn,
} from "../../../components/TableColumns/FlowerVarietyColumn";
import { AvailabilityColumn } from "../../../components/TableColumns/AvailabilityColumn";
import { ListingQuantityDisplay } from "../../growers/Inventory/utils/widgets";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useDebouncedTrigger } from "../../../hooks/util/useDebouncedTrigger";
import { getCategorySlugBreadcrumbReplacement } from "../../../utils/getCategorySlugBreadcrumbReplacement";
import { useRouterBreadcrumb } from "../../../components/Misc/useRouterBreadcrumb";
import { OriginalGrowerColumn } from "../../../components/TableColumns/OriginalGrowerColumn";

const DateWindowSelector: React.FC<{
  window: Window | null;
  onChangeDate: (m: moment.Moment | null) => void;
}> = ({ onChangeDate }) => {
  // Match height with search input
  return <DatePicker style={{ height: 34 }} onChange={onChangeDate} />;
};

// Checks if a date is within a listings start / end dates
const availableOnDay = (day: moment.Moment, listing: RealListing) => {
  if (!listing.availability || !listing.availability.startDate) return false;
  const listingStartMoment = firestoreToMoment(listing.availability.startDate);
  if (!listingStartMoment.isSameOrBefore(day, "day")) return false;
  // If no listing end date, then assume goes on forever.
  if (!listing.availability.endDate) return true;
  const listingEndMoment = firestoreToMoment(listing.availability.endDate);
  return listingEndMoment.isSameOrAfter(day, "day");
};

// Checks if a date is supported by a coop membership on a relisting
const availableOnMembershipDay = (dayOfWeek: DayOfWeek, relisting: RealRelisting) =>
  relisting.relistingMetaData.membershipDays.indexOf(dayOfWeek) !== -1;

export function CoopInventoryOverview<C extends RealRelisting["category"]>({
  category,
}: {
  category: C;
}) {
  const { activeRole: role } = useRooted();
  assertIsCoop(role);

  const breadcrumb = useRouterBreadcrumb({
    ...getCategorySlugBreadcrumbReplacement(category),
  });

  const [relistings = [], relistingsLoading] = useAllActiveRelistings(category, role);

  // we do a non trivial ammount of computation, so we should show loading during that also.

  const loading = relistingsLoading;

  // Filter results by a specified date range
  const [dateFilter, setFilterDate] = useState<{
    date: moment.Moment;
    dayOfWeek: DayOfWeek;
  } | null>(null);

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebouncedTrigger(search);

  const searchedRelistings = useMemo(() => {
    return relistings.filter(
      (listing) =>
        searchObject(debouncedSearch, listing) &&
        (!dateFilter || availableOnDay(dateFilter.date, listing)) &&
        (!dateFilter || availableOnMembershipDay(dateFilter.dayOfWeek, listing))
    );
  }, [relistings, debouncedSearch, dateFilter]);

  // We only support single day windows right now, so set both start and end at same time.
  const momentToFilterWindow = useCallback((m: moment.Moment | null) => {
    if (!m) {
      setFilterDate(null);
    } else {
      setFilterDate({ date: m, dayOfWeek: momentToDayOfWeek(m) });
    }
  }, []);

  // create a more personalized help tooltip based on actual data
  const [curatedSearchHelp, setCuratedSearchHelp] = useState<string | null>(null);

  useEffect(() => {
    if (!curatedSearchHelp && relistings.length > 0) {
      const randomRelisting = relistings[Math.floor(Math.random() * relistings.length)];
      // Use up to three words for each
      const exampleName = randomRelisting.product.name.split(" ").splice(0, 2).join(" ");
      const exampleGrower = randomRelisting.backingListing.seller._cache.bio.displayName
        .split(" ")
        .splice(0, 2)
        .join(" ");

      setCuratedSearchHelp(`Try searching for '${exampleName}' or '${exampleGrower}'`);
    }
  }, [relistings, curatedSearchHelp]);

  useEffect(() => {
    setCuratedSearchHelp(null);
    setFilterDate(null);
  }, [category]);

  const searchedActiveRelistings = useMemo(
    () => searchedRelistings?.filter((r) => r.relistingMetaData.isActive) || [],
    [searchedRelistings]
  );

  return (
    <>
      <PageHeader
        title={realProductCategories[category].pluralName}
        breadcrumb={breadcrumb}
        extra={
          category === "cut-flower" && (
            <Link to="/connections/notify-buyers">
              <Button type="default">Notify Buyers</Button>
            </Link>
          )
        }
      />

      <CategorySettingsWidget category={category} />

      <Card>
        <div
          style={{
            width: "100%",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            marginBottom: 16,
          }}
        >
          <Input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder="Search Inventory"
            // Match height with date input
            style={{ maxWidth: 400, height: 34 }}
          />
          <Tooltip title={curatedSearchHelp || "Try searching by item name or grower"}>
            <QuestionCircleOutlined style={{ color: "gray", marginLeft: 8, marginRight: 32 }} />
          </Tooltip>
          {category !== "csa" && (
            <>
              <DateWindowSelector window={window} onChangeDate={momentToFilterWindow} />
              <Tooltip title={"See what's available to buyers on a given day"}>
                <QuestionCircleOutlined style={{ color: "gray", marginLeft: 8 }} />
              </Tooltip>
            </>
          )}
        </div>
        <Tabs className="no-margin" type="card">
          <Tabs.TabPane
            tab={`All Inventory` + (loading ? "" : ` (${searchedRelistings.length})`)}
            key="all-inventory"
          >
            <InventoryTableMemo
              loading={loading}
              category={category}
              listings={searchedRelistings}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={`Available Inventory` + (loading ? "" : ` (${searchedActiveRelistings.length})`)}
            key="active-inventory"
          >
            <InventoryTableMemo
              loading={loading}
              category={category}
              listings={searchedActiveRelistings}
            />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </>
  );
}

const makeCoopPriceColumn = ({ title = "Price" }: { title?: ReactNode }) => ({
  title,
  // ellipsis: true,
  dataIndex: "price",
  key: "price",
  width: 80,
  className: "text-center",
  render: (_: any, record: WithId<RealRelisting>) => (
    <CoopPriceWidget editable relisting={record} />
  ),
  sorter: (a: any, b: any): any => a.pricing.pricePerStem - b.pricing.pricePerStem,
  sortDirections: ["descend", "ascend"] as any, // SortOrder from antd,
});

const StockColumn = {
  title: "Stock",
  dataIndex: "quantity",
  key: "quantity",
  width: 120,
  className: "text-center",
  render: (_: any, record: WithId<RealRelisting>) => <ListingQuantityDisplay listing={record} />,
  sorter: (a: any, b: any): any => b.availability.quantity - a.availability.quantity,
  sortDirections: ["descend", "ascend"] as any, // SortOrder from antd,
};

type MakeColumns<L extends RealRelisting> = (listings: WithId<L>[]) => ColumnsType<WithId<L>>;

const visibleColumn = {
  title: "Visible",
  dataIndex: "price",
  key: "price",
  width: 80,
  className: "text-center letter-spacing--1",
  render: (value: number, relisting: WithId<RealRelisting>) => (
    <ActiveWidget relisting={relisting} />
  ),
};

const defaultPriceColumn = makeCoopPriceColumn({});

const makeColumnsMap: {
  [C in RealRelisting["category"]]: MakeColumns<NarrowFromCategory<RealRelisting, C>>;
} = {
  "cut-flower": () => [
    photoColumn,
    FlowerVarietyDetailsColumn,
    colorColumn,
    stemLengthColumn,
    OriginalGrowerColumn,
    makeCoopPriceColumn({ title: <>Price / Stem</> }),
    StockColumn,
    AvailabilityColumn,
    visibleColumn,
  ],
  bouquet: () => [
    photoColumn,
    genericNameColumn,
    colorColumn,
    OriginalGrowerColumn,
    defaultPriceColumn,
    StockColumn,
    AvailabilityColumn,
    visibleColumn,
  ],
  bucket: () => [
    photoColumn,
    genericNameColumn,
    colorColumn,
    OriginalGrowerColumn,
    defaultPriceColumn,
    StockColumn,
    AvailabilityColumn,
    visibleColumn,
  ],
  "dried-flower": () => [
    photoColumn,
    genericNameColumn,
    colorColumn,
    OriginalGrowerColumn,
    defaultPriceColumn,
    StockColumn,
    AvailabilityColumn,
    visibleColumn,
  ],
  "potted-plant": () => [
    photoColumn,
    genericNameColumn,
    OriginalGrowerColumn,
    defaultPriceColumn,
    StockColumn,
    AvailabilityColumn,
    visibleColumn,
  ],
  csa: () => [
    photoColumn,
    genericNameColumn,
    OriginalGrowerColumn,
    defaultPriceColumn,
    StockColumn,
    visibleColumn,
  ],
  wreath: () => [
    photoColumn,
    genericNameColumn,
    OriginalGrowerColumn,
    defaultPriceColumn,
    StockColumn,
    AvailabilityColumn,
    visibleColumn,
  ],
};

const InventoryTableMemo = React.memo(InventoryTable);

function InventoryTable<C extends RealRelisting["category"] = RealRelisting["category"]>({
  listings,
  category,
  loading,
}: {
  listings: WithId<NarrowFromCategory<RealRelisting, C>>[];
  category: C;
  loading?: boolean;
}) {
  const { activeRole } = useRooted();
  assertIsCoop(activeRole);

  const columns = useMemo(() => makeColumnsMap[category](listings as any), [category, listings]);

  // We need to filter by category again here -- there is an unfortunate race condition where the category
  // can change but the query does not re-run until the next render, in which case the table
  // will display listings from a different category with the wrong columns
  const filteredListings = useMemo(
    () => listings.filter((listing) => listing.category === category),
    [category, listings]
  );

  // We protect these invariants in `makeColumnsMap`
  return (
    <Table
      loading={loading}
      columns={columns as any}
      dataSource={filteredListings as any}
      size="small"
      className="table-x-scroll"
      rowKey="_id"
    />
  );
}
