import { useEffect } from "react";
import { LocalAccountState, createCookie, deleteCookie } from "../../services/account-state";

/**
 * Sets a cookie readable across all subdomains so that the other frontend site can
 * tell whether a user is logged in and what their UID is
 * @param account the current account state
 */
function useUpdateAccountStatusCookie(account: LocalAccountState) {
  // if account.status is logged in + complete, set a cookie showing a user is logged in.
  // Cookie expires by default at the end of the session, so its here to get reset each time

  useEffect(() => {
    if (account.status === "signed-in-complete") {
      createCookie(account);

      // delete cookie
    } else {
      deleteCookie();
    }
  }, [account]);
}

export { useUpdateAccountStatusCookie };
