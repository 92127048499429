import { FirestoreDocument, removeUndefined } from "@rooted/shared";
import { Button, Form, message } from "antd";
import { FormInstance, FormProps } from "antd/lib/form/Form";
import firebase from "firebase/app";
import React, { useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Prompt } from "react-router-dom";
import { isEqual } from "lodash";

export interface FirebaseDocFormProps extends FormProps {
  data: FirestoreDocument | any;
  dbRef: firebase.firestore.DocumentReference;
  insertSubmitButton?: boolean;
  onStatusChange?: (x: FormActionStatus) => void;
  replaceOnSubmit?: (x: any) => any;
  status?: FormActionStatus;
  rootFieldPath?: string;
  buttonText?: string;
  scrollToTop?: boolean; // scroll back to top on save
  form?: FormInstance<any>;
}

export type FormActionStatus = "unchanged" | "changed" | "submitting" | "submitted";

/**
 * Generic form component for data from a single firebase document.
 * Pass in the document ('data') and a reference by which to set it ('dbRef').
 * Then, as children, add the controls (Form.Item components) with the 'name' props
 * set to the name of the field in your document.
 */
export const FirestoreDocForm: React.FC<FirebaseDocFormProps> = ({
  data,
  dbRef,
  children,
  status,
  onStatusChange,
  onFinish,
  replaceOnSubmit,
  rootFieldPath,
  insertSubmitButton = true,
  buttonText = "Save",
  scrollToTop = true,
  requiredMark,
  ...rest
}) => {
  const [internalState, setInternalState] = useState<FormActionStatus>("unchanged");

  const state = internalState || status;
  const setState = (x: FormActionStatus) => {
    setInternalState(x);
    onStatusChange?.(x);
  };

  return (
    <Form
      requiredMark={requiredMark ?? "optional"}
      layout="vertical"
      {...rest}
      onFieldsChange={() => {
        if (state === "unchanged" || state === "submitted") setState("changed");
      }}
      onFinish={async (values) => {
        try {
          setState("submitting");

          // run onFinish input and overwrite values, if defined
          values = replaceOnSubmit?.(values) || values;

          await (rootFieldPath
            ? dbRef.set({ [rootFieldPath]: removeUndefined(values) }, { merge: true })
            : dbRef.set(removeUndefined(values), { merge: true }));
          setState("submitted");

          onFinish?.(removeUndefined(values));

          // scroll back to the top of the page
          if (scrollToTop) {
            window.scrollTo(0, 0);
          }
        } catch (e) {
          // TODO What to do about this error? It should never occur in production.
          // If it happens, that probably means the security rules are wrong.
          console.error(e);
          message.error("Form failed to submit.");
          setState("changed");
        }
      }}
      initialValues={data}
      scrollToFirstError={true}
    >
      {children}
      {insertSubmitButton && (
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={state === "unchanged" || state === "submitted"}
            loading={state === "submitting"}
          >
            {state === "submitted" ? (
              <>
                Saved <CheckOutlined />
              </>
            ) : (
              <>{buttonText}</>
            )}
          </Button>
        </Form.Item>
      )}
      <Prompt
        when={state === "changed"}
        message="You have unsaved changes. Are you sure you want to abandon them?"
      />
    </Form>
  );
};
