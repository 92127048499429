import {
  FullBuyerRole,
  ChildOrder,
  OrderItem,
  FullSellerRole,
  WithId,
  isSeller,
  getOrderDetailsBySeller,
  filterByOriginalSeller,
} from "@rooted/shared";
import { Table, Button, List, notification, Tooltip, Tag, Col, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useState, useMemo } from "react";
import { GrowerAddOrderItemModal } from "./GrowerAddOrderItemModal";
import { formatCurrency } from "../FormControls/CurrencyInput";
import {
  childOrderNameColumn,
  childOrderTotalColumn,
  makeChildOrderPriceColumn,
  makeChildOrderQuantityColumn,
  childOrderOriginalGrowerColumn,
} from "../TableColumns/ChildOrderItemColumns";
import { CoopAddOrderItemModal } from "./CoopAddOrderItemModal";
import { GenericPriceWidget } from "../Widgets/GenericPriceWidget";
import { changeOrderFulfillmentPrice, useChildOrderItems } from "../../services/sellers/orders";
import { EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { HandlingFeePercentWidget } from "../Widgets/HandlingFeePercentWidget";

/**
 * A list of the items in a child order, as viewed by a seller or buyer.
 */
export const ChildOrderItems: React.FC<{
  order: WithId<ChildOrder>;
  role: FullBuyerRole | FullSellerRole;
  editable: boolean;
}> = ({ order, role, editable }) => {
  const [addItemModalOpen, setAddItemModalOpen] = useState(false);
  const [allOrderItems = [], loading] = useChildOrderItems(order._id);

  // The items visible to the acting user. If a seller, this doesn't include other sellers' items.
  const orderItems = useMemo(() => {
    if (isSeller(role) && order.sellerId !== role.profileId)
      return allOrderItems.filter(filterByOriginalSeller(role.profileId));
    return allOrderItems;
  }, [allOrderItems, order.sellerId, role]);

  const columns: ColumnsType<OrderItem> = [
    childOrderNameColumn,
    childOrderOriginalGrowerColumn,
    makeChildOrderPriceColumn(order, editable),
    makeChildOrderQuantityColumn(order, editable),
    childOrderTotalColumn,
  ];

  const { calculatedPricing: pricing } = getOrderDetailsBySeller({
    order,
    sellerId: isSeller(role) ? role.profileId : order.sellerId,
  });

  const deliveryFeeEdited =
    order.fulfillment.originalPrice !== undefined &&
    order.fulfillment.originalPrice !== pricing.fulfillmentPrice;

  const isPrimarySeller = isSeller(role) && role.profileId === order.sellerId;
  const isBackingSeller = isSeller(role) && !isPrimarySeller;

  return (
    <>
      <Table
        rowKey={(o) => `${o.listing._id}-${o.status}`}
        pagination={false}
        loading={loading}
        dataSource={orderItems}
        columns={columns}
        className="table-x-scroll"
      />

      {!isBackingSeller ? (
        // For the seller in charge of the order (coop or farm if direct-to-farm) then show the whole breakdown.
        // also show whole breakdown to the buyer
        <List size="small">
          <List.Item actions={[formatCurrency(pricing.subtotal)]}>Subtotal</List.Item>

          {order.applicableTaxes.map((tax, index) => (
            <List.Item
              // I'm not sure what the correct identifying thing is for this, so I'm just using everything.
              key={`tax-name: ${tax.name} ${tax._created} ${tax.rate} ${tax.active}`}
              actions={[formatCurrency(pricing.taxBurden[index])]}
            >
              {tax.name}
            </List.Item>
          ))}

          {((pricing.handlingFee !== undefined && pricing.handlingFee > 0) ||
            order.fulfillment.originalHandlingFeePercent !== undefined) && (
            <List.Item actions={[formatCurrency(pricing.handlingFee ?? 0)]}>
              Handling Fee (
              <HandlingFeePercentWidget
                orderId={order._id}
                handlingFeePercent={order.fulfillment.handlingFeePercent ?? 0}
                originalHandlingFeePercent={order.fulfillment.originalHandlingFeePercent}
                editable={editable}
              />
              )
            </List.Item>
          )}

          {/* The backing seller can always view / edit the fulfillment price, even if it's 0 */}
          {order.fulfillment.type === "physical" &&
            order.fulfillment.modality === "delivery" &&
            (isPrimarySeller ||
              pricing.fulfillmentPrice > 0 ||
              order.fulfillment.originalPrice !== undefined) && (
              <List.Item
                actions={[
                  <GenericPriceWidget
                    editable={editable}
                    key="edit-price"
                    title={"Edit Delivery Price"}
                    price={pricing.fulfillmentPrice}
                    originalPrice={order.fulfillment.originalPrice}
                    onChange={async (newPrice) => {
                      try {
                        await changeOrderFulfillmentPrice(order._id, newPrice);
                      } catch (error) {
                        notification.error({
                          message: "Oops! Something went wrong updating your delivery fee.",
                        });
                      }
                    }}
                  />,
                ]}
              >
                <Col>
                  {deliveryFeeEdited && (
                    <Tooltip title={"This delivery fee was changed after the original order"}>
                      <Tag
                        icon={<EditOutlined className={"not-button"} />}
                        color="warning"
                        style={{ marginBottom: 4, marginLeft: -12 }}
                      >
                        Changed
                      </Tag>
                      <br />
                    </Tooltip>
                  )}
                  Delivery
                </Col>
              </List.Item>
            )}
          <List.Item actions={[formatCurrency(pricing.total)]}>
            <b>Total</b>
          </List.Item>
        </List>
      ) : (
        // For a seller that part of a one-to-many coop sale, only show their total of the breakdown.
        <>
          <List size="small">
            <List.Item actions={[formatCurrency(pricing.total)]}>
              <b>Total</b>{" "}
              <Tooltip
                title={
                  "This order was placed through your coop, and may include items from other sellers and/or processing fees that are not visible to you."
                }
              >
                <QuestionCircleOutlined style={{ color: "gray", marginLeft: 8 }} />
              </Tooltip>
            </List.Item>
          </List>
        </>
      )}

      {editable && (
        <>
          <Button
            type="dashed"
            style={{
              width: "100%",
              marginTop: 16,
            }}
            onClick={() => setAddItemModalOpen(true)}
          >
            Add Another Item
          </Button>
          {role.type === "grower" && (
            <GrowerAddOrderItemModal
              order={order}
              onFinish={() => setAddItemModalOpen(false)}
              visible={addItemModalOpen}
            />
          )}
          {role.type === "coop" && (
            <CoopAddOrderItemModal
              order={order}
              role={role}
              onFinish={() => setAddItemModalOpen(false)}
              visible={addItemModalOpen}
            />
          )}
        </>
      )}
    </>
  );
};
