import {
  ApprovedConnection,
  ApprovedConnectionUpdate,
  Connection,
  CoopProfile,
  distance,
  EnterpriseProfile,
  ExtractProfileType,
  FullBuyerRole,
  FullCoopRole,
  FullGrowerRole,
  FullSellerRole,
  FullWholesaleBuyerRole,
  getGeohashRange,
  GrowerProfile,
  Location,
  makeConnectionId,
  Profile,
  removeUndefined,
  RequestedConnection,
  WholesaleBuyerProfile,
  WithId,
} from "@rooted/shared";
import firebase from "firebase/app";
import { useEffect, useRef, useState } from "react";
import { functions, db, useCollectionDataChecked, useDocumentDataChecked } from "./firebase";
import { CollectionHookReturn } from "./higher-order-searches";

export function shouldOrderingBeEnabled({
  pickupEnabled,
  deliveryEnabled,
}: {
  pickupEnabled: boolean;
  deliveryEnabled: boolean;
}): boolean {
  return pickupEnabled || deliveryEnabled;
}

/**
 * Update an approved connection as a seller with only the allowed fields
 */
export const updateConnection = (connectionId: string, changes: ApprovedConnectionUpdate) => {
  return db
    .collection("connections")
    .doc(connectionId)
    .set(removeUndefined(changes), { merge: true });
};

/**
 * Set the tags on an active connection
 */
export const setConnectionTags = (id: string, tags: string[] = []) =>
  updateConnection(id, { tags });

/**
 * React hook to get the data for a connection between a given buyer and seller
 * @param buyerId Id of the buyer's profile
 * @param sellerId Id of the seller's profile
 */
export const useConnection = (buyerProfileId: string, sellerProfileId: string) => {
  const connectionId = makeConnectionId({ buyerProfileId, sellerProfileId });
  return useDocumentDataChecked<WithId<Connection>>(
    db.collection("connections").doc(connectionId),
    {
      idField: "_id",
    }
  );
};

/**
 * Approve a given pending connection. No-op if used on an already-approved connection.
 * @param id Id of the connection
 */
export const approveConnection = (id: string) => functions.httpsCallable("approveConnection")(id);

/**
 * Remove a given existing or pending connection.
 * @param id Id of the connection
 */
export const removeConnection = (id: string) => db.collection("connections").doc(id).delete();

/**
 * Request a connection to a coop as a wholesale buyer.
 * @param role The role of the buyer requesting the connection
 * @param seller The profile of the seller with whom the buyer wants to connect
 */
export const requestConnectionToCoop = async (
  role: FullWholesaleBuyerRole,
  seller: WithId<CoopProfile>
) => {
  const c: RequestedConnection = {
    type: "coop-wholesale",
    status: "buyer-requested",
    sellerId: seller._id,
    buyerId: role.profileId,

    requestedOn: firebase.firestore.Timestamp.now(),
  };
  const connectionId = makeConnectionId({
    buyerProfileId: role.profileId,
    sellerProfileId: seller._id,
  });
  db.collection("connections").doc(connectionId).set(c);
};

/**
 * Request a connection to a grower as a wholesale buyer.
 * @param role The role of the buyer requesting the connection
 * @param grower The profile of the grower with whom the buyer wants to connect
 */
export const requestConnectionToGrower = async (
  role: FullWholesaleBuyerRole,
  grower: WithId<GrowerProfile>
) => {
  const c: RequestedConnection = {
    type: "grower-wholesale",
    status: "buyer-requested",
    sellerId: grower._id,
    buyerId: role.profileId,

    requestedOn: firebase.firestore.Timestamp.now(),
  };

  const connectionId = makeConnectionId({
    buyerProfileId: role.profileId,
    sellerProfileId: grower._id,
  });

  db.collection("connections").doc(connectionId).set(c);
};

/**
 * Request a connection to a buyer as a seller (coop or grower).
 * @param role The role of the coop or grower requesting the connection.
 * @param buyer The profile of the buyer to send the request to.
 */
export const requestConnectionToBuyer = async (
  role: FullGrowerRole | FullCoopRole,
  buyer: WithId<WholesaleBuyerProfile>
) => {
  if (role.type === "grower") {
    const c: RequestedConnection = {
      type: "grower-wholesale",
      status: "seller-requested",
      sellerId: role.profileId,
      buyerId: buyer._id,

      requestedOn: firebase.firestore.Timestamp.now(),
    };
    console.log("REQUEST", { role, buyer, c });

    const connectionId = makeConnectionId({
      buyerProfileId: buyer._id,
      sellerProfileId: role.profileId,
    });

    return db.collection("connections").doc(`${buyer._id}-${role.profileId}`).set(c);
  } else {
    const c: RequestedConnection = {
      type: "coop-wholesale",
      status: "seller-requested",
      sellerId: role.profileId,
      buyerId: buyer._id,

      requestedOn: firebase.firestore.Timestamp.now(),
    };

    return db.collection("connections").doc(`${buyer._id}-${role.profileId}`).set(c);
  }
};

/**
 * React hook to listen to connection requests for a given buyer
 * @param role The role of the buyer
 */
export const useBuyerConnectionRequests = (role: FullWholesaleBuyerRole) => {
  return useCollectionDataChecked<WithId<RequestedConnection>>(
    db
      .collection("connections")
      .where("status", "==", "seller-requested")
      .where("buyerId", "==", role.profileId),
    { idField: "_id" }
  );
};

/**
 * React hook to listen to outgoing requests from a given buyer
 * @param role The role of the buyer
 */
export const useBuyerPendingConnections = (role: FullWholesaleBuyerRole) => {
  return useCollectionDataChecked<WithId<RequestedConnection>>(
    db
      .collection("connections")
      .where("status", "==", "buyer-requested")
      .where("buyerId", "==", role.profileId),
    { idField: "_id" }
  );
};

/**
 * React hook to listen to approved connections for a given buyer.
 *
 * This is designed to accept wholesale *and* retail buyers, but always
 * returns an empty result for retail buyers as they have no connections.
 *
 * @param role The role of the buyer
 */
export const useBuyerConnections = (role: FullBuyerRole) => {
  return useCollectionDataChecked<WithId<ApprovedConnection>>(
    db
      .collection("connections")
      .where("buyerId", "==", role.profileId)
      .where("status", "==", "approved"),
    { idField: "_id" }
  );
};

/**
 * React hook to listen to any-status connections for a given buyer.
 * @param role The role of the buyer
 */
export const useAllBuyerConnections = (role: FullWholesaleBuyerRole) => {
  return useCollectionDataChecked<WithId<ApprovedConnection>>(
    db.collection("connections").where("buyerId", "==", role.profileId),
    { idField: "_id" }
  );
};

/**
 * React hook to listen to connection requests for a given grower
 * @param role The role of the grower
 */
export const useSellerConnectionRequests = (role: FullSellerRole) => {
  return useCollectionDataChecked<WithId<RequestedConnection>>(
    db
      .collection("connections")
      .where("status", "==", "buyer-requested")
      .where("sellerId", "==", role.profileId),
    { idField: "_id" }
  );
};

/**
 * React hook to listen to pending connections for a given seller
 * @param role The role of the grower
 */
export const useSellerPendingConnections = (role: FullSellerRole) => {
  return useCollectionDataChecked<WithId<RequestedConnection>>(
    db
      .collection("connections")
      .where("status", "==", "seller-requested")
      .where("sellerId", "==", role.profileId),
    { idField: "_id" }
  );
};

/**
 * React hook to listen to approved connections for a given grower.
 * @param role The role of the grower
 */
export const useSellerConnections = (role: FullSellerRole) => {
  return useCollectionDataChecked<WithId<ApprovedConnection>>(
    db
      .collection("connections")
      .where("sellerId", "==", role.profileId)
      .where("status", "==", "approved"),
    { idField: "_id" }
  );
};

type ConnectionAndProfile<P extends Profile> = {
  connection: WithId<ApprovedConnection>;
  profile: WithId<P>;
};

type ConnectionAndWholesaleBuyerProfile = ConnectionAndProfile<WholesaleBuyerProfile>;

/**
 * Fetch all of a seller's buyers, and their profiles.
 * This is memoized to ensure that the profile lookups only occur when the connections change.
 */
export const useSellersConnectedBuyersProfiles = (
  role: FullSellerRole
): CollectionHookReturn<ConnectionAndWholesaleBuyerProfile> => {
  const [connections, connectionsLoading, connectionsError] = useSellerConnections(role);

  const [[profiles, profilesLoading, profilesError], setProfilesHookReturn] = useState<
    CollectionHookReturn<ConnectionAndWholesaleBuyerProfile>
  >([undefined, true, undefined]);

  const fetchRequestTimestamp = useRef<number | null>(null);

  useEffect(() => {
    if (!connections) return;

    const fetchProfiles = async () => {
      const timestamp = Date.now();
      fetchRequestTimestamp.current = timestamp;
      const getConnectionProfileRequests = connections
        .filter((c) => (c.type = "grower-wholesale"))
        .map(async (connection) => {
          const { buyerId } = connection;
          const snapshot = await db.collection("profiles").doc(buyerId).get();
          if (!snapshot.exists) return;

          const profile = Object.assign(snapshot.data() as WholesaleBuyerProfile, {
            _id: buyerId,
          });
          return {
            profile,
            connection,
          };
        });

      try {
        // Ensure we filter out undefined, which is returned if the snapshot is not found (the connection profile does not exist)
        const data = await Promise.all(getConnectionProfileRequests);
        const filteredData = data.filter((d) => !!d) as ConnectionAndWholesaleBuyerProfile[];
        setProfilesHookReturn([filteredData, false, undefined]);
      } catch (error) {
        setProfilesHookReturn([undefined, false, error]);
      }
    };

    fetchProfiles();
  }, [connections, connectionsLoading]);

  return [profiles, profilesLoading, connectionsError || profilesError];
};

/**
 * React hook to listen to any status connections for a given grower.
 * @param role The role of the grower
 */
export const useAllSellerConnections = (role: FullSellerRole) => {
  return useCollectionDataChecked<WithId<ApprovedConnection>>(
    db.collection("connections").where("sellerId", "==", role.profileId),
    { idField: "_id" }
  );
};

/**
 * React hook to listen to approved connections for a given grower.
 * @param role The role of the grower
 */
export function useNearbyProfiles<T extends EnterpriseProfile>(
  location: Location,
  type: ExtractProfileType<T>,
  maxDistance = 200,
  limit = 75
) {
  const { lower, upper } = getGeohashRange(
    location.geopoint.latitude,
    location.geopoint.longitude,
    maxDistance
  );

  const [data, loading, error] = useCollectionDataChecked<WithId<T>>(
    db.collection("profiles").where("type", "==", type).limit(9999),

    { idField: "_id" }
  );

  if (!data) return [data, loading, error] as CollectionHookReturn<T>;
  return [
    data
      .filter((x) => !x.disabled && x.bio.location && x.bio.location.geopoint)
      // ^ Ugly fix for now until we remove all data that doesn't fit out schema
      .filter((x) => distance(x.bio.location, location) < maxDistance)
      // Client-side filer for hidden profiles
      .filter((profile) => !profile.hiddenInSearch)
      .sort((a, b) => distance(a.bio.location, location) - distance(b.bio.location, location))
      .slice(0, limit),
    loading,
    error,
  ] as CollectionHookReturn<T>;
}
