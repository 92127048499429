import React from "react";
import { ApplicationDetails } from "./ApplicationDetails";
import { Badge, Menu } from "antd";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { NotFoundPage, PageContent, WideLayout } from "../../../views/layouts";
import { CreatedApplications } from "./CreatedApplications";
import { RejectedApplications } from "./RejectedApplications";
import { InProgressApplications } from "./InProgressApplications";
import { EmptyApplications } from "./EmptyApplications";
import {
  useApprovedCompleteApplications,
  useApprovedIncompleteApplications,
  useCreatedApplications,
  useEmptyApplications,
  useInProgressApplications,
  useNeedsApprovalApplications,
  useRejectedApplications,
} from "./useApplications";
import { CollapsingSider } from "../../../components/Sider/CollapsingSider";
import { useCount } from "../../../services/higher-order-searches";
import { NeedsApprovalApplications } from "./NeedsApprovalApplications";
import { ApprovedIncompleteApplications } from "./ApprovedIncompleteApplications";
import { ApprovedCompleteApplications } from "./ApprovedCompleteApplications";
export * from "./ApplicationDetails";

export const Applications: React.FC = () => {
  const category = useLocation().pathname.split("/")[3] || "pending";

  return (
    <>
      <WideLayout>
        <CollapsingSider
          defaultOpenKeys={["stage-1", "stage-2"]}
          mode="inline"
          selectedKeys={[category]}
        >
          <Menu.SubMenu key="stage-1" title="Stage 1">
            <Menu.Item key="empty">
              <Link to="empty">Empty</Link>&nbsp;
              <Badge count={useCount(useEmptyApplications("all"))} />
            </Menu.Item>
            <Menu.Item key="in-progress">
              <Link to="in-progress">In progress</Link>&nbsp;
              <Badge count={useCount(useInProgressApplications("all"))} />
            </Menu.Item>
            <Menu.Item key="needs-approval">
              <Link to="needs-approval">Complete</Link>&nbsp;
              <Badge count={useCount(useNeedsApprovalApplications("all"))} />
            </Menu.Item>
            <Menu.Item key="waitlisted">
              <Link to="waitlisted">Waitlisted</Link>&nbsp;
              <Badge count={useCount(useRejectedApplications("all"))} />
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu key="stage-2" title="Stage 2">
            <Menu.Item key="approved-incomplete">
              <Link to="approved-incomplete">Incomplete</Link>&nbsp;
              <Badge count={useCount(useApprovedIncompleteApplications("all"))} />
            </Menu.Item>
            <Menu.Item key="approved-complete">
              <Link to="approved-complete">Complete</Link>&nbsp;
              <Badge count={useCount(useApprovedCompleteApplications("all"))} />
            </Menu.Item>
            <Menu.Item key="profile-created">
              <Link to="profile-created">Profile Created</Link>&nbsp;
              <Badge count={useCount(useCreatedApplications("all"))} />
            </Menu.Item>
          </Menu.SubMenu>
        </CollapsingSider>
        <PageContent>
          <Routes>
            <Route path="empty/*" element={<EmptyApplications />} />
            <Route path="in-progress/*" element={<InProgressApplications />} />
            <Route path="needs-approval/*" element={<NeedsApprovalApplications />} />
            <Route path="waitlisted/*" element={<RejectedApplications />} />
            <Route path="approved-incomplete/*" element={<ApprovedIncompleteApplications />} />
            <Route path="approved-complete/*" element={<ApprovedCompleteApplications />} />
            <Route path="profile-created/*" element={<CreatedApplications />} />
            <Route path="item/:itemId" element={<ApplicationDetails />} />
            <Route path="" element={<InProgressApplications />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </PageContent>
      </WideLayout>
    </>
  );
};
