import { GrowerApplicationForm, WithId } from "@rooted/shared";
import { Checkbox, Divider, Form, Input, InputNumber, Tooltip, Select } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FirestoreDocForm } from "../../../components/FormControls/FirestoreDocForm";
import { HowDidYouHear } from "../../../components/FormControls/HowDidYouHear";
import { submitApplication } from "../../../services/applications";
import { db } from "../../../services/firebase";
import { QuestionCircleOutlined } from "@ant-design/icons";

export const GrowerDetailsForm: React.FC<{
  application: WithId<GrowerApplicationForm>;
  disabled: boolean;
  adminView?: boolean;
}> = ({ application, disabled = false, adminView }) => {
  const navigate = useNavigate();
  return (
    <FirestoreDocForm
      layout="vertical"
      buttonText="Submit"
      onStatusChange={(x) => {
        if (x === "submitted") {
          navigate(`/onboarding/applications/${application._id}/review`);
        }
      }}
      onFinish={async (values: any) => {
        await submitApplication({ applicationId: application._id });
      }}
      rootFieldPath="details"
      data={application.details}
      dbRef={db.collection("applications").doc(application._id)}
    >
      <GrowerDetailsFormItems disabled={disabled} adminView={adminView} />
    </FirestoreDocForm>
  );
};

export const GrowerDetailsFormItems: React.FC<{ disabled?: boolean; adminView?: boolean }> = ({
  disabled = false,
  adminView,
}) => (
  <>
    <ol style={{ paddingLeft: 25 }}>
      <li>
        <Form.Item
          name="yearsGrowing"
          label="How many years have you been growing flowers?"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <InputNumber disabled={disabled} min={0} max={500} />
        </Form.Item>
      </li>
      <li>
        <Form.Item
          name="yearsSellingWholesale"
          label="How many years have you been selling to florists or wholesalers?"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <InputNumber disabled={disabled} min={0} max={500} />
        </Form.Item>
      </li>
      <li>
        <Form.Item
          name="yearsSellingRetail"
          label="How many years have you been selling through farmers markets, CSAs, or farm stands:"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <InputNumber disabled={disabled} min={0} max={500} />
        </Form.Item>
      </li>
      <li>
        <Form.Item
          name="yearsSellingWeddings"
          label="How many years have you been selling for weddings and events?"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <InputNumber disabled={disabled} min={0} max={500} />
        </Form.Item>
      </li>

      <li>
        <Form.Item
          name="landInProduction"
          label="About how much land do you have in production?"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <Select
            style={{ maxWidth: 200, width: "100%" }}
            defaultValue="-- Choose One --"
            disabled={disabled}
          >
            <Select.Option value=".25 Acres">.25 Acres</Select.Option>
            <Select.Option value=".5 Acres">.5 Acres</Select.Option>
            <Select.Option value=".5 - 1 Acres">.5 - 1 Acres</Select.Option>
            <Select.Option value="1 - 2 Acres">1 - 2 Acres</Select.Option>
            <Select.Option value="2 - 5 Acres">2 - 5 Acres</Select.Option>
            <Select.Option value="5 - 10 Acres">5 - 10 Acres</Select.Option>
            <Select.Option value="10+ Acres">10+ Acres</Select.Option>
          </Select>
        </Form.Item>
      </li>
      <li>
        <Form.Item label="When is your farm in production (flowers only)?">
          <Form.Item
            name={["monthsGrowing", "january"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>January</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "february"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>February</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "march"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>March</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "april"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>April</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "may"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>May</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "june"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>June</Checkbox>
          </Form.Item>
          <br />
          <Form.Item
            name={["monthsGrowing", "july"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>July</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "august"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>August</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "september"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>September</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "october"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>October</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "november"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>November</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "december"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>December</Checkbox>
          </Form.Item>
        </Form.Item>
      </li>
      <li>
        <Form.Item
          name="aboutBusiness"
          label={
            <>
              About you / your farm (private to Rooted Admin)&nbsp;
              <Tooltip
                placement="topRight"
                title="Hints: How did you get started?  Why do you enjoy farming?  What sets you apart?  Do you have any awards/certifications/press that you're excited to share?"
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          }
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <Input.TextArea rows={4} disabled={disabled} />
        </Form.Item>
      </li>
      <li>
        <Form.Item label="Are you a member of any of our partner organizations?">
          <Form.Item name="slowFlowersMember" noStyle initialValue={false} valuePropName="checked">
            <Checkbox disabled={disabled}>Slow Flowers</Checkbox>
          </Form.Item>
          <Form.Item name="ASCFGMember" noStyle initialValue={false} valuePropName="checked">
            <Checkbox disabled={disabled}>ASCFG</Checkbox>
          </Form.Item>
        </Form.Item>
      </li>

      <li>
        <HowDidYouHear disabled={disabled} adminView={adminView} />
      </li>
    </ol>

    <br />
    <Divider>Legal</Divider>

    <Form.Item
      name="signedAgreement"
      label={
        <>
          Please confirm you have read the&nbsp;
          <a href="https://www.rootedfarmers.com/terms" target="_blank" rel="noreferrer">
            Terms of Service.
          </a>
        </>
      }
      valuePropName="checked"
      rules={[
        {
          required: true,
          message: "Please accept the Terms of Service",
          validator: (_, value) =>
            value ? Promise.resolve() : Promise.reject("Please accept the Terms of Service"),
        },
      ]}
    >
      <Checkbox>I have read and agree to the Terms of Service</Checkbox>
    </Form.Item>
  </>
);
