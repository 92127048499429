import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Select } from "antd";
import React from "react";
import { CurrencyInput } from "../../../../components/FormControls/CurrencyInput";

export const PlanFormItems: React.FC = () => (
  <>
    <Form.Item label="Group" name="group" rules={[{ required: true }]}>
      <Select>
        <Select.Option value="all">All</Select.Option>
        <Select.Option value="ascfg">ASCFG members</Select.Option>
        <Select.Option value="slow-flowers">Slow Flowers members</Select.Option>
        <Select.Option value="hidden">Hidden</Select.Option>
      </Select>
    </Form.Item>
    <Form.Item label="Type" name="type" rules={[{ required: true }]}>
      <Select>
        <Select.Option value="grower">Grower</Select.Option>
        <Select.Option value="coop">Coop</Select.Option>
      </Select>
    </Form.Item>
    <Form.Item label="Name" name="name" rules={[{ required: true }]}>
      <Input />
    </Form.Item>
    <Form.Item label="Description" name="description" rules={[{ required: true }]}>
      <Input.TextArea rows={3} />
    </Form.Item>
    <Form.Item label="Price" name="price" rules={[{ required: true }]}>
      <CurrencyInput />
    </Form.Item>
    <Form.Item
      label="Online Transaction Fee"
      name="onlineTransactionFeePercent"
      rules={[{ required: true }]}
    >
      <InputNumber
        min={0}
        max={100}
        formatter={(value) => `${value}%`}
        parser={(value) => value?.replace("%", "") || 0}
      />
    </Form.Item>
    <Form.Item
      label="Point of Sale Transaction Fee"
      name="posTransactionFeePercent"
      rules={[{ required: true }]}
    >
      <InputNumber
        min={0}
        max={100}
        formatter={(value) => `${value}%`}
        parser={(value) => value?.replace("%", "") || 0}
      />
    </Form.Item>
    <Form.Item
      label="Internal permission level (don't edit)"
      name="permissionTier"
      rules={[{ required: true }]}
    >
      <InputNumber />
    </Form.Item>
    <Form.Item
      label="Stripe ID (This is the PRICE ID associated with the given subscription product)"
      name="stripeId"
      rules={[{ required: true }]}
    >
      <Input placeholder="plan_xxxxxxxxxxxxxx" />
    </Form.Item>
    <Form.List name="benefits">
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item key={field.key} label={index === 0 ? "Benefits" : ""} required>
              <Form.Item
                {...field}
                validateTrigger={["onChange", "onBlur"]}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input benefit or delete this field",
                  },
                ]}
                noStyle
              >
                <Input placeholder="Benefit text" />
              </Form.Item>
              {fields.length > 1 ? (
                <>
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    style={{ marginLeft: "5px" }}
                    onClick={() => remove(field.name)}
                  />
                </>
              ) : null}
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="ghost"
              onClick={() => add()}
              style={{ width: "100%" }}
              icon={<PlusOutlined />}
            >
              Add Benefit
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  </>
);
