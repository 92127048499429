import { ApprovedConnection, WithId } from "@rooted/shared";
import { notification, Switch, Tag, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { logError } from "../../sentry";
import { updateConnection } from "../../services/connections";

export const OrderingEnabledSwitch: React.FC<{
  loading: boolean;
  connection: WithId<ApprovedConnection>;
  editable: boolean;
}> = ({ connection, editable, loading }) => {
  const [internalValue, setInternalValue] = useState(connection?.orderingEnabled);
  useEffect(() => {
    setInternalValue(connection?.orderingEnabled);
  }, [connection]);

  return (
    // Always accommodate for the width of the tag
    <div style={{ width: 72, flexDirection: "column", display: "flex", alignItems: "flex-end" }}>
      <Switch
        style={{ right: 0 }}
        loading={loading || connection?.orderingEnabled !== internalValue}
        checked={internalValue}
        onChange={
          connection
            ? async (orderingEnabled) => {
                setInternalValue(orderingEnabled);
                try {
                  await updateConnection(connection._id, { orderingEnabled });
                } catch (error) {
                  notification.error({
                    message: "Something went wrong updating your connection. Please try again.",
                    description: error?.message,
                  });
                  logError({
                    error,
                    extraData: {
                      connection,
                      orderingEnabled,
                    },
                  });
                }
              }
            : undefined
        }
        disabled={!connection || !editable}
      />
      {connection && !connection.orderingEnabled && (
        <Tooltip
          title={
            "This buyer won't be able to see any of your inventory until you enable them again."
          }
        >
          <Tag color={"warning"} style={{ marginTop: 8, marginRight: 0, display: "block" }}>
            Disabled
          </Tag>
        </Tooltip>
      )}
    </div>
  );
};
