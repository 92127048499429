import { Button, Tooltip } from "antd";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import React from "react";

/**
 * Toggle button for card vs table view
 */
export const CardViewToggle: React.FC<{
  value: boolean;
  onChange: (x: boolean) => void;
}> = ({ value, onChange }) => {
  return (
    <Tooltip title={value ? "Switch to Table View" : "Switch to Card View"}>
      View:{" "}
      <Button type="text" onClick={() => onChange(!value)}>
        {value ? <BarsOutlined /> : <AppstoreOutlined />}
      </Button>
    </Tooltip>
  );
};
