import { Col, Image, Typography } from "antd";
import React from "react";

import "./EmailPreview.less";

type EmailType = "invite-buyers" | "invite-sellers" | "notify-buyer";

const images: { [o in EmailType]: string } = {
  "invite-buyers": "/images/misc/email-sample-farm-invites-buyer.png",
  "invite-sellers": "/images/misc/email-sample-buyer-invites-farmer.png",
  "notify-buyer": "/images/misc/email-sample-notify-buyer.png",
};

const EmailPreview: React.FC<{ emailType: EmailType }> = ({ emailType }) => (
  <Col>
    <div style={{ marginBottom: 5, textAlign: "center" }}>
      <Typography.Text type="secondary">Sample Email</Typography.Text>
    </div>
    <Image preview alt="Sample email" src={images[emailType]} wrapperClassName="sample-email" />
  </Col>
);

export default EmailPreview;
