import React, { useMemo } from "react";
import { storageUrlTo100x100Url } from "./getResizedUrl";
import { logError } from "../../sentry";
import { EnterpriseProfileBio } from "@rooted/shared";
import { AvatarProps, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

type ThumbnailAvatarProps = {
  src?: string;
  alt?: string;
  size?: AvatarProps["size"];
  style?: AvatarProps["style"];
};

export const ThumbnailAvatar: React.FC<ThumbnailAvatarProps> = ({ src, alt, size, style = {} }) => {
  // If thumbnailUrl is null, fallback icon will be used.
  const thumbnailUrl = useMemo(() => {
    if (!src) return null;
    try {
      return storageUrlTo100x100Url(src);
    } catch (error) {
      logError({
        error,
        tags: {
          component: "ThumbnailAvatar",
        },
        extraData: { src },
      });
      return null;
    }
  }, [src]);
  return (
    <Avatar
      style={{
        backgroundColor: "#ccc",
        ...style,
      }}
      size={size}
      alt={alt}
      icon={<UserOutlined />}
      src={thumbnailUrl}
    />
  );
};

/**
 * Automatically converts an enterprise bio into an avatar with alt text, and better fallback.
 */
export const EnterpriseAvatar: React.FC<
  Omit<ThumbnailAvatarProps, "src" | "alt"> & {
    profile: { bio: Pick<EnterpriseProfileBio, "displayName" | "photoStorageUrl"> };
  }
> = ({
  profile: {
    bio: { displayName, photoStorageUrl },
  },
  ...rest // explicitly the rest of the ThumbnailAvatarProps
}) => {
  return <ThumbnailAvatar alt={`Photo of ${displayName}`} src={photoStorageUrl} {...rest} />;
};
