import {
  FullBuyerRole,
  SellerProfile,
  WithId,
  ListingQueryContstraints as ListingQueryConstraints,
  CurrentCart,
  Listing,
  RealProductCategoryKey,
  RealListing,
  Relisting,
  realProductCategories,
  assertIsBuyer,
  isRetail,
} from "@rooted/shared";
import { Table, Tabs, Alert, Typography, Button, Modal } from "antd";
import React, { useState, useMemo, useEffect, useRef } from "react";
import { CollapsingSider } from "../../../../components/Sider/CollapsingSider";
import { useCurrentCart } from "../../../../services/buyers/active-cart";
import { useListingResults, useRelistingResults } from "../../../../services/buyers/listings";
import { useBuyerConnections } from "../../../../services/connections";
import { LoadingPage, PageContent, WideLayout } from "../../../../views/layouts";
import {
  AddAddressModal,
  ProductSearchEmptyWithConstraints,
  createItemConstraintFilter,
  DEFAULT_PHYSICAL_CONSTRAINTS,
  ItemConstraints,
  SearchFilters,
} from "./SearchFilters";
import { PageHeader } from "../../../../components/PageHeader";
import { db, useDocumentDataOnceChecked } from "../../../../services/firebase";
import { useQuery } from "../../../../hooks/util/useQuery";
import { useSyncQueryParams } from "../../../../hooks/util/useSyncQueryParams";
import { ProfileLink } from "../../../../components/ProfileLink/ProfileLink";
import { useRooted } from "../../../../RootedContext";

import { shopColumns } from "./ShopColumns";
import { GoToCartButton } from "../../../../components/Navbar/CartButtons";
import { useRouterBreadcrumb } from "../../../../components/Misc/useRouterBreadcrumb";
import { getCategorySlugBreadcrumbReplacement } from "../../../../utils/getCategorySlugBreadcrumbReplacement";
import { materializeFullfillmentOptions } from "../../../../utils/fulfillment";

type NarrowFromCategory<T, N> = T extends { category: N } ? T : never;

const ITEM_CONSTRAINS_BY_CATEGORY: { [category in RealProductCategoryKey]: ItemConstraints } = {
  bouquet: {
    colors: [],
  },
  bucket: {
    colors: [],
  },
  csa: {
    seasons: [],
  },
  "cut-flower": {
    bloomSizes: [],
    colors: [],
    designElements: [],
    stemLengths: [],
  },
  "dried-flower": {
    colors: [],
    stemLengths: [],
    driedFlowerTypes: [],
  },
  "potted-plant": {
    pottedPlantTypes: [],
    pottedPlantTags: [],
  },
  wreath: {
    wreathTags: [],
    wreathDiameters: [],
  },
};

const filterBySeller = (sellerProfileId?: string) => ({ listing }: { listing: Listing }) =>
  !sellerProfileId || listing.seller.profileId === sellerProfileId;

export const ShopCategory: React.FC<{
  category: RealProductCategoryKey;
  disableDelivery?: boolean;
  disableCoops?: boolean;
  preferDirectToFarmIfRetail?: boolean;
}> = ({ category, disableDelivery, disableCoops, preferDirectToFarmIfRetail = false }) => {
  const { activeRole } = useRooted();
  assertIsBuyer(activeRole);

  const [cart] = useCurrentCart();
  const [connections = [], connectionsLoading] = useBuyerConnections(activeRole);
  const coopConnections = useMemo(
    () => connections?.filter((c) => c.type === "coop-wholesale" || c.type === "coop-retail") || [],
    [connections]
  );

  const coopsEnabled = !disableCoops && (isRetail(activeRole) || coopConnections.length >= 1);

  const query = useQuery();
  const [listingType, setListingType] = useState(query.get("listing_type"));
  const [sellerProfileId, setSellerProfileId] = useState(query.get("seller"));

  // Set the listing_type query param if not se
  useEffect(() => {
    if (!connectionsLoading && !listingType) {
      const newListingType = coopsEnabled
        ? isRetail(activeRole) && preferDirectToFarmIfRetail
          ? "grower"
          : "coop"
        : "grower";
      setListingType(newListingType);
    }
  }, [coopsEnabled, listingType, connectionsLoading, preferDirectToFarmIfRetail, activeRole]);

  // Update the query params based on filters
  useSyncQueryParams([
    ["seller", sellerProfileId],
    ["listing_type", listingType],
  ]);

  const [seller, sellerLoading] = useDocumentDataOnceChecked<WithId<SellerProfile>>(
    sellerProfileId ? db.collection("profiles").doc(sellerProfileId) : null,
    { idField: "_id" }
  );

  const [modal, contextHolder] = Modal.useModal();
  const hasBeenPromptedModal = useRef(false);

  // Note: this works because we currently don't mutate the `seller` state for filtering.
  // This will need to be adjusted if we choose to do that.
  useEffect(() => {
    if (hasBeenPromptedModal.current) return;
    if (connectionsLoading) return;
    if (seller && !isRetail(activeRole)) {
      // If connections includes the filtered seller, don't prompt!
      if (connections.map((c) => c.sellerId).includes(seller._id)) return;
      hasBeenPromptedModal.current = true;
      modal.error({
        title: <h2>{`You're not connected with ${seller.bio.displayName}`}</h2>,
        content: (
          <>
            {/* TODO: embed connection button here. Connection buttons need a refactor first. */}
            {`To shop ${seller.bio.displayName}'s inventory, you'll need to connect with them by going to their `}
            <ProfileLink to={seller}>Profile Page</ProfileLink>
          </>
        ),
      });
    }
  }, [seller, connections, connectionsLoading, activeRole, modal]);

  const [showLoading, setShowLoading] = useState(true);

  // Hack: delay screen by 500ms to prevent some flickers
  // as we don't have a great way of distinguishing no cart from cart loading.
  useEffect(() => {
    if (!connectionsLoading && showLoading) {
      const timeout = setTimeout(() => setShowLoading(false), 500);
      return () => clearTimeout(timeout);
    }
  }, [connectionsLoading, showLoading]);

  const breadcrumb = useRouterBreadcrumb(getCategorySlugBreadcrumbReplacement(category));

  const [physicalConstraints, setPhysicalConstraints] = useState<ListingQueryConstraints>({
    ...DEFAULT_PHYSICAL_CONSTRAINTS,
    delivery: disableDelivery ? { enabled: false } : DEFAULT_PHYSICAL_CONSTRAINTS.delivery,
  });
  const [itemConstraints, setItemConstraints] = useState<ItemConstraints>(
    ITEM_CONSTRAINS_BY_CATEGORY[category]
  );

  return (
    <WideLayout>
      {contextHolder}
      <CollapsingSider
        siderWidth={260}
        siderStyle={{ width: 240, paddingRight: 20 }}
        label={"Search Settings"}
        className="side-filter"
        drawerHeight={"90vh"} // Hack to make this basically act as a modal
      >
        <SearchFilters
          disableDelivery={disableDelivery}
          physicalConstraints={physicalConstraints}
          setPhysicalConstraints={setPhysicalConstraints}
          itemConstraints={itemConstraints}
          setItemConstraints={setItemConstraints}
        />
      </CollapsingSider>

      <PageContent>
        <PageHeader title={realProductCategories[category].pluralName} breadcrumb={breadcrumb} />
        {showLoading ? (
          <LoadingPage marginTop={"10vh"} />
        ) : cart && cart.address ? (
          <>
            {/* Show an alert if we are showing results from a specific seller */}
            {seller && (
              <Alert
                type="info"
                message={
                  <Typography.Text>
                    Viewing results from{" "}
                    <ProfileLink to={seller}>{seller.bio.displayName}</ProfileLink>
                  </Typography.Text>
                }
                action={
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      setSellerProfileId(null);
                    }}
                  >
                    Show all results
                  </Button>
                }
              />
            )}

            {/* Show an error alert if the query param specifies a seller but it isn't found */}
            {sellerProfileId && !sellerLoading && !seller && (
              <Alert
                type="error"
                message={<Typography.Text>Could not find this seller</Typography.Text>}
                action={
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      setSellerProfileId(null);
                    }}
                  >
                    Show all results
                  </Button>
                }
              />
            )}
            <Tabs
              activeKey={listingType ?? undefined}
              onChange={(key) => {
                setListingType(key);
              }}
            >
              {coopsEnabled && (
                <Tabs.TabPane tab="Coops" key="coop">
                  {/* We assert above in the ternary that the cart exists */}
                  <CoopRelistings
                    category={category}
                    cart={cart}
                    role={activeRole}
                    constraints={{
                      itemConstraints,
                      physicalConstraints,
                    }}
                    sellerProfileId={sellerProfileId ?? undefined}
                  />
                </Tabs.TabPane>
              )}

              <Tabs.TabPane tab="Direct to Farm" key="grower">
                {coopsEnabled && <PickupWarning />}
                <DirectToFarmListings
                  cart={cart}
                  category={category}
                  role={activeRole}
                  constraints={{
                    itemConstraints,
                    physicalConstraints,
                  }}
                  sellerProfileId={sellerProfileId ?? undefined}
                />
              </Tabs.TabPane>
            </Tabs>
            <Typography.Paragraph style={{ marginTop: 30, textAlign: "center" }}>
              <GoToCartButton />
            </Typography.Paragraph>
          </>
        ) : (
          <AddAddressModal role={activeRole} />
        )}
      </PageContent>
    </WideLayout>
  );
};

type AllConstraints = {
  physicalConstraints: ListingQueryConstraints;
  itemConstraints: ItemConstraints;
};

// PANES:

function DirectToFarmListings<C extends RealProductCategoryKey>({
  role,
  constraints,
  cart,
  sellerProfileId,
  category,
}: {
  role: FullBuyerRole;
  cart: CurrentCart;
  constraints: AllConstraints;
  sellerProfileId?: string;
  category: C;
}) {
  const [unfilteredListings = [], loading] = useListingResults<NarrowFromCategory<RealListing, C>>(
    role,
    category,
    constraints.physicalConstraints,
    cart?.address
  );

  const [connections] = useBuyerConnections(role);

  const filteredListingsWithFulfillments = useMemo(() => {
    const constraintFilteredListings = unfilteredListings.filter(
      createItemConstraintFilter(constraints.itemConstraints)
    );
    return materializeFullfillmentOptions(
      role,
      constraintFilteredListings,
      connections || [],
      constraints.physicalConstraints,
      cart,
      { removeUnpurchasable: true, maxCountPerItem: 100 }
    ).filter(filterBySeller(sellerProfileId));
  }, [
    unfilteredListings,
    constraints.itemConstraints,
    constraints.physicalConstraints,
    role,
    connections,
    cart,
    sellerProfileId,
  ]);

  const columns = useMemo(() => shopColumns[category], [category]);

  if (loading) return <LoadingPage marginTop="10vh" />;

  if (!filteredListingsWithFulfillments.length)
    return (
      <ProductSearchEmptyWithConstraints physicalConstraints={constraints.physicalConstraints} />
    );

  return (
    <Table
      pagination={{
        showSizeChanger: false,
      }}
      size="small"
      className="table-x-scroll"
      dataSource={filteredListingsWithFulfillments}
      columns={columns as any}
      rowKey={(record) => record.listing._id}
    />
  );
}

function CoopRelistings<C extends RealProductCategoryKey>({
  role,
  cart,
  constraints,
  sellerProfileId,
  category,
}: {
  role: FullBuyerRole;
  cart: CurrentCart;
  constraints: AllConstraints;
  sellerProfileId?: string;
  category: C;
}) {
  const [unfilteredListings = [], loading] = useRelistingResults<NarrowFromCategory<Relisting, C>>(
    role,
    category,
    constraints.physicalConstraints,
    cart.address
  );

  const [connections] = useBuyerConnections(role);

  const filteredListingsWithFulfillments = useMemo(() => {
    const constraintFilteredListings = unfilteredListings.filter(
      createItemConstraintFilter(constraints.itemConstraints)
    );
    return materializeFullfillmentOptions(
      role,
      constraintFilteredListings,
      connections || [],
      constraints.physicalConstraints,
      cart,
      { removeUnpurchasable: true, maxCountPerItem: 100 }
    ).filter(filterBySeller(sellerProfileId));
  }, [
    unfilteredListings,
    constraints.itemConstraints,
    constraints.physicalConstraints,
    role,
    connections,
    cart,
    sellerProfileId,
  ]);

  const columns = useMemo(() => shopColumns[category], [category]);

  if (loading) return <LoadingPage marginTop="10vh" />;

  if (!filteredListingsWithFulfillments.length)
    return (
      <ProductSearchEmptyWithConstraints physicalConstraints={constraints.physicalConstraints} />
    );

  return (
    <Table
      pagination={{
        showSizeChanger: false,
      }}
      size="small"
      className="table-x-scroll"
      dataSource={filteredListingsWithFulfillments}
      columns={columns as any}
      rowKey={(record) => record.listing._id}
    />
  );
}

const PickupWarning: React.FC = () => (
  <Alert
    type="warning"
    showIcon
    closable
    style={{ marginBottom: 12, textAlign: "center" }}
    message={`Heads up: Buying from Direct to Farm will have different pick up locations than from your Coop`}
  />
);
