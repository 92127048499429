import { Popover, Space, Button, Typography } from "antd";
import React, { useState } from "react";
import { CurrencyInput, formatCurrency } from "../FormControls/CurrencyInput";
import { EditOutlined } from "@ant-design/icons";

export const GenericPriceWidget: React.FC<{
  price: number;
  originalPrice?: number;
  title: string;
  editable: boolean;
  onChange: (price: number) => Promise<void>;
}> = ({ price, onChange, title, originalPrice, editable }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [internalPrice, setInternalPrice] = useState(price);

  const submit = async () => {
    setSubmitting(true);
    await onChange(internalPrice);
    setSubmitting(false);
    setPopoverOpen(false);
  };

  return (
    <>
      {originalPrice !== undefined && originalPrice !== price && (
        <>
          <Typography.Text delete>{formatCurrency(originalPrice)}</Typography.Text>
          <br />
        </>
      )}
      <Typography.Text>{formatCurrency(price)}</Typography.Text>
      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <CurrencyInput
                  value={internalPrice}
                  onChange={(e) => setInternalPrice(e as number)}
                />
                <br />
                <Space direction="horizontal">
                  <Button type="primary" onClick={submit} loading={submitting}>
                    Update
                  </Button>
                </Space>
              </Space>
            </>
          }
          title={title}
          trigger="click"
          visible={popoverOpen}
          onVisibleChange={setPopoverOpen}
          placement="bottomRight"
        >
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};
