import { FullUserRole, stringifyCard, TokenizedCreditCard, WithId } from "@rooted/shared";
import { Button, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { useMyCards } from "../../services/buyers/credit-cards";
import { RootedStripeProvider } from "../../services/stripe";
import { AddCardForm } from "../../views/generic/Settings/CreditsCardsPane";

export interface CreditCardSelectProps extends SelectProps<string> {
  role: FullUserRole;
}

export const CreditCardSelect: React.FC<CreditCardSelectProps> = ({ role, onChange, ...props }) => {
  const [selection, setSelection] = useState<WithId<TokenizedCreditCard> | undefined>(undefined);

  const [data, dataLoading, dataError] = useMyCards(role);
  const [addCardFormOpen, setAddCardFormOpen] = useState(false);

  // Auto select the first credit card
  useEffect(() => {
    if (data && data.length > 0) {
      setSelection(data[0]);
      onChange && onChange(data[0]._id, { value: data[0]._id });
    }
  }, [data, onChange]);

  return (
    <>
      {data && data.length > 0 && (
        <Select
          {...props}
          style={{ marginBottom: 16 }}
          showArrow={false}
          filterOption={false}
          onChange={(v, o) => {
            setSelection(data.find((x) => x._id === v));
            onChange && onChange(v, o);
          }}
          notFoundContent={null}
          optionLabelProp="label"
          placeholder="Select payment option"
          value={selection?._id}
        >
          {data?.map((x) => {
            return (
              <Select.Option key={x._id} value={x._id} label={stringifyCard(x)}>
                {stringifyCard(x)}
              </Select.Option>
            );
          })}
        </Select>
      )}
      {data === undefined && <Button type="dashed" loading></Button>}
      {data?.length === 0 && (
        <Button
          type="dashed"
          onClick={() => setAddCardFormOpen(true)}
          size="small"
          style={{ marginBottom: 12 }}
        >
          Add a Credit Card
        </Button>
      )}
      <RootedStripeProvider>
        <AddCardForm
          role={role}
          onFinish={() => setAddCardFormOpen(false)}
          visible={addCardFormOpen}
        />
      </RootedStripeProvider>
    </>
  );
};
