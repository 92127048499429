import { Alert, Button, Form, Input, Typography, Row, Col } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AuthForm.less";
import { confirmPasswordReset } from "../../services/account-state";
import { WideLayout, PageContent } from "../layouts";
import { Logo } from "./LoginForm";

export const ConfirmPasswordResetForm: React.FC<{ oobCode: string }> = ({ oobCode }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (formValues: any) => {
    setError("");
    setLoading(true);
    try {
      await confirmPasswordReset(oobCode, formValues.password);
      setLoading(false);
      navigate("/accounts/login");
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  return (
    <WideLayout>
      <PageContent className="page-content-center">
        <Logo />
        <Row align="middle" justify="center" className="login-title">
          <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 10 }} xl={{ span: 10 }}>
            <Typography.Title level={3} style={{ textAlign: "center" }}>
              Reset Password
            </Typography.Title>

            <Typography.Text>Enter a new password below to reset</Typography.Text>

            <Form
              name="create-account"
              className="auth-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              {error && (
                <Form.Item>
                  <Alert message={error} type="error" />
                </Form.Item>
              )}

              <Form.Item
                name="password"
                rules={[{ required: true, message: "Please enter your new password." }]}
              >
                <Input.Password placeholder="Password" visibilityToggle />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="auth-form-button"
                  loading={loading}
                  block
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </PageContent>
    </WideLayout>
  );
};
