import { allItems, firestoreToMoment } from "@rooted/shared";
import { Button, Badge, Statistic, Space } from "antd";
import React, { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCurrentCart } from "../../services/buyers/active-cart";
import { ShoppingCartOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { formatSlug, useRouterBreadcrumb } from "../Misc/useRouterBreadcrumb";
import { useQuery } from "../../hooks/util/useQuery";

export const useCartShopLink = () => {
  const { routes } = useRouterBreadcrumb();
  // If we navigate to the cart page from a shop page, show the page to go back to.
  const shopCategory = useMemo(() => {
    const categoriesIndex = routes?.findIndex(({ path }) => path === "shop");
    if (
      !routes ||
      categoriesIndex === undefined ||
      categoriesIndex === -1 ||
      routes?.length < categoriesIndex + 1
    )
      return null;
    // This defensively guards against refreshes on the shop page itself.
    return routes[categoriesIndex + 1]?.path || null;
  }, [routes]);

  if (shopCategory) return `/cart?from=${shopCategory}`;
  return "/cart";
};

export const ShoppingCartButton: React.FC = () => {
  const [activeCart] = useCurrentCart();

  const shopLink = useCartShopLink();

  if (!activeCart || activeCart.status !== "active") return null;

  const deadline = firestoreToMoment(activeCart.firstItemAddedAt).add(1, "hours");
  return (
    <>
      <Link to={shopLink}>
        <Space>
          <Button>
            <Badge count={allItems(activeCart).length} offset={[12, -4]}>
              <Space size="large">
                <Statistic.Countdown
                  style={{ width: 30 }}
                  format="mm:ss"
                  valueStyle={{ fontSize: 14 }}
                  // @ts-expect-error Ant-d accepts moment here but doesn't include it in type annotation
                  value={deadline}
                />
                <ShoppingCartOutlined style={{ fontSize: 16 }} />
              </Space>
            </Badge>
          </Button>
        </Space>
      </Link>
    </>
  );
};

export const GoToCartButton: React.FC = () => {
  const [activeCart] = useCurrentCart();
  const shopLink = useCartShopLink();
  if (!activeCart || activeCart.status !== "active") return null;

  return (
    <Link to={shopLink} className="btn-link">
      <ShoppingCartOutlined /> Go to Cart
    </Link>
  );
};

export const BackToShopButton: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  const navigate = useNavigate();
  const query = useQuery();
  const shopCategory = query.get("from");

  if (!shopCategory) return null;
  return (
    <Button style={style} type="link" onClick={() => navigate(-1)}>
      <ArrowLeftOutlined />
      {`Continue shopping ${formatSlug(shopCategory)}`}
    </Button>
  );
};
