import { Button, notification, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import { ButtonType } from "antd/lib/button";
import { logError } from "../../sentry";

/**
 * @deprecated please use the `copyable` prop of antd typography instead.
 */
export const CopyButton: React.FC<{
  text: string;
  type?: ButtonType;
  style?: React.CSSProperties;
}> = ({ text, type, style, children }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  return (
    <Tooltip title={"Copy to Clipboard"} placement="bottomRight">
      <Button
        style={style}
        type={type}
        onClick={() => {
          if (!navigator?.clipboard?.writeText) {
            notification.error({
              message: "Oops! Your browser doesn't support this.",
            });
            logError({
              error: "'navigator' not found. Cannot copy to clipboard.",
            });
          } else {
            navigator.clipboard.writeText(text);
            setCopied(true);
          }
        }}
      >
        {children}
        {copied ? <CheckOutlined /> : <CopyOutlined />}
      </Button>
    </Tooltip>
  );
};

export const CopyIcon: React.FC<{ text: string }> = ({ text }) => (
  <CopyButton text={text} style={{ padding: 0, border: "none" }} />
);
