import { GrowerProfile, WithId, FullCoopRole } from "@rooted/shared";
import { Alert, Button } from "antd";
import React from "react";
import {
  approveCoopMembership,
  inviteGrowerToCoop,
  useCoopMembership,
} from "../../../services/coop-memberships";
import { CloudFunctionButton } from "../../admins/SiteSettings/ActionsPane";

/**
 * Shows the membership status between two entities on a profile page.
 */
export const GrowerCoopMembershipStatus: React.FC<{
  role: FullCoopRole;
  grower: WithId<GrowerProfile>;
}> = ({ role, grower }) => {
  const [membership, membershipLoading] = useCoopMembership(role.profile, grower);

  if (membershipLoading) return null;

  if (!membership)
    return (
      <Alert
        type="info"
        style={{ marginBottom: 16 }}
        message={
          <>
            {grower.bio.displayName} isn't a member of your coop.
            <Button
              type="primary"
              style={{ float: "right", backgroundColor: "white", color: "red" }}
              onClick={() => inviteGrowerToCoop(role, grower)}
            >
              Invite
            </Button>{" "}
          </>
        }
        description="Connect with them to relist their inventory."
      />
    );

  if (membership.status === "approved")
    return (
      <Alert
        type="info"
        style={{ marginBottom: 16 }}
        message={<>You're connected with {grower.bio.displayName}.</>}
        description="You can relist their inventory."
      />
    );

  if (membership.status === "coop-requested")
    return (
      <Alert
        type="info"
        style={{ marginBottom: 16 }}
        message={<>Your request to {grower.bio.displayName} is pending.</>}
        description="Once they accept, you'll be allowed to relist their inventory."
      />
    );
  else
    return (
      <Alert
        type="info"
        style={{ marginBottom: 16 }}
        message={
          <>
            {grower.bio.displayName} wants to connect with you.
            <CloudFunctionButton
              type="primary"
              style={{ float: "right", backgroundColor: "white", color: "red" }}
              onClick={() => approveCoopMembership(membership._id)}
            >
              Accept request
            </CloudFunctionButton>{" "}
          </>
        }
        description="Accept their request to see their inventory and place orders.."
      />
    );
};
