import { ApplicationForm, RootedUser, makeName, WithId } from "@rooted/shared";
import React from "react";
import { db, useDocumentDataChecked } from "../../../../services/firebase";

export const OwnerPanel: React.FC<{ application: WithId<ApplicationForm> }> = ({ application }) => {
  const [owner] = useDocumentDataChecked<RootedUser>(
    db.collection("users").doc(application.ownerId)
  );

  return (
    <>
      <b>{owner && makeName(owner)}</b> ({owner?.email})
    </>
  );
};
