import {
  ListingWithFulfillment,
  FulfillmentOption,
  allItems,
  PhysicalFulfillmentOption,
  isEqualFulfillmentOption,
  FullBuyerRole,
  assertIsBuyer,
} from "@rooted/shared";
import { Space, InputNumber, Popover, Button, List, notification, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { updateInCart, useCurrentCart } from "../../../services/buyers/active-cart";
import { ShoppingCartOutlined } from "@ant-design/icons";
import "./AddToCartWidget.less";
import { useRooted } from "../../../RootedContext";

import { stringifyFulfillment } from "../../../utils/fulfillment";

export const AddToCartWidget: React.FC<{
  listing: ListingWithFulfillment;
}> = ({ listing }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { activeRole: role } = useRooted();
  assertIsBuyer(role);
  const [cart, cartLoading] = useCurrentCart();

  // The item with this listing already in the cart, if it exists
  const existingItem =
    cart?.status === "active" && allItems(cart).find((i) => i.listing._id === listing.listing._id);

  // If the above exists, the fulfillment group that it's a member of.
  // TODO: Finding these separately is inefficient.
  const existingFulfillmentGroup = cart?.fulfillmentGroups.find(
    (g) => existingItem && g.items.includes(existingItem)
  );

  const [quantity, setQuantity] = useState<number>(0);

  useEffect(() => {
    setQuantity(existingItem ? existingItem.quantity : 0);
  }, [existingItem]);

  const changeQuantity = async () => {
    setLoading(true);
    try {
      await updateInCart(role, existingFulfillmentGroup!.fulfillment, listing.listing, quantity!);
      setPopoverVisible(false);
    } catch (e) {
      notification.error({ message: "Couldn't update in cart.", description: e.message });
    }
    setLoading(false);
  };

  const addToCart = async (option: FulfillmentOption) => {
    setLoading(true);
    try {
      await updateInCart(role, option, listing.listing, quantity!);
      setPopoverVisible(false);
    } catch (e) {
      notification.error({ message: "Couldn't add to cart.", description: e.message });
    }
    setLoading(false);
  };

  if (listing.fulfillmentOptions.length === 0)
    return (
      <Typography.Text type="secondary">No matching fulfillment options available.</Typography.Text>
    );

  return (
    <Space direction="vertical" className="full-width-add-to-cart-widget">
      <InputNumber
        // Force int only
        parser={(value) => (value ? parseInt(value) || 0 : 0)}
        min={0}
        max={listing.listing.availability.quantity + (existingItem ? existingItem.quantity : 0)}
        value={quantity}
        onChange={(e) => typeof e == "number" && setQuantity(e)}
      />

      {existingItem && (
        <Button
          icon={<ShoppingCartOutlined />}
          style={{ width: "100%" }}
          type="primary"
          onClick={changeQuantity}
          loading={loading}
          disabled={quantity === undefined || quantity === existingItem.quantity}
        >
          Update
        </Button>
      )}

      {/* Here, the add-to-cart flow branches based on fulfillment type. */}
      {/* Physical items have a popover to select an option. */}
      {!existingItem && listing.listing.availability.type === "physical" && (
        <Popover
          trigger="click"
          visible={popoverVisible}
          onVisibleChange={setPopoverVisible}
          title={<b>Select a fulfillment option</b>}
          content={
            <PhysicalFulfillmentOptionList listing={listing} onSelect={addToCart} role={role} />
          }
        >
          <Button
            icon={<ShoppingCartOutlined />}
            type="primary"
            disabled={!quantity}
            loading={loading}
          >
            Add
          </Button>
        </Popover>
      )}

      {/* CSAs only have one option, so there's no additional step.*/}
      {!existingItem && listing.listing.availability.type === "custom" && (
        <Button
          icon={<ShoppingCartOutlined />}
          onClick={() => addToCart(listing.fulfillmentOptions[0])}
          type="primary"
          disabled={!quantity}
          loading={loading}
        >
          Add
        </Button>
      )}
    </Space>
  );
};

export const AddToCartWidgetMock: React.FC = () => (
  <Space direction="vertical" className="full-width-add-to-cart-widget">
    <InputNumber disabled value={0} />
    <Button icon={<ShoppingCartOutlined />} type="primary" disabled>
      Add
    </Button>
  </Space>
);

const PhysicalFulfillmentOptionList: React.FC<{
  listing: ListingWithFulfillment;
  role: FullBuyerRole;
  onSelect: (o: FulfillmentOption) => void;
}> = ({ listing, role, onSelect }) => {
  const [shipping, setShipping] = useState(true);
  const [delivery, setDelivery] = useState(true);
  const [pickup, setPickup] = useState(true);

  const [cart] = useCurrentCart();

  const filteredOptions = useMemo(
    () =>
      listing.fulfillmentOptions.filter(
        (o) =>
          ((o as PhysicalFulfillmentOption).modality === "shipping" && shipping) ||
          ((o as PhysicalFulfillmentOption).modality === "pickup" && pickup) ||
          ((o as PhysicalFulfillmentOption).modality === "delivery" && delivery)
      ),
    [delivery, listing.fulfillmentOptions, pickup, shipping]
  );

  return (
    <>
      <List style={{ width: 300, maxHeight: 300, overflow: "scroll" }}>
        {filteredOptions.map((o) => {
          // Find the fulfillment options already in cart
          const matchingFulfillmentOption = cart?.fulfillmentGroups.find(
            (g) => g.fulfillment && isEqualFulfillmentOption(g.fulfillment, o)
          );

          const modality = (o as PhysicalFulfillmentOption).modality;

          return (
            <List.Item
              key={stringifyFulfillment(o)}
              extra={
                <>
                  <Button onClick={() => onSelect(o)}>
                    {modality === "delivery" ? (
                      <img
                        style={{ height: 20, marginRight: 8 }}
                        src={`${process.env.REACT_APP_BASE_URL}/images/icons/icon-delivery.png`}
                        alt="Delivery icon"
                      />
                    ) : (
                      <img
                        style={{ height: 20, marginRight: 8 }}
                        src={`${process.env.REACT_APP_BASE_URL}/images/icons/icon-farm.png`}
                        alt="Pickup icon"
                      />
                    )}
                    {stringifyFulfillment(o)}
                  </Button>
                  {matchingFulfillmentOption && (
                    <Typography.Text type="secondary" style={{ fontSize: 14 }}>
                      {matchingFulfillmentOption.items.length} other
                      {matchingFulfillmentOption.items.length > 1 && "s"} in cart
                    </Typography.Text>
                  )}
                </>
              }
            />
          );
        })}
      </List>
    </>
  );
};
