import {
  FullUserRole,
  PrivateProfile,
  Profile,
  removeUndefined,
  Role,
  RootedUser,
  UserRole,
  WithId,
} from "@rooted/shared";
import firebase from "firebase/app";
import cookies from "js-cookie";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { logError, setSentryUser } from "../sentry";
import { isLocalhost } from "../utils/env";
import { googleAnalytics, googleAnalyticsMeasurementId } from "../utils/googleAnalytics";
import { auth, db, useCollectionDataChecked, useDocumentDataChecked } from "./firebase";
import { useDocsWithIds } from "./higher-order-searches";

/**
 * Represents the application state when no user account is signed in.
 */
export interface SignedOut {
  status: "signed-out";
}

/**
 * Represents the application state where the user account state is loading. Almost no info can be
 * rendered in this state as we don't even know whether they are logged in yet.
 */
export interface Loading {
  status: "loading";
}

export interface SignedInNeedsInfo {
  status: "signed-in-needs-info";
  authUser: firebase.User;
  user: undefined;
}

export interface SignedInComplete {
  status: "signed-in-complete";
  authUser: firebase.User;
  user: WithId<RootedUser>;
  roles: FullUserRole[];
  isAdmin: boolean;
}

export type SignedIn = SignedInNeedsInfo | SignedInComplete;

/**
 * Represents the union of all local auth states.
 */
export type LocalAccountState = SignedIn | SignedOut | Loading;

export function assertIsSignedIn(account: {
  status: LocalAccountState["status"];
}): asserts account is { status: "signed-in-complete" } {
  if (account.status !== "signed-in-complete")
    throw new Error(`Account not signed in. Status: ${account.status}`);
}

/**
 * Custom hook to listen to the rooted account state. First, we fetch the actual auth account,
 * and then the profile / roles, short-circuiting appropriately on errors or loading state.
 * This hook encapsulates all that logic.
 */
export function useAccountState(): LocalAccountState {
  // First, get the Firebase auth user
  const [authUser, authLoading, authError] = useAuthState(auth);

  // As top level as possible, set sentry debug
  useEffect(() => {
    if (authUser) {
      setSentryUser({
        email: authUser.email || undefined,
        id: authUser.uid,
      });

      // Set the goole analytics user (if google analytics is available)
      if (googleAnalytics && googleAnalyticsMeasurementId) {
        googleAnalytics("config", googleAnalyticsMeasurementId, {
          user_id: authUser.uid,
        });
      }
    }
  }, [authUser]);

  //Then, do the same for the user document (profile) and roles. Hooks must always be called in the same order,
  //so we have to call these hooks even if we know we didn't get an authUser.
  const [user, userLoading, userError] = useDocumentDataChecked<WithId<RootedUser>>(
    authUser ? db.collection("users").doc(authUser.uid) : null,
    {
      idField: "_id",
    }
  );

  const [roles = [], rolesLoading, rolesError] = useCollectionDataChecked<WithId<Role>>(
    authUser ? db.collection(`roles`).where(`userId`, `==`, authUser.uid || " ") : null,
    { idField: "_id" }
  );

  // This is as ugly as it looks. This arises because we want to get the matching profile
  // or private profile for each role, but there is no query that describes all these
  // documents, and react prohibits calling hooks from a loop.

  // TODO: Fix this. Either find a way to get these in a collection query or decide
  // to break the rules and use hooks in a loop.

  const profileIds = (roles.filter((x) => x.type !== "admin") as UserRole[]).map(
    (x) => x.profileId
  );

  const [profiles, profilesLoading, profilesError] = useDocsWithIds<Profile>(
    db.collection("profiles"),
    profileIds
  );

  const [
    privateProfiles,
    privateProfilesLoading,
    privateProfilesError,
  ] = useDocsWithIds<PrivateProfile>(db.collection("privateProfiles"), profileIds);

  // For some reason hooks are wrong until rebuild after an id changes. This means that despite
  // being given an valid doc id, the hook will have loading: false and result: undefined.
  // Obviously, we can't have this so I detect this manually.

  // TODO sort all this out

  //TODO: Figure out what all these errors are and whether they could actually ever be thrown
  if (authError) throw new Error(`Error in auth hook: ${authError.code} ${authError.message}`);
  if (userError) throw new Error(`Error in auth hook: ${userError.message}`);
  if (rolesError) throw new Error(`Error in auth hook: ${rolesError.name} ${rolesError.message}`);

  //Any data may still be loading
  if (
    authLoading ||
    userLoading ||
    rolesLoading ||
    !profiles ||
    profiles?.includes(undefined) ||
    !privateProfiles ||
    privateProfilesLoading
  )
    return { status: "loading" };

  if (!authUser) return { status: "signed-out" };

  // Associate the roles with their profiles
  const fullRoles =
    // Remove admin role-- this is only used for determining if an admin
    (roles.filter((role) => role.type !== "admin") as WithId<UserRole>[])
      .map((role) => {
        const profile = profiles.find((p) => role.profileId === p?._id);
        const privateProfile = privateProfiles.find((pp) => role.profileId === pp?._id);

        if (!profile || !privateProfile) {
          throw new Error("Could not find profile or private profile");
        }

        return { ...role, profile, privateProfile } as FullUserRole;
      })
      .sort(
        // Sort alphanumerically on the ID to ensure consistent ordering
        (a, b) => (a._id < b._id ? -1 : a._id === b._id ? 0 : 1)
      )
      .filter((x) => x.profile && !x.profile.disabled);

  if (!user) {
    return { status: "signed-in-needs-info", authUser, user };
  }

  return {
    status: "signed-in-complete",
    authUser,
    user,
    roles: fullRoles || [],
    isAdmin: roles.some((role) => role.type === "admin"),
  };
}

// Firebase doesn't provide type annotations for their errors, but there's a list online, so
// I've filled these in here

type SignInErrorCode =
  | "auth/network-request-failed"
  | "auth/invalid-email"
  | "auth/user-disabled"
  | "auth/user-not-found"
  | "auth/wrong-password";

type CreateUserErrorCode =
  | "auth/network-request-failed"
  | "auth/email-already-in-use"
  | "auth/invalid-email"
  | "auth/operation-not-allowed"
  | "auth/weak-password";

type ResetPasswordErrorCode =
  | "auth/network-request-failed"
  | "auth/invalid-email"
  | "auth/missing-android-pkg-name"
  | "auth/missing-continue-uri"
  | "auth/missing-ios-bundle-id"
  | "auth/invalid-continue-uri"
  | "auth/unauthorized-continue-uri"
  | "auth/user-not-found";

type ConfirmPasswordResetErrorCode =
  | "auth/network-request-failed"
  | "auth/expired-action-code"
  | "auth/invalid-action-code"
  | "auth/user-disabled"
  | "auth/user-not-found"
  | "auth/weak-password";

/**
 * Attempt to sign in with a given email and password.
 *
 * See {@link useAccountState()} above for special handling
 * @param email The account's email.
 * @param password The account's password.
 */
export const signIn = async (email: string, password: string) => {
  const normalizedEmail = email.trim().toLowerCase();

  try {
    await auth.signInWithEmailAndPassword(normalizedEmail, password);
  } catch (e) {
    const code = e.code as SignInErrorCode;

    // error handling
    if (code === "auth/invalid-email") {
      throw new Error("Invalid email");
    } else if (code === "auth/user-disabled") {
      throw new Error("Account has been disabled");
    } else if (code === "auth/user-not-found") {
      throw new Error("No account found for this email");
    } else if (code === "auth/wrong-password") {
      throw new Error("Incorrect password");
    } else if (code === "auth/network-request-failed") {
      throw new Error("No network connection. Please try again");
    }

    logError({ error: e, tags: { type: "sign-in", email } });
    throw new Error("Internal error");
  }
};

/**
 * Create signed in cookie (_rooted_uid)
 */
export const createCookie = async (account: SignedInComplete) => {
  cookies.set("_rooted_uid", account.authUser.uid, {
    // Domain MUST be excluded in a local environment
    domain: !isLocalhost ? "rootedfarmers.com" : undefined,
    sameSite: "strict",
    secure: true,
  });
};

/**
 * Delete signed in cookie (_rooted_uid)
 */
export const deleteCookie = async () => {
  cookies.remove("_rooted_uid");
};

/**
 * Attempt to sign out the current user.
 */
export const signOut = async () => {
  try {
    await auth.signOut();

    deleteCookie();
    // Navigate to home
    document.location.href = "/";
  } catch (e) {
    throw new Error("Failed to sign out");
  }
};

/**
 * Attempt to create an account with a given email and password.
 * @param email The email of the account to create.
 * @param password The plaintext password that will be set.
 */
export const createAccount = async (email: string, password: string) => {
  try {
    return await auth.createUserWithEmailAndPassword(email, password);
  } catch (e) {
    const code = e.code as CreateUserErrorCode;

    // error handling
    if (code === "auth/email-already-in-use") {
      throw new Error(
        "A user with this email already exists. If you forgot your password, try using the password reset."
      );
    } else if (code === "auth/invalid-email") {
      throw new Error("This email is invalid. Check the input email for any errors.");
    } else if (code === "auth/operation-not-allowed") {
      throw new Error("This operation is not allowed");
    } else if (code === "auth/weak-password") {
      throw new Error("Passwords must be at least 6 characters.");
    } else if (code === "auth/network-request-failed") {
      throw new Error("No network connection");
    }

    logError({ error: e, tags: { type: "create-account", email } });
    throw new Error("Internal error");
  }
};

/**
 * Complete account creation
 * @param contactName The name of on the account
 */
export const completeAccount = async (authUser: firebase.User, values: Partial<RootedUser>) => {
  const newUser: RootedUser = {
    ...removeUndefined(values),
    language: "en",
    email: authUser.email?.toLowerCase(), // Should already be lower case, but make sure
    settings: {},
    userProvenance: "created-v2",
    status: "user",
    _authAccountGuaranteed: true,
  };

  await db.collection("users").doc(authUser.uid).set(newUser);
};

/**
 * Attempt to send a password reset to a given email.
 * @param email The email of the account to reset.
 */
export const resetPassword = async (email: string) => {
  try {
    await auth.sendPasswordResetEmail(email);
  } catch (e) {
    const code = e.code as ResetPasswordErrorCode;

    // error handling
    if (code === "auth/missing-android-pkg-name") {
      throw new Error("auth/missing-android-pkg-name");
    } else if (code === "auth/invalid-email") {
      throw new Error("This email is invalid. Check the input email for any errors.");
    } else if (code === "auth/missing-continue-uri") {
      throw new Error("auth/missing-continue-uri");
    } else if (code === "auth/missing-ios-bundle-id") {
      throw new Error("auth/missing-ios-bundle-id");
    } else if (code === "auth/invalid-continue-uri") {
      throw new Error("auth/invalid-continue-uri");
    } else if (code === "auth/unauthorized-continue-uri") {
      throw new Error("auth/unauthorized-continue-uri");
    } else if (code === "auth/user-not-found") {
      throw new Error("No account found for this email. Note email addresses are case-sensitive.");
    } else if (code === "auth/network-request-failed") {
      throw new Error("No network connection");
    }

    logError({ error: e, tags: { type: "reset-password", email } });
    throw new Error("Internal error");
  }
};

/**
 * Attempt to reset a password using a code from an email.
 * @param code The firebase action code (included in the email)
 * @param newPassword The new password to use
 */
export const confirmPasswordReset = async (code: string, newPassword: string) => {
  try {
    await auth.confirmPasswordReset(code, newPassword);
  } catch (e) {
    const code = e.code as ConfirmPasswordResetErrorCode;

    // error handling
    if (code === "auth/expired-action-code") {
      throw new Error("Expired action code");
    } else if (code === "auth/invalid-action-code") {
      throw new Error("Invalid action code");
    } else if (code === "auth/user-disabled") {
      throw new Error("User has been disabled");
    } else if (code === "auth/user-not-found") {
      throw new Error("No user found for this username");
    } else if (code === "auth/weak-password") {
      throw new Error("Passwords must be at least 6 characters.");
    } else if (code === "auth/network-request-failed") {
      throw new Error("No network connection");
    }

    logError({ error: e, tags: { type: "confirm-password-reset" } });
    throw new Error("Internal error");
  }
};
