import React from "react";
import { Profile, BuyerProfile, ChildOrder, getOrderDetailsBySeller } from "@rooted/shared";
import { Row, Col } from "antd";
import { AddressDisplay } from "../../../components/FormControls/AddressSelect";
import { formatCurrency } from "../../../components/FormControls/CurrencyInput";
import { db, useDocumentDataChecked } from "../../../services/firebase";
import { stringifyFulfillment } from "../../../utils/fulfillment";

const useProfile = <T extends Profile = Profile>(profileId: string) => {
  return useDocumentDataChecked<T>(db.collection("profiles").doc(profileId), { idField: "_id" });
};

export const OrderSummary: React.FC<{ order: ChildOrder; sellerId: string }> = ({
  order,
  sellerId,
}) => {
  const [buyer] = useProfile<BuyerProfile>(order.buyerId);
  const sellerDetails = getOrderDetailsBySeller({ order, sellerId });

  // Prefer showing the buyer email on the order itself, but if that is not available
  // then show the email loaded from the profile (if available)
  const buyerEmail: string | undefined = order.buyerEmail ?? buyer?.bio.email;

  return (
    <Row gutter={16} style={{ marginBottom: 16, width: "100%" }}>
      <Col xs={24} sm={16}>
        <table>
          <tbody>
            {/* Seller Name */}
            <tr>
              <th style={{ width: 120, verticalAlign: "top" }}>Seller:</th>
              <td>
                {sellerDetails.sellerDisplayName !== order.sellerDisplayName
                  ? `${sellerDetails.sellerDisplayName} (via ${order.sellerDisplayName})`
                  : sellerDetails.sellerDisplayName}
              </td>
            </tr>
            {/* Buyer Name */}
            <tr>
              <th style={{ width: 120, verticalAlign: "top" }}>
                {order.buyerId.includes("retail") && <>Retail&nbsp;</>}Buyer:
              </th>
              <td>
                {order.buyerDisplayName}
                {buyerEmail && (
                  <>
                    <br />
                    <a href={`mailto:${buyerEmail}`}>{buyerEmail}</a>
                  </>
                )}
                {buyer?.bio.phoneNumber && (
                  <>
                    <br />
                    <a href={`tel:${buyer?.bio.phoneNumber}`}>{buyer?.bio.phoneNumber}</a>
                  </>
                )}
              </td>
            </tr>
            {/* Fulfillment Info */}
            <tr>
              <th style={{ verticalAlign: "top" }}>Fulfillment:</th>
              <td>
                {stringifyFulfillment(order.fulfillment)}
                {order.fulfillment.type === "physical" &&
                  order.fulfillment.modality === "delivery" && (
                    <>
                      <br />
                      <AddressDisplay address={order.fulfillment.location.streetAddress} />
                    </>
                  )}
              </td>
            </tr>
            <tr>
              <th>Notes:</th>
              <td>{order.buyerNotes || "none"}</td>
            </tr>
          </tbody>
        </table>
      </Col>
      <Col xs={24} sm={8}>
        <table>
          <tbody>
            <tr>
              <th style={{ width: 120, verticalAlign: "top" }}>Order Placed:</th>
              <td>{order.placedAt.toDate().toLocaleString()}</td>
            </tr>
            <tr>
              <th>Status:</th>
              <td style={{ textTransform: "uppercase" }}>{order.status}</td>
            </tr>
            <tr>
              <th>Total:</th>
              <td>{`${formatCurrency(order._calculated.total)}`}</td>
            </tr>
          </tbody>
        </table>
      </Col>
    </Row>
  );
};
