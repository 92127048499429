import React from "react";
import { Typography } from "antd";

export const CopyCode: React.FC<{ children: string; inline?: boolean; allowWrap?: boolean }> = ({
  children,
  inline,
  allowWrap,
}) =>
  inline ? (
    <Typography.Text style={{ whiteSpace: allowWrap ? undefined : "nowrap" }} code copyable>
      {children}
    </Typography.Text>
  ) : (
    // Reverse compatible implementation that started with a block element
    <div>
      <Typography.Text style={{ whiteSpace: allowWrap ? undefined : "nowrap" }} code copyable>
        {children}
      </Typography.Text>
    </div>
  );
