import {
  AdHocListing,
  calculateMarkedUpPricing,
  ChildOrder,
  GrowerListingGeneric,
  GrowerProfile,
  RealRelistingOption,
  WithId,
} from "@rooted/shared";
import { Alert, Button, Form, Input, InputNumber, Modal, notification, Space, Tabs } from "antd";
import React, { useState } from "react";
import { db, snapshotToIdDoc } from "../../services/firebase";
import { addOrderItem } from "../../services/sellers/orders";
import { CurrencyInput } from "../FormControls/CurrencyInput";
import { GrowerListingSelect } from "../FormControls/ListingSelect";

export const GrowerAddOrderItemModal: React.FC<{
  order: WithId<ChildOrder>;
  onFinish?: () => void;
  visible: boolean;
}> = ({ onFinish, visible, order }) => {
  return (
    <Modal onCancel={onFinish} visible={visible} footer={[]} title="Add Item">
      <Tabs>
        <Tabs.TabPane tab="New Listing" key="new">
          <ItemFromNewListingForm order={order} onFinish={onFinish} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Existing Listing" key="existing">
          <ItemFromExistingListingForm order={order} onFinish={onFinish} />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

const ItemFromExistingListingForm: React.FC<{
  order: WithId<ChildOrder>;
  onFinish: (() => void) | undefined;
}> = ({ order, onFinish }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [lastError, setLastError] = useState<string | undefined>(undefined);

  const addItem = async (values: any) => {
    if (!(order.status === "active" || order.status === "guest-checkout")) {
      throw new Error("Order is not active or guest checkout");
    }

    setLoading(true);

    try {
      const itemData = (
        await db.collection("listings").doc(values["listingId"]).get()
      ).data() as GrowerListingGeneric<RealRelistingOption>;
      if (!itemData) throw new Error("Invalid listing id");

      const retailMarkup =
        itemData.seller._cache.categorySettings[itemData.category]?.retailMarkup ?? 0;

      // @ts-expect-error typescript can't infer the correct type of pricing here
      const item: WithId<GrowerListingGeneric<RealRelistingOption>> = {
        ...itemData,
        pricing: calculateMarkedUpPricing(itemData.pricing, order.isRetail ? retailMarkup : 0),
        _id: values["listingId"],
      };

      await addOrderItem(order, item, values["quantity"]);
      form.resetFields();
      onFinish?.();
    } catch (e) {
      setLastError(e.message);
    }
    setLoading(false);
  };

  return (
    <Form form={form} requiredMark="optional" layout="vertical" onFinish={addItem}>
      {lastError && (
        <Form.Item>
          <Alert message={lastError} type="error" />
        </Form.Item>
      )}
      <Form.Item label="Listing" name="listingId" required>
        <GrowerListingSelect profileId={order.fulfillment.profileId} />
      </Form.Item>
      <Form.Item
        label="Quantity"
        style={{ marginBottom: 0 }}
        name="quantity"
        rules={[{ required: true, message: " " }]}
      >
        <InputNumber min={0} style={{ width: 200 }} />
      </Form.Item>
      <Form.Item style={{ marginBottom: 0, marginTop: 16 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Add Item
        </Button>
      </Form.Item>
    </Form>
  );
};

const ItemFromNewListingForm: React.FC<{
  order: WithId<ChildOrder>;
  onFinish: (() => void) | undefined;
}> = ({ order, onFinish }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const addItem = async (values: any) => {
    if (!(order.status === "active" || order.status === "guest-checkout")) {
      throw new Error("Order is not active or guest checkout");
    }

    setLoading(true);
    try {
      const sellerCache = snapshotToIdDoc<GrowerProfile>(
        await db.collection("profiles").doc(order.sellerId).get()
      );

      if (!sellerCache) throw new Error("Could not find seller");

      const item: WithId<AdHocListing> = {
        category: "ad-hoc",
        product: {
          category: "ad-hoc",
          name: values["name"],
          photoStorageUrl: "",
          description: "",
          unit: "item",
        },
        seller: {
          profileId: order.sellerId,
          type: "grower",
          _cache: sellerCache,
        },
        pricing: {
          hasMarkupBaked: true,
          price: values["price"],
        },
        //@ts-expect-error The backing listing does not have a fulfillment in this case
        fulfillment: {},
        _id: Math.random().toString(36).substring(2, 15),
      };

      await addOrderItem(order, item, values["quantity"]);
      form.resetFields();
      onFinish?.();
    } catch (e) {
      notification.error({
        message: "Failed to add item to order",
        description: e.message,
      });
    }
    setLoading(false);
  };

  return (
    <Form form={form} requiredMark="optional" layout="vertical" onFinish={addItem}>
      <Form.Item
        style={{ marginBottom: 16 }}
        label="Name"
        name="name"
        rules={[{ required: true, message: " " }]}
      >
        <Input />
      </Form.Item>
      <Space>
        <Form.Item
          label="Price"
          style={{ marginBottom: 0 }}
          name="price"
          rules={[{ required: true, message: " " }]}
        >
          <CurrencyInput style={{ width: 200 }} />
        </Form.Item>
        <Form.Item
          label="Quantity"
          style={{ marginBottom: 0 }}
          name="quantity"
          rules={[{ required: true, message: " " }]}
        >
          <InputNumber min={0} style={{ width: 200 }} />
        </Form.Item>
      </Space>

      <Form.Item style={{ marginBottom: 0, marginTop: 16 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Add Item
        </Button>
      </Form.Item>
    </Form>
  );
};
