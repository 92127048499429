/**
 * Front End Stripe Service
 *
 * @see https://stripe.com/docs/js
 */
import React from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements as StripeProvider } from "@stripe/react-stripe-js";

// grab stripe public key from Craco (see .env- files)
const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || " ";

const stripePromises = new Map<string | undefined, Promise<Stripe | null>>();

const ROOTED_STRIPE_ACCOUNT = "ROOTED_STRIPE_ACCOUNT";

/**
 * Initialize the client side stripe account
 */
const getStripePromise = (stripeAccountKey: string) => {
  const existingPromise = stripePromises.get(stripeAccountKey);
  if (existingPromise) return existingPromise;

  // If the ROOTED_STRIPE_ACCOUNT constant is used, then the "default" account is assumed,
  // which is the rooted account. In this case, we need to pass undefined to the stripe loader
  const stripeAccount = stripeAccountKey === ROOTED_STRIPE_ACCOUNT ? undefined : stripeAccountKey;
  const promise = loadStripe(stripePublishableKey, { apiVersion: "2020-08-27", stripeAccount });

  stripePromises.set(stripeAccountKey, promise);
  return promise;
};

/**
 * Can be called when by a parent component that expects
 * to need stripe in a child that isn't yet mounted
 * (e.g. a page that uses stripe in a model)
 */
export const preLoadStripePromise = (stripeAccount: string): void =>
  void getStripePromise(stripeAccount);

export const RootedStripeProvider: React.FC<{ stripeAccount?: string }> = ({
  children,
  stripeAccount = ROOTED_STRIPE_ACCOUNT,
}) => {
  return (
    <StripeProvider stripe={getStripePromise(stripeAccount)} key={stripeAccount}>
      {children}
    </StripeProvider>
  );
};
