import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { initializeSentry } from "./sentry";
import "./theme"; // import css

// Before anything else, initialize our error reporting.
initializeSentry();

ReactDOM.render(<App />, document.getElementById("root"));
