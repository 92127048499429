import { BaseProduct } from "@rooted/shared";
import React from "react";
import { ThumbnailPreviewImage } from "./ThumbnailPreviewImage";

export const ProductThumbnail: React.FC<{ product: BaseProduct<any> }> = ({ product }) => {
  return (
    <ThumbnailPreviewImage
      src={product.photoStorageUrl}
      alt={product.name}
      width={75}
      height={75}
    />
  );
};
