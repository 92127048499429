import React from "react";
import { Typography, Button, Layout } from "antd";
import { Link } from "react-router-dom";
import { PageContent } from ".";

import { HomeOutlined } from "@ant-design/icons";
// import { logDebug } from "../../sentry";

export const NotFoundPage: React.FC = () => {
  // TODO: re-enable this when we have more Sentry quota available (as of 4.22.20).
  // useEffect(() => {
  //   // logDebug will include breadcrumbs in sentry of what page this is
  //   logDebug({
  //     message: "404: NotFoundPage",
  //   });
  // }, []);
  return (
    <>
      <Layout>
        <PageContent>
          <div style={{ textAlign: "center", marginTop: 32, marginBottom: 32 }}>
            <Typography.Title>Page not found.</Typography.Title>
            <Typography.Text>
              Either you lack the permission to view this page or it doesn't exist.
            </Typography.Text>
            <br />
            <Link to="/">
              <Button type="primary" icon={<HomeOutlined />} size={"large"}>
                Back to Home
              </Button>
            </Link>
          </div>
        </PageContent>
      </Layout>
    </>
  );
};
