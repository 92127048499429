import { ArrowRightOutlined } from "@ant-design/icons";
import {
  assertCasesExhausted,
  assertRoleExists,
  FullBuyerRole,
  getDriedFlowerProductTypeLabel,
  getPottedPlantTagLabel,
  isBuyer,
  isRelisting,
  isSeller,
  Listing,
  ListingQueryContstraints,
  RealListing,
  Relisting,
  WithId,
} from "@rooted/shared";
import { Alert, Button, Col, Descriptions, Image, Row, Space, Tag, Typography } from "antd";
import firebase from "firebase/app";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ColorDisplay } from "../../../components/Misc/ColorDisplay";
import { ProfileLink } from "../../../components/ProfileLink/ProfileLink";
import {
  AddToCartWidget,
  AddToCartWidgetMock,
} from "../../../components/Widgets/BuyerListings/AddToCartWidget";
import { BuyerListingPriceWidget } from "../../../components/Widgets/BuyerListings/PriceWidget";
import { useRooted } from "../../../RootedContext";
import { useCurrentCart } from "../../../services/buyers/active-cart";
import { useConnection } from "../../../services/connections";
import { db, useDocumentDataChecked } from "../../../services/firebase";
import { makeFulfillmentOptions } from "../../../utils/fulfillment";
import { RenderFn } from "../../../utils/renderFn";
import { LoadingPage, NotFoundPage, PageContent, WideLayout } from "../../../views/layouts";

export const ListingDetails: React.FC = () => {
  const { listingId } = useParams();
  const { activeRole } = useRooted();
  assertRoleExists(activeRole);

  // TODO: instead of checking both of these, regex if it's `${profileId}-${itemID}` for a relisting
  const [_listing, _listingLoading] = useDocumentDataChecked<WithId<RealListing>>(
    db.collection("listings").doc(listingId),
    { idField: "_id" }
  );

  const [relisting, relistingLoading] = useDocumentDataChecked<WithId<Relisting>>(
    db.collection("relistings").doc(listingId),
    { idField: "_id" }
  );

  const listing = relisting || _listing;
  const listingLoading = _listingLoading || relistingLoading;
  const navigate = useNavigate();

  if (listingLoading) return <LoadingPage />;
  if (!listing) return <NotFoundPage />;

  const isActive = isRelisting(listing)
    ? listing.relistingMetaData.isActive
    : listing.availability.active;

  if (!isActive) return <NotFoundPage />;

  const details = renderListingDetails({ listing });

  return (
    <WideLayout>
      <PageContent>
        {isSeller(activeRole) && activeRole.profileId === listing?.seller.profileId && (
          <Alert
            type="info"
            style={{ marginBottom: 16 }}
            message={
              <>
                This is your own listing.
                <Link
                  to={`/inventory/${listing.product.category}s/${listing._id}`}
                  style={{ float: "right" }}
                >
                  Seller View <ArrowRightOutlined />
                </Link>
              </>
            }
          />
        )}

        <Row gutter={32} style={{ marginTop: 0 }}>
          <Col xs={24}>
            <Button
              type="link"
              onClick={() => navigate(-1)}
              style={{ float: "right", marginBottom: 20 }}
            >
              Go back <ArrowRightOutlined />
            </Button>
          </Col>
          {/* Only display on small screens */}
          <Col xs={24} sm={0}>
            <Image
              width="100%"
              style={{
                borderRadius: 8,
                boxShadow: "0 2px 5px 0 rgba(60,66,87,.08),0 1px 1px 0 rgba(0,0,0,.12)",
                marginBottom: 30,
              }}
              src={listing.product.photoStorageUrl}
              alt={`Photo for ${listing.product.name}`}
              preview={false}
            />
          </Col>
          <Col xs={24} sm={16}>
            <Typography.Title level={2} style={{ marginBottom: 8 }}>
              {listing.product.name}
            </Typography.Title>
            {renderListingSubtitle({ listing })}
            Sold by{" "}
            <ProfileLink to={{ ...listing.seller._cache, _id: listing.seller.profileId }}>
              {" "}
              {listing.seller._cache.bio.displayName}{" "}
            </ProfileLink>{" "}
            in {listing.seller._cache.bio.location.streetAddress.city},{" "}
            {listing.seller._cache.bio.location.streetAddress.state}
            {listing.product.description && (
              <Typography.Paragraph style={{ marginTop: 32 }}>
                {listing.product.description}
              </Typography.Paragraph>
            )}
            <hr />
            <Space
              style={{
                maxWidth: 300,
                margin: "5px auto 20px",
                display: "block",
                textAlign: "center",
              }}
            >
              <BuyerListingPriceWidget listing={listing} />
            </Space>
            <Space style={{ maxWidth: 300, margin: "0 auto 15px", display: "block" }}>
              {isBuyer(activeRole) ? (
                <ListingFulfillmentOptions listing={listing} role={activeRole} />
              ) : (
                <AddToCartWidgetMock />
              )}
            </Space>
            <hr />
          </Col>
          {/* Only display on large screens */}
          <Col xs={0} sm={8}>
            {/* Outer border radius affects mask. This is imperfect, however, because
                  AntD adds some mysterious bottom margin
               */}
            <div style={{ borderRadius: 8, overflow: "hidden", margin: "auto" }}>
              <Image
                style={{
                  borderRadius: 8,
                  boxShadow: "0 2px 5px 0 rgba(60,66,87,.08),0 1px 1px 0 rgba(0,0,0,.12)",
                }}
                src={listing.product.photoStorageUrl}
                alt={`Photo for ${listing.product.name}`}
                preview={true}
              />
            </div>
          </Col>

          {details && (
            <Col>
              <Descriptions bordered title={"Details"} className="table-th-150">
                {details}
              </Descriptions>
            </Col>
          )}

          <Col xs={24}>
            <Button
              type="link"
              onClick={() => navigate(-1)}
              style={{ float: "right", marginTop: 20 }}
            >
              Go back <ArrowRightOutlined />
            </Button>
          </Col>
        </Row>
      </PageContent>
    </WideLayout>
  );
};

const ListingFulfillmentOptions: React.FC<{
  listing: WithId<RealListing>;
  role: WithId<FullBuyerRole>;
}> = ({ listing, role }) => {
  const buyerId = role.profileId;
  const [connection] = useConnection(buyerId, listing.seller.profileId);

  const [cart] = useCurrentCart();

  const constraints: ListingQueryContstraints = {
    type: "all" as const,
    dateRange: {
      start: firebase.firestore.Timestamp.now(),
      end: firebase.firestore.Timestamp.fromMillis(Date.now() + 999999999),
    },
    delivery: {
      enabled: true,
    },
    pickup: {
      enabled: true,
      maxDistance: 9999,
    },
    shipping: {
      enabled: true,
    },
    excludedProfileIds: [],
    showZeroQuantity: false,
  };

  const fulfillmentOptions = makeFulfillmentOptions(
    role,
    listing,
    connection,
    constraints,
    cart?.address,
    100
  );

  return <AddToCartWidget listing={{ listing, fulfillmentOptions: fulfillmentOptions }} />;
};

const renderListingSubtitle: RenderFn<{ listing: Listing }> = ({ listing }) => {
  if (
    listing.product.category === "cut-flower" ||
    listing.product.category === "bucket" ||
    listing.product.category === "bouquet"
  ) {
    return (
      <Typography.Title level={5} style={{ marginTop: 4, marginBottom: 8 }}>
        {listing.product._prototypeCache.commonNames.join(", ")}
      </Typography.Title>
    );
  }

  return null;
};

const renderListingDetails: RenderFn<{ listing: Listing }> = ({ listing }) => {
  switch (listing.category) {
    case "cut-flower":
      return (
        <>
          <Descriptions.Item span={3} label="Botanical Name">
            <b>{listing.product._prototypeCache.scientificName}</b>
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Common Names">
            {listing.product._prototypeCache.commonNames.join(", ")}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Dominant Color">
            <ColorDisplay color={listing.product.dominantColor} />
          </Descriptions.Item>
          {listing.product.bloomSize && (
            <Descriptions.Item span={3} label="Bloom Size">
              {listing.product.bloomSize}
            </Descriptions.Item>
          )}
          {listing.product.vaseLife && (
            <Descriptions.Item span={3} label="Vase Life">
              {listing.product.vaseLife}
            </Descriptions.Item>
          )}
          <Descriptions.Item span={3} label="Stem Length">
            {listing.product.stemLength}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Stems Per Bunch">
            {listing.product.stemsPerBunch}
          </Descriptions.Item>
        </>
      );

    case "bouquet":
      return (
        <>
          <Descriptions.Item span={3} label="Name">
            <b>{listing.product._prototypeCache.scientificName}</b>
          </Descriptions.Item>
          {listing.product._prototypeCache.commonNames.length && (
            <Descriptions.Item span={3} label="Common Names">
              {listing.product._prototypeCache.commonNames.join(", ")}
            </Descriptions.Item>
          )}
          <Descriptions.Item span={3} label="Dominant Color">
            <ColorDisplay color={listing.product.dominantColor} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Stem Count">
            {listing.product.stemCount}
          </Descriptions.Item>
        </>
      );

    case "csa":
      return (
        <>
          <Descriptions.Item span={3} label="Weeks">
            {listing.product.numberOfWeeks}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Order Fulfillment">
            {listing.availability.description}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Season">
            {listing.product.season}
          </Descriptions.Item>
        </>
      );

    case "bucket":
      return (
        <>
          <Descriptions.Item span={3} label="Name">
            <b>{listing.product._prototypeCache.scientificName}</b>
          </Descriptions.Item>
          {listing.product._prototypeCache.commonNames.length && (
            <Descriptions.Item span={3} label="Common Names">
              {listing.product._prototypeCache.commonNames.join(", ")}
            </Descriptions.Item>
          )}
          <Descriptions.Item span={3} label="Dominant Color">
            <ColorDisplay color={listing.product.dominantColor} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Typical Stems">
            {`${listing.product.stemsRange[0]} - ${listing.product.stemsRange[1]}`}
          </Descriptions.Item>
        </>
      );

    case "dried-flower": {
      const { product } = listing;
      return (
        <>
          <Descriptions.Item span={3} label="Type">
            {getDriedFlowerProductTypeLabel(product.type)}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Dominant Color">
            <ColorDisplay color={product.dominantColor} />
          </Descriptions.Item>
          {product.type !== "confetti" && (
            <>
              <Descriptions.Item span={3} label="Stem Count (est)">
                {product.stemCount}
              </Descriptions.Item>
              <Descriptions.Item span={3} label="Stem Length">
                {product.stemLength}
              </Descriptions.Item>
            </>
          )}
        </>
      );
    }

    case "wreath":
      return (
        <>
          <Descriptions.Item span={3} label="Diameter">
            {listing.product.diameter}
          </Descriptions.Item>
          {listing.product.tags.length > 0 && (
            <Descriptions.Item span={3} label="Tags">
              {listing.product.tags.map((tag) => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </Descriptions.Item>
          )}
          {listing.product.depth && (
            <Descriptions.Item span={3} label="Depth">
              {listing.product.depth} in.
            </Descriptions.Item>
          )}
        </>
      );
    case "potted-plant":
      return (
        <>
          <Descriptions.Item span={3} label="Type">
            {listing.product.pottedPlantType}
          </Descriptions.Item>
          {listing.product.tags.length > 0 && (
            <Descriptions.Item span={3} label="Additional Info">
              {listing.product.tags.map((tag) => (
                <Tag key={tag}>{getPottedPlantTagLabel(tag)}</Tag>
              ))}
            </Descriptions.Item>
          )}
          {listing.product.potSize && (
            <Descriptions.Item span={3} label="Planter size">
              {listing.product.potSize} in.
            </Descriptions.Item>
          )}
          {listing.product.traySize && (
            <Descriptions.Item span={3} label="Tray size">
              {listing.product.traySize}
            </Descriptions.Item>
          )}
        </>
      );

    case "ad-hoc":
      return null;

    default:
      assertCasesExhausted(listing);
  }
};
