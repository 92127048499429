import { Alert } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React from "react";

export const AdminDownForMaintenanceBanner: React.FC = () => {
  return (
    <Alert
      type="error"
      message={
        <>
          The website is currently down for maintenance--normal users are locked out of some
          functionality. Edit this in the firebase console (global/public.downForMainetance)
        </>
      }
      banner
      closable
      closeText={<CloseOutlined style={{ color: "white", fontSize: 16 }} />}
    />
  );
};
