import { EditOutlined } from "@ant-design/icons";
import { assertIsCoop, determinePricingScheme, RealRelisting, WithId } from "@rooted/shared";
import { Button, notification, Popover, Row, Space, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import { CurrencyInput, formatCurrency } from "../../../components/FormControls/CurrencyInput";
import { useRooted } from "../../../RootedContext";
import { clearRelistingPricing, updateRelistingPrice } from "../../../services/coops/relistings";

const PriceUpdateErrorMessage = "Oops! Something went wrong updating that price. Please try again.";
// Copied from GrowerPriceWidget

export const CoopPriceWidget: React.FC<{
  relisting: WithId<RealRelisting>;
  editable?: boolean;
}> = ({ relisting, editable = false }) => {
  const { activeRole: role } = useRooted();
  assertIsCoop(role);
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState<null | "clear" | "update">(null);
  const [price, setPrice] = useState(
    relisting.category === "cut-flower" ? relisting.pricing.pricePerStem : relisting.pricing.price
  );

  const scheme = determinePricingScheme(relisting.pricing);

  const clearPrice = async () => {
    setSubmitting("clear");
    try {
      await clearRelistingPricing(relisting);
    } catch {
      notification.error({ message: PriceUpdateErrorMessage });
    }
    setSubmitting(null);
    setOpen(false);
  };

  const updatePrice = async () => {
    setSubmitting("update");

    let newPricing: RealRelisting["pricing"];

    if (scheme === "minor-major") {
      // Ugly -- early on we didn't include the stems per bunch in pricing.
      // We retrieve it this way but this is kind of gross
      // We need this value to ensure the marked up major unit price is an integer
      // multiple of the marked up minor unit price.
      const quantity = Math.round(relisting.pricing.price / relisting.pricing.pricePerStem!);

      newPricing = {
        price: quantity * price,
        pricePerStem: price,
      };
    } else if (scheme === "major-minor") {
      // Ugly -- early on we didn't include the stems per bunch in pricing.
      // We retrieve it this way but this is kind of gross
      // We need this value to ensure the marked up major unit price is an integer
      // multiple of the marked up minor unit price.
      const quantity = Math.round(relisting.pricing.price / relisting.pricing.pricePerStem!);

      newPricing = {
        price: price,
        pricePerStem: Math.round(price / quantity),
      };
    } else {
      newPricing = {
        price: price,
      };
    }

    try {
      await updateRelistingPrice(relisting, newPricing);
    } catch {
      notification.error({ message: PriceUpdateErrorMessage });
    }
    setSubmitting(null);
    setOpen(false);
  };

  return (
    <>
      {relisting.overrides.pricing && (
        <Typography.Text delete type="secondary">
          {formatCurrency(
            scheme === "minor-major"
              ? relisting.backingListing.pricing.pricePerStem!
              : relisting.backingListing.pricing.price
          )}
        </Typography.Text>
      )}
      {formatCurrency(
        scheme === "minor-major" ? relisting.pricing.pricePerStem! : relisting.pricing.price
      )}
      {editable && (
        <Popover
          content={
            <>
              <Space size={16} direction="vertical">
                <CurrencyInput value={price} onChange={(e) => setPrice(e as number)} />
                <Row style={{ width: "100%", justifyContent: "space-between" }}>
                  <Tooltip title={"Set a new price for this listing"} placement="bottom">
                    <Button
                      type="primary"
                      onClick={updatePrice}
                      disabled={!open || submitting === "clear"}
                      loading={submitting === "update"}
                      style={{ marginRight: 8 }}
                    >
                      Update
                    </Button>
                  </Tooltip>
                  <Tooltip title={"Use the Grower's price"} placement="bottom">
                    <Button
                      disabled={!open || submitting === "update" || !relisting.overrides.pricing}
                      danger
                      type="ghost"
                      onClick={clearPrice}
                      loading={submitting === "clear"}
                    >
                      Clear
                    </Button>
                  </Tooltip>
                </Row>
              </Space>
            </>
          }
          title="Edit price"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <Tooltip title={"Edit price"} placement="bottom">
            <EditOutlined />
          </Tooltip>
        </Popover>
      )}
    </>
  );
};
