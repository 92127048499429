import { FullCoopRole, FullGrowerRole, FullWholesaleBuyerRole } from "@rooted/shared";
import { Row, Col, Form } from "antd";
import React from "react";
import { BulbOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { PageHeader } from "../../../components/PageHeader";
import { CopyButton } from "../../../components/Misc/CopyButton";

import "./Branding.less";

export const Branding: React.FC<{
  role: FullGrowerRole | FullCoopRole | FullWholesaleBuyerRole;
}> = ({ role }) => {
  const logoRootedMember = `/logos/logo-rooted-member.png`;
  const logoRootedMemberWhiteBG = `/logos/logo-rooted-member-white.png`;
  const giphyImages = [
    { url: "https://giphy.com/embed/iuZPFw3DGk46quQwVz", title: "Rooted Farmers Logo 1" },
    { url: "https://giphy.com/embed/3p8BvjhfmJuwMPQpiO", title: "Rooted Farmers Logo 2" },
    { url: "https://giphy.com/embed/jQ4VZ3lblJrKkuBGKP", title: "Farm to Vase Flowers" },
    { url: "https://giphy.com/embed/D3WsAufWh5SxXtcSRa", title: "Local, Seasonal Blooms For All" },
  ];
  const sampleContent =
    role.type === "grower" ? (
      <>
        <p>
          Greetings, floral designers and wholesale buyers! Our farm is a proud member of the Rooted
          Farmers network. We post our weekly availability there, and shopping through Rooted
          Farmers allows our designers to place their orders online, in real time.
        </p>
        <p>
          There is no cost for you to become a member! On Rooted, designers have access to one-stop
          online shopping across multiple farms simultaneously, or you can choose to shop only from
          a specific farm if that is your preference. You are able to search for specific flowers,
          search by color, or search by design element. Orders are paid for on delivery or pickup,
          as specified at the time of order.
        </p>
        <p>
          Ready to start buying our gorgeous flowers? All you have to do is apply here:
          <br />
          <a href="https://app.rootedfarmers.com/onboarding/?application=wholesale-buyer">
            https://app.rootedfarmers.com/onboarding/?application=wholesale-buyer
          </a>
          <br />
          <br />
          (Note – it helps if you have your reseller certificate handy).
        </p>
      </>
    ) : (
      <>
        <p>
          Greetings, flower friends and farmers! Our design shop is a proud member of the Rooted
          Farmers network. We are proud to support local farms by buying directly from them, and
          excited to bring you the highest quality blooms, grown with care, right in our back yard.
        </p>
        <p>
          If you are a farm looking to sell to us, we encourage you to learn more about Rooted by
          visiting their website (
          <a href="https://www.rootedfarmers.com/">https://www.rootedfarmers.com/</a>), or applying
          to join their platform (
          <a href="https://app.rootedfarmers.com/onboarding/?application=grower">
            https://app.rootedfarmers.com/onboarding/?application=grower
          </a>
          ).
        </p>
      </>
    );

  // Stripped off HTML : needs something more elegant to replace string above sampleContents off HTML,
  // or just let it be if this is rarely ever changing
  const sampleContentNoHTML =
    role.type === "grower"
      ? "Greetings, floral designers and wholesale buyers! Our farm is a proud member of the Rooted Farmers network. We post our weekly availability there, and shopping through Rooted Farmers allows our designers to place their orders online, in real time. There is no cost for you to become a member! On Rooted, designers have access to one-stop online shopping across multiple farms simultaneously, or you can choose to shop only from a specific farm if that is your preference. You are able to search for specific flowers, search by color, or search by design element. Orders are paid for on delivery or pickup, as specified at the time of order. Ready to start buying our gorgeous flowers? All you have to do is apply here: https://app.rootedfarmers.com/onboarding/?application=wholesale-buyer (Note – it helps if you have your reseller certificate handy)."
      : "Greetings, flower friends and farmers! Our design shop is a proud member of the Rooted Farmers network. We are proud to support local farms by buying directly from them, and excited to bring you the highest quality blooms, grown with care, right in our back yard. If you are a farm looking to sell to us, we encourage you to learn more about Rooted by visiting their website (https://www.rootedfarmers.com/), or applying to join their platform (https://app.rootedfarmers.com/onboarding/?application=grower).";

  return (
    <>
      <PageHeader title={"Branding"} />

      <section className="sect-pad-sml sect-light" id="payment">
        {role.type === "grower" ? (
          <>
            <h2 className="h5">
              Drive buyers on your website to your availability postings on Rooted Farmers!
            </h2>
            <p>
              Below, you will find downloads available for you to use, including a badge for your
              farm's site, and some sample content to let your buyers know where they can find your
              product and how Rooted Farmers works.
            </p>
          </>
        ) : (
          <>
            <h2 className="h5">Advertise your support for local farms on your website!</h2>
            <p>
              Below, you will find downloads available for you to use, including a badge for your
              business website, and some sample content to let farms and customers know that you
              support local growers and share a bit about how Rooted Farmers works.
            </p>
          </>
        )}
        <p>
          As a reminder, we ask that you use kindness and respect as you represent the Rooted
          Farmers brand and the large community of growers that Rooted Farmers supports.
        </p>
      </section>

      <section className="sect-pad-sml sect-dark" id="sample">
        <h2 className="ant-typography">Sample Website Content</h2>
        <Form requiredMark="optional" layout="vertical">
          <Form.Item
            name="sample-content"
            label={
              <span style={{ margin: "0 0 0 auto", paddingRight: 17, color: "#fff" }}>
                To copy, click <ArrowDownOutlined />
              </span>
            }
            className="full-width"
            //initialValue=""
            rules={[{ required: true, message: " " }]}
          >
            <div style={{ float: "right", backgroundColor: "#febe2d", borderBottomLeftRadius: 5 }}>
              <CopyButton text={sampleContentNoHTML} type="text" />
            </div>
            <div className="copy-to-clipboard" style={{ color: "#fff" }}>
              {sampleContent}
            </div>
          </Form.Item>
        </Form>
      </section>

      <section className="sect-pad-sml" id="download">
        <h2 className="ant-typography">Logo/Badge Download</h2>
        <br />
        <p>
          Below is a Rooted Farmers badge for use on your website – congrats! We are so happy to
          have you as a member of our extraordinary growing community, and we're excited to help you
          build your business.
        </p>
        {role.type === "grower" ? (
          <p>
            Feel free to use this badge in tandem with the text above to let your floral buyers and
            designers know where they can find your product!
          </p>
        ) : (
          <p>
            Feel free to use this badge in tandem with the text above to let your farm suppliers and
            customers know that you support small farms by buying from the Rooted Farmers network!
          </p>
        )}

        <Row>
          <Col xs={24} sm={24} md={12} style={{ padding: "30px", textAlign: "center" }}>
            <p>
              <strong>White background</strong>
            </p>
            <a href={logoRootedMemberWhiteBG} target="_blank" rel="noreferrer">
              <img
                src={logoRootedMemberWhiteBG}
                alt="Rooted Farmers Proud Member"
                style={{ width: "100%", maxWidth: 400 }}
              />
            </a>
          </Col>
          <Col xs={24} sm={24} md={12} style={{ padding: "30px", textAlign: "center" }}>
            <p>
              <strong>Transparent background</strong>
            </p>
            <a href={logoRootedMember} target="_blank" rel="noreferrer">
              <img
                src={logoRootedMember}
                alt="Rooted Farmers Proud Member"
                style={{ width: "100%", maxWidth: 400 }}
              />
            </a>
          </Col>
        </Row>
        <p className="text-center" style={{ paddingTop: 40, fontSize: 14 }}>
          <BulbOutlined style={{ fontSize: 20 }} /> <b>Tip!</b> Click or right click on the image
          and select 'Save Image As...' to download to your computer.
        </p>
      </section>

      <section className="sect-pad-sml" id="giphy" style={{ borderTop: "1px solid #3b4d3f" }}>
        <h2 className="ant-typography">Social Media Images via GIPHY</h2>
        <Row>
          {giphyImages.map(({ url, title }) => {
            return (
              <Col key={url} xs={24} sm={12} md={12} lg={6} style={{ margin: "0 auto" }}>
                <div className="responsive-iframe">
                  <iframe
                    title={title}
                    src={url}
                    width="250"
                    height="250"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </Col>
            );
          })}
        </Row>
        <p className="text-center" style={{ paddingTop: 40, fontSize: 14 }}>
          <BulbOutlined style={{ fontSize: 20 }} /> <b>Tip!</b> Using stickers in your stories?
          Search or download ours via Giphy. Click on the images above.
        </p>
      </section>
    </>
  );
};
