import {
  Connection,
  WithId,
  ApprovedConnection,
  EnterpriseProfile,
  distance as computeDistance,
  Location,
  RequestedConnection,
} from "@rooted/shared";
import { db, querySnapshotToIdDocs, snapshotToIdDoc } from "../../services/firebase";

/**
 * We use this type on the Connection pages to couple data of:
 * 1. the connection itself
 * 2. the profile the connection is with (relative the client profile)
 *
 * This is necessary for operations like filtering and sorting lists of connections based on _profile_ data.
 */
export type ConnectionWithProfile<T extends Connection = Connection> = {
  connection: WithId<T>;
  profile: WithId<EnterpriseProfile>;
  distance: number | null; // we pre-compute this to make sorting by distance easy.
};

// Predicates to help out with `ConnectionWithProfile`s:

export const sortByProfileName = (a: ConnectionWithProfile, b: ConnectionWithProfile) =>
  a.profile.bio._displayNameLowerCase.localeCompare(b.profile.bio._displayNameLowerCase);

export const sortByConnectionRequestDate = (a: ConnectionWithProfile, b: ConnectionWithProfile) =>
  a.connection.requestedOn.toMillis() - b.connection.requestedOn.toMillis();

export const sortByApprovedOnDate = (
  a: ConnectionWithProfile<ApprovedConnection>,
  b: ConnectionWithProfile<ApprovedConnection>
) => {
  // Defensively handle some broken approved states
  if (!a.connection.approvedOn) return -1;
  if (!b.connection.approvedOn) return 1;
  return b.connection.approvedOn.toMillis() - a.connection.approvedOn.toMillis();
};

export const sortByProfileDistance = (a: ConnectionWithProfile, b: ConnectionWithProfile) => {
  if (a.distance === null) return -1;
  if (b.distance === null) return 1;
  return a.distance - b.distance;
};

export const getConnectionsWithProfiles = async (
  profileId: string,
  ownType: "seller" | "buyer",
  ownLocation?: Location
) => {
  const otherType = ownType === "seller" ? "buyer" : "seller";
  const ownIdField: keyof Connection = `${ownType}Id` as "sellerId" | "buyerId";
  const otherIdField: keyof Connection = `${otherType}Id` as "sellerId" | "buyerId";

  const connections = querySnapshotToIdDocs<Connection>(
    await db.collection("connections").where(ownIdField, "==", profileId).get()
  );

  const enterpriseConnections = connections.filter(
    (connection) => connection.type === "coop-wholesale" || connection.type === "grower-wholesale"
  );

  // Get the Profile of each Connection
  const connectionsWithProfiles: ConnectionWithProfile[] = (
    await Promise.all(
      enterpriseConnections.map(async (connection) => {
        const profileId = connection[otherIdField];
        const profileDoc = await db.collection("profiles").doc(profileId).get();
        const profile = snapshotToIdDoc<EnterpriseProfile>(profileDoc);
        if (!profile) throw new Error(`Profile not found: ${profileId}`);
        return {
          connection,
          profile,
          distance:
            ownLocation && profile.bio.location
              ? computeDistance(ownLocation, profile.bio.location)
              : null,
        };
      })
    )
  )
    // Don't show disabled profiles
    .filter(({ profile }) => !profile.disabled);

  connectionsWithProfiles.sort(sortByConnectionRequestDate).sort(sortByProfileName);

  const approved = connectionsWithProfiles.filter(
    ({ connection }) => connection.status === "approved"
  ) as ConnectionWithProfile<ApprovedConnection>[];

  const requestedToClient = connectionsWithProfiles.filter(
    ({ connection }) => connection.status === `${otherType}-requested`
  ) as ConnectionWithProfile<RequestedConnection>[];
  const requestedByClient = connectionsWithProfiles.filter(
    ({ connection }) => connection.status === `${ownType}-requested`
  ) as ConnectionWithProfile<RequestedConnection>[];

  return {
    approved,
    requestedToClient,
    requestedByClient,
  };
};
