import { FullRetailBuyerRole } from "@rooted/shared";
import { Badge, Menu } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useActiveOrderCount } from "../../views/buyers/orders/order-notifications";
import { NavbarMode } from "./Navbar";

export const RetailBuyerNav: React.FC<{
  role: FullRetailBuyerRole;
  navbarMode: NavbarMode;
  onClick?: any;
}> = ({ role, navbarMode, onClick }) => {
  // Get the root level location (i.e. rootedfarmers.com/orders/msd763neo/... => "orders")
  const location = useLocation().pathname.split("/")[1] || "shop";

  return (
    <Menu
      onClick={onClick}
      mode={navbarMode}
      className="role-specific-menu"
      selectedKeys={[location]}
    >
      <Menu.Item key="shop">
        <Link to="/">Shop</Link>
      </Menu.Item>
      <Menu.Item key="orders">
        <Link to="/orders">
          Orders <Badge count={useActiveOrderCount(role)} />
        </Link>
      </Menu.Item>
      <Menu.Item key="faqs">
        <Link to="/faqs">FAQs</Link>
      </Menu.Item>
      <Menu.Item key="my-account">
        <Link to="/my-account">My Account</Link>
      </Menu.Item>
    </Menu>
  );
};
