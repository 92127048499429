import { ActiveChildOrder, canViewChildOrder, FullBuyerRole, WithId } from "@rooted/shared";
import { Breadcrumb, Card } from "antd";
import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { ChildOrderItems } from "../../../components/ChildOrderItems";
import { useRouterBreadcrumb } from "../../../components/Misc/useRouterBreadcrumb";
import { PageHeader } from "../../../components/PageHeader";
import { CardSkeleton } from "../../../components/skeletons";
import { useRooted } from "../../../RootedContext";
import { db, useDocumentDataChecked } from "../../../services/firebase";
import { NotFoundPage } from "../../../views/layouts";
import { OrderSummary } from "../../orders/OrderDetails/OrderSummary";

export const OrderDetails: React.FC<{ role: FullBuyerRole }> = ({ role }) => {
  const { orderId } = useParams();
  const { isAdmin } = useRooted();

  const [order, orderLoading] = useDocumentDataChecked<WithId<ActiveChildOrder>>(
    db.collection("childOrders").doc(orderId),
    { idField: "_id" }
  );

  const title = order ? `Order #${order.number}` : "...";
  const breadcrumb = useRouterBreadcrumb({ [orderId]: title });

  if (breadcrumb.routes?.length === 2) {
    breadcrumb.routes = [
      breadcrumb.routes[0],
      { path: "", breadcrumbName: "Active" },
      breadcrumb.routes[1],
    ];
  }

  if (orderLoading) return <CardSkeleton />;
  if (!order) return <NotFoundPage />;

  // Redirect to admin order view if the admin does not have the correct role
  if (!canViewChildOrder({ order, profileId: role.profileId })) {
    return isAdmin ? <Navigate replace to={`/admin/orders/${orderId}`} /> : <NotFoundPage />;
  }

  return (
    <>
      <Breadcrumb
        routes={breadcrumb.routes}
        itemRender={breadcrumb.itemRender}
        style={{ marginBottom: 16, paddingTop: 12 }}
      />
      <Card>
        <PageHeader title={title}>
          <div style={{ display: "flex" }}>
            <OrderSummary sellerId={order.sellerId} order={order} />
          </div>
        </PageHeader>

        <ChildOrderItems order={order} role={role} editable={false} />
      </Card>
    </>
  );
};
