import { useState, useEffect } from "react";
import { CheckPaymentMethodRequest, CheckPaymentMethodResponse } from "@rooted/shared";
import { notification } from "antd";
import { functions } from "../../services/firebase";
import { useIsMounted } from "../util/useIsMounted";
import { logDebug } from "../../sentry";

// TODO: abstract into api folder. Also, make this based on the order, not the direct payment method.
async function checkPaymentMethodExists(params: CheckPaymentMethodRequest) {
  return (await functions.httpsCallable("checkPaymentMethodExists")(params))
    .data as CheckPaymentMethodResponse;
}

/**
 * Opinionated hook that waits for a payment method Id and handles its own errors.
 * TODO: refactor this as a more generalized API response hook, so this isn't aware of its own error handling
 */
export const useCheckPaymentMethodExists = (paymentMethodId?: string) => {
  const [paymentMethodValid, setPaymentMethodValid] = useState<{
    isValid?: boolean;
    loading: boolean;
  }>({ loading: true });

  const isMounted = useIsMounted();

  useEffect(() => {
    if (paymentMethodId && paymentMethodValid.loading) {
      const request = async () => {
        try {
          const { exists } = await checkPaymentMethodExists({ paymentMethodId });
          if (!isMounted.current) return;
          setPaymentMethodValid({
            isValid: exists,
            loading: false,
          });
          if (!exists) {
            logDebug({
              message: "useCheckPaymentMethodExists: Payment Method does not exist",
              extraData: {
                paymentMethodId,
              },
            });
          }
        } catch (e) {
          // Only log an error if on the originating component.
          // Because this is a SPA, it's very possible for this to occur after a page change.
          if (isMounted.current) {
            notification.error({
              message: "Oops! Something went wrong retrieving this order. Please try again.",
            });
          }
          console.error(e);
        }
      };
      request();
    }
  }, [isMounted, paymentMethodId, paymentMethodValid.loading]);

  return [paymentMethodValid.isValid, paymentMethodValid.loading];
};
