import { ApplicationForm, WithId } from "@rooted/shared";
import { Button, List } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { SignedInComplete } from "../../../services/account-state";
import { db, useCollectionDataChecked } from "../../../services/firebase";
import { ApplicationStatusTag } from "../../onboarding/ApplicationCard";
import { PageHeader } from "../../../components/PageHeader";

export const ApplicationsPane: React.FC<{ account: SignedInComplete }> = ({ account }) => {
  const [applications, applicationsLoading] = useCollectionDataChecked<WithId<ApplicationForm>>(
    db.collection("applications").where("ownerId", "==", account.user._id),
    { idField: "_id" }
  );

  // sort by application type for easier reading
  applications?.sort((a, b) => (a.type > b.type ? 1 : -1));

  // separates each application type group with appliction type section header
  let lastApplicationType = "";

  return (
    <>
      <PageHeader
        title="Applications"
        breadcrumb
        extra={
          <Link to="/onboarding">
            <Button>New Application</Button>
          </Link>
        }
      />

      <List
        loading={applicationsLoading}
        bordered
        dataSource={applications}
        renderItem={(application) => (
          <React.Fragment key={application._id}>
            {lastApplicationType !== application.type && (
              <List.Item style={{ backgroundColor: "#f5f5f5", paddingTop: 15, paddingBottom: 15 }}>
                <b style={{ textTransform: "capitalize" }}>
                  {`${application.type.replace("-", " ")}`}s
                </b>
              </List.Item>
            )}
            <List.Item
              style={{ overflow: "auto" }}
              actions={[
                (application.status === "in-progress" &&
                  (!application.bio || !application.details)) ||
                (application.status === "approved" &&
                  application.type !== "wholesale-buyer" &&
                  (application.stripe.status === "needs-user-input" ||
                    !application.creditCardId ||
                    !application.subscription)) ? (
                  <Link to={`/onboarding/applications/${application._id}`}>
                    <Button type="primary">Continue</Button>
                  </Link>
                ) : (
                  <Link to={`/onboarding/applications/${application._id}`}>
                    <Button>View</Button>
                  </Link>
                ),
              ]}
              {...(lastApplicationType = application.type)}
            >
              {application.bio?.displayName ? (
                <>&mdash; {`${application.bio.displayName}`}</>
              ) : (
                <>&mdash; {`Untitled`}</>
              )}
              &nbsp;&nbsp;
              <ApplicationStatusTag application={application} />
            </List.Item>
          </React.Fragment>
        )}
      />
    </>
  );
};
