import React from "react";

export type FulfillmentError = {
  reason: "ORDER_MINIMUM" | "REQUIRED_NOTES";
  message: string;
};
type FulfillmentGroupErrorState = {
  [groupId: string]: { errors: FulfillmentError[] };
};

type SetGroupErrorsAction = {
  type: "SET_GROUP_ERRORS";
  payload: {
    errors: FulfillmentError[];
    groupId: string;
  };
};

// When all items in a fulfillment group are removed,
// we need to delete the group errors entirely to ensure
// the number of group error lists corresponds to the
// number of actual groups
type DeleteGroupAction = {
  type: "DELETE_GROUP";
  payload: {
    groupId: string;
  };
};

type FulfillmentGroupErrorAction = SetGroupErrorsAction | DeleteGroupAction;

function fulfillmentGroupErrorReducer(
  state: FulfillmentGroupErrorState,
  action: FulfillmentGroupErrorAction
): FulfillmentGroupErrorState {
  switch (action.type) {
    case "SET_GROUP_ERRORS": {
      const { groupId, errors } = action.payload;
      return { ...state, [groupId]: { errors } };
    }
    case "DELETE_GROUP": {
      const { groupId } = action.payload;
      const { [groupId]: _, ...newState } = state;
      return newState;
    }
    default: {
      throw new Error(`Unhandled action: ${JSON.stringify(action)}`);
    }
  }
}

type Dispatch = (action: FulfillmentGroupErrorAction) => void;
const FulfillmentGroupErrorsContext = React.createContext<
  { state: FulfillmentGroupErrorState; dispatch: Dispatch } | undefined
>(undefined);

const FulfillmentGroupErrorsProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(fulfillmentGroupErrorReducer, {});
  const value = { state, dispatch };
  return (
    <FulfillmentGroupErrorsContext.Provider value={value}>
      {children}
    </FulfillmentGroupErrorsContext.Provider>
  );
};

function useFulfillmentGroupErrors() {
  const context = React.useContext(FulfillmentGroupErrorsContext);
  if (context === undefined) {
    throw new Error("useCount must be used within a CountProvider");
  }
  return context;
}

export { FulfillmentGroupErrorsProvider, useFulfillmentGroupErrors };
