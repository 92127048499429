import { UserOutlined, MenuOutlined } from "@ant-design/icons";
import {
  FullRole,
  FullUserRole,
  isBuyer,
  isEnterprise,
  isRetail,
  WithId,
  makeName,
  isCoop,
  isGrower,
  isWholesale,
} from "@rooted/shared";

import {
  Button,
  Dropdown,
  Layout,
  Menu,
  Select,
  Space,
  Drawer,
  Modal,
  Row,
  Col,
  Typography,
} from "antd";
import React, { useMemo, useState } from "react";
import { Link, useNavigate, useLocation, useRoutes } from "react-router-dom";
import { SignedInComplete, signOut } from "../../services/account-state";
import { AdminNav } from "./AdminNav";
import { CoopNav } from "./CoopNav";
import { GrowerNav } from "./GrowerNav";
import "./Navbar.less";
import { RetailBuyerNav } from "./RetailBuyerNav";
import { ShoppingCartButton } from "./CartButtons";
import { WholesaleBuyerNav } from "./WholesaleBuyerNav";
import { breakpoint, useWindowSize } from "../../utils/detectWindowSize";
import { AddRoles } from "../../views/admins/SiteSettings/RolesPane";
import { useRooted } from "../../RootedContext";
import { EnterpriseAvatar } from "../Images/ThumbnailAvatar";

const Logo = () => (
  <Link to="/" title="Home">
    <img className="logo" src="/logos/logo-horizontal.svg" alt="Rooted Farmers logo" />
  </Link>
);

export type NavbarMode = "horizontal" | "vertical";

export const Navbar: React.FC<{ adminMode?: boolean }> = ({ adminMode }) => {
  const navigate = useNavigate();
  const { account, activeRole, setActiveRoleId, isAdmin } = useRooted();

  const [width, height] = useWindowSize();
  const navbarMode: NavbarMode = width > breakpoint.md ? "horizontal" : "vertical";

  const [menuVisible, setMenuVisible] = useState(false);
  const location = useLocation().pathname.split("/")[1];

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const onMenuClose = () => {
    setMenuVisible(false);
  };

  // Public Nav Bar
  if (account.status !== "signed-in-complete") {
    return <PublicNavBar />;
  }

  const accountMenu = (
    <Menu>
      <div className="user-info-header">
        <div>
          <b>{makeName(account.user)}</b>
          <br />
          {account.authUser.email}
        </div>
      </div>
      <Menu.Divider></Menu.Divider>
      <Menu.Item>
        {" "}
        <Link to="/user-settings/organizations">User Settings</Link>{" "}
      </Menu.Item>
      <Menu.Item onClick={signOut}> Sign out </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Header className="navbar">
      <nav
        className="navbar-container primary-navbar"
        aria-label="Main Navigation"
        style={{ flexWrap: "wrap" }}
      >
        <Logo />
        {navbarMode === "horizontal" && (
          <div className="user-settings">
            <Space size="middle">
              {isBuyer(activeRole) && <ShoppingCartButton />}

              {isAdmin && (
                <>
                  {adminMode ? (
                    <Link to="/">Return to Site</Link>
                  ) : (
                    <Link to="/admin">Admin Portal</Link>
                  )}
                </>
              )}

              {account.roles.length > 1 && !adminMode && (
                <RoleSelect
                  account={account}
                  roleOptions={account.roles}
                  selectedRoleId={activeRole?._id}
                  onChange={(role) => {
                    navigate("/");
                    setActiveRoleId(role._id);
                  }}
                />
              )}
              <Dropdown overlay={accountMenu} placement="bottomRight" trigger={["click"]}>
                <Button icon={<UserOutlined />}>{makeName(account.user)}</Button>
              </Dropdown>
            </Space>
          </div>
        )}

        {activeRole && (
          <>
            {navbarMode === "horizontal" ? (
              <div
                className="navbar-container secondary-navbar navbar-horizontal"
                style={{ flexBasis: "100%" }}
              >
                {adminMode ? (
                  <AdminNav navbarMode={navbarMode} />
                ) : (
                  <>
                    {isCoop(activeRole) && <CoopNav role={activeRole} navbarMode={navbarMode} />}
                    {isGrower(activeRole) && (
                      <GrowerNav role={activeRole} navbarMode={navbarMode} />
                    )}
                    {isRetail(activeRole) && (
                      <RetailBuyerNav role={activeRole} navbarMode={navbarMode} />
                    )}
                    {isWholesale(activeRole) && (
                      <WholesaleBuyerNav role={activeRole} navbarMode={navbarMode} />
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className="navbar-container secondary-navbar navbar-vertical">
                <Button
                  style={{ width: "50px", marginTop: "0px", marginBottom: "10px" }}
                  icon={<MenuOutlined />}
                  onClick={toggleMenu}
                ></Button>

                <Drawer
                  placement="right"
                  visible={menuVisible}
                  onClose={onMenuClose}
                  closable={true}
                  width={275}
                >
                  {isAdmin && (
                    <>
                      {adminMode ? (
                        <Link to="/">Return to Site</Link>
                      ) : (
                        <Link to="/admin">Admin Portal</Link>
                      )}
                    </>
                  )}
                  {adminMode ? (
                    <AdminNav navbarMode={navbarMode} onClick={toggleMenu} />
                  ) : (
                    <>
                      {isCoop(activeRole) && (
                        <CoopNav role={activeRole} navbarMode={navbarMode} onClick={toggleMenu} />
                      )}
                      {isGrower(activeRole) && (
                        <GrowerNav role={activeRole} navbarMode={navbarMode} onClick={toggleMenu} />
                      )}
                      {isRetail(activeRole) && (
                        <RetailBuyerNav
                          role={activeRole}
                          navbarMode={navbarMode}
                          onClick={toggleMenu}
                        />
                      )}
                      {isWholesale(activeRole) && (
                        <WholesaleBuyerNav
                          role={activeRole}
                          navbarMode={navbarMode}
                          onClick={toggleMenu}
                        />
                      )}

                      {isBuyer(activeRole) && (
                        <Menu onClick={toggleMenu} mode={"vertical"}>
                          <Menu.Item>
                            <ShoppingCartButton />
                          </Menu.Item>
                        </Menu>
                      )}
                    </>
                  )}

                  <Menu mode={"vertical"}>
                    <Menu.Item></Menu.Item>
                    <Menu.Divider></Menu.Divider>
                  </Menu>

                  {account.roles.length > 1 && (
                    <Menu mode={"vertical"}>
                      <Menu.Item>
                        <Select
                          bordered={false}
                          defaultValue={activeRole?._id || undefined}
                          onChange={(newId) => {
                            navigate("/");
                            setActiveRoleId(newId);
                            toggleMenu();
                          }}
                          style={{ width: "100%" }}
                        >
                          {account.roles.map((role) => (
                            <Select.Option value={role._id} key={role._id}>
                              <b>
                                <RoleName role={role} />
                              </b>
                            </Select.Option>
                          ))}
                        </Select>
                      </Menu.Item>
                    </Menu>
                  )}

                  <Menu mode={"vertical"} onClick={toggleMenu} selectedKeys={[location]}>
                    <Menu.Divider></Menu.Divider>
                    <Menu.Item key="user-settings">
                      <Link to="/user-settings/organizations">User Settings</Link>
                    </Menu.Item>
                    <Menu.Item onClick={signOut}> Sign out </Menu.Item>
                    <Menu.Divider></Menu.Divider>
                  </Menu>
                </Drawer>
              </div>
            )}
          </>
        )}
      </nav>
    </Layout.Header>
  );
};

const RoleSelect: React.FC<{
  roleOptions: FullUserRole[];
  onChange: (role: FullUserRole) => void;
  selectedRoleId?: string;
  account: SignedInComplete;
}> = ({ roleOptions, onChange, selectedRoleId, account }) => {
  const { isAdmin } = useRooted();
  const [options] = useMemo(() => {
    const options = roleOptions.map((role) => ({
      label: (
        <Row style={{ alignItems: "center" }} wrap={false}>
          {isEnterprise(role) && (
            <EnterpriseAvatar
              size="small"
              profile={role.profile}
              style={{ marginRight: 8, flexShrink: 0 }}
            />
          )}
          <Col flex={1}>
            <Typography.Text>{formatRoleName(role, isAdmin)}</Typography.Text>
          </Col>
        </Row>
      ),

      value: role._id,
    }));
    return [options, isAdmin];
  }, [isAdmin, roleOptions]);

  const currentRole = useMemo(() => roleOptions.find((role) => role._id === selectedRoleId), [
    roleOptions,
    selectedRoleId,
  ]);

  const showAddRetailRolesModal = () => {
    Modal.success({
      icon: null,
      title: <>Change Retail Role</>,
      content: <AddRoles account={account} retail />,
    });
  };

  return (
    <>
      {isAdmin && isRetail(currentRole) && (
        <Button type={"dashed"} onClick={showAddRetailRolesModal}>
          Change Retail Profile
        </Button>
      )}

      <Select
        bordered={false}
        value={selectedRoleId}
        onChange={(roleId: string) => {
          const role = roleOptions.find((role) => (role._id = roleId));
          if (!role) throw new Error(`Role '${roleId}' not found`);
          onChange(role);
        }}
        style={{
          marginRight: 10,
          minWidth: 240,
          maxWidth: 340,
        }}
        options={options}
      />
    </>
  );
};

const formatRoleName = (role: FullUserRole, showRetailName?: boolean): string => {
  const name = role.profile?.bio?.displayName || role.profile._id;
  if (isRetail(role)) return `Retail Account` + (showRetailName ? ` (${name})` : "");
  return name;
};

export const RoleName: React.FC<{ role: FullUserRole }> = ({ role }) => <>{formatRoleName(role)}</>;

export const PublicNavBar: React.FC = () => {
  return (
    <Layout.Header style={{ boxShadow: "0 1px #e3e8ee", paddingRight: 8 }}>
      <Row className={"navbar-container-public"} justify="space-between">
        <Logo />
        <Link to={"/login"}>
          <Button icon={<UserOutlined />}>Log in</Button>
        </Link>
      </Row>
    </Layout.Header>
  );
};
