import { LoadingOutlined } from "@ant-design/icons";
import { Layout, Spin } from "antd";
import React from "react";

export const LoadingIndicator = <LoadingOutlined style={{ fontSize: 48 }} spin />;

export const Loader: React.FC<{ style?: React.CSSProperties }> = ({ style }) => (
  <Spin style={style} indicator={LoadingIndicator} />
);

export const LoadingPage: React.FC<{ marginTop?: string }> = ({ marginTop = "40vh" }) => (
  <Layout>
    <Spin
      className="page-spinner"
      style={{ margin: "auto", marginTop: marginTop }}
      indicator={LoadingIndicator}
    />
  </Layout>
);
