import { RealListing, ListingWithFulfillment } from "@rooted/shared";
import { ColumnType } from "antd/lib/table";
import {
  buyerListingColorColumn,
  buyerListingImageColumn,
  buyerListingNameColumn,
  buyerListingQuantityColumn,
  buyerListingQuantityColumnCutFlowers,
  buyerListingStemLengthColumn,
  buyerListingPriceColumn,
  addToCartColumn,
  csaWeeksColumn,
  buyerListingDriedFlowerTypeColumn,
  buyerListingPottedPlantTypeColumn,
  buyerListingWreathDiameterColumn,
} from "../../../../components/TableColumns/BuyerListingColumns";

// https://stackoverflow.com/questions/46312206/narrowing-a-return-type-from-a-generic-discriminated-union-in-typescript
type NarrowFromCategory<T, N> = T extends { category: N } ? T : never;

export const shopColumns: {
  [C in RealListing["category"]]: ColumnType<
    ListingWithFulfillment<NarrowFromCategory<RealListing, C>>
  >[];
} = {
  "cut-flower": [
    buyerListingImageColumn,
    buyerListingNameColumn,
    buyerListingColorColumn,
    buyerListingStemLengthColumn,
    buyerListingQuantityColumnCutFlowers,
    buyerListingPriceColumn,
    addToCartColumn,
  ],
  bouquet: [
    buyerListingImageColumn,
    buyerListingNameColumn,
    buyerListingColorColumn,
    buyerListingQuantityColumn,
    buyerListingPriceColumn,
    addToCartColumn,
  ],
  bucket: [
    buyerListingImageColumn,
    buyerListingNameColumn,
    buyerListingColorColumn,
    buyerListingQuantityColumn,
    buyerListingPriceColumn,
    addToCartColumn,
  ],
  "dried-flower": [
    buyerListingImageColumn,
    buyerListingNameColumn,
    buyerListingDriedFlowerTypeColumn,
    buyerListingColorColumn,
    buyerListingStemLengthColumn,
    buyerListingQuantityColumn,
    buyerListingPriceColumn,
    addToCartColumn,
  ],
  "potted-plant": [
    buyerListingImageColumn,
    buyerListingNameColumn,
    buyerListingPottedPlantTypeColumn,
    buyerListingPriceColumn,
    addToCartColumn,
  ],
  csa: [
    buyerListingImageColumn,
    buyerListingNameColumn,
    buyerListingPriceColumn,
    csaWeeksColumn,
    buyerListingQuantityColumn,
    addToCartColumn,
  ],
  wreath: [
    buyerListingImageColumn,
    buyerListingNameColumn,
    buyerListingWreathDiameterColumn,
    buyerListingPriceColumn,
    addToCartColumn,
  ],
};
