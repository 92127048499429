import { Alert, Button, Col, Divider, Form, Image, Input, Row, Typography } from "antd";
import React, { useState } from "react";
import { signIn } from "../../services/account-state";
import { LinkWithQueryParams } from "../../utils/navigateWithQueryParams";
import { PageContent, WideLayout } from "../layouts";
import "./AuthForm.less";

export const Logo: React.FC = () => (
  <Row align="middle" justify="center">
    <Col xs={24} sm={0} style={{ textAlign: "center" }}>
      <Image
        style={{ marginTop: "1rem", display: "block", width: "200px", marginBottom: ".8rem" }}
        src="/logos/logo-horizontal.svg"
        preview={false}
        alt="Rooted Farmers logo"
      />
    </Col>
    <Col xs={{ span: 0 }} sm={{ span: 24 }} style={{ textAlign: "center" }}>
      <Image
        style={{ margin: "1rem auto", display: "block", maxWidth: "320px", width: "100%" }}
        src="/logos/logo-stacked.svg"
        preview={false}
        alt="Rooted Farmers logo"
      />
    </Col>
  </Row>
);

export const LoginForm: React.FC = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onFieldsChange = async (changedFields: any[] = [], allFields: any[] = []) => {
    if (changedFields.length === 1 && changedFields[0].name[0] === "email") {
      setError("");
    }
  };

  const onFinish = async ({ email, password }: { email: string; password: string }) => {
    setError("");
    setLoading(true);

    try {
      await signIn(email, password);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <WideLayout>
      <PageContent className="page-content-center">
        <Logo />
        <Row justify="center" className="login-title">
          <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 10 }} xl={{ span: 10 }}>
            <Typography.Title level={3} style={{ textAlign: "center" }}>
              Log In
            </Typography.Title>
            <Form
              layout="vertical"
              name="login"
              className="auth-form"
              initialValues={{ remember: true }}
              onFieldsChange={onFieldsChange}
              onFinish={onFinish}
            >
              {error && (
                <Form.Item>
                  <Alert
                    message={
                      error === "Member has been disabled" ? (
                        <Typography.Text>
                          Your account has been disabled. Please contact{" "}
                          <Typography.Link href="mailto:help@rootedfarmers.com?subject=Login Help">
                            help@rootedfarmers.com
                          </Typography.Link>{" "}
                          for support.
                        </Typography.Text>
                      ) : (
                        error
                      )
                    }
                    type="error"
                  />
                </Form.Item>
              )}
              <Form.Item
                name="email"
                getValueFromEvent={(event) => {
                  const value = event?.target?.value;
                  return typeof value === "string" ? value.trim() : value;
                }}
                rules={[
                  { required: true, message: "Please enter your email." },
                  {
                    type: "email",
                    message: "Please enter a valid email address.",
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Input placeholder="Email" type="email" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: "Please enter your password." }]}
              >
                <Input.Password placeholder="Password" visibilityToggle />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} block>
                  Log In
                </Button>
              </Form.Item>
            </Form>

            <div style={{ textAlign: "center" }}>
              <LinkWithQueryParams to="/accounts/reset-password">
                Reset Password
              </LinkWithQueryParams>{" "}
              &middot;{" "}
              <Typography.Link href="mailto:help@rootedfarmers.com?subject=Login Help">
                Ask for Help
              </Typography.Link>
            </div>

            <Divider> or </Divider>

            <LinkWithQueryParams to="/accounts/create-account">
              <Button type="default" block className="ant-btn btn-secondary">
                Create Account
              </Button>
            </LinkWithQueryParams>
          </Col>
        </Row>
      </PageContent>
    </WideLayout>
  );
};
