import { DeleteOutlined } from "@ant-design/icons";
import {
  ActiveChildOrder,
  assertRoleExists,
  ChildOrder,
  FullBuyerRole,
  FullSellerRole,
  FullUserRole,
  stringifyCard,
  TokenizedCreditCard,
  WithId,
} from "@rooted/shared";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Alert, Button, Form, Input, List, Modal, notification, Tag, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import { default as React, useState } from "react";
import { PageHeader } from "../../../components/PageHeader";
import { useRooted } from "../../../RootedContext";
import { logError } from "../../../sentry";
import {
  addPaymentMethod,
  getSetupIntentClientSecret,
  removeCreditCard,
  useMyCards,
} from "../../../services/buyers/credit-cards";
import { db, useCollectionDataChecked } from "../../../services/firebase";
import { RootedStripeProvider } from "../../../services/stripe";
import "./CreditCardsPane.less";

export const CreditCardsPane: React.FC<{
  role: WithId<FullBuyerRole | FullSellerRole>;
}> = ({ role }) => {
  const [cards, cardsLoading] = useMyCards(role);
  const [addCardFormOpen, setAddCardFormOpen] = useState(false);

  const [activeOrders] = useCollectionDataChecked<ActiveChildOrder>(
    db
      .collection("childOrders")
      .where("status", "==", "active")
      .where("buyerId", "==", role.profile._id),
    { idField: "_id" }
  );

  return (
    <>
      <PageHeader
        title={"Credit Cards"}
        extra={
          <Button type="primary" onClick={() => setAddCardFormOpen(true)}>
            Add Card
          </Button>
        }
      />
      <List
        loading={cardsLoading || !activeOrders}
        bordered
        dataSource={cards}
        locale={{
          emptyText: "You have no saved cards.",
        }}
        renderItem={(card) => <CardListItem card={card} />}
      />
      <RootedStripeProvider>
        <AddCardForm
          role={role}
          onFinish={() => setAddCardFormOpen(false)}
          visible={addCardFormOpen}
        />
      </RootedStripeProvider>
    </>
  );
};

const CardListItem: React.FC<{ card: WithId<TokenizedCreditCard> }> = ({ card }) => {
  const [removing, setRemoving] = useState(false);
  const { activeRole } = useRooted();
  assertRoleExists(activeRole);

  const [ordersUsingCard, ordersLoading] = useCollectionDataChecked<ChildOrder>(
    db
      .collection("childOrders")
      .where("status", "==", "active")
      .where("paymentMethodId", "==", card._id)
      .limit(1)
  );

  const cardUsedByActiveOrder = ordersUsingCard && ordersUsingCard.length > 0;

  const confirmRemoveCard = (card: WithId<TokenizedCreditCard>) => {
    Modal.confirm({
      icon: null,
      title: <>Remove {stringifyCard(card)}?</>,
      okText: "Remove",
      onOk: () => {
        setRemoving(true);

        // Do not return this promise -- if it is returned, Antd tries to be smart and shows
        // a loading button on the Modal. We are manually handling the loading state here,
        // so by not returning the promise the Modal will be immediatly dismissed
        removeCreditCard({ creditCardId: card._id })
          .catch((error) => {
            logError({
              error,
              tags: { type: "credit-card" },
              extraData: { cardId: card._id, profile: activeRole.profile._id },
            });

            notification.error({
              message: "Failed to remove credit card",
              description: error.message,
            });
          })
          .finally(() => {
            setRemoving(false);
          });
      },
    });
  };

  return (
    <List.Item
      key={card._id}
      extra={
        <Tooltip
          title={
            cardUsedByActiveOrder
              ? "This credit card is in use on an active order and cannot be removed."
              : undefined
          }
          trigger={["click", "hover"]}
        >
          <Button
            loading={removing || ordersLoading}
            disabled={cardUsedByActiveOrder}
            type="text"
            onClick={() => confirmRemoveCard(card)}
          >
            <DeleteOutlined />
          </Button>
        </Tooltip>
      }
    >
      {stringifyCard(card)}
    </List.Item>
  );
};

export const AddCardForm: React.FC<{
  role: FullUserRole;
  onFinish?: () => void;
  visible: boolean;
}> = ({ role, onFinish, visible }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState<boolean>(false);
  const [lastError, setLastError] = useState<string | undefined>(undefined);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    // Stripe.js has not yet loaded.
    // Form submission should be disabled so that this case can never happen
    // But we handle it here to narrow the types
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    try {
      const clientSecret = await getSetupIntentClientSecret(role);

      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)!,
          billing_details: {
            name: values["name"],
          },
        },
      });

      if (result.error) {
        setLoading(false);
        setLastError(result.error.message);
      } else {
        const method = result.setupIntent?.payment_method;
        if (method) await addPaymentMethod(role, method);
        setLoading(false);
        onFinish?.();
      }
    } catch (error) {
      notification.error({ message: "Oops! Something went wrong adding your credit card" });
      logError({
        error,
        tags: { page: "credit-card-pane" },
        extraData: {
          role,
        },
      });
      setLoading(false);
    }
  };

  const [form] = useForm();

  return (
    <Modal
      onCancel={onFinish}
      title="Add Credit Card"
      visible={visible}
      footer={
        <Button
          htmlType="submit"
          //Need to manually submit the form as the submit button is outside the form context
          onClick={() => form.submit()}
          type="primary"
          loading={loading}
          disabled={!stripe || !elements}
        >
          Submit
        </Button>
      }
    >
      <Form form={form} onFinish={handleSubmit} requiredMark="optional">
        {lastError && (
          <Form.Item>
            <Alert message={lastError} type="error" />
          </Form.Item>
        )}

        {/** Dev only message: */}
        {process.env.REACT_APP_BACKEND !== "rooted-app-v2-prod" && (
          <Form.Item>
            <Alert
              type="warning"
              message={
                <>
                  <Tag>TEST MODE</Tag>&nbsp;You can use <br />
                  4242 4242 4242 4242 2/22 222 22222 as a test card that will approve.
                </>
              }
            />
          </Form.Item>
        )}

        <Form.Item name="name" rules={[{ required: true, message: " " }]}>
          <Input placeholder="Name on card" />
        </Form.Item>
        <Form.Item>
          <CardElement />
        </Form.Item>
      </Form>
    </Modal>
  );
};
