import { EditOutlined } from "@ant-design/icons";
import { Popover, Select, Space, Tag, Typography } from "antd";
import React, { useState, useMemo } from "react";

export const GenericTagsWidget: React.FC<{
  value: string[];
  allTags: string[];
  onChange: (x: string[]) => void | Promise<void>;
  editable?: boolean;
  keyId: string;
}> = ({ value, onChange, editable, allTags, keyId }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [internalTags, setInternalTags] = useState(value);

  const submit = async (x: string[]) => {
    setSubmitting(true);
    setInternalTags(x);
    await onChange(x);

    setSubmitting(false);
  };

  const options = useMemo(() => {
    return allTags.map((t) => ({ label: t, value: t }));
  }, [allTags]);

  return (
    <React.Fragment key={keyId}>
      {value.map((t) => (
        <Tag key={`tag:${t}`}>{t}</Tag>
      ))}
      {value.length === 0 && <Typography.Text type="secondary">No tags</Typography.Text>}
      {editable && (
        <Popover
          content={
            <>
              <Space direction="vertical">
                <Select
                  style={{ width: 300 }}
                  mode="tags"
                  defaultValue={value}
                  onChange={submit}
                  options={options}
                />
              </Space>
            </>
          }
          title="Change tags"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </React.Fragment>
  );
};
