import { Layout } from "antd";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Navbar } from "../../components/Navbar";
import { Applications } from "../../views/admins/Applications";
import { Flowers } from "../../views/admins/Flowers";
import { SiteSettings } from "../admins/SiteSettings";
import { TaxRates } from "../admins/TaxRates";
import { DashboardLayout, Footer, NotFoundPage } from "../layouts";
import { UsersOverview } from "../admins/Users/UsersOverview";
import { Relistings } from "../admins/listings/Relistings";
import { useRooted } from "../../RootedContext";
import { AdminProfileList } from "./Profiles/AdminProfileList";
import { AdminOrderList, AdminOrderDetails } from "./Orders";
import { AdminProfileDetails } from "./Profiles/AdminProfileDetails/AdminProfileDetails";
import { ProfileReport } from "./Profiles/ProfileReport";

export const AdminRoutes: React.FC = () => {
  const { isAdmin } = useRooted();
  if (!isAdmin) throw new Error("Permission Denied");

  return (
    <Layout>
      <DashboardLayout>
        <Navbar adminMode />
        <Routes>
          <Route path="" element={<Navigate to="applications" replace />} />
          <Route path="tax-rates/*" element={<TaxRates />} />
          <Route path="site-settings/*" element={<SiteSettings />} />
          <Route path="applications/*" element={<Applications />} />
          <Route path="flowers/*" element={<Flowers />} />
          <Route path="users/" element={<UsersOverview />} />
          <Route path="relistings/" element={<Relistings />} />
          <Route path="orders/" element={<AdminOrderList />} />
          <Route path="orders/:orderId" element={<AdminOrderDetails />} />

          <Route path="profiles/" element={<AdminProfileList />} />
          <Route path="profiles/:profileId" element={<AdminProfileDetails />} />
          <Route path="profiles/:profileId/admin-report" element={<ProfileReport />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </DashboardLayout>
      <Footer />
    </Layout>
  );
};
