import {
  ApprovedConnection,
  WithId,
  EnterpriseProfile,
  FullEnterpriseRole,
  formatPluralCount,
  assertIsEnterprise,
  isSeller,
  formatPlural,
  Connection,
} from "@rooted/shared";
import { List, Typography, Row, Col, Select, Dropdown, Menu, Modal, Button } from "antd";
import Search from "antd/lib/input/Search";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FulfillmentWidget } from "../../components/Widgets";
import { useDebouncedTrigger } from "../../hooks/util/useDebouncedTrigger";
import { removeConnection, setConnectionTags } from "../../services/connections";
import { db, useDocumentDataChecked } from "../../services/firebase";
import { DeleteOutlined, MoreOutlined, EditOutlined } from "@ant-design/icons";
import { useRooted } from "../../RootedContext";
import { useSyncQueryParams } from "../../hooks/util/useSyncQueryParams";
import { useQuery } from "../../hooks/util/useQuery";
import {
  ConnectionWithProfile,
  sortByProfileName,
  sortByApprovedOnDate,
  sortByProfileDistance,
} from "./connectionWithProfile";
import { ProfileConnectionBlurb } from "./ProfileConnectionBlurb";
import { OrderingEnabledSwitch } from "./OrderingEnabledSwitch";

import { sortByLocaleCompare } from "../../utils/sortByLocaleCompare";

type SortMode = "name" | "recently-added" | "distance";
const connectionSorts: {
  [key in SortMode]: (
    a: ConnectionWithProfile<ApprovedConnection>,
    b: ConnectionWithProfile<ApprovedConnection>
  ) => number;
} = {
  name: sortByProfileName,
  "recently-added": sortByApprovedOnDate,
  distance: sortByProfileDistance,
};

export const ApprovedConnectionList: React.FC<{
  loading: boolean;
  dataSource: ConnectionWithProfile<ApprovedConnection>[];
  onDeleteConnection: (id: string) => void;
}> = ({ loading, dataSource: approvedConnectionProfiles, onDeleteConnection }) => {
  const { activeRole: role, permissions } = useRooted();
  assertIsEnterprise(role);

  const query = useQuery();

  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [search, setSearch] = useState(query.get("search") || "");
  const debouncedSearch = useDebouncedTrigger(search);

  const [sortMode, setSortMode] = useState<SortMode>(() => {
    const queryParam = query.get("sort");
    if (queryParam === "name" || queryParam === "recently-added") return queryParam;
    return "name";
  });

  // not syncing array string for selected groups for now. That'll require storing a representation in the
  // query param, which we should think out generally first.
  // They're also a buyer-only thing, so we shouldn't expose them.
  useSyncQueryParams([
    ["sort", sortMode],
    ["search", debouncedSearch],
  ]);

  // See notes at `updateSearchCacheConnectionTags` on how cache updating of the tags works.
  const [connectionTagsCache, setConnectionTagCache] = useState<{ [connection: string]: string[] }>(
    {}
  );

  const searchedApprovedConnections = useMemo(
    () =>
      // Short circuit if no need to filter. We also short circuit on each internal.
      (debouncedSearch.trim() || !(selectedGroups.length === 0)
        ? approvedConnectionProfiles.filter(
            ({ profile, connection: { _id } }) =>
              // Matches Tags
              (selectedGroups.length === 0 ||
                connectionTagsCache[_id].some((tag) => selectedGroups.includes(tag))) &&
              // Matches search
              (!debouncedSearch.trim() ||
                profile.bio._displayNameLowerCase.includes(
                  debouncedSearch.trim().toLocaleLowerCase()
                ))
          )
        : approvedConnectionProfiles
      ).sort(connectionSorts[sortMode]),
    [debouncedSearch, selectedGroups, approvedConnectionProfiles, sortMode, connectionTagsCache]
  );

  const connectionTypeCopy = role.type === "wholesale-buyer" ? "seller" : "buyer";

  const tagOptions = useMemo(() => {
    const knownTags = Array.from(new Set(Object.values(connectionTagsCache).flat()).keys());
    return knownTags
      .map((tag) => ({ tag }))
      .sort(sortByLocaleCompare("tag"))
      .map(({ tag }) => ({ label: tag, value: tag }));
  }, [connectionTagsCache]);

  useEffect(() => {
    const connectionsToTags: { [tag: string]: string[] } = {};
    for (const {
      connection: { tags, _id },
    } of approvedConnectionProfiles) {
      connectionsToTags[_id] = tags || [];
    }
    setConnectionTagCache(connectionsToTags);
  }, [approvedConnectionProfiles]);

  // When we update a connections tags, we can't listen to the firebase doc at this level
  // (We only want to listen at the root, rendered level, for performance)
  // To be able to support filtering from the top component, we pass a function to the component
  // to maintain our tag cache state whenever they request an update.
  const updateSearchCacheConnectionTags = useCallback(
    (id: string, tags: string[]) => {
      setConnectionTagCache({
        ...connectionTagsCache,
        [id]: tags,
      });
    },
    [connectionTagsCache]
  );

  return (
    <List
      className="list-card-header-bg"
      loading={loading}
      bordered
      header={
        !loading && (
          <Row justify="space-between" gutter={[16, 0]}>
            <Col>
              <Typography.Text style={{ marginBottom: 8, fontSize: 16 }}>
                {/* formats like: "6 Connections" | "1 Connection" | "12 (Filtered) Connections" */}
                {formatPluralCount(
                  `${debouncedSearch.trim() ? "(Matching) " : ""}Connection`,
                  searchedApprovedConnections.length
                )}
              </Typography.Text>
            </Col>
            <Col flex={1} />
            {/* This nesting of cols and rows ensures that mobile layout stacks and wraps these correctly */}
            <Col>
              <Row gutter={[16, 0]}>
                <Col>
                  <Row style={{ marginBottom: 8, alignItems: "center" }}>
                    <Typography.Text type="secondary" style={{ marginBottom: 0, marginRight: 8 }}>
                      Sort by:
                    </Typography.Text>
                    {/* These selections are maintained with `SortMode`. If this gets too lengthy,
                    we can make this with `options`, and be type safe, but this is more readable if only
                    maintaining 3
                */}
                    <Select
                      onChange={setSortMode}
                      value={sortMode}
                      style={{ fontSize: 14, width: 160 }}
                    >
                      <Select.Option value="name">Name</Select.Option>
                      <Select.Option value="recently-added">Recently Added</Select.Option>
                      <Select.Option value="distance">Distance</Select.Option>
                    </Select>
                  </Row>
                </Col>
                <Col>
                  <Search
                    placeholder={`Search by name`}
                    style={{ width: 300 }}
                    allowClear
                    value={search}
                    onChange={(e) => void setSearch(e.target.value)}
                    onSearch={(s) => void setSearch(s)}
                  />
                </Col>
              </Row>
              {permissions.seller?.buyerTags && (
                <Row>
                  <Select
                    value={selectedGroups}
                    onChange={setSelectedGroups}
                    style={{ width: "100%", paddingTop: 8 }}
                    placeholder="Filter by Groups"
                    mode="multiple"
                    allowClear
                    options={tagOptions}
                  />
                </Row>
              )}
            </Col>
          </Row>
        )
      }
      dataSource={searchedApprovedConnections}
      // This spread is needed without a provider for drilling the role
      renderItem={(data) => (
        <ApprovedConnectionCard
          {...data}
          role={role}
          onDeleteConnection={onDeleteConnection}
          tagOptions={tagOptions}
          updateSearchCacheConnectionTags={updateSearchCacheConnectionTags}
        />
      )}
      rowKey={({ connection }) => connection._id}
    >
      {/* Empty States */}
      {!loading && searchedApprovedConnections.length === 0 && (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 24,
          }}
        >
          {/* Empty Search: has viable results because has connections */}
          {approvedConnectionProfiles.length > 0 && (
            <>
              <Typography.Text style={{ marginBottom: 12 }}>
                No results found for{" "}
                {debouncedSearch && <Typography.Text code>{debouncedSearch}</Typography.Text>}
                {debouncedSearch && selectedGroups.length > 0 && <> and </>}
                {selectedGroups.length > 0 && (
                  <>
                    {/* This formats them nicely into a gramatically correct comma-seperated group of code chunks */}
                    {formatPlural("group", selectedGroups)}:{" "}
                    {selectedGroups.map((group, i) => (
                      <React.Fragment key={group}>
                        <Typography.Text code>{group}</Typography.Text>
                        {i === selectedGroups.length - 1 ? "" : ", "}
                      </React.Fragment>
                    ))}
                  </>
                )}
              </Typography.Text>
              <Button
                // this component is rendered based off the filters (search and groups),
                // so show loading if both of those have been cleared. That indicates
                // we're waiting on the local computation of those filter resets.
                loading={search === "" && selectedGroups.length === 0}
                type="primary"
                onClick={() => {
                  setSearch("");
                  setSelectedGroups([]);
                }}
              >
                Clear Search
              </Button>
            </>
          )}

          {/* No Connections */}
          {approvedConnectionProfiles.length === 0 && (
            <>
              <Typography.Text style={{ marginBottom: 12, fontSize: 16, textAlign: "center" }}>
                {`You aren't connected to any ${connectionTypeCopy}s on Rooted right now.`}

                <br />
                {`You can request to connect from a ${connectionTypeCopy}'s profile page, or by searching for nearby ${connectionTypeCopy}s:`}
              </Typography.Text>
              <Link to={`/connections/find-${connectionTypeCopy}s`}>
                <Button type="primary">{`Find nearby ${connectionTypeCopy}s`}</Button>
              </Link>
            </>
          )}
        </div>
      )}
    </List>
  );
};

const ApprovedConnectionCard: React.FC<{
  connection: WithId<ApprovedConnection>;
  profile: WithId<EnterpriseProfile>;
  role: WithId<FullEnterpriseRole>;
  onDeleteConnection: (id: string) => void;
  tagOptions: { label: string; value: string }[];
  updateSearchCacheConnectionTags: (connectionId: string, tags: string[]) => void;
}> = ({
  connection: hydrationConnection,
  profile,
  role,
  onDeleteConnection,
  tagOptions,
  updateSearchCacheConnectionTags,
}) => {
  const { permissions } = useRooted();

  // The connection supplied is initially hydrated but doesn't update --
  // this component needs to handle being connected to changes.
  // Using a stale connection will result in the Fulfillment Widgets not working properly,
  // because they expect to be used as purely controlled components.
  const { _id } = hydrationConnection;

  const [syncedConnection, loading] = useDocumentDataChecked<WithId<Connection>>(
    db.collection("connections").doc(_id),
    { idField: "_id" }
  );

  // This also protects us if the connection is deleted, and moves to the undefined state
  // This is very much an edge case of someone viewing the connection page while the other
  // profile removes the connection.
  // (this isn't handled great -- ideally that would remove from this list. this prevents a crash)
  const connection =
    syncedConnection?.status === "approved" ? syncedConnection : hydrationConnection;

  const promptDeleteConnection = useCallback(() => {
    Modal.confirm({
      type: "warning",
      title: `Remove Connection`,
      content: `Are you sure you want to remove '${profile.bio.displayName}' as a ${
        profile.type === "grower" ? "seller" : "buyer"
      }?`,
      okType: "danger",
      okText: "Remove Connection",
      onOk: async () => {
        await removeConnection(_id);
        onDeleteConnection(_id);
      },
    });
  }, [_id, onDeleteConnection, profile.bio.displayName, profile.type]);

  return (
    <List.Item className={"hover-highlight"}>
      <Row style={{ width: "100%" }}>
        <Col xs={24} sm={24} md={24} lg={10} xl={12} style={{ minHeight: 50 }}>
          <ConnectionOverview
            loading={loading}
            profile={profile}
            role={role}
            connection={connection}
            promptDeleteConnection={promptDeleteConnection}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={14} xl={12}>
          <FulfillmentWidget
            role={role}
            editable={!loading && isSeller(role)}
            connection={connection}
          />
          {isSeller(role) && permissions.seller?.buyerTags && (
            <ConnectionTags
              connection={connection}
              tagOptions={tagOptions}
              updateSearchCacheConnectionTags={updateSearchCacheConnectionTags}
            />
          )}
        </Col>
      </Row>
    </List.Item>
  );
};

const ConnectionOverview: React.FC<{
  loading: boolean;
  promptDeleteConnection: () => void;
  profile: WithId<EnterpriseProfile>;
  connection: WithId<ApprovedConnection>;
  role: WithId<FullEnterpriseRole>;
}> = ({ profile, connection, promptDeleteConnection, role, loading }) => {
  return (
    <ProfileConnectionBlurb
      profile={profile}
      connection={connection}
      extra={
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            paddingRight: 12,
          }}
        >
          {/* TODO: consider if we want to surface this explicitly to the buyer? */}
          {isSeller(role) && (
            <OrderingEnabledSwitch
              loading={loading}
              connection={connection}
              editable={isSeller(role)}
            />
          )}

          <MoreConnectionOptionsDropdown promptDeleteConnection={promptDeleteConnection} />
        </Col>
      }
    />
  );
};

const ConnectionTags: React.FC<{
  updateSearchCacheConnectionTags: (connectionId: string, tags: string[]) => void;
  tagOptions: { label: string; value: string }[];
  connection: WithId<ApprovedConnection>;
}> = ({ connection, tagOptions, updateSearchCacheConnectionTags }) => (
  <Row style={{ paddingTop: 12 }}>
    {/* I think this label is cumbersome, but we can add it if necessary: */}

    {!!connection?.tags?.length && (
      <Typography.Text style={{ paddingBottom: 4 }}>Groups:</Typography.Text>
    )}
    <Select
      style={{ width: "100%" }}
      options={tagOptions}
      placeholder={
        <>
          No Groups <EditOutlined />
        </>
      }
      mode="tags"
      value={connection.tags}
      onChange={(tags) => {
        // Update tags used for searching locally on this page. Like deleting a connection, this needs
        // to update our component-level cache
        setConnectionTags(connection._id, tags);
        updateSearchCacheConnectionTags(connection._id, tags);
      }}
    />
  </Row>
);

const MoreConnectionOptionsDropdown: React.FC<{
  promptDeleteConnection: () => void;
}> = ({ promptDeleteConnection }) => (
  <Dropdown
    trigger={["click", "hover"]}
    overlay={
      // Only has one option for now.
      <Menu
        onClick={() => {
          promptDeleteConnection();
        }}
      >
        <Menu.Item key="delete-connection">
          <DeleteOutlined /> Remove Connection
        </Menu.Item>
      </Menu>
    }
  >
    {/* TODO: figure out a better way to do this */}
    {/* This should be triggerable directly via the dropdown: https://ant.design/components/dropdown/ */}
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
      <MoreOutlined rotate={90} />
    </a>
  </Dropdown>
);
