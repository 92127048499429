import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotFoundPage } from "../../../layouts";
import { CreatePlan } from "./CreatePlan";
import { EditPlan } from "./EditPlan";
import { ListPlans } from "./ListPlans";

export const SubscriptionPlansPane = () => (
  <Routes>
    <Route path="create" element={<CreatePlan />} />
    <Route path=":planId" element={<EditPlan />} />
    <Route path="" element={<ListPlans />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);
