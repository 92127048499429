import React, { useMemo } from "react";
import { Divider, Form, Checkbox, Input, Skeleton } from "antd";
import {
  db,
  useDocumentDataChecked,
  useDocumentDataOnceChecked,
} from "../../../../services/firebase";
import { PrivateProfile, Profile, WithId, assertIsEnterprise } from "@rooted/shared";

import { FirestoreDocForm } from "../../../../components/FormControls/FirestoreDocForm";
import { WarningOutlined } from "@ant-design/icons";

export const AdminEnterpriseProfileDetails: React.FC<{ profileId: string }> = ({ profileId }) => {
  const profileRef = useMemo(() => db.collection("profiles").doc(profileId), [profileId]);
  const privateProfileRef = useMemo(() => db.collection("privateProfiles").doc(profileId), [
    profileId,
  ]);

  const [profile, profileLoading] = useDocumentDataOnceChecked<WithId<Profile>>(profileRef, {
    idField: "_id",
  });

  const [privateProfile, privateProfileLoading] = useDocumentDataChecked<WithId<PrivateProfile>>(
    privateProfileRef
  );

  if (profileLoading || privateProfileLoading) return <Skeleton />;

  assertIsEnterprise(profile);

  return (
    <>
      <Divider orientation="left">Public Profile Settings</Divider>
      <FirestoreDocForm data={profile} dbRef={profileRef}>
        <Form.Item valuePropName="checked" name="disabled">
          <Checkbox>
            Disabled -{" "}
            <span style={{ color: "red" }}>
              <WarningOutlined style={{ color: "red" }} /> Make sure you mean to do this!
            </span>
          </Checkbox>
        </Form.Item>

        <Form.Item valuePropName="checked" name="hiddenInSearch">
          <Checkbox>Hidden in search</Checkbox>
        </Form.Item>
      </FirestoreDocForm>
      <Divider orientation="left">Private Profile Settings</Divider>
      <FirestoreDocForm data={privateProfile} dbRef={privateProfileRef}>
        <p>Admin Notes:</p>
        <Form.Item name="adminNotes">
          <Input.TextArea rows={3} />
        </Form.Item>

        <Form.Item valuePropName="checked" name="profileSearchDisabled">
          <Checkbox>Searching disabled</Checkbox>
        </Form.Item>
      </FirestoreDocForm>
    </>
  );
};
