import { Layout } from "antd";
import React from "react";
import "./Layouts.less";
import { useWindowSize } from "../../utils/detectWindowSize";

export const DashboardLayout: React.FC = (props) => (
  <>
    <div className="dashboard-layout">{props.children}</div>
  </>
);

/**
 * A page layout with constrained width. This is used for most pages that don't have a sidebar.
 */
export const NarrowLayout: React.FC = (props) => (
  <>
    <Layout className="narrow-layout">{props.children}</Layout>
  </>
);

export const WideLayout: React.FC = (props) => (
  <>
    <Layout className="wide-layout">{props.children}</Layout>
  </>
);

interface LayoutProps {
  className?: string;
}

const LayoutProps: LayoutProps = {
  className: "",
};

export const PageContent: React.FC<LayoutProps> = (props) => {
  const [width, height] = useWindowSize();
  //const classDefault = width > breakpoint.md ? "page-content" : undefined;
  const classDefault = "page-content";

  return (
    <>
      <Layout.Content className={[classDefault, props.className].join(" ")}>
        {props.children}
      </Layout.Content>
    </>
  );
};
