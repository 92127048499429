import { Badge, Menu } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useCount } from "../../services/higher-order-searches";
import { useNeedsApprovalApplications } from "../../views/admins/Applications/useApplications";
import { NavbarMode } from "./Navbar";
export const AdminNav: React.FC<{ navbarMode: NavbarMode; onClick?: any }> = ({
  navbarMode,
  onClick,
}) => {
  // Get the root level location (i.e. rootedfarmers.com/orders/msd763neo/... => "orders")
  const locations = useLocation().pathname.split("/");

  return (
    <Menu
      className="role-specific-menu"
      selectedKeys={locations}
      onClick={onClick}
      mode={navbarMode}
    >
      <Menu.Item key="flowers">
        <Link to="/admin/flowers">Flowers</Link>
      </Menu.Item>
      {/* <Menu.Item key="Tax Rates">
        <Link to="tax-rates">Tax Rates</Link>
      </Menu.Item> */}
      <Menu.Item key="applications">
        <Link to="/admin/applications">
          Applications <Badge count={useCount(useNeedsApprovalApplications("all"))} />
        </Link>
      </Menu.Item>
      <Menu.Item key="profiles">
        <Link to="/admin/profiles">Profiles</Link>
      </Menu.Item>
      <Menu.Item key="users">
        <Link to="/admin/users">Users</Link>
      </Menu.Item>
      <Menu.Item key="orders">
        <Link to="/admin/orders">Orders</Link>
      </Menu.Item>
      <Menu.Item key="site-settings">
        <Link to="/admin/site-settings">Site Settings</Link>
      </Menu.Item>
    </Menu>
  );
};
