import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import {
  ApplicationForm,
  WithId,
  printDate,
  isWholesale,
  bioComplete,
  detailsComplete,
  subscriptionComplete,
} from "@rooted/shared";

import {
  Button,
  Collapse,
  message,
  Space,
  notification,
  Typography,
  Form,
  Input,
  Tooltip,
  Tag,
} from "antd";
import React, { useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  approveApplication,
  createProfileFromApplication,
  rejectApplication,
  sendApplicationApprovedEmail,
  unwaitlistApplication,
} from "../../../../services/applications";
import { functions, db, useDocumentDataChecked } from "../../../../services/firebase";
import { LoadingPage, NotFoundPage } from "../../../layouts";
import { BioPanel } from "./BioPanel";
import { CoopDetailsPanel } from "./CoopDetailsPanel";
import { GrowerDetailsPanel } from "./GrowerDetailsPanel";
import { OwnerPanel } from "./OwnerPanel";
import { StripePanel } from "./StripePanel";
import { CoopSubscriptionPanel } from "./SubscriptionPanel";
import { WholesaleBuyerDetailsPanel } from "./WholesaleBuyerDetailsPanel";
import { FirestoreDocForm } from "../../../../components/FormControls/FirestoreDocForm";
import { CloudFunctionButton } from "../../SiteSettings/ActionsPane";
import { logError } from "../../../../sentry";
import { PageHeader } from "../../../../components/PageHeader";
import { useRouterBreadcrumb } from "../../../../components/Misc/useRouterBreadcrumb";

export const ApplicationDetails: React.FC = () => {
  const { itemId } = useParams();

  const [application, applicationLoading] = useDocumentDataChecked<WithId<ApplicationForm>>(
    db.collection("applications").doc(itemId),
    { idField: "_id" }
  );

  const title = application?.bio?.displayName || "Untitled application";
  const breadcrumb = useRouterBreadcrumb({
    item: false,
    [itemId]: title,
  });

  // Manually recheck this single application's stripe completion / approval status
  const checkStripeStatus = useCallback(async () => {
    try {
      await functions.httpsCallable("checkSingleStripeOnboardingStatus")(itemId);
    } catch (error) {
      logError({
        error,
        tags: {
          page: "application-details",
        },
        extraData: {
          applicationId: itemId,
        },
      });
      notification.error({ message: "Error checking stripe status: " + error.message });
    }
  }, [itemId]);

  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  if (!applicationLoading && !application) return <NotFoundPage />;
  if (!application || applicationLoading) return <LoadingPage />;

  const approveSeller = async () => {
    try {
      setApproving(true);
      await approveApplication(application);
      await sendApplicationApprovedEmail(application);

      setApproving(false);
      notification.success({
        message: <>Application Approved.</>,
      });
    } catch (e) {
      setApproving(false);
      notification.error({
        message: <>Application approval failed.</>,
        description: e.name + ": " + e.message,
      });
    }
  };

  const createSellerProfile = async () => {
    try {
      setApproving(true);
      await createProfileFromApplication(application);
      notification.success({
        message: "Profile created!",
      });
    } catch (e) {
      setApproving(false);
      notification.error({
        message: <>Application approving process failed.</>,
        description: e.name + ": " + e.message,
      });
    } finally {
      setApproving(false);
    }
  };

  const createWholesaleBuyerProfile = async () => {
    try {
      setApproving(true);
      await approveApplication(application);
      await createProfileFromApplication(application);
      await sendApplicationApprovedEmail(application);
      setApproving(false);
      notification.success({
        message: "Profile created!",
      });
    } catch (e) {
      setApproving(false);
      console.log(e);
      notification.error({
        message: <>Application approving process failed.</>,
        description: e.name + ": " + e.message,
      });
    }
  };

  const reject = async () => {
    try {
      setRejecting(true);
      await rejectApplication(application);
      setRejecting(false);
      message.success("Application waitlisted.");
    } catch (e) {
      setApproving(false);
      message.error(`Failed to waitlist: ${e.message}`);
    }
  };

  const unwaitlist = async () => {
    if (application.status !== "waitlisted") {
      // Should be impossible as the UI won't be drawn, but we want to be sure
      message.error(`Failed to remove from waitlist: Application is not in waitlist.`);
      return;
    }

    try {
      setApproving(true);
      // Typescript won't narrow the type of `application.status` here on its own
      await unwaitlistApplication({ ...application, status: application.status });
      message.success("Application removed from waitlist.");
    } catch (e) {
      message.error(`Failed to remove from waitlist: ${e.message}`);
    } finally {
      setApproving(false);
    }
  };

  return (
    <>
      <PageHeader
        breadcrumb={breadcrumb}
        title={title}
        extra={
          application.status === "in-progress" ? (
            <Space>
              {application.bio && application.details && (
                <Tooltip title="Needs a Rooted admin's approval to continue.">
                  <Tag>Needs approval</Tag>
                </Tooltip>
              )}
              {!application.bio && application.details && (
                <Tooltip title={"Needs 'Bio' completed before approval"}>
                  <Tag>In progress</Tag>
                </Tooltip>
              )}
              {application.bio && !application.details && (
                <Tooltip title={"Needs 'Details' completed before approval"}>
                  <Tag>In progress</Tag>
                </Tooltip>
              )}
              {!application.bio && !application.details && (
                <Tooltip title={"No sections have been started."}>
                  <Tag>Empty</Tag>
                </Tooltip>
              )}
              {application.bio && application.details && (
                <Button
                  type="primary"
                  loading={approving}
                  disabled={rejecting}
                  onClick={isWholesale(application) ? createWholesaleBuyerProfile : approveSeller}
                >
                  {application.type === "wholesale-buyer" ? "Create profile" : "Approve"}
                </Button>
              )}
              <Button danger loading={rejecting} disabled={approving} onClick={reject}>
                Waitlist
              </Button>
            </Space>
          ) : application.status === "approved" ? (
            <Space>
              {application.type !== "wholesale-buyer" &&
                (rejecting ||
                  !application.creditCardId ||
                  !application.subscription ||
                  application.stripe.status !== "complete") && (
                  <Tooltip title="Approved by rooted; needs additional info to create profile">
                    <Tag color="blue">Approved</Tag>
                  </Tooltip>
                )}
              {application.type !== "wholesale-buyer" &&
                application.stripe.status === "complete" &&
                application.creditCardId &&
                application.subscription && (
                  <Tooltip title="Approved by rooted; ready to create profile">
                    <Tag color="blue">Approved</Tag>
                  </Tooltip>
                )}
              <CloudFunctionButton onClick={checkStripeStatus} type="ghost">
                Check stripe status
              </CloudFunctionButton>
              {application.type !== "wholesale-buyer" && (
                <Button
                  type="primary"
                  loading={approving}
                  disabled={
                    rejecting ||
                    !application.creditCardId ||
                    !application.subscription ||
                    application.stripe.status !== "complete"
                  }
                  onClick={createSellerProfile}
                >
                  Create Profile
                </Button>
              )}
            </Space>
          ) : application.status === "waitlisted" ? (
            <Space>
              <Tooltip title="This application has been waitlisted (rejected)">
                <Tag color="red">Waitlisted</Tag>
              </Tooltip>
              <Button loading={approving} onClick={unwaitlist}>
                Remove from Waitlist
              </Button>
            </Space>
          ) : (
            <>
              <Tooltip title="This application is complete">
                <Tag color="green">Profile Created</Tag>
              </Tooltip>
              <Link to={`/${application.type}s/${application._id}`}>
                Visit profile <ArrowRightOutlined />
              </Link>
            </>
          )
        }
      />
      <Typography.Text>
        <b>Created</b>: {printDate(application._created)}
      </Typography.Text>{" "}
      <br />
      <Typography.Text>
        <b>Submitted</b>: {printDate(application._submitted)}
      </Typography.Text>{" "}
      <br />
      <br />
      <FirestoreDocForm
        layout="vertical"
        data={application}
        dbRef={db.collection("applications").doc(application._id)}
        rootFieldPath=""
      >
        <Form.Item name="notes" label="Admin Notes">
          <Input.TextArea rows={4} />
        </Form.Item>
      </FirestoreDocForm>
      <br />
      <Collapse defaultActiveKey={["owner"]}>
        <Collapse.Panel
          collapsible="header"
          header={"Owner"}
          key="owner"
          extra={<CheckCircleOutlined style={{ color: "green" }} />}
        >
          <OwnerPanel application={application} />
        </Collapse.Panel>
        <Collapse.Panel
          collapsible="header"
          header={"Bio"}
          key="bio"
          extra={
            bioComplete(application) ? (
              <CheckCircleOutlined style={{ color: "green" }} />
            ) : (
              <EllipsisOutlined />
            )
          }
        >
          <BioPanel application={application} />
        </Collapse.Panel>
        {application.type === "grower" && (
          <Collapse.Panel
            collapsible="header"
            header={"Details"}
            key="details"
            extra={
              detailsComplete(application) ? (
                <CheckCircleOutlined style={{ color: "green" }} />
              ) : (
                <EllipsisOutlined />
              )
            }
          >
            <GrowerDetailsPanel application={application} />
          </Collapse.Panel>
        )}
        {application.type === "wholesale-buyer" && (
          <Collapse.Panel
            collapsible="header"
            header={"Details"}
            key="details"
            extra={
              detailsComplete(application) ? (
                <CheckCircleOutlined style={{ color: "green" }} />
              ) : (
                <EllipsisOutlined />
              )
            }
          >
            <WholesaleBuyerDetailsPanel application={application} />
          </Collapse.Panel>
        )}
        {application.type === "coop" && (
          <Collapse.Panel
            collapsible="header"
            header={"Details"}
            key="details"
            extra={
              detailsComplete(application) ? (
                <CheckCircleOutlined style={{ color: "green" }} />
              ) : (
                <EllipsisOutlined />
              )
            }
          >
            <CoopDetailsPanel application={application} />
          </Collapse.Panel>
        )}
        {(application.type === "grower" || application.type === "coop") && [
          <Collapse.Panel
            collapsible="header"
            header={"Subscription"}
            key="subscription"
            extra={
              subscriptionComplete(application) ? (
                <CheckCircleOutlined style={{ color: "green" }} />
              ) : (
                <EllipsisOutlined />
              )
            }
          >
            <CoopSubscriptionPanel application={application} />
          </Collapse.Panel>,
          <Collapse.Panel
            collapsible="header"
            header={"Stripe"}
            key="stripe"
            extra={
              application.stripe.status === "needs-user-input" ? (
                <EllipsisOutlined />
              ) : application.stripe.status === "under-stripe-review" ? (
                <ClockCircleOutlined />
              ) : application.stripe.status === "complete" ? (
                <CheckCircleOutlined style={{ color: "green" }} />
              ) : (
                <CloseCircleOutlined style={{ color: "red" }} />
              )
            }
          >
            <StripePanel application={application} />
          </Collapse.Panel>,
        ]}
      </Collapse>
    </>
  );
};
