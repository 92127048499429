import { EditOutlined } from "@ant-design/icons";
import {
  BloomSize,
  ColorKey,
  CustomListingAvailability,
  CutFlowerListing,
  determinePricingScheme,
  firestoreToMoment,
  FullGrowerRole,
  GrowerBouquetListing,
  GrowerCutFlowerListing,
  GrowerDriedFlowerListing,
  GrowerListing,
  Listing,
  PhysicalListingAvailability,
  StemLength,
  VaseLife,
  WithId,
  WreathDiameter,
  WreathListing,
  WreathTag,
} from "@rooted/shared";
import { Button, Image, Input, InputNumber, Popconfirm, Popover, Space, Typography } from "antd";
import firebase from "firebase/app";
import React, { useCallback, useState } from "react";
import { CurrencyInput, formatCurrency } from "../../../../components/FormControls/CurrencyInput";
import { MediaLibrarySelect } from "../../../../components/FormControls/MediaLibrarySelect";
import { ColorDisplay } from "../../../../components/Misc/ColorDisplay";
import { DateRangePicker } from "../../../../components/Misc/DateRangePicker";
import { ListingInterval } from "../../../../components/TableColumns/AvailabilityColumn";
import { db } from "../../../../services/firebase";
import { momentToFirestore } from "../../../../utils/dates";
import { StemCountInput } from "./BouquetUtils";
import {
  BloomSizeInput,
  PricingGuide,
  SingleColorInput,
  StemLengthInput,
  StemsPerBunchInput,
  VaseLifeInput,
} from "./FlowerUtils";
import { WreathDepthInput, WreathDiameterInput, WreathTagsInput } from "./WreathUtils";

export const ListingNameWidget: React.FC<{
  listing: WithId<GrowerListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState<string | undefined>(listing.product.name);

  const submit = useCallback(async () => {
    if (typeof name !== "string") return;
    const trimmed = name.trim();
    if (trimmed.length === 0) return;

    setSubmitting(true);

    await db.collection("listings").doc(listing._id).update({
      "product.name": trimmed,
    });

    setSubmitting(false);
    setOpen(false);
  }, [name, listing._id]);

  return (
    <>
      {listing.product.name}
      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <Input
                  type="text"
                  value={name}
                  onChange={(newName) => setName(newName.target.value)}
                  maxLength={200}
                />
                <br />
                <Button
                  type="primary"
                  onClick={submit}
                  loading={submitting}
                  disabled={name?.trim().length === 0}
                >
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change name"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

export const GrowerPriceWidget: React.FC<{
  listing: WithId<GrowerListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [price, setPrice] = useState(
    listing.category === "cut-flower" ? listing.pricing.pricePerStem : listing.pricing.price
  );

  const scheme = determinePricingScheme(listing.pricing);

  const submit = async () => {
    setSubmitting(true);

    if (scheme === "minor-major") {
      // Ugly -- early on we didn't include the stems per bunch in pricing.
      // We retrieve it this way but this is kind of gross
      // We need this value to ensure the marked up major unit price is an integer
      // multiple of the marked up minor unit price.
      const quantity = Math.round(listing.pricing.price / listing.pricing.pricePerStem!);

      await db
        .collection("listings")
        .doc(listing._id)
        .set(
          {
            pricing: {
              price: quantity * price,
              pricePerStem: price,
            },
          },
          { merge: true }
        );
    } else if (scheme === "major-minor") {
      // Ugly -- early on we didn't include the stems per bunch in pricing.
      // We retrieve it this way but this is kind of gross
      // We need this value to ensure the marked up major unit price is an integer
      // multiple of the marked up minor unit price.
      const quantity = Math.round(listing.pricing.price / listing.pricing.pricePerStem!);

      await db
        .collection("listings")
        .doc(listing._id)
        .set(
          {
            pricing: {
              price: price,
              pricePerStem: Math.round(price / quantity),
            },
          },
          { merge: true }
        );
    } else {
      await db
        .collection("listings")
        .doc(listing._id)
        .set(
          {
            pricing: {
              price: price,
            },
          },
          { merge: true }
        );
    }

    setSubmitting(false);
    setOpen(false);
  };

  return (
    <>
      {formatCurrency(
        scheme === "minor-major" ? listing.pricing.pricePerStem! : listing.pricing.price
      )}
      {editable && (
        <Popover
          content={
            <>
              <Space size={16} direction="vertical">
                {listing.category === "cut-flower" && (
                  <PricingGuide prototypeId={listing.product.prototypeId} price={price} />
                )}
                <CurrencyInput value={price} onChange={(e) => setPrice(e as number)} />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change price"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

export const ListingQuantityDisplay: React.FC<{ listing: Listing }> = ({ listing }) => {
  const { quantity } = listing.availability;
  const inStock = quantity > 0;
  return (
    <Typography.Text type={inStock ? undefined : "danger"}>
      {!inStock && <>None</>}
      {inStock && (
        <>
          {listing.availability.quantity}
          {listing.category === "cut-flower" && (
            <>
              {" "}
              bunches
              <br />
              <span style={{ whiteSpace: "nowrap" }}>
                ({quantity * listing.product.stemsPerBunch} stems total)
              </span>
            </>
          )}
        </>
      )}
    </Typography.Text>
  );
};

export const GrowerQuantityWidget: React.FC<{
  listing: WithId<GrowerListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [quantity, setQuantity] = useState(listing.availability.quantity);

  const submit = async () => {
    setSubmitting(true);

    await db.collection("listings").doc(listing._id).set(
      {
        availability: { quantity },
      },
      { merge: true }
    );

    setSubmitting(false);
    setOpen(false);
  };

  return (
    <>
      {/* TODO fix the types here */}
      <ListingQuantityDisplay listing={listing as CutFlowerListing} />
      {editable && listing.availability.active && (
        <Popover
          content={
            <>
              <Space size={4}>
                <InputNumber
                  // Force int only
                  parser={(value) => (value ? parseInt(value) || 0 : 0)}
                  min={0}
                  step={1}
                  value={quantity}
                  onChange={(e) => setQuantity(e as number)}
                />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change quantity"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

/**
 * Widget for editing physical availability dates for a product.
 *
 * If allListings is passed in, providees bulk editing options for those listings.
 * ("apply to all")
 */
export const GrowerListingDatesWidget: React.FC<{
  listing: WithId<GrowerListing> & {
    availability: PhysicalListingAvailability | CustomListingAvailability;
  };
  editable?: boolean;
  allListings?: WithId<GrowerListing>[];
}> = ({ listing, editable = false, allListings }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [dates, setDates] = useState<[moment.Moment | null, moment.Moment | null]>([
    listing.availability.startDate ? firestoreToMoment(listing.availability.startDate) : null,
    listing.availability.endDate ? firestoreToMoment(listing.availability.endDate) : null,
  ]);

  const submit = async () => {
    setSubmitting(true);

    await db
      .collection("listings")
      .doc(listing._id)
      .set(
        {
          availability: {
            startDate: momentToFirestore(dates[0]!),
            endDate: momentToFirestore(dates[1]!),
          },
        },
        { merge: true }
      );

    setSubmitting(false);
    setOpen(false);
  };

  const updateAll = async () => {
    setSubmitting(true);

    const promises = (allListings || []).map((l) =>
      db
        .collection("listings")
        .doc(l._id)
        .set(
          {
            availability: {
              startDate: momentToFirestore(dates[0]!),
              endDate: momentToFirestore(dates[1]!),
            },
          },
          { merge: true }
        )
    );

    await Promise.all(promises);

    setSubmitting(false);
    setOpen(false);
  };

  return (
    <>
      <ListingInterval listing={listing} />
      {editable && listing.availability.active && (
        <Popover
          content={
            <>
              <Space size="small" wrap={true}>
                <DateRangePicker value={dates} onChange={setDates} width={230} />

                <Button
                  type="primary"
                  onClick={submit}
                  loading={submitting}
                  disabled={!dates[0] || !dates[1]}
                >
                  Update
                </Button>
                {allListings && allListings.length > 1 && (
                  <Popconfirm
                    title={`Are you sure to update ${allListings.length} listing${
                      allListings.length > 1 ? "s" : ""
                    }`}
                    onConfirm={updateAll}
                    okText="Yes"
                    cancelText="No"
                    disabled={!dates[0] || !dates[1] || submitting}
                  >
                    <Button type="primary" loading={submitting} disabled={!dates[0] || !dates[1]}>
                      Update all ({allListings.length})
                    </Button>
                  </Popconfirm>
                )}
              </Space>
            </>
          }
          title="Change dates"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

/**
 * Widget for editing physical availability dates for a product.
 *
 * If allListings is passed in, providees bulk editing options for those listings.
 * ("apply to all")
 */
export const GrowerBulkListingDatesWidget: React.FC<{
  allListings: (WithId<GrowerListing> & {
    availability: PhysicalListingAvailability | CustomListingAvailability;
  })[];
}> = ({ allListings }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [dates, setDates] = useState<[moment.Moment | null, moment.Moment | null]>([
    allListings[0]?.availability.startDate
      ? firestoreToMoment(allListings[0].availability.startDate)
      : null,
    allListings[0]?.availability.endDate
      ? firestoreToMoment(allListings[0].availability.endDate)
      : null,
  ]);

  const updateAll = async () => {
    setSubmitting(true);

    const promises = (allListings || []).map((l) =>
      db
        .collection("listings")
        .doc(l._id)
        .set(
          {
            availability: {
              startDate: momentToFirestore(dates[0]!),
              endDate: momentToFirestore(dates[1]!),
            },
          },
          { merge: true }
        )
    );

    await Promise.all(promises);

    setSubmitting(false);
    setOpen(false);
  };

  return (
    <Popover
      content={
        <>
          <Space size={4}>
            <DateRangePicker
              format={"MM/DD/YY"}
              value={dates}
              onChange={(e) => (e ? setDates(e) : setDates([null, null]))}
            />
            <br />
            <Popconfirm
              title={`Are you sure to update ${allListings.length} listing${
                allListings.length > 1 ? "s" : ""
              }`}
              onConfirm={updateAll}
              okText="Yes"
              cancelText="No"
              disabled={!dates[0] || !dates[1] || submitting}
            >
              <Button type="primary" loading={submitting} disabled={!dates[0] || !dates[1]}>
                Update all ({allListings.length})
              </Button>
            </Popconfirm>
          </Space>
        </>
      }
      title="Change dates"
      trigger="click"
      visible={open}
      onVisibleChange={setOpen}
      placement="bottomRight"
    >
      &nbsp;&nbsp;
      <EditOutlined />
    </Popover>
  );
};

export const BloomSizeWidget: React.FC<{
  listing: WithId<GrowerCutFlowerListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [bloomSize, setBloomSize] = useState(listing.product.bloomSize);

  const saveBloomSize = useCallback(async () => {
    setSubmitting(true);

    await db
      .collection("listings")
      .doc(listing._id)
      .update({
        "product.bloomSize": bloomSize ?? firebase.firestore.FieldValue.delete(),
      });

    setSubmitting(false);
    setOpen(false);
  }, [listing._id, bloomSize]);

  return (
    <>
      {listing.product.bloomSize}

      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <BloomSizeInput value={bloomSize} onChange={(e) => setBloomSize(e as BloomSize)} />
                <br />
                <Button type="primary" onClick={saveBloomSize} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change bloom size"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

export const WreathTagsWidget: React.FC<{
  listing: WithId<WreathListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [tags, setTags] = useState(listing.product.tags);

  const submit = useCallback(async () => {
    setSubmitting(true);

    await db
      .collection("listings")
      .doc(listing._id)
      .update({
        "product.tags": tags ?? [],
      });

    setSubmitting(false);
    setOpen(false);
  }, [listing._id, tags]);

  return (
    <>
      {listing.product.tags.join(", ")}

      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <WreathTagsInput
                  value={tags}
                  onChange={(newTags) => setTags(newTags as WreathTag[])}
                />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change tags"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

export const WreathDiameterWidget: React.FC<{
  listing: WithId<WreathListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [diameter, setDiameter] = useState(listing.product.diameter);

  const submit = useCallback(async () => {
    setSubmitting(true);

    await db.collection("listings").doc(listing._id).update({
      "product.diameter": diameter,
    });

    setSubmitting(false);
    setOpen(false);
  }, [listing._id, diameter]);

  return (
    <>
      {listing.product.diameter}

      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <WreathDiameterInput
                  value={diameter}
                  onChange={(newDiameter) => setDiameter(newDiameter as WreathDiameter)}
                />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change diameter"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

export const WreathDepthWidget: React.FC<{
  listing: WithId<WreathListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [depth, setDepth] = useState(listing.product.depth);

  const submit = useCallback(async () => {
    setSubmitting(true);

    await db
      .collection("listings")
      .doc(listing._id)
      .update({
        "product.depth": depth || firebase.firestore.FieldValue.delete(),
      });

    setSubmitting(false);
    setOpen(false);
  }, [listing._id, depth]);

  return (
    <>
      {listing.product.depth}

      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <WreathDepthInput
                  value={depth}
                  onChange={(newDepth) => setDepth((newDepth || undefined) as number | undefined)}
                />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change depth"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

export const StemsPerBunchWidget: React.FC<{
  listing: WithId<GrowerCutFlowerListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [stemsPerBunch, setStemsPerBunch] = useState(listing.product.stemsPerBunch);

  const submit = useCallback(async () => {
    setSubmitting(true);

    await db
      .collection("listings")
      .doc(listing._id)
      .update({
        "product.stemsPerBunch": stemsPerBunch,
        "pricing.price": stemsPerBunch * listing.pricing.pricePerStem,
      });

    setSubmitting(false);
    setOpen(false);
  }, [listing._id, listing.pricing.pricePerStem, stemsPerBunch]);

  return (
    <>
      {listing.product.stemsPerBunch}

      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <StemsPerBunchInput
                  value={stemsPerBunch}
                  onChange={(e) => setStemsPerBunch(e as 5 | 10)}
                />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change stems / bunch"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

export const DominantColorWidget: React.FC<{
  listing: WithId<GrowerListing> & { product: { dominantColor: ColorKey } };
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [dominantColor, setDominantColor] = useState(listing.product.dominantColor);

  const submit = useCallback(async () => {
    setSubmitting(true);

    await db.collection("listings").doc(listing._id).update({
      "product.dominantColor": dominantColor,
    });

    setSubmitting(false);
    setOpen(false);
  }, [dominantColor, listing._id]);

  return (
    <>
      <ColorDisplay color={listing.product.dominantColor} />

      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <SingleColorInput
                  value={dominantColor}
                  onChange={(e) => setDominantColor(e as ColorKey)}
                />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change color"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

export const VaseLifeWidget: React.FC<{
  listing: WithId<GrowerCutFlowerListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [vaseLife, setVaseLife] = useState(listing.product.vaseLife);

  const submit = async () => {
    setSubmitting(true);

    await db
      .collection("listings")
      .doc(listing._id)
      .update({
        "product.vaseLife": vaseLife ?? firebase.firestore.FieldValue.delete(),
      });

    setSubmitting(false);
    setOpen(false);
  };

  return (
    <>
      {listing.product.vaseLife}

      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <VaseLifeInput value={vaseLife} onChange={(e) => setVaseLife(e as VaseLife)} />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change vase life"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

export const StemLengthWidget: React.FC<{
  listing: WithId<GrowerCutFlowerListing | GrowerDriedFlowerListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [stemLength, setStemLength] = useState(listing.product.stemLength);

  const submit = useCallback(async () => {
    setSubmitting(true);

    await db.collection("listings").doc(listing._id).update({
      "product.stemLength": stemLength,
    });

    setSubmitting(false);
    setOpen(false);
  }, [listing._id, stemLength]);

  return (
    <>
      {listing.product.stemLength}

      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <StemLengthInput
                  value={stemLength}
                  onChange={(e) => setStemLength(e as StemLength)}
                />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change stem length"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

export const StemCountWidget: React.FC<{
  listing: WithId<GrowerBouquetListing | GrowerDriedFlowerListing>;
  editable?: boolean;
}> = ({ listing, editable = false }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [stemCount, setStemCount] = useState(listing.product.stemCount);

  const submit = useCallback(async () => {
    setSubmitting(true);

    await db.collection("listings").doc(listing._id).update({
      "product.stemCount": stemCount,
    });

    setSubmitting(false);
    setOpen(false);
  }, [listing._id, stemCount]);

  return (
    <>
      {listing.product.stemCount ?? "Unknown"}

      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <StemCountInput value={stemCount} onChange={(e) => setStemCount(e as number)} />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change stem count"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};

const createProductTextFieldWidget = (
  field: keyof GrowerListing["product"],
  options: {
    style?: React.CSSProperties;
    required?: boolean;
  }
): React.FC<{
  listing: WithId<GrowerListing>;
  editable?: boolean;
}> => ({ listing, editable }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const submit = async (x: string) => {
    if (options.required && (!x || !x.trim())) return; // TODO: match validation with the validation on "Add" listenings
    setSubmitting(true);

    await db
      .collection("listings")
      .doc(listing._id)
      .set(
        {
          product: {
            [field]: x,
          },
        },
        { merge: true }
      );

    setSubmitting(false);
    setOpen(false);
  };

  return (
    <>
      <Typography.Paragraph
        style={options.style}
        editable={editable ? { onChange: submit } : undefined}
      >
        {listing.product[field]}
      </Typography.Paragraph>
    </>
  );
};

export const CustomFulfillmentInfoWidget: React.FC<{
  listing: WithId<GrowerListing> & { availability: CustomListingAvailability };
  editable?: boolean;
}> = ({ listing, editable }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const submit = async (x: string) => {
    setSubmitting(true);

    await db
      .collection("listings")
      .doc(listing._id)
      .set(
        {
          availability: {
            description: x,
          },
        },
        { merge: true }
      );

    setSubmitting(false);
    setOpen(false);
  };

  return (
    <>
      <Typography.Paragraph editable={editable ? { onChange: submit } : undefined}>
        {listing.availability.description}
      </Typography.Paragraph>
    </>
  );
};

export const DescriptionWidget = createProductTextFieldWidget("description", {});
export const NameWidget = createProductTextFieldWidget("name", {
  style: { fontWeight: "bold", marginBottom: 0 },
  required: true,
});

export const ImageWidget: React.FC<{
  listing: WithId<GrowerListing>;
  editable?: boolean;
  role: FullGrowerRole;
}> = ({ listing, editable = false, role }) => {
  const [submitting, setSubmitting] = useState(false);

  const submit = async (x: string) => {
    setSubmitting(true);

    await db
      .collection("listings")
      .doc(listing._id)
      .set(
        {
          product: {
            photoStorageUrl: x,
          },
        },
        { merge: true }
      );

    setSubmitting(false);
  };

  return (
    <>
      {editable ? (
        <MediaLibrarySelect
          imageSize={300}
          value={listing.product.photoStorageUrl}
          onChange={submit}
          placeholder="Change picture..."
        />
      ) : (
        <Image src={listing.product.photoStorageUrl} preview={true} />
      )}
    </>
  );
};
