import { firestoreToMoment, GrowerCutFlowerListing, GrowerListing, WithId } from "@rooted/shared";
import { Card, DatePicker } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../../components/FormControls/CurrencyInput";
import { ColorDisplay } from "../../../components/Misc/ColorDisplay";
import { GrowerPriceWidget, GrowerQuantityWidget } from "./utils/widgets";

const GenericListingCard: React.FC<{
  listing: WithId<GrowerListing>;
}> = ({ listing }) => (
  <Link to={listing._id} className="card-listing">
    <Card
      hoverable
      cover={
        <img
          style={{ objectFit: "cover", height: 200 }}
          src={listing.product.photoStorageUrl || "/logos/flowers-light.png"}
          alt={"Image of " + listing.product.name}
        />
      }
    >
      <Card.Meta title={listing.product.name} />
      <p className="listing-description">{listing.product.description}</p>

      <GrowerQuantityWidget listing={listing} editable />
      <GrowerPriceWidget listing={listing} editable />
    </Card>
  </Link>
);

const GrowerCutFlowerListingCard: React.FC<{
  listing: WithId<GrowerCutFlowerListing>;
}> = ({ listing }) => (
  <Link to={listing._id} className="card-listing">
    <Card
      hoverable
      className=""
      cover={
        <img
          style={{ objectFit: "cover", height: 200 }}
          src={listing.product.photoStorageUrl || "/logos/flowers-light.png"}
          alt={"Image of Flower Genus for " + listing.product._prototypeCache.genus}
        />
      }
    >
      <Card.Meta
        title={listing.product._prototypeCache.genus}
        // description={d_prototypeCache.commonNames.join(", ")}
      />
      <p className="listing-description">
        {listing.product._prototypeCache.commonNames.join(", ")}
      </p>

      <p>
        <ColorDisplay color={listing.product.dominantColor} />
      </p>

      <p>
        <b>Stock:</b> {listing.availability.quantity} bunches /{" "}
        {listing.availability.quantity * listing.product.stemsPerBunch} stems
      </p>
      <p>
        <b>Price:</b> {formatCurrency(listing.pricing.pricePerStem)}/stem
      </p>

      <DatePicker.RangePicker
        size="small"
        format={"MM/DD"}
        style={{ width: "100%" }}
        disabled={[true, true]}
        defaultValue={[
          listing.availability.startDate ? firestoreToMoment(listing.availability.startDate) : null,
          listing.availability.endDate ? firestoreToMoment(listing.availability.endDate) : null,
        ]}
      />
    </Card>
  </Link>
);

export const ListingCard: React.FC<{ listing: WithId<GrowerListing> }> = ({ listing }) => {
  if (listing.category === "cut-flower") return <GrowerCutFlowerListingCard listing={listing} />;
  return <GenericListingCard listing={listing} />;
};
