import {
  calculateMarkedUpPricing,
  ChildOrder,
  CoopRole,
  createRelisting,
  DayOfWeek,
  FullCoopRole,
  GrowerAdHocListing,
  GrowerProfile,
  Relisting,
  WithId,
} from "@rooted/shared";
import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Space,
  Tabs,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { SelectProps } from "antd/lib/select";
import React, { useCallback, useEffect, useState } from "react";
import { logError } from "../../sentry";
import { getCoopMembers } from "../../services/coop-memberships";
import { db } from "../../services/firebase";
import { addOrderItem } from "../../services/sellers/orders";
import { useBreakpoint } from "../../utils/detectWindowSize";
import { sortByLocaleCompare } from "../../utils/sortByLocaleCompare";
import { CurrencyInput } from "../FormControls/CurrencyInput";
import { CoopListingSelect } from "../FormControls/ListingSelect";
import { SearchableSelect, SelectOption } from "../FormControls/SearchableSelect";
import { EnterpriseAvatar } from "../Images/ThumbnailAvatar";

/**
 * Modal view for ad-hoc editing of orders
 */
export const CoopAddOrderItemModal: React.FC<{
  order: WithId<ChildOrder>;
  onFinish: () => void;
  visible: boolean;
  role: FullCoopRole;
}> = ({ onFinish, visible, order, role }) => {
  const [memberId, setMemberId] = useState<string | undefined>(undefined);

  const { md } = useBreakpoint();

  return (
    <Modal
      onCancel={onFinish}
      visible={visible}
      footer={[]}
      title="Add Item"
      width={md ? 700 : undefined}
    >
      <Form.Item label="Grower">
        <CoopMemberSelect value={memberId} onChange={setMemberId} role={role} />
      </Form.Item>
      {memberId && (
        <Tabs>
          <Tabs.TabPane tab="New Listing" key="new" disabled={!memberId}>
            <ItemFromNewListingForm
              order={order}
              onFinish={onFinish}
              role={role}
              growerId={memberId}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Existing Listing" key="existing" disabled={!memberId}>
            <ItemFromExistingListingForm
              order={order}
              onFinish={onFinish}
              role={role}
              growerId={memberId}
            />
          </Tabs.TabPane>
        </Tabs>
      )}
    </Modal>
  );
};

/**
 * Dropdown to select a member of a user's coop
 * `value` / `onChange` work with the id of the member's profile
 */
export const CoopMemberSelect: React.FC<SelectProps<string> & { role: CoopRole }> = React.memo(
  ({ role, value, onChange }) => {
    const [memberOptions, setMemberOptions] = useState<SelectOption[]>([]);
    const [loading, setLoading] = useState(true);
    const loadMembers = useCallback(async () => {
      try {
        const members = await getCoopMembers(role.profileId);
        const memberOptions: SelectOption[] = members.map(
          ({ _id, displayName, photoStorageUrl }) => ({
            label: (
              <Row style={{ alignItems: "center" }}>
                <EnterpriseAvatar
                  size="small"
                  profile={{
                    bio: {
                      displayName,
                      photoStorageUrl,
                    },
                  }}
                  style={{ marginRight: 8 }}
                />
                {displayName}
              </Row>
            ),
            name: displayName,
            value: _id,
          })
        );
        memberOptions.sort(sortByLocaleCompare("name"));
        setMemberOptions(memberOptions);
      } catch (error) {
        notification.error({ message: "Oops! Something went wrong loading your growers." });
        logError({
          error,
          tags: { page: "coop-pos" },
        });
      }
      setLoading(false);
    }, [role.profileId]);

    useEffect(() => {
      loadMembers();
    }, [loadMembers]);

    return <SearchableSelect options={memberOptions} loading={loading} onChange={onChange} />;
  }
);

/**
 * Form for coops to add an item to an order, taken from the existing inventory
 * of the member with id `growerId`
 */
const ItemFromExistingListingForm: React.FC<{
  order: WithId<ChildOrder>;
  onFinish: () => void;
  role: FullCoopRole;
  growerId: string;
}> = ({ order, onFinish, role, growerId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = useForm();

  const addItem = async (values: { listingId: string; quantity: number }) => {
    if (!(order.status === "active" || order.status === "guest-checkout")) {
      throw new Error("Order is not active or guest checkout");
    }

    setLoading(true);

    try {
      const itemData = (
        await db.collection("relistings").doc(values["listingId"]).get()
      ).data() as Relisting;

      if (!itemData) throw new Error("Invalid listing id");

      const retailMarkup =
        itemData.seller._cache.categorySettings[itemData.category]?.retailMarkup ?? 0;

      // @ts-expect-error typescript can't infer the correct type of pricing here
      const item: WithId<Relisting> = {
        ...itemData,
        pricing: calculateMarkedUpPricing(itemData.pricing, order.isRetail ? retailMarkup : 0),
        _id: values["listingId"],
      };

      await addOrderItem(order, item, values["quantity"]);
      form.resetFields();
      onFinish?.();
    } catch (e) {
      notification.error({
        message: "Failed to add item to order",
        description: e.message,
      });
    }
    setLoading(false);
  };

  // Wipe order selection when grower ID changes
  useEffect(() => form.resetFields(["listingId", "quantity"]), [form, growerId]);

  return (
    <Form requiredMark="optional" layout="vertical" onFinish={addItem} form={form}>
      <Form.Item label="Listing" name="listingId" required>
        <CoopListingSelect role={role} growerId={growerId} />
      </Form.Item>
      <Form.Item label="Quantity" name="quantity" rules={[{ required: true, message: " " }]}>
        <InputNumber min={0} style={{ width: 200 }} />
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Add Item
        </Button>
      </Form.Item>
    </Form>
  );
};

/**
 * Form for coops to add a new item to an order, attributed to
 * the member with id `growerId`.
 */
const ItemFromNewListingForm: React.FC<{
  order: WithId<ChildOrder>;
  onFinish: () => void;
  role: FullCoopRole;
  growerId: string;
}> = ({ order, onFinish, role, growerId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [lastError, setLastError] = useState<string>("");
  const [form] = Form.useForm();

  const addItem = async (values: any) => {
    if (!(order.status === "active" || order.status === "guest-checkout")) {
      throw new Error("Order is not active or guest checkout");
    }

    setLastError("");
    setLoading(true);

    try {
      const growerCache = (
        await db.collection("profiles").doc(growerId).get()
      ).data() as GrowerProfile;

      const backingListing: WithId<GrowerAdHocListing> = {
        category: "ad-hoc",
        product: {
          category: "ad-hoc",
          name: values["name"],
          photoStorageUrl: "",
          description: "",
          unit: "item",
        },
        // Original listing seller (the grower we're attributing the item to)
        seller: {
          profileId: growerId,
          type: "grower",
          _cache: growerCache,
        },
        pricing: {
          hasMarkupBaked: true,
          price: values["price"],
        },
        //@ts-expect-error The backing listing does not have a fulfillment in this case
        fulfillment: {},
        _id: Math.random().toString(36).substring(2, 15),
      };

      // Relisting seller (ourselves)
      const seller = {
        type: "coop" as const,
        profileId: role.profileId,
        _cache: role.profile,
      };

      // This doesn't matter much as we never check it again in orders
      // but we need a complete relisting to satisfy the type
      const metadata = {
        isActive: true,
        membershipDays: ["MO", "TU", "WE", "TH", "FR", "SA", "SU"] as DayOfWeek[],
      };

      const relisting = {
        ...createRelisting(backingListing, { seller }, metadata),
        _id: backingListing._id,
      };

      await addOrderItem(order, relisting, values["quantity"]);
      form.resetFields();
      onFinish?.();
    } catch (e) {
      setLastError(e.message);
    }
    setLoading(false);
  };

  return (
    <Form form={form} requiredMark="optional" layout="vertical" onFinish={addItem}>
      {lastError && (
        <Form.Item>
          <Alert message={lastError} type="error" />
        </Form.Item>
      )}
      <Form.Item
        style={{ marginBottom: 16 }}
        label="Name"
        name="name"
        rules={[{ required: true, message: " " }]}
      >
        <Input />
      </Form.Item>
      <Space>
        <Form.Item
          label="Price"
          style={{ marginBottom: 0 }}
          name="price"
          rules={[{ required: true, message: " " }]}
        >
          <CurrencyInput style={{ width: 200 }} />
        </Form.Item>
        <Form.Item
          label="Quantity"
          style={{ marginBottom: 0 }}
          name="quantity"
          rules={[{ required: true, message: " " }]}
        >
          <InputNumber min={0} style={{ width: 200 }} />
        </Form.Item>
      </Space>

      <Form.Item style={{ marginBottom: 0, marginTop: 16 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Add Item
        </Button>
      </Form.Item>
    </Form>
  );
};
