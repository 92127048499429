import { Loader } from "@googlemaps/js-api-loader";

/** Load Google Maps Geocoder */
export const loader: Loader = new Loader({
  apiKey: `${process.env.REACT_APP_API_KEY}`,
  version: "weekly",
});
loader.load(); // load at the outset

/**
 * Geocode Adress
 * @param  {string}                      address string address
 * @return {Promise<google.maps.LatLng>}         Promise that resolves with [lat, lon]
 */
export const geocodeAddress = async (address: string): Promise<number[]> => {
  // load first
  const geocoder: google.maps.Geocoder = new google.maps.Geocoder();

  return await new Promise((res, rej) => {
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK") {
        const latlng = results[0].geometry.location;
        res([latlng.lat(), latlng.lng()]);
      } else {
        rej(status);
      }
    });
  });
};
