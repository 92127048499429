import {
  assertIsSeller,
  CreateAnonymousPOSOrderRequest,
  CreatePOSOrderRequest,
  CreatePOSOrderResult,
} from "@rooted/shared";
import { Button, Divider, Form, Input, notification, Radio, Row } from "antd";
import React, { useState } from "react";
import { CustomerSelect } from "../../../components/FormControls/CustomerSelect";
import { useRooted } from "../../../RootedContext";
import { logError } from "../../../sentry";
import { functions } from "../../../services/firebase";

export const ExistingPointOfSaleForm: React.FC<{
  onSuccess: (orderId: string) => void;
  onCancel?: () => void;
}> = ({ onSuccess, onCancel }) => {
  const { activeRole } = useRooted();
  assertIsSeller(activeRole);
  const [loading, setLoading] = useState(false);
  const [lastServerError, setLastServerError] = useState<string | undefined>();

  type FormFields = { creditCardLast4: string; buyerId: string };

  const submitForm = async ({ creditCardLast4, buyerId }: FormFields) => {
    setLoading(true);
    setLastServerError(undefined);

    try {
      const req: CreatePOSOrderRequest = {
        creditCardLast4: creditCardLast4,
        buyerId: buyerId,
        sellerId: activeRole.profileId,
      };
      const { orderId } = (await functions.httpsCallable("createPOSOrder")(req))
        .data as CreatePOSOrderResult;

      onSuccess(orderId);
    } catch (e) {
      if (e.details?.reason === "no-cc-match") {
        setLastServerError("Couldn't find a matching credit card.");
        return;
      }
      notification.error({
        message: "Oops! Something went wrong creating a POS order.",
        description: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onFinish={submitForm} layout={"vertical"}>
      <Form.Item
        label="Customer"
        name="buyerId"
        rules={[
          {
            required: true,
            message: "Please select a customer",
          },
        ]}
      >
        <CustomerSelect />
      </Form.Item>
      <Form.Item
        label="Last 4 of credit card"
        name="creditCardLast4"
        rules={[
          {
            required: true,
            pattern: /^[0-9]{4}$/,
            message: "Please enter four digits.",
          },
        ]}
        validateStatus={lastServerError ? "error" : undefined}
        help={lastServerError}
      >
        <Input maxLength={4} />
      </Form.Item>
      <Divider />
      <Form.Item style={{ marginBottom: 0 }}>
        <Row justify="end">
          {onCancel && (
            <Button disabled={loading} type="ghost" onClick={onCancel} style={{ marginRight: 8 }}>
              Cancel
            </Button>
          )}
          <Button htmlType="submit" loading={loading} type="primary">
            Create Order
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

export const AnonymousPointOfSale: React.FC<{
  onSuccess: (orderId: string) => void;
  onCancel?: () => void;
}> = ({ onSuccess, onCancel }) => {
  const { activeRole } = useRooted();
  assertIsSeller(activeRole);
  const [loading, setLoading] = useState(false);

  type FormFields = { name: string; email: string; isRetail: boolean };

  const submitForm = async ({ email: buyerEmail, name: buyerName, isRetail }: FormFields) => {
    setLoading(true);

    try {
      const req: CreateAnonymousPOSOrderRequest = {
        buyerEmail,
        buyerName,
        sellerId: activeRole.profileId,
        isRetail,
      };
      const { orderId } = (await functions.httpsCallable("createAnonymousPosOrder")(req))
        .data as CreatePOSOrderResult;

      onSuccess(orderId);
    } catch (error) {
      logError({
        error,
        extraData: { buyerEmail, buyerName },
        tags: { page: "Create Anonymous POS" },
      });
      notification.error({
        message: "Oops! Something went wrong creating a POS order.",
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onFinish={submitForm} layout={"vertical"}>
      <Form.Item
        name="isRetail"
        label="Customer Type"
        rules={[
          {
            required: true,
            message: "Please select a customer type. This will affect their pricing markup.",
          },
        ]}
      >
        <Radio.Group buttonStyle="solid">
          <Radio.Button value={false}>Wholesale Buyer</Radio.Button>
          <Radio.Button value={true}>Retail Buyer</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
          },
        ]}
        label="Customer Name"
        name="name"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Customer Email"
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: "Please enter a valid email address",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Divider />
      <Form.Item style={{ marginBottom: 0 }}>
        <Row justify="end">
          {onCancel && (
            <Button disabled={loading} type="ghost" onClick={onCancel} style={{ marginRight: 8 }}>
              Cancel
            </Button>
          )}
          <Button htmlType="submit" loading={loading} type="primary">
            Create Order
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};
