import { FullBuyerRole } from "@rooted/shared";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotFoundPage } from "../../layouts";

import { ShopOverview } from "./ShopOverview";
import { ShopCategory } from "./ShopCategory";

export const ShopRoutes: React.FC<{ role: FullBuyerRole }> = ({ role }) => {
  return (
    <Routes>
      <Route path="" element={<ShopOverview role={role} />} />
      <Route path="cut-flowers/*" element={<ShopCategory category="cut-flower" />} />
      <Route
        path="bouquets/*"
        element={<ShopCategory category="bouquet" preferDirectToFarmIfRetail />}
      />
      <Route
        path="buckets/*"
        element={<ShopCategory category="bucket" preferDirectToFarmIfRetail />}
      />
      <Route
        path="csas/*"
        element={<ShopCategory category="csa" disableDelivery preferDirectToFarmIfRetail />}
      />
      <Route
        path="dried-flowers/*"
        element={<ShopCategory category="dried-flower" preferDirectToFarmIfRetail />}
      />
      <Route
        path="wreaths/*"
        element={<ShopCategory category="wreath" preferDirectToFarmIfRetail />}
      />
      <Route
        path="potted-plants/*"
        element={<ShopCategory category="potted-plant" preferDirectToFarmIfRetail />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
