import { RequestedCoopMembership, SellerProfile, WithId } from "@rooted/shared";
import { db, useCollectionDataChecked, useDocumentDataChecked } from "../../../services/firebase";

export const useWholesaleBuyerSettingsNotificationsCount = (profileId: string) => {
  return useFulfillmentSettingsNotificationsCount(profileId);
};

export const useFulfillmentSettingsNotificationsCount = (profileId: string) => {
  const [profile] = useDocumentDataChecked<SellerProfile>(
    db.collection("profiles").doc(profileId),
    {
      idField: "_id",
    }
  );

  if (!profile) return 0;
  return 0;
};

export const useCoopMembershipSettingsNotificationsCount = (profileId: string) => {
  const [membershipRequests] = useCollectionDataChecked<WithId<RequestedCoopMembership>>(
    db
      .collection("coopMemberships")
      .where("status", "==", "coop-requested")
      .where("growerId", "==", profileId),
    { idField: "_id" }
  );

  return membershipRequests?.length || 0;
};

export const useGrowerSettingsNotificationsCount = (profileId: string) => {
  return useCoopMembershipSettingsNotificationsCount(profileId);
};

export const useCoopSettingsNotificationsCount = (profileId: string) => {
  return 0;
};
