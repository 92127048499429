import { Card, Skeleton } from "antd";
import React from "react";

export const CardSkeleton: React.FC<{ breadcrumb?: boolean }> = ({ breadcrumb }) => (
  <>
    {breadcrumb && <Skeleton active title={{ width: 100 }} paragraph={false} />}
    <Card style={{ marginTop: breadcrumb ? 12 : 0 }}>
      <Skeleton active paragraph={true} />
    </Card>
  </>
);
