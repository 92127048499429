import { CheckOutlined } from "@ant-design/icons";
import {
  AdminRole,
  FullCoopRole,
  FullGrowerRole,
  FullRetailBuyerRole,
  FullUserRole,
  FullWholesaleBuyerRole,
  Profile,
  Role,
  WithId,
} from "@rooted/shared";
import { Alert, Button, Input, List, Modal, Space, Typography, notification, Card } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { CopyCode } from "../../../components/Misc/CopyCode";
import { PageHeader } from "../../../components/PageHeader";
import { useDebouncedTrigger } from "../../../hooks/util/useDebouncedTrigger";
import { useRooted } from "../../../RootedContext";
import { assertIsSignedIn, SignedInComplete } from "../../../services/account-state";
import { db, useCollectionDataChecked } from "../../../services/firebase";
import { makePrefixQuery } from "../../../services/higher-order-searches";
import { createRole } from "../../../services/roles";
import toIdDoc from "../../../utils/toIdDoc";

const MAX_ROLES = 16;
function warnTooManyRoles() {
  notification.warn({
    message: `You currently have ${MAX_ROLES} roles. Please remove a role before adding more.`,
  });
}

export const RolesPane: React.FC = () => {
  const { account } = useRooted();
  assertIsSignedIn(account);

  const coopRoles = account.roles.filter((r) => r.type === "coop") as WithId<FullCoopRole>[];
  const growerRoles = account.roles.filter((r) => r.type === "grower") as WithId<FullGrowerRole>[];
  const retailRoles = account.roles.filter(
    (r) => r.type === "retail-buyer"
  ) as WithId<FullRetailBuyerRole>[];
  const wholesaleRoles = account.roles.filter(
    (r) => r.type === "wholesale-buyer"
  ) as WithId<FullWholesaleBuyerRole>[];

  const [modalVisible, setModalVisible] = useState(false);
  const showAddEnterpriseRolesModal = () => {
    if (account.roles.length >= MAX_ROLES) {
      warnTooManyRoles();
      return;
    }
    setModalVisible(true);
  };

  const removeRole = (r: WithId<FullUserRole>) => {
    Modal.confirm({
      icon: null,
      title: `Remove Role`,
      content: (
        <Typography.Text>
          Are you sure you want to remove <b>{r.profile.bio.displayName}</b> role from your user?
        </Typography.Text>
      ),
      onOk: async () => {
        try {
          await db.collection("roles").doc(r._id).delete();
          globalThis.location.reload();
        } catch (error) {
          notification.error({
            message: "Oops! Something went wrong removing that role: " + error.message,
          });
          console.error(error);
        }
      },
    });
  };

  return (
    <>
      <PageHeader
        title="Roles"
        extra={
          <Button type="primary" onClick={showAddEnterpriseRolesModal}>
            Add Enterprise Roles
          </Button>
        }
      />

      <Space direction="vertical" style={{ width: "100%" }}>
        <Alert
          style={{ marginBottom: 12 }}
          showIcon
          type="warning"
          message={`There is an account-wide max of ${MAX_ROLES} roles.`}
        />
        <RoleGroupList roles={growerRoles} title="Growers" removeRole={removeRole} />
        <RoleGroupList roles={coopRoles} title="Coops" removeRole={removeRole} />
        <RoleGroupList roles={retailRoles} title="Retail Buyers" removeRole={removeRole} />
        <RoleGroupList roles={wholesaleRoles} title="Wholesale Buyers" removeRole={removeRole} />
      </Space>
      <Modal title={"Add Enterprise Roles"} visible={modalVisible}>
        <AddRoles onAddRole={() => setModalVisible(false)} account={account} />
      </Modal>
    </>
  );
};

const RoleGroupList: React.FC<{
  title: string;
  roles: FullUserRole[];
  removeRole: (role: FullUserRole) => void;
}> = ({ roles, removeRole, title }) => (
  <Card
    bodyStyle={{ padding: 0 }}
    title={
      <Typography.Title style={{ marginBottom: 0 }} level={5}>
        {title}
      </Typography.Title>
    }
  >
    <List
      // Hack because we can't directly edit the style via prop
      className="gray-list-header"
      dataSource={roles}
      rowKey={(role) => role._id}
      renderItem={(fullRole) => (
        <List.Item style={{ paddingLeft: 24, paddingRight: 24 }}>
          <List.Item.Meta
            title={fullRole?.profile?.bio?.displayName}
            description={<CopyCode>{fullRole?.profile._id}</CopyCode>}
          />
          <Button danger type="ghost" onClick={() => removeRole(fullRole)}>
            Remove
          </Button>
        </List.Item>
      )}
    />
  </Card>
);

export const AddRoles: React.FC<{
  account: SignedInComplete;
  retail?: boolean;
  onAddRole?: () => void;
}> = ({ account, retail, onAddRole }) => {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebouncedTrigger(search);

  const [profiles, setProfiles] = useState<WithId<Profile>[]>([]);
  const [profilesLoading, setProfilesLoading] = useState(true);

  const [userRoles, userRolesLoading, userRolesError] = useCollectionDataChecked<WithId<Role>>(
    db.collection("roles").where("userId", "==", account.user._id),
    { idField: "_id" }
  );

  const fetchProfiles = useCallback(async () => {
    setProfilesLoading(true);
    const baseQuery = debouncedSearch
      ? makePrefixQuery(db.collection("profiles"), "bio._displayNameLowerCase", debouncedSearch)
      : db.collection("profiles");
    try {
      const query = retail
        ? baseQuery.where("type", "==", "retail-buyer")
        : baseQuery.where("type", "in", ["wholesale-buyer", "grower", "coop"]); // TODO: Use != for this query
      const newProfiles = (await query.limit(5).get()).docs.map((doc) => toIdDoc<Profile>(doc));
      setProfiles(newProfiles);
    } catch (error) {
      console.error(error);
    }

    setProfilesLoading(false);
  }, [debouncedSearch, retail]);

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  const hasRole = (id: string) => {
    return (
      userRoles && userRoles.filter((r) => r.type !== "admin" && id === r.profileId).length > 0
    );
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Input
        placeholder="Search by Name"
        onChange={(e) => setSearch(e.target.value.toLocaleLowerCase())}
      />
      <List
        loading={profilesLoading || userRolesLoading}
        bordered
        dataSource={profiles}
        renderItem={(p) => (
          <List.Item key={p._id}>
            <Typography.Text>{p.bio.displayName}</Typography.Text>
            {hasRole(p._id) ? (
              <CheckOutlined />
            ) : (
              <Button
                type="primary"
                onClick={async () => {
                  if (account.roles.length >= MAX_ROLES) {
                    warnTooManyRoles();
                    return;
                  }
                  await createRole(account.user, p);
                  onAddRole?.();
                }}
              >
                {retail ? "Change Role" : "Add Role"}
              </Button>
            )}
          </List.Item>
        )}
      />
    </Space>
  );
};
