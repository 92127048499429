import { WithId, SubscriptionPlan } from "@rooted/shared";

import { Button, List, Spin, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { PageHeader } from "../../../../components/PageHeader";
import { db, useCollectionDataChecked } from "../../../../services/firebase";

export const ListPlans: React.FC = () => {
  const [plans, plansLoading] = useCollectionDataChecked<WithId<SubscriptionPlan>>(
    db.collection("subscriptionPlans"),
    { idField: "_id" }
  );

  return (
    <>
      <PageHeader
        title={"Subscription Plans"}
        breadcrumb
        extra={
          <Link to="create">
            <Button type="primary">Create</Button>
          </Link>
        }
      />
      <List
        bordered
        dataSource={plans}
        renderItem={(item) => (
          <List.Item
            key={item._id}
            actions={[
              <Link key="link-to-edit-item" to={item._id}>
                <Button type="ghost">Edit</Button>
              </Link>,
            ]}
          >
            {item.name} &nbsp;&nbsp;<Tag>{item.type}</Tag>
          </List.Item>
        )}
      >
        {plansLoading && <Spin />}
      </List>
    </>
  );
};
