import { ArrowRightOutlined } from "@ant-design/icons";
import { assertCasesExhausted, BaseApplicationForm, WithId } from "@rooted/shared";
import { Button, List, notification, Typography } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { ReactNode, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PageHeader } from "../../components/PageHeader";
import { setSessionStorageActiveRole } from "../../hooks/account/useSyncActiveRole";
import { useIsMounted } from "../../hooks/util/useIsMounted";
import { useQuery } from "../../hooks/util/useQuery";
import { useRooted } from "../../RootedContext";
import { logError } from "../../sentry";
import { SignedInComplete } from "../../services/account-state";
import {
  newCoopApplication,
  newGrowerApplication,
  newWholesaleBuyerApplication,
  requestRetailBuyerProfile,
} from "../../services/applications";
import { db, useCollectionDataChecked } from "../../services/firebase";
import { LoadingPage, PageContent, WideLayout } from "../layouts";

const safelyCreateApplication = async (
  navigate: (route: string) => void,
  account: SignedInComplete,
  newApplication: (account: SignedInComplete) => Promise<{ id: string }>
) => {
  try {
    const id = await newApplication(account);
    navigate(`/onboarding/applications/${id}`);
  } catch (error) {
    notification.error({
      message: "Oops! Something went wrong creating your application.",
    });
    logError({
      error,
      tags: { page: "onboarding" },
      extraData: {
        account,
      },
    });
  }
};

type ApplicationType = "retail" | "wholesale" | "grower" | "coop";

const icons: { [Type in ApplicationType]: string } = {
  retail: "/images/onboarding/retail.png",
  coop: "/images/onboarding/coop.png",
  grower: "/images/onboarding/grower.png",
  wholesale: "/images/onboarding/wholesale.png",
};

const onboardingLines: { text: string | ReactNode; type: ApplicationType }[] = [
  {
    text: "I want to buy flowers for myself or a friend",
    type: "retail",
  },
  {
    text: "I want to buy CSAs from my local farmer",
    type: "retail",
  },
  {
    text: (
      <span>
        I am a <b>farmer</b> and I want to sell my flowers
      </span>
    ),
    type: "grower",
  },
  {
    text: (
      <span>
        I am a <b>florist</b> or <b>studio designer</b> looking to buy flowers
      </span>
    ),
    type: "wholesale",
  },
  {
    text: (
      <span>
        I am the <b>floral buyer</b> for a grocery store or flower market
      </span>
    ),
    type: "wholesale",
  },
  {
    text: (
      <span>
        I am a <b>farmer-florist</b> and want to supplement my supply from other local growers
      </span>
    ),
    type: "wholesale",
  },
  {
    text: (
      <span>
        I am the administrator of a <b>collective</b> or <b>coop</b>
      </span>
    ),
    type: "coop",
  },
];

export const OnboardingHome: React.FC<{ account: SignedInComplete }> = ({ account }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setActiveRoleId } = useRooted();

  const [applications = [], applicationsLoading] = useCollectionDataChecked<
    WithId<BaseApplicationForm>
  >(db.collection("applications").where("ownerId", "==", account.user._id), { idField: "_id" });

  const growerApp = useMemo(() => {
    return applications.find((app) => app.type === "grower");
  }, [applications]);

  const wholesaleApp = useMemo(() => {
    return applications.find((app) => app.type === "wholesale-buyer");
  }, [applications]);

  const coopApp = useMemo(() => {
    return applications.find((app) => app.type === "coop");
  }, [applications]);

  const selectTextByType: { [Type in ApplicationType]: string } = useMemo(() => {
    const hasCoopProfile = account.roles.some((role) => role.type === "coop");
    const hasGrowerProfile = account.roles.some((role) => role.type === "grower");
    const hasWholesaleProfile = account.roles.some((role) => role.type === "wholesale-buyer");

    return {
      retail: "Select",
      coop: coopApp ? "Resume" : hasCoopProfile ? "Reapply" : "Select",
      grower: growerApp ? "Resume" : hasGrowerProfile ? "Reapply" : "Select",
      wholesale: wholesaleApp ? "Resume" : hasWholesaleProfile ? "Reapply" : "Select",
    };
  }, [growerApp, coopApp, wholesaleApp, account.roles]);

  const isMounted = useIsMounted();

  const safelySetLoading = (value: boolean) => {
    if (isMounted) {
      setLoading(value);
    }
  };

  const redirectToGrowerApplication = async () => {
    if (loading) return;
    if (growerApp) {
      navigate(`/onboarding/applications/${growerApp._id}`);
      return;
    }

    setLoading(true);
    await safelyCreateApplication(navigate, account, newGrowerApplication);
    safelySetLoading(false);
  };

  const redirectToWholesaleBuyerApplication = async () => {
    if (loading) return;
    if (wholesaleApp) {
      navigate(`/onboarding/applications/${wholesaleApp._id}`);
      return;
    }

    setLoading(true);
    await safelyCreateApplication(navigate, account, newWholesaleBuyerApplication);
    safelySetLoading(false);
  };

  const redirectToCoopApplication = async () => {
    if (loading) return;
    if (coopApp) {
      navigate(`/onboarding/applications/${coopApp._id}`);
      return;
    }

    setLoading(true);

    await safelyCreateApplication(navigate, account, newCoopApplication);
    safelySetLoading(false);
  };

  // Redirects and notifies differently than enterprise because no application needed.
  const redirectToRetailBuyer = async () => {
    if (loading) return;
    setLoading(true);

    const retailRole = account.roles.find((role) => role.type === "retail-buyer");
    if (retailRole) {
      setActiveRoleId(retailRole._id);
      navigate("/shop");
      return;
    }

    try {
      const { data: retailBuyerId } = await requestRetailBuyerProfile(account);

      // Set the active role in session storage so, when the page is reloaded, the retail buyer
      // role is picked up.
      setSessionStorageActiveRole({ userId: account.user._id, activeRoleId: retailBuyerId });

      // Refresh the page to "/shop". Unfortunately, we don't have a great way currently to "listen"
      // for the role to be added & the profile + private profile to be loaded, so once the
      // "requestRetailBuyerProfile" function returns our best bet is to reload -- we know that
      // the profile has been created so it will be available on reload
      // eslint-disable-next-line no-restricted-globals
      location.assign("/shop");

      // Do NOT set loading to false here -- we are reloading the page, so the component will unmount
    } catch (error) {
      notification.error({
        message: "Oops! Something went wrong creating your retail buyer account",
      });

      logError({
        error,
        tags: { page: "onboarding" },
        extraData: {
          account,
        },
      });

      safelySetLoading(false);
    }
  };

  const query = useQuery();
  const [intent] = useState(() => query.get("application"));
  const hasRetailRole = account.roles.find((x) => x.type === "retail-buyer");

  if (loading || applicationsLoading) return <LoadingPage />;

  if (intent === "coop")
    return (
      <WideLayout>
        <PageContent>
          <PageHeader title="New Coop Application" />
          <Button style={{ height: "75px" }} onClick={redirectToCoopApplication} block>
            Start a new Coop Application <ArrowRightOutlined />
          </Button>
        </PageContent>
      </WideLayout>
    );

  if (intent === "grower")
    return (
      <WideLayout>
        <PageContent>
          <PageHeader title="New Grower Application" />
          <Button style={{ height: "75px" }} onClick={redirectToGrowerApplication} block>
            Start a new Grower Application <ArrowRightOutlined />
          </Button>
        </PageContent>
      </WideLayout>
    );

  if (intent === "wholesale-buyer")
    return (
      <WideLayout>
        <PageContent>
          <PageHeader title="New Wholesale Buyer Application" />
          <Button style={{ height: "75px" }} onClick={redirectToWholesaleBuyerApplication} block>
            Start a new Wholesale Buyer Application <ArrowRightOutlined />
          </Button>
        </PageContent>
      </WideLayout>
    );

  // Only one retail account is allowed, so don't show this if
  // the page was navigated to by a use with a retail account already
  if (intent === "retail-buyer" && !hasRetailRole)
    return (
      <WideLayout>
        <PageContent>
          <PageHeader title="New Retail Buyer Application" />
          <Button style={{ height: "75px" }} onClick={redirectToRetailBuyer} block>
            Create a retail Buyer Account <ArrowRightOutlined />
          </Button>
        </PageContent>
      </WideLayout>
    );

  return (
    <>
      <WideLayout>
        <PageContent>
          <PageHeader
            title="Create a profile"
            extra={
              applications &&
              applications.length > 0 && (
                <Link to="/user-settings/applications/">
                  View existing applications <ArrowRightOutlined />
                </Link>
              )
            }
          />

          <div style={{ textAlign: "center" }}>
            <Typography.Title level={4}>
              Welcome! We're so glad you're here! Let's get started.
              <br />
              First, tell us about yourself.
            </Typography.Title>
            <Typography.Paragraph>
              Which of the following best describes you?{" "}
              <i>(You can always come back and add another selection!)</i>
            </Typography.Paragraph>
          </div>

          <List
            style={{ maxWidth: 600, margin: "0 auto" }}
            itemLayout="horizontal"
            dataSource={onboardingLines}
            renderItem={(item) => {
              return (
                <List.Item
                  actions={[
                    <Button
                      key="select"
                      onClick={() => {
                        switch (item.type) {
                          case "retail":
                            return redirectToRetailBuyer();
                          case "coop":
                            return redirectToCoopApplication();
                          case "grower":
                            return redirectToGrowerApplication();
                          case "wholesale":
                            return redirectToWholesaleBuyerApplication();
                          default:
                            assertCasesExhausted(item.type);
                        }
                      }}
                    >
                      {selectTextByType[item.type]}
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={<Avatar shape="square" src={icons[item.type]} />}
                    title={item.text}
                  />
                </List.Item>
              );
            }}
          />
        </PageContent>
      </WideLayout>
    </>
  );
};
