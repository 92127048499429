import { CloseOutlined } from "@ant-design/icons";
import { Alert, AlertProps } from "antd";
import React from "react";
import ReactMarkdown from "react-markdown";

type GlobalBannerProps =
  | {
      message: AlertProps["message"];
      markdown?: never;
      type?: AlertProps["type"];
    }
  | {
      markdown: string;
      message?: never;
      type?: AlertProps["type"];
    };

export const GlobalBanner: React.FC<GlobalBannerProps> = ({ message, markdown, type = "info" }) => {
  return (
    <Alert
      type={type}
      message={
        markdown ? (
          <ReactMarkdown
            components={{
              p: (props) => <p style={{ marginBottom: 0 }} {...props} />,
            }}
            allowedElements={["p", "a", "em", "strong"]}
          >
            {markdown}
          </ReactMarkdown>
        ) : (
          message
        )
      }
      banner
      closable
      closeText={<CloseOutlined style={{ fontSize: 16 }} />}
    />
  );
};
