import { firestoreToMoment } from "@rooted/shared";
import firebase from "firebase/app";

// https://stackoverflow.com/questions/52188399/typescript-constrain-argument-of-function-to-be-a-key-of-an-object-associated-w
export const sortByFirestoreTimestamp = <
  // This allows timestamp to be optional
  // note that `firebase.firestore.Timestamp | undefined` does _not_ work.
  T extends Record<K, firebase.firestore.Timestamp> | Record<K, undefined>,
  K extends keyof any
>(
  key: K
) => ({ [key]: a }: T, { [key]: b }: T) => {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;

  // unclear why these casts are needed, since `!a` should narrow to just firebase.firestore.Timestamp.
  return firestoreToMoment(a as firebase.firestore.Timestamp).isBefore(
    firestoreToMoment(b as firebase.firestore.Timestamp)
  )
    ? 1
    : -1;
};
