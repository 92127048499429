import { CoopApplicationForm, GrowerApplicationForm, WithId } from "@rooted/shared";

import { Alert, Button, Card, Form, Tag } from "antd";
import React from "react";
import { db } from "../../services/firebase";

export const StripeForm: React.FC<{
  application: WithId<GrowerApplicationForm | CoopApplicationForm>;
}> = ({ application }) => {
  if (application.status === "profile-created") return <>Your profile has been created.</>;
  return (
    <>
      {/** Dev only option: */}
      {process.env.REACT_APP_BACKEND !== "rooted-app-v2-prod" && (
        <Form.Item>
          <Alert
            type="warning"
            message={
              <>
                <Tag>DEV MODE</Tag>&nbsp; Stripe onboarding is a pain, and can take a while to get
                back. Click to skip this step. This may affect future payments / payouts for this
                profile once approved as the Stripe account won't actually be onboarded.
                <Button
                  style={{ marginTop: 8 }}
                  onClick={async () => {
                    await db
                      .collection("applications")
                      .doc(application._id)
                      .set(
                        {
                          stripe: {
                            status: "complete",
                          },
                        },
                        { merge: true }
                      );
                  }}
                >
                  Dangerously Approve Stripe
                </Button>
                <Button
                  style={{ marginTop: 8 }}
                  onClick={() =>
                    db
                      .collection("applications")
                      .doc(application._id)
                      .set(
                        {
                          stripe: {
                            status: "under-stripe-review",
                          },
                        },
                        { merge: true }
                      )
                  }
                >
                  Dangerously set stripe onboarding complete
                </Button>
              </>
            }
          />
        </Form.Item>
      )}

      {application.stripe.status === "needs-user-input" && (
        <>
          <Card title="Stripe Onboarding">
            <ul>
              <li>All of our payments are processed through Stripe</li>
              <li>
                Stripe deposits payments from your customers directly into the bank account that you
                linked to your Stripe account
              </li>
              <li>
                <a href="https://stripe.com/pricing" target="_blank" rel="noreferrer">
                  Read more about Stripe charges
                </a>
              </li>
              <li>
                The Rooted Farmers platform fee is automatically deducted from your transactions.
                From your Stripe Dashboard, if you hover over the symbol in the payment detail
                window, you will see the total amount of the transaction fee, as well as a breakdown
                of the Rooted Farmers fee and the Stripe fees.
              </li>
            </ul>
            <br />
            <a href={application.stripe.onboardingUrl}>
              <Button type="primary">Go to Stripe Onboarding</Button>
            </a>
          </Card>
        </>
      )}

      {application.stripe.status === "under-stripe-review" && (
        <>
          Stripe onboarding complete! Stripe can take 1-2 days to approve new members, so check back
          tomorrow to get started!{" "}
        </>
      )}

      {/* This is a lie--we actually haven't updated the status on our end yet. */}
      {application.stripe.status === "complete" && (
        <>
          Stripe onboarding complete! Stripe can take 1-2 days to approve new members, so check back
          tomorrow to get started!{" "}
        </>
      )}

      {application.stripe.status === "rejected" && (
        <>
          Stripe has rejected your application. Please contact{" "}
          <a href="mailto:help@rootedfarmers.com">help@rootedfarmers.com</a> for more information.
        </>
      )}
    </>
  );
};
