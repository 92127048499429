import {
  Connection,
  distance,
  FullCoopRole,
  FullGrowerRole,
  WholesaleBuyerProfile,
  WithId,
  FullSellerRole,
} from "@rooted/shared";

import { Button, List, Typography, Col } from "antd";
import React from "react";
import { PageHeader } from "../../../components/PageHeader";
import { ProfileLink } from "../../../components/ProfileLink/ProfileLink";
import {
  approveConnection,
  requestConnectionToBuyer,
  useAllSellerConnections,
  useNearbyProfiles,
} from "../../../services/connections";
import { useOldDataOnLoad } from "../../../services/higher-order-searches";
import { CloudFunctionButton } from "../../admins/SiteSettings/ActionsPane";
import { LoadingPage } from "../../layouts";
import "../../layouts/connection-list.less";

export const FindBuyersPane: React.FC<{
  role: FullGrowerRole | FullCoopRole;
}> = ({ role }) => {
  const [connections] = useAllSellerConnections(role);

  const [nearbyBuyers] = useOldDataOnLoad(
    useNearbyProfiles<WithId<WholesaleBuyerProfile>>(role.profile.bio.location, "wholesale-buyer")
  );

  if (!connections || !nearbyBuyers)
    return (
      <>
        <PageHeader title={"Find Nearby Buyers"} />
        <LoadingPage />
      </>
    );

  return (
    <>
      <PageHeader title={"Find Nearby Buyers"} />

      <List
        bordered
        dataSource={nearbyBuyers}
        className="connection-list"
        renderItem={(x) => (
          <List.Item
            key={x._id}
            extra={
              <SellerConnectionButton
                connection={connections.find((c) => c.buyerId === x._id)}
                profile={x}
                role={role}
              />
            }
          >
            <Col>
              <Typography.Paragraph>
                <ProfileLink to={x}>{x.bio.displayName}</ProfileLink>
              </Typography.Paragraph>
              {x.bio.location.streetAddress.city}, {x.bio.location.streetAddress.state}
              &nbsp;&nbsp;({distance(role.profile.bio.location, x.bio.location).toFixed(1)} miles
              away)
            </Col>
          </List.Item>
        )}
      />
    </>
  );
};

export const SellerConnectionButton: React.FC<{
  connection?: WithId<Connection>;
  profile: WithId<WholesaleBuyerProfile>;
  role: FullSellerRole;
}> = ({ connection, profile, role }) => {
  if (!connection)
    return (
      <Button type="primary" onClick={() => requestConnectionToBuyer(role, profile)}>
        Request Connection
      </Button>
    );

  if (connection.status === "approved") return <Typography.Text>Connected</Typography.Text>;

  if (connection.status === "seller-requested") return <Typography.Text>Requested</Typography.Text>;
  else
    return (
      <CloudFunctionButton type="primary" onClick={() => approveConnection(connection._id)}>
        Approve connection
      </CloudFunctionButton>
    );
};
