import { CloudDownloadOutlined } from "@ant-design/icons";
import { asyncPool, exportableCollections, GetOrderStatsResponse } from "@rooted/shared";
import { Button, Modal, notification, Typography } from "antd";
import { ButtonProps } from "antd/lib/button";
import firebase from "firebase/app";
import React, { useState } from "react";
import { PageHeader } from "../../../components/PageHeader";
import { logError } from "../../../sentry";
import { functions, db } from "../../../services/firebase";

async function getImageProcessingCounts() {
  const { data: resizedImageCounts } = await functions.httpsCallable("getImageProcessingCounts")();
  const {
    originalImagesCount,
    resizedImagesCount,
    resizableImagesCount,
    resizeFailedImagesCount,
    imagesWith100x100Count,
    imagesWithout100x100Count,
    imagesWithFailed100x100Count,
  } = resizedImageCounts;

  const percentComplete =
    (100 * (resizedImagesCount + resizeFailedImagesCount)) /
    (resizableImagesCount + resizedImagesCount + resizeFailedImagesCount);

  notification.success({
    message: (
      <>
        <Typography.Paragraph>
          Total Originals: {originalImagesCount}
          <br />
          To Resize: {resizableImagesCount}
          <br />
          Resized: {resizedImagesCount}
          <br />
          Resize Failed: {resizeFailedImagesCount}
          <br />
          Resize Percent Complete: {percentComplete.toFixed(1)}%
          <br />
          Without 100x100: {imagesWithout100x100Count}
          <br />
          With 100x100: {imagesWith100x100Count}
          <br />
          100x100 Failed: {imagesWithFailed100x100Count}
        </Typography.Paragraph>
      </>
    ),
  });
}

async function checkHiddenOrders() {
  try {
    const { data }: { data: GetOrderStatsResponse } = await functions.httpsCallable(
      "getOrderStats"
    )();

    console.log(`got ${data.orders.length} from the server`);

    const results = await asyncPool(50, data.orders, async (order) => {
      const snap = await db.collection("childOrders").doc(order._id).get();
      return { id: order._id, existsForClient: snap.exists, order };
    });

    const hiddenOrders = results.filter((result) => !result.existsForClient);
    console.log(hiddenOrders);
    Modal.info({
      title: "Hidden Orders",
      content: (
        <>
          {hiddenOrders.length > 0 && (
            <ol>
              {hiddenOrders.map(({ id, order }) => (
                <li key={id}>
                  <strong>Id:</strong> {id}
                  <br />
                  <strong>Status:</strong> {order.status}
                  <br />
                  <strong>Placed At:</strong> {order.placedAt}
                  <br />
                  <strong>Total:</strong> ${order._calculated.total / 100}
                </li>
              ))}
            </ol>
          )}
          {hiddenOrders.length === 0 && "No Issues!"}
        </>
      ),
    });
  } catch (error) {
    console.error("Failed to get hidden orders", error);
    logError({ error, tags: { type: "admin" } });
    throw error;
  }
}

export const ActionsPane: React.FC = () => {
  const styles = {
    marginRightBottom: {
      marginRight: 12,
      marginBottom: 15,
    },
  };

  return (
    <>
      <PageHeader title={"Actions"} breadcrumb />
      <CloudFunctionButton
        onClick={() => functions.httpsCallable("testExpireCarts")({})}
        style={styles.marginRightBottom}
      >
        Expire Carts
      </CloudFunctionButton>
      <CloudFunctionButton
        onClick={() => functions.httpsCallable("checkAllStripeOnboardingStatuses")({})}
        style={styles.marginRightBottom}
      >
        Check Onboarding Statuses
      </CloudFunctionButton>
      <CloudFunctionButton
        onClick={() => functions.httpsCallable("syncTags")({})}
        style={styles.marginRightBottom}
      >
        Sync Email Tags
      </CloudFunctionButton>
      <CloudFunctionButton
        onClick={() => getImageProcessingCounts()}
        style={styles.marginRightBottom}
      >
        Check Image Processing Status
      </CloudFunctionButton>
      <CloudFunctionButton onClick={() => checkHiddenOrders()} style={styles.marginRightBottom}>
        Check Hidden Orders
      </CloudFunctionButton>
      <br />
      <br />
      {exportableCollections.map((collection) => (
        <ExportButton key={collection} collection={collection} style={styles.marginRightBottom}>
          Export {collection}
        </ExportButton>
      ))}
      <Button
        onClick={() => {
          throw new Error("[TEST ERROR] ActionsPane.tsx error");
        }}
        style={styles.marginRightBottom}
      >
        Throw Error (Test Sentry)
      </Button>
    </>
  );
};

export const CloudFunctionButton: React.FC<
  ButtonProps & { onClick: () => Promise<any>; dev?: boolean }
> = ({ onClick, children, dev = false, ...rest }) => {
  const [running, setRunning] = useState(false);

  const run = async () => {
    try {
      setRunning(true);
      const res = await onClick();
      setRunning(false);
      if (dev) {
        notification.success({
          message: <>Function finished: {children}</>,
          description: JSON.stringify(res),
        });
      }
    } catch (e) {
      setRunning(false);
      if (dev) {
        notification.error({
          message: <>Function failed: {children}</>,
          description: e.name + ": " + e.message,
        });
      }
    }
  };

  return (
    <Button type="primary" {...rest} loading={running} onClick={run}>
      {children}
    </Button>
  );
};

export const ExportButton: React.FC<
  ButtonProps & {
    collection: string;
  }
> = ({ onClick, collection, children, ...rest }) => {
  const [running, setRunning] = useState(false);

  const run = async () => {
    try {
      setRunning(true);
      const res = await functions.httpsCallable("exportCSVData")(collection);
      const link = await firebase
        .storage()
        .ref()
        .child(`csv-exports/${collection}.csv`)
        .getDownloadURL();
      downloadURI(link);
      setRunning(false);
      notification.success({
        message: <>Export finished</>,
        description: JSON.stringify(res),
      });
    } catch (e) {
      setRunning(false);
      notification.error({
        message: <>Export failed</>,
        description: JSON.stringify(e),
      });
    }
  };

  function downloadURI(uri: string) {
    const link = document.createElement("a");
    link.href = uri;
    link.download = "true"; // Browsers don't respect this as a name
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Button {...rest} loading={running} onClick={run}>
      <CloudDownloadOutlined />
      {children || "Export to CSV"}
    </Button>
  );
};
