import React from "react";
import { Descriptions, Divider } from "antd";
import { db, useDocumentDataOnceChecked } from "../../../../services/firebase";
import { RootedUser } from "@rooted/shared";
import { CopyCode } from "../../../../components/Misc/CopyCode";

export const OwnerDetails: React.FC<{ ownerId?: string }> = ({ ownerId }) => {
  const [ownerUser] = useDocumentDataOnceChecked<RootedUser>(
    ownerId ? db.collection("users").doc(ownerId) : null
  );

  if (!ownerUser || !ownerId) return null;

  return (
    <>
      <Divider style={{ marginTop: 4 }} orientation="left">
        Owner User
      </Divider>
      <Descriptions bordered size={"small"}>
        <Descriptions.Item label="Name">
          {ownerUser.firstName} {ownerUser?.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          <CopyCode>{ownerUser.email || ""}</CopyCode>
        </Descriptions.Item>
        <Descriptions.Item label="ID">
          <CopyCode>{ownerId}</CopyCode>
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};
