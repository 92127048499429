import { formatPluralCount, FullUserRole, SendInviteRequest } from "@rooted/shared";
import { Button, Col, Form, Input, InputProps, notification, Row, Typography } from "antd";
import React, { useCallback, useState } from "react";
import EmailPreview from "../../../components/Images/EmailPreview";
import { PageHeader } from "../../../components/PageHeader";
import { logError } from "../../../sentry";
import { functions } from "../../../services/firebase";

// https://stackoverflow.com/questions/23777183/validate-a-comma-separated-email-list
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validateEmails = (emails: string[]) => {
  return emails.find((email) => !email || !emailRegex.test(email));
};

// TODO: export to a cloud functions / api folder
const sendInvite = (params: SendInviteRequest) => functions.httpsCallable("inviteEmail")(params);

export const InvitePane: React.FC<{
  role: FullUserRole;
  inviteType: "invite-buyers" | "invite-sellers";
}> = ({ role, inviteType }) => {
  const [sending, setSending] = useState(false);

  const invite = useCallback(
    async ({ emails: emailString, message }: { emails: string; message: string }) => {
      const withDuplicateEmails: string[] = emailString.replace(/\s/g, "").split(",");
      const emails = Array.from(new Set(withDuplicateEmails).values());

      const badEmail = validateEmails(emails);
      if (badEmail) {
        notification.error({
          message: `Invalid email '${badEmail}'`,
          description: "Please ensure you entered a valid email or comma-separated list of emails.",
        });
        return;
      }

      setSending(true);

      const quotedEmailListString = emails.map((email) => `'${email}'`).join(", ");

      try {
        await sendInvite({
          emails,
          message,
          type: inviteType === "invite-buyers" ? "FARMER_TO_BUYER" : "BUYER_TO_FARMER",
          profileId: role.profileId,
        });
        notification.success({
          message: `Invitation sent to ${formatPluralCount(
            "email",
            emails.length
          )}: ${quotedEmailListString}!`,
        });
      } catch (error) {
        notification.error({
          message: `Oops! Something went wrong inviting ${quotedEmailListString}`,
        });
        logError({
          error,
          extraData: {
            emails,
            inviteType,
          },
          tags: {
            page: "invite-pane",
          },
        });
      } finally {
        setSending(false);
      }
    },
    [inviteType, role.profileId]
  );

  return (
    <>
      <PageHeader title={"Send an Invite!"} />

      <Row justify="center">
        <Col lg={14}>
          {inviteType === "invite-buyers" ? (
            <Typography.Paragraph>
              Invite your floral buyers and designers to view your farm's inventory using our simple
              invitation tool!
            </Typography.Paragraph>
          ) : (
            <Typography.Paragraph>
              Invite your farmers to Rooted using our simple invitation tool!
            </Typography.Paragraph>
          )}
          <Form layout="vertical" onFinish={invite}>
            <Form.Item
              style={{ marginBottom: 16 }}
              rules={[
                { required: true, message: "Please enter a valid email (or comma-separated list)" },
              ]}
              name="emails"
              label="Their Email Address"
            >
              <InputWithDescription
                type="email"
                multiple
                description={"(enter multiple email addresses by separating each by a comma)"}
              />
            </Form.Item>
            <Form.Item name="message" label="Your Personalized Message">
              <Input.TextArea rows={5} />
            </Form.Item>
            <Form.Item>
              <Button type={"primary"} htmlType="submit" loading={sending}>
                Send Email
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col flex="auto" />
        <Col lg={8} md={12} sm={18} xs={20} style={{ paddingRight: 4 }}>
          <EmailPreview emailType={inviteType} />
        </Col>
      </Row>
    </>
  );
};

const InputWithDescription: React.FC<InputProps & { description: string }> = ({
  description,
  ...inputProps
}) => (
  <>
    <Typography.Text type="secondary">
      (enter multiple email addresses by separating each by a comma)
    </Typography.Text>
    <Input {...inputProps} />
  </>
);
