import { Typography, Collapse, Col } from "antd";
import { PageHeader } from "../../../components/PageHeader";
import React from "react";
import { Link } from "react-router-dom";
import { ZoomAlertCard } from "../../../components/ZoomAlertCard";
const { Panel } = Collapse;

export const FAQs = () => {
  return (
    <>
      <PageHeader title={"Frequently Asked Questions"} />

      <ZoomAlertCard style={{ marginBottom: 20 }} />

      <Collapse accordion defaultActiveKey={["images"]} style={{ marginBottom: 20 }}>
        <Panel collapsible="header" header="Returning member?" key="images">
          <ul style={{ paddingTop: 10 }}>
            <li style={{ paddingBottom: 10 }}>
              <b>Your images are safe!</b>
              <br />
              Your images have all been moved over for you - no need to add them again!
              <br />
              You can view your images from your{" "}
              <Link to="/my-account/media-library">Image Library</Link>.
            </li>
            <li>
              <b>All of your listings are safe, too :)</b>
              <br />
              View all of your listings from last year on your{" "}
              <Link to="/inventory/cut-flowers">Cut Flowers Inventory</Link>.
            </li>
          </ul>
        </Panel>
      </Collapse>
      <Collapse accordion defaultActiveKey={["start"]} style={{ marginBottom: 20 }}>
        <Panel collapsible="header" header="Can I get started now?" key="start">
          <Col style={{ padding: "8px 8px 0 8px" }}>
            <Typography.Paragraph>
              <b>Let's dig in! </b>
            </Typography.Paragraph>
            <Typography.Paragraph>
              A great first step would be to start by uploading new flower images!
              <br />
              Just navigate to your My Account tab and select{" "}
              <Link to="/my-account/media-library">Image Library</Link>.
            </Typography.Paragraph>
            <Typography.Paragraph>
              <i>Drag, drop, done!</i>
              <br />
              After that, head on over to your{" "}
              <Link to="/inventory/cut-flowers">Cut Flower Inventory</Link> page to start creating
              your listings for this year.
            </Typography.Paragraph>
          </Col>
        </Panel>
      </Collapse>
      <Collapse accordion style={{ marginBottom: 20 }}>
        <Panel collapsible="header" header="When can I start selling?" key="open">
          <Col style={{ padding: 8 }}>
            We are now open for transactions! <i>Make sure your inventory is up to date.</i>
          </Col>
        </Panel>
      </Collapse>
      <Collapse accordion style={{ marginBottom: 20 }}>
        <Panel
          collapsible="header"
          header="I still have a question that I don't see answered here..."
          key="email"
        >
          <Col style={{ padding: 8 }}>
            Send us an email at{" "}
            <Typography.Link href="mailto:help@rootedfarmers.com">
              help@rootedfarmers.com
            </Typography.Link>{" "}
            and we'll be back in touch ASAP (usually within a couple of hours!).
          </Col>
        </Panel>
      </Collapse>
    </>
  );
};
