import { ActiveChildOrder, FullBuyerRole, CancelledChildOrder, WithId } from "@rooted/shared";
import { db, useCollectionDataChecked } from "../firebase";

export const useActiveOrders = (role: FullBuyerRole) =>
  useCollectionDataChecked<WithId<ActiveChildOrder>>(
    db
      .collection("childOrders")
      .where("buyerId", "==", role.profileId)
      .where("status", "==", "active")
      .orderBy("placedAt", "desc"),
    {
      idField: "_id",
    }
  );

export const useFulfilledOrders = (role: FullBuyerRole) =>
  useCollectionDataChecked<WithId<CancelledChildOrder>>(
    db
      .collection("childOrders")
      .where("buyerId", "==", role.profileId)
      .where("status", "==", "fulfilled")
      .orderBy("placedAt", "desc"),
    {
      idField: "_id",
    }
  );

export const useCancelledOrders = (role: FullBuyerRole) =>
  useCollectionDataChecked<WithId<CancelledChildOrder>>(
    db
      .collection("childOrders")
      .where("buyerId", "==", role.profileId)
      .where("status", "==", "cancelled")
      .orderBy("placedAt", "desc"),
    {
      idField: "_id",
    }
  );
