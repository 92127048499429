import { Input } from "antd";
import { InputNumberProps } from "antd/lib/input-number";
import React, { ChangeEvent, useState } from "react";

export const CurrencyInput: React.FC<InputNumberProps> = ({ value, onChange, ...rest }) => {
  const [state, setState] = useState<number | undefined>(value);

  const activeValue = value === undefined ? state : value;
  const formatted =
    activeValue === undefined
      ? ""
      : new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
          activeValue / 100
        );

  // Hacky -- TODO come up with a more robust way of dealing with formatted numbers
  // (also seen with percent values)
  const listener = (e: ChangeEvent<HTMLInputElement>) => {
    setStringValue(e.target.value);
    const cleanedValue = e.target.value.startsWith("$") ? e.target.value.slice(1) : e.target.value;
    const float = parseFloat(cleanedValue);
    if (!isNaN(float) && isFinite(float) && float >= 0) {
      const result = Math.round(float * 100);
      setState(result);
      onChange && onChange(result);
    } else {
      setState(undefined);
    }
  };

  const [stringValue, setStringValue] = useState<string>(formatted);

  return (
    <Input
      value={stringValue}
      onFocus={(e) => e.target.select()}
      onChange={listener}
      onBlur={(e) => setStringValue(formatted)}
      onPressEnter={(e) => e.currentTarget.blur()}
      {...rest}
    />
  );
};

export const formatCurrency = (x: number) => {
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
    Math.round(x) / 100
  );
};
