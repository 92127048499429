import { CoopApplicationForm, WithId } from "@rooted/shared";
import { Typography } from "antd";
import React from "react";
import { FirestoreDocForm } from "../../../../components/FormControls/FirestoreDocForm";
import { db } from "../../../../services/firebase";
import { CoopDetailsFormItems } from "../../../onboarding/Forms/CoopDetailsForm";

export const CoopDetailsPanel: React.FC<{ application: WithId<CoopApplicationForm> }> = ({
  application,
}) => {
  if (!application.details)
    return <Typography.Text type="secondary">Not filled out.</Typography.Text>;
  return (
    <FirestoreDocForm
      data={application.details}
      dbRef={db.collection("applications").doc(application._id)}
      rootFieldPath="details"
    >
      <CoopDetailsFormItems adminView />
    </FirestoreDocForm>
  );
};
