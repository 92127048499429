import {
  assertCasesExhausted,
  assertCasesExhaustedSilently,
  FlowerPrototype,
  FullGrowerRole,
  getDriedFlowerProductTypeLabel,
  GrowerDriedFlowerListing,
  GrowerListing,
  WithId,
} from "@rooted/shared";
import { Button, Col, Descriptions, notification, Row, Space, Tooltip, Typography } from "antd";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRouterBreadcrumb } from "../../../components/Misc/useRouterBreadcrumb";
import { PageHeader } from "../../../components/PageHeader";
import { useRooted } from "../../../RootedContext";
import { logError } from "../../../sentry";
import { db, useDocumentDataChecked } from "../../../services/firebase";
import {
  archiveListing,
  deleteListing,
  unArchiveListing,
} from "../../../services/sellers/listings";
import { getCategorySlugBreadcrumbReplacement } from "../../../utils/getCategorySlugBreadcrumbReplacement";
import { LoadingPage, NotFoundPage } from "../../layouts";
import { CSASeasonWidget, CSAWeeksWidget } from "./utils/CSAUtils";
import {
  PotSizeWidget,
  TraySizeWidget,
  PottedPlantTypeWidget,
  PottedPlantTagsWidget,
} from "./utils/PottedPlantUtils";
import {
  BloomSizeWidget,
  CustomFulfillmentInfoWidget,
  DescriptionWidget,
  DominantColorWidget,
  GrowerListingDatesWidget,
  GrowerPriceWidget,
  GrowerQuantityWidget,
  ImageWidget,
  ListingNameWidget,
  StemCountWidget,
  StemLengthWidget,
  StemsPerBunchWidget,
  VaseLifeWidget,
  WreathDepthWidget,
  WreathDiameterWidget,
  WreathTagsWidget,
} from "./utils/widgets";

const productName = (listing: GrowerListing) => {
  switch (listing.category) {
    case "cut-flower":
      return listing.product._prototypeCache.scientificName;
  }
  return listing.product.name;
};

export const ListingDetails: React.FC = () => {
  const { activeRole: role } = useRooted();
  if (role?.type !== "grower") throw new Error(`Unsupported Role: '${role?.type}'`);

  const { listingId } = useParams();
  const [listing, listingLoading] = useDocumentDataChecked<WithId<GrowerListing>>(
    db.collection("listings").doc(listingId),
    { idField: "_id" }
  );

  const name = listing ? productName(listing) : "...";

  const breadcrumb = useRouterBreadcrumb({
    [listingId]: name,
    ...getCategorySlugBreadcrumbReplacement(listing?.category),
  });

  if (listingLoading) return <LoadingPage />;

  if (!listing || listing.seller.profileId !== role.profileId) return <NotFoundPage />;

  return (
    <>
      <PageHeader
        breadcrumb={breadcrumb}
        title={name}
        extra={[
          listing.availability.active && (
            <Link to={`/listings/${listing._id}`} key={"link-buyer-view"}>
              <Button type="link">Buyer View</Button>
            </Link>
          ),
          <ArchiveButton
            key={"archive-button"}
            active={listing.availability.active}
            _id={listing._id}
          />,
        ]}
      />
      <>
        <Row wrap={true} gutter={16}>
          <Col>
            <ImageWidget role={role} listing={listing} editable />
          </Col>
          <Col flex="auto">
            <ListingDetailsPrimary listing={listing} role={role} />
          </Col>
        </Row>

        <br />

        <ListingDetailsSecondary listing={listing} />

        <br />

        <Descriptions bordered title={"Availability"} className="table-th-150">
          {renderListingDates(listing, role)}
          {renderListingQuantity(listing, role)}
          <Descriptions.Item label="Status" span={3}>
            <Typography.Text>
              {listing.availability.active ? "Visible" : "Hidden / Archived"}
            </Typography.Text>
            <Space direction="horizontal" style={{ float: "right" }}>
              <ArchiveButton active={listing.availability.active} _id={listing._id} />
              <DeleteButton
                category={listing.category}
                _id={listing._id}
                disabled={listing.availability.active}
                productName={name}
              />
            </Space>
          </Descriptions.Item>
        </Descriptions>
      </>
    </>
  );
};

const ListingDetailsPrimary: React.FC<{ listing: WithId<GrowerListing>; role: FullGrowerRole }> = ({
  listing,
  role,
}) => (
  <Descriptions bordered className="table-th-150">
    {renderListingDetailsPrimaryItems(listing, role)}
    <Descriptions.Item label="Description" span={3}>
      <DescriptionWidget editable listing={listing} />
    </Descriptions.Item>
  </Descriptions>
);

const renderFlowerPrototypeDescription = (prototype: FlowerPrototype) => {
  return (
    <>
      <Descriptions.Item span={3} label="Botanical Name">
        <b>{prototype.scientificName}</b>
      </Descriptions.Item>
      <Descriptions.Item span={3} label="Common Names">
        {prototype.commonNames.join(", ")}
      </Descriptions.Item>
    </>
  );
};

const renderListingNameDefault = (listing: WithId<GrowerListing>) => {
  return (
    <Descriptions.Item span={3} label="Name">
      <ListingNameWidget editable listing={listing} />
    </Descriptions.Item>
  );
};

const renderDriedFlowerName = (listing: WithId<GrowerDriedFlowerListing>) => {
  const { product } = listing;
  switch (product.type) {
    case "single-variety":
      return renderFlowerPrototypeDescription(product._prototypeCache);
    case "mixed-bunch":
    case "confetti":
    default:
      // If the listing does not have a type for whatever reason, just render the name
      return renderListingNameDefault(listing);
  }
};

// This abstraction is dumb, but is needed because of how `Description` nests children -- we can't just abstract
// this to a fragment JSX component, because that loses scoping on the parent Descriptions styling.
const renderListingDetailsPrimaryItems = (listing: WithId<GrowerListing>, role: FullGrowerRole) => {
  switch (listing.category) {
    case "cut-flower":
      return (
        <>
          {renderFlowerPrototypeDescription(listing.product._prototypeCache)}
          <Descriptions.Item label="Price / Stem" span={3}>
            <GrowerPriceWidget editable listing={listing} />
          </Descriptions.Item>
        </>
      );
    case "dried-flower": {
      return (
        <>
          {renderDriedFlowerName(listing)}
          <Descriptions.Item label="Price" span={3}>
            <GrowerPriceWidget editable listing={listing} />
          </Descriptions.Item>
        </>
      );
    }
    case "potted-plant":
    case "wreath":
    case "csa":
      return (
        <>
          {renderListingNameDefault(listing)}
          <Descriptions.Item label="Price" span={3}>
            <GrowerPriceWidget editable listing={listing} />
          </Descriptions.Item>
        </>
      );
    case "bucket":
    case "bouquet":
      return (
        <>
          <Descriptions.Item span={3} label="Name">
            <b>{listing.product._prototypeCache.scientificName}</b>
          </Descriptions.Item>
          <Descriptions.Item label="Price" span={3}>
            <GrowerPriceWidget editable listing={listing} />
          </Descriptions.Item>
        </>
      );
    default:
      assertCasesExhausted(listing);
  }
};

const renderListingDetailsSecondaryItems = (listing: WithId<GrowerListing>) => {
  switch (listing.category) {
    case "cut-flower":
      return (
        <>
          <Descriptions.Item span={3} label="Dominant Color">
            <DominantColorWidget editable listing={listing} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Bloom Size">
            <BloomSizeWidget editable listing={listing} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Vase Life">
            <VaseLifeWidget editable listing={listing} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Stem Length">
            <StemLengthWidget editable listing={listing} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Stems Per Bunch">
            <StemsPerBunchWidget editable listing={listing} />
          </Descriptions.Item>
        </>
      );
    case "potted-plant":
      return (
        <>
          <Descriptions.Item span={3} label="Type">
            <PottedPlantTypeWidget editable listing={listing} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Additional Info">
            <PottedPlantTagsWidget editable listing={listing} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Planter Size">
            <PotSizeWidget editable listing={listing} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Tray Size">
            <TraySizeWidget editable listing={listing} />
          </Descriptions.Item>
        </>
      );
    case "bucket":
      return (
        <>
          <Descriptions.Item span={3} label="Dominant Color">
            <DominantColorWidget editable listing={listing} />
          </Descriptions.Item>
        </>
      );
    case "dried-flower": {
      const { product } = listing;
      return (
        <>
          <Descriptions.Item span={3} label="Type">
            {getDriedFlowerProductTypeLabel(product.type)}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Dominant Color">
            <DominantColorWidget editable listing={listing} />
          </Descriptions.Item>
          {product.type !== "confetti" && (
            <>
              <Descriptions.Item span={3} label="Stem Count (est)">
                <StemCountWidget editable listing={listing} />
              </Descriptions.Item>
              <Descriptions.Item span={3} label="Stem Length">
                <StemLengthWidget editable listing={listing} />
              </Descriptions.Item>
            </>
          )}
        </>
      );
    }
    case "bouquet":
      return (
        <>
          <Descriptions.Item span={3} label="Dominant Color">
            <DominantColorWidget editable listing={listing} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Stem Count">
            <StemCountWidget editable listing={listing} />
          </Descriptions.Item>
        </>
      );
    case "wreath": {
      return (
        <>
          <Descriptions.Item span={3} label="Tags">
            <WreathTagsWidget editable listing={listing} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Diameter">
            <WreathDiameterWidget editable listing={listing} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Depth">
            <WreathDepthWidget editable listing={listing} />
          </Descriptions.Item>
        </>
      );
    }
    case "csa": {
      return (
        <>
          <Descriptions.Item span={3} label="Season">
            <CSASeasonWidget editable listing={listing} />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Duration">
            <CSAWeeksWidget editable listing={listing} />
          </Descriptions.Item>
        </>
      );
    }
    default:
      assertCasesExhaustedSilently(listing);
  }
};

const renderListingDates = (listing: WithId<GrowerListing>, role: FullGrowerRole) => {
  switch (listing.category) {
    case "cut-flower":
    case "potted-plant":
    case "bucket":
    case "bouquet":
    case "wreath":
    case "dried-flower":
      return (
        <Descriptions.Item label="Dates" span={3}>
          <Tooltip title={listing.availability.active ? "" : "Unhide to edit"}>
            <span>
              <GrowerListingDatesWidget editable={listing.availability.active} listing={listing} />
            </span>
          </Tooltip>
        </Descriptions.Item>
      );

    case "csa":
      return (
        <>
          <Descriptions.Item label="Fulfillment Details" span={3}>
            <CustomFulfillmentInfoWidget editable listing={listing} />
          </Descriptions.Item>
        </>
      );
    default:
      assertCasesExhausted(listing);
  }
};

const renderListingQuantity = (listing: WithId<GrowerListing>, role: FullGrowerRole) => {
  switch (listing.category) {
    case "cut-flower":
    case "potted-plant":
    case "bucket":
    case "bouquet":
    case "wreath":
    case "dried-flower":
    case "csa":
      return (
        <Descriptions.Item label="Quantity" span={3}>
          <Tooltip title={listing.availability.active ? "" : "Unhide to edit"}>
            <span>
              <GrowerQuantityWidget editable={listing.availability.active} listing={listing} />
            </span>
          </Tooltip>
        </Descriptions.Item>
      );
    default:
      assertCasesExhausted(listing);
  }
};

const ListingDetailsSecondary: React.FC<{
  listing: WithId<GrowerListing>;
}> = ({ listing }) => {
  const details = renderListingDetailsSecondaryItems(listing);
  return details ? (
    <Descriptions bordered title={"Details"} className="table-th-150">
      {details}
    </Descriptions>
  ) : null;
};

const ArchiveButton: React.FC<{ active: boolean; _id: string }> = ({ active, _id }) => (
  <Button
    key={"availability-active-button"}
    type="primary"
    danger={active}
    onClick={() => {
      if (active) {
        archiveListing({ _id });
      } else {
        unArchiveListing({ _id });
      }
    }}
  >
    {active ? "Hide" : "Unhide"}
  </Button>
);

const DeleteButton: React.FC<{
  productName: string;
  _id: string;
  disabled: boolean;
  category: GrowerListing["category"];
}> = ({ productName, _id: itemId, disabled, category }) => {
  const navigate = useNavigate();

  return (
    <Button
      danger
      type="ghost"
      disabled={disabled}
      onClick={async () => {
        try {
          await deleteListing({ id: itemId });
          navigate(`/inventory/${category}s`);
          notification.success({
            message: `Deleted ${productName} listing.`,
          });
        } catch (error) {
          notification.error({
            message: `Oops! Something went wrong deleting ${productName}.`,
          });
          logError({
            error,
            tags: {
              page: "listing-details",
            },
            extraData: {
              itemId,
            },
          });
        }
      }}
    >
      Delete
    </Button>
  );
};
