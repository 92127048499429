import { FullSellerRole, isSeller } from "@rooted/shared";
import { Badge, Menu } from "antd";
import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { CollapsingSider } from "../../../components/Sider/CollapsingSider";
import { useActiveOrders, useAnonymousPosOrders } from "../../../services/sellers/orders";
import { PageContent, WideLayout } from "../../layouts";
import {
  ActiveOrders,
  AnonymousPointOfSaleOrders,
  CancelledOrders,
  FulfilledOrders,
} from "../../orders";
import { OrderDetails } from "./OrderDetails";

export const Orders: React.FC<{ role: FullSellerRole }> = ({ role }) => {
  // This allows active detail pages to show the active as selected
  const category =
    useLocation()
      .pathname.split("/")
      .find((c) => c === "cancelled" || c === "fulfilled" || c === "guest-checkout") || "active";
  const [activeOrders] = useActiveOrders(role);
  const [anonymousPosOrders] = useAnonymousPosOrders(role);

  return (
    <>
      <WideLayout>
        <CollapsingSider
          selectedKeys={[category]}
          smallDrawerLabel={{
            title: "Orders",
            selection: category,
          }}
        >
          <Menu.Item key="active">
            <Link to="">
              Active Orders {activeOrders && <Badge count={activeOrders?.length ?? 0} />}
            </Link>
          </Menu.Item>
          {isSeller(role) && (
            <Menu.Item key="guest-checkout">
              <Link to="guest-checkout">
                Guest Checkout{" "}
                {anonymousPosOrders && <Badge count={anonymousPosOrders?.length ?? 0} />}
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="fulfilled">
            <Link to="fulfilled">Fulfilled Orders</Link>
          </Menu.Item>
          <Menu.Item key="cancelled">
            <Link to="cancelled">Cancelled Orders</Link>
          </Menu.Item>
        </CollapsingSider>
        <PageContent>
          <Routes>
            {/*<Route path="preorders/*" element={<PreOrders role={role} />} />*/}
            <Route path="/" element={<ActiveOrders />} />
            {isSeller(role) && (
              <Route path="guest-checkout/" element={<AnonymousPointOfSaleOrders />} />
            )}
            {isSeller(role) && (
              <Route path="guest-checkout/:orderId" element={<OrderDetails role={role} />} />
            )}
            <Route path="fulfilled/" element={<FulfilledOrders />} />
            <Route path="cancelled/" element={<CancelledOrders />} />
            <Route path=":orderId" element={<OrderDetails role={role} />} />
            <Route path="cancelled/:orderId" element={<OrderDetails role={role} />} />
            <Route path="fulfilled/:orderId" element={<OrderDetails role={role} />} />
          </Routes>
        </PageContent>
      </WideLayout>
    </>
  );
};
