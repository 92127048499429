import {
  legalColors,
  legalStemsPerBunch,
  legalBloomSizes,
  legalStemLengths,
  legalVaseLifes,
  FlowerPrototype,
} from "@rooted/shared";
import { Select, Alert } from "antd";
import { LabeledValue, SelectProps } from "antd/lib/select";
import { valueType } from "antd/lib/statistic/utils";
import React, { useState, useMemo } from "react";
import { ColorDisplay } from "../../../../components/Misc/ColorDisplay";
import { db, useDocumentDataChecked } from "../../../../services/firebase";
import { useFlowerPrototypeFullTextResults } from "../../../../services/flower-prototypes";
import { makeSelectOptions } from "../../../../utils/make-select-options";

// Buckets & Bouquets use prototypes, badly.
// We need to move away from this, but this is a short time mitigation
// to prevent *all* flowers from showing up when selecting a bucket.
const makeHackPrototypeSelect = (
  name: string,
  name2: string
): React.FC<SelectProps<LabeledValue>> => (props) => {
  const [prototypes1 = [], prototypes1Loading] = useFlowerPrototypeFullTextResults(name, {
    limit: 50,
  });
  const [prototypes2 = [], prototypes2Loading] = useFlowerPrototypeFullTextResults(name2, {
    limit: 50,
  });
  const options = useMemo(
    () =>
      Array.from(new Set([...prototypes1, ...prototypes2]).keys()).map((prototype) => ({
        label: prototype.scientificName,
        value: prototype._id,
      })),
    [prototypes1, prototypes2]
  );

  const loading = prototypes1Loading || prototypes2Loading;
  return <Select loading={loading} placeholder="Select a name..." options={options} {...props} />;
};

export const BucketPrototypeSelect = makeHackPrototypeSelect("Mixed Bucket", "Individual Bucket");
export const BouquetPrototypeSelect = makeHackPrototypeSelect("Bouquet", "Arrangement");

export const FlowerPrototypeInput: React.FC<SelectProps<LabeledValue>> = ({
  onChange,
  value,
  ...props
}) => {
  const [search, setSearch] = useState("");
  const [
    dataWithBucketsBouquets = [],
    dataLoading,
    dataError,
  ] = useFlowerPrototypeFullTextResults(search, { limit: 50 });

  const data = useMemo(
    () =>
      dataWithBucketsBouquets.filter(
        (prototype) =>
          !prototype.genus.includes("Mixed Bucket") &&
          !prototype.genus.includes("Individual Bucket") &&
          !prototype.genus.includes("Bouquet") &&
          !prototype.genus.includes("Arrangement")
      ),
    [dataWithBucketsBouquets]
  );

  return (
    <Select
      {...props}
      value={value}
      showSearch
      style={{}}
      showArrow={false}
      filterOption={false}
      onChange={(v, o) => {
        onChange && onChange(v, o);
      }}
      onSearch={setSearch}
      notFoundContent={null}
      placeholder="Search by Common or Latin Name"
      virtual={false}
    >
      {data?.map(({ _id, genus = "", species = "", variety = "", series = "", commonNames }) => (
        <Select.Option value={_id} key={_id}>
          <em>{`${genus} ${species}`}</em>
          {` ${series} ${variety} ${commonNames.filter((c) => !!c.trim()).join(", ")}`}
        </Select.Option>
      ))}
    </Select>
  );
};

const renderColorOptions = () =>
  legalColors.map(({ name, key }) => (
    <Select.Option value={key} key={key}>
      <ColorDisplay color={key} />
    </Select.Option>
  ));

export const ColorInput: React.FC<SelectProps<string[]>> = (props) => {
  return (
    <Select
      style={{ width: 340 }}
      {...props}
      mode="multiple"
      maxTagCount={3}
      placeholder="Color..."
    >
      {renderColorOptions()}
    </Select>
  );
};

export const SingleColorInput: React.FC<SelectProps<string>> = (props) => {
  return (
    // This width needs to be large enough to accommodate the largest color name, "Cream / Buttercream"
    <Select style={{ width: 220 }} {...props} placeholder="Color...">
      {renderColorOptions()}
    </Select>
  );
};

const legalStemsPerBunchOptions = makeSelectOptions(legalStemsPerBunch);
export const StemsPerBunchInput: React.FC<SelectProps<valueType>> = (props) => {
  return <Select style={{ width: 120 }} {...props} options={legalStemsPerBunchOptions} />;
};

const legalBloomSizesOptions = makeSelectOptions(legalBloomSizes);
export const BloomSizeInput: React.FC<SelectProps<valueType>> = (props) => {
  return <Select style={{ width: 120 }} {...props} options={legalBloomSizesOptions} allowClear />;
};

const legalStemLengthsOptions = makeSelectOptions(legalStemLengths);
export const StemLengthInput: React.FC<SelectProps<valueType>> = (props) => {
  return <Select style={{ width: 120 }} {...props} options={legalStemLengthsOptions} />;
};

const legalVaseLifesOptions = makeSelectOptions(legalVaseLifes);
export const VaseLifeInput: React.FC<SelectProps<valueType>> = (props) => {
  return <Select style={{ width: 120 }} {...props} options={legalVaseLifesOptions} allowClear />;
};

export const PricingGuide: React.FC<{
  prototypeId?: string;
  price?: number;
}> = ({ prototypeId, price }) => {
  const [prototype] = useDocumentDataChecked<FlowerPrototype>(
    prototypeId ? db.collection("flowerPrototypes").doc(prototypeId) : null
  );

  if (!prototypeId) return <Alert type="info" message="Select a flower to view pricing info." />;
  if (!prototype) return null; //loading

  if (
    !prototype.lowestPrice ||
    !prototype.lowPrice ||
    !prototype.averagePrice ||
    !prototype.highPrice ||
    !prototype.highestPrice
  ) {
    return <Alert type="info" message="Pricing information coming soon for this item!" />;
  }

  if (!price) return <Alert type="info" message="Set a price to view pricing info." />;

  if (price < prototype.lowestPrice)
    return <Alert type="info" message="Your price is lower than most listings for this flower." />;
  //if (price < prototype.lowPrice) return <Alert type="info" message="Your price is lower than the average" />
  //if (price < prototype.averagePrice) return <Alert type="info" message="Your price is good" />
  //if (price < prototype.highPrice) return <Alert type="info" message="Your price is higher than the average" />
  if (price > prototype.highestPrice)
    return <Alert type="info" message="Your price is higher than most listings for this flower." />;
  return <Alert type="info" message="Your price is in the usual range for this flower." />;
};
