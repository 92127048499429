import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotFoundPage } from "../../../views/layouts";
import { CreateFlower } from "./CreateFlower";
import { FlowerDetails } from "./FlowerDetails";
import { FlowersOverview } from "./FlowersOverview";
export * from "./FlowerDetails";

export const Flowers: React.FC = () => {
  return (
    <Routes>
      <Route path=":itemId" element={<FlowerDetails />} />
      <Route path="" element={<FlowersOverview />} />
      <Route path="create" element={<CreateFlower />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
