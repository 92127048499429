import { Alert, Button, Col, Form, Input, Row, Typography } from "antd";
import React, { useState } from "react";
import { createAccount } from "../../services/account-state";
import { LinkWithQueryParams } from "../../utils/navigateWithQueryParams";
import { PageContent, WideLayout } from "../layouts";
import "./AuthForm.less";
import { Logo } from "./LoginForm";

export const CreateAccountForm = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onFinish = async (formValues: any) => {
    setError("");
    setLoading(true);
    try {
      await createAccount(formValues.email, formValues.password);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <WideLayout>
      <PageContent className="page-content-center">
        <Logo />
        <Row align="middle" justify="center" className="login-title">
          <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 10 }} xl={{ span: 10 }}>
            <Typography.Title level={3} style={{ textAlign: "center" }}>
              Create Account
            </Typography.Title>
            <Form
              name="create-account"
              className="auth-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              {error && (
                <Form.Item>
                  <Alert message={error} type="error" />
                </Form.Item>
              )}
              <Form.Item
                name="email"
                getValueFromEvent={(event) => {
                  const value = event?.target?.value;
                  return typeof value === "string" ? value.trim() : value;
                }}
                rules={[{ required: true, message: "Please enter your email." }]}
              >
                <Input placeholder="Email" type="email" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Please enter your password." }]}
              >
                <Input.Password placeholder="Password" visibilityToggle />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="auth-form-button"
                  loading={loading}
                  block
                >
                  Create Account
                </Button>
              </Form.Item>
              <div style={{ textAlign: "center" }}>
                <LinkWithQueryParams to="/accounts/login">Login</LinkWithQueryParams> or{" "}
                <LinkWithQueryParams to="/accounts/reset-password">
                  Reset Password
                </LinkWithQueryParams>
              </div>
            </Form>
          </Col>
        </Row>
      </PageContent>
    </WideLayout>
  );
};
