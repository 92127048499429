import { WholesaleBuyerApplicationForm, WithId } from "@rooted/shared";
import { Typography } from "antd";
import React from "react";
import { FirestoreDocForm } from "../../../../components/FormControls/FirestoreDocForm";
import { db } from "../../../../services/firebase";
import { WholesaleBuyerDetailsFormItems } from "../../../onboarding/Forms/WholesaleBuyerDetailsForm";

export const WholesaleBuyerDetailsPanel: React.FC<{
  application: WithId<WholesaleBuyerApplicationForm>;
}> = ({ application }) => {
  if (!application.details)
    return <Typography.Text type="secondary">Not filled out.</Typography.Text>;
  return (
    <FirestoreDocForm
      data={application.details}
      dbRef={db.collection("applications").doc(application._id)}
      rootFieldPath="details"
    >
      <WholesaleBuyerDetailsFormItems application={application} adminView />
    </FirestoreDocForm>
  );
};
