import { throttle } from "lodash";
import { useState, useLayoutEffect } from "react";

export const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useLayoutEffect(() => {
    const updateSize = throttle(() => void setSize([window.innerWidth, window.innerHeight]), 200, {
      leading: true,
      trailing: true,
    });
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const breakpoint = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export const useBreakpoint = () => {
  const [width] = useWindowSize();
  return checkBreakpoint(width);
};

const checkBreakpoint = (width: number) => {
  return {
    xs: width > breakpoint.xs,
    sm: width > breakpoint.sm,
    md: width > breakpoint.md,
    lg: width > breakpoint.lg,
    xl: width > breakpoint.xl,
    xxl: width > breakpoint.xxl,
  };
};

export const getBreakpoint = () => {
  return checkBreakpoint(window.innerWidth);
};
