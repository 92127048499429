import { Layout } from "antd";
import React from "react";
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { Navbar } from "../../components/Navbar";
import { SignedInComplete } from "../../services/account-state";
import { NavigateWithQueryParams } from "../../utils/navigateWithQueryParams";
import { MyCart } from "../../views/generic/Cart";
import { Listings } from "../../views/generic/Listings";
import { Settings } from "../../views/generic/Settings";
import { GrowerInventory } from "../../views/growers/Inventory";
import { GrowerResources } from "../../views/growers/Resources";
import { ShopRoutes as Shop } from "../buyers/Shop";
import { RetailBuyerFAQs } from "../../views/retail-buyers/RetailBuyerFAQs";
import { BuyerOrders } from "../buyers/orders";
import { CoopMemberships } from "../coops/CoopMemberships";
import { CoopInventory } from "../coops/Inventory";
import { AccountSettings } from "../generic/AccountSettings";
import { DashboardLayout, Footer, NotFoundPage } from "../layouts";
import { Onboarding } from "../onboarding";
import { OnboardingHome } from "../onboarding/OnboardingHome";
import { MyBuyers } from "../sellers/MyBuyers";
import { Orders } from "../sellers/orders";
import { MySellers } from "../wholesale-buyers/Connections";
import { useRooted } from "../../RootedContext";
import { EnterpriseProfileDetails } from "../generic/EnterpriseProfileDetails";
import { isBuyer } from "@rooted/shared";
import { SalesSummary } from "../sellers/analytics/SalesSummary";

export const Dashboard: React.FC = () => {
  const { account, activeRole } = useRooted();
  if (account.status !== "signed-in-complete")
    throw new Error(`Cannot render dashboard with account status: ${account.status}`);

  //Redirect users just signing in with onboarding intents
  const params = new URL(document.location.toString()).searchParams;
  const intent = params.get("application");
  const location = useLocation().pathname.split("/")[1];
  if (intent && location !== "onboarding") {
    // rendering a component to navigate ensures that this will succeed
    // as no component down the page has the chance to also trigger a
    // navigation on render
    return <NavigateWithQueryParams to="/onboarding" />;
  }

  return (
    <>
      <Layout>
        <DashboardLayout>
          <Navbar />
          <Routes>
            {RedirectRoutes}

            {/* Any-role specific routes*/}
            {activeRole !== undefined && (
              <>
                <Route path="growers/:profileRef" element={<EnterpriseProfileDetails />} />
                <Route path="coops/:profileRef" element={<EnterpriseProfileDetails />} />
                <Route path="wholesale-buyers/:profileRef" element={<EnterpriseProfileDetails />} />
                <Route path="listings/*" element={<Listings />} />
              </>
            )}

            {/* Any-buyer specific routes */}
            {isBuyer(activeRole) && (
              <>
                <Route path="" element={<Navigate to="/shop" replace />} />

                <Route path="shop/*" element={<Shop role={activeRole} />} />
                <Route
                  path="categories/*"
                  element={<DeepRedirect to={"shop"} from="categories" />}
                />

                <Route path="cart" element={<MyCart role={activeRole} />} />
                <Route path="orders/*" element={<BuyerOrders role={activeRole} />} />
              </>
            )}

            {/* Any-seller specific routes */}
            {(activeRole?.type === "coop" || activeRole?.type === "grower") && (
              <>
                <Route path="orders/*" element={<Orders role={activeRole} />} />
                <Route path="connections/*" element={<MyBuyers role={activeRole} />} />
                <Route path="dashboard/sales" element={<SalesSummary />} />
              </>
            )}

            {/* Retail-specific routes */}
            {activeRole?.type === "retail-buyer" && (
              <>
                <Route path="faqs" element={<RetailBuyerFAQs role={activeRole} />} />
              </>
            )}

            {/* Real profile routes */}
            {activeRole && (
              <>
                <Route path="my-account/*" element={<Settings role={activeRole} />} />
              </>
            )}

            {/* Wholesale-specific routes */}
            {activeRole?.type === "wholesale-buyer" && [
              <Route
                key={`ws-route-connections`}
                path="connections/*"
                element={<MySellers role={activeRole} />}
              />,
            ]}

            {/* Grower-specific routes */}
            {activeRole?.type === "grower" && (
              <>
                <Route path="" element={<Navigate to="/inventory" replace />} />
                <Route path="inventory/*" element={<GrowerInventory role={activeRole} />} />
                <Route path="resources/*" element={<GrowerResources role={activeRole} />} />
              </>
            )}

            {/* Coop-specific routes */}
            {activeRole?.type === "coop" && (
              <>
                <Route path="" element={<Navigate to="/inventory" replace />} />
                <Route path="inventory/*" element={<CoopInventory />} />
                <Route path="members/*" element={<CoopMemberships role={activeRole} />} />
              </>
            )}

            {/* No roles splash page */}
            {activeRole === undefined && (
              <Route path="" element={<OnboardingHome account={account as SignedInComplete} />} />
            )}

            <Route path="onboarding/*" element={<Onboarding account={account} />} />
            <Route path="user-settings/*" element={<AccountSettings />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </DashboardLayout>
        <Footer />
      </Layout>
    </>
  );
};

type Redirect = { from: string; to: string };

const REDIRECTS: Redirect[] = [
  // As of 4.15.21, per changes in https://github.com/rooted-tech/rooted-app/pull/851
  {
    from: "/account-settings/applications",
    to: "/user-settings/applications",
  },
  {
    from: "/account-settings/organizations",
    to: "/user-settings/organizations",
  },
  {
    from: "account-settings/profile",
    to: "/my-account/profile",
  },
  // For old retail profile
  {
    from: "account-settings/credit-cards",
    to: "/my-account/credit-cards",
  },
  // For old wholesale settings
  {
    from: "/settings/credit-cards",
    to: "/my-account/credit-cards",
  },
];

// Build these OUTSIDE of the render loop, so that they get mounted as routes themselves.
const RedirectRoutes = REDIRECTS.map(({ from, to }) => (
  <Route key={`${from}-${to}`} path={from} element={<Navigate to={to} replace />} />
));

const DeepRedirect: React.FC<{ to: string; from: string }> = ({ to, from }) => {
  const location = useLocation();
  return <NavigateWithQueryParams to={location.pathname.replace(from, to)} replace />;
};
