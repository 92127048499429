import {
  WithId,
  ChildOrder,
  OrderItem,
  CutFlowerListing,
  determinePricingScheme,
} from "@rooted/shared";
import { Popover, Space, Button, Typography } from "antd";
import React, { useState } from "react";
import { changeItem } from "../../../services/sellers/orders";
import { CurrencyInput } from "../../FormControls/CurrencyInput";
import { EditOutlined } from "@ant-design/icons";
import { PricingDisplay } from "../BuyerListings/PriceWidget";

export const ChildOrderPriceWidget: React.FC<{
  order: WithId<ChildOrder>;
  item: OrderItem;
  editable: boolean;
}> = ({ order, item, editable }) => {
  const listing = item.listing as CutFlowerListing;

  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  // Internal price is the pending client price.
  // Either the major or minor unit price depending upon
  // the prioritizeMajorUnit key of the pricing.
  const [internalPrice, setInternalPrice] = useState(
    listing.pricing.prioritizeMajorUnit
      ? listing.pricing.price
      : listing.pricing.pricePerStem ?? listing.pricing.price // The `as CutFlowerListing` is wrong, so this fixes internal prices on adhoc items
  );

  // To be priced by minor unit, something must have a minor unit
  // and not explicitly prioritize the major unit
  const pricingScheme = determinePricingScheme(listing.pricing);

  const submit = async () => {
    if (order.status !== "active" && order.status !== "guest-checkout")
      throw new Error("Order is not active / guest-checkout.");
    setSubmitting(true);

    if (pricingScheme === "minor-major") {
      await changeItem(order, {
        ...item,
        //@ts-expect-error No way to infer this type constraint
        listing: {
          ...item.listing,
          pricing: {
            ...item.listing.pricing,
            // UGLY Recover the stemsPerBunch from the pricing fields
            price:
              internalPrice *
              Math.round(item.listing.pricing.price / item.listing.pricing.pricePerStem!),
            pricePerStem: internalPrice,
          },
        },
      });
    } else if (pricingScheme === "major") {
      // Pure major unit pricing
      await changeItem(order, {
        ...item,
        //@ts-expect-error No way to infer this type constraint
        listing: {
          ...item.listing,
          pricing: {
            ...item.listing.pricing,
            price: internalPrice,
          },
        },
      });
    } else {
      // "minor-major" case
      // Minor unit exists but isn't prioritized
      await changeItem(order, {
        ...item,
        //@ts-expect-error No way to infer this type constraint
        listing: {
          ...item.listing,
          pricing: {
            ...item.listing.pricing,
            price: internalPrice,
            // UGLY Recover the stemsPerBunch from the pricing fields
            pricePerStem: Math.ceil(
              internalPrice /
                Math.round(item.listing.pricing.price / item.listing.pricing.pricePerStem!)
            ),
          },
        },
      });
    }

    setSubmitting(false);
    setOpen(false);
  };

  return (
    <>
      {item.initialValue.listing.pricing.price !== item.listing.pricing.price && (
        <Typography.Text delete type="secondary">
          <PricingDisplay
            pricing={item.initialValue.listing.pricing}
            unit={item.initialValue.listing.product.unit}
            twoLine={true}
          />
        </Typography.Text>
      )}
      <PricingDisplay pricing={listing.pricing} unit={listing.product.unit} twoLine={true} />
      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <CurrencyInput
                  value={internalPrice}
                  onChange={(e) => setInternalPrice(e as number)}
                />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change price"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};
