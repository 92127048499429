import { WithId, Listing, Relisting } from "@rooted/shared";

import {
  Button,
  notification,
  List,
  Card,
  Col,
  Row,
  Divider,
  Typography,
  Collapse,
  Alert,
  Tabs,
  Tag,
} from "antd";
import React, { useState, useCallback, useMemo } from "react";
import { db } from "../../../services/firebase";
import { PageContent, WideLayout } from "../../layouts";
import { PageHeader } from "../../../components/PageHeader";
import _ from "lodash";
import { CopyCode } from "../../../components/Misc/CopyCode";
import ReactDiffViewer from "react-diff-viewer";

const { Panel } = Collapse;

type ProcessStatus = {
  totalFetched: number;
  totalProcessed: number;
};
type InvalidRelistingData = {
  relisting: WithId<Relisting>;
  backingListing?: WithId<Listing>;
  differentKeys: string[];
};
const getInvalidRelistings = async (
  onUpdate: ({ totalFetched, totalProcessed }: ProcessStatus) => void
): Promise<InvalidRelistingData[]> => {
  const invalidListings: InvalidRelistingData[] = [];
  // We can query everything right now because of size
  let lastPage: any = null;
  let moreToFetch = true;
  let totalFetched = 0;
  let totalProcessed = 0;
  const interval = setInterval(() => {
    onUpdate({
      totalFetched,
      totalProcessed,
    });
  }, 200);
  while (moreToFetch) {
    const relistings = await (
      await db.collection("relistings").orderBy("category").limit(50).startAfter(lastPage).get()
    ).docs;
    if (relistings.length < 50) {
      moreToFetch = false;
      break;
    }
    totalFetched = totalFetched + relistings.length;
    lastPage = relistings[relistings.length - 1];

    await Promise.all(
      // eslint-disable-next-line no-loop-func
      relistings.map(async (doc) => {
        const relisting: WithId<Relisting> = Object.assign(doc.data() as Relisting, {
          _id: doc.id,
        });
        const backingListingSnap = await db
          .collection("listings")
          .doc(relisting.backingListing._id)
          .get();
        const backingListingData = backingListingSnap.exists
          ? (backingListingSnap.data() as Listing)
          : undefined;
        const backingListing = backingListingData
          ? Object.assign(backingListingData, { _id: backingListingSnap.id })
          : undefined;
        if (!_.isEqual(backingListing, relisting.backingListing)) {
          invalidListings.push({
            relisting,
            backingListing,
            differentKeys: backingListing
              ? getObjectDiff(relisting.backingListing, backingListing)
              : ["ALL"],
          });
        }
        totalProcessed = totalProcessed + 1;
      })
    );
  }
  clearInterval(interval);
  onUpdate({
    totalFetched,
    totalProcessed,
  });

  return invalidListings;
};

export const Relistings: React.FC = () => {
  const [invalidRelistings, setInvalidRelistings] = useState<InvalidRelistingData[]>([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<ProcessStatus>();

  const checkRelistings = useCallback(async () => {
    setLoading(true);
    try {
      setInvalidRelistings(await getInvalidRelistings(setStatus));
    } catch (error) {
      console.error(error);
      notification.error({ message: error.message });
    }
    setLoading(false);
  }, []);

  return (
    <WideLayout>
      <PageContent>
        <PageHeader title="Relistings" />
        <Alert
          style={{ marginBottom: 16 }}
          type="warning"
          message={
            "This script will run over every single relisting! Please use this sparingly, and be patient."
          }
        />
        <Button type="primary" loading={loading} onClick={checkRelistings}>
          Validate Relistings
        </Button>

        <List
          dataSource={invalidRelistings}
          loading={loading}
          header={
            <>
              <Typography.Text>{`Relistings Fetched: ${status?.totalFetched}`}</Typography.Text>
              <br />
              <Typography.Text>{`Relistings Checked : ${status?.totalProcessed}`}</Typography.Text>
              <br />
              <Typography.Text>{`Relistings Invalid : ${
                loading ? "loading..." : invalidRelistings.length
              }`}</Typography.Text>
            </>
          }
          rowKey={({ relisting: { _id } }) => _id}
          renderItem={(data) => <InvalidRelistingRow invalidRelistingData={data} />}
        />
      </PageContent>
    </WideLayout>
  );
};

const InvalidRelistingRow: React.FC<{ invalidRelistingData: InvalidRelistingData }> = ({
  invalidRelistingData: { relisting, backingListing, differentKeys },
}) => (
  <List.Item>
    <Card style={{ width: "100%" }}>
      <Row gutter={[8, 8]} style={{ width: "100%" }}>
        <Col span={12}>
          {relisting?.availability?.active &&
            relisting?.backingListing?.availability?.active &&
            relisting?.relistingMetaData?.isActive && <Tag color={"warning"}>Active!</Tag>}
          <Typography.Title level={5}>Relisting:</Typography.Title>
          <CopyCode>{relisting?._id ?? "Not Found"}</CopyCode>
          {relisting?.product?.name ?? "Not Found"}
          <Divider />
          <Typography.Title level={5}>Relister:</Typography.Title>
          {relisting?.seller?._cache?.bio?.displayName ?? "Not Found"}
          <CopyCode>{relisting?.seller?.profileId ?? "Not Found"}</CopyCode>
        </Col>
        <Col span={12}>
          {backingListing?.availability?.active && <Tag color={"warning"}>Active!</Tag>}
          <Typography.Title level={5}>Listing:</Typography.Title>
          <CopyCode>{backingListing?._id ?? "Not Found"}</CopyCode>
          {backingListing?.product?.name ?? "Not Found"}
          <Divider />
          <Typography.Title level={5}>Lister:</Typography.Title>
          {backingListing?.seller?._cache?.bio?.displayName ?? "Not Found"}
          <CopyCode>{backingListing?.seller?.profileId ?? "Not Found"}</CopyCode>
        </Col>

        <Divider />
        <Col span={24}>
          <Collapse>
            {differentKeys.map((key, i) => (
              <Panel header={key} key={key} collapsible="header">
                <Diff
                  a={(relisting.backingListing as any)?.[key]}
                  b={(backingListing as any)?.[key]}
                />
              </Panel>
            ))}
          </Collapse>
        </Col>
      </Row>
    </Card>
  </List.Item>
);

// https://stackoverflow.com/questions/31683075/how-to-do-a-deep-comparison-between-2-objects-with-lodash
function getObjectDiff(obj1: any, obj2: any) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
}
const _Diff: React.FC<{ a: any; b: any }> = ({ a, b }) => {
  const _a = useMemo(() => JSON.stringify(ordered(a), null, 2), [a]);
  const _b = useMemo(() => JSON.stringify(ordered(b), null, 2), [b]);

  return (
    <Tabs>
      <Tabs.TabPane tab="Raw" key={"raw"}>
        {/* Raw text, no diffs displayed */}
        <Row gutter={[8, 8]} style={{ width: "100%" }}>
          <Col span={12}>
            <Typography.Text code>
              <pre>{_a}</pre>
            </Typography.Text>{" "}
          </Col>
          <Col span={12}>
            <Typography.Text code>
              <pre>{_b}</pre>
            </Typography.Text>
          </Col>
        </Row>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Diff" key={"diff"}>
        {/* With diff, can be weird, is expensive */}
        <ReactDiffViewer splitView oldValue={_a} newValue={_b} />
      </Tabs.TabPane>
    </Tabs>
  );
};

const Diff = React.memo(_Diff);

const ordered = (unordered: unknown) => {
  if (typeof unordered !== "object" || unordered === null) return unordered;

  return Object.keys(unordered)
    .sort()
    .reduce((obj, key) => {
      // @ts-expect-error We didn't want to type unordered correctly here
      obj[key] = ordered(unordered[key]);
      return obj;
    }, {});
};
