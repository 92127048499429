import { FullCoopRole, FullGrowerRole, FullSellerRole, SellerProfile } from "@rooted/shared";
import { Badge, Layout, Menu } from "antd";
import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { CollapsingSider } from "../../../components/Sider/CollapsingSider";
import { db } from "../../../services/firebase";
import { InvitePane } from "../../generic/Invite";
import { NotifyBuyers } from "../../generic/Notify/NotifyBuyers";
import { useCoopMembershipSettingsNotificationsCount } from "../../generic/Settings/settings-notifications";
import { NotFoundPage, PageContent, WideLayout } from "../../layouts";
import { ConnectionsPane } from "../../connections/ConnectionsPane";
import { FindBuyersPane } from "./FindBuyersPane";

export const useFulfillmentSettingsNotificationsCount = (profileId: string) => {
  const [profile] = useDocumentData<SellerProfile>(db.collection("profiles").doc(profileId), {
    idField: "_id",
  });

  if (!profile) return 0;
  return 0;
};

export const MyBuyers: React.FC<{
  role: FullSellerRole;
}> = ({ role }) => {
  const category = useLocation().pathname.split("/")[2] || "my-buyers";

  return (
    <>
      <WideLayout>
        <CollapsingSider
          selectedKeys={[category]}
          smallDrawerLabel={{
            selection: category,
            title: "Connections",
          }}
        >
          {/* Enterprise-specific routes -- these apply to everyone but retail buyers */}
          <Menu.Item key="my-buyers">
            <Link to="">Buyers</Link>
          </Menu.Item>
          {!role.privateProfile.profileSearchDisabled && [
            <Menu.Item key="find-buyers">
              <Link to="find-buyers">Find Nearby Buyers</Link>
            </Menu.Item>,
            <Menu.Item key="invite-buyers">
              <Link to="invite-buyers">Invite Buyers</Link>
            </Menu.Item>,
          ]}
          <Menu.Item key="notify-buyers">
            <Link to="notify-buyers">Notify Buyers</Link>
          </Menu.Item>
        </CollapsingSider>
        <PageContent>
          <Routes>
            <Route path="" element={<ConnectionsPane />} />
            {!role.privateProfile.profileSearchDisabled && [
              <Route
                path="find-buyers/*"
                element={<FindBuyersPane role={role} />}
                key="find-buyers"
              />,
              <Route
                path="invite-buyers/*"
                element={
                  <InvitePane role={role} inviteType={"invite-buyers"} key="invite-buyers" />
                }
                key="invite-buyers"
              />,
            ]}
            <Route path="notify-buyers" element={<NotifyBuyers role={role} />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Layout.Sider />
        </PageContent>
      </WideLayout>
    </>
  );
};

export const FulfillmentSettingBadge: React.FC<{
  role: FullGrowerRole | FullCoopRole;
}> = ({ role }) => <Badge count={useFulfillmentSettingsNotificationsCount(role.profileId)} />;

export const CoopMembershipSettingsBadge: React.FC<{
  role: FullGrowerRole | FullCoopRole;
}> = ({ role }) => <Badge count={useCoopMembershipSettingsNotificationsCount(role.profileId)} />;
