import React from "react";
import { firestoreToISO, RetailBuyerProfile, WithId } from "@rooted/shared";

import { CopyCode } from "../../../components/Misc/CopyCode";
import { sortByLocaleCompare } from "../../../utils/sortByLocaleCompare";
import OverflowParagraph from "../../../components/OverflowParagraph";
import { sortByFirestoreTimestamp } from "../../../utils/sortByTimestamp";

export const RetailProfileColumns = [
  {
    title: "Name",
    key: "displayName",
    render: (o: any, r: WithId<RetailBuyerProfile>) => <b>{r.bio.displayName}</b>,
    sorter: (a: WithId<RetailBuyerProfile>, b: WithId<RetailBuyerProfile>) =>
      sortByLocaleCompare("displayName")(a.bio, b.bio),
  },
  {
    title: "Phone Number",
    key: "email",
    render: (o: any, r: WithId<RetailBuyerProfile>) => (
      <OverflowParagraph expandable style={{ width: 180 }}>
        {r.bio.phoneNumber}
      </OverflowParagraph>
    ),
    sorter: (a: WithId<RetailBuyerProfile>, b: WithId<RetailBuyerProfile>) =>
      sortByLocaleCompare("phoneNumber")(a.bio, b.bio),
  },
  {
    title: "Profile Id",
    key: "profile-id",
    render: (o: any, r: WithId<RetailBuyerProfile>) => <CopyCode>{r._id}</CopyCode>,
    sorter: sortByLocaleCompare("_id"),
  },
  {
    title: "Owner Id",
    key: "owner-id",
    render: (o: any, r: WithId<RetailBuyerProfile>) => <CopyCode>{r.ownerId}</CopyCode>,
    sorter: sortByLocaleCompare("ownerId"),
  },
  {
    title: "Created",
    key: "created",
    render: (o: any, r: WithId<RetailBuyerProfile>) =>
      r._created && new Date(firestoreToISO(r._created)).toLocaleString().split(",")[0],
    sorter: sortByFirestoreTimestamp("_created"),
  },
];
