import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotFoundPage } from "../../layouts";
import { CreateTaxRate } from "./CreateTaxRate";
import { TaxRateDetails } from "./TaxRateDetails";
import { TaxRatesOverview } from "./TaxRatesOverview";
export * from "./TaxRateDetails";

export const TaxRates: React.FC = () => {
  return (
    <Routes>
      <Route path=":itemId" element={<TaxRateDetails />} />
      <Route path="" element={<TaxRatesOverview />} />
      <Route path="create" element={<CreateTaxRate />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
