import {
  FullRetailBuyerRole,
  FullWholesaleBuyerRole,
  Listing,
  ListingQueryContstraints,
  Location,
  RealProductCategoryKey,
  RelistingGeneric,
  RelistingOption,
  WithId,
} from "@rooted/shared";
import { db, useCollectionDataChecked } from "../firebase";
import { CollectionHookReturn } from "../higher-order-searches";

/**
 * Return shop query results for a given set of constraints.
 *
 * TODO: Move this to the server
 */
export function useListingResults<T extends Listing>(
  role: FullRetailBuyerRole | FullWholesaleBuyerRole,
  category: RealProductCategoryKey,
  constraints: ListingQueryContstraints,
  location: Location | undefined
): CollectionHookReturn<WithId<T>> {
  const isRetail = role.type === "retail-buyer";

  const baseQuery = db
    .collection("listings")
    .orderBy("product.name")
    // Active listings
    .where("availability.active", "==", true)
    // In the right category
    .where("category", "==", category);

  const buyerTypeQuery = isRetail
    ? baseQuery
        .where("categoryAvailableToRetail", "==", true)
        // When retail buyers are enabled
        .where(`seller._cache._retailConnectionCache.orderingEnabled`, "==", true)
    : baseQuery
        // When that category is enabled for wholesale
        .where("categoryAvailableToWholesale", "==", true)
        // When the specific buyer is enabled
        .where(`seller._cache._enabledBuyerIds`, "array-contains", role.profileId);

  return useCollectionDataChecked<WithId<T>>(location ? buyerTypeQuery : null, { idField: "_id" });
}

/**
 * Return shop query results for a given set of constraints.
 *
 * TODO: Move this to the server
 */
export function useRelistingResults<T extends RelistingOption>(
  role: FullRetailBuyerRole | FullWholesaleBuyerRole,
  category: RealProductCategoryKey,
  constraints: ListingQueryContstraints,
  location: Location | undefined
): CollectionHookReturn<WithId<RelistingGeneric<T>>> {
  const isRetail = role.type === "retail-buyer";

  const baseQuery = db
    .collection("relistings")
    .orderBy("product.name")
    // Active listings
    .where("availability.active", "==", true)
    .where("categoryAvailableViaCoops", "==", true)
    .where("relistingMetaData.isActive", "==", true)
    // In the right category
    .where("category", "==", category);

  const buyerTypeQuery = isRetail
    ? baseQuery
        // When that category is enabled for retaill
        .where("categoryAvailableToRetail", "==", true)
        // When retail buyers are enabled
        .where(`seller._cache._retailConnectionCache.orderingEnabled`, "==", true)
    : baseQuery // When that category is enabled for wholesale
        .where("categoryAvailableToWholesale", "==", true)
        // When the specific buyer is enabled
        .where(`seller._cache._enabledBuyerIds`, "array-contains", role.profileId);

  return useCollectionDataChecked<WithId<RelistingGeneric<T>>>(location ? buyerTypeQuery : null, {
    idField: "_id",
  });
}
