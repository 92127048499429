import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Alert, Button, Modal, notification, Spin, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { logError } from "../../../sentry";
import {
  initiateAnonymousPosOrderPayment,
  refreshAnonymousPosOrderPayment,
} from "../../../services/sellers/orders";

export const GuestCheckoutModal: React.FC<{
  visible: boolean;
  orderId: string;
  setVisible: () => void;
}> = ({ orderId, visible, setVisible }) => {
  const [processing, setProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [checkoutError, setCheckoutError] = useState<string | null>(null);
  const [secretLoading, setSecretLoading] = useState(false);

  useEffect(() => {
    if (!visible) return; // Do not fetch if not visible
    setSecretLoading(true);
    // reset these whenever the model becomes visible
    setClientSecret(null);
    setCheckoutError(null);

    initiateAnonymousPosOrderPayment({ orderId })
      .then((data) => setClientSecret(data.client_secret))
      .catch((error) => setCheckoutError(JSON.stringify(error)))
      .finally(() => setSecretLoading(false));
  }, [orderId, visible]);

  const stripe = useStripe();
  const elements = useElements();

  const loading = secretLoading || !stripe || !elements;

  const submit = useCallback(async () => {
    try {
      setProcessing(true);

      if (!clientSecret || !stripe || !elements) {
        throw new Error("stripe / elements not defined");
      }

      const card = elements.getElement(CardElement);
      if (!card) {
        throw new Error("Could not find card");
      }

      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card,
        },
      });

      if (payload.error) {
        throw payload.error;
      }

      await refreshAnonymousPosOrderPayment({ orderId });
      notification.success({ message: "Payment succeeded!" });
      setVisible();
    } catch (error) {
      logError({ error, extraData: { orderId } });
      setCheckoutError(JSON.stringify(error.message));
    } finally {
      setProcessing(false);
    }
  }, [stripe, elements, orderId, setVisible, clientSecret]);

  return (
    <Modal
      visible={visible}
      title="Payment Information"
      onCancel={setVisible}
      footer={
        <>
          <Button type="default" onClick={setVisible}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={submit}
            disabled={loading || !!checkoutError}
            loading={processing}
          >
            Process Payment
          </Button>
        </>
      }
    >
      {checkoutError && (
        <Alert
          type="error"
          message={"Something went wrong:"}
          description={<Typography.Text code>{checkoutError}</Typography.Text>}
          style={{ marginBottom: 12 }}
        />
      )}
      <CardElement options={{ disabled: loading }} />
    </Modal>
  );
};
