import { ProfileType, printDate } from "@rooted/shared";
import { Badge, Table, Tabs } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PageHeader } from "../../../components/PageHeader";
import { useApprovedIncompleteApplications } from "./useApplications";
import { useCount } from "../../../services/higher-order-searches";
import firebase from "firebase/app";

export const ApprovedIncompleteApplications: React.FC = () => {
  const [type, setType] = useState<ProfileType>("grower");
  const [data, dataLoading, dataError] = useApprovedIncompleteApplications(type);

  return (
    <>
      <PageHeader title="Stage 2: Incomplete" breadcrumb />
      <Tabs
        defaultActiveKey="grower"
        //@ts-expect-error Cannot easily coerce string to ProfileType
        onChange={setType}
      >
        <Tabs.TabPane
          tab={
            <>
              Growers&nbsp;
              <Badge count={useCount(useApprovedIncompleteApplications("grower"))} />
            </>
          }
          key="grower"
        />
        <Tabs.TabPane
          tab={
            <>
              Coops&nbsp;
              <Badge count={useCount(useApprovedIncompleteApplications("coop"))} />
            </>
          }
          key="coop"
        />
      </Tabs>
      <Table columns={tableColumns} dataSource={data} pagination={false} />
    </>
  );
};

const tableColumns = [
  {
    title: "Name",
    dataIndex: ["bio", "displayName"],
    key: "businessName",
    render: (text: string) => <b>{text}</b>,
    sorter: (a: any, b: any): any =>
      b.bio.displayName.localeCompare(a.bio.displayName.toLowerCase()),
    sortDirections: ["ascend", "descend"] as any, // SortOrder from antd
  },
  {
    title: "Location",
    dataIndex: ["bio", "location", "streetAddress"],
    key: "name",
    render: (address: any) => (
      <>
        {address?.city}, {address?.state}
      </>
    ),
  },
  {
    title: "Submitted",
    dataIndex: ["_submitted"],
    key: "submitted",
    width: 150,
    render: (submitted: firebase.firestore.Timestamp) => <>{printDate(submitted)}</>,
    sorter: (a: any, b: any): any => a._submitted - b._submitted,
    sortDirections: ["ascend", "descend"] as any, // SortOrder from antd
    defaultSortOrder: "descend" as any, // SortOrder from antd
  },
  {
    title: "Status",
    dataIndex: ["status"],
    key: "name",
    width: 110,
    className: "text-center",
    render: (status: string) => <span style={{ textTransform: "capitalize" }}>{status}</span>,
  },
  {
    title: "",
    key: "action",
    width: 56,
    className: "col-action pad-left-0",
    // TODO this seems overridden by row-click below
    render: (x: any, record: any) => (
      <Link to={`/admin/applications/item/${record._id}`}>More...</Link>
    ),
  },
];
