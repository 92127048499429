import React from "react";
import {
  Connection,
  CoopProfile,
  distance,
  GrowerProfile,
  FullWholesaleBuyerRole,
  SellerProfile,
  WithId,
} from "@rooted/shared";
import { Button, List, Typography, Row } from "antd";
import { PageHeader } from "../../../components/PageHeader";
import { ProfileLink } from "../../../components/ProfileLink/ProfileLink";
import {
  approveConnection,
  requestConnectionToCoop,
  requestConnectionToGrower,
  useAllBuyerConnections,
  useNearbyProfiles,
} from "../../../services/connections";
import { useOldDataOnLoad } from "../../../services/higher-order-searches";
import { CloudFunctionButton } from "../../admins/SiteSettings/ActionsPane";
import { LoadingPage } from "../../layouts";
import "../../layouts/connection-list.less";

export const FindSellersPane: React.FC<{
  role: FullWholesaleBuyerRole;
}> = ({ role }) => {
  const [connections] = useAllBuyerConnections(role);

  const [nearbyCoops] = useOldDataOnLoad(
    useNearbyProfiles<WithId<CoopProfile>>(role.profile.bio.location, "coop")
  );
  const [nearbyGrowers] = useOldDataOnLoad(
    useNearbyProfiles<WithId<GrowerProfile>>(role.profile.bio.location, "grower")
  );

  if (!connections || !nearbyCoops || !nearbyGrowers)
    return (
      <>
        <PageHeader title={"Find Nearby Sellers"} />
        <LoadingPage />
      </>
    );

  return (
    <>
      <PageHeader title={"Find Nearby Sellers"} />
      <List
        bordered
        dataSource={[...nearbyCoops, ...nearbyGrowers]}
        className="connection-list"
        renderItem={(x) => (
          <List.Item
            key={x._id}
            extra={
              <BuyerConnectionButton
                connection={connections.find((c) => c.sellerId === x._id)}
                profile={x}
                role={role}
              />
            }
          >
            <Row>
              <ProfileLink to={x}>{x.bio.displayName}</ProfileLink>
              &nbsp;&nbsp;({distance(role.profile.bio.location, x.bio.location).toFixed(1)} miles
              away)
            </Row>
          </List.Item>
        )}
      />
    </>
  );
};

export const BuyerConnectionButton: React.FC<{
  connection?: WithId<Connection>;
  profile: WithId<SellerProfile>;
  role: FullWholesaleBuyerRole;
}> = ({ connection, profile, role }) => {
  if (!connection)
    return (
      <Button
        type="primary"
        onClick={() =>
          profile.type === "grower"
            ? requestConnectionToGrower(role, profile)
            : requestConnectionToCoop(role, profile)
        }
      >
        Request Connection
      </Button>
    );

  if (connection.status === "approved") return <Typography.Text>Connected</Typography.Text>;

  if (connection.status === "buyer-requested") return <Typography.Text>Requested</Typography.Text>;
  else
    return (
      <CloudFunctionButton type="primary" onClick={() => approveConnection(connection._id)}>
        Approve connection
      </CloudFunctionButton>
    );
};
