import {
  ApplicationForm,
  CoopApplicationDetails,
  CoopApplicationForm,
  EnterpriseProfileBio,
  GrowerApplicationDetails,
  GrowerApplicationForm,
  WholesaleBuyerApplicationDetails,
  WholesaleBuyerApplicationForm,
  WithId,
  RootedUser,
  assertCasesExhausted,
  SubmitApplicationRequest,
  SubmitApplicationResponse,
} from "@rooted/shared";
import { SignedInComplete } from "./account-state";
import { functions, db, snapshotToIdDoc, useCollectionDataChecked } from "./firebase";

import { now } from "../utils/dates";

export const rejectApplication = (application: WithId<ApplicationForm>) => {
  return db.collection("applications").doc(application._id).update({ status: "waitlisted" });
};

export const unwaitlistApplication = (
  application: WithId<ApplicationForm> & { status: "waitlisted" }
) => {
  return db.collection("applications").doc(application._id).update({ status: "in-progress" });
};

export const createProfileFromApplication = (application: WithId<ApplicationForm>) => {
  return functions.httpsCallable("createProfileFromApplication")(application._id);
};

export const approveApplication = async (application: WithId<ApplicationForm>) => {
  const user = snapshotToIdDoc<RootedUser>(
    await db.collection("users").doc(application.ownerId).get()
  );

  if (!user) {
    throw new Error(`No user document found for application owner ${application.ownerId}`);
  }

  return db.collection("applications").doc(application._id).update({ status: "approved" });
};

export const sendApplicationApprovedEmail = async (application: WithId<ApplicationForm>) => {
  const user = snapshotToIdDoc<RootedUser>(
    await db.collection("users").doc(application.ownerId).get()
  );

  if (!user) {
    throw new Error(`No user document found for application owner ${application.ownerId}`);
  }

  const { email } = user;

  // sent approved e-mail, don't wait for it
  if (application.type === "grower" || application.type === "coop") {
    await functions.httpsCallable("sendSellerApplicationApprovedEmail")({ email });
  } else if (application.type === "wholesale-buyer") {
    await functions.httpsCallable("sendBuyerApplicationApprovedEmail")({ email });
  } else {
    assertCasesExhausted(application);
  }
};

export const useMyApplications = (account: SignedInComplete) => {
  return useCollectionDataChecked(
    db.collection("applications").where("ownerId", "==", account.user._id)
  );
};

/** Creates a new empty grower application and returns the id. */
export const newGrowerApplication = async (account: SignedInComplete) =>
  (await functions.httpsCallable("createGrowerApplication")()).data;

/** Creates a new empty wholesale buyer application and returns the id. */
export const newWholesaleBuyerApplication = async (account: SignedInComplete) =>
  (await functions.httpsCallable("createWholesaleBuyerApplication")()).data;

/** Creates a new empty coop application and returns the id. */
export const newCoopApplication = async (account: SignedInComplete) =>
  (await functions.httpsCallable("createCoopApplication")()).data;

/** Requests a retail buyer profile and returns the id. */
export const requestRetailBuyerProfile = (account: SignedInComplete) => {
  return functions.httpsCallable("requestRetailBuyerProfile")(account.user._id);
};

export const setApplicationBio = async (
  application: WithId<ApplicationForm>,
  bio: EnterpriseProfileBio
) => {
  await db.collection("applications").doc(application._id).update({ bio });
};

export const setGrowerApplicationDetails = async (
  application: WithId<GrowerApplicationForm>,
  details: GrowerApplicationDetails
) => {
  await db.collection("applications").doc(application._id).update({ details });
};

export const setWholesaleBuyerApplicationDetails = async (
  application: WithId<WholesaleBuyerApplicationForm>,
  details: WholesaleBuyerApplicationDetails
) => {
  await db.collection("applications").doc(application._id).update({ details });
};

export const setCoopApplicationDetails = async (
  application: WithId<CoopApplicationForm>,
  details: CoopApplicationDetails
) => {
  await db.collection("applications").doc(application._id).update({ details });
};

export const setApplicationSubscription = async (
  application: WithId<GrowerApplicationForm | CoopApplicationForm>,
  key: string
) => {
  await db.collection("applications").doc(application._id).update({ "subscription.key": key });
};

export const setApplicationCreditCard = async (
  application: WithId<GrowerApplicationForm | CoopApplicationForm>,
  creditCardId: string
) => {
  await db.collection("applications").doc(application._id).update({ creditCardId });
};

export const submitApplication = async (
  params: SubmitApplicationRequest
): Promise<SubmitApplicationResponse> => {
  const { data } = await functions.httpsCallable("submitApplication")(params);
  return data;
};

export const sendApplicationReceivedEmail = async () => {
  await functions.httpsCallable("sendApplicationReceivedEmail")();
};

export const setSubmitted = async (application: WithId<ApplicationForm>) => {
  await db.collection("applications").doc(application._id).update({ _submitted: now() });
};
