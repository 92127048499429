import { SubscriptionPlan, WithId } from "@rooted/shared";
import { Card } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { FirestoreDocForm } from "../../../../components/FormControls/FirestoreDocForm";
import { useRouterBreadcrumb } from "../../../../components/Misc/useRouterBreadcrumb";
import { PageHeader } from "../../../../components/PageHeader";
import { db, useDocumentDataChecked } from "../../../../services/firebase";
import { LoadingPage } from "../../../layouts";
import { PlanFormItems } from "./PlanFormItems";

export const EditPlan: React.FC = () => {
  const { planId } = useParams();

  const [plan, planLoading] = useDocumentDataChecked<WithId<SubscriptionPlan>>(
    db.collection("subscriptionPlans").doc(planId),
    { idField: "_id" }
  );
  const breadcrumb = useRouterBreadcrumb({ [planId]: plan ? plan.name : "..." });

  return (
    <>
      <PageHeader title={plan ? plan.name : "..."} breadcrumb={breadcrumb} />
      {plan ? (
        <Card>
          <FirestoreDocForm
            requiredMark="optional"
            dbRef={db.collection("subscriptionPlans").doc(plan._id)}
            data={plan}
          >
            <PlanFormItems />
          </FirestoreDocForm>
        </Card>
      ) : (
        <LoadingPage />
      )}
    </>
  );
};
