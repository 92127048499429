import React from "react";
import { NotFoundPage } from "../../views/layouts";
import { ConfirmPasswordResetForm } from "./ConfirmPasswordResetForm";

export const AuthAction: React.FC = () => {
  //URL parameters passed by firebase in magic link
  const params = new URL(document.location.toString()).searchParams;
  const oobCode = params.get("oobCode");
  const mode = params.get("mode");

  if (mode === "resetPassword" && oobCode && typeof oobCode === "string") {
    return <ConfirmPasswordResetForm oobCode={oobCode} />;
  }

  // default NotFound
  return <NotFoundPage />;
};
