import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Table } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PageHeader } from "../../../components/PageHeader";
import { useTaxRateResults } from "../../../services/admins/tax-rates";
import { PageContent, WideLayout } from "../../layouts";

const tableColumns = [
  {
    title: "Name",
    ellipsis: true,
    dataIndex: "name",
    key: "name",
    sorter: (a: any, b: any): any => b.name.localeCompare(a.name.toUpperCase()),
    sortDirections: ["descend"] as any, // SortOrder from antd
  },

  {
    title: "Zip Codes",
    dataIndex: "zipCodes",
    key: "zipCodes",
    render: (x: string[]) => (
      <em>
        {x.slice(0, 3).join(", ")} {x.length > 3 && "..."}
      </em>
    ),
    sorter: (a: any, b: any): any => b.zipCodes[0].localeCompare(a.zipCodes[0]),
    sortDirections: ["descend"] as any, // SortOrder from antd
  },
  {
    width: 120,
    ellipsis: true,
    title: "Rate",
    dataIndex: "rate",
    key: "rate",
    render: (x: number) => <>{x}%</>,
    sorter: (a: any, b: any): any => b.stemsPerBunch - a.stemsPerBunch,
    sortDirections: ["descend"] as any, // SortOrder from antd
  },
  {
    width: 120,
    title: "Active",
    dataIndex: "active",
    key: "active",
    render: (x: boolean) => (
      <>{x ? <Checkbox checked disabled /> : <Checkbox checked={false} disabled />}</>
    ),
  },
  {
    title: "",
    key: "action",
    render: (x: any, record: any) => (
      <Link to={record._id}>
        Edit <ArrowRightOutlined />
      </Link>
    ),
  },
];

export const TaxRatesOverview: React.FC = () => {
  const [search, setSearch] = useState("");

  const [data, dataLoading, dataError] = useTaxRateResults(search, 9999);

  return (
    <WideLayout>
      <PageContent>
        <PageHeader
          title="Tax Rates"
          breadcrumb
          extra={
            <Link to="create">
              <Button type="primary">Add Tax Rates</Button>
            </Link>
          }
        />
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search by name or complete zip"
          style={{ marginBottom: 25 }}
        />
        <Table dataSource={data} columns={tableColumns} />
      </PageContent>
    </WideLayout>
  );
};
