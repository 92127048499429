import { Button, Alert, Form, Input, Typography, Row, Col } from "antd";
import React, { useState } from "react";
import { resetPassword } from "../../services/account-state";
import "./AuthForm.less";
import { WideLayout, PageContent } from "../layouts";
import { Logo } from "./LoginForm";
import { LinkWithQueryParams } from "../../utils/navigateWithQueryParams";

export const ResetPasswordForm: React.FC = () => {
  const [error, setError] = useState("");
  const [status, setStatus] = useState<"initial" | "loading" | "sent">("initial");

  const onFinish = async (formValues: any) => {
    setError("");
    setStatus("loading");
    try {
      await resetPassword(formValues.email);
      setStatus("sent");
    } catch (e) {
      setError(e.message);
      setStatus("initial");
    }
  };

  return (
    <WideLayout>
      <PageContent className="page-content-center">
        <Logo />
        <Row align="middle" justify="center" className="login-title">
          <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 10 }} xl={{ span: 10 }}>
            <Typography.Title level={3} style={{ textAlign: "center" }}>
              Reset Password
            </Typography.Title>

            <Typography.Text>
              Enter your e-mail below to receive a link to reset your password
            </Typography.Text>

            <Form
              name="password-reset"
              className="auth-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
            >
              {error && (
                <Form.Item>
                  <Alert message={error} type="error" />
                </Form.Item>
              )}
              <Form.Item
                name="email"
                rules={[{ required: true, message: "Please enter your email." }]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="auth-form-button"
                  loading={status === "loading"}
                  block
                >
                  Send Me a Link
                </Button>
              </Form.Item>
              {status === "sent" && (
                <Form.Item>
                  <Alert
                    message={"Password reset email sent. Check your email for a link."}
                    type="success"
                  />
                </Form.Item>
              )}
              <div style={{ textAlign: "center" }}>
                <LinkWithQueryParams to="/accounts/login">Login</LinkWithQueryParams> or{" "}
                <LinkWithQueryParams to="/accounts/create-account">
                  Create New Account
                </LinkWithQueryParams>
              </div>
            </Form>
          </Col>
        </Row>
      </PageContent>
    </WideLayout>
  );
};
