import {
  FulfillmentGroupItem,
  calculateItemPrice,
  BuyerRole,
  Cart,
  WithId,
  FullBuyerRole,
  FulfillmentGroup,
} from "@rooted/shared";
import { ColumnType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import { Typography } from "antd";
import React from "react";
import { formatCurrency } from "../FormControls/CurrencyInput";
import { Link } from "react-router-dom";
import { BuyerListingPriceWidget } from "../Widgets/BuyerListings/PriceWidget";
import { CartQuantityWidget } from "../Widgets/CartItems/QuantityWidget";
import { ProductThumbnail } from "../Images/ProductThumbnail";

export const cartPhotoColumn: ColumnType<FulfillmentGroupItem> = {
  title: "",
  dataIndex: ["listing", "photoStorageUrl"],
  key: "photo",
  width: 80,
  render: (_, row) => <ProductThumbnail product={row.listing.product} />,
};

export const cartNameColumn: ColumnType<FulfillmentGroupItem> = {
  title: "Name",
  ellipsis: true,
  // fixed: "right",
  key: "name",
  render: (_: any, record) => (
    <>
      <Link to={`/listings/${record.listing._id}`}>{record.listing.product.name}</Link> <br />
      {record.listing.category === "cut-flower" ? (
        record.listing.product._prototypeCache.commonNames.join("").trim() !== "" ? (
          <>
            {record.listing.product._prototypeCache.commonNames.join(", ")}
            <br />
          </>
        ) : (
          <></>
        )
      ) : (
        <>
          <Typography.Text type="secondary">{record.listing.product.description}</Typography.Text>
          <br />
        </>
      )}
    </>
  ),
  sorter: (a, b) => b.listing.product.name.localeCompare(a.listing.product.name.toLowerCase()),
  sortDirections: ["descend", "ascend"] as SortOrder[], // SortOrder from antd,
};

export function makeCartPriceColumn(
  role: FullBuyerRole,
  cart: WithId<Cart>
): ColumnType<FulfillmentGroupItem> {
  return {
    title: "Price",
    width: 140,
    dataIndex: ["listing", "price"],
    render: (_, x) => <BuyerListingPriceWidget listing={x.listing} twoLine />,
  };
}

export function makeCartQuantityColumn(
  role: FullBuyerRole,
  cart: WithId<Cart>,
  group: FulfillmentGroup
): ColumnType<FulfillmentGroupItem> {
  return {
    title: "Quantity",
    dataIndex: ["quantity"],
    key: "quantity",
    width: 110,
    render: (qty: number, item) => (
      <CartQuantityWidget role={role} cart={cart} group={group} item={item} />
    ),
  };
}

export function makeCartTotalColumn(role: BuyerRole): ColumnType<FulfillmentGroupItem> {
  return {
    title: "Total",
    width: 100,
    render: (text: string, record) => (
      <>{formatCurrency(calculateItemPrice(record, role.type === "retail-buyer"))}</>
    ),
  };
}
