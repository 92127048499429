import {
  FullBuyerRole,
  WithId,
  Cart,
  FulfillmentGroup,
  FulfillmentGroupItem,
  stringifyQuantity,
  RealListing,
  Relisting,
} from "@rooted/shared";
import React, { useState, useEffect, useRef } from "react";
import { Popover, Space, InputNumber, Button, Alert, notification } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { updateInCart } from "../../../services/buyers/active-cart";
import { db, useDocumentDataChecked } from "../../../services/firebase";
import { logError } from "../../../sentry";

export const CartQuantityWidget: React.FC<{
  role: FullBuyerRole;
  cart: WithId<Cart>;
  group: FulfillmentGroup;
  item: FulfillmentGroupItem;
}> = ({ role, group, cart, item }) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [quantity, setQuantity] = useState(item.quantity);

  // This can be a listing or relisting: check both.
  const [_listing, listingLoading] = useDocumentDataChecked<WithId<RealListing>>(
    db.collection("listings").doc(item.listing._id),
    { idField: "_id" }
  );
  const [relisting, relistingLoading] = useDocumentDataChecked<WithId<Relisting>>(
    db.collection("relistings").doc(item.listing._id),
    { idField: "_id" }
  );
  const listing = relisting || _listing;

  const loading = listingLoading || relistingLoading;

  const hasAttemptedToDelete = useRef(false);
  const cartIdForDebugLog = useRef(cart._id);
  // If this component sees that the item has been deleted, attempt to
  // remove it from the cart. If this fails, this will also occur when a change
  // occurs to the quantity. (at the `submit`)
  useEffect(() => {
    if (!listing && !loading && !hasAttemptedToDelete.current) {
      hasAttemptedToDelete.current = true;
      const attemptToDelete = async () => {
        try {
          await updateInCart(role, group.fulfillment, item.listing, 0);
          if (!listing)
            notification.error({
              message: `Oops! Looks like ${item.listing.product.name} is unavailable.`,
            });
        } catch (error) {
          // This is a once-running, best effort attempt, so we ignore this error.
          logError({
            error,
            tags: { page: "cart" },
            extraData: {
              item,
              role,
              cartId: cartIdForDebugLog.current,
            },
          });
        }
      };
      attemptToDelete();
    }
  }, [group.fulfillment, item, listing, loading, role]);

  const submit = async () => {
    setSubmitting(true);
    try {
      await updateInCart(role, group.fulfillment, item.listing, quantity);
      if (!listing)
        notification.error({
          message: `Oops! Looks like ${item.listing.product.name} is unavailable.`,
        });
      setSubmitting(false);
    } catch (e) {
      setError(e.message);
    }
    setOpen(false);
  };

  // If item has been deleted, prevent adding _more_ of the item.
  const amountAvailable = listing ? listing.availability.quantity : 0;

  return (
    <>
      <Popover
        content={
          !loading ? (
            <>
              {error && (
                <>
                  <Alert message={error} />
                  <br />
                </>
              )}
              <Space size={4}>
                <InputNumber
                  // Force int only
                  parser={(value) => (value ? parseInt(value) || 0 : 0)}
                  min={0}
                  max={item.quantity + amountAvailable}
                  value={quantity}
                  onChange={(e) => setQuantity(e as number)}
                />
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          ) : (
            "..."
          )
        }
        title="Change quantity"
        trigger="click"
        visible={open}
        onVisibleChange={setOpen}
        placement="bottomRight"
      >
        {stringifyQuantity(item)}
        <EditOutlined />
      </Popover>
    </>
  );
};
