import { WithId, OrderItem, stringifyQuantity, ChildOrder } from "@rooted/shared";
import { Button, InputNumber, Popover, Space, Typography } from "antd";
import React, { useState } from "react";
import { changeItemQuantity } from "../../../services/sellers/orders";
import { EditOutlined } from "@ant-design/icons";

export const ChildOrderQuantityWidget: React.FC<{
  order: WithId<ChildOrder>;
  item: OrderItem;
  editable: boolean;
}> = ({ order, item, editable }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [quantity, setQuantity] = useState(item.quantity);

  const submit = async () => {
    setSubmitting(true);
    if (!(order.status === "active" || order.status === "guest-checkout"))
      throw new Error("Order is not active.");
    await changeItemQuantity(order, item, quantity);
    setSubmitting(false);
    setOpen(false);
  };

  return (
    <>
      {item.quantity !== item.initialValue.quantity && (
        <Typography.Text delete type="secondary">
          &nbsp;{item.initialValue.quantity}&nbsp;
        </Typography.Text>
      )}
      {stringifyQuantity(item)}

      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <InputNumber
                  // Force int only
                  parser={(value) => (value ? parseInt(value) || 0 : 0)}
                  min={0}
                  max={100000}
                  value={quantity}
                  onChange={(e) => setQuantity(e as number)}
                />{" "}
                <br />
                <Button type="primary" onClick={submit} loading={submitting}>
                  Update
                </Button>
              </Space>
            </>
          }
          title="Change quantity"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};
