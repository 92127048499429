import { realProductCategories, RealProductCategoryKey } from "@rooted/shared";

/**
 * Get an object of breadcrumb replacements for the given category by key
 */
export function getCategorySlugBreadcrumbReplacement(key?: RealProductCategoryKey) {
  if (!key) return {};
  const category = realProductCategories[key];

  if (!category) return {};
  return { [category.slug]: category.pluralName };
}
