import { RootedUser } from "@rooted/shared";
import { Button, Form, Input, Typography, Row, Col, notification } from "antd";
import React, { useState } from "react";
import { AdaptedDatePicker } from "../../components/FormControls/AdaptedDatePicker";
import { SignedIn, completeAccount } from "../../services/account-state";
import { WideLayout, PageContent } from "../layouts";
import { Logo } from "./LoginForm";
import { logError } from "../../sentry";

export const CompleteAccountForm: React.FC<{ account: SignedIn }> = ({ account }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: Partial<RootedUser>) => {
    setLoading(true);
    try {
      await completeAccount(account.authUser, values);
      // Do not perform navigation here. If this call succeeds, the user will automatically
      // be taken to the onboarding page
    } catch (error) {
      notification.error({ message: "Oops! Something went wrong setting up your account." });
      logError({
        error,
        extraData: {
          values,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <WideLayout>
      <PageContent className="page-content-center">
        <Logo />
        <Row align="middle" justify="center" className="login-title">
          <Col xs={{ span: 24 }} sm={{ span: 16 }} md={{ span: 10 }} xl={{ span: 10 }}>
            {account.user && (
              <>
                <Typography.Title level={3} style={{ textAlign: "center" }}>
                  Welcome Back!
                </Typography.Title>
                <Typography.Text style={{ paddingBottom: "15px" }}>
                  <p>Confirm your account details look correct</p>
                </Typography.Text>
              </>
            )}

            {!account.user && (
              <>
                <Typography.Title level={3} style={{ textAlign: "center" }}>
                  Account Setup
                </Typography.Title>
              </>
            )}

            <Form
              requiredMark="optional"
              layout="vertical"
              onFinish={onFinish}
              initialValues={account.user}
              className="auth-form"
            >
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    name="firstName"
                    label="Name"
                    requiredMark
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="First Name"></Input>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastName"
                    label=" "
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Input placeholder="Last Name"></Input>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="birthDate" label="Birth Date">
                <AdaptedDatePicker />
              </Form.Item>
              <Form.Item name="pronouns" label="Pronouns">
                <Input placeholder="He/him/his"></Input>
              </Form.Item>

              <Form.Item name="zip" label="Zip Code">
                <Input></Input>
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit" type="primary" loading={loading} block>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </PageContent>
    </WideLayout>
  );
};
