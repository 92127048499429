import { Button, List, Typography, Row } from "antd";
import React from "react";
import { SignedInComplete } from "../../../services/account-state";
import { PageHeader } from "../../../components/PageHeader";
import { RoleName } from "../../../components/Navbar";
import { useRooted } from "../../../RootedContext";
import { EnterpriseAvatar } from "../../../components/Images/ThumbnailAvatar";

export const OrganizationsPane: React.FC<{
  account: SignedInComplete;
}> = ({ account }) => {
  const { activeRole, setActiveRoleId } = useRooted();
  return (
    <>
      <PageHeader title="Organizations" breadcrumb />
      <List
        bordered
        dataSource={account.roles}
        rowKey={(role) => role._id}
        renderItem={(role) => (
          <List.Item
            className={"hover-highlight"}
            actions={
              activeRole?._id === role._id
                ? [
                    <Typography.Text key="active-role" type="secondary" style={{ marginRight: 16 }}>
                      Active Role
                    </Typography.Text>,
                  ]
                : [
                    <Button
                      key="set-active-role"
                      type="link"
                      onClick={async () => {
                        await setActiveRoleId(role._id);
                        // Reloading here is a hack because the select in the navbar doesn't update otherwise
                        // TODO determine why this is and fix it
                        // That said, the momentary refresh / loading indicator provides a good reminder
                        // to the user that something major has changed

                        // eslint-disable-next-line no-restricted-globals
                        location.reload();
                      }}
                    >
                      Switch to Role
                    </Button>,
                  ]
            }
          >
            <Row style={{ alignItems: "center" }}>
              <EnterpriseAvatar profile={role.profile} style={{ marginRight: 12 }} />
              <RoleName role={role} />
            </Row>
          </List.Item>
        )}
      />
    </>
  );
};
