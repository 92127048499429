import { prependHttps } from "@rooted/shared";
import { Space, Typography } from "antd";
import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";
import React from "react";

export const ProfileSocialLinks: React.FC<{ facebookUrl?: string; instagramUrl?: string }> = ({
  facebookUrl,
  instagramUrl,
}) => {
  if (!facebookUrl && !instagramUrl) return null;
  return (
    <Space direction="horizontal" style={{ fontSize: 24 }}>
      {facebookUrl && facebookUrl.length > 3 && (
        <Typography.Link href={prependHttps(facebookUrl)} target="_blank">
          <FacebookOutlined alt="Facebook Logo" />
        </Typography.Link>
      )}
      {instagramUrl && instagramUrl.length > 3 && (
        <Typography.Link href={prependHttps(instagramUrl)} target="_blank">
          <InstagramOutlined alt="Instagram Logo" />
        </Typography.Link>
      )}
    </Space>
  );
};
