import { CoopApplicationForm, WithId } from "@rooted/shared";
import { Checkbox, Divider, Form, Input, InputNumber, Radio } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FirestoreDocForm } from "../../../components/FormControls/FirestoreDocForm";
import { HowDidYouHear } from "../../../components/FormControls/HowDidYouHear";
import { submitApplication } from "../../../services/applications";
import { db } from "../../../services/firebase";

export const CoopDetailsForm: React.FC<{
  application: WithId<CoopApplicationForm>;
  disabled: boolean;
  adminView?: boolean;
}> = ({ application, disabled = false, adminView }) => {
  const navigate = useNavigate();
  return (
    <FirestoreDocForm
      layout="vertical"
      buttonText="Submit"
      onStatusChange={(x) => {
        if (x === "submitted") {
          navigate(`/onboarding/applications/${application._id}/review`);
        }
      }}
      onFinish={async (values: any) => {
        await submitApplication({ applicationId: application._id });
      }}
      rootFieldPath="details"
      data={application.details}
      dbRef={db.collection("applications").doc(application._id)}
    >
      <CoopDetailsFormItems disabled={disabled} adminView={adminView} />
    </FirestoreDocForm>
  );
};

export const CoopDetailsFormItems: React.FC<{ disabled?: boolean; adminView?: boolean }> = ({
  disabled = false,
  adminView,
}) => (
  <>
    <ol style={{ paddingLeft: 25 }}>
      <li>
        <Form.Item
          name="farmsCount"
          label="How many farms participate in your exchange?"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <InputNumber min={0} max={500} disabled={disabled} />
        </Form.Item>
      </li>
      <li>
        <Form.Item
          name="yearsSellingWholesale"
          label="How many years have you been selling to florists or wholesalers?"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <InputNumber min={0} max={500} disabled={disabled} />
        </Form.Item>
      </li>
      <li>
        <Form.Item label="When is your coop/exchange in operation?">
          <Form.Item
            name={["monthsGrowing", "january"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>January</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "february"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>February</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "march"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>March</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "april"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>April</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "may"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>May</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "june"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>June</Checkbox>
          </Form.Item>
          <br />
          <Form.Item
            name={["monthsGrowing", "july"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>July</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "august"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>August</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "september"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>September</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "october"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>October</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "november"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>November</Checkbox>
          </Form.Item>
          <Form.Item
            name={["monthsGrowing", "december"]}
            valuePropName="checked"
            initialValue={false}
            noStyle
          >
            <Checkbox disabled={disabled}>December</Checkbox>
          </Form.Item>
        </Form.Item>
      </li>
      <li>
        <Form.Item
          label="Do you take a percentage of your growers' sales?"
          name="takesPercentageOfSales"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <Radio.Group disabled={disabled} buttonStyle="solid">
            <Radio.Button disabled={disabled} value={true}>
              Yes
            </Radio.Button>
            <Radio.Button disabled={disabled} value={false}>
              No
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </li>
      <li>
        <Form.Item
          label="Who do your buyers pay upon delivery?"
          name="buyersPayCoop"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <Radio.Group disabled={disabled} buttonStyle="solid">
            <Radio.Button disabled={disabled} value={true}>
              The coop
            </Radio.Button>
            <Radio.Button disabled={disabled} value={false}>
              The farm
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </li>
      <li>
        <Form.Item
          label="Do you provide farm-level transparency to your buyers when they are placing orders?"
          name="farmLevelTransparency"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <Radio.Group disabled={disabled} buttonStyle="solid">
            <Radio.Button disabled={disabled} value={true}>
              Yes
            </Radio.Button>
            <Radio.Button disabled={disabled} value={false}>
              No
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </li>
      <li>
        <Form.Item
          label="Who determines which farm will receive a given order?"
          name="buyerDeterminesFarm"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <Radio.Group disabled={disabled} buttonStyle="solid">
            <Radio.Button disabled={disabled} value={false}>
              The coop
            </Radio.Button>
            <Radio.Button disabled={disabled} value={true}>
              The buyer
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </li>
      <li>
        <Form.Item
          label="Do you set prices for your farms?	"
          name="setsPrices"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button disabled={disabled} value={true}>
              Yes
            </Radio.Button>
            <Radio.Button disabled={disabled} value={false}>
              No
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </li>
      <li>
        <Form.Item
          label="Do you allow your growers to maintain sales relationships outside of your exchange?"
          name="allowOutsideGrowerSales"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button disabled={disabled} value={true}>
              Yes
            </Radio.Button>
            <Radio.Button disabled={disabled} value={false}>
              No
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      </li>
      <li>
        <Form.Item
          name="howExpectationsSet"
          label="Please share how you set quality/stage of harvest/stem-length expectations with your growers:"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <Input.TextArea disabled={disabled} rows={4} />
        </Form.Item>
      </li>
      <li>
        <Form.Item
          name="currentChallenges"
          label="Are there any specific challenges that your coop/exchange has faced that you are working to mitigate going forward?"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <Input.TextArea disabled={disabled} rows={4} />
        </Form.Item>
      </li>
      <li>
        <Form.Item
          name="anythingElse"
          label="Is there anything else that you feel we should know about your model or process?"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <Input.TextArea disabled={disabled} rows={4} />
        </Form.Item>
      </li>
      <li>
        <HowDidYouHear disabled={disabled} adminView={adminView} />
      </li>
    </ol>
    <br />
    <Divider>Legal</Divider>
    <Form.Item
      name="signedAgreement"
      label={
        <>
          Please confirm you have read the&nbsp;
          <a href="https://www.rootedfarmers.com/terms" target="_blank" rel="noreferrer">
            Terms of Service.
          </a>
        </>
      }
      valuePropName="checked"
      rules={[
        {
          required: true,
          message: "Please accept the Terms of Service agreement",
          validator: (_, value) =>
            value
              ? Promise.resolve()
              : Promise.reject("Please accept the Terms of Service agreement"),
        },
      ]}
    >
      <Checkbox disabled={disabled}>I have read and agree to the Terms of Service</Checkbox>
    </Form.Item>
  </>
);
