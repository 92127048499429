import React, { useCallback, useState, useMemo } from "react";
import { EnterpriseProfile, RequestedConnection, WithId } from "@rooted/shared";
import { Button, List, Space, Col, Modal, notification } from "antd";
import { ConnectionWithProfile } from "./connectionWithProfile";
import { ProfileConnectionBlurb } from "./ProfileConnectionBlurb";
import { approveConnection, removeConnection } from "../../services/connections";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { logError } from "../../sentry";

const LIMIT = 1;

type RequestRelationship = "to-client" | "from-client";
export const RequestedConnectionList: React.FC<{
  dataSource: ConnectionWithProfile<RequestedConnection>[];
  onCancelRequest: (id: string) => void;
  onApproveRequest?: (id: string) => void;
  // these cases are basically identical, so it's easy to just pass this prop to manage them
  requestRelationship: RequestRelationship;
}> = ({ dataSource, onCancelRequest, onApproveRequest, requestRelationship }) => {
  const [showAll, setShowAll] = useState(false);
  const limitSource = useMemo(() => (showAll ? dataSource : dataSource.slice(0, LIMIT)), [
    dataSource,
    showAll,
  ]);

  return (
    <List
      style={{ marginTop: 4 }}
      bordered
      loadMore={
        !showAll &&
        dataSource.length > LIMIT && (
          <div
            style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 12 }}
          >
            <Button
              type="primary"
              onClick={() => void setShowAll(true)}
            >{`Show All (${dataSource.length})`}</Button>
          </div>
        )
      }
      dataSource={limitSource}
      renderItem={({ profile, connection }) => (
        <ConnectionRequestCard
          requestRelationship={requestRelationship}
          profile={profile}
          connection={connection}
          onApproveRequest={onApproveRequest}
          onCancelRequest={onCancelRequest}
        />
      )}
      rowKey={({ connection }) => connection._id}
    />
  );
};

const ConnectionRequestCard: React.FC<{
  profile: WithId<EnterpriseProfile>;
  connection: WithId<RequestedConnection>;
  onCancelRequest: (id: string) => void;
  onApproveRequest?: (id: string) => void;
  requestRelationship: RequestRelationship;
}> = ({ profile, connection, onCancelRequest, onApproveRequest, requestRelationship }) => {
  const [approving, setApproving] = useState(false);
  const [canceling, setCanceling] = useState(false);

  const cancelConnectionRequest = useCallback(async () => {
    const cancelTitle =
      requestRelationship === "to-client" ? "Decline Connection" : "Remove Connection Request"; // This copy is to avoid having the destructive action called "Cancel" also
    Modal.confirm({
      type: "warning",
      title: cancelTitle,
      content: `Are you sure you want to ${
        requestRelationship === "to-client" ? "decline the request from " : "cancel your request to"
      } '${profile.bio.displayName}'?`,
      okType: "danger",
      okText: cancelTitle,
      onOk: async () => {
        setCanceling(true);
        try {
          await removeConnection(connection._id);
          onCancelRequest(connection._id);
        } catch (error) {
          logError({ error, extraData: { connection, profile } });
          notification.error({ message: "Oops! Something went wrong removing that connection." });
        }
        setCanceling(false);
      },
    });
  }, [connection, onCancelRequest, profile, requestRelationship]);

  const approveConnectionRequest = useCallback(async () => {
    setApproving(true);
    try {
      await approveConnection(connection._id);
      onApproveRequest?.(connection._id);
    } catch (error) {
      logError({ error, extraData: { connection, profile } });
      notification.error({
        message: "Oops! Something went wrong accepting that connection request.",
      });
    }
    setApproving(false);
  }, [connection, onApproveRequest, profile]);

  const { md } = useBreakpoint();

  return (
    <List.Item className={"hover-highlight"}>
      <ProfileConnectionBlurb
        style={{ width: "100%" }}
        profile={profile}
        connection={connection}
        extra={
          <Col>
            <Space direction={md ? "horizontal" : "vertical"} style={{ alignItems: "flex-end" }}>
              <Button
                loading={canceling}
                disabled={approving}
                onClick={cancelConnectionRequest}
                danger
                type="ghost"
              >
                {requestRelationship === "from-client" ? "Cancel Request" : "Decline"}
              </Button>
              {requestRelationship === "to-client" && (
                <Button
                  loading={approving}
                  disabled={canceling}
                  onClick={approveConnectionRequest}
                  type="primary"
                >
                  Approve
                </Button>
              )}
            </Space>
          </Col>
        }
      />
    </List.Item>
  );
};
