import { GrowerProfile, FullWholesaleBuyerRole, WithId } from "@rooted/shared";
import { Alert, Button } from "antd";
import React from "react";
import {
  approveConnection,
  requestConnectionToGrower,
  useConnection,
} from "../../../services/connections";
import { CloudFunctionButton } from "../../admins/SiteSettings/ActionsPane";

/**
 * Shows the connection status between two entities on a profile page.
 */
export const GrowerProfileConnectionStatus: React.FC<{
  role: FullWholesaleBuyerRole;
  grower: WithId<GrowerProfile>;
}> = ({ role, grower }) => {
  const [connection, connectionLoading, connectionError] = useConnection(
    role.profileId,
    grower._id
  );

  if (connectionLoading) return null;

  if (!connection)
    return (
      <Alert
        type="info"
        style={{ marginBottom: 16 }}
        message={
          <>
            You're not connected with {grower.bio.displayName}.
            <Button
              type="primary"
              style={{ float: "right" }}
              onClick={() => requestConnectionToGrower(role, grower)}
            >
              Request Connection
            </Button>{" "}
          </>
        }
        description="Connect with them to view their inventory and place orders."
      />
    );

  if (connection.status === "approved")
    return (
      <Alert
        type="info"
        style={{ marginBottom: 16 }}
        message={<>You're connected with {grower.bio.displayName}.</>}
        description="You can see their inventory and place orders"
      />
    );

  if (connection.status === "buyer-requested")
    return (
      <Alert
        type="info"
        style={{ marginBottom: 16 }}
        message={<>Your request to {grower.bio.displayName} is pending.</>}
        description="Once they accept, you'll be allowed to see their inventory and place orders."
      />
    );
  else
    return (
      <Alert
        type="info"
        style={{ marginBottom: 16 }}
        message={
          <>
            {grower.bio.displayName} wants to connect with you.
            <CloudFunctionButton
              type="primary"
              style={{ float: "right", backgroundColor: "white", color: "red" }}
              onClick={() => approveConnection(connection._id)}
            >
              Accept request
            </CloudFunctionButton>{" "}
          </>
        }
        description="Accept their request to see their inventory and place orders.."
      />
    );
};
