import React from "react";
import { RealRelisting, Relisting } from "@rooted/shared";
import { ProfileLink } from "../ProfileLink/ProfileLink";

/**
 * For a coop's admin view of seeing the original seller on a relisted flower
 */
export const OriginalGrowerColumn = {
  title: "Grower",
  key: "original-grower",
  width: 140,
  render: (_: any, relisting: RealRelisting) => <OriginalGrowerLink relisting={relisting} />,
  sorter: (a: RealRelisting, b: RealRelisting) =>
    b.backingListing.seller._cache.bio.displayName.localeCompare(
      a.backingListing.seller._cache.bio.displayName
    ),
  sortDirections: ["descend", "ascend"] as any, // SortOrder from antd
};

export const OriginalGrowerLink: React.FC<{ relisting: RealRelisting }> = ({
  relisting: {
    backingListing: { seller },
  },
}) => (
  <ProfileLink
    to={{
      ...seller._cache,
      _id: seller.profileId,
    }}
  >
    {seller._cache.bio.displayName}
  </ProfileLink>
);
