import { encodeGeoHash, removeUndefined, SavedLocation, StreetAddress } from "@rooted/shared";
import { Alert, Form, Input } from "antd";
import firebase from "firebase/app";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { geocodeAddress } from "../../services/geocode";
import { StateSelector } from "./StateSelector";

export const AddressInput: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  return (
    <>
      <Form.Item
        name={["location", "streetAddress", "line1"]}
        rules={[{ required: true, message: " " }]}
      >
        <Input placeholder="Line one" disabled={disabled} />
      </Form.Item>
      <Form.Item name={["location", "streetAddress", "line2"]} initialValue="">
        <Input placeholder="Line two" disabled={disabled} />
      </Form.Item>
      <Input.Group compact>
        <Form.Item
          name={["location", "streetAddress", "city"]}
          rules={[{ required: true, message: " " }]}
          noStyle
        >
          <Input placeholder="City" style={{ width: "50%" }} disabled={disabled} />
        </Form.Item>
        <Form.Item
          name={["location", "streetAddress", "state"]}
          rules={[{ required: true, message: " " }]}
          noStyle
        >
          <StateSelector disabled={disabled} />
        </Form.Item>
        <Form.Item
          name={["location", "streetAddress", "zip"]}
          rules={[{ required: true, message: " " }]}
          noStyle
        >
          <Input placeholder="Zip" style={{ width: "30%" }} disabled={disabled} />
        </Form.Item>
      </Input.Group>
      <br />
      <Form.Item
        name={["location", "streetAddress", "country"]}
        rules={[{ required: true, message: " " }]}
      >
        <Input placeholder="Country" disabled={disabled} />
      </Form.Item>
    </>
  );
};

/** Location input for use in a form. Only validates if the address can be geocoded */
export const LocationInput: React.FC<{
  value?: SavedLocation | undefined;
  onChange?: (x: SavedLocation | undefined) => void;
  disabled?: boolean;
}> = ({ value, onChange, disabled }) => {
  const [internalState, setInternalState] = useState<Partial<StreetAddress>>({});
  const [updatedOnce, setUpdatedOnce] = useState(false);

  const [status, setStatus] = useState<"incomplete" | "validating" | "valid" | "invalid">(
    "incomplete"
  );

  const debouncedQuery = useRef(_.debounce((q) => geoCodeAddress(q), 500)).current;

  // Take a value from the value prop, but only once.
  // This lets us get data from the form (like in a prepopulated FirestoreDocForm)
  // but avoids messy race conditions.
  // TODO do this in a more elegant way
  useEffect(() => {
    if (value && !updatedOnce) {
      setInternalState(value.streetAddress);
      setStatus("valid");
      setUpdatedOnce(true);
    }
  }, [updatedOnce, value]);

  const geoCodeAddress = async (value: StreetAddress) => {
    setStatus("validating");

    const stringAddress = `${value.line1} ${value.line2}\n${value.city}, ${value.state} ${value.zip}`;

    // defaults
    let [lat, lon] = [0, 0];
    let geohash = "";

    try {
      [lat, lon] = await geocodeAddress(stringAddress);
      geohash = encodeGeoHash(lat, lon);
      setStatus("valid");
      onChange?.({
        streetAddress: removeUndefined(value),
        geohash: geohash,
        geopoint: new firebase.firestore.GeoPoint(lat, lon),
      });
    } catch (e) {
      setStatus("invalid");
    }
  };

  function addressDidUpdate(value: Partial<StreetAddress>) {
    const newAddress = { ...internalState, ...value };
    setInternalState(newAddress);

    if (
      newAddress.line1 &&
      newAddress.line1.trim() !== "" &&
      newAddress.city &&
      newAddress.city.trim() !== "" &&
      newAddress.zip &&
      newAddress.zip.trim() !== "" &&
      newAddress.state &&
      newAddress.state.trim() !== "" &&
      newAddress.country &&
      newAddress.country.trim() !== ""
    ) {
      // Address is complete
      setStatus("validating");
      // Old validation no longer counts
      onChange?.(undefined);
      debouncedQuery(newAddress);
    } else {
      setStatus("incomplete");
    }
  }

  const debouncedStatus = status;

  return (
    <>
      {!disabled && debouncedStatus === "valid" && <Alert message="We Found You!" type="success" />}
      {!disabled && debouncedStatus === "validating" && (
        <Alert message="Searching..." type="info" />
      )}
      {!disabled && debouncedStatus === "invalid" && (
        <Alert
          message="We couldn't find you. If this is unexpected, please contact us."
          type="error"
        />
      )}
      <Input
        style={{ marginTop: 8 }}
        placeholder="Line one"
        disabled={disabled}
        value={internalState.line1}
        onChange={(e) => {
          addressDidUpdate({ line1: e.target.value });
        }}
      />

      <Input
        style={{ marginTop: 8 }}
        placeholder="Line two"
        disabled={disabled}
        value={internalState.line2}
        onChange={(e) => {
          addressDidUpdate({ line2: e.target.value });
        }}
      />
      <Input.Group style={{ marginTop: 8, marginBottom: 0 }} compact>
        <Input
          placeholder="City"
          style={{ width: "50%" }}
          disabled={disabled}
          value={internalState.city}
          onChange={(e) => {
            addressDidUpdate({ city: e.target.value });
          }}
        />

        <Input
          placeholder="State"
          style={{ width: "20%" }}
          disabled={disabled}
          value={internalState.state}
          onChange={(e) => {
            addressDidUpdate({ state: e.target.value });
          }}
        />

        <Input
          placeholder="Zip"
          style={{ width: "30%" }}
          disabled={disabled}
          value={internalState.zip}
          onChange={(e) => {
            addressDidUpdate({ zip: e.target.value });
          }}
        />
      </Input.Group>
      <Input
        style={{ marginTop: 8 }}
        placeholder="Country"
        disabled={disabled}
        value={internalState.country}
        onChange={(e) => {
          addressDidUpdate({ country: e.target.value });
        }}
      />
    </>
  );
};
