import { FullRole, realProductCategories, RealProductCategoryKey } from "@rooted/shared";
import { Card, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { ProductGrid } from "../../../components/ProductGrid";
import { PageContent, WideLayout } from "../../layouts";
import { PageHeader } from "../../../components/PageHeader";

const CardFooter: React.FC = ({ children }) => (
  <div
    style={{
      backgroundColor: "#f2d0d1",
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      padding: 5,
      color: "#3b4d3f",
      fontSize: 18,
      textShadow: "none",
    }}
  >
    {children}
  </div>
);

export const ShopOverview: React.FC<{ role: FullRole }> = ({ role }) => {
  const comingSoonCategories: RealProductCategoryKey[] = role.type !== "wholesale-buyer" ? [] : [];

  const availableCategories: RealProductCategoryKey[] =
    role.type !== "wholesale-buyer"
      ? ["cut-flower", "csa", "bouquet", "bucket", "dried-flower", "potted-plant", "wreath"]
      : ["cut-flower", "bouquet", "bucket", "dried-flower", "potted-plant", "wreath"];

  const newCategories: RealProductCategoryKey[] = ["dried-flower", "potted-plant", "wreath"];

  return (
    <>
      <WideLayout>
        <PageContent>
          <PageHeader title="Shop" />

          <ProductGrid>
            {availableCategories.map((c) => {
              const x = realProductCategories[c];
              return (
                <Link
                  key={`categories-${x.id}`}
                  to={"/shop/" + x.slug}
                  style={{ display: "inline-block" }}
                >
                  <Card
                    className="overview-card"
                    style={{
                      backgroundImage: `url("/images/${x.id}.jpg")`,
                    }}
                  >
                    <div className="overview-card-overlay">{x.pluralName}</div>
                    {newCategories.includes(c) && (
                      <Tag
                        color={"cyan"}
                        style={{
                          position: "absolute",
                          top: 8,
                          left: 8,
                          zIndex: 100,
                        }}
                      >
                        NEW!
                      </Tag>
                    )}
                  </Card>
                </Link>
              );
            })}
            {comingSoonCategories.map((c) => {
              const x = realProductCategories[c];
              return (
                <div key={`coming-soon-${x.id}`} style={{ display: "inline-block" }}>
                  <Card
                    className="overview-card"
                    style={{
                      backgroundImage: `url("/images/${x.id}.jpg")`,
                    }}
                  >
                    <div className="overview-card-overlay overview-card-overlay-disabled">
                      {x.pluralName}
                      <CardFooter>Coming Soon!</CardFooter>
                    </div>
                  </Card>
                </div>
              );
            })}
          </ProductGrid>
        </PageContent>
      </WideLayout>
    </>
  );
};
