import { CoopProfile, FullGrowerRole, WithId } from "@rooted/shared";

import { Button, Card } from "antd";
import React from "react";
import {
  approveCoopMembership,
  requestMembershipInCoop,
  useCoopMembership,
} from "../../../services/coop-memberships";
import { CloudFunctionButton } from "../../admins/SiteSettings/ActionsPane";

/**
 * Shows the membership status between two entities on a profile page.
 */
export const CoopMembershipStatus: React.FC<{
  role: WithId<FullGrowerRole>;
  coop: WithId<CoopProfile>;
}> = ({ role, coop }) => {
  const [membership, membershipLoading, membershipError] = useCoopMembership(coop, role.profile);

  if (membershipLoading) return null;

  if (!membership)
    return (
      <Card
        title={`You're not connected with ${coop.bio.displayName}.`}
        extra={
          <Button type="primary" onClick={() => requestMembershipInCoop(role, coop)}>
            Request Coop Membership
          </Button>
        }
      >
        To let them relist your inventory, request to connect.
      </Card>
    );

  if (membership.status === "approved")
    return (
      <Card title={`You're connected with the ${coop.bio.displayName}.`}>
        They can relist your inventory for sale.
      </Card>
    );

  if (membership.status === "grower-requested")
    return (
      <Card title={`Your request to ${coop.bio.displayName} is pending.`}>
        Once they accept, they'll be able to relist your inventory.
      </Card>
    );
  else
    return (
      <Card
        title={`${coop.bio.displayName} wants to connect with you`}
        extra={
          <CloudFunctionButton type="primary" onClick={() => approveCoopMembership(membership._id)}>
            Approve membership
          </CloudFunctionButton>
        }
      >
        If you accept, they'll be able to relist your inventory.
      </Card>
    );
};
