import { PublicGlobalSettings } from "@rooted/shared";
import * as Sentry from "@sentry/react";
import { ConfigProvider as AntConfigProvider, Modal, Typography } from "antd";
import { ConfigProviderProps as AntConfigProviderProps } from "antd/lib/config-provider";
import Layout from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import compareVersions from "compare-versions";
import { Navbar } from "./components/Navbar/Navbar";
import "./polyfills";
import { RootedGlobalStateProvider, useRooted } from "./RootedContext";
import { logDebug } from "./sentry";
import { db, useDocumentDataChecked } from "./services/firebase";
import { NavigateWithQueryParams } from "./utils/navigateWithQueryParams";
import { AdminRoutes } from "./views/admins/AdminRoutes";
import { Authentication, CompleteAccountForm, LoginForm } from "./views/authentication";
import { Dashboard } from "./views/dashboard";
import { EnterpriseProfileDetails } from "./views/generic/EnterpriseProfileDetails";
import { ErrorPage, Footer, NarrowLayout, NotFoundPage, RootedEmpty } from "./views/layouts";

const appVersion = process.env.REACT_APP_VERSION;

const reloadPage = () => {
  // https://stackoverflow.com/questions/5721704/window-location-reload-with-clear-cache
  // Tested on Firefox, Chrome, Safari, and Mobile Safari
  window.location.reload(true);
};

/**
 * This is a temporary stopgap measure to ensure that we enforce old clients to update
 * when we make a breaking change to our data layer. In the future, we will avoid such breaking
 * changes, but while we are doing significant backend work (Aug-Sept 2021) this is necessary
 * so we can move fast and not need to deal with a smooth migration path
 */
const OutdatedClientBlocker: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [updateMessageVisible, setUpdateMessageVisible] = useState(false);
  const [globalSettings] = useDocumentDataChecked<PublicGlobalSettings>(
    db.collection("global").doc("public")
  );

  useEffect(() => {
    const clientIsOlderThanOldestSupportedAppVersion = (() => {
      if (!globalSettings || !appVersion) return false;

      const { oldestSupportedAppVersion } = globalSettings;
      if (!oldestSupportedAppVersion) return false;

      return compareVersions.compare(appVersion, oldestSupportedAppVersion, "<");
    })();

    if (!updateMessageVisible && clientIsOlderThanOldestSupportedAppVersion) {
      setUpdateMessageVisible(true);

      logDebug({
        message: "User saw 'you are using an old version of Rooted' notice",
      });

      const updateModal = Modal.warn({
        maskClosable: false,
        closable: false,
        title: (
          <Typography.Title level={5}>
            Heads up: you are using an old version of Rooted
          </Typography.Title>
        ),
        content: (
          <>
            <Typography.Paragraph>
              Please refresh now to get the latest version!
            </Typography.Paragraph>
            <Typography.Paragraph>
              If you see this message after refreshing, try{" "}
              <a
                href="https://www.pcmag.com/how-to/how-to-clear-your-cache-on-any-browser"
                target="_blank"
                rel="noreferrer"
              >
                clearing you browser cache
              </a>{" "}
              and restarting your browser.
            </Typography.Paragraph>
          </>
        ),
        okText: "Refresh now",
        onOk: reloadPage,
        // Do not let the modal close
        afterClose: () => updateModal.update({ visible: true }),
      });
    }

    if (globalSettings) setLoading(false);
  }, [globalSettings, updateMessageVisible]);

  // Do not render a loading screen -- it would look like the loading screen was glitching here
  if (loading || updateMessageVisible) return <></>;
  return <>{children}</>;
};

/**
 * The main entry point React component for the app.
 */
const AppContent: React.FC = () => {
  return (
    <AntConfigProvider {...antConfigSettings}>
      <OutdatedClientBlocker>
        <RootedGlobalStateProvider>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </RootedGlobalStateProvider>
      </OutdatedClientBlocker>
    </AntConfigProvider>
  );
};

const AppRoutes: React.FC = () => {
  const { account, isAdmin } = useRooted();
  return (
    <Routes>
      {isAdmin && <Route path="/admin/*" element={<AdminRoutes />} />}

      {account.status === "signed-in-needs-info" && (
        <Route path="*" element={<CompleteAccountForm account={account} />} />
      )}

      {account.status === "signed-out" && <Route path="accounts/*" element={<Authentication />} />}
      {account.status !== "signed-out" && (
        <Route path="accounts/*" element={<NavigateWithQueryParams to="/" />} />
      )}
      {account.status === "signed-in-complete" && <Route path="*" element={<Dashboard />} />}

      <Route path="growers/:profileRef" element={<PublicProfileDetails />} />
      <Route path="coops/:profileRef" element={<PublicProfileDetails />} />
      <Route path="wholesale-buyers/:profileRef" element={<PublicProfileDetails />} />

      {account.status !== "signed-in-complete" && <Route path="*" element={<LoginForm />} />}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

// TODO: instead of having this wrapper mimmick the internal dashboard layout,
// we should have a globally applied layout, with auth flows being *exceptions*
const PublicWrapper: React.FC = ({ children }) => (
  <Layout>
    <div className="dashboard-layout" style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <Navbar />
      <NarrowLayout>{children}</NarrowLayout>
      <Footer />
    </div>
  </Layout>
);

const PublicProfileDetails = () => (
  <PublicWrapper>
    <EnterpriseProfileDetails />
  </PublicWrapper>
);

// Wrap the AppContent in an error boundary to ensure _any_ application errors can show an error page.
// For example, if the effects in `AppContent` (like stripe setup) throw, then the site will still show _something_
export const AppWithErrorBoundary: React.FC = () => (
  <Sentry.ErrorBoundary fallback={ErrorPage}>
    <AppContent />
  </Sentry.ErrorBoundary>
);

export const App = Sentry.withProfiler(AppWithErrorBoundary);

const antConfigSettings: AntConfigProviderProps = {
  renderEmpty: RootedEmpty,
};
