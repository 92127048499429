import {
  WithId,
  EnterpriseProfile,
  GetPublicProfileRequest,
  GetPublicProfileResponse,
  ProfileType,
  makeProfileRoute,
  GrowerExtraBio,
  ProductPreview,
  realProductCategories,
  RealProductCategoryKey,
  isSeller,
} from "@rooted/shared";
import { Alert, Avatar, Card, Col, Divider, Row, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { functions, db, useDocumentDataChecked } from "../../services/firebase";
import { Loader, NarrowLayout, PageContent, NotFoundPage } from "../layouts";

import {
  ArrowRightOutlined,
  UserOutlined,
  EnvironmentFilled,
  CalendarOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { GrowerProfileConnectionStatus } from "./GrowerProfiles/GrowerProfileConnectionStatus";
import { GrowerCoopMembershipStatus } from "./GrowerProfiles/GrowerCoopMembershipStatus";
import { WholesaleBuyerProfileConnectionStatus } from "./WholesaleBuyerProfiles/WholesaleBuyerProfileConnectionStatus";
import { CoopProfileConnectionStatus } from "./CoopProfiles/CoopProfileConnectionStatus";
import { CoopMembershipStatus } from "./CoopProfiles/CoopMembershipStatus";
import { useRooted } from "../../RootedContext";
import { AlertLink } from "../../components/Buttons/AlertLink";
import { BarnIcon } from "../../components/Icons";
import OverflowParagraph from "../../components/OverflowParagraph";
import { ProfileSocialLinks } from "../../components/Profiles/ProfileSocialLinks";
import { FlowerVariety } from "../../components/TableColumns/FlowerVarietyColumn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck, faSeedling } from "@fortawesome/free-solid-svg-icons";
import { CopyCode } from "../../components/Misc/CopyCode";
import { wordListToSentence } from "../../utils/wordListToSentence";
import { monthListToRanges } from "../../utils/monthListToRanges";
import { logError } from "../../sentry";

const getPublicProfile = async (params: GetPublicProfileRequest) =>
  (await functions.httpsCallable("publicProfilePage")(params)).data as GetPublicProfileResponse;

export const EnterpriseProfileDetails: React.FC = () => {
  const { profileRef } = useParams(); // this may be "_id" or "slug
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState<GetPublicProfileResponse>();

  const loadPublicProfileData = useCallback(async () => {
    setLoading(true);
    try {
      const newProfileData = await getPublicProfile({ id: profileRef });
      setProfileData(newProfileData);
    } catch (error) {
      logError({
        error,
        tags: { page: "Public Profile" },
        extraData: {
          profileRef,
        },
      });
      // 404 errors result in loading false without a profile, which results in 404 page.
      if (error.code !== "not-found") {
        // Any other error we throw to let the wrapper component handle.
        // TODO: consider a more graceful path here. Ideally, this is _purely_ SSG and no failure like this could happen.
        throw error;
      }
    }
    setLoading(false);
  }, [profileRef]);

  useEffect(() => {
    loadPublicProfileData();
  }, [loadPublicProfileData]);

  const { activeRole: role, isAdmin } = useRooted();

  if (loading)
    return (
      <NarrowLayout>
        <Loader style={{ marginTop: "30vh", marginBottom: "30vh" }} />
      </NarrowLayout>
    );

  if (!profileData) {
    return <NotFoundPage />;
  }

  const {
    profileId,
    avatarUrl,
    location,
    displayName,
    socials,
    type,
    description,
    growerExtra,
  } = profileData;

  return (
    <PageContent>
      {/* Logged In User Content: */}
      {role && (
        <div style={{ marginBottom: 24 }}>
          {isAdmin && <AdminLinkAlert profileId={profileId} />}
          <ConnectionStatus profileId={profileId} />
          {role.profileId === profileId && (
            <OwnProfileAlert
              route={`${process.env.REACT_APP_BASE_URL}${makeProfileRoute(role.profile)}`}
            />
          )}
        </div>
      )}

      {/* Public Content: */}

      <Row
        style={{
          alignItems: "center",
          paddingBottom: 16,
          flexWrap: "wrap-reverse",
          justifyContent: "center",
        }}
        gutter={[16, 16]}
      >
        <ProfileAvatar src={avatarUrl} />

        <Col>
          <Typography.Title style={{ fontSize: 32, marginBottom: 4 }} level={1}>
            {displayName}
          </Typography.Title>
          <Row style={{ justifyContent: "space-between", alignItems: "center" }}>
            <Typography.Text type="secondary" style={{ fontSize: 16, marginBottom: 0 }}>
              <EnvironmentFilled style={{ paddingRight: 8 }} />
              {location}
            </Typography.Text>
            <div>
              <ProfileSocialLinks
                facebookUrl={socials.facebookUrl}
                instagramUrl={socials.instagramUrl}
              />
            </div>
          </Row>
        </Col>
      </Row>
      <Row gutter={[8, 8]} justify="center">
        {/* Description Section: */}
        <Col md={12} sm={24} xs={24}>
          <Typography.Text type="secondary" style={{ fontSize: 14 }}>
            From the {typeName[type]}:
          </Typography.Text>
          <OverflowParagraph
            expandableRows={4}
            expandable={true}
            style={{ marginBottom: 0, fontSize: 16 }}
          >
            {description}
          </OverflowParagraph>
        </Col>

        {growerExtra && (
          <Col md={12} sm={24} xs={24}>
            <GrowerThingsToKnowSection growerExtra={growerExtra} />
          </Col>
        )}
      </Row>

      {/* If this is a seller, we show a preview of their active inventory + a call to action */}
      <PreviewSection
        seller={profileData}
        category={"cut-flower"}
        previews={profileData.flowerPreviews}
      />
      <PreviewSection
        seller={profileData}
        category={"bucket"}
        previews={profileData.bucketPreviews}
      />
      <PreviewSection
        seller={profileData}
        category={"bouquet"}
        previews={profileData.bouquetPreviews}
      />
    </PageContent>
  );
};

function PreviewSection<C extends RealProductCategoryKey>({
  previews,
  seller,
  category,
}: {
  seller: {
    displayName: string;
    profileId: string;
    type: ProfileType;
  };
  category: C;
  previews?: ProductPreview<C>[];
}): React.ReactElement | null {
  if (!previews || previews.length === 0) return null;

  return (
    <>
      <Divider style={{ marginBottom: 8 }} />
      <ShopLink seller={seller} category={category} />
      <Typography.Text type="secondary" style={{ fontSize: 14 }}>
        {`${realProductCategories[category].name} Highlights:`}
      </Typography.Text>
      <ProductPreviewGrid previews={previews} />
    </>
  );
}

const ShopLink: React.FC<{
  seller: {
    displayName: string;
    profileId: string;
    type: ProfileType;
  };
  category: RealProductCategoryKey;
}> = ({ seller, category }) => {
  const { activeRole: role } = useRooted();

  const isOwnProfile = role?.profileId === seller.profileId;
  //  Only show the shop link to buyers (or disabled button for the grower themself)
  if (isSeller(role) && !isOwnProfile) return null;

  return (
    <AlertLink
      disabled={isOwnProfile}
      to={`/shop/${realProductCategories[category].slug}?seller=${seller.profileId}&listing_type=${seller.type}`}
      style={{ marginBottom: 8, marginTop: 16 }}
    >
      <Typography.Link>
        <ShoppingCartOutlined />{" "}
        {`Shop ${seller.displayName}'s ${realProductCategories[category].pluralName}`}
      </Typography.Link>
    </AlertLink>
  );
};

const ProductPreviewGrid: React.FC<{ previews: ProductPreview[] }> = ({ previews }) => (
  <Row gutter={[8, 8]} style={{ paddingTop: 8 }}>
    {previews.map(({ url, prototype, _id, category }) => (
      <Col xs={24} sm={12} md={6} key={_id}>
        <Card
          bodyStyle={{ padding: 8 }}
          style={{ flex: 1 }}
          cover={
            // TODO: use 200x200 images. we only support full and 100x100 right now, so we'll need to make those.
            <img
              style={{ objectFit: "cover", height: 200 }}
              src={url || "/logos/flowers-light.png"}
              alt={`${prototype.genus} ${realProductCategories[category].name}`}
            />
          }
        >
          <FlowerVariety scientificEllipsis flowerPrototype={prototype} />
        </Card>
      </Col>
    ))}
  </Row>
);

const typeName: { [type in ProfileType]: string } = {
  coop: "Coop",
  grower: "Farm",
  "retail-buyer": "Buyer",
  "wholesale-buyer": "Buyer",
};

const GrowerThingsToKnowSection: React.FC<{ growerExtra: GrowerExtraBio }> = ({ growerExtra }) => (
  <>
    <Typography.Text type="secondary" style={{ fontSize: 14 }}>
      Things to Know:
    </Typography.Text>
    <Typography.Paragraph style={{ fontSize: 16, marginBottom: 0 }}>
      {/* These icons have different aspect ratios, with heights set to the font size,
          so these margins are intentionally different to keep the text lined up */}
      <ul style={{ listStyleType: "none", margin: 0 }}>
        {growerExtra.typicalProductionMonths && growerExtra.typicalProductionMonths.length > 0 && (
          <li style={{ margin: 0 }}>
            <IconRow icon={<FontAwesomeIcon icon={faSeedling} />}>
              Produces cut flowers from{" "}
              <b>{monthListToRanges(growerExtra.typicalProductionMonths)}</b>
            </IconRow>
          </li>
        )}
        {growerExtra.typicalInventoryUpdateDays &&
          growerExtra.typicalInventoryUpdateDays.length > 0 && (
            <li style={{ margin: 0 }}>
              <IconRow icon={<CalendarOutlined />}>
                Typically updates inventory {formatWeekdays(growerExtra.typicalInventoryUpdateDays)}
              </IconRow>
            </li>
          )}
        {growerExtra.typicalDeliveryDays && growerExtra.typicalDeliveryDays.length > 0 && (
          <li style={{ margin: 0 }}>
            <IconRow icon={<FontAwesomeIcon icon={faTruck} />}>
              Typically does offer deliveries {formatWeekdays(growerExtra.typicalDeliveryDays)}
            </IconRow>
          </li>
        )}
        {/* Ensure we check for undefined, NOT truthy! */}
        {growerExtra.typicallyOffersFarmPickUp !== undefined && (
          <li style={{ margin: 0 }}>
            <IconRow icon={<BarnIcon />}>
              Typically <b>does {growerExtra.typicallyOffersFarmPickUp ? "" : "not"}</b> offer farm
              pick up
            </IconRow>
          </li>
        )}
      </ul>
    </Typography.Paragraph>
  </>
);

const ProfileAvatar: React.FC<{ src?: string }> = ({ src }) => (
  <Avatar
    style={{
      boxShadow: "0 2px 5px 0 rgba(60,66,87,.08),0 1px 1px 0 rgba(0,0,0,.12)",
    }}
    size={150}
    src={src}
    icon={<UserOutlined />}
  />
);

const OwnProfileAlert: React.FC<{ route: string }> = ({ route }) => (
  <Alert
    type="info"
    style={{ marginBottom: 16 }}
    message={
      <Row gutter={[8, 8]} justify="space-between">
        <Col>
          <Typography.Text>This is your profile!</Typography.Text>
          <br />
          <Typography.Text style={{ fontSize: 14 }} type="secondary">
            Shareable Profile Link:
          </Typography.Text>
          <br />
          <CopyCode allowWrap>{route}</CopyCode>
        </Col>
        <Col>
          <Link to="/my-account/profile">
            Back to Profile Settings <ArrowRightOutlined />
          </Link>
        </Col>
      </Row>
    }
  />
);

const AdminLinkAlert: React.FC<{ profileId: string }> = ({ profileId }) => (
  <Alert
    closable // Let this be closable for demo purposes
    type="warning"
    style={{ marginBottom: 16 }}
    message={
      <Row gutter={[8, 8]} justify="space-between">
        <Col>
          <Typography.Text>Currently viewing profile on Public Page</Typography.Text>
        </Col>

        <Col>
          <Link to={`/admin/profiles/${profileId}`}>
            View on Admin Dashboard <ArrowRightOutlined />
          </Link>
        </Col>
      </Row>
    }
  />
);

// TODO: refactor these sub-components to just one generic "ConnectionStatus" that deals with everything.
// this is a partial refactor to move from many details pages to 1.
const ConnectionStatus: React.FC<{ profileId: string }> = ({ profileId }) => {
  const { activeRole: role } = useRooted();
  const [profile, profileLoading] = useDocumentDataChecked<WithId<EnterpriseProfile>>(
    db.collection("profiles").doc(profileId),
    { idField: "_id" }
  );

  if (profileLoading || !profile || !role) return null;

  if (profile.type === "grower") {
    if (role.type === "wholesale-buyer") {
      return <GrowerProfileConnectionStatus role={role} grower={profile} />;
    }
    if (role.type === "coop") {
      return <GrowerCoopMembershipStatus role={role} grower={profile} />;
    }
  }
  if (profile.type === "wholesale-buyer") {
    if (role.type === "grower" || role.type === "coop") {
      return <WholesaleBuyerProfileConnectionStatus role={role} buyer={profile} />;
    }
  }
  if (profile.type === "coop") {
    if (role.type === "wholesale-buyer") {
      return <CoopProfileConnectionStatus role={role} coop={profile} />;
    }
    if (role.type === "grower") {
      return <CoopMembershipStatus role={role} coop={profile} />;
    }
  }
  return null;
};

// This is a hack to let us use a variety of different icon sources in a list-like way.
// We want the icons to act as list, like https://fontawesome.com/how-to-use/on-the-web/styling/icons-in-a-list
// TODO: migrate just to fontawesome, use list icons.
const IconRow: React.FC<{ icon: JSX.Element }> = ({ icon, children }) => (
  <Row>
    <div style={{ height: 26, width: 24 }}>{icon}</div>
    <div style={{ flex: 1 }}>{children}</div>
  </Row>
);

const formatWeekdays = (days: string[]) => {
  if (days.length === 7) return <b>every day</b>;
  return (
    <>
      on <b>{wordListToSentence(days)}</b>
    </>
  );
};
