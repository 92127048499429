import { FullSellerRole } from "@rooted/shared";
import { Button, Col, Divider, Form, Input, notification, Row, Typography } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { CheckboxGroup } from "../../../components/FormControls/CheckboxGroup";
import EmailPreview from "../../../components/Images/EmailPreview";
import { PageHeader } from "../../../components/PageHeader";
import { useSellersConnectedBuyersProfiles } from "../../../services/connections";
import { functions } from "../../../services/firebase";
import { Loader } from "../../layouts";

type NotifyBuyersParams = {
  buyerProfileIds: string[];
  sellerProfileId: string;
  personalMessage: string;
};
// TODO: export to a cloud functions / api folder
const notifyBuyers = (params: NotifyBuyersParams) =>
  functions.httpsCallable("notifyBuyers")(params);

export const NotifyBuyers: React.FC<{
  role: FullSellerRole;
}> = ({ role }) => {
  const [notifying, setNotifying] = useState(false);

  const requestNotifyBuyers = useCallback(
    async (buyerProfileIds: string[], personalMessage: string) => {
      if (notifying) return;
      setNotifying(true);

      try {
        await notifyBuyers({ buyerProfileIds, sellerProfileId: role.profileId, personalMessage });
        // TODO: If we want to include how many notifications are sent, that should be in the
        // response body of the API.
        notification.success({
          message: "Your selected buyers have been notified!",
        });
      } catch {
        notification.error({
          message: "Oops! Something went wrong notifying your buyers.",
        });
      }
      setNotifying(false);
    },
    [notifying, role.profileId]
  );

  return (
    <>
      <PageHeader title="Notify Buyers of New Inventory" />
      <Row justify="center">
        <Col lg={14}>
          <Typography.Paragraph>
            Let your customers know that you have inventory ready for them to view and shop. By
            selecting buyers from the list below and hitting "Send Email", those customers will
            receive a copy of this notification email, personalized for your farm.
          </Typography.Paragraph>
          <Divider />
          <SelectBuyersForm role={role} onSubmit={requestNotifyBuyers} submitting={notifying} />
          <br />
        </Col>
        <Col flex="auto" />
        <Col lg={8} md={12} sm={18} xs={20} style={{ paddingRight: 4 }}>
          <EmailPreview emailType={"notify-buyer"} />
        </Col>
      </Row>
    </>
  );
};

type FormValues = {
  buyerIds: string[];
  message: string;
};

const SelectBuyersForm: React.FC<{
  role: FullSellerRole;
  onSubmit: (buyerIds: string[], message: string) => void;
  submitting: boolean;
}> = ({ role, submitting, onSubmit: onSubmitProp }) => {
  const [buyers] = useSellersConnectedBuyersProfiles(role);

  const [loading, setLoading] = useState(true);
  const [selectedBuyersCount, setSelectedBuyersCount] = useState(0);

  const options = useMemo<{ label: string; value: string }[]>(() => {
    if (!buyers) return [];
    setLoading(false);
    return buyers
      .map(({ profile }) => ({
        label: profile.bio.displayName,
        value: profile._id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [buyers]);

  const onSubmit = useCallback(
    ({ buyerIds, message }: FormValues) => {
      onSubmitProp(buyerIds, message);
    },
    [onSubmitProp]
  );

  const onValuesChange = (_: any, values: FormValues) => {
    setSelectedBuyersCount(values.buyerIds?.length ?? 0);
  };

  if (loading) {
    return (
      <Row style={{ justifyContent: "center" }}>
        <Loader
          style={{ marginTop: 20, marginBottom: 20, alignSelf: "center", justifySelf: "center" }}
        />
      </Row>
    );
  }

  return (
    <Form layout="vertical" onFinish={onSubmit} onValuesChange={onValuesChange}>
      <Form.Item
        label="Customers to Notify"
        name={"buyerIds"}
        rules={[
          {
            required: true,
            message: "Please select the buyers you'd like to notify",
          },
        ]}
      >
        <CheckboxGroup selectAll deselectAll options={options} />
      </Form.Item>
      <Divider />
      <Form.Item
        name={"message"}
        label="Your Personalized Message"
        rules={[{ required: true, message: "Please enter a message for your buyers" }]}
      >
        <Input.TextArea rows={5} />
      </Form.Item>
      <Button type={"primary"} htmlType="submit" loading={submitting}>
        Send Email {selectedBuyersCount > 0 && `(${selectedBuyersCount})`}
      </Button>
    </Form>
  );
};
