import Select, { SelectProps } from "antd/lib/select";
import React, { useMemo, useState } from "react";
import { useDebouncedTrigger } from "../../hooks/util/useDebouncedTrigger";

export type OnChangeFunc = SelectProps<string>["onChange"];
export type SelectOption = {
  name: string;
  label: JSX.Element | string;
  value: string;
  searchFields?: string[];
};

export const SearchableSelect: React.FC<{
  options: SelectOption[];
  loading: boolean;
  onChange: SelectProps<string>["onChange"];
}> = ({ options, loading, onChange }) => {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebouncedTrigger(search);
  const searchLowerCase = debouncedSearch.toLocaleLowerCase();

  const filteredOptions = useMemo(
    () =>
      options.filter(
        ({ name, searchFields }) =>
          name.toLocaleLowerCase().includes(searchLowerCase) ||
          searchFields?.some((field) => field.includes(searchLowerCase))
      ),
    [searchLowerCase, options]
  );

  return (
    <Select
      loading={loading}
      showSearch
      showArrow={false}
      filterOption={false}
      onChange={(v, o) => {
        setSearch("");
        onChange?.(v.toString(), o);
      }}
      onSearch={setSearch}
      notFoundContent={null}
      optionLabelProp="label"
      placeholder="Search by name"
      options={filteredOptions}
    />
  );
};
