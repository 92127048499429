import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "./useQuery";

export const useSyncQueryParams = (
  queryParams: [name: string, value: string | undefined | null][]
) => {
  const queryParamLength = useRef(queryParams.length);
  if (queryParams.length !== queryParamLength.current) {
    throw new Error(
      `Must pass stable number of query parameter options.
      To omit a param, pass it as an empty string or undefined.`
    );
  }

  const query = useQuery();
  const navigate = useNavigate();

  // short circuit if params are same as query param
  if (!queryParams.some(([name, value]) => query.get(name) !== value)) return;

  const newQuery = new URLSearchParams(query.toString());

  queryParams.forEach(([name, value]) => {
    if (value !== undefined && value !== null) {
      newQuery.set(name, value);
    } else newQuery.delete(name);
  });

  navigate({ search: `?${newQuery.toString()}` }, { replace: true });
};
