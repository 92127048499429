import { FlowerPrototype, WithId } from "@rooted/shared";
import { db, useCollectionDataChecked } from "./firebase";
import { useOldDataOnLoad, CollectionHookReturn } from "./higher-order-searches";

function isString(str: string | undefined): str is string {
  return typeof str === "string";
}

export const getFlowerPrototypeSearchFields = (prototype: FlowerPrototype) => {
  return [
    prototype._commonName1Lowercase,
    prototype._commonName2Lowercase,
    prototype._commonName3Lowercase,
    prototype._commonName4Lowercase,
    prototype._commonName5Lowercase,
    prototype._scientificNameLowercase,
    prototype._varietyLowercase,
  ].filter(isString);
};

export const sortByGenericFlowerPrototypes = <T extends FlowerPrototype>(
  { generic: a }: T,
  { generic: b }: T
) => {
  if (!!a === !!b) return 0;
  return b ? 1 : -1;
};

const sortAndLimitPrototypes = <T extends FlowerPrototype>(
  prototypes: T[],
  { limit }: { limit?: number }
) => {
  const sorted = [...prototypes].sort(sortByGenericFlowerPrototypes);
  return sorted.slice(0, limit);
};

export const useFlowerPrototypeFullTextResults = (
  search: string,
  { limit, requireActive = true }: { limit?: number; requireActive?: boolean }
): CollectionHookReturn<WithId<FlowerPrototype>> => {
  const normalizedSearch = search.trim().toLowerCase();

  const baseQuery = requireActive
    ? db.collection("flowerPrototypes").where("active", "==", true)
    : db.collection("flowerPrototypes");

  const [prototypes, loading, error] = useOldDataOnLoad(
    useCollectionDataChecked<WithId<FlowerPrototype>>(baseQuery, { idField: "_id" })
  );

  if (!prototypes) return [prototypes, loading, error];

  if (!normalizedSearch.length) {
    return [sortAndLimitPrototypes(prototypes, { limit }), loading, error];
  }

  const filtered = prototypes.filter((prototype) => {
    const searchFields = getFlowerPrototypeSearchFields(prototype);
    return searchFields.some((param) => param.includes(normalizedSearch));
  });

  return [sortAndLimitPrototypes(filtered, { limit }), loading, error];
};
