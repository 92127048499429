import { CoopApplicationForm, GrowerApplicationForm, WithId } from "@rooted/shared";
import React from "react";

export const StripePanel: React.FC<{
  application: WithId<CoopApplicationForm | GrowerApplicationForm>;
}> = ({ application }) => {
  // Stripe URLs are different for test data. All server backends except production use
  // the test stripe backend
  const testUrlAddition = process.env.REACT_APP_BACKEND === "rooted-app-v2-prod" ? "" : "test/";

  if (application.stripe.status === "needs-user-input")
    return (
      <>
        <ul>
          <li>
            <b>Account created:</b> Waiting for user to fill out info
          </li>
          <li>
            <b>Stripe ID:</b> {application.stripe.accountId} &nbsp;
            <a
              href={`https://dashboard.stripe.com/${testUrlAddition}connect/accounts/${application.stripe.accountId}`}
            >
              (View in dashboard)
            </a>
          </li>
          <li>
            <b>OnboardingUrl:</b> {application.stripe.onboardingUrl}
          </li>
        </ul>
      </>
    );
  else if (application.stripe.status === "under-stripe-review")
    return (
      <>
        Waiting for stripe to review filled out info.
        <br />
        Stripe ID: {application.stripe.accountId} &nbsp;
        <a
          href={`https://dashboard.stripe.com/${testUrlAddition}connect/accounts/${application.stripe.accountId}`}
        >
          (View in dashboard)
        </a>
      </>
    );
  else if (application.stripe.status === "complete")
    return (
      <>
        Stripe onboarding complete. <br />
        Stripe ID: {application.stripe.accountId} &nbsp;
        <a
          href={`https://dashboard.stripe.com/${testUrlAddition}connect/accounts/${application.stripe.accountId}`}
        >
          (View in dashboard)
        </a>
      </>
    );
  else
    return (
      <>
        Stripe onboarding failed. <br />
        Stripe ID: {application.stripe.accountId} &nbsp;
        <a
          href={`https://dashboard.stripe.com/${testUrlAddition}connect/accounts/${application.stripe.accountId}`}
        >
          (View in dashboard)
        </a>
      </>
    );
};
