import { ColorKey, legalColors } from "@rooted/shared";
import { Tag, Badge } from "antd";
import React from "react";
import "./ColorDisplay.less";

export const ColorDisplay: React.FC<{ color: ColorKey; style?: React.CSSProperties }> = ({
  color,
  style,
}) => {
  const fullColor = legalColors.find((x) => x.key === color)!;
  if (!fullColor) return <Tag>Unknown</Tag>;
  if (color === "mixed")
    return (
      <Badge
        className="color-badge mixed-color-badge"
        color={"#000"}
        text={fullColor.name}
        style={style}
      />
    );
  return (
    <Badge
      className="color-badge"
      color={"#" + fullColor.hexCode}
      text={fullColor.name}
      style={style}
    />
  );
};
