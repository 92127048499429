import { Typography, Form } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { PageHeader } from "../../../components/PageHeader";

export const RetailStartPane: React.FC = () => {
  return (
    <>
      <PageHeader title={"Where do I start?"} />
      <Typography.Paragraph>
        <Form>
          <ul>
            <li>
              <Form.Item>
                <b>New member?</b>
                <br />
                Excellent! We can't wait to help you source those gorgeous blooms! Head over to the{" "}
                <Link to="/shop">Shop</Link> to see what's available now!
              </Form.Item>
            </li>
            <li>
              <Form.Item>
                <b>When can I start buying?</b>
                <br />
                Depends on your climate zone! Our farmers' growing schedules differ, but typically
                by mid-March we start to see fresh flowers becoming available. Head over to the{" "}
                <Link to="/shop">Shop</Link> to see what's available now!
              </Form.Item>
            </li>
            <li>
              <Form.Item>
                <b>I still have a question that I don't see answered here… </b>
                <br />
                Drop us a line! Send us an email at{" "}
                <Typography.Link href="mailto:help@rootedfarmers.com">
                  help@rootedfarmers.com
                </Typography.Link>{" "}
                and we'll be back in touch ASAP (usually within a couple of hours!).
              </Form.Item>
            </li>
          </ul>
        </Form>
      </Typography.Paragraph>
    </>
  );
};
