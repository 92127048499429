import { InputNumber } from "antd";
import React from "react";
import { InputNumberProps } from "antd/lib/input-number";

export const StemCountInput: React.FC<InputNumberProps> = ({ value, onChange, ...rest }) => {
  return (
    <InputNumber
      min={1}
      max={10000}
      step={1}
      precision={0}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};
