import { CoopProfile, FullWholesaleBuyerRole, WithId } from "@rooted/shared";

import { Button, Card } from "antd";
import React from "react";
import {
  approveConnection,
  requestConnectionToCoop,
  useConnection,
} from "../../../services/connections";
import { CloudFunctionButton } from "../../admins/SiteSettings/ActionsPane";

/**
 * Shows the connection status between two entities on a profile page.
 */
export const CoopProfileConnectionStatus: React.FC<{
  role: WithId<FullWholesaleBuyerRole>;
  coop: WithId<CoopProfile>;
}> = ({ role, coop }) => {
  const [connection, connectionLoading, connectionError] = useConnection(role.profileId, coop._id!);

  if (connectionLoading) return null;

  if (!connection)
    return (
      <Card
        title={`You're not connected with ${coop.bio.displayName}.`}
        extra={
          <Button type="primary" onClick={() => requestConnectionToCoop(role, coop)}>
            Request Connection
          </Button>
        }
      >
        To view their inventory and place wholesale orders, request to connect.
      </Card>
    );

  if (connection.status === "approved")
    return (
      <Card title={`You're connected with the ${coop.bio.displayName}.`}>
        You can see inventory and place wholesale orders.
      </Card>
    );

  if (connection.status === "buyer-requested")
    return (
      <Card title={`Your request to ${coop.bio.displayName} is pending.`}>
        Once they accept, you'll be able to see their inventory and place wholesale orders.
      </Card>
    );
  else
    return (
      <Card
        title={`${coop.bio.displayName} wants to connect with you`}
        extra={
          <CloudFunctionButton type="primary" onClick={() => approveConnection(connection._id)}>
            Approve connection
          </CloudFunctionButton>
        }
      >
        If you accept, you'll be able to see their inventory and place wholesale orders.
      </Card>
    );
};
