import { FullCoopRole, FullGrowerRole, WholesaleBuyerProfile, WithId } from "@rooted/shared";
import { Button, Card } from "antd";
import React from "react";
import {
  approveConnection,
  requestConnectionToBuyer,
  useConnection,
} from "../../../services/connections";
import { CloudFunctionButton } from "../../admins/SiteSettings/ActionsPane";
import { FulfillmentWidget } from "../../../components/Widgets";

/**
 * Shows the connection status between two entities on a profile page.
 */
export const WholesaleBuyerProfileConnectionStatus: React.FC<{
  role: FullGrowerRole | FullCoopRole;
  buyer: WithId<WholesaleBuyerProfile>;
}> = ({ role, buyer }) => {
  const [connection, connectionLoading, connectionError] = useConnection(buyer._id, role.profileId);

  if (connectionLoading) return null;

  if (!connection)
    return (
      <Card
        title={`You're not connected with ${buyer.bio.displayName}.`}
        extra={
          <Button type="primary" onClick={() => requestConnectionToBuyer(role, buyer)}>
            Request Connection
          </Button>
        }
      >
        To let them view your inventory and place wholesale orders, request to connect.
      </Card>
    );

  if (connection.status === "approved")
    return (
      <Card title={`You're connected with the ${buyer.bio.displayName}.`}>
        They can see your inventory and place wholesale orders.
        <br />
        <br />
        <FulfillmentWidget connection={connection} role={role} editable />
      </Card>
    );

  if (connection.status === "seller-requested")
    return (
      <Card title={`Your request to ${buyer.bio.displayName} is pending.`}>
        Once they accept, they'll be able to see your inventory and place wholesale orders.
      </Card>
    );
  else
    return (
      <Card
        title={`${buyer.bio.displayName} wants to connect with you`}
        extra={
          <CloudFunctionButton type="primary" onClick={() => approveConnection(connection._id)}>
            Approve connection
          </CloudFunctionButton>
        }
      >
        If you accept, they'll be able to see your inventory and place wholesale orders.
      </Card>
    );
};
