import { Button, Card, Form } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../../../components/PageHeader";
import { db } from "../../../../services/firebase";
import { PlanFormItems } from "./PlanFormItems";

export const CreatePlan: React.FC = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const submit = async (values: any) => {
    setLoading(true);
    await db.collection("subscriptionPlans").add(values);
    navigate("/site-settings/subscription-plans");
  };

  return (
    <>
      <PageHeader title={"Create Subscription Plan"} breadcrumb />
      <Card>
        <Form
          onFinish={submit}
          layout="vertical"
          requiredMark="optional"
          initialValues={{ benefits: [""] }}
        >
          <PlanFormItems />
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Card>
    </>
  );
};
