import { useRef } from "react";
import firebase from "firebase/app";
import { WithId } from "@rooted/shared";
import { useDocumentDataChecked } from "./firebase";

export type CollectionHookReturn<T> = [T[] | undefined, boolean, Error | undefined];
export type DocumentHookReturn<T> = [T | undefined, boolean, Error | undefined];

/**
 * Merge the results of multiple collection hooks. The result is a array of the combined
 * results, a boolean value of whether *any* of the queries are loading, and an array of
 * all the errors. The values are deduplicated based on the given id field. If there are
 * any errors of loading queries, the results will be undefined.
 *
 * @param hooks The hooks to merge
 * @param idField The id field given to each hook (used for deduplication)
 */
export function useMergedCollectionHooks<T extends { _id: string }>(
  hooks: CollectionHookReturn<T>[]
) {
  const anyLoading = hooks.some(([_, x, __]: CollectionHookReturn<T>) => x);
  const values = (hooks
    .map(([x, _, __]: CollectionHookReturn<T>) => x)
    .flat()
    .filter((v, i, a) => v !== undefined) as T[]).filter(
    (v, i, a) => a.findIndex((t) => t._id === v._id) === i
  );

  if (values.some((x) => x._id === undefined))
    throw new Error("Error merging collection hooks: no ID field found on object");

  const firstError = hooks
    .map(([_, __, x]: CollectionHookReturn<T>) => x)
    .find((x) => x !== undefined);

  if (firstError) console.error(firstError);

  const res: [T[] | undefined, boolean, Error | undefined] = [
    !anyLoading ? values : undefined,
    anyLoading,
    firstError,
  ];

  return res;
}

/**
 * Merge the results of multiple doocument hooks. The result is a array of the combined
 * results, a boolean value of whether *any* of the queries are loading, and an array of
 * all the errors. The values are deduplicated based on the given id field. If there are
 * any errors of loading queries, the results will be undefined.
 *
 * @param hooks The hooks to merge
 * @param idField The id field given to each hook (used for deduplication)
 */
export function useMergedDocumentHooks<T extends { _id: string }>(
  hooks: DocumentHookReturn<T>[],
  idField = "id"
) {
  const anyLoading = hooks.some(([_, x, __]: DocumentHookReturn<T>) => x);
  const values = hooks.map(([x, _, __]: DocumentHookReturn<T>) => x);

  const firstError = hooks
    .map(([_, __, x]: DocumentHookReturn<T>) => x)
    .find((x) => x !== undefined);

  if (firstError) throw firstError;

  const res: [(T | undefined)[] | undefined, boolean, Error | undefined] = [
    !anyLoading ? values : undefined,
    anyLoading,
    firstError,
  ];

  return res;
}

/**
 * Modifies the result of a collection hook to keep returning old data even when
 * newer results are loading. Useful for avoiding UI jitter when updating queries on
 * keypress, etc.
 */
export function useOldDataOnLoad<T>([values, loading, error]: CollectionHookReturn<T>) {
  const lastValues = useRef<T[]>([]);
  if (!loading && values) lastValues.current = values;
  const res: CollectionHookReturn<T> = [!loading ? values : lastValues.current, loading, error];
  return res;
}

/**
 * Create a prefix-search query on a given string field from a given base query.
 */
export const makePrefixQuery = (
  query: firebase.firestore.Query,
  fieldPath: firebase.firestore.FieldPath | string,
  prefix: string
) => {
  if (prefix === "") return query;
  const nextPrefix =
    prefix.substring(0, prefix.length - 1) +
    String.fromCharCode(prefix.charCodeAt(prefix.length - 1) + 1);
  return query.where(fieldPath, ">=", prefix).where(fieldPath, "<", nextPrefix);
};

export function useDocsWithIds<T>(
  basePath: firebase.firestore.CollectionReference,
  ids: (string | undefined)[]
) {
  if (ids.length > 16) throw new Error("Maximum 16 documents in merged hook.");
  const hook0 = useDocumentDataChecked<WithId<T>>(ids[0] ? basePath.doc(ids[0]) : null, {
    idField: "_id",
  });
  const hook1 = useDocumentDataChecked<WithId<T>>(ids[1] ? basePath.doc(ids[1]) : null, {
    idField: "_id",
  });
  const hook2 = useDocumentDataChecked<WithId<T>>(ids[2] ? basePath.doc(ids[2]) : null, {
    idField: "_id",
  });
  const hook3 = useDocumentDataChecked<WithId<T>>(ids[3] ? basePath.doc(ids[3]) : null, {
    idField: "_id",
  });
  const hook4 = useDocumentDataChecked<WithId<T>>(ids[4] ? basePath.doc(ids[4]) : null, {
    idField: "_id",
  });
  const hook5 = useDocumentDataChecked<WithId<T>>(ids[5] ? basePath.doc(ids[5]) : null, {
    idField: "_id",
  });
  const hook6 = useDocumentDataChecked<WithId<T>>(ids[6] ? basePath.doc(ids[6]) : null, {
    idField: "_id",
  });
  const hook7 = useDocumentDataChecked<WithId<T>>(ids[7] ? basePath.doc(ids[7]) : null, {
    idField: "_id",
  });
  const hook8 = useDocumentDataChecked<WithId<T>>(ids[8] ? basePath.doc(ids[8]) : null, {
    idField: "_id",
  });
  const hook9 = useDocumentDataChecked<WithId<T>>(ids[9] ? basePath.doc(ids[9]) : null, {
    idField: "_id",
  });
  const hook10 = useDocumentDataChecked<WithId<T>>(ids[10] ? basePath.doc(ids[10]) : null, {
    idField: "_id",
  });
  const hook11 = useDocumentDataChecked<WithId<T>>(ids[11] ? basePath.doc(ids[11]) : null, {
    idField: "_id",
  });
  const hook12 = useDocumentDataChecked<WithId<T>>(ids[12] ? basePath.doc(ids[12]) : null, {
    idField: "_id",
  });
  const hook13 = useDocumentDataChecked<WithId<T>>(ids[13] ? basePath.doc(ids[13]) : null, {
    idField: "_id",
  });
  const hook14 = useDocumentDataChecked<WithId<T>>(ids[14] ? basePath.doc(ids[14]) : null, {
    idField: "_id",
  });
  const hook15 = useDocumentDataChecked<WithId<T>>(ids[15] ? basePath.doc(ids[15]) : null, {
    idField: "_id",
  });

  const hooksThatShouldHaveValues = [
    ...(ids[0] ? [hook0] : []),
    ...(ids[1] ? [hook1] : []),
    ...(ids[2] ? [hook2] : []),
    ...(ids[3] ? [hook3] : []),
    ...(ids[4] ? [hook4] : []),
    ...(ids[5] ? [hook5] : []),
    ...(ids[6] ? [hook6] : []),
    ...(ids[7] ? [hook7] : []),
    ...(ids[8] ? [hook8] : []),
    ...(ids[9] ? [hook9] : []),
    ...(ids[10] ? [hook10] : []),
    ...(ids[11] ? [hook11] : []),
    ...(ids[12] ? [hook12] : []),
    ...(ids[13] ? [hook13] : []),
    ...(ids[14] ? [hook14] : []),
    ...(ids[15] ? [hook15] : []),
  ];
  return useMergedDocumentHooks<WithId<T>>(hooksThatShouldHaveValues);
}

export function useCount<T>(x: CollectionHookReturn<T>) {
  return x[0]?.length || 0;
}
