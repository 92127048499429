import { MediaReference, WithId, FullEnterpriseRole } from "@rooted/shared";
import type { UploadProps } from "antd/es/upload/interface";
import firebase from "firebase/app";
import { db, useCollectionDataChecked } from "../firebase";
import { makePrefixQuery, useOldDataOnLoad } from "../higher-order-searches";

export const useAllMedia = (role: FullEnterpriseRole) =>
  useCollectionDataChecked<WithId<MediaReference>>(
    db
      .collection("mediaReferences")
      .where("ownerId", "==", role.profileId)
      .orderBy("uploadedAt", "desc"),
    { idField: "_id" }
  );

export const useMediaLibraryResults = (
  role: FullEnterpriseRole,
  searchString?: string,
  limit = 5
) => {
  const baseQuery = db.collection("mediaReferences").where("ownerId", "==", role.profileId);

  const query =
    searchString && searchString !== ""
      ? makePrefixQuery(baseQuery, "_fileNameLowerCase", searchString).limit(limit)
      : baseQuery.orderBy("uploadedAt", "desc").limit(limit);

  return useOldDataOnLoad(
    useCollectionDataChecked<WithId<MediaReference>>(query, { idField: "_id" })
  );
};

const supportedFileTypes = ["image/jpeg", "image/png"];

type CustomRequest = Exclude<NonNullable<UploadProps["customRequest"]>, undefined>;

export const makeCustomUploadRequest = (role: FullEnterpriseRole): CustomRequest => async ({
  file,
  onSuccess,
  onError,
  onProgress,
}) => {
  if (!supportedFileTypes.includes(file.type)) {
    return onError?.(new Error(`Unsupported image format: ${file.type}`), file);
  }

  const newMediaRef: MediaReference = {
    storageUrl: "",
    fileName: file.name,
    ownerId: role.profileId,
    _fileNameLowerCase: file.name.toLowerCase(),
    uploadedAt: firebase.firestore.Timestamp.now(),
  };
  //Create a new doc referencing the media so we can query it in firestore
  const mediaDocRef = await db.collection("mediaReferences").add(newMediaRef);

  const uploadTask = firebase.storage().ref().child(`user-media/${mediaDocRef.id}`).put(file);

  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on(
    "state_changed",
    function (snapshot) {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

      // CONNECT ON PROGRESS
      // Their progress event is incredibly silly...
      onProgress?.({ percent: progress } as any);

      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          break;
      }
    },
    (error) => onError?.(error),
    async () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      const storageUrl = await uploadTask.snapshot.ref.getDownloadURL();

      // User may have already deleted the image. In this case, don't try to set the URL.
      if (!(await db.doc(`mediaReferences/${mediaDocRef.id}`).get()).exists) return;

      await db.doc(`mediaReferences/${mediaDocRef.id}`).update({ storageUrl });

      // This is silly to pass a random XMLHttpRequest -- this should be optional...
      onSuccess?.(storageUrl, new XMLHttpRequest());
    }
  );
};
