import { Radio } from "antd";
import React, { useState } from "react";
import { ChildOrder } from "@rooted/shared";
import { PageHeader } from "../../../components/PageHeader";
import { useQuery } from "../../../hooks/util/useQuery";
import { useSyncQueryParams } from "../../../hooks/util/useSyncQueryParams";
import { WideLayout } from "../../layouts";
import { AdminOrdersTable } from "../../orders/OrderTable/OrderTable";

function isStatus(string?: any): string is ChildOrder["status"] {
  return (
    string &&
    typeof string === "string" &&
    (string === "active" || string === "fulfilled" || string === "cancelled")
  );
}

export const AdminOrderList: React.FC = () => {
  const query = useQuery();
  const [status, setStatus] = useState<ChildOrder["status"] | "all">(() => {
    const queryStatus = query.get("status");
    if (isStatus(queryStatus)) return queryStatus;
    return "active";
  });
  useSyncQueryParams([["status", status]]);
  return (
    <>
      <WideLayout>
        <PageHeader title={"Orders"} />
        <Radio.Group
          style={{ marginBottom: 16 }}
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          buttonStyle="solid"
          optionType="button"
          options={[
            { label: "All", value: "all" },
            { label: "Active", value: "active" },
            { label: "Fulfilled", value: "fulfilled" },
            { label: "Cancelled", value: "cancelled" },
            { label: "Guest Checkout", value: "guest-checkout" },
          ]}
        />
        <AdminOrdersTable status={status === "all" ? undefined : status} />
      </WideLayout>
    </>
  );
};
