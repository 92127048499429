import {
  allProductCategories,
  assertIsGrower,
  ActiveChildOrder,
  WithId,
  Listing,
  formatPluralCount,
  ProductCategoryKey,
  CutFlowerListing,
  OrderItem,
  filterByOriginalSeller,
} from "@rooted/shared";

import { Button, Card, notification, Table, Typography } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { ColumnType } from "antd/lib/table";
import React, { useMemo, useRef, useState, useCallback, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import {
  ProfileCallout,
  ProfileNameSimple,
} from "../../../components/Misc/EnterpriseProfileCallout";
import {
  colorColumn,
  nameWithDescriptionColumn,
  photoColumn,
  stemLengthColumn,
} from "../../../components/ProductTable";
import { FlowerVarietyDetailsColumn } from "../../../components/TableColumns/FlowerVarietyColumn";
import { logError } from "../../../sentry";
import "./HarvestLists.less";
import { useRooted } from "../../../RootedContext";
import { db, querySnapshotToIdDocs, snapshotToIdDoc } from "../../../services/firebase";
import { Loader } from "../../layouts";
import { stringifyFulfillment } from "../../../utils/fulfillment";

type NarrowFromCategory<T, N> = T extends { category: N } ? T : never;

type HarvestListItem<T extends Listing = Listing> = T & {
  harvestCount: {
    total: number;
    breakdown: Map<string, number>;
  };
};

const cutFlowerHarvestQuantity = {
  title: "Quantity",
  key: "harvestTotal",
  width: 90,
  render: (x: HarvestListItem<CutFlowerListing>) => (
    <>
      {x.harvestCount.total} bunch{x.harvestCount.total !== 1 && "es"} <br />(
      {x.harvestCount.total * x?.product.stemsPerBunch} stems total)
    </>
  ),
  sorter: (a: HarvestListItem<CutFlowerListing>, b: HarvestListItem<CutFlowerListing>) =>
    a.harvestCount.total - b.harvestCount.total,
  sortDirections: ["descend", "ascend"] as SortOrder[],
};

const harvestQuantityColumn = {
  title: "Quantity",
  key: "harvestTotal",
  width: 90,
  render: ({ harvestCount }: HarvestListItem) => <>{harvestCount.total}</>,
  sorter: ({ harvestCount: a }: HarvestListItem, { harvestCount: b }: HarvestListItem) =>
    a.total - b.total,
  sortDirections: ["descend", "ascend"] as SortOrder[],
};

const harvestListBreakdownColumn = {
  title: "Breakdown",
  key: "harvestBreakdown",
  width: 200,
  render: ({ harvestCount }: { harvestCount: HarvestListItem["harvestCount"] }) =>
    Array.from(harvestCount.breakdown.entries()).map(([buyerId, quantity]) => (
      <React.Fragment key={buyerId}>
        {quantity} to <ProfileNameSimple id={buyerId} />
        <br />
      </React.Fragment>
    )),
};

const genericColumns = [
  photoColumn,
  nameWithDescriptionColumn,
  harvestQuantityColumn,
  harvestListBreakdownColumn,
];
const genericColorColumns = [
  photoColumn,
  nameWithDescriptionColumn,
  colorColumn,
  harvestQuantityColumn,
  harvestListBreakdownColumn,
];

const harvestListColumns: {
  [C in ProductCategoryKey]: ColumnType<HarvestListItem<WithId<NarrowFromCategory<Listing, C>>>>[];
} = {
  "cut-flower": [
    photoColumn,
    FlowerVarietyDetailsColumn,
    colorColumn,
    stemLengthColumn,
    cutFlowerHarvestQuantity,
    harvestListBreakdownColumn,
  ],
  bouquet: genericColorColumns,
  bucket: genericColorColumns,
  "dried-flower": genericColumns,
  "potted-plant": genericColumns,
  wreath: genericColumns,

  // Currently Omitted:
  csa: genericColumns,

  // No photo!
  "ad-hoc": [harvestQuantityColumn, harvestListBreakdownColumn],
};

type OrderWithItems = { data: WithId<ActiveChildOrder>; items: WithId<OrderItem>[] };

const filterExistingOrders = (order: Partial<OrderWithItems>): order is OrderWithItems => {
  return !!order.data && !!order.items;
};

const getBulkOrders = async (orderIds: string[], sellerId: string): Promise<OrderWithItems[]> => {
  const orders = await Promise.all(
    orderIds.map(async (id) => ({
      data: snapshotToIdDoc<ActiveChildOrder>(await db.collection("childOrders").doc(id).get()),
      items: (await getOrderItems(id)).filter(filterByOriginalSeller(sellerId)),
    }))
  );
  return orders.filter(filterExistingOrders);
};

const getOrderItems = async (id: string): Promise<WithId<OrderItem>[]> => {
  const items = querySnapshotToIdDocs<OrderItem>(
    await db.collection("childOrders").doc(id).collection("items").get()
  );
  return items;
};
export const HarvestList: React.FC<{
  orderIds: string[];
}> = ({ orderIds }) => {
  const { activeRole: role } = useRooted();
  assertIsGrower(role);

  const printRef = useRef<HTMLDivElement>(null);

  const [orders, setOrders] = useState<OrderWithItems[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchOrders = useCallback(() => {
    setLoading(true);
    getBulkOrders(orderIds, role.profileId)
      .then((fetchedOrders) => {
        setOrders(fetchedOrders);
      })
      .finally(() => setLoading(false))
      .catch((error) => {
        notification.error({
          message: "Something went wrong looking up your harvest list orders.",
          description: "Please try again, or contact Rooted if this problem persists.",
        });
        logError({
          error,
          extraData: {
            harvestListIds: orderIds,
          },
        });
      });
  }, [orderIds, role.profileId]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const printPage = useReactToPrint({
    documentTitle: "Harvest List",
    pageStyle: "",
    content: () => printRef.current!,
    onPrintError: (error) => {
      logError({
        error: "Print Error",
        tags: { page: "print-harvest-list" },
        extraData: {
          errorCode: error,
        },
      });
    },
  });

  // Build the actual harvest list
  // Map of listing ids to entries
  const harvestListItems = useMemo(() => {
    const list: Map<string, HarvestListItem> = new Map();

    orders.forEach((order) => {
      order.items.forEach((item) => {
        // Don't show harvest lists for these categories:
        if (item.listing.category === "csa") return;
        if (item.quantity === 0) return;

        const existingItem = list.get(item.listing._id) || {
          ...item.listing,
          harvestCount: { total: 0, breakdown: new Map() },
        };

        // Update the overall quantity + buyer totals
        existingItem.harvestCount.total = existingItem.harvestCount.total + item.quantity;
        const buyerQuantity =
          (existingItem.harvestCount.breakdown.get(order.data.buyerId) ?? 0) + item.quantity;

        // Ensure the updated breakdown and items get set
        existingItem.harvestCount.breakdown.set(order.data.buyerId, buyerQuantity);
        list.set(item.listing._id, existingItem);
      });
    });

    return Array.from(list.values());
  }, [orders]);

  const harvestListItemsByCategory = useMemo(
    () =>
      harvestListItems.reduceRight((acc, curr) => {
        const { category } = curr;
        if (!acc[category]) acc[category] = [];
        acc[category].push(curr);
        return acc;
      }, {} as { [category: string]: HarvestListItem[] }),
    [harvestListItems]
  );

  const title = `Harvest List (${formatPluralCount("order", orders.length)})`;

  if (loading) {
    return (
      <Card title={"Harvest List"} className="harvest-list-card" style={{ marginTop: 16 }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 16,
          }}
        >
          <Loader />
        </div>
      </Card>
    );
  }

  return (
    <Card
      title={title}
      className="harvest-list-card"
      style={{ marginTop: 16 }}
      extra={
        <Button type="primary" onClick={printPage}>
          Print
        </Button>
      }
    >
      {/* Printed Content: */}
      <div ref={printRef} style={{ paddingTop: 8 }}>
        <Typography.Text style={{ padding: 8, fontSize: 18 }}>{title}:</Typography.Text>
        {/* Styled in .less */}
        <table id="harvest-list-customers">
          <tr>
            <th>Order #</th>
            <th>Buyer Name & Order Notes</th>
            <th>Fulfillment Date</th>
          </tr>
          {orders
            .sort(({ data: a }, { data: b }) => a.number - b.number)
            .map(({ data: { number, fulfillment, buyerId, _id, buyerNotes } }) => (
              <tr key={_id} style={{ borderBottom: "1px solid #cccc" }}>
                <td>
                  <Typography.Text type="secondary">#{number}</Typography.Text>
                </td>
                <td>
                  <ProfileCallout id={buyerId} bold={false} />
                  {buyerNotes && (
                    <div style={{ paddingLeft: 4, paddingTop: 4 }}>
                      <Typography.Text style={{ fontSize: 14 }} type="secondary">
                        Notes:{" "}
                      </Typography.Text>
                      <Typography.Text>"{buyerNotes}"</Typography.Text>
                    </div>
                  )}
                </td>
                <td>{stringifyFulfillment(fulfillment)}</td>
              </tr>
            ))}
        </table>

        {/* Sort categories, cut flowers at top. */}
        {Object.entries(harvestListItemsByCategory)
          .sort(([a], [b]) => {
            if (a === "cut-flower" && b === "cut-flower") return 0;
            if (a === "cut-flower") return -1;
            if (b === "cut-flower") return 1;
            return a.localeCompare(b);
          })
          .map(([category, items]) => (
            <Table
              key={`table-${category}`}
              title={() => (
                <Typography.Text style={{ marginBottom: 0, fontSize: 18 }}>
                  {allProductCategories[category as ProductCategoryKey]?.pluralName}:
                </Typography.Text>
              )}
              columns={harvestListColumns[category as ProductCategoryKey] as any}
              rowKey="_id"
              dataSource={items}
              pagination={false}
              size="small"
              className="table-x-scroll"
            />
          ))}
      </div>
    </Card>
  );
};
