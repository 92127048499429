import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

const addParamsToPath = (path: string) => {
  const params = new URL(document.location.toString()).searchParams;
  const paramString = Array.from(params.entries())
    .map(([k, v]) => `${k}=${v}`)
    .join(`&`);
  return paramString.length > 0 ? `${path}?${paramString}` : path;
};

/**
 * Wrap useNavigate from react-router to forward old query params with navigation.
 */
export const useNavigateWithQueryParams = () => {
  const navigate = useNavigate();

  return (s: string) => navigate(addParamsToPath(s));
};

/**
 * Wrap <Navigate /> component from react-router to forward old query params with navigation.
 */
export const NavigateWithQueryParams: React.FC<{ to: string; replace?: boolean }> = ({
  to,
  replace,
}) => {
  return <Navigate to={addParamsToPath(to)} replace={replace} />;
};

export const LinkWithQueryParams: React.FC<{ to: string }> = ({ to, children }) => {
  return <Link to={addParamsToPath(to)}>{children}</Link>;
};
