import { RealRelisting, WithId } from "@rooted/shared";
import { Button, notification } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { setRelistingIsActive } from "../../../services/coops/relistings";

export const ActiveWidget: React.FC<{
  relisting: WithId<RealRelisting>;
}> = ({ relisting }) => {
  const [submitting, setSubmitting] = useState(false);

  // "optimistic" state for isActive -- it won't update immediatly from the relisting,
  // so we use this local state to keep track of the optimistic state, but use
  // the relisting's data if it ever updates
  const [isActive, setIsActive] = useState(relisting.relistingMetaData.isActive);

  useEffect(() => {
    setIsActive(relisting.relistingMetaData.isActive);
  }, [relisting.relistingMetaData.isActive]);

  // Since we expect toggling an element to remove from the "active"
  // category, we shouldn't set state in an async callback without
  // knowing if the component still is mounted.
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const toggleIsActive = useCallback(async () => {
    setSubmitting(true);
    try {
      const newIsActive = !isActive;
      await setRelistingIsActive(relisting, newIsActive);
      setIsActive(newIsActive);
    } catch (e) {
      notification.error({
        message:
          "Oops! Something went wrong. Try again in a few seconds and contact support if this problem persists.",
      });
    }

    if (mounted.current) {
      setSubmitting(false);
    }
  }, [isActive, relisting]);

  return (
    <Button
      danger={isActive}
      loading={submitting}
      type={isActive ? "ghost" : "primary"}
      onClick={toggleIsActive}
    >
      {/* We fight for horizontal space on this screen,
                so when submitting just show the loader, not the text too.
              */}
      {submitting ? "" : isActive ? "Hide" : "Show"}
    </Button>
  );
};
