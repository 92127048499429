import {
  ChildOrder,
  WithId,
  firestoreToMoment,
  isSeller,
  SellerOrderDetails,
  SubType,
} from "@rooted/shared";
import { Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { ColumnType } from "antd/lib/table";
import React from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../../components/FormControls/CurrencyInput";
import { CopyCode } from "../../../components/Misc/CopyCode";
import OverflowParagraph from "../../../components/OverflowParagraph";
import { useRooted } from "../../../RootedContext";
import { sortByLocaleCompare } from "../../../utils/sortByLocaleCompare";
import { ExclamationCircleTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import firebase from "firebase/app";
import { stringifyFulfillment } from "../../../utils/fulfillment";

export type OrderWithSelectedSellerDetails<T extends ChildOrder = ChildOrder> = {
  order: WithId<T>;
  selectedSellerDetails: SellerOrderDetails;
};

const OrderNumberLink: React.FC<{ order: WithId<ChildOrder> }> = ({
  order: { number, sellerId, _id },
}) => {
  const { activeRole } = useRooted();
  const profileId = activeRole?.profileId;
  return (
    <Link to={_id}>
      {isSeller(activeRole) && sellerId !== profileId ? `${number} (coop)` : number}
    </Link>
  );
};

const BuyerName: React.FC<{ order: WithId<ChildOrder> }> = ({
  order: { sellerId, buyerId, buyerDisplayName, sellerDisplayName, isRetail },
}) => {
  const { activeRole } = useRooted();
  const profileId = activeRole?.profileId;

  return (
    <>
      <b>{buyerDisplayName}</b> {isRetail && " (Retail)"}
      {profileId !== sellerId && (
        <>
          {" via "} <b>{sellerDisplayName}</b>
        </>
      )}
    </>
  );
};

const OrderNumberColumn: ColumnType<OrderWithSelectedSellerDetails> = {
  title: "Order #",
  key: "number",
  width: 100,
  sorter: ({ order: a }, { order: b }) => a.number - b.number,
  render: (_, { order }) => <OrderNumberLink order={order} />,
};

type DateKey = keyof SubType<ChildOrder, firebase.firestore.Timestamp>;

const localTimeZone = new Date()
  .toLocaleTimeString("en-us", { timeZoneName: "short" })
  .split(" ")[2];

function makeDateColumn({
  title,
  dateKey,
}: {
  title: string;
  dateKey: DateKey;
}): ColumnType<OrderWithSelectedSellerDetails> {
  return {
    title,
    key: title,
    width: 120,
    sorter: ({ order: { [dateKey]: a } }, { order: { [dateKey]: b } }) => {
      if (!a && !b) return 0;
      if (!a || !b) return a ? 1 : -1;
      return firestoreToMoment(a).isBefore(firestoreToMoment(b)) ? -1 : 1;
    },
    render: (_, { order: { [dateKey]: date } }) => {
      if (!date) return null;
      const moment = firestoreToMoment(date);
      const text = moment.isBefore(new Date().getTime() - 24 * 60 * 60 * 1000)
        ? moment.format("M/D/YY")
        : moment.fromNow();

      return (
        <Typography.Text title={`${moment.toDate().toLocaleString()} ${localTimeZone}`}>
          {text}
        </Typography.Text>
      );
    },
  };
}

const PlacedAtColumn = makeDateColumn({ title: "Placed", dateKey: "placedAt" });
const FulfilledAtColumn = makeDateColumn({ title: "Processed", dateKey: "fulfilledAt" });
const CancelledAtColumn = makeDateColumn({ title: "Cancelled", dateKey: "cancelledAt" });

const SellerNameColumn: ColumnType<OrderWithSelectedSellerDetails> = {
  title: "Seller",
  key: "name",
  sorter: ({ order: a }, { order: b }) => sortByLocaleCompare("sellerDisplayName")(a, b),
  render: (_, { order: { sellerDisplayName } }) => <>{sellerDisplayName}</>,
};

const BuyerNameColumn: ColumnType<OrderWithSelectedSellerDetails> = {
  title: "Buyer",
  key: "name",
  sorter: ({ order: a }, { order: b }) => sortByLocaleCompare("buyerDisplayName")(a, b),
  render: (_, { order }) => <BuyerName order={order} />,
};

const FulfillmentColumn: ColumnType<OrderWithSelectedSellerDetails> = {
  title: "Fulfillment",
  key: "fulfillment",
  width: 140,
  render: (_, { order: { fulfillment } }) => (
    <span style={{ textTransform: "capitalize" }}>{stringifyFulfillment(fulfillment)}</span>
  ),
  sorter: ({ order: { fulfillment: a } }, { order: { fulfillment: b } }) => {
    // Put non-physical orders first when ascending
    if (a.type !== "physical" && b.type !== "physical") return 0;
    if (a.type !== "physical") return -1;
    if (b.type !== "physical") return 1;

    return a.date - b.date;
  },
};

const ItemCountColumn: ColumnType<OrderWithSelectedSellerDetails> = {
  title: "# of Items",
  key: "items",
  className: "text-center",
  width: 100,
  render: (_, { selectedSellerDetails }) => (
    <Typography.Text>{selectedSellerDetails.itemCounts.total}</Typography.Text>
  ),
  sorter: (
    {
      selectedSellerDetails: {
        itemCounts: { total: a },
      },
    },
    {
      selectedSellerDetails: {
        itemCounts: { total: b },
      },
    }
  ) => a - b,
};

const TotalCostColumn: ColumnType<OrderWithSelectedSellerDetails> = {
  title: "total",
  key: "total",
  width: 70,
  className: "text-right",
  render: (_, { selectedSellerDetails }) => (
    <Typography.Text>
      {formatCurrency(selectedSellerDetails.calculatedPricing.total)}
    </Typography.Text>
  ),
  sorter: (
    {
      selectedSellerDetails: {
        calculatedPricing: { total: a },
      },
    },
    {
      selectedSellerDetails: {
        calculatedPricing: { total: b },
      },
    }
  ) => a - b,
};

function defaultDescend<T>(
  col: ColumnType<T>
): ColumnType<T> & {
  defaultSortOrder: "descend";
} {
  return {
    ...col,
    defaultSortOrder: "descend" as const,
  };
}

export function makeOrderTableColumns({
  status,
  nameColumn,
}: {
  status: ChildOrder["status"] | "all";
  nameColumn: "buyer" | "seller";
}): ColumnsType<OrderWithSelectedSellerDetails> {
  return [
    OrderNumberColumn,
    status === "fulfilled" || status === "cancelled"
      ? PlacedAtColumn
      : defaultDescend(PlacedAtColumn),
    ...(status === "fulfilled" ? [defaultDescend(FulfilledAtColumn)] : []),
    ...(status === "cancelled" ? [defaultDescend(CancelledAtColumn)] : []),
    ...(nameColumn === "buyer" ? [BuyerNameColumn] : [SellerNameColumn]),
    FulfillmentColumn,
    ItemCountColumn,
    TotalCostColumn,
  ];
}

// Admin Special Columns:
const StatusColumn: ColumnType<OrderWithSelectedSellerDetails> = {
  title: "Status",
  key: "status",
  render: (_, { order: { status } }) => status,
  sorter: ({ order: a }, { order: b }) => sortByLocaleCompare("status")(a, b),
};

const IdAndNumberColumn: ColumnType<OrderWithSelectedSellerDetails> = {
  title: "Id / Number",
  key: "id-number",
  render: (_, { order: { _id, number } }) => (
    <>
      <CopyCode>{_id}</CopyCode>
      <Link to={_id}>{number}</Link>{" "}
    </>
  ),
};

const ProcessingErrorColumn: ColumnType<OrderWithSelectedSellerDetails> = {
  title: "Er", // title takes more space than the render body
  key: "error",
  render: (_, { order: { processingError } }) =>
    processingError ? (
      processingError.isResolved ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      ) : (
        <ExclamationCircleTwoTone twoToneColor="#ff0033" />
      )
    ) : null,
  sorter: ({ order: a }, { order: b }) => errorSortValue(a) - errorSortValue(b),
};

const errorSortValue = (order: ChildOrder) => {
  if (order.processingError) {
    return order.processingError.isResolved ? 3 : 2;
  }
  return 1;
};

export function makeAdminOrderTableColumns(
  status?: ChildOrder["status"]
): ColumnsType<OrderWithSelectedSellerDetails> {
  return [
    IdAndNumberColumn,
    ProcessingErrorColumn,
    StatusColumn,
    status === "fulfilled" || status === "cancelled"
      ? PlacedAtColumn
      : defaultDescend(PlacedAtColumn),
    ...(status === "fulfilled" ? [defaultDescend(FulfilledAtColumn)] : []),
    ...(status === "cancelled" ? [defaultDescend(CancelledAtColumn)] : []),
    {
      ...SellerNameColumn,
      width: 260,
      render: (_, { order: { detailsByOriginalSeller, sellerDisplayName } }) => (
        <>
          <b>{sellerDisplayName}</b>
          {Object.values(detailsByOriginalSeller).length > 1 && (
            <OverflowParagraph expandable style={{ marginBottom: 0 }}>
              {Object.values(detailsByOriginalSeller)
                .filter(({ sellerDisplayName: name }) => name !== sellerDisplayName)
                .map(({ sellerDisplayName }) => sellerDisplayName)
                .join(", ")}
            </OverflowParagraph>
          )}
        </>
      ),
    },
    {
      ...BuyerNameColumn,
      render: (_, { order: { buyerDisplayName, buyerId } }) => (
        <>
          <b>{buyerDisplayName}</b> {buyerId.includes("retail") && " (Retail)"}
        </>
      ),
    },
    FulfillmentColumn,
    ItemCountColumn,
    TotalCostColumn,
  ];
}
