import { useRef, useEffect } from "react";

/**
 * Returns a boolean ref to determine if the component is still mounted.
 * Useful for async logic that can't occur after a dismount.
 */
export const useIsMounted = () => {
  const mounted = useRef(true);
  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );
  return mounted;
};
