import { BuyerProfile, WithId } from "@rooted/shared";
import { db, useCollectionDataChecked } from "../firebase";
import { makePrefixQuery, useOldDataOnLoad } from "../higher-order-searches";

/** Search *all* site customers by name / email prefix */
export const useCustomerResults = (search: string) => {
  const lowercaseSearch = search.toLowerCase();

  const baseQuery = db
    .collection("profiles")
    .where("type", "in", ["wholesale-buyer", "retail-buyer"]);

  return useOldDataOnLoad(
    useCollectionDataChecked<WithId<BuyerProfile>>(
      lowercaseSearch.trim() === ""
        ? null
        : makePrefixQuery(baseQuery, "bio._displayNameLowerCase", lowercaseSearch)
            .orderBy("bio._displayNameLowerCase")
            .limit(10),
      { idField: "_id" }
    )
  );
};
