import { Button, Col, Typography } from "antd";
import { BlockProps } from "antd/lib/typography/Base";
import React, { useState } from "react";
import "./OverflowParagraph.less";

/**
 * Due to a bug in how typography sets the bounding parent,
 * going from an `expandable: true` to `expandable: false`
 * state will cause a single row paragraph to overflow.
 *
 * We fix this by rendering conditionally on the expandable,
 * so that the paragraph node never transitions *into* an `expandable: true`
 * state, but is instead always *created* in that state.
 */
const OverflowParagraph: React.FC<{
  type?: BlockProps["type"];
  expandable?: boolean;
  expandableRows?: number;
  style?: React.CSSProperties;
}> = ({ children, type, expandable, style, expandableRows = 1 }) => {
  const [expanded, setExpanded] = useState(true);

  const toggle = () => setExpanded((e) => !e);

  if (expanded) {
    return (
      <Typography.Paragraph
        type={type}
        style={style}
        className={"expandable-paragraph"}
        ellipsis={{
          rows: expandableRows,
          expandable: expanded,
          onExpand: () => {
            toggle();
          },
          // hack to hide the expand button, allowing this component
          // to be used for an identical layout if needed
          symbol: expandable ? undefined : <>{null}</>,
        }}
      >
        {children}
      </Typography.Paragraph>
    );
  }

  return (
    <Col style={{ padding: 0 }}>
      <Typography.Paragraph style={{ marginBottom: 0 }} type={type}>
        {/* Intentional whitespace! force a space between the collapse. Extra will be auto stripped */}
        {children}{" "}
        <Button type={"link"} onClick={toggle} style={{ float: "right" }}>
          Collapse
        </Button>
      </Typography.Paragraph>
    </Col>
  );
};
export default OverflowParagraph;
