import React from "react";
import { OrdersTable } from "./OrderTable/OrderTable";
import { PageHeader } from "../../components/PageHeader";

export const CancelledOrders: React.FC = () => (
  <>
    <PageHeader title={"Cancelled Orders"} breadcrumb />
    <OrdersTable status="cancelled" />
  </>
);
