import {
  ApprovedConnection,
  RequestedConnection,
  Connection,
  WithId,
  EnterpriseProfile,
  formatPlural,
  assertIsEnterprise,
  isSeller,
  EnterpriseRole,
} from "@rooted/shared";
import { notification, Tabs } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { PageHeader } from "../../components/PageHeader";
import { logError } from "../../sentry";
import { useRooted } from "../../RootedContext";
import { ApprovedConnectionList } from "./ApprovedConnectionList";
import { getConnectionsWithProfiles } from "./connectionWithProfile";
import { RequestedConnectionList } from "./RequestedConnectionList";
import firebase from "firebase/app";
import { RetailConnectionCard } from "./RetailConnectionCard";
const { TabPane } = Tabs;

type ConnectionWithProfile<T extends Connection = Connection> = {
  connection: WithId<T>;
  profile: WithId<EnterpriseProfile>;
  distance: number | null; // we pre-compute this to make sorting by distance easy.
};

export const ConnectionsPane: React.FC = () => {
  const { activeRole: role } = useRooted();
  assertIsEnterprise(role);

  const [connectionsWithProfiles, setConnectionsWithProfiles] = useState<{
    approved: ConnectionWithProfile<ApprovedConnection>[];
    requestedToClient: ConnectionWithProfile<RequestedConnection>[];
    requestedByClient: ConnectionWithProfile<RequestedConnection>[];
  }>({
    approved: [],
    requestedToClient: [],
    requestedByClient: [],
  });

  const [loading, setLoading] = useState(true);

  const fetchConnections = useCallback(async () => {
    try {
      const connections = await getConnectionsWithProfiles(
        role.profileId,
        role.type === "wholesale-buyer" ? "buyer" : "seller",
        role.profile.bio.location
      );
      setConnectionsWithProfiles(connections);
    } catch (error) {
      notification.error({ message: "Oops! Something went wrong looking up your connections." });
      logError({
        error,
        tags: { page: "connections" },
      });
    }
    setLoading(false);
  }, [role.profile.bio.location, role.profileId, role.type]);

  // Only fetch this on first mount.
  const fetched = useRef(false);
  useEffect(() => {
    if (!fetched.current) {
      fetchConnections();
      fetched.current = true;
    }
  }, [fetchConnections]);

  // Since we aren't listening to the connections snapshot (it gets updated a lot!),
  // we need to manually update our list.
  const removeApprovedConnection = useCallback(
    (id: string) => {
      setConnectionsWithProfiles({
        ...connectionsWithProfiles,
        approved: connectionsWithProfiles.approved.filter((c) => c.connection._id !== id),
      });
    },
    [connectionsWithProfiles]
  );

  const removeConnectionRequest = useCallback(
    (id: string) => {
      setConnectionsWithProfiles({
        ...connectionsWithProfiles,
        // Connection requests may be removed from either list
        // ("Decline" incoming request, or "Cancel" outgoing request)
        requestedToClient: connectionsWithProfiles.requestedToClient.filter(
          (c) => c.connection._id !== id
        ),
        requestedByClient: connectionsWithProfiles.requestedByClient.filter(
          (c) => c.connection._id !== id
        ),
      });
    },
    [connectionsWithProfiles]
  );

  const movePendingRequestToApproved = useCallback(
    (id: string) => {
      const requestedConnection = connectionsWithProfiles.requestedToClient.find(
        (c) => c.connection._id === id
      );
      if (!requestedConnection) return;
      // This is a little hacky, but results in immediate state updates without any network call latency.
      // Ideally, `approveConnection` would return the new connection, and we could just use that.
      // However, we don't actually care about the new fields, since we don't use the hydrated form of that
      // in `ApprovedConnectionList` (we use a listener on the doc)
      const approvedConnection: ConnectionWithProfile<ApprovedConnection> = {
        ...requestedConnection,
        connection: {
          ...requestedConnection.connection,
          approvedOn: firebase.firestore.Timestamp.now(),
          deliverySettings: {
            enabled: false,
            price: 0,
            weekdays: [],
          },
          pickupSettings: {
            enabled: false,
            weekdays: [],
          },
          orderingEnabled: false,
          status: "approved",
        },
      };
      setConnectionsWithProfiles({
        ...connectionsWithProfiles,
        requestedToClient: connectionsWithProfiles.requestedToClient.filter(
          (c) => c.connection._id !== id
        ),
        approved: [...connectionsWithProfiles.approved, approvedConnection],
      });
    },
    [connectionsWithProfiles]
  );

  const connectionTypeCopy = role.type === "wholesale-buyer" ? "Seller" : "Buyer";

  const hasOutgoingRequests = connectionsWithProfiles.requestedByClient.length > 0;
  const hasIncomingRequests = connectionsWithProfiles.requestedToClient.length > 0;

  return (
    <>
      <PageHeader title={`My ${formatPlural(connectionTypeCopy)}`} />

      {!loading && (hasOutgoingRequests || hasIncomingRequests) && (
        <Tabs
          className="no-margin"
          defaultActiveKey={hasIncomingRequests ? "incoming" : "outgoing"}
          size={"small"}
          style={{ marginBottom: 12 }}
        >
          {hasIncomingRequests && (
            <TabPane
              tab={`New Requests (${connectionsWithProfiles.requestedToClient.length})`}
              key="incoming"
            >
              <RequestedConnectionList
                dataSource={connectionsWithProfiles.requestedToClient}
                onCancelRequest={removeConnectionRequest}
                onApproveRequest={movePendingRequestToApproved}
                requestRelationship="to-client"
              />
            </TabPane>
          )}
          {hasOutgoingRequests && (
            <TabPane
              tab={`Your Requests (${connectionsWithProfiles.requestedByClient.length})`}
              key="outgoing"
            >
              <RequestedConnectionList
                dataSource={connectionsWithProfiles.requestedByClient}
                onCancelRequest={removeConnectionRequest}
                // no-op: this doesn't occur
                onApproveRequest={() => {
                  logError({
                    error: new Error("Outgoing request approved"),
                  });
                }}
                requestRelationship="from-client"
              />
            </TabPane>
          )}
        </Tabs>
      )}

      {isSeller(role) && <RetailConnectionCard style={{ marginBottom: 12 }} />}

      <ApprovedConnectionList
        loading={loading}
        dataSource={connectionsWithProfiles.approved}
        onDeleteConnection={removeApprovedConnection}
      />
    </>
  );
};
