import React, { useMemo } from "react";
import {
  Connection,
  EnterpriseProfile,
  firestoreToMoment,
  makeProfileRoute,
  WithId,
} from "@rooted/shared";
import { Col, Row, Tag, Typography } from "antd";
import { EnterpriseAvatar } from "../../components/Images/ThumbnailAvatar";
import { Link } from "react-router-dom";
import { ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { ProfileDistance } from "./ProfileDistance";

export const ProfileConnectionBlurb: React.FC<{
  profile: WithId<EnterpriseProfile>;
  connection: Connection;
  style?: React.CSSProperties;
  extra?: React.ReactNode;
}> = ({ profile, connection, extra, style }) => {
  const timestamp =
    connection.status === "approved" ? connection.approvedOn : connection.requestedOn;
  const { timestampCopy, isNew } = useMemo(() => {
    const tsMoment = firestoreToMoment(timestamp);
    return {
      isNew: tsMoment.isAfter(moment().subtract(3, "days")),
      timestampCopy: tsMoment.fromNow(),
    };
  }, [timestamp]);

  return (
    <Row gutter={[8, 8]} style={style}>
      <Col>
        <EnterpriseAvatar profile={profile} size="large" style={{ marginTop: 4 }} />
      </Col>
      <Col style={{ flex: 1, flexBasis: "min-content" }}>
        <Link to={makeProfileRoute(profile)}>
          <Typography.Link style={{ fontSize: 18, marginBottom: 0 }}>
            {profile.bio.displayName}
          </Typography.Link>
        </Link>
        <ProfileDistance profile={profile} />
        <Typography.Text
          type="secondary"
          style={{ fontSize: 14, marginBottom: 0, display: "block" }}
        >
          <ClockCircleOutlined /> {connection.status === "approved" ? "Connected" : "Requested"}{" "}
          {timestampCopy}
          {isNew && (
            <Tag style={{ marginLeft: 8 }} color="cyan">
              NEW
            </Tag>
          )}
        </Typography.Text>
      </Col>
      {extra}
    </Row>
  );
};
