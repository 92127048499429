import React, { useState } from "react";
import { Button, Card, Checkbox, Form, Input, InputNumber, Select, Space } from "antd";
import { PageContent, WideLayout } from "../../layouts";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { addTaxRate, CreateTaxRateParams } from "../../../services/admins/tax-rates";
import { PageHeader } from "../../../components/PageHeader";

export const CreateTaxRate: React.FC = () => {
  const [, setLoading] = useState(false);

  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    setLoading(true);

    //Add each prototype in parallel
    const promises = values["rates"].map(async (x: any) => {
      const val: CreateTaxRateParams = {
        name: x["name"],
        zipCodes: x["zipCodes"],
        active: x["active"],
        rate: x["rate"],
      };
      return addTaxRate(val);
    });

    await Promise.all(promises);
    setLoading(false);
    navigate("/tax-rates");
  };

  return (
    <WideLayout>
      <PageContent>
        <PageHeader title="Create Tax Rates" breadcrumb />
        <Form layout="vertical" requiredMark="optional" onFinish={onFinish}>
          <Form.List name="rates">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field) => (
                    <Card key={field.key} style={{ marginBottom: 16 }}>
                      <div style={{ textAlign: "right" }}>
                        <Button
                          type="link"
                          onClick={() => {
                            remove(field.name);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Space direction="vertical" size={0} style={{ flex: "1" }}>
                          <Form.Item
                            label="Name"
                            name={[field.name, "name"]}
                            fieldKey={[field.fieldKey, "name"]}
                            rules={[{ required: true, message: " " }]}
                          >
                            <Input></Input>
                          </Form.Item>
                          <Form.Item
                            label="Zip Codes"
                            name={[field.name, "zipCodes"]}
                            fieldKey={[field.fieldKey, "zipCodes"]}
                            rules={[{ required: true, message: " " }]}
                          >
                            <Select mode="tags"></Select>
                          </Form.Item>
                          <Form.Item
                            label="Rate"
                            name={[field.name, "rate"]}
                            fieldKey={[field.fieldKey, "rate"]}
                            rules={[{ required: true, message: " " }]}
                          >
                            <InputNumber
                              defaultValue={0}
                              min={0}
                              max={100}
                              formatter={(value) => `${value}%`}
                              parser={(value) => value?.replace("%", "") || ""}
                            />
                          </Form.Item>

                          <Form.Item
                            requiredMark={undefined}
                            name={[field.name, "active"]}
                            fieldKey={[field.fieldKey, "active"]}
                            valuePropName="checked"
                            initialValue={true}
                          >
                            <Checkbox>Active</Checkbox>
                          </Form.Item>
                        </Space>
                      </div>
                    </Card>
                  ))}

                  <Form.Item className="full-width">
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add Tax Rate
                    </Button>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </PageContent>
    </WideLayout>
  );
};
