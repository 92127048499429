import {
  createRelisting,
  FullCoopRole,
  NarrowFromCategory,
  RealRelisting,
  RealRelistingOptionCategory,
  RelistingGeneric,
  RelistingOption,
  WithId,
} from "@rooted/shared";
import { db, snapshotToIdDoc, useCollectionDataChecked, useDocumentDataChecked } from "../firebase";

export function useRelisting<T extends RelistingOption>(relistingId: string) {
  return useDocumentDataChecked<RelistingGeneric<T>>(db.collection("relistings").doc(relistingId), {
    idField: "_id",
  });
}

/**  */
export async function updateRelistingPrice<T extends RelistingOption>(
  { _id: relistingId }: WithId<RelistingGeneric<T>>,
  pricing: RelistingGeneric<T>["pricing"]
) {
  const relistingRef = db.collection("relistings").doc(relistingId);

  // Ensure the relisting is up-to-date before we use it
  await db.runTransaction(async (t) => {
    const relisting = snapshotToIdDoc<RelistingGeneric<T>>(await t.get(relistingRef));
    if (!relisting) throw new Error("Relisting does not exist");

    t.set(
      relistingRef,
      createRelisting(
        relisting.backingListing,
        { ...relisting.overrides, pricing },
        relisting.relistingMetaData
      )
    );
  });
}

export async function clearRelistingPricing<T extends RelistingOption>({
  _id: relistingId,
}: WithId<RelistingGeneric<T>>) {
  const relistingRef = db.collection("relistings").doc(relistingId);

  // Ensure the relisting is up-to-date before we use it
  await db.runTransaction(async (t) => {
    const relisting = snapshotToIdDoc<RelistingGeneric<T>>(await t.get(relistingRef));
    if (!relisting) throw new Error("Relisting does not exist");

    const oldOverrides = { ...relisting.overrides };
    delete oldOverrides.pricing;

    t.set(
      relistingRef,
      createRelisting(relisting.backingListing, oldOverrides, relisting.relistingMetaData)
    );
  });
}

export async function setRelistingIsActive<T extends RelistingOption>(
  { _id: relistingId }: WithId<RelistingGeneric<T>>,
  isActive: boolean
) {
  const relistingRef = db.collection("relistings").doc(relistingId);

  await db.runTransaction(async (t) => {
    const relisting = snapshotToIdDoc<RelistingGeneric<T>>(await t.get(relistingRef));
    if (!relisting) throw new Error("Relisting does not exist");

    t.update(relistingRef, {
      "relistingMetaData.isActive": isActive,
    });
  });
}

/**
 * Hook to listen to all of the listings that a coop has relisted
 * that the original
 *
 * @param category The category to query for
 * @param role The user's coop role
 */
export function useAllActiveRelistings<T extends RealRelistingOptionCategory>(
  category: T,
  role: FullCoopRole
) {
  return useCollectionDataChecked<WithId<NarrowFromCategory<RealRelisting, T>>>(
    db
      .collection("relistings")
      .orderBy("product.name")
      .where("category", "==", category)
      .where("seller.profileId", "==", role.profileId)
      .where("categoryAvailableViaCoops", "==", true)
      .where("backingListing.availability.active", "==", true),
    { idField: "_id" }
  );
}
