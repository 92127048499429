import { TaxRate } from "@rooted/shared";
import { Button, Checkbox, Form, Select, Input, InputNumber } from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRouterBreadcrumb } from "../../../components/Misc/useRouterBreadcrumb";
import { PageHeader } from "../../../components/PageHeader";
import { makeTaxRate } from "../../../services/admins/tax-rates";
import { db, useDocumentDataChecked } from "../../../services/firebase";
import { LoadingPage, PageContent, WideLayout, NotFoundPage } from "../../layouts";

export const TaxRateDetails: React.FC = () => {
  const { itemId } = useParams();
  const [taxRate, taxRateLoading] = useDocumentDataChecked<TaxRate>(
    db.collection("taxRates").doc(itemId)
  );

  const breadcrumb = useRouterBreadcrumb({ [itemId]: taxRate?.name || "..." });

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    setLoading(true);
    const newRate = makeTaxRate(values);
    await db.collection("taxRates").doc(itemId).set(newRate);
    setLoading(false);
    navigate("/tax-rates");
  };

  if (!taxRate && !taxRateLoading) return <NotFoundPage />;
  return (
    <>
      <WideLayout>
        <PageContent>
          <PageHeader title={taxRate?.name || "..."} breadcrumb={breadcrumb} />
          {taxRate && (
            <Form
              layout="vertical"
              requiredMark="optional"
              onFinish={onFinish}
              initialValues={taxRate}
            >
              <Form.Item label="Name" name="name" rules={[{ required: true, message: " " }]}>
                <Input></Input>
              </Form.Item>
              <Form.Item
                label="Zip Codes"
                name="zipCodes"
                rules={[{ required: true, message: " " }]}
              >
                <Select mode="tags"></Select>
              </Form.Item>
              <Form.Item label="Rate" name="rate" rules={[{ required: true, message: " " }]}>
                <InputNumber
                  defaultValue={0}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value?.replace("%", "") || ""}
                />
              </Form.Item>

              <Form.Item requiredMark={undefined} name="active" valuePropName="checked">
                <Checkbox>Active</Checkbox>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Update
                </Button>
              </Form.Item>
            </Form>
          )}
          {!taxRate && <LoadingPage />}
        </PageContent>
      </WideLayout>
    </>
  );
};
