import { Info as LuxonInfo } from "luxon";
import { wordListToSentence } from "./wordListToSentence";

const months = LuxonInfo.months();

/**
 * Converts a list of months to a formatted range string.
 *
 * @example
 * // returns "September - October and December"
 * monthListToRanges(["September", "October", "December"])
 */
export const monthListToRanges = (selectedMonths: string[]): string => {
  const sortedSelectedMonths = selectedMonths.sort((a, b) => months.indexOf(a) - months.indexOf(b));
  const groups = sortedSelectedMonths.reduceRight((acc, curr) => {
    const lastGroup = acc.length === 0 ? null : acc[acc.length - 1];
    if (!lastGroup) {
      acc.push([curr]);
      return acc;
    }
    const lastGroupLastElement = lastGroup[lastGroup.length - 1];
    if (months.indexOf(lastGroupLastElement) === months.indexOf(curr) + 1) {
      lastGroup.push(curr);
    } else {
      acc.push([curr]);
    }
    return acc;
  }, [] as string[][]);

  const ranges = groups.reduceRight((acc, curr) => {
    const [last] = curr;
    const first = curr.pop();
    if (first === last) acc.push(first);
    else acc.push(`${first} - ${last}`);
    return acc;
  }, [] as string[]);

  return wordListToSentence(ranges);
};
