import { PlusOutlined } from "@ant-design/icons";
import {
  assertCasesExhausted,
  assertIsGrower,
  DriedFlowerProductType,
  driedFlowerProductTypes,
  driedFlowerUsesBunchUnit,
  getDriedFlowerProductTypeLabel,
  StagedListing,
} from "@rooted/shared";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Radio,
  Row,
  Typography,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { FormListFieldData } from "antd/lib/form/FormList";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CurrencyInput } from "../../../components/FormControls/CurrencyInput";
import { MediaLibrarySelect } from "../../../components/FormControls/MediaLibrarySelect";
import { DateRangePicker } from "../../../components/Misc/DateRangePicker";
import { PageHeader } from "../../../components/PageHeader";
import { useRooted } from "../../../RootedContext";
import { logError } from "../../../sentry";
import { addListings } from "../../../services/sellers/listings";
import { RenderFn } from "../../../utils/renderFn";
import { StemCountInput } from "./utils/BouquetUtils";
import { CSASeasonInput } from "./utils/CSAUtils";
import {
  BloomSizeInput,
  BouquetPrototypeSelect,
  BucketPrototypeSelect,
  FlowerPrototypeInput,
  PricingGuide,
  SingleColorInput,
  StemLengthInput,
  StemsPerBunchInput,
  VaseLifeInput,
} from "./utils/FlowerUtils";
import { PottedPlantTagsSelect, PottedPlantTypeInput } from "./utils/PottedPlantUtils";
import { WreathDepthInput, WreathDiameterInput, WreathTagsInput } from "./utils/WreathUtils";

/**
 * Serialize the data with JSON parse / JSON stringify in order to remove any non-JSON values
 * before the data is passed to firebase functions, which is unhappy with being passed values like
 * moment() or Date. In the future we might want to find and convert these values in particular
 * instead of doing a wholesale (ha) JSON.stringify -> JSON.parse
 */
function serializeListingFieldValues(listings: any) {
  return JSON.parse(JSON.stringify(listings));
}

const coerceEmptyToUndefined = (value: any) => value ?? undefined;

const NewlineText: React.FC<{ text: string }> = ({ text }) => {
  return (
    <>
      {text.split("\n").map((str, idx) => (
        <p key={idx}>{str}</p>
      ))}
    </>
  );
};

/**
 * This component renders the form that allows users to add new listings. This component
 * is tightly integrated with `addListings`, which takes the items from the form and
 * creates the listings. Because Antd forms do not have great type support, it is important
 * to test + keep the rendered items in these forms consistent with the listing validation schemas
 * so we don't get any runtime errors.
 */
export const AddInventory: React.FC<{ category: StagedListing["category"] }> = ({ category }) => {
  const { activeRole } = useRooted();
  assertIsGrower(activeRole);

  const [form] = useForm();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  return (
    <>
      <PageHeader title="Add New Listings" breadcrumb />
      <Form
        form={form}
        // Start with one empty, open card
        initialValues={{ listings: [{}] }}
        name="add-inventory"
        onFinish={async (fieldValues) => {
          setSubmitting(true);
          try {
            await addListings({
              listings: serializeListingFieldValues(fieldValues.listings),
              profileId: activeRole.profileId,
            });

            notification.success({
              message: `${fieldValues.listings.length} listing${
                fieldValues.listings.length > 1 ? "s" : ""
              } added to inventory`,
            });
            navigate(`/inventory/${category}s`);
          } catch (error) {
            logError({
              error,
              tags: {
                category,
              },
              extraData: {
                listings: fieldValues.listings,
              },
            });

            notification.error({
              message: "Oops! There was an issue submitting these listings.",
              description: <NewlineText text={error.message} />,
              duration: 0,
            });
          } finally {
            setSubmitting(false);
          }
        }}
        autoComplete="off"
        layout="vertical"
      >
        <Form.List name="listings">
          {(fields, { add, remove }) => {
            const addIsDisabled = fields.length >= 5;

            return (
              <>
                {fields.map((field) => (
                  <AddListingFormCard
                    category={category}
                    key={field.key}
                    form={form}
                    field={field}
                    onDelete={() => remove(field.name)}
                  />
                ))}
                <Form.Item className="full-width">
                  <Button disabled={addIsDisabled} type="dashed" onClick={add} block>
                    <PlusOutlined /> Add Another
                  </Button>
                  {addIsDisabled && (
                    <Typography.Paragraph style={{ textAlign: "center", paddingTop: 8 }}>
                      Woo hoo! Five new entries! Let's submit these and then keep going.
                    </Typography.Paragraph>
                  )}
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <Form.Item dependencies={["listings"]} noStyle>
          {({ getFieldValue }) => {
            const listings = getFieldValue("listings");

            return (
              <Button
                loading={submitting}
                type="primary"
                htmlType="submit"
                disabled={!listings?.length}
              >
                Submit
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </>
  );
};

export const AddListingFormCard: React.FC<{
  category: StagedListing["category"];
  onDelete: () => void;
  field: FormListFieldData;
  form: FormInstance<any>;
}> = ({ category, field, onDelete, form }) => {
  return (
    <Card
      style={{ marginBottom: 16 }}
      title={<Typography.Text type="secondary">Item #{field.fieldKey + 1}</Typography.Text>}
      extra={
        <Button style={{ marginLeft: 8, marginRight: 8 }} type="default" danger onClick={onDelete}>
          Remove
        </Button>
      }
    >
      <Form.Item
        label="Category"
        name={[field.name, "category"]}
        fieldKey={[field.fieldKey, "category"]}
        initialValue={category}
        hidden
      >
        <Input type="text" />
      </Form.Item>
      <Row gutter={32}>
        <Col>{renderPhotoStorageUrlFormItem({ field })}</Col>
        <Col flex="auto">
          <Row>
            <Col span={24}>{renderGenericNameFormItem({ field, category })}</Col>
          </Row>
          <Row>
            <Col span={24}>{renderDescriptionFormItem({ field })}</Col>
          </Row>
          <Row>{renderGenericPriceFormItem({ field, category })}</Row>
        </Col>
      </Row>

      <Row gutter={16}>{renderGenericCategoryDetailsFormItem({ field, category })}</Row>

      <Divider>Availability</Divider>
      {renderGenericAvailabilityFormItem({ category, field })}
    </Card>
  );
};

const driedFlowerNamePlaceholders: { [type in DriedFlowerProductType]: string } = {
  confetti: "Example name: Flower Confetti",
  "mixed-bunch": "Example name: Mixed Bunch",
  "single-variety": "Example name: Single Variety Bunch",
};

/**
 * Generic Form Items
 */

const renderGenericNameFormItem: RenderFn<{
  category: StagedListing["category"];
  field: FormListFieldData;
}> = ({ field, category }) => {
  switch (category) {
    case "cut-flower":
      return renderPrototypeIdFormItem({ field });
    case "dried-flower": {
      const typePath = ["listings", field.name, "type"];
      return (
        <Col>
          <Form.Item
            rules={[{ required: true, message: "Required" }]}
            label="Type"
            name={[field.name, "type"]}
            fieldKey={[field.fieldKey, "type"]}
            initialValue="single-variety"
          >
            <Radio.Group>
              {driedFlowerProductTypes.map((type) => (
                <Radio.Button key={type} value={type}>
                  {getDriedFlowerProductTypeLabel(type)}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item dependencies={[typePath]} noStyle>
            {({ getFieldValue }) => {
              const type: DriedFlowerProductType | undefined = getFieldValue(typePath);
              const usesFlowerPrototype = type === "single-variety";
              const placeholder: string | undefined = driedFlowerNamePlaceholders[type!];

              return (
                <>
                  <Form.Item
                    hidden={!usesFlowerPrototype}
                    rules={[{ required: usesFlowerPrototype, message: "Required" }]}
                    label="Flower"
                    name={[field.name, "prototypeId"]}
                    fieldKey={[field.fieldKey, "prototypeId"]}
                  >
                    <FlowerPrototypeInput />
                  </Form.Item>
                  <Form.Item
                    hidden={usesFlowerPrototype}
                    rules={[{ required: !usesFlowerPrototype, message: "Required" }]}
                    label="Name"
                    name={[field.name, "name"]}
                    fieldKey={[field.fieldKey, "name"]}
                  >
                    <Input type="text" placeholder={placeholder} />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
        </Col>
      );
    }
    case "bouquet":
      return (
        <Form.Item
          rules={[{ required: true, message: "Required" }]}
          label="Name"
          name={[field.name, "prototypeId"]}
          fieldKey={[field.fieldKey, "prototypeId"]}
        >
          <BouquetPrototypeSelect />
        </Form.Item>
      );
    case "bucket":
      return (
        <Form.Item
          rules={[{ required: true, message: "Required" }]}
          label="Name"
          name={[field.name, "prototypeId"]}
          fieldKey={[field.fieldKey, "prototypeId"]}
        >
          <BucketPrototypeSelect />
        </Form.Item>
      );
    case "wreath":
      return renderNameFormItem({ field, placeholder: "Example Name: Spring Wreath" });
    case "potted-plant":
      return renderNameFormItem({ field, placeholder: "Example Name: Snapdragon Starts" });
    case "csa":
      return renderNameFormItem({ field, placeholder: "Example Name: Spring Bulb Share" });
    default:
      assertCasesExhausted(category);
  }
};

const renderNameFormItem: RenderFn<{
  field: FormListFieldData;
  placeholder?: string;
}> = ({ field, placeholder }) => {
  return (
    <Form.Item
      rules={[{ required: true, message: "Required" }]}
      label="Name"
      name={[field.name, "name"]}
      fieldKey={[field.fieldKey, "name"]}
    >
      <Input type="text" placeholder={placeholder} />
    </Form.Item>
  );
};

const renderGenericPriceFormItem: RenderFn<{
  category: StagedListing["category"];
  field: FormListFieldData;
}> = ({ field, category }) => {
  switch (category) {
    case "cut-flower": {
      const prototypePath = ["listings", field.name, "prototypeId"];
      const pricePath = ["listings", field.name, "price"];
      return (
        <>
          <Col>{renderPriceFormItem({ field, label: "Price / Stem" })}</Col>
          <Col style={{ marginTop: 20 }}>
            <Form.Item dependencies={[prototypePath, pricePath]} noStyle>
              {({ getFieldValue }) => {
                return (
                  <PricingGuide
                    prototypeId={getFieldValue(prototypePath)}
                    price={getFieldValue(pricePath)}
                  />
                );
              }}
            </Form.Item>
          </Col>
        </>
      );
    }
    case "wreath":
    case "bouquet":
    case "bucket":
    case "potted-plant":
    case "csa":
      return <Col>{renderPriceFormItem({ field })}</Col>;
    case "dried-flower": {
      const typePath = ["listings", field.name, "type"];
      return (
        <Form.Item dependencies={[typePath]} noStyle>
          {({ getFieldValue }) => {
            const type: DriedFlowerProductType | undefined = getFieldValue(typePath);
            const label = "Price" + (type !== "confetti" ? " / Bunch" : "");

            return <Col>{renderPriceFormItem({ field, label })}</Col>;
          }}
        </Form.Item>
      );
    }
    default:
      assertCasesExhausted(category);
  }
};

const renderGenericCategoryDetailsFormItem: RenderFn<{
  category: StagedListing["category"];
  field: FormListFieldData;
}> = ({ field, category }) => {
  switch (category) {
    case "bouquet":
      return (
        <>
          <Col>{renderDominantColorFormItem({ field })}</Col>
          <Col>{renderStemCountFormItem({ field })}</Col>
        </>
      );
    case "bucket":
      return (
        <>
          <Col>{renderDominantColorFormItem({ field })}</Col>
          <Col>{renderMinStemsFormItem({ field })}</Col>
          <Col>{renderMaxStemsFormItem({ field })}</Col>
        </>
      );
    case "cut-flower":
      return (
        <>
          <Col>{renderDominantColorFormItem({ field })}</Col>
          <Col>{renderStemLengthFormItem({ field })}</Col>
          <Col>{renderStemsPerBunchFormItem({ field })}</Col>
          <Col>{renderVaseLifeFormItem({ field })}</Col>
          <Col>{renderBloomSizeFormItem({ field })}</Col>
        </>
      );
    case "dried-flower": {
      const typePath = ["listings", field.name, "type"];
      return (
        <Form.Item dependencies={[typePath]} noStyle>
          {({ getFieldValue }) => {
            const type: DriedFlowerProductType | undefined = getFieldValue(typePath);
            const usesFlowerFields = type && type !== "confetti";

            return (
              <>
                <Col>{renderDominantColorFormItem({ field })}</Col>
                <Col>
                  {renderStemCountFormItem({
                    field,
                    label: "Stems / Bunch (est.)",
                    placeholder: "10",
                    hidden: !usesFlowerFields,
                  })}
                </Col>
                <Col>{renderStemLengthFormItem({ field, hidden: !usesFlowerFields })}</Col>
              </>
            );
          }}
        </Form.Item>
      );
    }
    case "wreath":
      return (
        <>
          <Col>
            <Form.Item
              label="Tags"
              name={[field.name, "tags"]}
              fieldKey={[field.fieldKey, "tags"]}
              rules={[{ required: true, message: "Required" }]}
            >
              <WreathTagsInput />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Diameter (inches)"
              name={[field.name, "diameter"]}
              fieldKey={[field.fieldKey, "diameter"]}
              rules={[{ required: true, message: "Required" }]}
            >
              <WreathDiameterInput />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Depth (inches)"
              name={[field.name, "depth"]}
              fieldKey={[field.fieldKey, "depth"]}
              getValueFromEvent={coerceEmptyToUndefined}
            >
              <WreathDepthInput />
            </Form.Item>
          </Col>
        </>
      );
    case "potted-plant": {
      return (
        <>
          <Col>
            <Form.Item
              label="Type"
              name={[field.name, "pottedPlantType"]}
              fieldKey={[field.fieldKey, "pottedPlantType"]}
              rules={[{ required: true, message: "Required" }]}
            >
              <PottedPlantTypeInput />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Additional Info"
              name={[field.name, "tags"]}
              fieldKey={[field.fieldKey, "tags"]}
            >
              <PottedPlantTagsSelect />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Planter Size (inches)"
              name={[field.name, "potSize"]}
              fieldKey={[field.fieldKey, "potSize"]}
              getValueFromEvent={coerceEmptyToUndefined}
            >
              <InputNumber min={1} max={30} precision={1} step={1} />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Tray Size (# cells)"
              name={[field.name, "traySize"]}
              fieldKey={[field.fieldKey, "traySize"]}
              getValueFromEvent={coerceEmptyToUndefined}
            >
              <InputNumber min={1} max={500} step={1} precision={0} />
            </Form.Item>
          </Col>
        </>
      );
    }
    case "csa": {
      return (
        <>
          <Col>
            <Form.Item
              label="Season"
              name={[field.name, "season"]}
              fieldKey={[field.fieldKey, "season"]}
              rules={[{ required: true, message: "Required" }]}
            >
              <CSASeasonInput style={{ width: 200 }} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Weeks"
              name={[field.name, "numberOfWeeks"]}
              fieldKey={[field.fieldKey, "numberOfWeeks"]}
              validateTrigger={["onBlur", "onSubmit"]}
              rules={[
                {
                  required: true,
                  message: "Must be between 3 and 12",
                  type: "integer",
                  min: 3,
                  max: 12,
                },
              ]}
            >
              <InputNumber step={1} min={3} max={12} precision={0} style={{ width: 100 }} />
            </Form.Item>
          </Col>
        </>
      );
    }
    default:
      assertCasesExhausted(category);
  }
};

const quantityDisplayUnits: { [category in StagedListing["category"]]?: string } = {
  "cut-flower": "bunches",
  "dried-flower": "bunches",
};

const renderGenericAvailabilityFormItem: RenderFn<{
  category: StagedListing["category"];
  field: FormListFieldData;
}> = ({ field, category }) => {
  const typePath = ["listings", field.name, "type"];

  switch (category) {
    case "cut-flower":
    case "bouquet":
    case "bucket":
    case "dried-flower":
    case "wreath":
    case "potted-plant": {
      const activePath = ["listings", field.name, "availability", "active"];
      return (
        <Row wrap>
          {renderAvailabilityActiveFormItem({ field })}
          <Form.Item dependencies={[typePath]} noStyle>
            {({ getFieldValue }) => {
              if (category === "dried-flower") {
                const type: DriedFlowerProductType | undefined = getFieldValue(typePath);

                const units = driedFlowerUsesBunchUnit(type)
                  ? quantityDisplayUnits[category]
                  : undefined;
                return renderQuantityFormItem({ field, units });
              }

              return renderQuantityFormItem({ field, units: quantityDisplayUnits[category] });
            }}
          </Form.Item>

          <Form.Item dependencies={[activePath]} noStyle>
            {({ getFieldValue }) => {
              const active = getFieldValue(activePath);
              return (
                <Form.Item
                  label="Available Dates"
                  name={[field.name, "availability", "dates"]}
                  fieldKey={[field.fieldKey, "availability", "dates"]}
                  rules={[{ required: active, message: "Required" }]}
                  initialValue={undefined}
                >
                  <DateRangePicker
                    width={265}
                    format="MMM D, YYYY"
                    disabledDate={(current) => {
                      return current.isBefore(moment().subtract(1, "day"));
                    }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Row>
      );
    }
    case "csa": {
      return (
        <>
          <Form.Item
            label="Short Name"
            name={[field.name, "availability", "shortName"]}
            fieldKey={[field.fieldKey, "availability", "shortName"]}
            initialValue="CSA"
            hidden
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Required" }]}
            label="Order Fulfillment"
            name={[field.name, "availability", "description"]}
            fieldKey={[field.fieldKey, "availability", "description"]}
          >
            <Input.TextArea
              rows={3}
              placeholder="Describe the options for customers to receive their CSAs/Subscriptions (pickup locations, delivery, etc.)"
            />
          </Form.Item>

          <br />
          <Row wrap>
            {renderAvailabilityActiveFormItem({ field })}
            {renderQuantityFormItem({ field })}
          </Row>
        </>
      );
    }
    default:
      assertCasesExhausted(category);
  }
};

const defaultImageUrl = `${process.env.REACT_APP_BASE_URL}/images/inventory-default.png`;
const renderPhotoStorageUrlFormItem: RenderFn<{ field: FormListFieldData }> = ({ field }) => {
  return (
    <Form.Item
      label="Image"
      name={[field.name, "photoStorageUrl"]}
      fieldKey={[field.fieldKey, "photoStorageUrl"]}
      initialValue={defaultImageUrl}
    >
      <MediaLibrarySelect />
    </Form.Item>
  );
};

const renderAvailabilityActiveFormItem: RenderFn<{ field: FormListFieldData }> = ({ field }) => {
  return (
    <Form.Item
      initialValue={false}
      name={[field.name, "availability", "active"]}
      fieldKey={[field.fieldKey, "availability", "active"]}
      valuePropName="checked"
      style={{ marginRight: 16, marginTop: "auto", marginBottom: "auto" }}
    >
      <Checkbox>Visible Now</Checkbox>
    </Form.Item>
  );
};

const renderQuantityFormItem: RenderFn<{ field: FormListFieldData; units?: string }> = ({
  field,
  units,
}) => {
  return (
    <Form.Item
      label={"Quantity" + (units ? ` (${units})` : "")}
      name={[field.name, "availability", "quantity"]}
      fieldKey={[field.fieldKey, "availability", "quantity"]}
      style={{ marginRight: 16 }}
      rules={[{ required: true, message: "Required", type: "number", min: 0 }]}
      required={false} // Do not show the required mark so users don't think they need to add quantity
      initialValue={0}
    >
      <InputNumber min={0} max={100000} step={1} precision={0} />
    </Form.Item>
  );
};

/**
 * Category-Specific Form Items
 */
const renderPrototypeIdFormItem: RenderFn<{ field: FormListFieldData }> = ({ field }) => {
  return (
    <Form.Item
      rules={[{ required: true, message: "Required" }]}
      label="Flower"
      name={[field.name, "prototypeId"]}
      fieldKey={[field.fieldKey, "prototypeId"]}
    >
      <FlowerPrototypeInput />
    </Form.Item>
  );
};

const renderDominantColorFormItem: RenderFn<{ field: FormListFieldData }> = ({ field }) => {
  return (
    <Form.Item
      rules={[{ required: true, message: "Required" }]}
      label="Dominant Color"
      name={[field.name, "dominantColor"]}
      fieldKey={[field.fieldKey, "dominantColor"]}
    >
      <SingleColorInput />
    </Form.Item>
  );
};

const renderStemLengthFormItem: RenderFn<{ field: FormListFieldData; hidden?: boolean }> = ({
  field,
  hidden,
}) => {
  return (
    <Form.Item
      hidden={hidden}
      rules={[{ required: !hidden, message: "Required" }]}
      label="Stem Length"
      name={[field.name, "stemLength"]}
    >
      <StemLengthInput />
    </Form.Item>
  );
};

const renderStemsPerBunchFormItem: RenderFn<{ field: FormListFieldData }> = ({ field }) => {
  return (
    <Form.Item
      rules={[{ required: true, message: "Required" }]}
      label="Stems / Bunch"
      initialValue={10}
      name={[field.name, "stemsPerBunch"]}
    >
      <StemsPerBunchInput />
    </Form.Item>
  );
};

const renderVaseLifeFormItem: RenderFn<{ field: FormListFieldData }> = ({ field }) => {
  return (
    <Form.Item label="Vase Life" name={[field.name, "vaseLife"]}>
      <VaseLifeInput />
    </Form.Item>
  );
};

const renderBloomSizeFormItem: RenderFn<{ field: FormListFieldData; hidden?: boolean }> = ({
  field,
  hidden,
}) => {
  return (
    <Form.Item
      hidden={hidden}
      label="Bloom Size"
      name={[field.name, "bloomSize"]}
      fieldKey={[field.fieldKey, "bloomSize"]}
    >
      <BloomSizeInput />
    </Form.Item>
  );
};

const renderStemCountFormItem: RenderFn<{
  field: FormListFieldData;
  label?: string;
  placeholder?: string;
  hidden?: boolean;
}> = ({ field, label = "Stem Count", placeholder, hidden }) => {
  return (
    <Form.Item
      hidden={hidden}
      label={label}
      name={[field.name, "stemCount"]}
      rules={[
        { required: !hidden, message: "Required" },
        { type: "integer", min: 1, message: "Must be > 0" },
      ]}
    >
      <StemCountInput />
    </Form.Item>
  );
};

const renderDescriptionFormItem: RenderFn<{ field: FormListFieldData }> = ({ field }) => {
  return (
    <Form.Item
      label="Description"
      initialValue=""
      name={[field.name, "description"]}
      fieldKey={[field.fieldKey, "description"]}
    >
      <Input.TextArea rows={3} />
    </Form.Item>
  );
};

const renderPriceFormItem: RenderFn<{ field: FormListFieldData; label?: string }> = ({
  field,
  label = "Price",
}) => {
  return (
    <Form.Item
      label={label}
      name={[field.name, "price"]}
      fieldKey={[field.fieldKey, "price"]}
      rules={[{ required: true, message: "Required", type: "integer", min: 1, max: 1000000 }]}
      style={{ maxWidth: 120, marginRight: 16 }}
    >
      <CurrencyInput max={1000000} />
    </Form.Item>
  );
};

const renderMinStemsFormItem: RenderFn<{ field: FormListFieldData }> = ({ field }) => {
  return (
    <Form.Item
      style={{ maxWidth: 170 }}
      label="Min Stems (est.)"
      name={[field.name, "minStems"]}
      fieldKey={[field.fieldKey, "minStems"]}
      rules={[{ required: true, message: "Required", type: "integer" }]}
    >
      <InputNumber min={1} max={10000} step={1} precision={0} style={{ width: 170 }} />
    </Form.Item>
  );
};

const renderMaxStemsFormItem: RenderFn<{ field: FormListFieldData }> = ({ field }) => {
  return (
    <Form.Item
      style={{ maxWidth: 170 }}
      label="Max Stems (est.)"
      name={[field.name, "maxStems"]}
      fieldKey={[field.fieldKey, "maxStems"]}
      rules={[{ required: true, message: "Required", type: "integer" }]}
    >
      <InputNumber min={1} max={10000} step={1} precision={0} style={{ width: 170 }} />
    </Form.Item>
  );
};
