import { FullRetailBuyerRole } from "@rooted/shared";
import { Typography, Collapse, Tooltip, Tag, Image, Row, Col } from "antd";
import { ExclamationCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { PageContent, NarrowLayout } from "../../views/layouts";
import React from "react";
import { PageHeader } from "../../components/PageHeader";
const { Panel } = Collapse;

export const RetailBuyerFAQs: React.FC<{
  role: FullRetailBuyerRole;
}> = ({ role }) => {
  return (
    <>
      <NarrowLayout>
        <PageContent>
          <PageHeader title={"Frequently Asked Questions"} />
          <Typography.Paragraph>
            <Collapse accordion defaultActiveKey={1} className="faqs">
              <Panel
                collapsible="header"
                header="What times of year are fresh cut flowers sold? "
                key="1"
              >
                <p>
                  Great question! The season during which farms are producing flowers in volume is
                  called "production" season, and most domestic farms are not producing year-round.
                  As the year goes on, farms also production season typically begins in April for
                  most farms across the country. The farther north you are, the later in the spring
                  production season begins.
                </p>
              </Panel>
              <Panel collapsible="header" header="How do I care for my flowers?" key="3">
                <ul className="how-to-care">
                  <li>
                    <Row wrap={false}>
                      <Col flex="60px">
                        <Image
                          style={{ width: 35 }}
                          src={`${process.env.REACT_APP_BASE_URL}/images/icons/icon-tap-water.png`}
                          alt="Flowers need water"
                        />
                      </Col>
                      <Col flex="auto">
                        <b>Water:</b>&nbsp;&nbsp;
                        <Tooltip
                          placement="bottom"
                          title={
                            "Some flowers are what we describe as heavy drinkers (like tulips!).  Keep a close eye on these, especially if your vase is not transparent, and make sure that they always have water to drink."
                          }
                        >
                          <Tag>
                            Pro-tip <ExclamationCircleOutlined />
                          </Tag>
                        </Tooltip>
                        <br />
                        Always make sure that they have clean water. Flowers drink through their
                        stems, and if the water gets grimy and has a lot of bacteria growth, your
                        flowers' stems will get clogged and they will have a shorter vase life.
                        Clean water lets them drink with freedom! &nbsp;-&nbsp;
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row wrap={false}>
                      <Col flex="60px">
                        <Image
                          style={{ width: 35 }}
                          src={`${process.env.REACT_APP_BASE_URL}/images/icons/icon-rose.png`}
                          alt="Fresh cut flower"
                        />
                      </Col>
                      <Col flex="auto">
                        <b>Fresh-cut stems:</b>&nbsp;&nbsp;
                        <Tooltip
                          placement="bottom"
                          title={
                            "Some flowers are gorgeous but leak a sap when you cut the ends.  This is normal, but that sap can be irritating to your skin.  Always be sure to wash well after handling flowers, particularly those like daffodils and euphorbia.  A good rule of thumb is that if the sap is irritating to your skin, it would be irritating to other flowers, too.  If you're cutting any of these leaky flowers, let them sit for an hour or so in their own water before adding them back to your arrangement with other flowers."
                          }
                        >
                          <Tag>
                            Pro-tip <ExclamationCircleOutlined />
                          </Tag>
                        </Tooltip>
                        <br />
                        Always make sure that the ends of your flower stems have fresh, angled cuts
                        on them. You don't need any special tools for this – scissors work great!
                        Just try to be sure that you're using a clean pair so as not to introduce
                        bacteria into the stem.
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row wrap={false}>
                      <Col flex="60px">
                        <Image
                          style={{ width: 35 }}
                          src={`${process.env.REACT_APP_BASE_URL}/images/icons/icon-ethylene.png`}
                          alt="Ethylene gas"
                        />
                      </Col>
                      <Col flex="auto">
                        <b>Fruit:</b>
                        <br />
                        We love flowers in the kitchen! They brighten up the space so nicely! Just
                        be sure not to have them next to that bowl of fruit on the counter –
                        off-gassing fruit emits a substance called &nbsp;
                        <Tooltip
                          placement="bottomRight"
                          title={
                            "Natural gasses emitted from fruits and veggies as they age - often found in higher concentration around produce."
                          }
                        >
                          <Tag style={{ border: "none", padding: "0", margin: "0" }}>
                            ethylene <QuestionCircleOutlined />
                          </Tag>
                        </Tooltip>
                        , which shortens the vase life for most flowers.
                      </Col>
                    </Row>
                  </li>
                  <li>
                    <Row wrap={false}>
                      <Col flex="60px">
                        <Image
                          style={{ width: 35 }}
                          src={`${process.env.REACT_APP_BASE_URL}/images/icons/icon-sun-sunglasses.png`}
                          alt="Sun with shades"
                        />
                      </Col>
                      <Col flex="auto">
                        <b>Sun:</b>
                        <br />
                        Plants need sunlight to survive, but cut flowers actually hate it! Direct
                        sunlight will shorten vase life also, so try to avoid keeping your weekly
                        arrangement out of the direct sun if possible. If your flowers are in
                        sunlight, make sure to check their water levels regularly.
                      </Col>
                    </Row>
                  </li>
                </ul>
              </Panel>
              <Panel
                collapsible="header"
                header="How do I get the farm fresh flowers that I order?"
                key="4"
              >
                <p>
                  Every farmer has the choice of how they want to handle order fulfillment – so that
                  information from your farmer will be included when you place your order! Some
                  farms deliver (and can choose their delivery fee), some have on-site pickup, while
                  others give you a choice!
                </p>
              </Panel>
              <Panel
                collapsible="header"
                header="What if I want to order them for someone outside of my area? Does Rooted Farmers ship?"
                key="5"
              >
                <p>
                  Looking to send flowers to mom on Mother's Day or have a friend you want to let
                  know you're thinking about them? You can still get them flowers, even though we
                  don't support shipping! Just add their address on your Shop Flowers page and start
                  shopping local farms in their area!
                </p>
              </Panel>
              <Panel
                collapsible="header"
                header="Who does my order benefit when I purchase through Rooted Farmers?"
                key="6"
              >
                <p>
                  Everyone! You're doing a service to the planet, the pollinators, your neighbor
                  farmer, your community, and yourself by purchasing local flowers.
                </p>
              </Panel>
              <Panel
                collapsible="header"
                header="Why would I buy a single variety bunch instead of a pre-arranged bouquet?"
                key="7"
              >
                <p>
                  Good question! The short answer is personal preference! You may want to try your
                  hand at DIY floral design, you may want to choose specific flowers that really
                  speak to you, or you may just fall in love with certain colors or flowers as you
                  are shopping. We find that most Rooted members love the process of experimenting
                  with seasonal blooms in different ways their homes, and single variety bunches are
                  a great way to do that!
                </p>
              </Panel>
            </Collapse>
          </Typography.Paragraph>
        </PageContent>
      </NarrowLayout>
    </>
  );
};
