import { FullBuyerRole, SavedLocation, WithId } from "@rooted/shared";
import { db, useCollectionDataChecked } from "../firebase";

/** Listen to the list of all of a given buyer's saved addresses. */
export const useSavedAddresses = (role: FullBuyerRole) =>
  useCollectionDataChecked<WithId<SavedLocation>>(
    db.collection("profiles").doc(role.profileId).collection("addresses"),
    { idField: "_id" }
  );

/** Add an address to a buyer's list of saved addresses/ */
export const saveAddress = (role: FullBuyerRole, address: SavedLocation) =>
  db.collection("profiles").doc(role.profileId).collection("addresses").add(address);

export const removeAddress = (role: FullBuyerRole, address: WithId<SavedLocation>) =>
  db.collection("profiles").doc(role.profileId).collection("addresses").doc(address._id).delete();
