import React from "react";
import { Route, Routes } from "react-router-dom";
import { NotFoundPage } from "../../../views/layouts";
import { ListingDetails } from "./ListingDetails";
export * from "./ListingDetails";

export const Listings: React.FC = () => {
  return (
    <Routes>
      <Route path=":listingId" element={<ListingDetails />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
