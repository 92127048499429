import { InfoCircleOutlined, LockOutlined } from "@ant-design/icons";
import {
  BloomSize,
  ColorKey,
  CSASeason,
  CurrentCart,
  DesignElementKey,
  DriedFlowerProductType,
  driedFlowerProductTypes,
  firestoreToLuxon,
  firestoreToMoment,
  FullBuyerRole,
  getDriedFlowerProductTypeLabel,
  getPottedPlantTagLabel,
  legalBloomSizes,
  legalColors,
  legalCSASeasons,
  legalDesignElements,
  legalStemLengths,
  ListingQueryContstraints,
  PottedPlantTag,
  pottedPlantTagSchema,
  PottedPlantType,
  pottedPlantTypeSchema,
  SavedLocation,
  StemLength,
  WithId,
  WreathDiameter,
  wreathDiameterSchema,
  WreathTag,
  wreathTagSchema,
} from "@rooted/shared";
import {
  Badge,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Empty,
  Form,
  Row,
  Slider,
  Space,
  Tooltip,
  Typography,
} from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import firebase from "firebase/app";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AddressDisplay,
  AddressSelect,
} from "../../../../../components/FormControls/AddressSelect";
import { DateRangePicker } from "../../../../../components/Misc/DateRangePicker";
import { useDebouncedTrigger } from "../../../../../hooks/util/useDebouncedTrigger";
import { setAddress, useCurrentCart } from "../../../../../services/buyers/active-cart";
import { momentToFirestore } from "../../../../../utils/dates";

const FilterSettingHeader: React.FC<{ title: string; subtitle?: string }> = ({
  title,
  subtitle,
}) => (
  <>
    {title}
    {subtitle && (
      <>
        <br />
        <Typography.Paragraph
          strong
          style={{ marginBottom: 0 }}
          ellipsis={{
            rows: 1,
            expandable: false,
          }}
        >
          {subtitle}
        </Typography.Paragraph>
      </>
    )}
  </>
);

export const AddressConstraintBox: React.FC<{
  openByDefault?: boolean;
  role: FullBuyerRole;
  cart?: CurrentCart;
  setAddress: (x: WithId<SavedLocation>) => void;
}> = ({ role, cart, setAddress, openByDefault }) => {
  const cartAddressLine1 = cart?.address?.streetAddress.line1;

  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={openByDefault ? ["address"] : []}
      style={{ marginBottom: 16 }}
    >
      <Collapse.Panel
        header={
          <FilterSettingHeader
            title={"Address"}
            subtitle={cartAddressLine1 ? cartAddressLine1 + "..." : undefined}
          />
        }
        key="address"
        collapsible="header"
        extra={
          cart?.status === "provisional" || !cart ? (
            <Tooltip title="You can edit this address until you've added items to your cart.">
              <InfoCircleOutlined />
            </Tooltip>
          ) : (
            <Tooltip title="Once you've added items to your cart, your address is locked.">
              <LockOutlined />
            </Tooltip>
          )
        }
      >
        {cart?.status === "provisional" || !cart ? (
          <>
            <AddressSelect role={role} onChange={(e) => e && setAddress(e)} value={cart?.address} />
            <Typography.Text type="secondary" style={{ marginTop: 10, display: "block" }}>
              Availability may vary depending on your location.
            </Typography.Text>
          </>
        ) : (
          <AddressDisplay address={cart.address.streetAddress} />
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export const AddAddressModal: React.FC<{ role: FullBuyerRole }> = ({ role }) => {
  const onChange = useCallback(
    (newAddress: WithId<SavedLocation> | undefined) => {
      if (newAddress) {
        setAddress(role, newAddress);
      }
    },
    [role]
  );
  const [cart] = useCurrentCart();
  return (
    <>
      <Row align="middle" justify="center">
        <Col>
          <Typography.Title level={5}>Select an address to browse inventory...</Typography.Title>
          <AddressSelect autoSelect role={role} onChange={onChange} value={cart?.address} />
        </Col>
      </Row>
    </>
  );
};

export const PhysicalFulfillmentConstraintBox: React.FC<{
  openByDefault?: boolean;
  constraints: ListingQueryContstraints;
  setConstraints: (x: ListingQueryContstraints) => void;
  disableDelivery?: boolean;
}> = ({ constraints, setConstraints, openByDefault, disableDelivery }) => {
  const [radius, setRadius] = useState(constraints.pickup.maxDistance);
  const debouncedRadius = useDebouncedTrigger(radius);

  // TODO: use a useReducer for setConstraints. This will allow us to pass a referentially
  // stable function around, which would allow less updates to setConstraints
  useEffect(() => {
    if (constraints.pickup.maxDistance === debouncedRadius) return;
    setConstraints({
      ...constraints,
      pickup: {
        ...constraints.pickup,
        maxDistance: debouncedRadius,
      },
    });
  }, [constraints, debouncedRadius, setConstraints]);

  const numSelected: number =
    (constraints.delivery.enabled ? 1 : 0) + (constraints.pickup.enabled ? 1 : 0);

  const fulfillmentTypeCopy = disableDelivery ? "Pickup" : "Pickup / Delivery";
  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={openByDefault ? ["fulfillment"] : []}
      style={{ marginBottom: 16 }}
    >
      <Collapse.Panel
        header={<FilterSettingHeader title={fulfillmentTypeCopy} />}
        extra={<Badge count={numSelected} />}
        key="fulfillment"
        collapsible="header"
      >
        <Space direction="vertical">
          <Form.Item label={`${fulfillmentTypeCopy} Date`}>
            <DateRangePicker
              value={[
                firestoreToMoment(constraints.dateRange.start),
                firestoreToMoment(constraints.dateRange.end),
              ]}
              onChange={(v) =>
                v &&
                v[0] &&
                v[1] &&
                setConstraints({
                  ...constraints,
                  dateRange: {
                    start: momentToFirestore(v[0]),
                    end: momentToFirestore(v[1]),
                  },
                })
              }
            />
          </Form.Item>
          {!disableDelivery && (
            <>
              <Checkbox
                checked={constraints.delivery.enabled}
                onChange={(e) =>
                  setConstraints({
                    ...constraints,
                    delivery: {
                      ...constraints.delivery,
                      enabled: e.target.checked,
                    },
                  })
                }
                style={{ width: "100%" }}
              >
                Delivery
                <img
                  style={{ height: 25, float: "right" }}
                  src={`${process.env.REACT_APP_BASE_URL}/images/icons/icon-delivery.png`}
                  alt="Delivery icon"
                />
              </Checkbox>
              <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            </>
          )}
          <Checkbox
            checked={constraints.pickup.enabled}
            onChange={(e) =>
              setConstraints({
                ...constraints,
                pickup: {
                  ...constraints.pickup,
                  enabled: e.target.checked,
                },
              })
            }
            style={{ width: "100%" }}
          >
            Pickup
            <img
              style={{ height: 25, float: "right" }}
              src={`${process.env.REACT_APP_BASE_URL}/images/icons/icon-farm.png`}
              alt="Pickup icon"
            />
          </Checkbox>
          {constraints.pickup.enabled && (
            <>
              <Slider
                min={0}
                max={150}
                tipFormatter={(x) => `Within ${x} miles`}
                value={radius}
                onChange={(newRadius: number) => void setRadius(newRadius)}
                disabled={!constraints.pickup.enabled}
              />
              <Typography.Text
                type="secondary"
                style={{ marginTop: "-12px", display: "block", textAlign: "center" }}
              >
                {`Pickup Radius: ${radius} miles`}
              </Typography.Text>
            </>
          )}
        </Space>
      </Collapse.Panel>
    </Collapse>
  );
};

export const ColorConstraintBox: React.FC<{
  openByDefault?: boolean;
  colors: ColorKey[];
  setColors: (x: ColorKey[]) => void;
}> = ({ colors, setColors, openByDefault }) => {
  const toggleColor = (x: ColorKey) => {
    if (colors.includes(x)) setColors(colors.filter((c) => c !== x));
    else setColors([...colors, x]);
  };

  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={openByDefault ? ["color"] : []}
      style={{ marginBottom: 16 }}
    >
      <Collapse.Panel
        header="Color"
        key="color"
        collapsible="header"
        extra={<Badge count={colors.length} />}
      >
        {legalColors.map((c) => (
          <CheckableTag
            checked={colors.includes(c.key)}
            onChange={(v) => toggleColor(c.key)}
            key={c.key}
          >
            {" "}
            <Badge color={"#" + c.hexCode}></Badge>
            {c.name}
          </CheckableTag>
        ))}
      </Collapse.Panel>
    </Collapse>
  );
};

export const DriedFlowerTypeConstraintBox: React.FC<{
  openByDefault?: boolean;
  types: DriedFlowerProductType[];
  setTypes: (x: DriedFlowerProductType[]) => void;
}> = ({ types, setTypes, openByDefault }) => {
  const toggleType = (x: DriedFlowerProductType) => {
    if (types.includes(x)) setTypes(types.filter((c) => c !== x));
    else setTypes([...types, x]);
  };

  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={openByDefault ? ["driedFlowerType"] : []}
      style={{ marginBottom: 16 }}
    >
      <Collapse.Panel
        header="Type"
        key="driedFlowerType"
        collapsible="header"
        extra={<Badge count={types.length} />}
      >
        {driedFlowerProductTypes.map((t) => (
          <CheckableTag checked={types.includes(t)} onChange={(v) => toggleType(t)} key={t}>
            {getDriedFlowerProductTypeLabel(t)}
          </CheckableTag>
        ))}
      </Collapse.Panel>
    </Collapse>
  );
};

export const PottedPlantTypeConstraintBox: React.FC<{
  openByDefault?: boolean;
  types: PottedPlantType[];
  setTypes: (x: PottedPlantType[]) => void;
}> = ({ types, setTypes, openByDefault }) => {
  const toggleType = (x: PottedPlantType) => {
    if (types.includes(x)) setTypes(types.filter((c) => c !== x));
    else setTypes([...types, x]);
  };

  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={openByDefault ? ["pottedPlantType"] : []}
      style={{ marginBottom: 16 }}
    >
      <Collapse.Panel
        header="Type"
        key="pottedPlantType"
        collapsible="header"
        extra={<Badge count={types.length} />}
      >
        {pottedPlantTypeSchema.options.map((t) => (
          <CheckableTag checked={types.includes(t)} onChange={(v) => toggleType(t)} key={t}>
            {t}
          </CheckableTag>
        ))}
      </Collapse.Panel>
    </Collapse>
  );
};

export const PottedPlantTagsConstraintBox: React.FC<{
  openByDefault?: boolean;
  tags: PottedPlantTag[];
  setTags: (x: PottedPlantTag[]) => void;
}> = ({ tags, setTags, openByDefault }) => {
  const toggleType = (x: PottedPlantTag) => {
    if (tags.includes(x)) setTags(tags.filter((c) => c !== x));
    else setTags([...tags, x]);
  };

  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={openByDefault ? ["pottedPlantTags"] : []}
      style={{ marginBottom: 16 }}
    >
      <Collapse.Panel
        header="Additional Info"
        key="pottedPlantTags"
        collapsible="header"
        extra={<Badge count={tags.length} />}
      >
        {pottedPlantTagSchema.options.map((t) => (
          <CheckableTag checked={tags.includes(t)} onChange={(v) => toggleType(t)} key={t}>
            {getPottedPlantTagLabel(t)}
          </CheckableTag>
        ))}
      </Collapse.Panel>
    </Collapse>
  );
};

export const WreathTagsConstraintBox: React.FC<{
  openByDefault?: boolean;
  tags: WreathTag[];
  setTags: (x: WreathTag[]) => void;
}> = ({ tags, setTags, openByDefault }) => {
  const toggleType = (x: WreathTag) => {
    if (tags.includes(x)) setTags(tags.filter((c) => c !== x));
    else setTags([...tags, x]);
  };

  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={openByDefault ? ["wreathTags"] : []}
      style={{ marginBottom: 16 }}
    >
      <Collapse.Panel
        header="Tags"
        key="wreathTags"
        collapsible="header"
        extra={<Badge count={tags.length} />}
      >
        {wreathTagSchema.options.map((t) => (
          <CheckableTag checked={tags.includes(t)} onChange={(v) => toggleType(t)} key={t}>
            {t}
          </CheckableTag>
        ))}
      </Collapse.Panel>
    </Collapse>
  );
};

export const WreathDiameterConstraintBox: React.FC<{
  openByDefault?: boolean;
  values: WreathDiameter[];
  setValues: (x: WreathDiameter[]) => void;
}> = ({ values, setValues, openByDefault }) => {
  const toggleValue = (x: WreathDiameter) => {
    if (values.includes(x)) setValues(values.filter((c) => c !== x));
    else setValues([...values, x]);
  };

  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={openByDefault ? ["wreathDiameter"] : []}
      style={{ marginBottom: 16 }}
    >
      <Collapse.Panel
        header="Diameter"
        key="wreathDiameter"
        collapsible="header"
        extra={<Badge count={values.length} />}
      >
        {wreathDiameterSchema.options.map((t) => (
          <CheckableTag checked={values.includes(t)} onChange={(v) => toggleValue(t)} key={t}>
            {t}
          </CheckableTag>
        ))}
      </Collapse.Panel>
    </Collapse>
  );
};

export const DesignElementConstraintBox: React.FC<{
  openByDefault?: boolean;
  designElements: DesignElementKey[];
  setDesignElements: (x: DesignElementKey[]) => void;
}> = ({ designElements, setDesignElements, openByDefault }) => {
  const toggleDesignElement = (x: DesignElementKey) => {
    if (designElements.includes(x)) setDesignElements(designElements.filter((c) => c !== x));
    else setDesignElements([...designElements, x]);
  };

  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={openByDefault ? ["design-element"] : []}
      style={{ marginBottom: 16 }}
    >
      <Collapse.Panel
        header="Design Element"
        key="design-element"
        collapsible="header"
        extra={<Badge count={designElements.length} />}
      >
        {legalDesignElements.map((d) => (
          <CheckableTag
            checked={designElements.includes(d.key)}
            onChange={(v) => toggleDesignElement(d.key)}
            key={d.key}
          >
            {d.name}
          </CheckableTag>
        ))}
      </Collapse.Panel>
    </Collapse>
  );
};

export const StemLengthConstraintBox: React.FC<{
  openByDefault?: boolean;
  stemLengths: StemLength[];
  setStemLengths: (x: StemLength[]) => void;
}> = ({ stemLengths, setStemLengths, openByDefault }) => {
  const toggleStemLength = (x: StemLength) => {
    if (stemLengths.includes(x)) setStemLengths(stemLengths.filter((c) => c !== x));
    else setStemLengths([...stemLengths, x]);
  };

  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={openByDefault ? ["stem-length"] : []}
      style={{ marginBottom: 16 }}
    >
      <Collapse.Panel
        header="Stem Length"
        key="stem-length"
        collapsible="header"
        extra={<Badge count={stemLengths.length} />}
      >
        {legalStemLengths.map((d) => (
          <CheckableTag
            checked={stemLengths.includes(d)}
            onChange={(v) => toggleStemLength(d)}
            key={d}
          >
            {d}
          </CheckableTag>
        ))}
      </Collapse.Panel>
    </Collapse>
  );
};

export const BloomSizeConstraintBox: React.FC<{
  openByDefault?: boolean;
  bloomSizes: BloomSize[];
  setBloomSizes: (x: BloomSize[]) => void;
}> = ({ bloomSizes, setBloomSizes, openByDefault }) => {
  const toggleBloomSize = (x: BloomSize) => {
    if (bloomSizes.includes(x)) setBloomSizes(bloomSizes.filter((c) => c !== x));
    else setBloomSizes([...bloomSizes, x]);
  };

  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={openByDefault ? ["bloom-size"] : []}
      style={{ marginBottom: 16 }}
    >
      <Collapse.Panel
        header="Bloom Size"
        key="bloom-size"
        collapsible="header"
        extra={<Badge count={bloomSizes.length} />}
      >
        {legalBloomSizes.map((d) => (
          <CheckableTag
            checked={bloomSizes.includes(d)}
            onChange={(v) => toggleBloomSize(d)}
            key={d}
          >
            {d}
          </CheckableTag>
        ))}
      </Collapse.Panel>
    </Collapse>
  );
};

export const ProductSearchEmpty = (x: string | undefined) => {
  const defaultImageUrl = `${process.env.REACT_APP_BASE_URL}/images/inventory-default.png`;
  return (
    <Empty
      image={defaultImageUrl}
      description="No results match your search. Try expanding your search."
    />
  );
};

/**
 * More specific empty state to guide user to update physical constraints.
 * TODO: expand this to include specific constraints for categories themselves, e.g.
 * if buyer has narrowed by bloom size.
 */

const firestoreToReadable = (timestamp: firebase.firestore.Timestamp) =>
  firestoreToLuxon(timestamp).toLocaleString({
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

export const ProductSearchEmptyWithConstraints: React.FC<{
  physicalConstraints: ListingQueryContstraints;
}> = ({ physicalConstraints }) => {
  const humanReadableDateRange = useMemo(
    () =>
      `between ${firestoreToReadable(
        physicalConstraints.dateRange.start
      )} and ${firestoreToReadable(physicalConstraints.dateRange.end)}`,
    [physicalConstraints.dateRange.end, physicalConstraints.dateRange.start]
  );
  const pickupSettingsMessage = physicalConstraints.pickup.enabled
    ? `pickup (${physicalConstraints.pickup.maxDistance} miles)`
    : undefined;
  const deliverySettingsMessage = physicalConstraints.delivery.enabled ? "delivery" : undefined;

  // Add an "or" to the message
  const fulfillmentMessage =
    pickupSettingsMessage && deliverySettingsMessage
      ? `${pickupSettingsMessage} or ${deliverySettingsMessage}`
      : deliverySettingsMessage || pickupSettingsMessage;

  const defaultImageUrl = `${process.env.REACT_APP_BASE_URL}/images/inventory-default.png`;

  if (!pickupSettingsMessage && !deliverySettingsMessage) {
    return (
      <Empty image={defaultImageUrl} description={"Please select Pickup / Delivery settings"} />
    );
  }

  return (
    <Empty
      image={defaultImageUrl}
      description={
        <>
          No results found for: <br />
          {fulfillmentMessage}
          <br />
          {humanReadableDateRange}
        </>
      }
    />
  );
};

export const CSASeasonConstraintBox: React.FC<{
  openByDefault?: boolean;
  seasons: CSASeason[];
  setSeasons: (x: CSASeason[]) => void;
}> = ({ seasons, setSeasons, openByDefault }) => {
  const toggleSeason = (x: CSASeason) => {
    if (seasons.includes(x)) setSeasons(seasons.filter((c) => c !== x));
    else setSeasons([...seasons, x]);
  };

  return (
    <Collapse
      collapsible="header"
      defaultActiveKey={openByDefault ? ["season"] : []}
      style={{ marginBottom: 16 }}
    >
      <Collapse.Panel
        header="Season"
        key="season"
        collapsible="header"
        extra={<Badge count={seasons.length} />}
      >
        {legalCSASeasons.map((d) => (
          <CheckableTag key={d} checked={seasons.includes(d)} onChange={(v) => toggleSeason(d)}>
            {d}
          </CheckableTag>
        ))}
      </Collapse.Panel>
    </Collapse>
  );
};
