import { FullBuyerRole } from "@rooted/shared";
import { db, useCollectionDataChecked } from "../../../services/firebase";

export const useActiveOrderCount = (role: FullBuyerRole) =>
  useCollectionDataChecked(
    db
      .collection("childOrders")
      .where("buyerId", "==", role.profileId)
      .where("status", "==", "active")
  )[0]?.length;
