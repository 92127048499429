import { FullSellerRole } from "@rooted/shared";
import { Typography, Alert, Input, Button, Form, Space, notification } from "antd";
import React, { useState } from "react";
import { PageHeader } from "../../../components/PageHeader";
import { CreditCardSelect } from "../../../components/FormControls/CreditCardSelect";
import { SubscriptionPicker } from "../../../components/SubscriptionPicker";
import { changeSubscription } from "../../../services/sellers/subscriptions";
import { InfoCircleOutlined } from "@ant-design/icons";
import { logError } from "../../../sentry";

export const SubscriptionPane: React.FC<{
  role: FullSellerRole;
}> = ({ role }) => {
  const subscription = role.privateProfile.subscription;

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [subscriptionSelected, setSubscriptionSelected] = useState<boolean>(false);
  const [subscriptionId, setSubscriptionId] = useState<string | undefined>(subscription.key);

  // id of the subscription being updated
  const [loadingId, setLoadingId] = useState<string | undefined>(undefined);

  const select = async () => {
    setSubmitting(true);
    if (subscriptionId) {
      try {
        await changeSubscription(role, subscriptionId);
      } catch (error) {
        notification.error({ message: "Oops! Something went wrong changing your subscription" });
        logError({
          error,
          tags: { page: "subscription-pane" },
          extraData: { subscriptionId },
        });
      }
    }
    setSubmitting(false);
  };

  return (
    <>
      <PageHeader title={"Subscription"} />

      {/*
      Removed for now (2/3) by @ohxh as offer code behavior isn't well-defined for existing
      subscription. TODO re-add once backend is finalized
      <OfferCodeEntry
        value={subscription.offerCode}
        onChange={(x) => db.collection("privateProfiles").doc(role.profileId).set({
          subscription: {
            offerCode: x
          }
        }, { merge: true })} />*/}

      <Form layout="vertical" onFinish={select}>
        <Form.Item label="Credit Card">
          <CreditCardSelect role={role} />
        </Form.Item>

        <Alert
          type="info"
          style={{ marginTop: 40, marginBottom: 20, textAlign: "center" }}
          message={
            <>
              <InfoCircleOutlined /> Pick the Rooted Subscription that is right for your farm!
            </>
          }
        />

        <Form.Item label="Subscription Type" className="ant-form-item-full-width">
          <SubscriptionPicker
            columnSpan={12}
            type={role.type}
            onSelect={async (x) => {
              if (x !== subscription.key) {
                setSubscriptionId(x);
                setSubscriptionSelected(true); // must be separate from subscriptionId
              } else {
                setSubscriptionId(x);
                setSubscriptionSelected(false); // must be separate from subscriptionId
              }
            }}
            selected={subscriptionId}
            constraints={{
              slowFlowers: role.type === "grower" ? role.profile.slowFlowersMember : false,
              ascfg: role.type === "grower" ? role.profile.ASCFGMember : false,
            }}
          />
        </Form.Item>
        <Form.Item>
          {/** This will only be enabled when a new subscription is selected */}
          <Button
            disabled={!subscriptionSelected}
            type="primary"
            loading={submitting}
            htmlType="submit"
          >
            Checkout
          </Button>
        </Form.Item>
        <Typography.Text>
          <b>Note:</b> <em>Subscriptions changes are prorated and will apply instantly.</em>
        </Typography.Text>
      </Form>
    </>
  );
};

export const OfferCodeEntry: React.FC<{ value?: string; onChange: (x: string) => void }> = ({
  value,
  onChange,
}) => {
  const [code, setCode] = useState(value || "");
  const [editing, setEditing] = useState(value ? false : true);
  return (
    <Form.Item label="Offer Code">
      <Space direction="horizontal">
        <Input value={code} onChange={(e) => setCode(e.target.value)} disabled={!editing} />
        {editing ? (
          <Button
            type="primary"
            onClick={() => {
              setEditing(false);
              onChange(code);
            }}
          >
            Apply
          </Button>
        ) : (
          <Button
            onClick={() => {
              setEditing(true);
              setCode("");
            }}
          >
            Change code
          </Button>
        )}
      </Space>
    </Form.Item>
  );
};
