import { ApplicationForm, EnterpriseProfileBio, normalizeValue, WithId } from "@rooted/shared";
import { Typography } from "antd";
import React from "react";
import { FirestoreDocForm } from "../../../../components/FormControls/FirestoreDocForm";
import { db } from "../../../../services/firebase";
import { BioFormItems } from "../../../onboarding/Forms/BioForm";

export const BioPanel: React.FC<{ application: WithId<ApplicationForm> }> = ({ application }) => {
  if (!application.bio) return <Typography.Text type="secondary">Not filled out.</Typography.Text>;
  return (
    <FirestoreDocForm
      data={application.bio}
      dbRef={db.collection("applications").doc(application._id)}
      rootFieldPath={"bio"}
      replaceOnSubmit={(values: EnterpriseProfileBio): EnterpriseProfileBio => {
        // add normalized displayName for querying to bio model
        return {
          ...values,
          _displayNameLowerCase: normalizeValue(values.displayName)!,
        };
      }}
    >
      <BioFormItems />
    </FirestoreDocForm>
  );
};
