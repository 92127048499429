import { Row, Col, Typography, Alert } from "antd";
import Button from "antd/es/button";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { breakpoint, useWindowSize } from "../../utils/detectWindowSize";

/**
 * Ideal for callout links. Full width on mobile, minimal width otherwise.
 */
export const AlertLink: React.FC<{
  to: LinkProps["to"];
  style?: React.CSSProperties;
  disabled?: boolean;
}> = ({ to, style = {}, children, disabled }) => {
  const [width] = useWindowSize();
  const isSmall = width < breakpoint.sm;

  if (disabled) {
    return (
      <Row>
        <div style={{ flex: 1 }} />
        <Button
          disabled
          style={{
            whiteSpace: "normal",
            height: "auto",
            ...style,
          }}
        >
          {children}
        </Button>
        <div style={{ flex: 1 }} />
      </Row>
    );
  }

  return (
    <Link to={to}>
      {isSmall ? (
        <Alert
          style={style}
          message={
            <Typography.Link
              style={{ textAlign: "center", width: "100%", display: "inline-block" }}
            >
              {children}
            </Typography.Link>
          }
        />
      ) : (
        <Row>
          {/* On large screens, use the minimal width of the text, centered. */}
          <div style={{ flex: 1 }} />
          <Alert style={style} message={<Typography.Link>{children}</Typography.Link>} />
          <div style={{ flex: 1 }} />
        </Row>
      )}
    </Link>
  );
};
