import { firestoreToMoment } from "@rooted/shared";
import DatePicker, { DatePickerProps } from "antd/lib/date-picker";
import firebase from "firebase/app";
import React from "react";
import { momentToFirestore } from "../../utils/dates";
export interface AdaptedDatePickerProps extends Omit<DatePickerProps, "value" | "onChange"> {
  value?: firebase.firestore.Timestamp;
  onChange?: (x: firebase.firestore.Timestamp | null) => void;
}

/** The AntD datepicker, adapted to use firestore date objects. */
export const AdaptedDatePicker: React.FC<AdaptedDatePickerProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const adaptedValue = value ? firestoreToMoment(value) : value;
  const adaptedOnChange = onChange
    ? (v: moment.Moment | null, s: string) => {
        v ? onChange?.(momentToFirestore(v)) : onChange?.(v);
      }
    : onChange;

  return <DatePicker {...rest} value={adaptedValue} onChange={adaptedOnChange} />;
};
