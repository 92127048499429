import {
  FulfillmentGroup,
  FullBuyerRole,
  WithId,
  Cart,
  SellerProfile,
  FulfillmentGroupItem,
  createUniqueFulfillmentGroupKey,
  CartFulfillmentGroup as CartFulfillmentGroupType,
} from "@rooted/shared";
import { List, Card, Input, Typography, Form, Col } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../../components/FormControls/CurrencyInput";
import { db, useDocumentDataChecked } from "../../../services/firebase";
import { updateCartOrderNotes } from "../../../services/buyers/active-cart";
import {
  cartNameColumn,
  cartPhotoColumn,
  makeCartPriceColumn,
  makeCartQuantityColumn,
  makeCartTotalColumn,
} from "../../../components/TableColumns/CartItemColumns";
import { FulfillmentError, useFulfillmentGroupErrors } from "./FulfillmentGroupErrorsContext";
import { useConnection } from "../../../services/connections";
import { logError } from "../../../sentry";
import { useDebouncedTrigger } from "../../../hooks/util/useDebouncedTrigger";
import { stringifyFulfillment } from "../../../utils/fulfillment";

export const CartFulfillmentGroup: React.FC<{
  group: CartFulfillmentGroupType;
  role: FullBuyerRole;
  cart: WithId<Cart>;
}> = ({ group, role, cart }) => {
  const sellerId = group.fulfillment.profileId;
  const uniqueGroupId = createUniqueFulfillmentGroupKey(group);

  const { dispatch: dispatchGroupErrors } = useFulfillmentGroupErrors();

  const [seller, sellerLoading] = useDocumentDataChecked<WithId<SellerProfile>>(
    db.collection("profiles").doc(sellerId),
    { idField: "_id" }
  );

  const tableColumns: ColumnsType<FulfillmentGroupItem> = [
    cartPhotoColumn,
    cartNameColumn,
    makeCartPriceColumn(role, cart),
    makeCartQuantityColumn(role, cart, group),
    makeCartTotalColumn(role),
  ];

  const [connection, connectionLoading] = useConnection(
    role.type === "retail-buyer" ? "retail" : role.profileId,
    sellerId
  );

  const [orderMinimumStatus, setOrderMinimumStatus] = useState<{
    value: number;
    isBelow: boolean;
  }>();

  useEffect(() => {
    if (!connectionLoading) {
      if (!connection || connection.status !== "approved") {
        throw new Error("Connection not found or not approved.");
      }
      const orderMinimumCents = connection?.orderMinimumCents ?? 0;
      setOrderMinimumStatus({
        isBelow: group._calculated.subtotal < orderMinimumCents,
        value: orderMinimumCents,
      });
    }
  }, [connection, connectionLoading, group._calculated.subtotal]);

  // Required for CSAs
  const missingBuyerNotes = !group.buyerNotes && group.fulfillment.type === "custom";

  useEffect(() => {
    const errors: FulfillmentError[] = [];
    if (orderMinimumStatus?.isBelow) {
      errors.push({
        message: "is bellow order minimum",
        reason: "ORDER_MINIMUM",
      });
    }
    if (missingBuyerNotes) {
      errors.push({
        message: "needs order notes",
        reason: "REQUIRED_NOTES",
      });
    }
    dispatchGroupErrors({
      type: "SET_GROUP_ERRORS",
      payload: {
        groupId: uniqueGroupId,
        errors,
      },
    });
  }, [orderMinimumStatus, missingBuyerNotes, uniqueGroupId, dispatchGroupErrors]);

  useEffect(() => {
    return () => {
      dispatchGroupErrors({
        type: "DELETE_GROUP",
        payload: {
          groupId: uniqueGroupId,
        },
      });
    };
  }, [dispatchGroupErrors, uniqueGroupId]);

  return (
    <Card
      bodyStyle={{ padding: 0 }}
      style={{ marginBottom: 24 }}
      title={
        <>
          {stringifyFulfillment(group.fulfillment)} from{" "}
          <Link to={`/${seller?.type}s/${seller?._id}`}>{seller?.bio.displayName}</Link>
        </>
      }
      extra={
        <>
          {group.items.length} item{group.items.length > 1 && "s"}
        </>
      }
    >
      <Table
        rowKey={(i) => i.listing._id}
        columns={tableColumns}
        dataSource={group.items}
        pagination={false}
      />
      <List size="small">
        <FulfillmentNotesWidget group={group} role={role} />

        <List.Item
          actions={[
            <Typography.Text
              key={"subtotal"}
              type={orderMinimumStatus?.isBelow ? "danger" : undefined}
            >
              {formatCurrency(group._calculated.subtotal)}
            </Typography.Text>,
          ]}
        >
          <Col>
            <Typography.Text>Subtotal</Typography.Text>
            <br />
            {orderMinimumStatus && orderMinimumStatus.isBelow && (
              <Typography.Text type="danger">
                {`Let's add a few more flowers! This seller's minimum order is ${formatCurrency(
                  orderMinimumStatus.value
                )}`}
              </Typography.Text>
            )}
          </Col>
        </List.Item>

        {group.applicableTaxes.map((tax, index) => (
          <List.Item
            key={`tax-${tax.name}`}
            actions={[formatCurrency(group._calculated.taxBurden[index])]}
          >
            {tax.name}
          </List.Item>
        ))}

        {group._calculated.handlingFee !== undefined && group._calculated.handlingFee > 0 && (
          <List.Item actions={[formatCurrency(group._calculated.handlingFee)]}>
            Handling Fee ({group.fulfillment.handlingFeePercent}%)
          </List.Item>
        )}

        {group._calculated.fulfillmentPrice > 0 && (
          <List.Item actions={[formatCurrency(group._calculated.fulfillmentPrice)]}>
            Delivery
          </List.Item>
        )}

        <List.Item actions={[formatCurrency(group._calculated.total)]}>
          <b>Total</b>
        </List.Item>
      </List>
    </Card>
  );
};

const FulfillmentNotesWidget: React.FC<{
  group: FulfillmentGroup;
  role: FullBuyerRole;
}> = ({ group, role }) => {
  const [updatingNotes, setUpdatingNotes] = useState(false);
  const [notes, setNotes] = useState(group.buyerNotes);

  const debouncedNotes = useDebouncedTrigger(notes);

  const updateNotes = useCallback(
    async (notes: string) => {
      setUpdatingNotes(true);
      try {
        await updateCartOrderNotes(role, group.fulfillment, notes);
      } catch (error) {
        logError({
          error,
          tags: { page: "cart" },
          extraData: {
            fulfillment: group.fulfillment,
            notes,
          },
        });
      }
      setUpdatingNotes(false);
    },
    [group.fulfillment, role]
  );

  // Only update if the notes have changed, not the cart data
  // We should change the API to accept a cart Id and a group Id,
  // and then this wouldn't be needed
  const prevNotes = useRef<string>();
  useEffect(() => {
    if (prevNotes.current === debouncedNotes) return;
    updateNotes(debouncedNotes);
    prevNotes.current = debouncedNotes;
  }, [debouncedNotes, updateNotes]);

  if (group.fulfillment.type !== "custom")
    return (
      <List.Item>
        <Input.TextArea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Order notes..."
        />
      </List.Item>
    );

  return (
    <List.Item>
      <Col>
        <Typography.Text>
          <b>From grower:</b> {group.fulfillment.description}
          <br />
          <br />
        </Typography.Text>
        <Form layout={"vertical"}>
          <Form.Item
            label={"Please specify how you will be receiving your order"}
            required
            help={!debouncedNotes?.trim() ? "Required" : undefined}
            validateStatus={
              !debouncedNotes?.trim() ? "error" : updatingNotes ? "validating" : undefined
            }
          >
            <Input.TextArea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Order notes..."
            />
          </Form.Item>
        </Form>
      </Col>
    </List.Item>
  );
};
