import {
  ApplicationForm,
  WithId,
  bioComplete,
  detailsComplete,
  firstIncompleteSection,
  legalProfileTypes,
  stripeComplete,
  subscriptionComplete,
} from "@rooted/shared";

import { Button, Layout, Steps } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import { Navigate, Route, Routes, useLocation, useNavigate, Link } from "react-router-dom";
import { useRouterBreadcrumb } from "../../components/Misc/useRouterBreadcrumb";
import { WideLayout, PageContent, NotFoundPage } from "../layouts";
import { ApplicationCompleteSplash } from "./ApplicationCompleteSplash";
import { BioForm } from "./Forms/BioForm";
import { CoopDetailsForm } from "./Forms/CoopDetailsForm";
import { GrowerDetailsForm } from "./Forms/GrowerDetailsForm";
import { StripeForm } from "./StripeForm";
import { SubscriptionForm } from "./Forms/SubscriptionForm";
import { WholesaleBuyerDetailsForm } from "./Forms/WholesaleBuyerDetailsForm";
import { useWindowSize, breakpoint } from "../../utils/detectWindowSize";
import { PageHeader } from "../../components/PageHeader";

export const ApplicationNav: React.FC<{ application: WithId<ApplicationForm> }> = ({
  application,
}) => {
  const [width, height] = useWindowSize();
  const currentSection = useLocation().pathname.split("/")[4];

  const navigate = useNavigate();

  return (
    <>
      <Link to="/user-settings/applications/">
        <ArrowLeftOutlined /> Back to Applications
      </Link>
      <br />
      <br />
      <Steps direction="vertical">
        <Steps.Step
          title="Bio"
          style={{ cursor: "pointer" }}
          description={width > breakpoint.md ? "Your public rootedfarmers.com profile" : ""}
          status={
            currentSection === "bio" ? "process" : bioComplete(application) ? "finish" : "wait"
          }
          onClick={() => navigate("bio")}
        />

        <Steps.Step
          title="Business Details"
          description={width > breakpoint.md ? "More about your business" : ""}
          status={
            currentSection === "details"
              ? "process"
              : detailsComplete(application)
              ? "finish"
              : "wait"
          }
          onClick={() => navigate("details")}
        />

        <Steps.Step
          title="Rooted Review"
          description={width > breakpoint.md ? "Rooted staff will review your application" : ""}
          status={
            currentSection === "review"
              ? "process"
              : application.status === "approved" || application.status === "profile-created"
              ? "finish"
              : application.status === "waitlisted"
              ? "error"
              : "wait"
          }
          onClick={() => navigate("review")}
        />

        {(application.type === "coop" || application.type === "grower") && (
          <>
            <Steps.Step
              disabled={application.status === "in-progress"}
              title="Rooted Subscription"
              description={width > breakpoint.md ? "Pick your subscription plan" : ""}
              status={
                currentSection === "subscription"
                  ? "process"
                  : subscriptionComplete(application)
                  ? "finish"
                  : "wait"
              }
              onClick={
                application.status === "approved" || application.status === "profile-created"
                  ? () => navigate("subscription")
                  : undefined
              }
            />
            <Steps.Step
              title="Stripe Onboarding"
              disabled={application.status === "in-progress"}
              description={width > breakpoint.md ? "Create an account to receive payments" : ""}
              status={
                currentSection === "stripe"
                  ? "process"
                  : stripeComplete(application)
                  ? "finish"
                  : "wait"
              }
              onClick={
                application.status === "approved" || application.status === "profile-created"
                  ? () => navigate("stripe")
                  : undefined
              }
            />
          </>
        )}
      </Steps>
    </>
  );
};

export const ApplicationDetails: React.FC<{ application: WithId<ApplicationForm> }> = ({
  application,
}) => {
  const [width, height] = useWindowSize();
  const currentSection = useLocation().pathname.split("/")[4];

  const displayTitle =
    application?.bio?.displayName || `New ${legalProfileTypes[application.type].name}`;

  const breadcrumb = useRouterBreadcrumb({
    [application._id]: displayTitle,
    applications: false,
  });

  const emailSubject = `Help with ${displayTitle} - ${currentSection}`.replace(" ", "%20");
  const emailBody = `I need help with my application (id ${application._id}).`.replace(" ", "%20");
  const mailtoString = `mailto:help@rootedfarmers.com?subject=${emailSubject}&body=${emailBody}`;

  const disabled = !(application.status === "in-progress");

  return (
    <WideLayout>
      {width > breakpoint.md && (
        <Layout.Sider style={{ marginTop: 7, marginRight: 16 }} width={300}>
          <ApplicationNav application={application} />
        </Layout.Sider>
      )}

      <PageContent>
        <PageHeader
          breadcrumb={breadcrumb}
          title={displayTitle}
          extra={
            <a href={mailtoString}>
              <Button type="primary">Need Help?</Button>
            </a>
          }
        />

        {width <= breakpoint.md && (
          <>
            <ApplicationNav application={application} />
            <br />
          </>
        )}

        <Routes>
          <Route path="bio" element={<BioForm application={application} disabled={disabled} />} />

          {application.type === "grower" && (
            <Route
              path="details"
              element={<GrowerDetailsForm application={application} disabled={disabled} />}
            />
          )}
          {application.type === "coop" && (
            <Route
              path="details"
              element={<CoopDetailsForm application={application} disabled={disabled} />}
            />
          )}
          {application.type === "wholesale-buyer" && (
            <Route
              path="details"
              element={<WholesaleBuyerDetailsForm application={application} disabled={disabled} />}
            />
          )}

          {/* Only approved grower and coop applications can view these */}
          {application.type !== "wholesale-buyer" &&
            (application.status === "approved" || application.status === "profile-created") && (
              <>
                <Route
                  path="subscription"
                  element={
                    <SubscriptionForm
                      application={application}
                      disabled={application.status === "profile-created"}
                    />
                  }
                />
                <Route path="stripe" element={<StripeForm application={application} />} />
              </>
            )}

          <Route path="review" element={<ApplicationCompleteSplash application={application} />} />

          <Route path="" element={<Navigate to={firstIncompleteSection(application)} replace />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </PageContent>

      {width > breakpoint.xl && <Layout.Sider />}
    </WideLayout>
  );
};
