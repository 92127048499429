import { FullGrowerRole } from "@rooted/shared";
import { Alert, Button, Space } from "antd";
import React from "react";
import { EnterpriseProfileCallout } from "../../../components/Misc/EnterpriseProfileCallout";
import { PageHeader } from "../../../components/PageHeader";
import {
  approveCoopMembership,
  removeCoopMembership,
  useGrowerCoopMembershipRequests,
  useGrowerCoopMemberships,
  useGrowerPendingCoopMemberships,
} from "../../../services/coop-memberships";
import { CloudFunctionButton } from "../../admins/SiteSettings/ActionsPane";
import { LoadingPage } from "../../layouts";

export const CoopMembershipSettingsPane: React.FC<{
  role: FullGrowerRole;
}> = ({ role }) => {
  const [memberships, membershipsLoading] = useGrowerCoopMemberships(role);
  const [pendingCoopMemberships, pendingCoopMembershipsLoading] = useGrowerPendingCoopMemberships(
    role
  );
  const [membershipRequests, membershipRequestsLoading] = useGrowerCoopMembershipRequests(role);

  if (!memberships || !pendingCoopMemberships || !membershipRequests)
    return (
      <>
        <PageHeader title={"Collective Membership"} />
        <LoadingPage />
      </>
    );

  return (
    <>
      <PageHeader title={"Collective Membership"} />

      {memberships.length === 0 &&
        pendingCoopMemberships.length === 0 &&
        membershipRequests.length === 0 && (
          <Alert
            style={{ marginBottom: 16 }}
            message="You're not currently a member of any coop."
            type="info"
          />
        )}
      {membershipRequests.map((membership) => (
        <Alert
          key={membership._id}
          style={{ marginBottom: 16 }}
          message={
            <>
              <EnterpriseProfileCallout id={membership.coopId} /> requested to connect with you.
            </>
          }
          type="info"
          action={
            <Space>
              <CloudFunctionButton
                danger
                type="primary"
                onClick={() => approveCoopMembership(membership._id)}
              >
                Approve
              </CloudFunctionButton>
              <Button danger type="primary" onClick={() => removeCoopMembership(membership._id)}>
                Decline
              </Button>
            </Space>
          }
        />
      ))}

      {pendingCoopMemberships.map((membership) => (
        <Alert
          key={membership._id}
          style={{ marginBottom: 16 }}
          message={
            <>
              Your request to <EnterpriseProfileCallout id={membership.coopId} /> is pending.
            </>
          }
          type="info"
          action={
            <Space>
              <Button danger type="primary" onClick={(c) => removeCoopMembership(membership._id)}>
                Cancel Request
              </Button>
            </Space>
          }
        />
      ))}

      {memberships.map((membership) => (
        <Alert
          key={membership._id}
          style={{ marginBottom: 16 }}
          message={
            <>
              You're a member of <EnterpriseProfileCallout id={membership.coopId} />.
            </>
          }
          type="info"
        />
      ))}
    </>
  );
};
