import { FullRetailBuyerRole, normalizeValue, RetailProfileBio } from "@rooted/shared";
import { Form, Input, Typography } from "antd";
import React, { useState } from "react";
import { FirestoreDocForm } from "../../../components/FormControls/FirestoreDocForm";
import { PageHeader } from "../../../components/PageHeader";
import { db } from "../../../services/firebase";
import { LoadingPage } from "../../layouts";

export const RetailProfilePane: React.FC<{
  role: FullRetailBuyerRole;
}> = ({ role }) => {
  return (
    <>
      <PageHeader title={"Profile"} breadcrumb />
      {role.profile ? <RetailProfileEditForm role={role} /> : <LoadingPage />}
    </>
  );
};

const RetailProfileEditForm: React.FC<{ role: FullRetailBuyerRole }> = ({ role }) => {
  const [dbRef] = useState(db.collection("profiles").doc(role.profileId));
  return (
    <FirestoreDocForm
      rootFieldPath="bio"
      data={role.profile.bio}
      dbRef={dbRef}
      layout="vertical"
      requiredMark="optional"
      replaceOnSubmit={(values: RetailProfileBio): RetailProfileBio => ({
        ...values,
        _displayNameLowerCase: normalizeValue(values.displayName)!,
      })}
    >
      <ol style={{ paddingLeft: 20 }}>
        <li>
          <Form.Item
            name="displayName"
            label="Display Name"
            rules={[
              {
                required: true,
                message: "Please enter your name",
                min: 2,
                transform: (name: string) => name.trim(),
              },
            ]}
            requiredMark
          >
            <Input type="text"></Input>
          </Form.Item>
        </li>
        <li>
          <Form.Item
            requiredMark
            name="phoneNumber"
            label="Phone Number"
            rules={[
              {
                required: true,
                type: "string",
                min: 10,
                max: 14,
                transform: (phoneNumber: string) => phoneNumber.trim(),
                message: "Please enter your phone number",
              },
            ]}
          >
            <Input type="tel" />
          </Form.Item>
        </li>
      </ol>
    </FirestoreDocForm>
  );
};
