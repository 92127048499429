import {
  assertRoleExists,
  ChildOrder,
  formatPluralCount,
  getOrderDetailsBySeller,
  isBuyer,
  isSeller,
  searchObjectCurried,
  WithId,
} from "@rooted/shared";
import { Skeleton, Space, Table, Typography } from "antd";
import Search from "antd/lib/input/Search";
import { ColumnsType, TableRowSelection } from "antd/lib/table/interface";
import React, { useEffect, useMemo, useState } from "react";
import { useDebouncedTrigger } from "../../../hooks/util/useDebouncedTrigger";
import { useQuery } from "../../../hooks/util/useQuery";
import { useSyncQueryParams } from "../../../hooks/util/useSyncQueryParams";
import { useRooted } from "../../../RootedContext";
import { db, useCollectionDataChecked } from "../../../services/firebase";
import {
  makeAdminOrderTableColumns,
  makeOrderTableColumns,
  OrderWithSelectedSellerDetails,
} from "./OrderTableColumns";

/**
 * Fetches and renders all orders of a status
 */
export const OrdersTable: React.FC<{
  status: ChildOrder["status"];
  rowSelection?: TableRowSelection<OrderWithSelectedSellerDetails>;
}> = ({ status, rowSelection }) => {
  const { activeRole } = useRooted();
  assertRoleExists(activeRole);

  const [orders = [], loading] = useCollectionDataChecked<WithId<ChildOrder>>(
    (isSeller(activeRole)
      ? db.collection("childOrders").where("allSellerIds", "array-contains", activeRole.profileId)
      : db.collection("childOrders").where("buyerId", "==", activeRole.profileId)
    )
      .where("status", "==", status)
      .orderBy("placedAt", "desc"),
    {
      idField: "_id",
    }
  );

  // Pre-compute the seller who should be considered for this order.
  // This matters when a farmer contributes to part of a coop order.
  // By computing this here, we allow the columns to be display-only,
  // not doing any computations themselves.
  const ordersWithSellerDetails: OrderWithSelectedSellerDetails[] = useMemo(
    () =>
      orders.map((order) => ({
        order,
        selectedSellerDetails: getOrderDetailsBySeller({
          order,
          sellerId: isBuyer(activeRole) ? order.sellerId : activeRole.profileId,
        }),
      })),
    [activeRole, orders]
  );

  const columns = useMemo(
    () => makeOrderTableColumns({ status, nameColumn: isSeller(activeRole) ? "buyer" : "seller" }),
    [activeRole, status]
  );

  return (
    <OrderTableWithSearch
      loading={loading}
      columns={columns}
      data={ordersWithSellerDetails}
      rowSelection={rowSelection}
    />
  );
};

export const AdminOrdersTable: React.FC<{ status?: ChildOrder["status"] }> = ({ status }) => {
  const [orders = [], loading] = useCollectionDataChecked<WithId<ChildOrder>>(
    (status
      ? db.collection("childOrders").where("status", "==", status)
      : db.collection("childOrders")
    ).orderBy("placedAt", "desc"),
    {
      idField: "_id",
    }
  );

  // This is the main difference than the normal table -- Always use the original seller!
  // The admin interface won't be utilizing the role while viewing this, so the table
  // data selection + columns must not rely on role.
  const ordersWithSellerDetails: OrderWithSelectedSellerDetails[] = useMemo(
    () =>
      orders.map((order) => ({
        order,
        selectedSellerDetails: getOrderDetailsBySeller({
          order,
          sellerId: order.sellerId,
        }),
      })),
    [orders]
  );

  const columns = useMemo(() => makeAdminOrderTableColumns(status), [status]);

  return (
    <OrderTableWithSearch loading={loading} columns={columns} data={ordersWithSellerDetails} />
  );
};

const OrderTableWithSearch: React.FC<{
  data: OrderWithSelectedSellerDetails[];
  loading?: boolean;
  columns: ColumnsType<OrderWithSelectedSellerDetails>;
  rowSelection?: TableRowSelection<OrderWithSelectedSellerDetails>;
}> = ({ data, loading: loadingExternal, columns, rowSelection }) => {
  const query = useQuery();
  const [search, setSearch] = useState<string>(() => query.get("s") || "");
  const debouncedSearch = useDebouncedTrigger(search);

  useSyncQueryParams([["s", debouncedSearch]]);

  const filteredData = useMemo(
    () =>
      !debouncedSearch.trim() ? data : data.filter(searchObjectCurried(debouncedSearch.trim())),
    [debouncedSearch, data]
  );

  const loading = loadingExternal || debouncedSearch !== search;

  // See comment in `<Table>` about why `key` needs to change when `columns` changes
  const [columnsKey, setColumnsKey] = useState(0);
  useEffect(() => {
    setColumnsKey((key) => key + 1);
  }, [columns]);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Search
        placeholder={`Search Orders`}
        style={{ width: 300 }}
        allowClear
        onSearch={(v) => void setSearch(v)}
        defaultValue={search || undefined} // use query param
        enterButton
      />
      <Typography.Text type="secondary">
        {loading ? (
          <Skeleton active title={{ width: 60 }} paragraph={false} />
        ) : (
          formatPluralCount("result", filteredData.length)
        )}
      </Typography.Text>
      <Table
        // AntD won't update the table's default sort order if the column default sort orders change.
        // AntD lacks a way to programmatically set the table sort orders,
        // so the only way to ensure the sort orders (e.g. changing from fulfilledAt -> cancelledAt)
        // default correctly on status change is to trigger a rerender via the key.
        key={`${columnsKey}`}
        loading={loading || debouncedSearch !== search}
        columns={columns}
        rowKey={({ order }) => order._id}
        dataSource={filteredData}
        pagination={false}
        rowSelection={rowSelection}
        size="small"
        className="table-x-scroll"
      />
    </Space>
  );
};
