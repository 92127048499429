import {
  BloomSize,
  ColorKey,
  DesignElementKey,
  StemLength,
  ListingQueryContstraints as ListingQueryConstraints,
  RealListing,
  CSASeason,
  PottedPlantType,
  PottedPlantTag,
  DriedFlowerProductType,
  WreathTag,
  WreathDiameter,
  assertIsBuyer,
} from "@rooted/shared";
import firebase from "firebase/app";
import React from "react";
import { setAddress, useCurrentCart } from "../../../../../services/buyers/active-cart";
import {
  AddressConstraintBox,
  BloomSizeConstraintBox,
  ColorConstraintBox,
  DesignElementConstraintBox,
  PhysicalFulfillmentConstraintBox,
  StemLengthConstraintBox,
  CSASeasonConstraintBox,
  PottedPlantTypeConstraintBox,
  PottedPlantTagsConstraintBox,
  DriedFlowerTypeConstraintBox,
  WreathTagsConstraintBox,
  WreathDiameterConstraintBox,
} from "./SearchFilterWidgets";
import { useRooted } from "../../../../../RootedContext";

import "./SearchFilters.less";

export type ItemConstraints = Partial<{
  colors: ColorKey[];
  stemLengths: StemLength[];
  bloomSizes: BloomSize[];
  designElements: DesignElementKey[];
  seasons: CSASeason[];
  pottedPlantTypes: PottedPlantType[];
  pottedPlantTags: PottedPlantTag[];
  driedFlowerTypes: DriedFlowerProductType[];
  wreathTags: WreathTag[];
  wreathDiameters: WreathDiameter[];
}>;

export const DEFAULT_PHYSICAL_CONSTRAINTS: ListingQueryConstraints = {
  type: "all",
  dateRange: {
    start: firebase.firestore.Timestamp.now(),
    end: firebase.firestore.Timestamp.fromMillis(Date.now() + 999999999),
  },
  delivery: {
    enabled: true,
  },
  pickup: {
    enabled: true,
    maxDistance: 50,
  },
  shipping: {
    enabled: true,
  },
  excludedProfileIds: [],
  showZeroQuantity: false,
};

export const SearchFilters: React.FC<{
  disableDelivery?: boolean;
  physicalConstraints: ListingQueryConstraints;
  setPhysicalConstraints: React.Dispatch<React.SetStateAction<ListingQueryConstraints>>;
  itemConstraints: ItemConstraints;
  setItemConstraints: React.Dispatch<React.SetStateAction<ItemConstraints>>;
}> = ({
  physicalConstraints,
  setPhysicalConstraints,
  itemConstraints,
  setItemConstraints,
  disableDelivery,
}) => {
  const { activeRole } = useRooted();
  assertIsBuyer(activeRole);

  const [cart] = useCurrentCart();

  // We should monitor this for performance. If this ends up being expensive,
  // we can optimize this by changing the item constraints to using a `useReducer`.
  // That's a pretty intensive change that will require making the filter edit widgets
  // need to know about the dispatch, so that should only be done if necessary.
  const setItemConstraintField = <K extends keyof ItemConstraints>(key: K) => (
    value: ItemConstraints[K]
  ) => {
    setItemConstraints((oldConstraints) => ({
      ...oldConstraints,
      [key]: value,
    }));
  };

  return (
    <>
      <AddressConstraintBox
        role={activeRole}
        cart={cart}
        setAddress={(a) => setAddress(activeRole, a)}
      />
      <PhysicalFulfillmentConstraintBox
        openByDefault
        disableDelivery={disableDelivery}
        constraints={physicalConstraints}
        setConstraints={setPhysicalConstraints}
      />
      {itemConstraints.driedFlowerTypes && (
        <DriedFlowerTypeConstraintBox
          openByDefault
          types={itemConstraints.driedFlowerTypes}
          setTypes={setItemConstraintField("driedFlowerTypes")}
        />
      )}
      {itemConstraints.colors && (
        <ColorConstraintBox
          openByDefault
          colors={itemConstraints.colors}
          setColors={setItemConstraintField("colors")}
        />
      )}
      {itemConstraints.pottedPlantTypes && (
        <PottedPlantTypeConstraintBox
          openByDefault
          types={itemConstraints.pottedPlantTypes}
          setTypes={setItemConstraintField("pottedPlantTypes")}
        />
      )}
      {itemConstraints.pottedPlantTags && (
        <PottedPlantTagsConstraintBox
          openByDefault
          tags={itemConstraints.pottedPlantTags}
          setTags={setItemConstraintField("pottedPlantTags")}
        />
      )}
      {itemConstraints.wreathTags && (
        <WreathTagsConstraintBox
          tags={itemConstraints.wreathTags}
          setTags={setItemConstraintField("wreathTags")}
        />
      )}
      {itemConstraints.wreathDiameters && (
        <WreathDiameterConstraintBox
          values={itemConstraints.wreathDiameters}
          setValues={setItemConstraintField("wreathDiameters")}
        />
      )}
      {itemConstraints.designElements && (
        <DesignElementConstraintBox
          designElements={itemConstraints.designElements}
          setDesignElements={setItemConstraintField("designElements")}
        />
      )}
      {itemConstraints.bloomSizes && (
        <BloomSizeConstraintBox
          bloomSizes={itemConstraints.bloomSizes}
          setBloomSizes={setItemConstraintField("bloomSizes")}
        />
      )}
      {itemConstraints.stemLengths && (
        <StemLengthConstraintBox
          stemLengths={itemConstraints.stemLengths}
          setStemLengths={setItemConstraintField("stemLengths")}
        />
      )}
      {itemConstraints.seasons && (
        <CSASeasonConstraintBox
          seasons={itemConstraints.seasons}
          setSeasons={setItemConstraintField("seasons")}
        />
      )}
    </>
  );
};

// Filter functions
// These check for if the product has *some* match across *every* filter
// (e.g. filtering for `"red, blue", "stem length > 2"` allows "red, SL: 3" AND "blue, SL: 4")

function hasColor({ product }: RealListing, { colors }: ItemConstraints) {
  if (colors && colors.length > 0) {
    if ("dominantColor" in product) {
      return colors.includes(product.dominantColor);
    }
  }
  return true;
}

function hasStemLength({ product }: RealListing, { stemLengths }: ItemConstraints) {
  if (stemLengths && stemLengths.length > 0) {
    if ("stemLength" in product && product.stemLength) {
      return stemLengths.includes(product.stemLength);
    }

    // Stem length is an optional field, so if a stem length is selected then filter out results
    // that don't have stem lengths set
    return false;
  }
  return true;
}

function hasBloomSize({ product }: RealListing, { bloomSizes }: ItemConstraints) {
  if (bloomSizes && bloomSizes.length > 0) {
    if ("bloomSize" in product) {
      return product.bloomSize && bloomSizes.includes(product.bloomSize);
    }
  }
  return true;
}

function hasDesignElements({ product }: RealListing, { designElements }: ItemConstraints) {
  if (designElements && designElements.length > 0) {
    if ("_prototypeCache" in product) {
      if ("designElements" in product._prototypeCache) {
        // Check any overlap of elements
        return (
          product._prototypeCache.designElements &&
          designElements.some((e) => product._prototypeCache.designElements.includes(e))
        );
      }
    }
  }
  return true;
}

function hasSeason({ product }: RealListing, { seasons }: ItemConstraints) {
  if (seasons && seasons.length > 0) {
    if ("season" in product) {
      return product.season && seasons.includes(product.season);
    }
  }
  return true;
}

function hasPottedPlantType({ product }: RealListing, { pottedPlantTypes }: ItemConstraints) {
  if (pottedPlantTypes && pottedPlantTypes.length > 0) {
    if ("pottedPlantType" in product) {
      return product.pottedPlantType && pottedPlantTypes.includes(product.pottedPlantType);
    }
  }
  return true;
}

function hasPottedPlantTags({ product }: RealListing, { pottedPlantTags }: ItemConstraints) {
  if (pottedPlantTags && pottedPlantTags.length > 0) {
    if ("tags" in product) {
      const tags: string[] = product.tags;
      return pottedPlantTags.some((tag) => tags.includes(tag));
    }
  }
  return true;
}

function hasWreathTags({ product }: RealListing, { wreathTags }: ItemConstraints) {
  if (wreathTags && wreathTags.length > 0) {
    if ("tags" in product) {
      const tags: string[] = product.tags;
      return wreathTags.some((tag) => tags.includes(tag));
    }
  }
  return true;
}

function hasWreathDiameter({ product }: RealListing, { wreathDiameters }: ItemConstraints) {
  if (wreathDiameters && wreathDiameters.length > 0) {
    if ("diameter" in product) {
      return product.diameter && wreathDiameters.includes(product.diameter);
    }
  }
  return true;
}

function hasDriedFlowerType({ product }: RealListing, { driedFlowerTypes }: ItemConstraints) {
  if (driedFlowerTypes && driedFlowerTypes.length > 0) {
    if ("type" in product) {
      return product.type && driedFlowerTypes.includes(product.type);
    }
  }
  return true;
}

export const createItemConstraintFilter = (constraints: ItemConstraints) => (
  listing: RealListing
): boolean => {
  return [
    hasColor,
    hasStemLength,
    hasBloomSize,
    hasDesignElements,
    hasSeason,
    hasPottedPlantType,
    hasPottedPlantTags,
    hasDriedFlowerType,
    hasWreathTags,
    hasWreathDiameter,
  ].every((filter) => filter(listing, constraints));
};
