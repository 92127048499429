import {
  FullCoopRole,
  FullGrowerRole,
  FullRetailBuyerRole,
  FullWholesaleBuyerRole,
  isCoop,
  isSeller,
} from "@rooted/shared";
import { Badge, Layout, Menu } from "antd";
import React from "react";
import { Link, Route, Routes, useLocation, Navigate } from "react-router-dom";
import { CollapsingSider } from "../../../components/Sider/CollapsingSider";
import { NotFoundPage, PageContent, WideLayout } from "../../../views/layouts";
import { AddressesPane } from "./AddressesPane";
import { CoopMembershipSettingsPane } from "./CoopMembershipSettings";
import { GrowerOffersPane } from "./GrowerOffersPane";
import { MediaPane } from "./MediaPane";
import { EnterpriseProfilePane, isMissingGrowerExtraFields } from "./EnterpriseProfilePane";
import { RetailProfilePane } from "./RetailProfilePane";
import { useCoopMembershipSettingsNotificationsCount } from "./settings-notifications";
import { SubscriptionPane } from "./SubscriptionPane";
import { WholesaleBuyerStartPane } from "./WholesaleBuyerStartPane";
import { Branding } from "./Branding";
import { CreditCardsPane } from "./CreditsCardsPane";
import { RetailStartPane } from "./RetailStartPane";

export const Settings: React.FC<{
  role: FullGrowerRole | FullWholesaleBuyerRole | FullCoopRole | FullRetailBuyerRole;
}> = ({ role }) => {
  const category = useLocation().pathname.split("/")[2] || "start";
  const profileSettingsCount = isMissingGrowerExtraFields(role.profile) ? 1 : 0;

  return (
    <>
      <WideLayout>
        <CollapsingSider
          selectedKeys={[category]}
          smallDrawerLabel={{
            title: "Account",
            selection: category,
          }}
        >
          {!isSeller(role) && (
            <Menu.Item key="start">
              <Link to="">Where Do I Start?</Link>
            </Menu.Item>
          )}
          <Menu.Item key="profile">
            <Link to="profile">Profile</Link> <Badge count={profileSettingsCount} />
          </Menu.Item>
          <Menu.Item key="credit-cards">
            <Link to="credit-cards">Credit Cards</Link>
          </Menu.Item>
          {role.type !== "retail-buyer" && (
            <>
              <Menu.Item key="media-library">
                <Link to="media-library">Image Library</Link>
              </Menu.Item>
            </>
          )}
          {/* Settings for wholesale buyers */}
          {role.type === "wholesale-buyer" && (
            <Menu.Item key="addresses">
              <Link to="addresses">Addresses</Link>
            </Menu.Item>
          )}
          {/* Grower-specific routes */}
          {role.type === "grower" && (
            <>
              <Menu.Item key="coop-membership">
                <Link to="coop-membership">
                  Collective Membership <CoopMembershipSettingsBadge role={role} />
                </Link>
              </Menu.Item>
              <Menu.Item key="subscription">
                <Link to="subscription">Rooted Subscription</Link>
              </Menu.Item>
              <Menu.Item key="special-offers">
                <Link to="special-offers">Special Offers</Link>
              </Menu.Item>
            </>
          )}
          {/* Coop-specific routes */}
          {role.type === "coop" && (
            <Menu.Item key="subscription">
              <Link to="subscription">Rooted Subscription</Link>
            </Menu.Item>
          )}
          {role.type !== "retail-buyer" && role.type !== "grower" && (
            <>
              <Menu.Item key="branding">
                <Link to="branding">Branding</Link>
              </Menu.Item>
            </>
          )}
        </CollapsingSider>
        <PageContent>
          <Routes>
            {/* Enterprise-specific routes -- these apply to everyone but retail buyers */}
            {role.type !== "retail-buyer" && (
              <>
                <Route path="media-library/*" element={<MediaPane role={role} />} />
                <Route path="branding" element={<Branding role={role} />} />
              </>
            )}

            <Route
              path="profile"
              element={
                role.type === "retail-buyer" ? (
                  <RetailProfilePane role={role} />
                ) : (
                  <EnterpriseProfilePane />
                )
              }
            />

            <Route path="credit-cards" element={<CreditCardsPane role={role} />} />

            {/* Settings for wholesale buyers */}
            {role.type === "wholesale-buyer" && (
              <>
                <Route path="addresses/*" element={<AddressesPane role={role} />} />
                <Route path="" element={<WholesaleBuyerStartPane role={role} />} />
              </>
            )}
            {/* Grower-specific routes */}
            {role.type === "grower" && (
              <>
                <Route path="" element={<Navigate to="profile" replace />} />
                <Route path="special-offers" element={<GrowerOffersPane role={role} />} />
                <Route
                  path="coop-membership/*"
                  element={<CoopMembershipSettingsPane role={role} />}
                />
                <Route path="subscription/*" element={<SubscriptionPane role={role} />} />
              </>
            )}
            {/* Coop-specific routes */}
            {role.type === "coop" && (
              <>
                <Route path="subscription/*" element={<SubscriptionPane role={role} />} />
                <Route path="" element={<Navigate to="profile" replace />} />
              </>
            )}
            {role.type === "retail-buyer" && <Route path="" element={<RetailStartPane />} />}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Layout.Sider />
        </PageContent>
      </WideLayout>
    </>
  );
};

export const CoopMembershipSettingsBadge: React.FC<{
  role: FullGrowerRole | FullCoopRole;
}> = ({ role }) => <Badge count={useCoopMembershipSettingsNotificationsCount(role.profileId)} />;
