import {
  assertIsSeller,
  GenerateSalesSummaryRequest,
  GenerateSalesSummaryResponse,
  IntervalsSummary,
  isCoop,
  ProfileIntervalSales,
} from "@rooted/shared";
import {
  Button,
  Col,
  Collapse,
  Form,
  InputNumber,
  notification,
  Radio,
  Space,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { DateTime } from "luxon";
import React, { useCallback, useMemo, useState } from "react";
import { formatCurrency } from "../../../components/FormControls/CurrencyInput";
import { DateRange, DateRangePicker } from "../../../components/Misc/DateRangePicker";
import { PageHeader } from "../../../components/PageHeader";
import { useRooted } from "../../../RootedContext";
import { functions } from "../../../services/firebase";
import { PageContent, WideLayout } from "../../layouts";

const ONE_DAY_MS = 60e3 * 60 * 24;

function formatSummaryCurrency(amount: number): string {
  return amount ? formatCurrency(amount) : "–";
}

const generateSalesSummary = async (
  params: GenerateSalesSummaryRequest
): Promise<GenerateSalesSummaryResponse> => {
  const { data } = await functions.httpsCallable("generateSalesSummary")(params);
  return data;
};

function calculateIntervalTotal(sales: ProfileIntervalSales["intervalSales"]): number {
  return Object.values(sales).reduce((acc, curr) => acc + curr.total ?? 0, 0);
}

function calculateCoopFee(
  sales: ProfileIntervalSales["intervalSales"],
  coopFeePercent: number
): number {
  const subtotal = calculateIntervalTotal(sales);
  return Math.round(subtotal * coopFeePercent);
}

function calculateSellerReceives(
  sales: ProfileIntervalSales["intervalSales"],
  coopFeePercent: number
): number {
  const subtotal = calculateIntervalTotal(sales);
  const fee = Math.round(subtotal * coopFeePercent);
  return subtotal - fee;
}

const SalesTable: React.FC<{
  summary: IntervalsSummary;
  showCoopFee?: boolean;
  formatDate: (date: string) => string;
}> = ({ summary, showCoopFee = false, formatDate }) => {
  const { activeRole } = useRooted();
  assertIsSeller(activeRole);

  const [coopFee, setCoopFee] = useState(0); // 0 - 100
  const coopFeePercent = coopFee / 100;

  const coopColumns = useMemo(() => {
    const coopColumns: ColumnsType<ProfileIntervalSales> = showCoopFee
      ? [
          {
            title: "Coop Fee",
            key: "collective-fee",
            fixed: "right" as const,
            width: 50,
            render: (_, { intervalSales }) => {
              const fee = calculateCoopFee(intervalSales, coopFeePercent);
              return formatSummaryCurrency(fee);
            },
          },
          {
            title: "Seller Receives",
            key: "seller-receives",
            width: 50,
            fixed: "right" as const,
            render: (_, { intervalSales }) => {
              const subtotal = calculateIntervalTotal(intervalSales);

              const fee = Math.round(subtotal * coopFeePercent);
              const grower = subtotal - fee;
              return formatSummaryCurrency(grower);
            },
          },
        ]
      : [];

    return coopColumns;
  }, [coopFeePercent, showCoopFee]);

  const regularColumns: ColumnsType<ProfileIntervalSales> = useMemo(() => {
    const intervalColumns: ColumnsType<ProfileIntervalSales> = summary.orderedIntervals.map(
      (interval: { start: string; end: string }) => ({
        title: (
          <span
            title={
              DateTime.fromISO(interval.start).toFormat("MMM d, yyyy") +
              " to " +
              DateTime.fromISO(interval.end).toFormat("MMM d, yyyy")
            }
          >
            {formatDate(interval.start)}
          </span>
        ),
        key: interval.start,
        render: (_, { intervalSales }) => {
          return formatSummaryCurrency(intervalSales[interval.start]?.total ?? 0);
        },
        sorter: (a, b) =>
          (a.intervalSales[interval.start]?.total ?? 0) -
          (b.intervalSales[interval.start]?.total ?? 0),
      })
    );

    const columns: ColumnsType<ProfileIntervalSales> = [
      {
        title: "Member",
        key: "member",
        fixed: "left" as const,
        defaultSortOrder: "descend",
        render: (_, { profile }) => profile.displayName,
        sorter: (a, b) => b.profile.displayName.localeCompare(a.profile.displayName),
      },
      ...intervalColumns,
      {
        title: "Total",
        key: "total",
        width: 80,
        fixed: "right" as const,
        render: (_, { intervalSales }) => {
          const subtotal = calculateIntervalTotal(intervalSales);
          return formatSummaryCurrency(subtotal);
        },
        sorter: (a, b) =>
          calculateIntervalTotal(a.intervalSales) - calculateIntervalTotal(b.intervalSales),
      },
    ];

    return columns;
  }, [summary, formatDate]);

  const columns = useMemo(() => [...regularColumns, ...coopColumns], [coopColumns, regularColumns]);

  return (
    <>
      {showCoopFee && (
        <Space style={{ marginLeft: 8 }}>
          <Typography.Text>Coop Fee:</Typography.Text>
          <InputNumber
            min={0}
            max={100}
            precision={0}
            step={1}
            value={coopFee}
            onChange={(n) => setCoopFee(Number(n))}
            formatter={(value) => `${value}%`}
            parser={(value) => value?.replace("%", "") || 0}
          />
        </Space>
      )}

      <Table
        size="small"
        pagination={false}
        bordered
        className="table-x-scroll table-sales-summary"
        style={{ width: "100%", marginTop: 16 }}
        columns={columns}
        scroll={{ x: true }}
        dataSource={Object.values(summary.intervalSalesByProfile).map((interval) => ({
          ...interval,
          key: interval.profile.profileId,
        }))}
        summary={() => {
          const intervals = summary.orderedIntervals;
          const count = intervals.length;

          const coopFeeSum = Object.values(summary.intervalSalesByProfile).reduce(
            (acc, curr) => acc + calculateCoopFee(curr.intervalSales, coopFeePercent),
            0
          );

          const sellerReceivesSum = Object.values(summary.intervalSalesByProfile).reduce(
            (acc, curr) => acc + calculateSellerReceives(curr.intervalSales, coopFeePercent),
            0
          );

          const subtotalSum = intervals.reduce(
            (acc, curr) => acc + curr.overallIntervalStats.subtotal,
            0
          );

          const totalSum = intervals.reduce(
            (acc, curr) => acc + curr.overallIntervalStats.total,
            0
          );

          const handlingSum = intervals.reduce(
            (acc, curr) => acc + curr.overallIntervalStats.handlingFees,
            0
          );

          const fulfillmentSum = intervals.reduce(
            (acc, curr) => acc + curr.overallIntervalStats.fulfillmentFees,
            0
          );

          const showHandling = handlingSum > 0;
          const showFulfillment = fulfillmentSum > 0;
          const showSubtotal = showHandling || showFulfillment;

          return (
            <>
              {/* Subtotal */}
              {showSubtotal && (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Subtotal</Table.Summary.Cell>
                  {intervals.map((interval, idx) => (
                    <Table.Summary.Cell key={interval.start} index={idx + 1}>
                      {formatSummaryCurrency(interval.overallIntervalStats.subtotal)}
                    </Table.Summary.Cell>
                  ))}
                  <Table.Summary.Cell index={count + 1}>
                    {formatSummaryCurrency(subtotalSum)}
                  </Table.Summary.Cell>
                  {showCoopFee && (
                    <>
                      <Table.Summary.Cell index={count + 2}>
                        {formatSummaryCurrency(coopFeeSum)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={count + 3}>
                        {formatSummaryCurrency(sellerReceivesSum)}
                      </Table.Summary.Cell>
                    </>
                  )}
                </Table.Summary.Row>
              )}

              {/* Handling Fee */}
              {showHandling && (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Handling</Table.Summary.Cell>
                  {intervals.map((interval, idx) => (
                    <Table.Summary.Cell key={interval.start} index={idx + 1}>
                      {formatSummaryCurrency(interval.overallIntervalStats.handlingFees)}
                      {interval.overallIntervalStats.handlingFees > 0 && (
                        <span>
                          {" "}
                          (
                          {(
                            (interval.overallIntervalStats.handlingFees /
                              interval.overallIntervalStats.subtotal) *
                            100
                          ).toFixed(1)}
                          %)
                        </span>
                      )}
                    </Table.Summary.Cell>
                  ))}
                  <Table.Summary.Cell index={count + 1}>
                    {formatSummaryCurrency(handlingSum)}
                    {handlingSum > 0 && (
                      <span>
                        {" "}
                        ({((handlingSum / subtotalSum) * 100).toFixed(1)}
                        %)
                      </span>
                    )}
                  </Table.Summary.Cell>
                  {showCoopFee && (
                    <>
                      <Table.Summary.Cell index={count + 2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={count + 3}></Table.Summary.Cell>
                    </>
                  )}
                </Table.Summary.Row>
              )}

              {/* Delivery Fee */}
              {showFulfillment && (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>Delivery</Table.Summary.Cell>
                  {intervals.map((interval, idx) => (
                    <Table.Summary.Cell key={interval.start} index={idx + 1}>
                      {formatSummaryCurrency(interval.overallIntervalStats.fulfillmentFees)}
                    </Table.Summary.Cell>
                  ))}
                  <Table.Summary.Cell index={count + 1}>
                    {formatSummaryCurrency(fulfillmentSum)}
                  </Table.Summary.Cell>
                  {showCoopFee && (
                    <>
                      <Table.Summary.Cell index={count + 2}></Table.Summary.Cell>
                      <Table.Summary.Cell index={count + 3}></Table.Summary.Cell>
                    </>
                  )}
                </Table.Summary.Row>
              )}

              {/* Grand Total */}
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                {intervals.map((interval, idx) => (
                  <Table.Summary.Cell key={interval.start} index={idx + 1}>
                    {formatSummaryCurrency(interval.overallIntervalStats.total)}
                  </Table.Summary.Cell>
                ))}
                <Table.Summary.Cell index={count + 1}>
                  {formatSummaryCurrency(totalSum)}
                </Table.Summary.Cell>
                {showCoopFee && (
                  <>
                    <Table.Summary.Cell index={count + 2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={count + 3}></Table.Summary.Cell>
                  </>
                )}
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </>
  );
};

export const SalesSummary: React.FC = () => {
  const { activeRole } = useRooted();
  assertIsSeller(activeRole);

  const activeRoleIsCoop = isCoop(activeRole);

  const [dates, setDates] = useState<DateRange>([null, null]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GenerateSalesSummaryResponse>();
  const [intervalType, setIntervalType] = useState<"weeks" | "months">("weeks");
  const [group, setGroup] = useState<"byOriginalSeller" | "byBuyer">("byOriginalSeller");

  const generate = useCallback(async () => {
    const [start, end] = dates;
    if (!start || !end) return;

    setLoading(true);

    try {
      const newData = await generateSalesSummary({
        sellerProfileId: activeRole.profileId,
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      });

      setData(newData);
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Oops! Something went wrong.",
      });
    } finally {
      setLoading(false);
    }
  }, [activeRole.profileId, dates]);

  const formatDate = useMemo(() => {
    return (date: string) => {
      if (intervalType === "weeks") return DateTime.fromISO(date).toFormat("MMM d yyyy");
      else return DateTime.fromISO(date).toFormat("MMM yyyy");
    };
  }, [intervalType]);

  return (
    <WideLayout>
      <PageContent>
        <PageHeader title="Sales Summary" />

        <Collapse defaultActiveKey={["1"]} style={{ marginBottom: 20 }}>
          <Collapse.Panel collapsible="header" header="What is a Sales Summary?" key="1">
            <Col style={{ padding: "8px 8px 0 8px" }}>
              <Typography.Paragraph>
                View your sales history in just a couple of clicks!
              </Typography.Paragraph>
              <Typography.Paragraph>
                Take a look back at your sales over the season: view sales by week or by month, in
                total or by customer. Sales histories include all orders placed directly to you from
                your buyers.
              </Typography.Paragraph>
            </Col>
          </Collapse.Panel>
          <Collapse.Panel collapsible="header" header="How does it work?" key="2">
            <Col style={{ padding: "8px 8px 0 8px" }}>
              <Typography.Paragraph>
                <ol>
                  <li>Select the date range that you would like to view</li>
                  <li>Click the Go! button</li>
                  <li>Scroll on!</li>
                </ol>
              </Typography.Paragraph>
            </Col>
          </Collapse.Panel>
        </Collapse>

        <Form layout="vertical">
          <Form.Item label="View orders processed on and between:">
            <DateRangePicker
              value={dates}
              onChange={setDates}
              disabledDate={(date) => date.valueOf() > Date.now() + ONE_DAY_MS}
            />
            <Button type="primary" style={{ margin: 8 }} onClick={generate} loading={loading}>
              Go!
            </Button>
          </Form.Item>
        </Form>

        {data && (
          <>
            <br />
            <Space wrap style={{ marginTop: 16 }}>
              <Typography.Text>Period:</Typography.Text>
              <Radio.Group value={intervalType} onChange={(e) => setIntervalType(e.target.value)}>
                <Radio.Button value="weeks">Week</Radio.Button>
                <Radio.Button value="months">Month</Radio.Button>
              </Radio.Group>

              <Typography.Text>Group By:</Typography.Text>
              <Radio.Group value={group} onChange={(e) => setGroup(e.target.value)}>
                <Radio.Button value="byOriginalSeller">Seller</Radio.Button>
                <Radio.Button value="byBuyer">Buyer</Radio.Button>
              </Radio.Group>
            </Space>
            <SalesTable
              formatDate={formatDate}
              summary={data[intervalType][group]}
              showCoopFee={activeRoleIsCoop && group === "byOriginalSeller"}
            />
          </>
        )}
      </PageContent>
    </WideLayout>
  );
};
