import { removeUndefined, TaxRate, WithId } from "@rooted/shared";
import { db, useCollectionDataChecked } from "../firebase";
import {
  makePrefixQuery,
  useMergedCollectionHooks,
  useOldDataOnLoad,
} from "../higher-order-searches";

export const useTaxRateResults = (search: string, limit = 10) => {
  const baseQuery = db.collection("taxRates");
  const lowercaseSearch = search.toLowerCase();

  const nameQuery = useCollectionDataChecked<WithId<TaxRate>>(
    makePrefixQuery(baseQuery, "_nameLowercase", lowercaseSearch).limit(limit),
    { idField: "_id" }
  );

  const zipQuery = useCollectionDataChecked<WithId<TaxRate>>(
    baseQuery.where("zipCodes", "array-contains", lowercaseSearch).limit(limit),
    { idField: "_id" }
  );

  return useOldDataOnLoad(useMergedCollectionHooks([nameQuery, zipQuery]));
};

export interface CreateTaxRateParams {
  name: string;
  zipCodes: string[];
  active: boolean;
  rate: number;
}

export const makeTaxRate = (params: CreateTaxRateParams) => {
  const r: TaxRate = {
    ...params,
    _nameLowercase: params.name.toLowerCase(),
  };
  return removeUndefined(r);
};

export const addTaxRate = (params: CreateTaxRateParams) => {
  return db.collection("taxRates").add(makeTaxRate(params));
};
