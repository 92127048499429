import { WithId, GrowerApplicationForm, CoopApplicationForm, FullUserRole } from "@rooted/shared";

import { Form, Typography, Alert, Button } from "antd";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreditCardSelect } from "../../../components/FormControls/CreditCardSelect";
import { SubscriptionPicker } from "../../../components/SubscriptionPicker";
import {
  setApplicationCreditCard,
  setApplicationSubscription,
} from "../../../services/applications";
import { db } from "../../../services/firebase";
import { OfferCodeEntry } from "../../generic/Settings/SubscriptionPane";

export const SubscriptionForm: React.FC<{
  application: WithId<GrowerApplicationForm | CoopApplicationForm>;
  disabled: boolean;
}> = ({ application, disabled = false }) => {
  //@ts-expect-error Only used in a disabled form
  const mockRole: FullUserRole = { type: application.type, profileId: application._id };
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [subscriptionSelected, setSubscriptionSelected] = useState<boolean>(false);
  const [creditCardId, setCreditCardId] = useState<string | undefined>(application.creditCardId);
  const [subscriptionId, setSubscriptionId] = useState<string | undefined>(
    application.subscription?.key
  );

  // note we are guarenteed to have a credit card and subscription defined at submission
  const submit = async () => {
    setSubmitting(true);
    if (creditCardId && subscriptionId) {
      await setApplicationSubscription(application, subscriptionId);
      await setApplicationCreditCard(application, creditCardId);
      navigate(`/onboarding/applications/${application._id}/stripe`);
    }
    setSubmitting(false);
  };

  return (
    <>
      <Form
        layout="vertical"
        onFinish={() => {
          submit();
        }}
      >
        <Typography.Text>
          <Alert
            style={{ marginBottom: 20 }}
            message="Your subscription won't take effect until your Stripe account is fully linked to Rooted."
            type="warning"
            showIcon={true}
          />
        </Typography.Text>
        <Form.Item label="Credit Card">
          <CreditCardSelect
            disabled={disabled}
            role={mockRole}
            value={application.creditCardId}
            onChange={async (x) => {
              setCreditCardId(x);
            }}
          />
        </Form.Item>
        <OfferCodeEntry
          value={application?.subscription?.offerCode}
          onChange={(x) =>
            db
              .collection("applications")
              .doc(application._id)
              .set(
                {
                  subscription: {
                    offerCode: x,
                  },
                },
                { merge: true }
              )
          }
        />
        <Form.Item label="Subscription Type" className="ant-form-item-full-width">
          <SubscriptionPicker
            disabled={disabled || creditCardId === undefined}
            columnSpan={12}
            type={application.type}
            onSelect={async (x) => {
              setSubscriptionId(x);
              setSubscriptionSelected(true); // must be separate from subscriptionId
            }}
            selected={subscriptionId}
            constraints={{
              slowFlowers:
                application.type === "grower" ? application.details?.slowFlowersMember : false,
              ascfg: application.type === "grower" ? application.details?.ASCFGMember : false,
            }}
          />
        </Form.Item>

        <Form.Item>
          <Typography.Text>
            <em>
              Please note that Stripe may reflect your discount as a charge and refund on your
              credit card statement.
            </em>
          </Typography.Text>
          <br />
          <br />

          {/** This will only be enabled when a credit card and subscription is selected */}
          <Button
            disabled={!subscriptionSelected || disabled}
            type="primary"
            loading={submitting}
            htmlType="submit"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
