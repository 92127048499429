import {
  ActiveChildOrder,
  AddOrderItemRequest,
  AdHocListing,
  CancelledChildOrder,
  ChangeOrderItemRequest,
  changeOrderFulfillmentPriceRequest,
  ChildOrder,
  ExtractOrderType,
  FulfilledChildOrder,
  FullSellerRole,
  Listing,
  OrderItem,
  WithId,
  CancelOrderRequest,
  assertRoleExists,
  AnonymousPosChildOrder,
  InitiateAnonymousPosOrderPaymentResponse,
  InitiateAnonymousPosOrderPaymentRequest,
  RefreshAnonymousPosOrderPaymentRequest,
  RefreshAnonymousPosOrderPaymentResponse,
} from "@rooted/shared";
import { useRooted } from "../../RootedContext";
import { functions, db, useCollectionDataChecked, useDocumentDataChecked } from "../firebase";
import { CollectionHookReturn, DocumentHookReturn } from "../higher-order-searches";

export function useChildOrder(orderId: string): DocumentHookReturn<WithId<ChildOrder>> {
  const { activeRole } = useRooted();
  assertRoleExists(activeRole);

  const [order, orderLoading, orderError] = useDocumentDataChecked<WithId<ChildOrder>>(
    db.collection("childOrders").doc(orderId),
    { idField: "_id" }
  );

  return [order, orderLoading, orderError];
}

export function useChildOrderItems(orderId: string): CollectionHookReturn<WithId<OrderItem>> {
  const { activeRole } = useRooted();
  assertRoleExists(activeRole);

  const [items, itemsLoading, itemsError] = useCollectionDataChecked<WithId<OrderItem>>(
    db.collection("childOrders").doc(orderId).collection("items"),
    { idField: "_id" }
  );

  return [items, itemsLoading, itemsError];
}

const makeUseOrder = <T extends ChildOrder>(status: ExtractOrderType<T>) => {
  return (role: FullSellerRole): CollectionHookReturn<WithId<T>> => {
    const { activeRole } = useRooted();
    if (!activeRole) return [[], false, undefined];

    const [data, loading, error] = useCollectionDataChecked<WithId<T>>(
      db
        .collection("childOrders")
        .where("allSellerIds", "array-contains", activeRole.profileId)
        .where("status", "==", status)
        .orderBy("placedAt", "desc"),
      {
        idField: "_id",
      }
    );

    return [data, loading, error];
  };
};

export const useActiveOrders = makeUseOrder<ActiveChildOrder>("active");
export const useCancelledOrders = makeUseOrder<CancelledChildOrder>("cancelled");
export const useFulfilledOrders = makeUseOrder<FulfilledChildOrder>("fulfilled");
export const useAnonymousPosOrders = makeUseOrder<AnonymousPosChildOrder>("guest-checkout");

export const addOrderItem = (
  order: WithId<ActiveChildOrder | AnonymousPosChildOrder>,
  listing: WithId<Listing | AdHocListing>,
  quantity: number
) => {
  const x: AddOrderItemRequest = {
    listing,
    orderId: order._id,
    quantity,
  };
  return functions.httpsCallable("addOrderItem")(x);
};

export const changeItem = (
  order: WithId<ActiveChildOrder | AnonymousPosChildOrder>,
  item: OrderItem
) => {
  const req: ChangeOrderItemRequest = {
    listingId: item.listing._id,
    orderId: order._id,
    newItem: item,
  };
  return functions.httpsCallable("changeOrderItem")(req);
};

export const changeOrderFulfillmentPrice = (orderId: string, newPrice: number) => {
  const req: changeOrderFulfillmentPriceRequest = {
    orderId,
    newPrice,
  };
  return functions.httpsCallable("changeOrderFulfillmentPrice")(req);
};

export const changeItemQuantity = (
  order: WithId<ActiveChildOrder | AnonymousPosChildOrder>,
  item: OrderItem,
  quantity: number
) => {
  const x: ChangeOrderItemRequest = {
    orderId: order._id,
    listingId: item.listing._id,
    newItem: { ...item, quantity },
  };
  return functions.httpsCallable("changeOrderItem")(x);
};

export const markOrderComplete = (order: WithId<ActiveChildOrder>) => {
  return functions.httpsCallable("markOrderComplete")(order._id);
};

export const cancelOrder = (data: CancelOrderRequest) => {
  return functions.httpsCallable("cancelOrder")(data);
};

export const initiateAnonymousPosOrderPayment = async (
  params: InitiateAnonymousPosOrderPaymentRequest
): Promise<InitiateAnonymousPosOrderPaymentResponse> => {
  const { data } = await functions.httpsCallable("initiateAnonymousPosOrderPayment")(params);
  return data;
};

export const refreshAnonymousPosOrderPayment = async (
  params: RefreshAnonymousPosOrderPaymentRequest
): Promise<RefreshAnonymousPosOrderPaymentResponse> => {
  const { data } = await functions.httpsCallable("refreshAnonymousPosOrderPayment")(params);
  return data;
};
