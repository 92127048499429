import { FullGrowerRole } from "@rooted/shared";
import { Layout, Menu } from "antd";
import React from "react";
import { Link, Route, Routes, useLocation, useNavigate, Navigate } from "react-router-dom";
import { CollapsingSider } from "../../../components/Sider/CollapsingSider";
import { NotFoundPage, PageContent, WideLayout } from "../../../views/layouts";
import { Branding } from "./../../generic/Settings/Branding";
import { FAQs } from "./FAQs";

function formatCategory(category: string) {
  if (category === "faqs") return "FAQs";
  return category;
}

export const GrowerResources: React.FC<{
  role: FullGrowerRole;
}> = ({ role }) => {
  const category = useLocation().pathname.split("/")[2] || "start";

  return (
    <>
      <WideLayout>
        <CollapsingSider
          selectedKeys={[category]}
          smallDrawerLabel={{ selection: formatCategory(category), title: "Resources" }}
        >
          <Menu.Item key="branding">
            <Link to="">Branding</Link>
          </Menu.Item>
          <Menu.Item key="faqs">
            <Link to="faqs">FAQs</Link>
          </Menu.Item>
          <Menu.Item key="blog">
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href="https://www.rootedfarmers.com/blog/" target="_blank">
              Blog
            </a>
          </Menu.Item>
        </CollapsingSider>
        <PageContent>
          <Routes>
            <Route path="" element={<Navigate to="branding" />} />
            <Route path="branding" element={<Branding role={role} />} />
            <Route path="faqs" element={<FAQs />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Layout.Sider />
        </PageContent>
      </WideLayout>
    </>
  );
};
