import { useEffect, useRef } from "react";
import { db } from "../../services/firebase";

const updateRemoteRoleId = async ({
  userId,
  activeRoleId,
}: {
  userId: string;
  activeRoleId?: string;
}) => {
  await db
    .collection("users")
    .doc(userId)
    .update({ _currentRoleId: activeRoleId || " " });
};

const makeSessionStorageKey = (userId: string) => `activeRole:${userId}`;

export function getSessionStorageActiveRole(userId: string) {
  return window.sessionStorage.getItem(makeSessionStorageKey(userId));
}

export function setSessionStorageActiveRole({
  userId,
  activeRoleId,
}: {
  userId: string;
  activeRoleId: string;
}) {
  window.sessionStorage.setItem(makeSessionStorageKey(userId), activeRoleId);
}

export const useSyncActiveRole = ({
  userId,
  activeRoleId,
}: {
  userId?: string;
  activeRoleId?: string;
}) => {
  const lastSetActiveRole = useRef<string>();

  useEffect(() => {
    if (!userId) {
      return;
    }

    if (activeRoleId) {
      setSessionStorageActiveRole({ userId, activeRoleId });
    }

    const onFocus = async () => {
      try {
        await updateRemoteRoleId({ userId, activeRoleId });
        lastSetActiveRole.current = activeRoleId;
      } catch (e) {
        console.log("Failed to sync remote active role id");
        // Swallow error -- fine if this fails silently.
      }
    };
    window.addEventListener("focus", onFocus);
    return () => window.removeEventListener("focus", onFocus);
  }, [userId, activeRoleId]);
};
