import React, { useState } from "react";
import { scientificName } from "@rooted/shared";
import { Button, Checkbox, Input, Table, Typography } from "antd";
import { PageContent, WideLayout, LoadingPage } from "../../../views/layouts";
import { Link } from "react-router-dom";
import {
  sortByGenericFlowerPrototypes,
  useFlowerPrototypeFullTextResults,
} from "../../../services/flower-prototypes";
import { PageHeader } from "../../../components/PageHeader";
import { useDebouncedTrigger } from "../../../hooks/util/useDebouncedTrigger";

const tableColumns = [
  {
    title: "Name",
    width: "25%",
    ellipsis: true,
    dataIndex: "genus",
    key: "businessName",
    render: (x: any, r: any) => (
      <Typography.Text strong={true} disabled={!r.active}>
        {scientificName(r)}
      </Typography.Text>
    ),
    sorter: (a: any, b: any): any => b.genus.localeCompare(a.genus.toUpperCase()),
    sortDirections: ["descend"] as any, // SortOrder from antd
    defaultSortOrder: "descend" as any, // SortOrder from antd
  },
  {
    title: "Common Names",
    dataIndex: "commonNames",
    key: "commonNames",
    render: (x: string[], r: any) => (
      <Typography.Text disabled={!r.active}>
        <em>{x.join(", ")}</em>
      </Typography.Text>
    ),
    sorter: (a: any, b: any): any =>
      b.commonNames.join(",").toUpperCase().localeCompare(a.commonNames.join(",").toUpperCase()),
    sortDirections: ["descend"] as any, // SortOrder from antd
  },
  {
    title: (
      <>
        Stems /<br />
        Bunch
      </>
    ),
    ellipsis: true,
    width: 72,
    dataIndex: "stemsPerBunch",
    key: "stemsPerBunch",
    className: "text-center letter-spacing--1",
    render: (x: number, r: any) => <Typography.Text disabled={!r.active}>{x}</Typography.Text>,
    sorter: (a: any, b: any): any => b.stemsPerBunch - a.stemsPerBunch,
    sortDirections: ["descend"] as any, // SortOrder from antd
  },
  {
    title: "Generic",
    width: 80,
    dataIndex: "generic",
    key: "generic",
    className: "text-center letter-spacing--1",
    sorter: sortByGenericFlowerPrototypes,
    render: (generic: boolean) => <Checkbox checked={generic} disabled />,
  },
  {
    title: "Ethylene Sensitive",
    width: 81,
    dataIndex: "ethyleneSensitive",
    key: "ethyleneSensitive",
    className: "text-center letter-spacing--1",
    render: (ethyleneSensitive: boolean) => <Checkbox checked={ethyleneSensitive} disabled />,
  },
  {
    title: "Cooler Sensitive",
    width: 80,
    dataIndex: "coolerSensitive",
    key: "coolerSensitive",
    className: "text-center letter-spacing--1",
    render: (coolerSensitive: boolean) => <Checkbox checked={coolerSensitive} disabled />,
  },
  {
    title: "Invasive",
    width: 74,
    dataIndex: "invasive",
    key: "invasive",
    className: "text-center letter-spacing--1",
    render: (invasive: boolean) => <Checkbox checked={invasive} disabled />,
  },
  {
    title: "",
    width: 39,
    key: "action",
    className: "col-action pad-left-0",
    render: (x: any, record: any) => <Link to={record._id}>Edit</Link>,
  },
];

export const FlowersOverview: React.FC = () => {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebouncedTrigger(search);

  const [data, dataLoading, dataError] = useFlowerPrototypeFullTextResults(debouncedSearch, {
    // silly high limit to intend for "all". Behind the scenes, the hook is fetching all the
    // prototypes anyways, so this ensures that we can render all of them in the table
    limit: 100000,
    requireActive: false,
  });

  return (
    <WideLayout>
      <PageContent>
        <PageHeader
          title="Flower Varieties"
          extra={
            <Link to="create">
              <Button type="primary">Add Flowers</Button>
            </Link>
          }
        />
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search by Common or Latin Name"
          style={{ marginBottom: 16 }}
        />

        {dataLoading ? (
          <LoadingPage marginTop={"10vh"} />
        ) : (
          <Table
            dataSource={data}
            columns={tableColumns}
            rowKey="_id"
            style={{ textAlign: "center" }}
          />
        )}
      </PageContent>
    </WideLayout>
  );
};
