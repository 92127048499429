import Select, { SelectValue } from "antd/lib/select";
import React, { useState, useMemo } from "react";
import { useDebouncedTrigger } from "../../hooks/util/useDebouncedTrigger";
import { useCustomerResults } from "../../services/sellers/customers";

export const CustomerSelect: React.FC<{
  value?: SelectValue;
  onChange?: (value: SelectValue) => void;
}> = ({ value, onChange }) => {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebouncedTrigger(search);

  const [customers, customersLoading] = useCustomerResults(debouncedSearch);

  const options = useMemo(() => {
    return customers?.map((customer) => ({
      label: (
        <>
          {customer.bio.displayName}
          {customer.type === "wholesale-buyer" && (
            <i style={{ float: "right", marginRight: 2 }}>
              {" "}
              {customer.bio.location.streetAddress.city},{" "}
              {customer.bio.location.streetAddress.state}
            </i>
          )}
        </>
      ),
      value: customer._id,
    }));
  }, [customers]);

  return (
    <Select
      loading={customersLoading || search !== debouncedSearch}
      showSearch
      showArrow={false}
      filterOption={false}
      value={value}
      onChange={(value) => {
        setSearch("");
        onChange?.(value);
      }}
      onSearch={setSearch}
      notFoundContent={null}
      optionLabelProp="label"
      placeholder="Search by name"
      options={options}
      allowClear
    />
  );
};
