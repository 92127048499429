import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { WideLayout, PageContent, NotFoundPage, LoadingPage } from "../../layouts";
import { FullRetailBuyerRole, WithId } from "@rooted/shared";
import { Menu } from "antd";
import { OrganizationsPane } from "./OrganizationsPane";
import { ApplicationsPane } from "./ApplicationsPane";
import { db, useDocumentDataChecked } from "../../../services/firebase";
import { AddressesPane } from "../Settings/AddressesPane";
import { CreditCardsPane } from "../Settings/CreditsCardsPane";
import { CollapsingSider } from "../../../components/Sider/CollapsingSider";
import { useRooted } from "../../../RootedContext";

export const AccountSettings: React.FC = () => {
  const category = useLocation().pathname.split("/")[2] || "profile";

  const { account } = useRooted();
  if (account.status !== "signed-in-complete")
    throw new Error(`Unexpected Account Status: ${account.status}`);

  // FullRetailBuyerRole will only be defined if the user has created a retail buyer profile
  const [FullRetailBuyerRole, FullRetailBuyerRoleLoading] = useDocumentDataChecked<
    WithId<FullRetailBuyerRole>
  >(db.collection("roles").doc(`${account.user._id}-retail`), { idField: "_id" });

  if (FullRetailBuyerRoleLoading) return <LoadingPage />;
  return (
    <>
      <WideLayout>
        <CollapsingSider selectedKeys={[category]}>
          <Menu.Item key="organizations">
            <Link to="organizations">Organizations</Link>
          </Menu.Item>
          <Menu.Item key="applications">
            <Link to="applications">Applications</Link>
          </Menu.Item>
        </CollapsingSider>
        <PageContent>
          <Routes>
            {/* TODO: overview page */}
            <Route path="" element={<OrganizationsPane account={account} />} />
            <Route path="organizations/*" element={<OrganizationsPane account={account} />} />
            <Route path="applications/*" element={<ApplicationsPane account={account} />} />
            {
              /* Insert retail buyer settings in account settings */
              FullRetailBuyerRole && (
                <>
                  <Route
                    path="addresses/*"
                    element={<AddressesPane role={FullRetailBuyerRole} />}
                  />
                  <Route
                    path="credit-cards/*"
                    element={<CreditCardsPane role={FullRetailBuyerRole} />}
                  />
                </>
              )
            }
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </PageContent>
      </WideLayout>
    </>
  );
};
