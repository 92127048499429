import React from "react";
import {
  EnterpriseProfile,
  firestoreToISO,
  formatCityState,
  StreetAddress,
  WithId,
} from "@rooted/shared";
import { Link } from "react-router-dom";

import { CopyCode } from "../../../components/Misc/CopyCode";
import { sortByFirestoreTimestamp } from "../../../utils/sortByTimestamp";
import { sortByLocaleCompare } from "../../../utils/sortByLocaleCompare";
import { ColumnType } from "antd/lib/table";

const formatSortAddress = ({
  bio: {
    location: { streetAddress },
  },
}: EnterpriseProfile) => ({
  address: `${streetAddress?.state} ${streetAddress.city}`,
});

const sortByStreetAddress = (a: EnterpriseProfile, b: EnterpriseProfile) =>
  sortByLocaleCompare("address")(formatSortAddress(a), formatSortAddress(b));

type EnterpriseProfileColumn = ColumnType<WithId<EnterpriseProfile>>;

const NameIdColumn: EnterpriseProfileColumn = {
  title: "Name",
  key: "businessName",
  render: (profile: WithId<EnterpriseProfile>) => (
    <>
      <Link to={profile._id}>{profile.bio?.displayName ?? "[Undefined Name]"}</Link>
      <CopyCode>{profile._id}</CopyCode>
    </>
  ),
  sorter: (a: EnterpriseProfile, b: EnterpriseProfile) =>
    sortByLocaleCompare("displayName")(a.bio, b.bio),
  defaultSortOrder: "ascend" as const,
};

const ContactColumn: EnterpriseProfileColumn = {
  title: "Contact",
  key: "contact",
  render: ({ bio: { email, phoneNumber } }: EnterpriseProfile) => (
    <>
      {email} <br />
      {phoneNumber}
    </>
  ),
};

const LocationColumn: EnterpriseProfileColumn = {
  title: "Location",
  dataIndex: ["bio", "location", "streetAddress"],
  key: "location",
  render: (address: StreetAddress) => (
    <>{address ? formatCityState(address) : "[Undefined Address]"}</>
  ),
  sorter: sortByStreetAddress,
};

const CreatedColumn: EnterpriseProfileColumn = {
  title: "Created",
  key: "created",
  render: (o: any, r: WithId<EnterpriseProfile>) =>
    r._created && new Date(firestoreToISO(r._created)).toLocaleString().split(",")[0],
  sorter: sortByFirestoreTimestamp("_created"),
};

export const EnterpriseProfileColumns: EnterpriseProfileColumn[] = [
  NameIdColumn,
  ContactColumn,
  LocationColumn,
  CreatedColumn,
];
