import { FullWholesaleBuyerRole } from "@rooted/shared";
import { Layout, Menu } from "antd";
import React from "react";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { CollapsingSider } from "../../../components/Sider/CollapsingSider";
import { InvitePane } from "../../generic/Invite";
import { NotFoundPage, PageContent, WideLayout } from "../../layouts";
import { ConnectionsPane } from "../../connections/ConnectionsPane";
import { FindSellersPane } from "./FindSellersPane";

export const MySellers: React.FC<{
  role: FullWholesaleBuyerRole;
}> = ({ role }) => {
  const category = useLocation().pathname.split("/")[2] || "sellers";

  return (
    <>
      <WideLayout>
        <CollapsingSider
          selectedKeys={[category]}
          smallDrawerLabel={{
            title: "Connections",
            selection: category,
          }}
        >
          {/* Enterprise-specific routes -- these apply to everyone but retail buyers */}
          <Menu.Item key="sellers">
            <Link to="">Sellers</Link>
          </Menu.Item>
          {!role.privateProfile.profileSearchDisabled && [
            <Menu.Item key="find-sellers">
              <Link to="find-sellers">Find Nearby Sellers</Link>
            </Menu.Item>,
            <Menu.Item key="invite-sellers">
              <Link to="invite-sellers">Invite Sellers</Link>
            </Menu.Item>,
          ]}
        </CollapsingSider>
        <PageContent>
          <Routes>
            {/* Enterprise-specific routes -- these apply to everyone but retail buyers */}

            <Route path="" element={<ConnectionsPane />} />
            {!role.privateProfile.profileSearchDisabled && [
              <Route
                path="find-sellers/*"
                element={<FindSellersPane role={role} />}
                key="find-sellers"
              />,
              <Route
                path="invite-sellers/*"
                element={<InvitePane role={role} inviteType={"invite-sellers"} />}
                key="invite-sellers"
              />,
            ]}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Layout.Sider />
        </PageContent>
      </WideLayout>
    </>
  );
};
