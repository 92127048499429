import { Card, Typography, Button } from "antd";
import React from "react";

export const ZoomAlertCard: React.FC<{ style?: React.CSSProperties }> = ({ style = {} }) => {
  return (
    <Card style={{ textAlign: "center", ...style }}>
      <Typography.Paragraph>
        <b>Have Questions? Need Hands-On Help?</b>
      </Typography.Paragraph>
      <Typography.Paragraph>
        Join us on our weekly Q&A sessions over Zoom on <b>Tuesdays at 12-12:30pm</b> EST.
        <br />
        <i>We can't wait to help you take back your free time!</i>
      </Typography.Paragraph>
      <Button
        type="primary"
        href="https://us02web.zoom.us/j/89360653366?pwd=eTZ2WVJNZElwYWtEWmlPOVZZcU9PZz09"
        target="_blank"
      >
        Join Zoom
      </Button>
    </Card>
  );
};
