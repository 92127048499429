import { firestoreToMoment, Listing } from "@rooted/shared";
import { Typography } from "antd";
import React, { useMemo } from "react";

const makeIntervalString = ([start, end]: [moment.Moment | null, moment.Moment | null]) => {
  if (!end) {
    if (!start || start?.isBefore()) return "Not Set";
    else return `${start!.format("MMM D")} onwards`;
  } else if (!start) {
    if (end.isBefore()) return `Ended ${end.format("MMM D")}`;
    else return `Until ${end.format("MMM D")}`;
  } else {
    if (start.isBefore() && end.isAfter()) return `Until ${end.format("MMM D")}`;
    else if (start.isAfter() && end.isAfter())
      return `${start.format("MMM D")} - ${end.format("MMM D")}`;
    else return `Ended ${end.format("MMM D")}`;
  }
};

const listingToIntervalString = (listing: Listing) =>
  makeIntervalString([
    listing.availability.startDate ? firestoreToMoment(listing.availability.startDate) : null,
    listing.availability.endDate ? firestoreToMoment(listing.availability.endDate) : null,
  ]);

export const ListingInterval: React.FC<{
  listing: Listing;
}> = ({ listing }) => {
  const [copy, isAvailable] = useMemo(() => {
    const _copy = listingToIntervalString(listing);
    const _isAvailable = !(_copy.includes("Not Set") || _copy.includes("Ended"));
    return [_copy, _isAvailable];
  }, [listing]);
  return <Typography.Text type={isAvailable ? undefined : "danger"}>{copy}</Typography.Text>;
};

export const AvailabilityColumn = {
  title: "Availability",
  key: "availability",
  width: 145,
  render: (_: any, listing: Listing) => <ListingInterval listing={listing} />,
  // TODO: better, smarter sort on this
  sorter: (a: Listing, b: Listing) => {
    return listingToIntervalString(a).localeCompare(listingToIntervalString(b));
  },
};
