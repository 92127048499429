import React from "react";
import { FullEnterpriseRole, MediaReference, WithId } from "@rooted/shared";
import { Card, Col, Image, Row, Spin, Dropdown, Menu, notification } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { PageHeader } from "../../../components/PageHeader";
import { makeCustomUploadRequest, useAllMedia } from "../../../services/sellers/media-library";
import { LoadingIndicator } from "../../../views/layouts/LoadingPage";
import { BulbOutlined, UploadOutlined } from "@ant-design/icons";

import { db } from "../../../services/firebase";

export const MediaPane: React.FC<{ role: FullEnterpriseRole }> = ({ role }) => {
  const [media, mediaLoading, mediaError] = useAllMedia(role);

  const makeImageMenu = (x: WithId<MediaReference>) => (
    <Menu>
      <Menu.Item
        danger
        key="1"
        onClick={() => db.collection("mediaReferences").doc(x._id).delete()}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <PageHeader title={"Image Library"} />

      <Dragger
        multiple
        customRequest={makeCustomUploadRequest(role)}
        showUploadList={false}
        className="ant-upload-height-auto"
        onChange={({ file }) => {
          if (file.status === "error")
            notification.error({
              message: `Failed to upload ${file.name}`,
              description: file.error.message,
            });
        }}
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined style={{ fontSize: 40 }} />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload.</p>
        <p className="ant-upload-hint">
          Files in your image library can be added to any product.
          <br />
          Any photos you have uploaded will appear below.
          <br />
          Allowed formats: JPEG and PNG
        </p>
      </Dragger>

      <div style={{ textAlign: "center", margin: "30px 0 20px" }}>
        <BulbOutlined style={{ fontSize: 20 }} /> <b>ProTip!</b> Photos will display better if the
        subject matter is both vertically and horizontally centered.
      </div>

      <Row>
        {media &&
          media.map((m) => (
            <Col key={m._id} xs={{ span: 8 }} sm={{ span: 6 }}>
              <div style={{ padding: 8 }}>
                <Dropdown overlay={makeImageMenu(m)} trigger={["contextMenu"]}>
                  <Card
                    hoverable
                    bordered={false}
                    cover={
                      m.storageUrl ? (
                        <Image alt={m.fileName} src={m.storageUrl} style={{ display: "block" }} />
                      ) : (
                        <Spin indicator={LoadingIndicator} tip="Uploading..." />
                      )
                    }
                    bodyStyle={{ padding: 10, fontSize: "0.7em", textAlign: "center" }}
                  >
                    <Card.Meta description={m.fileName} />
                  </Card>
                </Dropdown>
              </div>
            </Col>
          ))}
      </Row>
    </>
  );
};
