import { FullGrowerRole } from "@rooted/shared";
import { Card, Button, Typography, Col, Row } from "antd";
import React from "react";
import { PageHeader } from "../../../components/PageHeader";
import { MailOutlined } from "@ant-design/icons";

export const GrowerOffersPane: React.FC<{
  role: FullGrowerRole;
}> = ({ role }) => {
  return (
    <>
      <PageHeader title={"Special Offers"} />

      <Typography.Paragraph>
        <Row gutter={16} style={{ marginBottom: 16 }}>
          <Col xs={24} sm={12}>
            <Card
              title={
                <Typography.Title level={5}>
                  Inventory Setup Package
                  <br />
                  $99.00
                </Typography.Title>
              }
              style={{ marginBottom: 16 }}
              headStyle={{ textAlign: "center" }}
              bodyStyle={{ minHeight: "200px" }}
              className="no-list"
              actions={[
                <a
                  key="mailto-reachout-inventory-setup"
                  href="mailto:reachout@rootedfarmers.com?subject=Inventory Setup"
                >
                  <Button type="primary" icon={<MailOutlined />} size={"large"}>
                    Email Us
                  </Button>
                </a>,
              ]}
            >
              <p>
                <b>Let the Rooted team set up your shop for you!</b>
              </p>
              <p>
                Send us an email at{" "}
                <a href="mailto:reachout@rootedfarmers.com?subject=Inventory Setup">
                  reachout@rootedfarmers.com
                </a>{" "}
                with the subject line "Inventory Setup!" and we'll take care of the rest.
              </p>
              <p>Returning member? Don't forget your special offer code!</p>
            </Card>
          </Col>
        </Row>
      </Typography.Paragraph>
    </>
  );
};
