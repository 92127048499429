import {
  AdHocListing,
  BuyerRole,
  Cart,
  FulfillmentOption,
  Listing,
  PrivateProfile,
  ProvisionalCart,
  SavedLocation,
  UpdateCartOrderNotesPayload,
  UpdateInCartPayload,
  CurrentCart,
  WithId,
  assertIsBuyer,
  assertRoleExists,
} from "@rooted/shared";
import { useEffect, useState } from "react";
import { useRooted } from "../../RootedContext";
import { functions, db, useCollectionDataChecked } from "../firebase";

/** Listen to the state of a buyer's active or provisional cart (or undefined if it doesn't exist).
 *
 */
export const useCurrentCart: () => [
  WithId<CurrentCart> | undefined,
  boolean,
  Error | undefined
] = () => {
  const { activeRole } = useRooted();
  assertRoleExists(activeRole);

  const [carts, _loading, error] = useCollectionDataChecked<WithId<CurrentCart>>(
    db
      .collection("carts")
      .where("profileId", "==", activeRole.profileId)
      .where("status", "in", ["active", "provisional"])
      .limit(1),
    {
      idField: "_id",
    }
  );

  // TODO:
  // make this cleaner, probably just by making the active cart
  // have a single doc listener ("carts/${profileId}") instead of a query listener
  // this is to fix the `useCollectionDataChecked` not updating when a doc no longer
  // meets the critera of the query (moves from active -> purchased).
  // See: https://github.com/rooted-tech/rooted-app/issues/596
  const [cart, setCart] = useState<WithId<CurrentCart>>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setCart(carts?.[0] || undefined);
    // This solves `_loading` changing a render too soon,
    // because `cart` changes one render after `carts`.
    // This is problematic on the `orders` page,
    // which routes based on first non-loading value.
    setLoading(_loading);
  }, [carts, _loading]);

  useEffect(() => {
    if (!cart) return;
    let isMounted = true;
    const removeListener = db
      .collection("carts")
      .doc(cart._id)
      .onSnapshot((snap) => {
        if (!isMounted) return;
        const data = snap.data() as Cart;
        if (data.status === "expired" || data.status === "purchased") {
          setCart(undefined);
        }
      });
    return () => {
      isMounted = false;
      removeListener();
    };
  }, [cart]);

  return [cart, loading, error];
};

export const updateInCart = async (
  role: BuyerRole,
  fulfillment: FulfillmentOption,
  listing: WithId<Listing | AdHocListing>,
  quantity: number
) => {
  const req: UpdateInCartPayload = {
    fulfillment,
    listingId: listing._id,
    profileId: role.profileId,
    quantity,
  };

  const updateInCart = functions.httpsCallable("updateInCart");
  await updateInCart(req);
};

export const updateCartOrderNotes = async (
  role: BuyerRole,
  fulfillment: FulfillmentOption,
  notes: string | undefined
) => {
  const req: UpdateCartOrderNotesPayload = {
    fulfillment,
    profileId: role.profileId,
    notes,
  };
  const _updateCartOrderNotes = functions.httpsCallable("updateCartOrderNotes");
  await _updateCartOrderNotes(req);
};

export const setPaymentMethodId = (cart: WithId<Cart>, paymentMethodId: string) => {
  return db.collection("carts").doc(cart._id).update({ paymentMethodId });
};

export const placeOrder = (cart: WithId<Cart>) => {
  return functions.httpsCallable("placeOrder")(cart._id);
};

/**
 * Sets the address on the current cart or creates a new provisional cart if necessary.
 * Errors if the current cart is active (address can't be changed)
 * @param role
 * @param address
 */
export const setAddress = async (role: BuyerRole, address: WithId<SavedLocation>) => {
  const activeCart = await db
    .collection("carts")
    .where("profileId", "==", role.profileId)
    .where("status", "==", "active")
    .orderBy("firstItemAddedAt", "desc")
    .limit(1)
    .get();

  if (activeCart.docs.length > 0)
    throw new Error("Your cart is currently active. The address cannot be changed.");

  const {
    docs: [provisionalCart],
  } = await db
    .collection("carts")
    .where("profileId", "==", role.profileId)
    .where("status", "==", "provisional")
    .limit(1)
    .get();

  const privateProfileRef = db.collection("privateProfiles").doc(role.profileId);

  // Update existing provisional cart
  if (provisionalCart && provisionalCart.exists) {
    const cartUpdates: Partial<ProvisionalCart> = { address };
    const profileUpdates: Partial<PrivateProfile> = { activeCartId: provisionalCart.id };

    console.log("Updating existing cart");

    // Ensure that any provisional cart we are using is correctly linked to by the role.
    await db.runTransaction(async (t) => {
      t.set(provisionalCart.ref, cartUpdates, { merge: true });
      t.set(privateProfileRef, profileUpdates, { merge: true });
    });
  }
  // Create new provisional cart
  else {
    console.log("Creating new cart");

    const newCart: ProvisionalCart = {
      profileId: role.profileId,
      fulfillmentGroups: [],
      status: "provisional",
      address,
      isRetail: role.type === "retail-buyer",
    };

    const newCartRef = db.collection("carts").doc();
    const profileUpdates: Partial<PrivateProfile> = { activeCartId: newCartRef.id };

    // Ensure that any cart we are using is correctly linked to by the role.
    await db.runTransaction(async (t) => {
      t.set(newCartRef, newCart);
      t.set(privateProfileRef, profileUpdates, { merge: true });
    });
  }
};
