import React, { useCallback, useState } from "react";
import {
  WithId,
  MarkOrderResolvedRequest,
  ChildOrder,
  TokenizedCreditCard,
  stringifyCard,
  FulfilledChildOrder,
  GetOrderPaymentIntentUrlRequest,
  GetOrderPaymentIntentUrlResponse,
} from "@rooted/shared";
import {
  Breadcrumb,
  Card,
  Alert,
  Typography,
  Col,
  Collapse,
  notification,
  Button,
  Select,
} from "antd";
import { useParams } from "react-router-dom";
import { ChildOrderItems } from "../../../components/ChildOrderItems";
import { PageHeader } from "../../../components/PageHeader";
import { CardSkeleton } from "../../../components/skeletons";
import {
  functions,
  db,
  snapshotToIdDoc,
  useCollectionDataOnceChecked,
  useDocumentDataChecked,
} from "../../../services/firebase";
import { NotFoundPage, WideLayout } from "../../../views/layouts";
import { OrderSummary } from "../../orders/OrderDetails/OrderSummary";
import { CopyCode } from "../../../components/Misc/CopyCode";
import { useRouterBreadcrumb } from "../../../components/Misc/useRouterBreadcrumb";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const resolveOrderApi = (params: MarkOrderResolvedRequest) =>
  functions.httpsCallable("markOrderResolved")(params);

export const AdminOrderDetails = () => {
  const { orderId } = useParams();

  const [order, orderLoading] = useDocumentDataChecked<WithId<ChildOrder>>(
    db.collection("childOrders").doc(orderId),
    { idField: "_id" }
  );

  const title = order ? `Order #${order.number}` : "...";
  const breadcrumb = useRouterBreadcrumb();

  if (orderLoading) return <CardSkeleton />;
  if (!order) return <NotFoundPage />;

  return (
    <WideLayout>
      <Breadcrumb {...breadcrumb} style={{ marginBottom: 16, paddingTop: 12 }} />

      <AdminOrderError order={order} />

      {/* Admin only data + actions */}
      <Card style={{ marginBottom: 12 }}>
        <Col>
          <table>
            <tbody>
              <tr>
                <th>Status:</th>
                <td style={{ textTransform: "uppercase" }}>
                  {order.status}
                  {order.status === "fulfilled" && <ViewStripePaymentIntent order={order} />}
                </td>
              </tr>
              <tr>
                <th>Order Id:</th>
                <td>
                  <CopyCode>{order._id}</CopyCode>
                </td>
              </tr>
              <tr>
                <th>Seller Id:</th>
                <td>
                  <CopyCode inline>{order.sellerId}</CopyCode> ({order.sellerDisplayName})
                </td>
              </tr>
              <tr>
                <th>Buyer Id:</th>
                <td>
                  <CopyCode inline>{order.buyerId}</CopyCode> ({order.buyerDisplayName})
                </td>
              </tr>
              {order.status !== "guest-checkout" && (
                <tr>
                  <th>Card:</th>
                  <td>
                    <PaymentMethodSelect order={order} />
                    <CopyCode inline>{order.paymentMethodId}</CopyCode>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Collapse style={{ marginTop: 8 }}>
            <Collapse.Panel header="Full Document JSON" key="1">
              <pre
                style={{
                  background: "rgba(150, 150, 150, 0.1)",
                  border: "1px solid rgba(100, 100, 100, 0.2)",
                }}
              >
                {JSON.stringify(order, null, 2)}
              </pre>
            </Collapse.Panel>
          </Collapse>
        </Col>

        {/* TODO: resolve order issue */}
      </Card>

      {/* Body of order details */}
      <Card>
        {/* Overview of buyer / seller / fulfillment */}
        <PageHeader title={title}>
          <div style={{ display: "flex" }}>
            <OrderSummary sellerId={order.sellerId} order={order} />
          </div>
        </PageHeader>

        {/* Order Items */}
        <ChildOrderItems
          order={order}
          // Mock a buyer profile. This gives us a read-only view of the child order.
          role={{
            profile: { type: "wholesale-buyer" } as any,
            profileId: "fake-profile",
            userId: "fake-user",
            _id: "fake-id",
            type: "wholesale-buyer",
            permissionLevel: "user",
            privateProfile: { type: "wholesale-buyer" } as any,
          }}
          editable={false}
        />
      </Card>
    </WideLayout>
  );
};

const PaymentMethodSelect: React.FC<{
  order: WithId<ChildOrder>;
}> = ({ order }) => {
  const [optimisticPaymentMethodId, setOptimisticPaymentMethodId] = useState<string>();
  const [paymentMethods = []] = useCollectionDataOnceChecked<WithId<TokenizedCreditCard>>(
    db.collection("creditCards").where("ownerId", "==", order.buyerId),
    { idField: "_id" }
  );

  const onChange = useCallback(
    async (newPaymentMethodId: string) => {
      setOptimisticPaymentMethodId(newPaymentMethodId);

      const orderRef = db.collection("childOrders").doc(order._id);
      try {
        await db.runTransaction(async (t) => {
          const refreshedOrder = snapshotToIdDoc<ChildOrder>(await t.get(orderRef));
          if (!refreshedOrder || refreshedOrder.status !== "active") {
            throw new Error("Order is no longer active");
          }

          await t.update(orderRef, { paymentMethodId: newPaymentMethodId });
        });

        notification.success({ message: "Payment method updated" });
      } catch (error) {
        setOptimisticPaymentMethodId(undefined);
        notification.error({
          message: "Failed to update payment method",
          description: error.message,
        });
      }
    },
    [order]
  );

  const options = paymentMethods.map((pm) => ({
    value: pm._id,
    label: stringifyCard(pm, { includeExpiration: true }),
  }));

  return (
    <Select
      disabled={order.status !== "active"}
      loading={!!optimisticPaymentMethodId && optimisticPaymentMethodId !== order.paymentMethodId}
      options={options}
      value={optimisticPaymentMethodId ?? order.paymentMethodId}
      onChange={onChange}
    />
  );
};

const ViewStripePaymentIntent: React.FC<{ order: WithId<FulfilledChildOrder> }> = ({ order }) => {
  const [loading, setLoading] = useState(false);

  const viewPaymentIntent = useCallback(async () => {
    if (loading) return;
    setLoading(true);

    try {
      const { stripePaymentIntentUrl } = await getOrderPaymentIntentUrl({ orderId: order._id });
      window.open(stripePaymentIntentUrl, "_blank")?.focus();
    } catch (error) {
      notification.error({
        message: "Failed to get payment intent url",
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  }, [order._id, loading]);

  return (
    <Button style={{ marginLeft: 10 }} type="link" onClick={viewPaymentIntent} loading={loading}>
      View payment on Stripe
      <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: 8 }} />
    </Button>
  );
};

const getOrderPaymentIntentUrl = async (
  params: GetOrderPaymentIntentUrlRequest
): Promise<GetOrderPaymentIntentUrlResponse> => {
  const { data } = await functions.httpsCallable("getOrderPaymentIntentUrl")(params);
  return data;
};

const AdminOrderError: React.FC<{ order: WithId<ChildOrder> }> = ({ order }) => {
  const [loading, setLoading] = useState(false);
  const resolveOrder = useCallback(async (params: MarkOrderResolvedRequest) => {
    setLoading(true);
    try {
      await resolveOrderApi(params);
    } catch (error) {
      notification.error({
        message: "Failed to mark order as resolved",
        description: error?.message,
      });
    }
    setLoading(false);
  }, []);

  if (!order.processingError) return null;

  // Credit card issue that has been fixed
  if (order.processingError.isResolved)
    return (
      <Alert
        style={{ marginBottom: 16 }}
        showIcon
        type="success"
        message="This credit card issue has been resolved."
      />
    );

  // Credit card issue that needs to be fixed
  return (
    <Alert
      style={{ marginBottom: 16 }}
      showIcon
      type={"error"}
      message={"Error processing order:"}
      description={
        <Typography.Text code>
          {order.processingError.cardError?.message || "Error not found"}
        </Typography.Text>
      }
      action={
        <Button onClick={() => resolveOrder({ orderId: order._id })} loading={loading}>
          Mark as Resolved
        </Button>
      }
    />
  );
};
