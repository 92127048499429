import { WholesaleBuyerApplicationForm, WithId } from "@rooted/shared";
import { Button, Checkbox, Divider, Form, Input, InputNumber, Upload } from "antd";
import React from "react";
import { UploadOutlined, CheckOutlined } from "@ant-design/icons";
import { submitApplication } from "../../../services/applications";
import { useNavigate } from "react-router-dom";
import { FirestoreDocForm } from "../../../components/FormControls/FirestoreDocForm";
import { HowDidYouHear } from "../../../components/FormControls/HowDidYouHear";
import { db } from "../../../services/firebase";
import type { UploadProps } from "antd/es/upload/interface";
import firebase from "firebase/app";

export const WholesaleBuyerDetailsForm: React.FC<{
  disabled: boolean;
  application: WithId<WholesaleBuyerApplicationForm>;
  adminView?: boolean;
}> = ({ application, disabled = false, adminView }) => {
  const navigate = useNavigate();
  return (
    <FirestoreDocForm
      layout="vertical"
      buttonText="Submit"
      onStatusChange={(x) => {
        if (x === "submitted") {
          navigate(`/onboarding/applications/${application._id}/review`);
        }
      }}
      onFinish={async (values: any) => {
        await submitApplication({ applicationId: application._id });
      }}
      rootFieldPath="details"
      data={application.details}
      dbRef={db.collection("applications").doc(application._id)}
    >
      <WholesaleBuyerDetailsFormItems
        disabled={disabled}
        application={application}
        adminView={adminView}
      />
    </FirestoreDocForm>
  );
};

export const WholesaleBuyerDetailsFormItems: React.FC<{
  disabled?: boolean;
  application: WithId<WholesaleBuyerApplicationForm>;
  adminView?: boolean;
}> = ({ disabled = false, adminView, application }) => (
  <>
    <ol style={{ paddingLeft: 25 }}>
      <li>
        <Form.Item
          label="How would you best describe your business?"
          extra="Please check all that apply."
        >
          <Form.Item
            name={["businessDescription", "floralDesigner"]}
            initialValue={false}
            noStyle
            valuePropName="checked"
          >
            <Checkbox disabled={disabled}>Floral Designer</Checkbox>
          </Form.Item>
          <Form.Item
            name={["businessDescription", "eventPlanner"]}
            initialValue={false}
            noStyle
            valuePropName="checked"
          >
            <Checkbox disabled={disabled}>Event Planner</Checkbox>
          </Form.Item>
          <Form.Item
            name={["businessDescription", "retailFlorist"]}
            initialValue={false}
            noStyle
            valuePropName="checked"
          >
            <Checkbox disabled={disabled}>Retail Florist</Checkbox>
          </Form.Item>
          <Form.Item
            name={["businessDescription", "groceryFlorist"]}
            initialValue={false}
            noStyle
            valuePropName="checked"
          >
            <Checkbox disabled={disabled}>Grocery Florist</Checkbox>
          </Form.Item>
          <Form.Item
            name={["businessDescription", "distributor"]}
            initialValue={false}
            noStyle
            valuePropName="checked"
          >
            <Checkbox disabled={disabled}>Distributor</Checkbox>
          </Form.Item>
          <Form.Item
            name={["businessDescription", "other"]}
            initialValue={false}
            noStyle
            valuePropName="checked"
          >
            <Checkbox disabled={disabled}>Other</Checkbox>
          </Form.Item>
        </Form.Item>
      </li>
      <li>
        <Form.Item
          name="aboutBusiness"
          label="About you / Your business (private to Rooted Admin)"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <Input.TextArea
            disabled={disabled}
            rows={4}
            placeholder="Hints: How did you get started? Why are you seeking local product? What sets you apart? Do you have any awards/certifications/press that you're excited to share?"
          />
        </Form.Item>
      </li>
      <li>
        <Form.Item
          name="yearsInBusiness"
          label="Years in business"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <InputNumber disabled={disabled} min={0} max={500} />
        </Form.Item>
      </li>
      <li>
        <Form.Item label="Are you a member of any of our partner organizations?">
          <Form.Item name="slowFlowersMember" noStyle initialValue={false} valuePropName="checked">
            <Checkbox disabled={disabled}>Slow Flowers</Checkbox>
          </Form.Item>
          <Form.Item name="ASCFGMember" noStyle initialValue={false} valuePropName="checked">
            <Checkbox disabled={disabled}>ASCFG</Checkbox>
          </Form.Item>
        </Form.Item>
      </li>
      <li>
        <Form.Item
          name="percentLocal"
          label="About what % of your floral purchases are currently local?"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <InputNumber min={0} max={100} disabled={disabled} />
        </Form.Item>

        <Form.Item
          name="whyLoveLocal"
          label="Why do you love local?"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <Input.TextArea
            rows={2}
            placeholder="Tell us all the reasons you love local flowers!"
            disabled={disabled}
          />
        </Form.Item>
      </li>
      <li>
        <HowDidYouHear disabled={disabled} adminView={adminView} />
      </li>
    </ol>

    <br />
    <Divider>Legal</Divider>
    <Form.Item
      name="resaleCertificate"
      label="Resale Certificate/Exemption Certificate"
      extra="Your Resale/Exemption Certificate is not required at this time, but please keep in mind that if you do not provide it, your application approval may be delayed, and we will be required to collect sales tax on all of your transactions."
      initialValue={""}
      // Required, not required mark. This hack allows us to not show the "optional" text, so as to encourage the field
      // TODO: Fix this hack, this is misleading code-wise.
      required
    >
      <FirebaseStorageUpload path={`/application-docs/${application._id}`} />
    </Form.Item>
    <Form.Item
      name="agreement"
      label={
        <>
          Please confirm you have read the&nbsp;
          <a href="https://www.rootedfarmers.com/terms" target="_blank" rel="noreferrer">
            Terms of Service.
          </a>
        </>
      }
      valuePropName="checked"
      rules={[
        {
          required: true,
          message: "Please accept the Terms of Service agreement",
          validator: (_, value) =>
            value
              ? Promise.resolve()
              : Promise.reject("Please accept the Terms of Service agreement"),
        },
      ]}
    >
      <Checkbox disabled={disabled}>I have read and agree to the Terms of Service</Checkbox>
    </Form.Item>
  </>
);

export const FirebaseStorageUpload: React.FC<{
  value?: boolean | undefined;
  onChange?: (x: boolean | undefined) => void;
  path: string;
}> = ({ value, onChange, path }) => {
  return (
    <Upload
      customRequest={makeCertificateUploadRequest(path)}
      name="certificate"
      multiple={false}
      onChange={(e) => {
        if (e.file.status === "success" || e.file.status === "done") onChange?.(true);
        else onChange?.(undefined);
      }}
    >
      {value ? (
        <Button icon={<CheckOutlined />}>Uploaded</Button>
      ) : (
        <Button icon={<UploadOutlined />}>Click to upload</Button>
      )}
    </Upload>
  );
};

type CustomRequest = Exclude<NonNullable<UploadProps["customRequest"]>, undefined>;
export const makeCertificateUploadRequest = (path: string): CustomRequest => async ({
  file,
  onSuccess,
  onError,
  onProgress,
}) => {
  const randomId = Math.random().toString(36).substr(2, 5);

  const uploadTask = firebase.storage().ref().child(path).child(randomId).put(file);

  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("Upload is " + progress + "% done");

      onProgress?.({ percent: progress } as any);
    },
    (error) => onError?.(error),
    () => {
      // TODO: Allow users to access the storage of their application docs,
      // and pass a download url of the image to onSuccess so they can
      // see a preview
      onSuccess?.({}, new XMLHttpRequest());
    }
  );
};
