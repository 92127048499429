import { EditOutlined } from "@ant-design/icons";
import { ChangeOrderHandlingFeePercentRequest } from "@rooted/shared";
import { Button, InputNumber, notification, Popover, Space, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { logError } from "../../sentry";
import { functions } from "../../services/firebase";

const changeOrderHandlingFeePercent = async (params: ChangeOrderHandlingFeePercentRequest) => {
  const { data } = await functions.httpsCallable("changeOrderHandlingFeePercent")(params);
  return data;
};

export const HandlingFeePercentWidget: React.FC<{
  orderId: string;
  handlingFeePercent: number;
  originalHandlingFeePercent?: number;
  editable?: boolean;
}> = ({ orderId, handlingFeePercent, originalHandlingFeePercent, editable }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [internal, setInternal] = useState(handlingFeePercent);

  const submit = useCallback(async () => {
    setSubmitting(true);

    try {
      await changeOrderHandlingFeePercent({
        orderId,
        newHandlingFeePercent: internal,
      });

      setOpen(false);
    } catch (error) {
      logError({
        error,
        tags: {
          location: "handling fee edit",
        },
      });
      notification.error({
        message: "Oops! Something went wrong.",
      });
    } finally {
      setSubmitting(false);
    }
  }, [orderId, internal]);

  return (
    <>
      {originalHandlingFeePercent !== undefined &&
        originalHandlingFeePercent !== handlingFeePercent && (
          <>
            <Typography.Text delete>{originalHandlingFeePercent}%</Typography.Text>
            &nbsp;
          </>
        )}
      {handlingFeePercent}%
      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <InputNumber
                  value={internal}
                  onChange={(e) => setInternal(Number(e))}
                  min={0}
                  max={originalHandlingFeePercent ?? handlingFeePercent}
                  step={1}
                  precision={0}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value?.replace("%", "") || 0}
                />
                <br />
                <Space direction="horizontal">
                  <Button type="primary" onClick={submit} loading={submitting}>
                    Update
                  </Button>
                </Space>
              </Space>
            </>
          }
          title="Edit Handling Fee"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};
