import {
  EnterpriseProfile,
  formatCityState,
  distance as computeDistance,
  Location,
  isEnterprise,
} from "@rooted/shared";
import { Typography } from "antd";
import React, { useMemo } from "react";
import { EnvironmentOutlined } from "@ant-design/icons";
import { useRooted } from "../../RootedContext";

export const ProfileDistance: React.FC<{
  profile: EnterpriseProfile;
}> = ({ profile }) => {
  const locationCopy = formatCityState(profile.bio.location.streetAddress);
  const { activeRole } = useRooted();
  let ownLocation: Location | null = null;
  if (isEnterprise(activeRole)) {
    ownLocation = activeRole.profile.bio.location;
  }
  const distance = useMemo(
    () => (ownLocation ? computeDistance(ownLocation, profile.bio.location).toFixed(0) : null),
    [profile.bio.location, ownLocation]
  );
  if (!distance) return null;
  return (
    <Typography.Text type="secondary" style={{ fontSize: 14, marginBottom: 0, display: "block" }}>
      <EnvironmentOutlined /> {locationCopy} {distance !== null ? `(${distance} mi)` : ""}
    </Typography.Text>
  );
};
