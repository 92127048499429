import { makeName, RootedUser, WithId } from "@rooted/shared";

import { Typography, Input, Spin, List, Button, Modal } from "antd";
import React, { useState } from "react";
import { db, useCollectionDataChecked, useDocumentDataChecked } from "../../../services/firebase";
import { createAdminRole, getAdminRole } from "../../../services/roles";
import { UserName } from "../../generic/Settings/StaffPane";

export const AddAdmin: React.FC = () => {
  const [search, setSearch] = useState("");

  const searchPlusOne =
    search.substring(0, search.length - 1) +
    String.fromCharCode(search.charCodeAt(search.length - 1) + 1);

  const [data, dataLoading, dataError] = useCollectionDataChecked<WithId<RootedUser>>(
    search
      ? db
          .collection("users")
          .where("email", ">=", search)
          .where("email", "<", searchPlusOne)
          .limit(5)
      : db.collection("users").limit(5),
    { idField: "_id" }
  );

  return (
    <>
      <br />
      <Input placeholder="Search by email" onChange={(e) => setSearch(e.target.value)} />
      <br />
      <br />
      {dataLoading && <Spin />}
      {data && (
        <List
          dataSource={data}
          renderItem={(u) => (
            <>
              <List.Item key={u._id}>
                <AddAdminItem user={u} />
              </List.Item>
            </>
          )}
        />
      )}
    </>
  );
};

const AddAdminItem: React.FC<{
  user: WithId<RootedUser>;
}> = ({ user }) => {
  const [existingRole] = useDocumentDataChecked(getAdminRole(user));

  const confirmCreateAdmin = (user: WithId<RootedUser>) => {
    Modal.confirm({
      icon: null,
      title: (
        <>
          Add <UserName userId={user._id} /> as admin?
        </>
      ),
      content: (
        <>
          Are you sure you want to add{" "}
          <b>
            <UserName userId={user._id} />
          </b>{" "}
          as an admin?
        </>
      ),
      onOk: () => {
        createAdminRole(user);
      },
    });
  };

  return (
    <>
      <List.Item.Meta title={makeName(user)} description={user.email} />
      {existingRole && <Typography.Text type="secondary">(Admin)</Typography.Text>}
      {!existingRole && (
        <Button
          type="primary"
          onClick={() => {
            confirmCreateAdmin(user);
          }}
        >
          Add
        </Button>
      )}
    </>
  );
};
