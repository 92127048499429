import { legalWreathDiameters, legalWreathTags } from "@rooted/shared";
import { InputNumber, InputNumberProps, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import React from "react";
import { makeSelectOptions } from "../../../../utils/make-select-options";

const legalWreathDiametersOptions = makeSelectOptions(legalWreathDiameters);
export const WreathDiameterInput: React.FC<SelectProps<string>> = (props) => {
  return (
    <Select
      style={{ width: 200 }}
      {...props}
      placeholder="Diameter..."
      options={legalWreathDiametersOptions}
    />
  );
};

const legalWreathTagsOptions = makeSelectOptions(legalWreathTags);
export const WreathTagsInput: React.FC<SelectProps<string[]>> = (props) => {
  return (
    <Select
      style={{ width: 265 }}
      {...props}
      mode="multiple"
      maxTagCount={2}
      placeholder="Tags..."
      options={legalWreathTagsOptions}
    />
  );
};

export const WreathDepthInput: React.FC<InputNumberProps> = (props) => {
  return <InputNumber {...props} min={1} max={30} step={1} precision={0} />;
};
