import { BreadcrumbProps, Route } from "antd/lib/breadcrumb/Breadcrumb";
import { Typography } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
/**
 * Auto-generates a breadcrumb for a given url (to pass to a <Breadcrumb> antd component).
 * Transforms slugs to look prettier (my-slug-name) => "My Slug Name".
 * @param substitutions A map from slugs to override names. Useful if the url contains an ID
 * (i.e. for /drinks/3s834md9 use {3s834md9: "Coffee"} to generate "Drinks / Coffee")
 */
export const useRouterBreadcrumb = (
  substitutions?: Record<string, string | false>
): BreadcrumbProps => {
  const location = useLocation();
  const routes = location.pathname
    .split("/")
    .filter((x) => x.length > 0)
    .map((slug) => {
      return {
        path: slug,
        breadcrumbName: substitutions?.[slug] || formatSlug(slug),
      };
    });

  const itemRender = (route: Route, params: any, routes: Route[], paths: string[]) => {
    const last = routes.indexOf(route) === routes.length - 1;
    if (substitutions?.[paths[paths.length - 1]] === false) return null;
    return routes.length === 1 ? (
      // don't show breadcrumb if its only one level
      <Typography.Text disabled>{route.breadcrumbName}</Typography.Text>
    ) : last ? (
      // no hyperlink on last breadcrumb
      <Typography.Text disabled>{route.breadcrumbName}</Typography.Text>
    ) : (
      <Link to={"/" + paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };
  return { routes, itemRender };
};

export const formatSlug = (slug: string) =>
  slug
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
