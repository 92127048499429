import {
  ColorKey,
  CutFlowerListing,
  CutFlowerRelisting,
  FlowerPrototype,
  RelistingGeneric,
  WithId,
} from "@rooted/shared";
import { Col, Divider, Row, Typography } from "antd";
import React, { useMemo } from "react";
import { ColorDisplay } from "../Misc/ColorDisplay";
import OverflowParagraph from "../OverflowParagraph/OverflowParagraph";
import { ProfileLink } from "../ProfileLink/ProfileLink";

export const FlowerVariety: React.FC<{
  flowerPrototype: FlowerPrototype;
  color?: ColorKey;
  expandable?: boolean;
  scientificEllipsis?: boolean;
  textStyle?: React.CSSProperties;
}> = ({
  flowerPrototype: { commonNames, scientificName },
  color,
  expandable,
  scientificEllipsis,
  textStyle = {},
}) => {
  const concatenatedCommonNames = useMemo(() => commonNames.join(", "), [commonNames]);
  return (
    // Text usually has a margin bottom in the ant design ecosystem, so this aligns
    // this component to be broadly usable without extra config
    <Col style={{ marginBottom: 4 }}>
      <Row>
        <Typography.Text
          ellipsis={scientificEllipsis}
          style={{ paddingRight: color ? 20 : undefined, ...textStyle }}
        >
          {scientificName}
        </Typography.Text>
        {color && (
          <div>
            <ColorDisplay color={color} style={textStyle} />
          </div>
        )}
      </Row>
      {concatenatedCommonNames && (
        <OverflowParagraph
          expandable={expandable}
          type={"secondary"}
          style={{ marginBottom: 0, ...textStyle }}
        >
          {concatenatedCommonNames}
        </OverflowParagraph>
      )}
    </Col>
  );
};

const createFlowerVarietyColumn = <T extends CutFlowerListing = CutFlowerListing>(
  render: (_: any, r: WithId<T>) => JSX.Element
) => ({
  title: <>Flower&nbsp;/ Variety</>,
  //ellipsis: true,
  key: "flower",
  width: 200,
  render,
  sorter: (a: WithId<T>, b: WithId<T>) =>
    b.product._prototypeCache.scientificName.localeCompare(
      a.product._prototypeCache.scientificName.toLowerCase()
    ),
  sortDirections: ["descend", "ascend"] as any, // SortOrder from antd
});

/**
 * Basic FlowerVarietyColumn, just has flower scientific + common names
 */
export const FlowerVarietyColumn = createFlowerVarietyColumn((_, { product, _id }) => {
  return <FlowerVariety flowerPrototype={product._prototypeCache} />;
});

/**
 * FlowerVarietyColumn with details
 */
export const FlowerVarietyDetailsColumn = createFlowerVarietyColumn((_, { product }) => (
  <>
    <FlowerVariety flowerPrototype={product._prototypeCache} />
    {product.description && (
      <>
        <Divider style={{ marginTop: 0, marginBottom: 4 }} />
        <OverflowParagraph expandable>{product.description}</OverflowParagraph>
      </>
    )}
  </>
));

/**
 * For a coop's admin view of seeing the original seller on a relisted flower
 */
export const FlowerVarietyColumnWithOriginalSeller = createFlowerVarietyColumn<CutFlowerRelisting>(
  (_, { product, backingListing: { seller } }) => (
    <>
      <FlowerVariety flowerPrototype={product._prototypeCache} />
      <br />
      Grown by{" "}
      <ProfileLink
        to={{
          ...seller._cache,
          _id: seller.profileId,
        }}
      >
        {seller._cache.bio.displayName}
      </ProfileLink>
    </>
  )
);

/**
 * For harvest lists so growers can distinguish between similar flower varieties
 */
export const FlowerVarietyColumnWithDescription = createFlowerVarietyColumn<CutFlowerListing>(
  (_, { product }) => (
    <>
      <FlowerVariety flowerPrototype={product._prototypeCache} />
      {product.description}
    </>
  )
);
