import { FullBuyerRole } from "@rooted/shared";
import { Badge, Menu } from "antd";
import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { PageContent, WideLayout } from "../../../views/layouts";
import { CollapsingSider } from "../../../components/Sider/CollapsingSider";
import { OrderDetails } from "./OrderDetails";
import { useActiveOrders } from "../../../services/buyers/orders";
import { ActiveOrders, FulfilledOrders, CancelledOrders } from "../../orders";

export const BuyerOrders: React.FC<{ role: FullBuyerRole }> = ({ role }) => {
  // This allows active detail pages to show the active as selected
  const category =
    useLocation()
      .pathname.split("/")
      .find((c) => c === "cancelled" || c === "fulfilled") || "active";
  const [activeOrders] = useActiveOrders(role);

  return (
    <>
      <WideLayout>
        <CollapsingSider
          selectedKeys={[category]}
          smallDrawerLabel={{
            title: "Orders",
            selection: category,
          }}
        >
          <Menu.Item key="active">
            <Link to="">
              Active Orders <Badge count={activeOrders?.length ?? 0} />
            </Link>
          </Menu.Item>
          <Menu.Item key="fulfilled">
            <Link to="fulfilled">Fulfilled Orders</Link>
          </Menu.Item>
          <Menu.Item key="cancelled">
            <Link to="cancelled">Cancelled Orders</Link>
          </Menu.Item>
        </CollapsingSider>
        <PageContent>
          <Routes>
            <Route path="/" element={<ActiveOrders />} />
            <Route path="fulfilled" element={<FulfilledOrders />} />
            <Route path="cancelled" element={<CancelledOrders />} />
            <Route path=":orderId" element={<OrderDetails role={role} />} />
            <Route path="cancelled/:orderId" element={<OrderDetails role={role} />} />
            <Route path="fulfilled/:orderId" element={<OrderDetails role={role} />} />
          </Routes>
        </PageContent>
      </WideLayout>
    </>
  );
};
