/**
 * Front End Reference to Firebase
 * See API reference https://firebase.google.com/docs/reference/js
 */

import firebase from "firebase/app";
import "firebase/analytics"; // for analytics
import "firebase/auth"; // for authentication
import "firebase/firestore"; // for cloud firestore
import "firebase/functions"; // for cloud functions
import "firebase/storage"; // for storage
import {
  useCollectionData,
  useCollectionDataOnce,
  useDocumentData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import { WithId } from "@rooted/shared";
import { addBreadcrumb } from "../sentry";

// Any environment variables that we pass in must start with REACT_APP_. This is a quirk of CRA.
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID, // only defined for prod
  measurementId: process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID, // only defined for prod
};

firebase.initializeApp(config);

// Initialize firstore once in this module. We'll import it to use elsewhere
// There's no performance benefit to this, but importing firestore from here ensures that
// Firebase.intializeApp(...) is always called before we access firestore
export const db = firebase.firestore();
export const functions = firebase.functions(); // front end reference to cloud functions: https://firebase.google.com/docs/reference/js/firebase.functions
export const auth = firebase.auth();
export const storage = firebase.storage();

// Use local persistence (default)
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// Use emulators in local mode
if (process.env.REACT_APP_BACKEND === "local") {
  db.useEmulator("localhost", 8080);
  functions.useEmulator("localhost", 5001);

  // @ts-expect-error https://github.com/firebase/firebase-js-sdk/issues/4591
  auth.useEmulator("http://localhost:9099", { disableWarnings: true });
  storage.useEmulator("localhost", 9199);
}

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: any, value: any) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

function addQueryBreadcrumb(
  message: string,
  query?: firebase.firestore.Query<firebase.firestore.DocumentData> | null
): void {
  // Because firestore is compiled, we do not know the name of the private
  // property containing the info we want. However, we don't care about anything
  // in the public "firestore" property, so we destructure firebase and use
  // the rest of the object as the data
  const { firestore, ...rest } = query || {};
  if (Object.keys(rest).length === 0) return;

  addBreadcrumb({
    category: "db",
    message,
    data: {
      queryInfo: JSON.stringify(rest, getCircularReplacer()),
    },
  });
}

/** Wraps the use collection data hook to always throw errors.
 * These errors *should* all be real errors (permissions, invalid query, etc)
 * and not things like network problems so I think throwing is correct.
 */
export function useCollectionDataChecked<T>(
  query?: firebase.firestore.Query<firebase.firestore.DocumentData> | null,
  options?: {
    idField?: string | undefined;
    snapshotListenOptions?: firebase.firestore.SnapshotListenOptions | undefined;
  }
) {
  const result = useCollectionData<T>(query, options);

  if (result[2]) {
    console.error("Extra firestore debug info: ", query);
    addQueryBreadcrumb("Error during useCollectionDataChecked", query);
    throw result[2];
  }
  return result;
}

/** Wraps the use collection data once hook to always throw errors.
 * See above for rationale.
 */
export function useCollectionDataOnceChecked<T>(
  query?: firebase.firestore.Query<firebase.firestore.DocumentData> | null,
  options?: {
    idField?: string | undefined;
    snapshotListenOptions?: firebase.firestore.SnapshotListenOptions | undefined;
  }
) {
  const result = useCollectionDataOnce<T>(query, options);
  if (result[2]) {
    console.error("Extra firestore debug info: ", query);
    addQueryBreadcrumb("Error during useCollectionDataOnceChecked", query);
    throw result[2];
  }
  return result;
}

/** Wraps the use document data hook to always throw errors.
 * See above for rationale.
 */
export function useDocumentDataChecked<T>(
  docRef?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | null,
  options?: {
    getOptions?: firebase.firestore.GetOptions;
    idField?: string;
  }
) {
  const result = useDocumentData<T>(docRef, options);
  if (result[2]) {
    console.error("Extra firestore debug info: ", docRef);
    addBreadcrumb({
      category: "db",
      message: "Error during useDocumentDataChecked",
      data: {
        path: docRef?.path,
      },
    });
  }
  return result;
}

/** Wraps the use document data once hook to always throw errors.
 * See above for rationale.
 */
export function useDocumentDataOnceChecked<T>(
  docRef?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | null,
  options?: {
    getOptions?: firebase.firestore.GetOptions;
    idField?: string;
  }
) {
  const result = useDocumentDataOnce<T>(docRef, options);
  if (result[2]) {
    console.error("Extra firestore debug info: ", docRef);
    addBreadcrumb({
      category: "db",
      message: "Error during useDocumentDataOnceChecked",
      data: {
        path: docRef?.path,
      },
    });
    throw result[2];
  }
  return result;
}

function dataWithId<T>(
  doc:
    | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
    | firebase.firestore.DocumentSnapshot
): WithId<T> {
  return Object.assign(doc.data() as T, { _id: doc.ref.id });
}

export function snapshotToIdDoc<T>(
  snap: firebase.firestore.DocumentSnapshot | undefined
): WithId<T> | undefined {
  if (snap && snap.exists) return dataWithId<T>(snap);
  else return undefined;
}

export function querySnapshotToIdDocs<T>(snap: firebase.firestore.QuerySnapshot): WithId<T>[] {
  return snap.docs.map((doc) => dataWithId<T>(doc));
}
