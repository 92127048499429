import React, { useState } from "react";
import { Layout, Drawer, Button, Menu, Typography } from "antd";
import { breakpoint, useWindowSize } from "../../utils/detectWindowSize";
import { DownOutlined } from "@ant-design/icons";
import { MenuProps } from "antd/lib/menu";
import { DrawerProps } from "antd/lib/drawer";

export interface CollapsingSiderProps extends MenuProps {
  onClick?: any;
  selectedKeys?: string[];
  siderStyle?: any;
  drawerStyle?: any;
  label?: string;
  siderWidth?: number;
  drawerHeight?: DrawerProps["height"];
  hideIfSmall?: boolean;
  smallDrawerLabel?: {
    title: string;
    selection: string;
  };
}

export const CollapsingSider: React.FC<CollapsingSiderProps> = ({
  children,
  onClick,
  selectedKeys,
  siderStyle,
  drawerStyle,
  label,
  siderWidth,
  drawerHeight,
  hideIfSmall,
  smallDrawerLabel,
  ...rest
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [width, height] = useWindowSize();

  if (!onClick) {
    onClick = () => setCollapsed(!collapsed);
  }

  if (hideIfSmall && width < breakpoint.md) return null;

  return (
    <>
      {width >= breakpoint.md ? (
        <Layout.Sider theme="light" breakpoint="md" style={siderStyle} width={siderWidth}>
          {label && <Typography.Text strong>{label}</Typography.Text>}
          <Menu onClick={onClick} selectedKeys={selectedKeys} mode={"vertical"} {...rest}>
            {children}
          </Menu>
        </Layout.Sider>
      ) : (
        <>
          <Button
            style={{
              width: "100%",
              marginTop: "0px",
              marginBottom: "10px",
              textTransform: "capitalize",
            }}
            onClick={() => setCollapsed(!collapsed)}
          >
            {label ||
              (smallDrawerLabel
                ? `${smallDrawerLabel.title} / ${smallDrawerLabel.selection.replace("-", " ")}`
                : "Sub-section")}
            <DownOutlined />
          </Button>
          <Drawer
            title={label || smallDrawerLabel?.title}
            placement="bottom"
            visible={collapsed}
            closable={true}
            style={drawerStyle}
            onClose={() => setCollapsed(!collapsed)}
            height={drawerHeight}
          >
            <Menu onClick={onClick} selectedKeys={selectedKeys} mode={"vertical"}>
              {children}
            </Menu>
          </Drawer>
        </>
      )}
    </>
  );
};
