import { ApplicationForm, WithId, normalizeValue, EnterpriseProfileBio } from "@rooted/shared";
import { Form, Input } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LocationInput } from "../../../components/FormControls/AddressInput";
import { FirestoreDocForm } from "../../../components/FormControls/FirestoreDocForm";
import { db } from "../../../services/firebase";
import { removeWhitespace } from "../../../utils/removeWhitespace";

export const BioForm: React.FC<{ application: WithId<ApplicationForm>; disabled: boolean }> = ({
  application,
  disabled = true,
}) => {
  const navigate = useNavigate();

  return (
    <FirestoreDocForm
      layout="vertical"
      onStatusChange={(x) =>
        x === "submitted" && navigate(`/onboarding/applications/${application._id}/details`)
      }
      replaceOnSubmit={(values: EnterpriseProfileBio): EnterpriseProfileBio => {
        // add normalized displayName for querying to bio model
        return {
          ...values,
          _displayNameLowerCase: normalizeValue(values.displayName)!,
        };
      }}
      rootFieldPath="bio"
      data={application.bio}
      dbRef={db.collection("applications").doc(application._id)}
    >
      <BioFormItems disabled={disabled} />
    </FirestoreDocForm>
  );
};

export const BioFormItems: React.FC<{ disabled?: boolean }> = ({ disabled = false }) => (
  <>
    <ol style={{ paddingLeft: 25 }}>
      <li>
        <Form.Item
          name="displayName"
          label="Display Name"
          requiredMark
          rules={[{ required: true, message: " " }]}
        >
          <Input disabled={disabled} />
        </Form.Item>
      </li>
      <li>
        <Form.Item
          name="legalName"
          label="Legal Business Name (may be the same)"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <Input disabled={disabled} />
        </Form.Item>
      </li>
      <li>
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          requiredMark
          rules={[{ required: true, message: " " }]}
        >
          <Input disabled={disabled} />
        </Form.Item>
      </li>
      <li>
        <Form.Item
          name="email"
          label="Business Email"
          requiredMark
          validateTrigger={["onBlur", "onSubmit"]}
          normalize={removeWhitespace}
          rules={[
            {
              required: true,
              message: "Please enter a valid email address",
              type: "email",
            },
          ]}
        >
          <Input disabled={disabled} />
        </Form.Item>
      </li>
      <li>
        <Form.Item
          label="Physical Address"
          name="location"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <LocationInput disabled={disabled} />
        </Form.Item>
      </li>
      <li>
        <Form.Item
          name="description"
          label="Description (public to Rooted users)"
          rules={[{ required: true, message: " " }]}
          requiredMark
        >
          <Input.TextArea rows={4} disabled={disabled} />
        </Form.Item>
      </li>
      <li>
        <Form.Item name="websiteUrl" label="Website" initialValue="">
          <Input addonBefore="https://" disabled={disabled} />
        </Form.Item>
      </li>
      <li>
        <Form.Item name="instagramUrl" label="Instagram URL" initialValue="">
          <Input disabled={disabled} />
        </Form.Item>
      </li>
      <li>
        <Form.Item name="facebookUrl" label="Facebook URL" initialValue="">
          <Input disabled={disabled} />
        </Form.Item>
      </li>
    </ol>
  </>
);
