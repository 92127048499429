import { EditOutlined } from "@ant-design/icons";
import { faShoppingCart, faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ApprovedConnection,
  ApprovedConnectionUpdate,
  FullRole,
  FullSellerRole,
  isSeller,
  WithId,
} from "@rooted/shared";
import { Button, Divider, Popover, Select, Space, Typography, Col, Checkbox, Row } from "antd";
import React, { useState } from "react";
import { CurrencyInput, formatCurrency } from "../../components/FormControls/CurrencyInput";
import { WeekdaySelect } from "../../components/FormControls/WeekdaySelect";
import { useRooted } from "../../RootedContext";
import { shouldOrderingBeEnabled, updateConnection } from "../../services/connections";
import { db } from "../../services/firebase";
import { BarnIcon } from "../Icons";
import { OrderMinimumWidget } from "./OrderMinimumWidget";

export const FulfillmentWidget: React.FC<{
  connection: WithId<ApprovedConnection>;
  editable?: boolean;
  role: WithId<FullRole>;
}> = ({ connection, editable, role }) => {
  const { permissions } = useRooted();
  const canEditOrderMinimum = permissions.seller?.orderMinimum;
  const showCheckboxAlert =
    editable &&
    connection.orderingEnabled &&
    !connection.deliverySettings.enabled &&
    !connection.pickupSettings.enabled;

  return (
    <>
      <div
        style={{
          border: "1px solid lightgray",
          backgroundColor: "#fbf9f9",
          width: "100%",
          borderRadius: 8,
          padding: 4,
          paddingLeft: 8,
        }}
      >
        <Row wrap={false} style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <Space
              style={{
                // These margins are applied the non-disappearing element to prevent jank on toggle
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              {isSeller(role) ? (
                <Checkbox
                  disabled={!connection.orderingEnabled}
                  checked={connection.deliverySettings.enabled}
                  onChange={
                    editable
                      ? (v) => {
                          const deliveryEnabled = v.target.checked;

                          const update: ApprovedConnectionUpdate = {
                            orderingEnabled: shouldOrderingBeEnabled({
                              pickupEnabled: connection.pickupSettings.enabled,
                              deliveryEnabled,
                            }),
                            deliverySettings: {
                              enabled: deliveryEnabled,
                            },
                          };

                          updateConnection(connection._id, update);
                        }
                      : undefined
                  }
                  style={{
                    border: showCheckboxAlert ? "1px solid red" : undefined,
                    padding: 2,
                    borderRadius: 4,
                  }}
                />
              ) : (
                <div style={{ width: 20 }}>
                  <FontAwesomeIcon icon={faTruck} height={24} />
                </div>
              )}
              <div style={{ width: 70 }}>Delivery:</div>
              <div style={{ width: 80 }}>
                {!editable && !connection.deliverySettings.enabled ? (
                  // We expect `nowrap` to exceed the 80px,
                  // but this is fine because it will overflow into where the weekday select would be
                  <Typography.Text type="secondary" style={{ whiteSpace: "nowrap" }}>
                    <>Not Available</>
                  </Typography.Text>
                ) : (
                  <DeliveryPriceWidget
                    connection={connection}
                    editable={connection.orderingEnabled && editable}
                  />
                )}
              </div>
            </Space>

            {connection.deliverySettings.enabled && connection.orderingEnabled && (
              <Space
                className={`week-line-calendar-xsmall ${
                  !editable ? "week-line-calendar-disabled" : ""
                }`}
                // This small margin only affects the smaller-view column display
                style={{ marginLeft: 12, marginBottom: 4 }}
              >
                <WeekdaySelect
                  disabled={
                    !connection.deliverySettings.enabled || !connection.orderingEnabled || !editable
                  }
                  value={connection.deliverySettings.weekdays}
                  onChange={
                    editable
                      ? (v) => {
                          updateConnection(connection._id, {
                            deliverySettings: {
                              weekdays: v,
                            },
                          });
                        }
                      : undefined
                  }
                  size="small"
                />
              </Space>
            )}
            <br />

            {isSeller(role) &&
              connection.buyerId === "retail" &&
              connection.deliverySettings.enabled && (
                <>
                  <Space
                    style={{
                      paddingBottom: 8,
                      paddingTop: 4,
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <div>
                      <Typography.Text style={{ paddingLeft: 25 }}>Zip Codes:</Typography.Text>
                    </div>
                    {/* 286 lines up with weekday on large layout. the margin-left aligns with the weekday left on small layout.  */}
                    {/* TODO: make this not line break on _super_ small screens. This is acceptable for a first pass. */}
                    <div style={{ width: 274, display: "flex", marginLeft: 12 }}>
                      <DeliveryZipCodesWidget role={role} />
                    </div>
                  </Space>
                </>
              )}

            <Divider style={{ marginTop: 5, marginBottom: 5, width: "100%" }} />
            <Space
              style={{
                marginTop: 5,
                marginBottom: 5,
              }}
            >
              {isSeller(role) ? (
                <Checkbox
                  disabled={!connection.orderingEnabled}
                  checked={connection.pickupSettings.enabled}
                  onChange={
                    editable
                      ? (v) => {
                          const pickupEnabled = v.target.checked;

                          const update: ApprovedConnectionUpdate = {
                            orderingEnabled: shouldOrderingBeEnabled({
                              pickupEnabled,
                              deliveryEnabled: connection.deliverySettings.enabled,
                            }),
                            pickupSettings: {
                              enabled: pickupEnabled,
                            },
                          };

                          updateConnection(connection._id, update);
                        }
                      : undefined
                  }
                  style={{
                    border: showCheckboxAlert ? "1px solid red" : undefined,
                    padding: 2,
                    borderRadius: 4,
                  }}
                />
              ) : (
                <div style={{ width: 20 }}>
                  <BarnIcon />
                </div>
              )}
              <div style={{ width: 70 }}>Pickup:</div>
              <div style={{ width: 80 }}>
                {/* We expect `nowrap` to exceed the 80px,
                  but this is fine because it will overflow into where the weekday select would be
              */}
                <Typography.Text type="secondary" style={{ whiteSpace: "nowrap" }}>
                  {connection.pickupSettings.enabled ? "Free" : "Not Available"}
                </Typography.Text>
              </div>
            </Space>

            {connection.pickupSettings.enabled && connection.orderingEnabled && (
              <Space
                className={`week-line-calendar-xsmall ${
                  !editable ? "week-line-calendar-disabled" : ""
                }`}
                style={{ marginLeft: 12 }}
              >
                <WeekdaySelect
                  disabled={
                    !connection.pickupSettings.enabled || !connection.orderingEnabled || !editable
                  }
                  value={connection.pickupSettings.weekdays}
                  onChange={
                    editable
                      ? (v) => {
                          updateConnection(connection._id, {
                            pickupSettings: {
                              weekdays: v,
                            },
                          });
                        }
                      : undefined
                  }
                  size="small"
                />
              </Space>
            )}
          </div>
        </Row>

        {showCheckboxAlert && (
          <Col>
            <Typography.Text type="danger">Please select fulfillment option(s)</Typography.Text>
          </Col>
        )}

        {((editable && canEditOrderMinimum) || connection.orderMinimumCents) && (
          <>
            <Divider style={{ marginTop: 5, marginBottom: 5 }} />

            <Space>
              {!editable && <FontAwesomeIcon icon={faShoppingCart} style={{ width: 20 }} />}
              Order Minimum:
              <OrderMinimumWidget
                connection={connection}
                editable={editable && canEditOrderMinimum}
              />
            </Space>
          </>
        )}
      </div>
    </>
  );
};

const DeliveryZipCodesWidget: React.FC<{ role: FullSellerRole }> = ({ role }) => (
  <Select
    value={role.profile.fulfillmentSettings.delivery.zipCodes}
    style={{ width: "100%" }}
    showSearch
    mode="tags"
    maxTagPlaceholder="No Delivery Zip-Codes"
    onChange={async (x) => {
      await db
        .collection("profiles")
        .doc(role.profileId)
        .set(
          {
            fulfillmentSettings: {
              delivery: {
                zipCodes: x,
              },
            },
          },
          { merge: true }
        );
    }}
  />
);

const DeliveryPriceWidget: React.FC<{
  connection: WithId<ApprovedConnection>;
  editable?: boolean;
}> = ({ connection, editable }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [price, setPrice] = useState(connection.deliverySettings?.price);

  const submit = async () => {
    setSubmitting(true);
    await updateConnection(connection._id, { deliverySettings: { price: price } });
    setSubmitting(false);
    setOpen(false);
  };

  return (
    <>
      {formatCurrency(connection.deliverySettings.price)}
      {editable && (
        <Popover
          content={
            <>
              <Space size={4}>
                <CurrencyInput value={price} onChange={(e) => setPrice(e as number)} />
                <br />
                <Space direction="horizontal">
                  <Button type="primary" onClick={submit} loading={submitting}>
                    Update
                  </Button>
                </Space>
              </Space>
            </>
          }
          title="Change delivery price"
          trigger="click"
          visible={open}
          onVisibleChange={setOpen}
          placement="bottomRight"
        >
          &nbsp;&nbsp;
          <EditOutlined />
        </Popover>
      )}
    </>
  );
};
