import React, { useState } from "react";
import { DayOfWeek, DAYS_OF_WEEK } from "@rooted/shared";

import { Button, Col, Typography } from "antd";

export const WeekdaySelect: React.FC<{
  size?: "small" | "middle" | "large";
  value?: DayOfWeek[];
  onChange?: (x: DayOfWeek[]) => void;
  disabled?: boolean;
}> = ({ value, onChange, size = "middle", disabled }) => {
  const [internalDays, setDays] = useState(value || []);

  const days = value || internalDays;

  const toggle = (x: DayOfWeek) => () => {
    const newDays = days.includes(x) ? days.filter((y) => y !== x) : [...days, x];
    setDays(newDays);
    onChange && onChange(newDays);
  };

  const showRequiredError = !disabled && days.length === 0;

  return (
    <Col>
      <div
        style={{
          padding: 2,
          borderRadius: 4,
          border: showRequiredError ? "1px solid red" : undefined,
        }}
      >
        <Button.Group size={size}>
          {DAYS_OF_WEEK.map(({ name, short }) => (
            <Button
              key={name}
              disabled={disabled}
              type={days.includes(short) ? "primary" : "default"}
              onClick={toggle(short)}
              style={{
                textTransform: "capitalize",
              }}
            >
              {size === "large" ? name : short.toLowerCase()}
            </Button>
          ))}
        </Button.Group>
      </div>

      {showRequiredError && (
        <div>
          <Typography.Text type="danger">Please select days</Typography.Text>
        </div>
      )}
    </Col>
  );
};
