import {
  ColorKey,
  CSAListing,
  CutFlowerListing,
  DriedFlowerListing,
  getDriedFlowerProductTypeLabel,
  legalColors,
  legalStemLengths,
  ListingWithFulfillment,
  PottedPlantListing,
  Relisting,
  StemLength,
  WreathListing,
} from "@rooted/shared";
import { Typography } from "antd";
import { ColumnType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import React from "react";
import { Link } from "react-router-dom";
import { ProductThumbnail } from "../Images/ProductThumbnail";
import { ColorDisplay } from "../Misc/ColorDisplay";
import { ProfileLink } from "../ProfileLink/ProfileLink";
import { AddToCartWidget } from "../Widgets/BuyerListings/AddToCartWidget";
import { BuyerListingPriceWidget } from "../Widgets/BuyerListings/PriceWidget";

export const buyerListingImageColumn = {
  title: "",
  dataIndex: ["listing", "product", "photoStorageUrl"],
  key: "photo",
  width: 80,
  fixed: "left" as const,
  render: (_: unknown, { listing }: ListingWithFulfillment) => (
    <ProductThumbnail product={listing.product} />
  ),
};

export const buyerListingNameColumn = {
  title: "Name",
  ellipsis: true,
  width: 200,
  // fixed: "right",
  key: "name",
  render: (_: any, record: ListingWithFulfillment) => (
    <>
      <Link to={`/listings/${record.listing._id}`}>{record.listing.product.name}</Link> <br />
      {record.listing.category === "cut-flower" ? (
        record.listing.product._prototypeCache.commonNames.join("").trim() !== "" ? (
          <>
            {record.listing.product._prototypeCache.commonNames.join(", ")}
            <br />
          </>
        ) : (
          <></>
        )
      ) : record.listing.product.description ? (
        <>
          <Typography.Text type="secondary">{record.listing.product.description}</Typography.Text>
          <br />
        </>
      ) : null}
      Sold by {/* TODO: fix hack */}
      {((record.listing as unknown) as Relisting).backingListing && (
        <>
          <ProfileLink
            to={{
              ...((record.listing as unknown) as Relisting).backingListing.seller._cache,
              _id: ((record.listing as unknown) as Relisting).backingListing.seller.profileId,
            }}
          >
            {
              ((record.listing as unknown) as Relisting).backingListing.seller._cache.bio
                .displayName
            }
          </ProfileLink>
          <br />
          via{" "}
        </>
      )}
      <ProfileLink
        to={{
          ...record.listing.seller._cache,
          _id: record.listing.seller.profileId,
        }}
      >
        {record.listing.seller._cache.bio.displayName}
      </ProfileLink>
    </>
  ),
  sorter: (a: ListingWithFulfillment, b: ListingWithFulfillment) =>
    b.listing.product.name.localeCompare(a.listing.product.name.toLowerCase()),
  sortDirections: ["descend", "ascend"] as SortOrder[],
};

export const buyerListingColorColumn = {
  title: "Color",
  dataIndex: ["listing", "product", "dominantColor"],
  key: "dominantColor",
  width: 100,
  render: (x: ColorKey) => <ColorDisplay color={x} />,
  sorter: (a: ListingWithFulfillment, b: ListingWithFulfillment) =>
    legalColors.findIndex((x) => x.key === (a.listing as CutFlowerListing).product.dominantColor) -
    legalColors.findIndex((x) => x.key === (b.listing as CutFlowerListing).product.dominantColor),
  sortDirections: ["descend", "ascend"] as SortOrder[],
};

export const buyerListingPriceColumn = {
  title: "Price",
  width: 140,
  key: "name",
  render: (_: any, record: ListingWithFulfillment) => (
    <BuyerListingPriceWidget listing={record.listing} twoLine />
  ),
  sorter: (a: ListingWithFulfillment, b: ListingWithFulfillment) =>
    (a.listing.pricing.pricePerStem || a.listing.pricing.price) -
    (b.listing.pricing.pricePerStem || b.listing.pricing.price),
  sortDirections: ["descend", "ascend"] as SortOrder[],
};

export const buyerListingSellerColumn: ColumnType<ListingWithFulfillment> = {
  title: "Seller",
  key: "seller",
  width: 160,
  render: (v: any, r) => (
    <ProfileLink to={{ ...r.listing.seller._cache, _id: r.listing.seller.profileId }}>
      {r.listing.seller._cache.bio.displayName}
    </ProfileLink>
  ),
};

export const buyerListingDriedFlowerTypeColumn: ColumnType<
  ListingWithFulfillment<DriedFlowerListing>
> = {
  title: "Type",
  key: "type",
  width: 60,
  render: (v: any, r) => getDriedFlowerProductTypeLabel(r.listing.product.type),
};

export const buyerListingPottedPlantTypeColumn: ColumnType<
  ListingWithFulfillment<PottedPlantListing>
> = {
  title: "Type",
  key: "type",
  width: 60,
  render: (v: any, r) => r.listing.product.pottedPlantType,
};

export const buyerListingWreathDiameterColumn: ColumnType<ListingWithFulfillment<WreathListing>> = {
  title: "Diameter",
  key: "diameter",
  width: 60,
  render: (v: any, r) => r.listing.product.diameter,
};

export const buyerListingQuantityColumn = {
  title: "Qty.",
  dataIndex: ["listing", "availability", "quantity"],
  width: 55,
  className: "text-center",
  key: "quantity",
  sorter: (a: ListingWithFulfillment, b: ListingWithFulfillment) =>
    a.listing.availability.quantity - b.listing.availability.quantity,
  sortDirections: ["descend", "ascend"] as SortOrder[],
};

export const buyerListingQuantityColumnCutFlowers = {
  ...buyerListingQuantityColumn,
  title: "Qty. (bunches)",
  width: 80,
};

export const buyerListingStemLengthColumn = {
  title: "Stem Length",
  dataIndex: ["listing", "product", "stemLength"],
  key: "stemLength",
  width: 80,
  sorter: (
    a: ListingWithFulfillment<CutFlowerListing | DriedFlowerListing>,
    b: ListingWithFulfillment<CutFlowerListing | DriedFlowerListing>
  ) =>
    legalStemLengths.findIndex((sL) => sL === b.listing.product.stemLength) -
    legalStemLengths.findIndex((sL) => sL === a.listing.product.stemLength),
  render: (stemLength?: StemLength) => <Typography.Text>{stemLength ?? "n/a"}</Typography.Text>,
};

export const addToCartColumn = {
  key: "add-to-cart",
  className: "letter-spacing--1",
  width: 115,
  render: (_: unknown, listing: ListingWithFulfillment) => <AddToCartWidget listing={listing} />,
};

export const csaWeeksColumn: ColumnType<ListingWithFulfillment<CSAListing>> = {
  title: "Weeks",
  dataIndex: ["listing", "product", "numberOfWeeks"],
  width: 75,
  className: "text-center",
  key: "weeks",
  sorter: (a: ListingWithFulfillment<CSAListing>, b: ListingWithFulfillment<CSAListing>) =>
    a.listing.product.numberOfWeeks - b.listing.product.numberOfWeeks,
  sortDirections: ["descend", "ascend"] as SortOrder[],
};
