import { Button, Col, Modal, Tabs, Tooltip, Typography } from "antd";
import React, { ReactText, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isGrower, isSeller } from "@rooted/shared";
import { PageHeader } from "../../components/PageHeader";
import { useRooted } from "../../RootedContext";
import { HarvestList } from "../sellers/orders/HarvestLists";
import { AnonymousPointOfSale, ExistingPointOfSaleForm } from "../sellers/orders/PointOfSale";
import { OrdersTable } from "./OrderTable/OrderTable";

export const ActiveOrders: React.FC = () => {
  const { activeRole: role } = useRooted();

  const [selectedRowKeys, setSelectedRowKeys] = useState<ReactText[]>([]);
  const [harvestListOpen, setHarvestListOpen] = useState(false);

  const navigate = useNavigate();

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const selectedOrderIds = useMemo(() => selectedRowKeys.map((a) => a.toString()), [
    selectedRowKeys,
  ]);

  const navigateToOrder = (orderId: string, isGuestCheckout?: boolean) => {
    closePosModal();
    navigate(`/orders/${isGuestCheckout ? "guest-checkout/" : ""}${orderId}`);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const openPosModal = () => setModalOpen(true);
  const closePosModal = () => setModalOpen(false);

  return (
    <>
      {/* Point of Sale Orders */}
      {isSeller(role) && (
        <Modal
          title={
            <Typography.Title style={{ marginBottom: 0 }} level={4}>
              {"Point of Sale Order"}
            </Typography.Title>
          }
          bodyStyle={{ paddingTop: 0 }}
          visible={modalOpen}
          footer={null}
          closable={false}
          destroyOnClose
        >
          <Typography.Text type="secondary" style={{ fontSize: 14 }}>
            Point of Sale orders can be placed for existing Rooted members or can be used for guest
            checkout for non-Rooted members.
          </Typography.Text>
          <Tabs>
            <Tabs.TabPane key="existing-user" tab="Existing User">
              <ExistingPointOfSaleForm onSuccess={navigateToOrder} onCancel={closePosModal} />
            </Tabs.TabPane>
            <Tabs.TabPane key="guest-checkout" tab="Guest Checkout">
              <AnonymousPointOfSale
                onSuccess={(id) => navigateToOrder(id, true)}
                onCancel={closePosModal}
              />
            </Tabs.TabPane>
          </Tabs>
        </Modal>
      )}

      <PageHeader
        title={"Active Orders"}
        breadcrumb
        extra={
          isSeller(role) ? (
            <Button type="primary" onClick={openPosModal}>
              Create Point of Sale Order
            </Button>
          ) : null
        }
      />

      {/* Harvest lists for growers */}
      {isGrower(role) && (
        <Col style={{ marginBottom: 15 }}>
          <Tooltip
            placement="top"
            title="Select orders to make a harvest list."
            trigger={selectedOrderIds.length === 0 ? ["hover"] : []}
          >
            <Button
              onClick={() => {
                if (harvestListOpen) {
                  setSelectedRowKeys([]);
                  setHarvestListOpen(false);
                } else {
                  setHarvestListOpen(true);
                }
              }}
              type="primary"
              disabled={selectedOrderIds.length === 0}
            >
              {harvestListOpen ? "Back to orders" : "Make Harvest List"}
            </Button>
          </Tooltip>
          <span style={{ marginLeft: 8 }}>
            {selectedOrderIds.length > 0
              ? `${selectedOrderIds.length} order${selectedOrderIds.length > 1 ? "s" : ""} selected`
              : ""}
          </span>
        </Col>
      )}

      {harvestListOpen ? (
        <HarvestList orderIds={selectedOrderIds} />
      ) : (
        <OrdersTable status="active" rowSelection={isGrower(role) ? rowSelection : undefined} />
      )}
    </>
  );
};
