import { FullUserRole, isSeller, PublicGlobalSettings } from "@rooted/shared";
import { Button, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { AdminDownForMaintenanceBanner } from "./components/Banners/AdminDownForMaintenanceBanner";
import { AppVersionBanner } from "./components/Banners/AppVersionBanner";
import { GlobalBanner } from "./components/Banners/GlobalBanner";
import { getSessionStorageActiveRole, useSyncActiveRole } from "./hooks/account/useSyncActiveRole";
import { useUpdateAccountStatusCookie } from "./hooks/account/useUpdateAccountStatusCookie";
import { LocalAccountState, useAccountState } from "./services/account-state";
import { db, useDocumentDataChecked } from "./services/firebase";
import { useMySubscriptionPlan } from "./services/sellers/subscriptions";
import { isLocalhost } from "./utils/env";
import { LoadingPage } from "./views/layouts";
import { DownForMaintenancePage } from "./views/layouts/DownForMaintenancePage";

type RootedGlobalState = {
  isAdmin: boolean;
  permissions: {
    seller?: {
      orderMinimum: boolean;
      buyerTags: boolean;
    };
  };
  globalSettings: PublicGlobalSettings;
  account: LocalAccountState;
  activeRole: FullUserRole | undefined;
  setActiveRoleId: (newRoleId: string) => void;
};

const reloadPage = () => {
  // https://stackoverflow.com/questions/5721704/window-location-reload-with-clear-cache
  // Tested on Firefox, Chrome, Safari, and Mobile Safari
  window.location.reload(true);
};

const RootedGlobalStateContext = React.createContext<RootedGlobalState | undefined>(undefined);

const RootedGlobalStateProvider: React.FC = ({ children }) => {
  const [activeRoleId, setActiveRoleId] = useState<string>();
  const clientUpdateMessageVisibleRef = useRef(false);

  const [globalSettings] = useDocumentDataChecked<PublicGlobalSettings>(
    db.collection("global").doc("public")
  );

  const account = useAccountState();

  useEffect(() => {
    if (!activeRoleId) {
      if (account.status === "signed-in-complete") {
        const {
          user: { _currentRoleId, _id },
          roles,
        } = account;

        // A user may not have any roles, e.g. on first account creation.
        if (!roles.length) return;

        const sessionStorageActiveRole = getSessionStorageActiveRole(_id);

        if (sessionStorageActiveRole && roles.some((r) => r._id === sessionStorageActiveRole)) {
          // Default to use the active role from session storage -- this will persist across refreshes
          // in the same tab but not across tabs
          setActiveRoleId(sessionStorageActiveRole);
        } else if (roles.some((r) => r._id === _currentRoleId)) {
          // If the session does not have an active role, use the most recently used role
          // by the user that is stored in the database
          setActiveRoleId(_currentRoleId);
        } else {
          // If no role is stored for the user, use their first role
          setActiveRoleId(roles[0]._id);
        }
      }
    }
  }, [account, activeRoleId]);

  const { gitsha } = globalSettings ?? {};
  const clientIsOutOfDate = gitsha && gitsha !== process.env.REACT_APP_GIT_SHA;

  // Present the "Client out of date" message if the version has changed and the message
  // is not already visible
  useEffect(() => {
    if (!isLocalhost && !clientUpdateMessageVisibleRef.current && clientIsOutOfDate) {
      clientUpdateMessageVisibleRef.current = true;
      notification.info({
        onClose: () => (clientUpdateMessageVisibleRef.current = false),
        message: (
          <>
            A newer version of Rooted is available!{" "}
            <Button type="link" onClick={reloadPage}>
              Refresh now
            </Button>
          </>
        ),
        duration: 0,
      });
    }
  }, [clientIsOutOfDate]);

  // Keep remote DB in sync with this active role, so that refreshes stay on same profile.
  useSyncActiveRole({
    userId: account.status === "signed-in-complete" ? account.authUser.uid : undefined,
    activeRoleId,
  });

  useUpdateAccountStatusCookie(account);

  const activeRole =
    account.status === "signed-in-complete"
      ? account.roles.find((role) => role._id === activeRoleId) || account.roles[0]
      : undefined;

  const [subscription] = useMySubscriptionPlan(activeRole);

  if (
    globalSettings === undefined ||
    account === undefined ||
    // If the user has any roles, they will be set automatically via the useEffect above, based on
    // their previous `_currentRoleId`. Otherwise, they are in an onboarding state.
    (account.status === "signed-in-complete" &&
      activeRoleId === undefined &&
      account.roles.length > 0) ||
    account.status === "loading"
  ) {
    return <LoadingPage />;
  }

  const isAdmin = account.status === "signed-in-complete" && account.isAdmin;

  const { downForMaintenance, bannerMessageMarkdown, bannerVisibility } = globalSettings;
  if (downForMaintenance && !isAdmin) return <DownForMaintenancePage />;

  // Banner Visibility is controlled in /admin/site-settings, toggle-able for each profile type + "guest"
  const bannerVisible = !!bannerVisibility?.includes(activeRole?.type || "guest");

  return (
    <RootedGlobalStateContext.Provider
      value={{
        activeRole,
        isAdmin,
        permissions: {
          seller: isSeller(activeRole)
            ? {
                orderMinimum: (subscription?.permissionTier ?? 0) > 10,
                // Coops can always have tags.
                buyerTags: (subscription?.permissionTier ?? 0) > 10 || activeRole.type === "coop",
              }
            : undefined,
        },
        globalSettings,
        account,
        setActiveRoleId,
      }}
    >
      {/* Custom Banner, for whatever utility we may need */}
      {bannerVisible && !!bannerMessageMarkdown && (
        <GlobalBanner markdown={bannerMessageMarkdown} />
      )}

      {/* Dev / Admin Banners */}
      <AppVersionBanner />
      {isAdmin && downForMaintenance && <AdminDownForMaintenanceBanner />}
      {children}
    </RootedGlobalStateContext.Provider>
  );
};

function useRooted() {
  const context = React.useContext(RootedGlobalStateContext);
  if (context === undefined) {
    throw new Error("useRooted must be used within a RootedGlobalStateProvider");
  }
  return context;
}

export { RootedGlobalStateProvider, useRooted };
