import { CloseOutlined } from "@ant-design/icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { assertIsEnterprise, MediaReference, WithId } from "@rooted/shared";
import { Button, Card, Col, Popover, Row, Space } from "antd";
import { SelectProps } from "antd/lib/select";
import React, { useState } from "react";
import { useRooted } from "../../RootedContext";
import { useMediaLibraryResults } from "../../services/sellers/media-library";

export interface MediaSelectProps extends SelectProps<string> {
  imageSize?: number;
}

export const MediaLibrarySelect: React.FC<MediaSelectProps> = ({
  value,
  onChange,
  imageSize = 200,
  ...props
}) => {
  const { activeRole } = useRooted();
  assertIsEnterprise(activeRole);

  const [selection, setSelection] = useState<string | undefined>(value);
  const [visible, setVisible] = useState(false);

  // load all media from library and
  const [data, dataLoading, dataError] = useMediaLibraryResults(activeRole, "", 9999);

  const selectImage = (m: WithId<MediaReference>) => {
    setSelection(m.storageUrl);
    value = m.storageUrl; // propogate back up to Form.Items
    setVisible(false);
    onChange && onChange(m.storageUrl, {} as any);
  };

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const mediaSelector = (
    <Row>
      {data?.map((m) => (
        <Col
          key={m._id}
          xs={6}
          md={3}
          style={{ margin: 10 }}
          onClick={() => {
            selectImage(m);
          }}
        >
          <Card
            hoverable
            bordered={false}
            cover={<img alt={m.fileName} src={m.storageUrl} style={{ display: "block" }} />}
            bodyStyle={{ padding: 10, fontSize: "0.7em", textAlign: "center" }}
          ></Card>
        </Col>
      ))}
    </Row>
  );
  return (
    <Space direction="vertical">
      <div
        style={{
          display: "block",
          width: imageSize,
          height: imageSize,
          backgroundPosition: "center",
          backgroundImage: "url(" + selection + ")",
          backgroundSize: "cover",
          overflow: "hidden",
          border: "1px solid #d9d9d9",
          borderRadius: 5,
        }}
      >
        &nbsp;
      </div>

      <Popover
        content={mediaSelector}
        visible={visible}
        title={
          <>
            <Button
              onClick={() => setVisible(false)}
              icon={<CloseOutlined />}
              type="text"
              size="small"
            >
              Close
            </Button>

            {/* Add a button for the media library to open in a new tab. When an image is added,
                it will immediately show up here. In the future, we would like to add direct image
                uploading from the MediaLibrarySelect, but for now this works as a stopgap */}
            <Button
              style={{ marginLeft: 10 }}
              type="link"
              onClick={() => {
                window.open("/my-account/media-library", "_blank")?.focus();
              }}
            >
              Add Image
              <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: 8 }} />
            </Button>
          </>
        }
      >
        <Button onClick={() => setVisible(true)} style={{ width: imageSize }}>
          Select Image
        </Button>
      </Popover>
    </Space>
  );
};
