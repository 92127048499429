import { ChildOrder, OrderItem, Relisting, WithId } from "@rooted/shared";
import { Divider, Typography, Tag, Tooltip } from "antd";
import { ColumnType } from "antd/lib/table";
import React from "react";
import { formatCurrency } from "../FormControls/CurrencyInput";
import { ProfileLink } from "../ProfileLink/ProfileLink";
import { ChildOrderPriceWidget } from "../Widgets/ChildOrderItems/PriceWidget";
import { ChildOrderQuantityWidget } from "../Widgets/ChildOrderItems/QuantityWidget";
import { FlowerVariety } from "./FlowerVarietyColumn";
import { OriginalGrowerLink } from "./OriginalGrowerColumn";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import OverflowParagraph from "../OverflowParagraph/OverflowParagraph";

export const childOrderNameColumn: ColumnType<OrderItem> = {
  title: "Product",
  key: "name",
  defaultSortOrder: "ascend",
  sorter: (a, b) =>
    a.listing.product.name.toLowerCase().localeCompare(b.listing.product.name.toLowerCase()),
  render: (r, v) => (
    <>
      {v.status === "added" && (
        <Tooltip title={"This item was added after the original order"}>
          <Tag icon={<PlusOutlined />} color="warning" style={{ marginBottom: 4, marginLeft: -12 }}>
            Added
          </Tag>
          <br />
        </Tooltip>
      )}
      {v.status === "changed" && (
        <Tooltip title={"This item was changed after the original order"}>
          <Tag
            icon={<EditOutlined className={"not-button"} />}
            color="warning"
            style={{ marginBottom: 4, marginLeft: -12 }}
          >
            Changed
          </Tag>
          <br />
        </Tooltip>
      )}
      {v.listing.category === "cut-flower" ? (
        <FlowerVariety
          expandable
          flowerPrototype={v.listing.product._prototypeCache}
          color={v.listing.product.dominantColor}
        />
      ) : (
        <Typography.Text>{v.listing.product.name}</Typography.Text>
      )}
      {v.listing.product.description && (
        <>
          <Divider style={{ marginTop: 4, marginBottom: 4 }} />
          <OverflowParagraph expandable>{v.listing.product.description}</OverflowParagraph>
        </>
      )}
    </>
  ),
};
export function makeChildOrderQuantityColumn(
  order: WithId<ChildOrder>,
  editable: boolean
): ColumnType<OrderItem> {
  return {
    title: "Quantity",
    key: "quantity",
    width: 130,
    className: "text-center",
    sorter: (a, b) => a.quantity - b.quantity,
    render: (r, v) => <ChildOrderQuantityWidget order={order} item={v} editable={editable} />,
  };
}

export function makeChildOrderPriceColumn(
  order: WithId<ChildOrder>,
  editable: boolean
): ColumnType<OrderItem> {
  return {
    title: "Price",
    key: "price",
    width: 160,
    className: "text-right",
    sorter: (a, b) => a.listing.pricing.price - b.listing.pricing.price,
    render: (r, v) => <ChildOrderPriceWidget order={order} item={v} editable={editable} />,
  };
}

export const childOrderTotalColumn: ColumnType<OrderItem> = {
  title: "Total",
  key: "total",
  width: 90,
  className: "text-right",
  sorter: (a, b) => a.listing.pricing.price * a.quantity - b.listing.pricing.price * b.quantity,
  render: (r, v) => <>{formatCurrency(v.listing.pricing.price * v.quantity)}</>,
};

export const childOrderOriginalGrowerColumn: ColumnType<OrderItem> = {
  title: "Grower",
  key: "grower",
  width: 100,
  sorter: (a, b) =>
    a.listing.seller._cache.bio.displayName
      .toLowerCase()
      .localeCompare(b.listing.seller._cache.bio.displayName.toLowerCase()),
  render: (r, v) =>
    (v.listing as any).backingListing ? (
      <OriginalGrowerLink relisting={v.listing as Relisting} />
    ) : (
      <ProfileLink
        to={{
          ...v.listing.seller._cache,
          _id: v.listing.seller.profileId,
        }}
      >
        {v.listing.seller._cache.bio.displayName}
      </ProfileLink>
    ),
};
