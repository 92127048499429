import { ApplicationForm, WithId, firstIncompleteSection } from "@rooted/shared";

import { Button, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

export const ApplicationCompleteSplash: React.FC<{ application: WithId<ApplicationForm> }> = ({
  application,
}) => {
  if (application.status === "approved")
    return (
      <>
        <Typography.Text>
          Your application has been approved.
          {application.status === "approved" && (
            <Link
              style={{ float: "right" }}
              to={`/onboarding/applications/${application._id}/${firstIncompleteSection(
                application
              )}`}
            >
              {" "}
              <Button type="primary">
                Continue to Complete&nbsp;
                <span style={{ textTransform: "capitalize" }}>
                  {firstIncompleteSection(application)}
                </span>
              </Button>
            </Link>
          )}
        </Typography.Text>
      </>
    );

  if (application.status === "waitlisted")
    return (
      <>
        <Typography.Text>Your application has been waitlisted.</Typography.Text>
      </>
    );
  if (application.status === "in-progress" && application.bio && application.details)
    return (
      <>
        <Typography.Text>
          <h1 style={{ fontSize: 24 }}>Your Application is Complete!</h1>
          <p>
            You should receive an email confirmation with instructions about next steps. We'll be in
            touch shortly!
          </p>
        </Typography.Text>
      </>
    );
  if (application.status === "profile-created")
    return (
      <>
        <Typography.Text>
          You're all done! Your application is approved and you're ready to get started.
        </Typography.Text>
      </>
    );

  return (
    <>
      <Typography.Text>Looks like your application isn't finished yet.</Typography.Text>

      <Link
        style={{ float: "right" }}
        to={`/onboarding/applications/${application._id}/${firstIncompleteSection(application)}`}
      >
        <Button type="primary">
          Continue to Complete &nbsp;
          <span style={{ textTransform: "capitalize" }}>{firstIncompleteSection(application)}</span>
        </Button>
      </Link>
    </>
  );
};
