import { MoreOutlined } from "@ant-design/icons";
import { ApprovedCoopMembership, FullCoopRole, WithId } from "@rooted/shared";
import { Alert, Button, Dropdown, Menu, Space, Table } from "antd";
import React from "react";
import { WeekdaySelect } from "../../../components/FormControls/WeekdaySelect";
import { EnterpriseProfileCallout } from "../../../components/Misc/EnterpriseProfileCallout";
import { PageHeader } from "../../../components/PageHeader";
import { GenericTagsWidget } from "../../../components/Widgets/GenericTagsWidget";
import {
  approveCoopMembership,
  removeCoopMembership,
  setCoopMembershipTags,
  updateCoopMembership,
  useCoopMembershipRequests,
  useCoopMemberships,
  usePendingCoopMemberships,
} from "../../../services/coop-memberships";
import { CloudFunctionButton } from "../../admins/SiteSettings/ActionsPane";
import { LoadingPage, PageContent, WideLayout } from "../../layouts";

export const CoopMemberships: React.FC<{ role: FullCoopRole }> = (props) => {
  const [memberships, membershipsLoading] = useCoopMemberships(props.role);
  const [pendingCoopMemberships, pendingCoopMembershipsLoading] = usePendingCoopMemberships(
    props.role
  );
  const [membershipRequests, membershipRequestsLoading] = useCoopMembershipRequests(props.role);

  const existingTags = memberships
    ?.map((x) => x.tags)
    .flat()
    .filter((v, i, arr) => arr.indexOf(v) === i && v !== undefined) as string[]; // get unique items

  const currentCoopMembershipTableColumns = [
    {
      title: "Name",
      dataIndex: ["buyerProfileCache", "bio", "displayName"],
      key: "businessName",
      render: (text: string, record: ApprovedCoopMembership) => (
        <>
          <EnterpriseProfileCallout id={record.growerId} />
        </>
      ),
    },
    {
      title: <>Availability Days</>,
      key: "order",
      width: 81,
      render: (x: any, record: WithId<ApprovedCoopMembership>) => (
        <WeekdaySelect
          value={record.availabilitySettings.weekdays}
          onChange={(v) => {
            updateCoopMembership(record, {
              availabilitySettings: {
                weekdays: v,
              },
            });
          }}
          size="small"
        />
      ),
    },

    {
      title: <>Type</>,
      key: "order",
      width: 200,
      dataPath: ["tags"],
      filters: existingTags?.map((t) => {
        return {
          text: t,
          value: t,
        };
      }),
      onFilter: (value: any, record: WithId<ApprovedCoopMembership>) =>
        record.tags?.includes(value) ? true : false,
      render: (_: any, record: WithId<ApprovedCoopMembership>) => (
        <GenericTagsWidget
          keyId={record._id}
          editable
          value={record.tags || []}
          allTags={existingTags || []}
          onChange={(x) => setCoopMembershipTags(record, x)}
        />
      ),
    },
    {
      title: "",
      width: 30,
      key: "action",
      render: (x: any, record: WithId<ApprovedCoopMembership>) => (
        <>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item danger key="remove" onClick={() => removeCoopMembership(record._id)}>
                  Remove Buyer
                </Menu.Item>
              </Menu>
            }
          >
            <MoreOutlined />
          </Dropdown>
        </>
      ),
    },
  ];

  return (
    <>
      <WideLayout>
        <PageContent>
          <PageHeader title="My Coop Members" />

          {membershipRequests &&
            membershipRequests.map((membership) => (
              <Alert
                key={membership._id}
                style={{ marginBottom: 16 }}
                message={
                  <>
                    <EnterpriseProfileCallout id={membership.growerId} /> requested to connect with
                    you.
                  </>
                }
                type="info"
                action={
                  <Space>
                    <CloudFunctionButton
                      danger
                      type="primary"
                      onClick={() => approveCoopMembership(membership._id)}
                    >
                      Approve
                    </CloudFunctionButton>
                    <Button
                      danger
                      type="primary"
                      onClick={() => removeCoopMembership(membership._id)}
                    >
                      Decline
                    </Button>
                  </Space>
                }
              />
            ))}

          {pendingCoopMemberships &&
            pendingCoopMemberships.map((membership) => (
              <Alert
                key={membership._id}
                style={{ marginBottom: 16 }}
                message={
                  <>
                    Your request to <EnterpriseProfileCallout id={membership.growerId} /> is
                    pending.
                  </>
                }
                type="info"
                action={
                  <Space>
                    <Button
                      danger
                      type="primary"
                      onClick={(c) => removeCoopMembership(membership._id)}
                    >
                      Cancel Request
                    </Button>
                  </Space>
                }
              />
            ))}

          {!memberships || !pendingCoopMemberships || !membershipRequests ? (
            <LoadingPage marginTop={"10vh"} />
          ) : (
            <Table
              rowKey={(m) => m._id}
              columns={currentCoopMembershipTableColumns}
              dataSource={memberships}
              pagination={false}
            />
          )}
        </PageContent>
      </WideLayout>
    </>
  );
};
