import { FullBuyerRole, WithId, SavedLocation, StreetAddress } from "@rooted/shared";

import { Button, Select, Form } from "antd";
import { SelectProps } from "antd/lib/select";
import React, { useEffect } from "react";
import { useSavedAddresses } from "../../services/buyers/addresses";
import { showAddressModal } from "../../views/generic/Settings/AddressesPane";
import { Loader } from "../../views/layouts";

export interface AddressSelectProps extends SelectProps<WithId<SavedLocation>> {
  value?: WithId<SavedLocation>;
  onChange?: (x: WithId<SavedLocation> | undefined) => void;
  onUserChange?: (x: WithId<SavedLocation> | undefined) => void;
  autoSelect?: boolean;
  role: FullBuyerRole;
}

const MAX_LEN = 18;
const shortenAddress = (a: string) => {
  if (a.length < MAX_LEN - 1) return a;
  return `${a.substr(0, MAX_LEN)}...`;
};

export const AddressSelect: React.FC<AddressSelectProps> = ({
  autoSelect,
  role,
  onChange,
  value,
  onUserChange,
}) => {
  const [data, dataLoading, dataError] = useSavedAddresses(role);

  // Auto select the first address
  useEffect(() => {
    if (data && data.length === 1 && !value && autoSelect) {
      onChange && onChange(data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!data || dataLoading)
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Loader />
      </div>
    );

  const activeValueText = value ? shortenAddress(value.streetAddress.line1) : undefined;

  // TODO: Current Problem:
  // If a user deletes all their addresses, they still have a cart address even with nothing in a cart
  // Ideally, if only 1 address would like to set a preset address for ui purposes of current (without down arrow)
  // If more than 1, then show pulldown arrow.
  // This affects commented out showArrow and value below once that's done.
  // For time being, so it's clear, displaying "cart" address so the user can visually see and update the address
  return (
    <Form layout="vertical">
      {data.length > 0 && (
        <Select
          style={{ width: "100%" }}
          id="addressSelect"
          //showArrow={data.length > 1 ? true : false}
          showArrow={true}
          filterOption={false}
          onChange={(v, o) => {
            if (v === "add-item") {
              showAddressModal(role);
              return;
            }

            const address = data.find((x) => x._id === v);
            onChange?.(address);
            onUserChange?.(address);
          }}
          notFoundContent={null}
          optionLabelProp="label"
          placeholder="Select address"
          className="side-padding"
          value={activeValueText}
        >
          {data.map((x) => {
            return (
              <Select.Option
                value={x._id}
                label={<>&nbsp;&nbsp;{x.streetAddress.line1}</>}
                key={x.geohash}
              >
                <AddressDisplay address={x.streetAddress} />
              </Select.Option>
            );
          })}
        </Select>
      )}
      {data.length > 0 && (
        <span style={{ display: "block", textAlign: "center", paddingTop: 10, marginBottom: 15 }}>
          - or -
        </span>
      )}
      <Button
        type={data && data.length > 0 ? "dashed" : "primary"}
        onClick={() => showAddressModal(role)}
        style={{ display: "block", width: "100%" }}
        className="btn-small"
      >
        Add an Address
      </Button>
    </Form>
  );
};

export const AddressDisplay: React.FC<{ address: StreetAddress }> = ({ address }) => {
  return (
    <>
      {address.line1} <br />
      {address.line2 && (
        <>
          {address.line2}
          <br />
        </>
      )}
      {address.city}, {address.state}
      {/* TODO: do we need country yet?? It bloats every address into 3 rows
      <br />{address.country}*/}
    </>
  );
};
