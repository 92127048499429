import { WithId, SubscriptionPlan, FullSellerRole, FullRole, isSeller } from "@rooted/shared";
import { functions, db, useCollectionDataChecked, useDocumentDataChecked } from "../firebase";

export const useSubscriptionPlans = (type: "coop" | "grower") =>
  useCollectionDataChecked<WithId<SubscriptionPlan>>(
    db.collection("subscriptionPlans").where("type", "==", type),
    { idField: "_id" }
  );

export const changeSubscription = async (role: FullSellerRole, key: string) => {
  await functions.httpsCallable("changeSubscription")({ profileId: role.profileId, key: key });
};

export const useMySubscriptionPlan = (role?: FullRole) =>
  useDocumentDataChecked<WithId<SubscriptionPlan>>(
    // Only sellers have subscription keys
    role && isSeller(role) && role.privateProfile?.subscription?.key
      ? db.collection("subscriptionPlans").doc(role.privateProfile.subscription.key)
      : null,
    { idField: "_id" }
  );
