import React, { useState } from "react";
import { legalDesignElements, CreateFlowerParams, makeFlowerPrototype } from "@rooted/shared";
import { Button, Card, Checkbox, Form, Input, Select, Space } from "antd";
import { db } from "../../../services/firebase";
import { PageContent, WideLayout } from "../../layouts";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { StemsPerBunchInput } from "../../growers/Inventory/utils/FlowerUtils";
import { SelectProps } from "antd/lib/select";
import { PageHeader } from "../../../components/PageHeader";

const legalDesignElementsOptions = legalDesignElements.map((element) => ({
  label: element.name,
  value: element.key,
}));

export const DesignElementInput: React.FC<SelectProps<string[]>> = (props) => {
  return <Select {...props} mode="multiple" maxTagCount={5} options={legalDesignElementsOptions} />;
};

export const CreateFlower: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    setLoading(true);

    //Add each prototype in parallel
    const promises = values["prototypes"].map(async (x: CreateFlowerParams) => {
      x.active = true;
      const flower = makeFlowerPrototype(x);
      await db.collection("flowerPrototypes").add(flower);
    });

    await Promise.all(promises);
    setLoading(false);
    navigate("/admin/flowers");
  };

  return (
    <WideLayout>
      <PageContent>
        <PageHeader title="Create Flowers" breadcrumb />
        <Form layout="vertical" requiredMark="optional" onFinish={onFinish}>
          <Form.List name="prototypes">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field) => (
                    <Card key={field.key} style={{ marginBottom: 16 }}>
                      <div style={{ textAlign: "right" }}>
                        <Button
                          type="link"
                          onClick={() => {
                            remove(field.name);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                      <div style={{ display: "flex" }}>
                        <Space direction="vertical" size={0} style={{ flex: "1" }}>
                          <Space direction="horizontal">
                            <Form.Item
                              style={{ marginBottom: 8 }}
                              label="Genus"
                              name={[field.name, "genus"]}
                              fieldKey={[field.fieldKey, "genus"]}
                              rules={[{ required: true, message: " " }]}
                            >
                              <Input></Input>
                            </Form.Item>
                            <Form.Item
                              style={{ marginBottom: 8 }}
                              label="Species"
                              name={[field.name, "species"]}
                              fieldKey={[field.fieldKey, "species"]}
                            >
                              <Input></Input>
                            </Form.Item>
                            <Form.Item
                              style={{ marginBottom: 8 }}
                              label="Series"
                              name={[field.name, "series"]}
                              fieldKey={[field.fieldKey, "series"]}
                            >
                              <Input></Input>
                            </Form.Item>
                            <Form.Item
                              style={{ marginBottom: 8 }}
                              label="Variety"
                              name={[field.name, "variety"]}
                              fieldKey={[field.fieldKey, "variety"]}
                            >
                              <Input></Input>
                            </Form.Item>
                          </Space>
                          <Form.Item
                            style={{ marginBottom: 8 }}
                            label="Common Names"
                            name={[field.name, "commonNames"]}
                            fieldKey={[field.fieldKey, "commonNames"]}
                          >
                            <Select mode="tags" maxTagCount={5}></Select>
                          </Form.Item>

                          <Form.Item
                            label="Design Elements"
                            name={[field.name, "designElements"]}
                            fieldKey={[field.fieldKey, "designElements"]}
                          >
                            <DesignElementInput />
                          </Form.Item>

                          <Space>
                            {
                              // TODO: Remove this field in favor of listing only selection
                            }
                            <Form.Item
                              label="Stems Per Bunch"
                              name={[field.name, "stemsPerBunch"]}
                              fieldKey={[field.fieldKey, "stemsPerBunch"]}
                              initialValue={10}
                              rules={[{ required: true, message: " " }]}
                            >
                              <StemsPerBunchInput />
                            </Form.Item>
                            &nbsp;&nbsp;&nbsp;
                            <Form.Item
                              label=" "
                              name={[field.name, "invasive"]}
                              fieldKey={[field.fieldKey, "invasive"]}
                              requiredMark={undefined}
                              valuePropName="checked"
                            >
                              <Checkbox>Invasive</Checkbox>
                            </Form.Item>
                            <Form.Item
                              requiredMark={undefined}
                              label=" "
                              name={[field.name, "ethyleneSensitive"]}
                              fieldKey={[field.fieldKey, "ethyleneSensitive"]}
                              valuePropName="checked"
                            >
                              <Checkbox>Ethylene Sensitive</Checkbox>
                            </Form.Item>
                            <Form.Item
                              requiredMark={undefined}
                              label=" "
                              name={[field.name, "coolerSensitive"]}
                              fieldKey={[field.fieldKey, "coolerSensitive"]}
                              valuePropName="checked"
                            >
                              <Checkbox>Cooler Sensitive</Checkbox>
                            </Form.Item>
                          </Space>
                        </Space>
                      </div>
                    </Card>
                  ))}

                  <Form.Item className="full-width">
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add Flower
                    </Button>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </PageContent>
    </WideLayout>
  );
};
