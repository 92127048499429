import React from "react";
import { Typography, Button, Col } from "antd";

export const NoSearchResults: React.FC<{
  search: string;
  onClear: () => void;
  clearing?: boolean;
}> = ({ search, onClear, clearing }) => {
  return (
    <div style={{ padding: 20, display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography.Text style={{ paddingBottom: 8 }}>
        No Results found for <Typography.Text code>{search}</Typography.Text>.
      </Typography.Text>
      <Button loading={clearing} type="primary" onClick={() => void onClear()}>
        Clear Search
      </Button>
    </div>
  );
};
