import React, { useMemo, useState } from "react";
import {
  EnterpriseProfile,
  searchObjectCurried,
  formatPluralCount,
  ProfileType,
  EnterpriseProfileType,
  RetailBuyerProfile,
  WithId,
} from "@rooted/shared";
import { PageHeader } from "../../../components/PageHeader";
import { Space, Table, Typography, Radio } from "antd";
import Search from "antd/lib/input/Search";
import { db, useCollectionDataChecked } from "../../../services/firebase";
import { PageContent, WideLayout } from "../../layouts";

import { useDebouncedTrigger } from "../../../hooks/util/useDebouncedTrigger";
import { EnterpriseProfileColumns } from "./EnterpriseProfileColumns";
import { RetailProfileColumns } from "./RetailProfileColumns";
import { useSyncQueryParams } from "../../../hooks/util/useSyncQueryParams";
import { useQuery } from "../../../hooks/util/useQuery";

function isProfileType(s?: string | null): s is ProfileType {
  return !!s && (s === "wholesale-buyer" || s === "retail-buyer" || s === "coop" || s === "grower");
}

export const AdminProfileList: React.FC = () => {
  const query = useQuery();
  const [type, setType] = useState<ProfileType>(() => {
    const param = query.get("type");
    if (isProfileType(param)) {
      return param;
    } else return "coop";
  });

  const [search, setSearch] = useState(() => query.get("s") || "");
  useSyncQueryParams([
    ["type", type],
    ["s", search],
  ]);

  return (
    <WideLayout>
      <PageContent>
        <PageHeader title={`Profiles`} />
        <Space direction="vertical" style={{ width: "100%" }}>
          <Radio.Group
            style={{ marginBottom: 16 }}
            value={type}
            onChange={(e) => setType(e.target.value)}
            buttonStyle="solid"
            optionType="button"
            options={[
              { label: "Coops", value: "coop" },
              { label: "Wholesale Buyers", value: "wholesale-buyer" },
              { label: "Growers", value: "grower" },
              { label: "Retail Buyers", value: "retail-buyer" },
            ]}
          />
          <Search
            placeholder={`Experimental Fuzzy Search`}
            style={{ width: 300 }}
            allowClear
            onSearch={setSearch}
            enterButton
            defaultValue={search} // use query param
          />
          {type === "retail-buyer" ? (
            <RetailProfileTable search={search} />
          ) : (
            <EnterpriseProfileTable type={type} search={search} />
          )}
        </Space>
      </PageContent>
    </WideLayout>
  );
};

const EnterpriseProfileTable: React.FC<{ search: string; type: EnterpriseProfileType }> = ({
  search,
  type,
}) => {
  const debouncedSearch = useDebouncedTrigger(search);
  const [profiles = [], loading] = useCollectionDataChecked<WithId<EnterpriseProfile>>(
    db.collection("profiles").where("type", "==", type),
    {
      idField: "_id",
    }
  );

  const filteredProfiles = useMemo(
    () =>
      !debouncedSearch.trim()
        ? profiles
        : profiles.filter(searchObjectCurried(debouncedSearch.trim())),
    [debouncedSearch, profiles]
  );

  return (
    <>
      <Typography.Text type="secondary">
        {formatPluralCount("result", filteredProfiles.length)}
      </Typography.Text>
      <Table
        rowKey={({ _id }) => _id}
        className={"table-x-scroll"}
        loading={loading || debouncedSearch !== search}
        columns={EnterpriseProfileColumns}
        dataSource={filteredProfiles}
        pagination={false}
        style={{ width: "100%", display: "flex", flex: 1 }}
      />
    </>
  );
};

const RetailProfileTable: React.FC<{ search: string }> = ({ search }) => {
  const debouncedSearch = useDebouncedTrigger(search);
  const [profiles = [], loading] = useCollectionDataChecked<WithId<RetailBuyerProfile>>(
    db.collection("profiles").where("type", "==", "retail-buyer"),
    {
      idField: "_id",
    }
  );

  const filteredProfiles = useMemo(
    () =>
      !debouncedSearch.trim()
        ? profiles
        : profiles.filter(searchObjectCurried(debouncedSearch.trim())),
    [debouncedSearch, profiles]
  );

  return (
    <>
      <Typography.Text type="secondary">
        {formatPluralCount("result", filteredProfiles.length)}
      </Typography.Text>
      <Table
        rowKey={({ _id }) => _id}
        className={"table-x-scroll"}
        loading={loading || debouncedSearch !== search}
        columns={RetailProfileColumns}
        dataSource={filteredProfiles}
        pagination={false}
        style={{ width: "100%", display: "flex", flex: 1 }}
      />
    </>
  );
};
