import { FlowerPrototype, makeFlowerPrototype, printDate } from "@rooted/shared";
import { Button, Checkbox, Divider, Form, Input, Select, Space, Switch, Typography } from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CurrencyInput } from "../../../components/FormControls/CurrencyInput";
import { PageHeader } from "../../../components/PageHeader";
import { db, useDocumentDataChecked } from "../../../services/firebase";
import { now } from "../../../utils/dates";
import { LoadingPage, NarrowLayout, NotFoundPage, PageContent } from "../../../views/layouts";
import { DesignElementInput } from "./CreateFlower";

export const FlowerDetails: React.FC = () => {
  const { itemId } = useParams();
  const [flower, flowerLoading] = useDocumentDataChecked<FlowerPrototype>(
    db.collection("flowerPrototypes").doc(itemId)
  );

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    setLoading(true);
    values._updated = now(); // update time updated from UI
    const updatedFlower = makeFlowerPrototype(values);
    await db.collection("flowerPrototypes").doc(itemId).set(updatedFlower, { merge: true });
    setLoading(false);
    navigate("/admin/flowers");
  };

  if (!flower && !flowerLoading) return <NotFoundPage />;
  return (
    <>
      <NarrowLayout>
        <PageContent>
          <PageHeader title={flower?.scientificName || "..."} breadcrumb />
          <Space direction="vertical" size="small">
            <Typography.Text type="secondary">
              <b>Created:</b> &nbsp; {printDate(flower?._created)}
            </Typography.Text>
            <Typography.Text type="secondary">
              <b>Last Updated:</b> &nbsp; {printDate(flower?._updated)}
            </Typography.Text>
          </Space>
          <br />
          <br />

          {flower && (
            <Form
              layout="vertical"
              requiredMark="optional"
              onFinish={onFinish}
              initialValues={flower}
            >
              <Form.Item
                label="Active"
                name="active"
                valuePropName="checked"
                rules={[{ required: true, message: " " }]}
              >
                <Switch />
              </Form.Item>

              <Form.Item name="generic" label="Generic" valuePropName="checked">
                <Checkbox />
              </Form.Item>

              <Form.Item label="Genus" name="genus" rules={[{ required: true, message: " " }]}>
                <Input></Input>
              </Form.Item>
              <Form.Item label="Species" name="species">
                <Input></Input>
              </Form.Item>
              <Form.Item label="Series" name="series">
                <Input></Input>
              </Form.Item>
              <Form.Item label="Variety" name="variety">
                <Input></Input>
              </Form.Item>
              <Form.Item label="Common Names" name="commonNames">
                <Select mode="tags" maxTagCount={5}></Select>
              </Form.Item>

              <Form.Item label="Design Elements" name="designElements">
                <DesignElementInput />
              </Form.Item>

              <Form.Item
                label="Stems Per Bunch"
                name="stemsPerBunch"
                initialValue={5}
                rules={[{ required: true, message: " " }]}
              >
                <Select>
                  <Select.Option value={5}>5</Select.Option>
                  <Select.Option value={10}>10</Select.Option>
                </Select>
              </Form.Item>
              <Space>
                <Form.Item requiredMark={undefined} name="invasive" valuePropName="checked">
                  <Checkbox>Invasive</Checkbox>
                </Form.Item>

                <Form.Item
                  requiredMark={undefined}
                  name="ethyleneSensitive"
                  valuePropName="checked"
                >
                  <Checkbox>Ethylene Sensitive</Checkbox>
                </Form.Item>
                <Form.Item requiredMark={undefined} name="coolerSensitive" valuePropName="checked">
                  <Checkbox>Cooler Sensitive</Checkbox>
                </Form.Item>
              </Space>

              <Divider>Pricing</Divider>
              <Space direction="horizontal">
                <Form.Item requiredMark={undefined} name="lowestPrice" label="Lowest">
                  <CurrencyInput />
                </Form.Item>
                <Form.Item requiredMark={undefined} name="lowPrice" label="Low">
                  <CurrencyInput />
                </Form.Item>
                <Form.Item requiredMark={undefined} name="averagePrice" label="Average">
                  <CurrencyInput />
                </Form.Item>
                <Form.Item requiredMark={undefined} name="highPrice" label="High">
                  <CurrencyInput />
                </Form.Item>
                <Form.Item requiredMark={undefined} name="highestPrice" label="Highest">
                  <CurrencyInput />
                </Form.Item>
              </Space>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Update
                </Button>
              </Form.Item>
            </Form>
          )}
          {!flower && <LoadingPage />}
        </PageContent>
      </NarrowLayout>
    </>
  );
};
